import moment from "moment-timezone";

export interface EventsConfigValue{
  startEvent: string
  endEvent: string
  hide?: string
}
export interface EventsConfig{
  ny?:EventsConfigValue;
  ny_china?:EventsConfigValue
}
export const configs: EventsConfig = {
  ny:{
    startEvent:  moment.tz("2022-12-27",'Europe/Moscow').format(),
    endEvent:  moment.tz("2023-01-18 15:00",'Europe/Moscow').format(),
    hide:  moment.tz("2023-01-18 15:00",'Europe/Moscow').format()
  },
  ny_china:{
    startEvent:  moment.tz("2023-01-18 15:01",'Europe/Moscow').format(),
    endEvent:  moment.tz("2023-01-25 15:00",'Europe/Moscow').format(),
    hide:  moment.tz("2023-01-27 15:00",'Europe/Moscow').format()
  }
}
