import {Injectable} from '@angular/core';
import {Environments, EnvironmentService} from '@csgofast/core/environment-service';
import {ICurrency} from '@dev-fast/types';
import {Observable} from 'rxjs';
import {ApiService} from './api.service';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  private environment: Environments;

  constructor(private readonly api: ApiService, private readonly environmentService: EnvironmentService) {
    this.environment = this.environmentService.environments;
  }

  public getCurrencySettings = (): Observable<ICurrency[]> => this.api.get(`${this.environment.GATEWAY_URL}/api/currency-settings`);
}
