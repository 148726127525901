export enum OrderStatusEnum {
  NEW = 'new',
  FIRST_BID = 'first-bid',
  SECOND_BID = 'second-bid',
  THIRD_PLUS_BID = 'third-plus-bid',
  WAIT_FOR_CONFIRM = 'wait-for-confirm',
  WAIT_FOR_TRADE = 'wait-for-trade',
  WAIT_FOR_BUYER_ACCEPT = 'wait-for-buyer-accept',
  CANCELED_BY_SELLER_BEFORE_TRADE = 'canceled-by-seller-before-trade',
  CANCELED_BY_BUYER = 'canceled-by-buyer',
  CANCELED_BY_SELLER = 'canceled-by-seller',
  CANCELED_BY_TIMER = 'canceled-by-timer',
  CANCELED_BY_FASTOFFER = 'canceled-by-fastoffer',
  AUCTION_FINISHED = 'auction-finished',
  COMPLETED = 'completed',
  DELETED = 'deleted',
  PAUSED = 'paused',
}
