<fast-ui-brand ></fast-ui-brand>
<div class="title"> {{'GOFAST.MODAL.BANNED.HEADER'|translate}}</div>
<div class="description">{{data |translate}}</div>
<div class="description">{{'GOFAST.MODAL.BANNED.TEXT' |translate}} <a (click)="onClose()" [routerLink]="['support']" >{{'GOFAST.MODAL.BANNED.LINK'|translate}}</a></div>
<div class="logout-btn">
  <button
    class="btn btn-primary"
    (click)="onLogout()"
  >
    <mat-icon
      svgIcon="logout"
    ></mat-icon>
    {{ 'LOGOUT' | translate | titlecase }}
  </button>
</div>
