import {InjectionToken} from '@angular/core';
import {IGame, IInputMessage, IMessage, IStickerPack, IUserDetailed} from '@dev-fast/types';
import {Observable} from 'rxjs';

export const CHAT_ENGINE = new InjectionToken('CHAT_ENGINE');
// export const CHAT_API = new InjectionToken('CHAT_API');

export interface ChatEngine {
  readonly messages$: Observable<IMessage[]>;
  readonly stickers$: Observable<IStickerPack[]>;
  readonly activeStickerPack$: Observable<IStickerPack>;
  readonly chatId$: Observable<string>;
  readonly user$: Observable<IUserDetailed | null>;
  readonly availableGames$: Observable<IGame[]>;
  readonly currentGame$: Observable<IGame | null>;

  readonly banTimer$: Observable<string>;

  addMessage(msg: IInputMessage): void;
  setActiveStickerPack(name: string): void;
  getMessagesList(): void;
  checkCanChat(): void;
  closeWidget(): void;
}
// export interface ChatApi {
//   addMessage(chatId: string, message: { value: string | ISticker; game: string }): Observable<void>;
//   getMessagesList(chatId: string, callback: (messages: IMessage[]) => void): void;
//   messageCreatedEvent(): Observable<IMessage>;
//   messageDeletedEvent(): Observable<IMessage>;
// }
