<ng-container *ngIf="(breadcrumbsList$ | async) as list">
  <div class="breadcrumbs">
      <a class="breadcrumbs__breadcrumb breadcrumb"
       [routerLink]="breadcrumb.value"
       class="breadcrumb-link"
       *ngFor="let breadcrumb of list">
        {{breadcrumb.name}}
    </a>
  </div>
</ng-container>
