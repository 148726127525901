import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SkinItemTooltipComponent} from './skin-item-tooltip.component';
import {SkinItemTooltipDirective} from './skin-item-tooltip.directive';
import {OverlayModule} from '@angular/cdk/overlay';
import {TranslateModule} from '@ngx-translate/core';
import {AppCurrencyModule} from '@csgofast/shared/pipes';
import {LazyLoadImageModule, SkinItemImageModule} from '@csgofast/shared/directives';

@NgModule({
  declarations: [SkinItemTooltipComponent, SkinItemTooltipDirective],
  imports: [CommonModule, OverlayModule, AppCurrencyModule, LazyLoadImageModule, SkinItemImageModule, TranslateModule.forChild()],
  exports: [SkinItemTooltipComponent, SkinItemTooltipDirective],
})
export class SkinItemTooltipModule {}
