import {EventsConfig, ICaseQuestMotivatorLastDto, ICasesQuestItem} from '@dev-fast/types';

export interface NYEventStateModel {
  items: ICasesQuestItem[];
  motivatorList: ICaseQuestMotivatorLastDto[];
  overlay: ICasesQuestItem|null;
  reward: ICasesQuestItem | null
  eventName: keyof EventsConfig | null;
  untilEventTime:number|null;
  eventHistoryName: keyof EventsConfig | null;
  untilEventHistoryTime:number|null
}
export const NY_EVENT_INITIAL_STATE: NYEventStateModel = {
  items: [],
  motivatorList: [],
  overlay: null,
  reward: null,
  eventName: null,
  untilEventTime: null,
  eventHistoryName: null,
  untilEventHistoryTime: null,
};
