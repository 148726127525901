import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  Output, Renderer2
} from '@angular/core';

@Directive({
  selector: '[lazyLoadImage]',
})
export class LazyLoadImageDirective implements AfterViewInit, OnChanges {
  @HostBinding('attr.src') private srcAttr: string | null = null;
  @Input()
  public src!: string;
  @Input()
  public bgSrc!: string;
  @Input()
  public fallback!: string;
  @Input() public forceLoad = false;

  private static canLazyLoad(): boolean {
    return window && 'IntersectionObserver' in window;
  }

  @HostListener('error')
  useFallback(): void {
    this.srcAttr = this.fallback;
  }

  constructor(private el: ElementRef,private renderer:Renderer2) {}

  public ngAfterViewInit(): void {

    if (LazyLoadImageDirective.canLazyLoad() && !this.forceLoad) {
      this.lazyLoadImage();
    } else {
      this.loadImage();
    }
  }

  public ngOnChanges(): void {

    if (LazyLoadImageDirective.canLazyLoad() && !this.forceLoad) {
      this.lazyLoadImage();
    } else {
      this.loadImage();
    }
  }

  private lazyLoadImage(): void {

    const obs = new IntersectionObserver((entries) =>
      entries.forEach(({ isIntersecting }) => {
        if (isIntersecting) {

          this.loadImage();
          this.el.nativeElement.src = this.src;
          obs.unobserve(this.el.nativeElement);
        }
      })
    );
    obs.observe(this.el.nativeElement);
  }

  private loadImage(): void {
    this.srcAttr = this.src;
    if(this.bgSrc){
      this.renderer.setStyle(this.el.nativeElement,'background-image', `url(${this.bgSrc})`)
    }
    if (this.forceLoad) {
      this.el.nativeElement.src = this.src;
    }
  }
}
