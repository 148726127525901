import {animate, query, sequence, style, transition, trigger} from '@angular/animations';

export const headerSettingsAnimation = trigger('headerSettingsAnimation', [
  transition(':enter', [
    query('.header-settings-dropdown', [style({ height: '0' }), animate('300ms 100ms ease-in', style({ height: '!' }))], {
      optional: true,
    }),
  ]),
]);
export const settingsAnimation = trigger('settingsAnimation', [
  transition(':enter', [
    sequence([
      query('.header-control-selected-header', [style({ opacity: 0 })]),
      query(':self', [style({ width: '42px' }), animate('150ms 100ms ease-in', style({ width: '!' }))]),
      query('.header-control-selected-header', [style({ opacity: 0 }), animate('150ms ease-in', style({ opacity: 1 }))]),
    ]),
  ]),
  transition(':leave', [
    sequence([
      query('.header-control-selected-header', [animate('100ms ease-in', style({ opacity: 0 }))]),
      query(':self', [animate('150ms 100ms ease-in', style({ width: '42px' }))]),
    ]),
  ]),
]);
