<!-- <ng-container
  *ngTemplateOutlet="
    appTpl;
    context: {
      user: user$ | async,
      availableGames: availableGames$ | async
    }
  "
></ng-container>

<ng-template
  #appTpl
  let-user="user"
  let-availableGames="availableGames"
>
  <div class="chat-messages-wrapper">
    <perfect-scrollbar
      (psScrollY)="onScroll($event)"
      [config]="{ wheelPropagation: false }"
    >
      <div class="chat-messages-container">
        <csgofast-chat-item
          *ngFor="let message of messages$ | async; trackBy: trackingFunction"
          [message]="message"
          [availableGames]="availableGames"
          [userId]="getUserId(user)"
        ></csgofast-chat-item>
      </div>
    </perfect-scrollbar>
  </div>
  <div class="pex"></div>
  <div
    class="scroller"
    (click)="scroll()"
    [ngClass]="{ 'scroller-visible': scrollerVisible }"
  >
    <span class="icomoon icon-arrow-up"></span>
  </div>
  <csgofast-message-input
    [user]="user"
    (sizeChanges)="scrollToBottom()"
    (addMessage)="sendMsg($event)"
  ></csgofast-message-input>
</ng-template> -->

<ng-container
  *ngTemplateOutlet="
    appTpl;
    context: {
      user: user$ | async,
      availableGames: availableGames$ | async,
      stickers: stickers$ | async
    }
  "
></ng-container>

<ng-template
  #appTpl
  let-user="user"
  let-availableGames="availableGames"
  let-stickers="stickers"
>
  <div class="chat-header">
    <button class="btn-close">
      <mat-icon
        svgIcon="close"
        (click)="onClose()"
      >
      </mat-icon>
    </button>
  </div>
  <ng-scrollbar>
    <div class="chat-body">
      <div class="chat-messages">
        <!-- <ng-container *ngFor="let message of messageList; trackBy: trackingFunction">
        <div class="chat-message">
          <div class="chat-message__avatar">
            <fast-ui-user-avatar [url]="message.user.avatar"></fast-ui-user-avatar>
          </div>
          <div class="chat-message__username">{{message.user.name}}:</div>
          <div class="chat-message__text">{{message.value}}</div>
          <div class="chat-message__time">{{message.createdAt | date: 'HH:mm:ss'}}</div>
        </div>
      </ng-container> -->

        <csgofast-chat-item
          *ngFor="let message of messages$ | async; trackBy: trackingFunction"
          [message]="message"
          [availableGames]="availableGames"
          [userId]="getUserId(user)"
        ></csgofast-chat-item>
      </div>
    </div>
  </ng-scrollbar>
  <div class="chat-footer">
    <ng-container *ngIf="user; else notAuthTemplate">
      <csgofast-message-input
        [user]="user"
        [stickers]="stickers"
        (sizeChanges)="scrollToBottom()"
        (addMessage)="sendMsg($event)"
      ></csgofast-message-input>
    </ng-container>
    <ng-template #notAuthTemplate>
      <div class="chat-not-auth">{{ 'GOFAST.SERVICE_MESSAGES.CHAT_AUTORIZATION_REQUIRED' | translate }}</div>
    </ng-template>
  </div>
</ng-template>
