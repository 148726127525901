import { Component, Inject, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'csgofast-offerwall-modal',
  templateUrl: './offerwall-modal.component.html',
  styleUrls: ['./offerwall-modal.component.scss'],
})
export class OfferwallModalComponent {
  public urlSafe: SafeResourceUrl | undefined;
  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly userId: number,
    private readonly sanitizer: DomSanitizer,
    private readonly dialogRef: MatDialogRef<OfferwallModalComponent>
  ) {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
      `https://www.ayetstudios.com/offers/web_offerwall/11300?external_identifier=${this.userId}`
    );
  }
  public onClose() {
    this.dialogRef.close();
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [OfferwallModalComponent],
  exports: [OfferwallModalComponent],
})
export class OfferwallModalComponentModule {}
