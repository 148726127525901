
export interface SeoStateModel {
  h1: {value: string, data: any};
  main: {value: string, data: any};
  faq: {value: string, data: any};
  description: {value: string, data: any};
  title: {value: string, data: any};
}

export const SEO_INITIAL_STATE: SeoStateModel = {
  h1: {value: '', data: null},
  main: {value: '', data: null},
  faq: {value: '', data: null},
  description: {value: '', data: null},
  title: {value: '', data: null},
};
