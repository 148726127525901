import {ChangeDetectorRef, OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {CurrencyPipe} from '@angular/common';

import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {ICurrencyRate} from '@dev-fast/types';
import {CurrencyService} from '@csgofast/core/currency-service';

@Pipe({
  name: 'appCurrencyTranslate',
  pure: false,
})
export class AppCurrencyTranslatePipe implements PipeTransform, OnDestroy {
  private currency!: ICurrencyRate;
  private readonly key = 'amount';
  private readonly destroyed$: Subject<void>;
  constructor(
    private currencyPipe: CurrencyPipe,
    private currencyService: CurrencyService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef
  ) {
    this.destroyed$ = new Subject<void>();
    this.currencyService.currency$
      .pipe(
        takeUntil(this.destroyed$),
        filter((val): val is ICurrencyRate => !!val)
      )
      .subscribe((currency: ICurrencyRate) => {
        this.currency = currency;
        this.cdr.markForCheck();
      });
  }
  //TODO поработать над пайпами и их типизацией
  public transform(text: any = '', params: any, ...args: any[]) {
    if (!this.currency) return ''
    const { prefix } = this.currency;
    if (params) {
      let value = params[this.key];
      if (value) {
        value = this.currencyService.convert(value);
        const result = this.currencyPipe.transform(value, '', prefix || '', args[2]);
        value = result && result.endsWith('.00') ? result.slice(0, -3) : result;
      }
      // else {
      //   value = this.currencyPipe.transform(0, '', prefix || '', args[2])?.split('.00')[0];
      // }
      return this.translateService.instant(text, { ...params, [this.key]: value });
    } else {
      return this.translateService.instant(text);
    }
  }

  public ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
