<div
  class="menu-item"
  *ngFor="let group of groups"
>
  <a
    [href]="group.link"
    [target]="'blank'"
  >
    <mat-icon [svgIcon]="group.type"></mat-icon>
  </a>
</div>
