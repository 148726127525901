import {Component, Inject, OnDestroy} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {P2pSellModifierModalComponent} from '../p2p-sell-modifier-modal/p2p-sell-modifier-modal.component';
import {P2pSellService} from '../../../p2p-sell.service';
import {Subject} from 'rxjs';

@Component({
  selector: 'csgofast-p2p-preview-sell-modifier-modal',
  templateUrl: './p2p-preview-sell-modifier-modal.component.html',
  styleUrls: ['./p2p-preview-sell-modifier-modal.component.scss'],
})
export class P2pPreviewSellModifierModalComponent implements OnDestroy {
  public previewDepositForm: FormGroup;
  private destroyed$: Subject<void>;

  constructor(
    private readonly dialogRef: MatDialogRef<P2pPreviewSellModifierModalComponent>,
    private readonly dialog: MatDialog,
    private readonly fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { id: number },
    private readonly moduleService: P2pSellService
  ) {
    this.destroyed$ = new Subject<void>();
    this.previewDepositForm = this.fb.group({ agree: false });

    // this.moduleService.getDepositModifier();
    // this.moduleService.depositModifier$.pipe(takeUntil(this.destroyed$)).subscribe((preview) => {
    //   const config = preview || { agree: false };
    //   this.previewDepositForm.patchValue({ ...config });
    // });
  }

  public onSubmit() {
    this.moduleService.setDepositModifier(this.previewDepositForm.value);

    this.dialogRef.close();
    this.dialog.open(P2pSellModifierModalComponent, {
      data: this.data,
    });
  }
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
