import { Component, OnInit } from '@angular/core';
import { ILRTop, ILRLeaderboards, EventsConfig } from '@dev-fast/types';
import { Observable } from 'rxjs';
import { NyAllEventService } from '../core/services/ny-all-event.service';
import { NyEventService } from '../core/services/ny-event.service';

@Component({
  selector: 'csgofast-ny-results-leaders',
  templateUrl: './ny-results-leaders.component.html',
  styleUrls: ['./ny-results-leaders.component.scss'],
})
export class NyResultsLeadersComponent {
  public topPlayers$: Observable<ILRTop[]> | undefined;
  public leaderboards$: Observable<ILRLeaderboards[]> | undefined;
  public eventHistoryName$: Observable<keyof EventsConfig | null> | undefined;

  constructor(private readonly nyEventService: NyEventService, private readonly nyAllEventService: NyAllEventService) {
    this.topPlayers$ = this.nyAllEventService.topPlayers$;
    this.leaderboards$ = this.nyAllEventService.leaderboards$;
    this.eventHistoryName$ = this.nyEventService.eventHistoryName$;
  }

  get EventKeyType() {
    return 1;
  }

  public topPlayers(topPlayers: ILRTop[]): ILRTop[] {
    return [topPlayers[1], topPlayers[0], topPlayers[2]];
  }
  public prizesCount(prizes: number): string {
    return prizes > 9 ? '9/9 + 1' : `${prizes}/9`;
  }
  public placeCount(place: number): string {
    return place > 998 ? '999+' : `${place}`;
  }
  public leaderBoards(leaderboards: ILRLeaderboards[]): ILRLeaderboards[] {
    return [...leaderboards].reverse();
  }
  public limbColor(user_place: number): string {
    switch (user_place) {
      case 0:
        return '#e1e1e2';
      case 1:
        return '#ffdb0c';
      case 2:
        return '#ed9100';
      default:
        return '#e1e1e2';
    }
  }
  public glowColor(user_place: number): string {
    switch (user_place) {
      case 0:
        return 'background: radial-gradient(50% 50% at 50% 50%, rgba(225, 225, 226, 0.26) 0%, rgba(225, 225, 226, 0) 100%);';
      case 1:
        return 'background: radial-gradient(50% 50% at 50% 50%, rgba(255, 219, 12, 0.26) 0%, rgba(255, 219, 12, 0) 100%);';

      case 2:
        return 'background: radial-gradient(50% 50% at 50% 50%, rgba(237, 145, 0, 0.26) 0%, rgba(237, 145, 0, 0) 100%);';

      default:
        return '#e1e1e2';
    }
  }
  public eventCurrencyColor(currency_color: string) {
    switch (currency_color) {
      case 'orange-key':
        return 'red';
      case 'green-key':
        return 'green';
      case 'blue-key':
        return 'blue';
      default:
        return 'red';
    }
  }
  public eventCurrencyType(event_type: string) {
    switch (event_type) {
      case 'ny_china':
        return 'pop';
      case 'ny':
        return 'cane';
      default:
        return 'cane';
    }
  }
}
