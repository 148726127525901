import {Affise, PromoCode} from '@dev-fast/types';

export class GetCurrentPromo {
  public static readonly type = '[Referrals] Get Current Promo';

}
export class AffiseEvent {
  public static readonly type = '[Referrals] Affise Event';
  constructor(public affise: Affise) {}
}
export class OpenPromoModal {
  public static readonly type = '[Referrals] Open Promo Modal';
  constructor(public code: PromoCode) {}
}
export class ActivatePromoCode {
  public static readonly type = '[Referrals] Activate Promo Code';
  constructor(public code: string) {}
}
export class ActivatePromoCodeSuccess {
  public static readonly type = '[Referrals] Activate Promo Code Complete';
  constructor(public code: PromoCode) {}
}
export class ClickPromoCode {
  public static readonly type = '[Referrals] Click Promo Code';
  constructor(public code: string) {}
}

export class ProceedPromoCode {
  public static readonly type = '[Referrals] Begin work with promocode';
  constructor(public code: PromoCode) {}
}
