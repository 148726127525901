import {Inject, Injectable} from '@angular/core';
// import { AuthService } from '@csgofast/auth';
import {CommonStoreService} from '@csgofast/core-state-common';
import {NotificationsService} from '@csgofast/core/notification-service';
import {GetLevelsRoadMap} from '@csgofast/core/state/account';
import {ClickOnLockedSide, LayoutState, SetLayoutType} from '@csgofast/core/state/layout';
import {GetLotteryItems} from '@csgofast/core/state/lottery';
import {OpenModal} from "@csgofast/core/state/modals";
import {NyAllEventService, NyEventService} from "@csgofast/modules/events";
import {ModalNames} from "@dev-fast/types";
import {Dispatch} from '@ngxs-labs/dispatch-decorator';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
// import { LayoutStateService } from '@csgofast/core/ny/layot';
// import { UserStoreService } from '@csgofast/core/ny/user-store';
// import { ILocales, IUserDetailed, Themes } from '@dev-fast/types';
// import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GofastLayoutService {
  @Select(LayoutState.isFadeSides)
  public readonly isFadeSides$!: Observable<boolean>;

  public init() {
    this.commonService.init();
    // this.nyEventService.initEvent();
    // // this.nyEventService.getMotivator();
    //
    // this.nyAllEventService.initEvent();
    // // this.nyAllEventService.getTickets();
    this.notificationsService.getNotifications();
    // this.getLotteryItems();
    if(!this.isServer) {
      this.getLevelsRoadMap();
    }
  }

  constructor(
    private commonService: CommonStoreService,
    private readonly notificationsService: NotificationsService,
    private readonly nyEventService:NyEventService,
    private readonly nyAllEventService:NyAllEventService,
    @Inject('isServer') private isServer: boolean,
  ) {
    // this.theme$ = this.layoutStateService.themeSelector$;
    // this.user$ = this.userStateService.userSelector$;
    // this.locales$ = this.layoutStateService.availableLocalesSelector$;
  }
  // public initLayoutState = () => this.layoutStateService.init();
  // public switchTheme(theme: Themes) {
  //   this.layoutStateService.changeTheme(theme);
  // }
  // public init = () => {
  //   this.userStateService.getCurrentUser();

  //   this.layoutStateService.setInitialTheme();
  //   this.layoutStateService.getAvailableLocales();
  // };
  // public login = () => {
  //   this.authService.openLoginProvidersModal();
  // };
  // public logout = () => {
  //   this.authService.logout();
  // };
  @Dispatch() public clickOnLockedSide = () => new ClickOnLockedSide();
  @Dispatch() public setLayoutType = (type: string) => new SetLayoutType(type);
  @Dispatch() public getLotteryItems = () => new GetLotteryItems();
  @Dispatch() public getLevelsRoadMap = () => new GetLevelsRoadMap();
  @Dispatch() public openModal = (modal:ModalNames) => new OpenModal(modal);

}
