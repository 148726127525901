import { Injectable } from '@angular/core';
import {UserState} from "@csgofast/core/state/user-store";
import {IUserDetailed, Permissions} from "@dev-fast/types";
import {Select} from "@ngxs/store";
import {Observable} from "rxjs";

@Injectable()
export class HasPermissionService {
  @Select(UserState.permissions)
  public readonly permissions$!: Observable<Permissions[]>;
}
