// FIXME заменить на ngx-scrollbar (уже установлен на проекте)
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  NgModule,
  OnDestroy,
  Output
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MenuItemComponent} from './menu-item/menu-item.component';
import {ExpandedAreaComponent} from './expanded-area/expanded-area.component';
import {GameItemComponent} from './game-item/game-item.component';
import {MatIconModule} from '@angular/material/icon';
import {GameMode, GameStatus, IGame} from '@dev-fast/types';
import {areaAnimation} from './game-menu.animation';
import {NavigationEnd, Router, RouterModule} from '@angular/router';
import {SocialGroupsComponentModule} from '../social-groups/social-groups.component';
import {AppCurrencyModule} from '@csgofast/shared/pipes';
import {TagComponentModule} from '../tag/tag.component';
import {filter, Subscription} from "rxjs";
import {LanguageService} from "@csgofast/core/language-service";
import {EnvironmentService} from "@csgofast/core/environment-service";

export const newGames: Record<string, boolean> = { cases: true, 'case-battle': true };
@Component({
  selector: 'fast-ui-game-menu',
  templateUrl: './game-menu.component.html',
  styleUrls: ['./game-menu.component.scss'],
  animations: [areaAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GameMenuComponent implements OnDestroy{
  private subscriptions = new Subscription();
  public environment = this.environmentService.environments;

  @Input() public state: Record<string, GameStatus>;
  //FIXME в стейт или сервис по хорошему
  @Input() public set availableGames(payload: IGame[] | null) {
    if (!payload) return;
    this.gamesDict = {};
    payload.filter(value => this.environment.MODULES.games.includes(value.key)).forEach((game) => {
      if (this.gamesDict[game.mode]) {
        this.gamesDict[game.mode]?.push(game);
      } else {
        this.gamesDict[game.mode] = [game];
      }
    });
    this.modes = (Object.values(GameMode)).filter(value => {return Object.keys(this.gamesDict).includes(value)});
  }
  @Input() public currentGame: IGame | undefined;
  @Input() public online: number | null;
  @Input() public isShowFullView: boolean | null;
  @Output() public selectionChange: EventEmitter<IGame>;
  @Output() public toggleFullView: EventEmitter<boolean>;
  public modes: GameMode[];
  public activeUrl: string = '/';
  public gamesDict: Partial<Record<GameMode, IGame[]>>;
  public newGames = newGames;
  constructor(
    @Inject('isServer') public isServer: boolean,
    private environmentService: EnvironmentService,
    private cdr: ChangeDetectorRef,
    private router: Router,
  ) {
    this.environment = this.environmentService.environments;
    this.availableGames = [];
    this.isShowFullView = false;
    this.modes = [];
    this.state = {};
    this.gamesDict = {};
    this.online = 0;
    this.selectionChange = new EventEmitter(false);
    this.toggleFullView = new EventEmitter(false);
    this.subscribeEmitters();
  }

  public onSelectGame = (game: IGame): void => this.selectionChange.emit(game);

  public showFullView = (): void => {
    this.toggleFullView.emit(!this.isShowFullView);
  };

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  subscribeEmitters(): void {
    this.subscriptions.add(
      this.router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe(
          (event) => {
            if (event instanceof NavigationEnd) {
              this.activeUrl = LanguageService.getBaseUrl(this.router.url.split('?')[0]);
              this.cdr.markForCheck();
            }
          }
        )
    )
  }
}

@NgModule({
  imports: [
    CommonModule,
    TagComponentModule,
    MatIconModule,
    RouterModule,
    SocialGroupsComponentModule,
    PerfectScrollbarModule,
    AppCurrencyModule,
  ],
  declarations: [GameMenuComponent, MenuItemComponent, ExpandedAreaComponent, GameItemComponent],
  exports: [GameMenuComponent],
})
export class GameMenuComponentModule {}
