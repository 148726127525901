<ng-container *ngIf="presents">
  <div class="gifts-grid--container">
    <csgofast-gift-card
      *ngFor="let present of presents; let i = index"
      [present]="present"
      [cardNumber]="i+1"
      [eventName]="eventName"
      [isEventComplete]="isEventComplete"
    >
    </csgofast-gift-card>
  </div>
</ng-container>
