import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {IError, PromoCode} from '@dev-fast/types';
import { TranslateService } from "@ngx-translate/core";
import {ActionsExecuting} from '@ngxs-labs/actions-executing';

@Component({
  selector: 'csgofast-promo',
  templateUrl: './promo.component.html',
  styleUrls: ['./promo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PromoComponent {
  @Input() public activationIsExecuting: ActionsExecuting | undefined;
  @Input() public promoData:
    | {
        activationErr: IError | null;
        activePromoCode: PromoCode | null;
        promoActivated: boolean;
      }
    | null
    | undefined;

  public opened: boolean;
  public code: string;
  public defaultCode = 'CSGOFAST';
  @Output() public activateEmit: EventEmitter<string>;
  @Output() public getPromo: EventEmitter<void>;

  public get codeValid(): boolean {
    return this.code.length > 2;
  }
  constructor(private translate: TranslateService) {
    this.opened = false;
    this.code = '';
    this.activateEmit = new EventEmitter<string>();
    this.getPromo = new EventEmitter<void>();
    // FIXME: Translation won't update properly
    translate.setTranslation('en_US', {
      PROMOCODE_WIDGET: {
        DEFAULT_CODE_MESSAGE: "or use this"
      }
    }, true);
    translate.setTranslation('ru_RU', {
      PROMOCODE_WIDGET: {
        DEFAULT_CODE_MESSAGE: "или используй этот"
      }
    }, true);
  }

  public togglePromocode(): void {
    this.opened = !this.opened;
    if (this.opened) this.getPromo.emit();
  }
  public closePromocode(): void {
    this.opened = false;
  }
  public onActivate(): void {
    if (this.codeValid) this.activateEmit.next(this.code);
  }
  public onCodeClick(event: Event): void {
    event.preventDefault();
    this.code = this.defaultCode;
  }
  public isBonusCodeEntered(): boolean {
    return this.code === this.defaultCode;
  }
  public onActivateErr(msg: string): string{
    // TODO Add other keys. Add switch loop
    let err_dict = undefined
    if(msg ==='Too many requests, try again later!')
      err_dict = 'PROMOCODE.ERRORS.REQUEST_LIMIT_REACHED'
    return err_dict? err_dict : msg
  }
}
