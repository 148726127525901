import { CommonModule } from "@angular/common";
import { Component, NgModule } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { RouterModule } from "@angular/router";
import { MatIconModule } from '@angular/material/icon';
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: "csgofast-steam-error-modal",
  templateUrl: "./steam-error-modal.component.html",
  styleUrls: ["./steam-error-modal.component.scss"],
})
export class SteamErrorModalComponent {
  constructor(
    private readonly dialogRef: MatDialogRef<SteamErrorModalComponent>
  ) {}

  public close(): void {
    this.dialogRef.close();
  }
}
@NgModule({
  imports: [CommonModule, RouterModule,MatIconModule, TranslateModule],
  declarations: [SteamErrorModalComponent],
  exports: [SteamErrorModalComponent],
})
export class SteamErrorModalComponentModule {}
