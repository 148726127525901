import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NyResultsShopComponent} from "./ny-results-shop.component";


const routes: Routes = [
  {
    path: '',
    component: NyResultsShopComponent,
    pathMatch: 'full',
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NyResultsShopRoutingModule {}
