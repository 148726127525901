import {GameMode} from '../enums';
import {IGame} from '../models';

//FIXME при сборке

export const GAMES: IGame[] = [
  {
    key: 'classic',
    mode: GameMode.PVP,
    title: 'Classic',
    description: 'Classic Game - the game for those, who like playing for the high stakes.',
  },
  {
    key: 'fast',
    mode: GameMode.PVP,
    title: 'Fast',
    description: 'Fast game - the game to try your luck instantly. The winner gets a general deposit of 3 players.',
  },
  {
    key: 'match-bets',
    mode: GameMode.PVP,
    title: 'Esports bets',
    description: 'Place your bets on real cybersports matches and win real money.',
  },
  {
    key: 'case-battle',
    mode: GameMode.PVP,
    title: 'Case battle',
    description: 'Case Battle - is modern world',
  },
  {
    key: 'hilo',
    mode: GameMode.PVE,
    title: 'Hi Lo',
    description: 'Guess if the next card will be higher or lower.',
  },
  {
    key: 'crash',
    mode: GameMode.PVE,
    title: 'Crash',
    description: 'Crash - is a mode, where players have to press Stop button before Crash comes.',
  },
  {
    key: 'double',
    mode: GameMode.PVE,
    title: 'Double',
    description: 'Double Game - is a mode of a game, where gamers bet on black, red or green slots.',
  },
  {
    key: 'x50',
    mode: GameMode.PVE,
    title: 'X50',
    description: 'Game mode x50 allows user significantly increase his bet and inventory!',
  },
  {
    key: 'baccarat',
    mode: GameMode.PVE,
    title: 'Baccarat',
    description: 'Tower Game is a classic mode to climb to the top of the tower and collect a coin chest guessing the winning sectors.',
  },
  {
    key: 'craft',
    mode: GameMode.SOLOPVE,
    title: 'Craft',
    description: 'Craft - gives all users a great opportunity to obtain their desired items below their market value.',
  },
  {
    key: 'fortune-wheel',
    mode: GameMode.SOLOPVE,
    title: 'Wheel',
    description: 'Wheel of Fortune - is a mode of game where you can spin the wheel of fortune and get chance to win great prizes!',
  },
  {
    key: 'tower',
    mode: GameMode.SOLOPVE,
    title: 'Tower',
    description: 'Tower Game is a classic mode to climb to the top of the tower and collect a coin chest guessing the winning sectors.',
  },
  {
    key: 'cases',
    mode: GameMode.SOLOPVE,
    title: 'Cases',
    description: 'Cases - is a fun mode where you can spend coins to open cases and win great skins. Everyone loves it!',
  },
  {
    key: 'slot',
    mode: GameMode.SOLOPVE,
    title: 'Slots',
    description:
      'Slot Game - classic "spin to win" slot game. Select the lines to bet on, enter the bet amount ' +
      'and break the bank with the wild combinations!',
  },
  {
    key: 'poggi',
    mode: GameMode.SOLOPVE,
    title: 'Poggi',
    description:
      'Poggi is a quirky slots game based on our beloved CS:GO. Choose your side - CT or T, choose your bet amount and click "Spin". The winning depends on the arrangement of the elements on the dropped lines and additional bonuses.',
  },
];
