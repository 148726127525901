<div
  class="inventory__wrapper"
  *ngIf="config"
>
  <div class="inventory__participation-panel">
    <div class="participation-title">Your bet:</div>
    <div class="participation-title participation-title--right">{{ totalPrice | appCurrency }}</div>
    <button
      class="participation-button participation-button--confirm btn btn-primary"
      [disabled]="participationDisabled"
      (click)="onParticipate()"
    >
      <span class="participation-button-text">
        <span>Participate</span>
        <span
          class="participation-timer"
          *ngIf="config.name === 'classic'"
          >:&nbsp;
          <span *ngIf="liteState">
            <fast-ui-countdown
              [until]="liteState.timer"
              [reset]="liteState.freeze"
              [minutes]="0.92"
            >
            </fast-ui-countdown>
          </span>
        </span>
      </span>
    </button>
  </div>
  <div class="inventory__info-panel">
    <div class="participation-description">
      <ng-container *ngIf="config.max_bet; else noMaxBetTemplate">
        {{ 'Bet' }}: {{ config.min_bet * 100 | appCurrency }} - {{ config.max_bet * 100 | appCurrency }}
      </ng-container>
      <ng-template #noMaxBetTemplate> {{ 'Min. bet' }}: {{ config.min_bet * 100 | appCurrency }} </ng-template>
    </div>
    <div
      class="participation-description participation-description--right"
      [ngClass]="{
        'text-color-success': selectedItems.length <= config.max_items_per_trade,
        'text-color-alert': selectedItems.length > config.max_items_per_trade
      }"
    >
      {{ 'Items' | translate }}: {{ selectedItems.length }} / {{ config.max_items_per_trade }}
    </div>
  </div>
  <div class="inventory__items-wrapper">
    <div class="inventory__items-list">
      <ng-container *ngIf="participatedItems && participatedItems.length > 0">
        <ng-container *ngFor="let item of participatedItems; trackBy: trackingFunction">
          <fast-ui-skin-item
            [name]="item.name"
            [icon]="item.icon"
            [color]="item.color"
            [price]="item.price"
          >
          </fast-ui-skin-item>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="participated && participated.items.length > 0">
        <ng-container *ngFor="let item of participated.items; trackBy: trackingFunction">
          <fast-ui-skin-item
            [name]="item.name"
            [icon]="item.icon"
            [color]="item.color"
            [price]="item.price"
            [delay]="participated.timeout * 1000"
          >
          </fast-ui-skin-item>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="selectedItems && selectedItems.length > 0">
        <ng-container *ngFor="let item of selectedItems; trackBy: trackingFunction">
          <fast-ui-skin-item
            [name]="item.name"
            [icon]="item.icon"
            [color]="item.color"
            [id]="item.id"
            [price]="item.price"
            (skinClick)="clickOnSkinEmit.emit($event)"
            class="selected"
          >
          </fast-ui-skin-item>
        </ng-container>
      </ng-container>
      <!-- <div class="inventory-items-list readonly d-none"></div> -->
    </div>
    <div class="participation-items-holder">
      <div
        class="participation-items-holder_item participation-items-holder_item--required"
        *ngFor="let item of minItemsPerTrade"
      ></div>
      <div
        class="participation-items-holder_item"
        *ngFor="let item of maxItemsPerTrade"
      ></div>
    </div>
    <!--  FIXME Добавить ключ-->
  </div>
  <div class="inventory__control-panel">
    <button
      class="btn btn-outline inventory__control-panel_btn"
      [disabled]="clearDisabled"
      (click)="onClear()"
    >
      Clean
    </button>
  </div>
</div>
