<nav
  class="menu"
  [ngClass]="{ menu_opened: isShowFullView }">
  <button
    class="menu__collapse"
    (click)="showFullView()">
    <mat-icon
      class="menu__collapse-icon"
      [svgIcon]="'chevron-double-right'"
    ></mat-icon>
  </button>
  <perfect-scrollbar
    [config]="{ suppressScrollX: true }"
    [scrollIndicators]="false">
    <div
      [class]="'menu__group menu__group_' + mode"
      *ngFor="let mode of modes">
      <ng-container *ngIf="gamesDict[mode];">
        <fast-ui-menu-item
          *ngFor="let game of gamesDict[mode]"
          [game]="game"
          [status]="state[game.key]"
          [isNew]="newGames[game.key]"
          [isShowFullView]="isShowFullView"
          [isActive]="activeUrl === '/game/' + game.key"
        >
        </fast-ui-menu-item>
      </ng-container>
    </div>
    <!--  FIXME Ключи онлайна текст и цифра-->
    <div
      class="menu__online"
      *ngIf="online"
    >
    <span class="menu__online_value">
      <span class="menu__online_label"> Players online </span>
      {{ online }}
    </span>
    </div>
    <div class="menu__socials">
      <fast-ui-social-groups></fast-ui-social-groups>
    </div>
  </perfect-scrollbar>

  <!--  <ng-container *ngIf="isShowFullView">-->
  <!--    <div-->
  <!--      class="overlay"-->
  <!--      (click)="showFullView()"-->
  <!--    ></div>-->
  <!--  </ng-container>-->

  <!--  <div-->
  <!--    class="dropdown-host"-->
  <!--    (click)="showFullView()"-->
  <!--    @areaAnimation-->
  <!--    *ngIf="isShowFullView"-->
  <!--  >-->

  <!--    <fast-ui-expanded-area-->
  <!--      [itemsDictionary]="gamesDict"-->
  <!--      class="dropdown"-->
  <!--    >-->
  <!--    </fast-ui-expanded-area>-->
  <!-- <div class="header-settings-dropdown">dropdown</div> -->

  <!--  </div>-->
</nav>
