<ng-container *ngIf="$user | async as user; else loading">
  <span
    class="icomoon icon-close close"
    (click)="close()"
  ></span>
  <div class="wrapper">
    <div class="left-block">
      <div class="user-country">
        <img
          class="user-country-img"
          *ngIf="user.country"
          [src]="getUserCountry(user)"
         alt=""/>
      </div>
      <fast-ui-user-avatar
        [noChevron]="true"
        [url]="user.avatar"
        [level]="user.experience.level"
        [type]="user.profileType"
        [levelLabelOn]="true"
      ></fast-ui-user-avatar>
    </div>
    <div class="right-block">
      <div class="user-name">{{ user.name }}</div>
      <div class="info info-wrapper">
        <div class="info-labels">
          <span class="info-label">ID:</span>
          <span class="info-label">{{ 'USER_PANEL.FIRST_GAME' | translate }}:</span>
        </div>
        <div class="info-values">
          <span class="info-value">{{ user.id }}</span>
          <span class="info-value">{{ user.activity.registered | date: 'dd.MM.yyyy' }}</span>
        </div>
      </div>
      <div
        class="profile-link"
        *ngIf="user.providers.length"
      >
        <ng-container *ngFor="let provider of user.providers">
          <a
            *ngIf="provider.name === socialTypes.Vkontakte"
            href="{{ 'https://vk.com/id' + provider.id }}"
            [target]="'blank'"
            class="link"
            (click)="close()"
          >
            <mat-icon [svgIcon]="socialTypes.Vkontakte"></mat-icon>
          </a>
          <a
            *ngIf="provider.name === socialTypes.Steam"
            href="{{ 'https://steamcommunity.com/profiles/' + provider.id }}"
            [target]="'blank'"
            class="link"
            (click)="close()"
          >
            <mat-icon [svgIcon]="socialTypes.Steam"></mat-icon>
          </a>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="activities">
    <ng-container *ngIf="!user.privacy.statisticsIsHidden; else hiddenStatTemplate">
      <csgofast-best-card
        [item]="item"
        [game]="getGameTitle(item.key) | myAsync"
        *ngFor="let item of user.statistics"
      >
      </csgofast-best-card>
    </ng-container>
    <ng-template #hiddenStatTemplate>
      <csgofast-best-card
        class="blur"
        [item]="hiddenItem"
        [game]="getGameTitle(hiddenItem.key) | myAsync"
        *ngFor="let item of hiddenItemsArr"
      ></csgofast-best-card>
      <div class="statistics-hidden-wrapper">
        <h2>{{ 'USER_PANEL.STATISTIC_HIDDEN' | translate }}</h2>
      </div>
    </ng-template>
  </div>
</ng-container>
<ng-template #loading>
  <span
    class="icomoon icon-close close"
    (click)="close()"
  ></span>
  <div class="wrapper">
    <div class="left-block">
      <div class="user-country">
        <ngx-skeleton-loader
          animation="progress-dark"
          appearance="circle"
          [theme]="{
            width: '20px',
            height: '20px',
            margin: '0'
          }"
        ></ngx-skeleton-loader>
      </div>
      <ngx-skeleton-loader
        animation="progress-dark"
        appearance="circle"
        [theme]="{
          width: '100px',
          height: '100px'
        }"
      ></ngx-skeleton-loader>
    </div>
    <div class="right-block">
      <div class="user-name">
        <ngx-skeleton-loader
          animation="progress-dark"
          [theme]="{
            width: '200px',
            'border-radius': '0',
            height: '16px',
            margin: '0'
          }"
        ></ngx-skeleton-loader>
      </div>
      <div class="info-wrapper">
        <div class="info-labels">
          <ngx-skeleton-loader
            animation="progress-dark"
            [theme]="{
              width: '70px',
              'border-radius': '0',
              height: '14px',
              margin: '0'
            }"
          ></ngx-skeleton-loader>
          <ngx-skeleton-loader
            animation="progress-dark"
            [theme]="{
              width: '125px',
              'border-radius': '0',
              height: '14px',
              margin: '0'
            }"
          ></ngx-skeleton-loader>
        </div>
        <div class="info-values">
          <ngx-skeleton-loader
            animation="progress-dark"
            [theme]="{
              width: '70px',
              'border-radius': '0',
              height: '14px',
              margin: '0'
            }"
          ></ngx-skeleton-loader>
          <ngx-skeleton-loader
            animation="progress-dark"
            [theme]="{
              width: '125px',
              'border-radius': '0',
              height: '14px',
              margin: '0'
            }"
          ></ngx-skeleton-loader>
        </div>
      </div>
      <div class="profile-link">
        <ngx-skeleton-loader
          animation="progress-dark"
          [theme]="{
            width: '100px',
            'border-radius': '0',
            height: '14px',
            margin: '0'
          }"
        ></ngx-skeleton-loader>
      </div>
    </div>
  </div>
</ng-template>
