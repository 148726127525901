import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router, UrlTree } from '@angular/router';
import { UserApiService } from '@dev-fast/backend-services';
import { map, Observable, take, tap } from 'rxjs';
import { AuthService } from '../../auth.service';

@Injectable()
export class IsAuthGuard implements CanActivate, CanLoad {
  constructor(private authService: AuthService, private router: Router, private readonly apiUserService: UserApiService) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // return this.authService.isAuth$.pipe(
    //   tap((value) => {
    //     if (value) return value;
    //     return this.router.navigate(['/game/classic']);
    //   })
    // );
    return this.apiUserService
      .getMeProfile({ detailed: false })
      .pipe(take(1))
      .pipe(
        map((res) => {
          return !!res;
        })
      );
  }
  canLoad_old(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.isAuth$.pipe(
      tap((value) => {
        if (value) return value;
        return this.router.navigate(['']);
      })
    );
  }

  canLoad(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.isAuth$.pipe(
      tap((value) => {
        if (value) return value;
        return this.router.navigate(['/game/classic']);
      })
    );
  }
}
