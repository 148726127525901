import { Component, ContentChild, Directive, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { fromEvent, Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { DOCUMENT } from "@angular/common";
import { ILocales } from "@dev-fast/types";

@Directive({
  // FIXME Посмотреть почему ругается на название директивы
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[language-dropdown]',
})
export class LanguageDropdownDirective {
  constructor (
    public readonly templateRef: TemplateRef<unknown>,
  ) {
  }
}

@Component({
  selector: 'csgofast-language-dropdown',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss'],
})
export class LanguageDropDownComponent implements OnDestroy {

  @Input() public items: ILocales[];
  @Input() public selected!: ILocales;
  @Output() public changeSelect: EventEmitter<ILocales>;
  @ContentChild(LanguageDropdownDirective) content!: LanguageDropdownDirective;

  public readonly opened$: Subject<boolean>;
  private destroyed$: Subject<void>;
  private opened: boolean;


  constructor (
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    this.items = [];
    this.opened$ = new Subject<boolean>();
    this.destroyed$ = new Subject();
    this.changeSelect =  new EventEmitter<ILocales>();
    this.opened = false;
    this.subscribeEmitters();
    this.opened$.pipe(
      takeUntil(this.destroyed$),
    ).subscribe(
      (opened: boolean) => this.opened = opened,
    );
  }


  public ngOnDestroy (): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public toggleDropdown (): void {
    this.opened$.next(!this.opened);
    if (this.opened) {
      this.subscribeEmitters();
    }
  }

  public select (item: any): void {
    this.changeSelect.emit(item);
    this.opened$.next(false);
  }

  private subscribeEmitters (): void {
    fromEvent(this.document, 'click').pipe(
      takeUntil(this.opened$),
      filter((event: any) => !(event.target.closest('csgofast-language-dropdown') instanceof HTMLElement)),
    ).subscribe(
      () => this.opened$.next(false),
    );
  }
}
