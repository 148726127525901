import { Inject, Injectable } from '@angular/core';
import { BaseType } from '@dev-fast/types';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { LocalStorageModel } from './local-storage.model';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) {}
  public get = <K extends keyof LocalStorageModel>(key: K): LocalStorageModel[K] => {
    return this.storage.get(key);
  };
  public set = <T extends LocalStorageModel, K extends keyof LocalStorageModel>(key: K, value: T[K]) => {
    this.storage.set(key, value);
  };
  public remove = (key: keyof LocalStorageModel) => {
    this.storage.remove(key);
  };
  public removeMultiple = (keys: (keyof LocalStorageModel)[]) => {
    keys.forEach((value) => this.remove(value));
  };
  public clear = () => {
    this.storage.clear();
  };
  public clearExcept = (exceptList: (keyof LocalStorageModel)[]) => {
    // хотел написать обратную логику, но с другой стороны тоже нужно или проходить все гетом или удалять каждое поле по одному
    // поэтому сделал так предположив что exceptList в теории должен быть не большим в отличие от всего что хранится сторадже
    const values: BaseType<keyof LocalStorageModel, LocalStorageModel[keyof LocalStorageModel] | undefined>[] = exceptList.map((key) => ({
      name: key,
      value: this.get(key),
    }));
    this.storage.clear();
    if (values.length) {
      values.forEach((el) => {
        if (el.value !== undefined) this.set(el.name, el.value);
      });
    }
  };
}
