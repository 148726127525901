export enum LangKeys {
  EN = 'EN',
  RU = 'RU',
  ES = 'ES',
  NB = 'NB',
  PL = 'PL',
  PT = 'PT',
  TH = 'TH',
  TR = 'TR',
  VI = 'VI',
  ZH = 'ZH',
}
