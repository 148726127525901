import {IconConfig} from './icon.model';

export const iconsMoon: IconConfig[] = [
  {
    name: 'account',
    path: 'assets/img/mat-icons/icomoon/account.svg',
  },
  {
    name: 'arrow-left',
    path: 'assets/img/mat-icons/icomoon/arrow-left.svg',
  },
  {
    name: 'number-four',
    path: 'assets/img/mat-icons/numbers/four.svg',
  },
  {
    name: 'number-zero',
    path: 'assets/img/mat-icons/numbers/zero.svg',
  },
  {
    name: 'arrow-up',
    path: 'assets/img/mat-icons/icomoon/arrow-up.svg',
  },
  {
    name: 'baccarat-mini',
    path: 'assets/img/mat-icons/icomoon/baccarat-mini.svg',
  },
  {
    name: 'balance',
    path: 'assets/img/mat-icons/icomoon/balance.svg',
  },
  {
    name: 'bitcoin-logo',
    path: 'assets/img/mat-icons/icomoon/bitcoin-logo.svg',
  },
  {
    name: 'bonus',
    path: 'assets/img/mat-icons/icomoon/bonus.svg',
  },
  {
    name: 'cases-mini',
    path: 'assets/img/mat-icons/icomoon/cases-mini.svg',
  },
  {
    name: 'cashback',
    path: 'assets/img/mat-icons/icomoon/cashback.svg',
  },
  {
    name: 'cashback-lock',
    path: 'assets/img/mat-icons/icomoon/cashback-lock.svg',
  },
  {
    name: 'classic-mini',
    path: 'assets/img/mat-icons/icomoon/classic-mini.svg',
  },
  {
    name: 'close',
    path: 'assets/img/mat-icons/icomoon/close.svg',
  },
  {
    name: 'craft-mini',
    path: 'assets/img/mat-icons/icomoon/craft-mini.svg',
  },
  {
    name: 'crash-mini',
    path: 'assets/img/mat-icons/icomoon/crash-mini.svg',
  },
  {
    name: 'credit-card-logo',
    path: 'assets/img/mat-icons/icomoon/credit-card-logo.svg',
  },
  {
    name: 'csgo',
    path: 'assets/img/mat-icons/icomoon/csgo.svg',
  },
  {
    name: 'currency',
    path: 'assets/img/mat-icons/icomoon/currency.svg',
  },
  {
    name: 'currency_2',
    path: 'assets/img/mat-icons/icomoon/currency_2.svg',
  },
  {
    name: 'customize-settings',
    path: 'assets/img/mat-icons/icomoon/customize-settings.svg',
  },
  {
    name: 'dota',
    path: 'assets/img/mat-icons/icomoon/dota.svg',
  },
  {
    name: 'double-mini',
    path: 'assets/img/mat-icons/icomoon/double-mini.svg',
  },
  {
    name: 'error',
    path: 'assets/img/mat-icons/icomoon/error.svg',
  },
  {
    name: 'errors',
    path: 'assets/img/mat-icons/icomoon/errors.svg',
  },
  {
    name: 'facebook',
    path: 'assets/img/mat-icons/icomoon/facebook.svg',
  },
  {
    name: 'fast-mini',
    path: 'assets/img/mat-icons/icomoon/fast-mini.svg',
  },
  {
    name: 'faucet',
    path: 'assets/img/mat-icons/icomoon/faucet.svg',
  },
  {
    name: 'faucet-x2',
    path: 'assets/img/mat-icons/icomoon/faucet-x2.svg',
  },
  {
    name: 'faucet-x6',
    path: 'assets/img/mat-icons/icomoon/faucet-x6.svg',
  },
  {
    name: 'fortune-wheel-mini',
    path: 'assets/img/mat-icons/icomoon/fortune-wheel-mini.svg',
  },
  {
    name: 'garbage',
    path: 'assets/img/mat-icons/icomoon/garbage.svg',
  },
  {
    name: 'gift',
    path: 'assets/img/mat-icons/icomoon/gift.svg',
  },
  {
    name: 'google',
    path: 'assets/img/mat-icons/icomoon/google.svg',
  },
  {
    name: 'hilo-mini',
    path: 'assets/img/mat-icons/icomoon/hilo-mini.svg',
  },
  {
    name: 'infinity',
    path: 'assets/img/mat-icons/icomoon/infinity.svg',
  },
  {
    name: 'level-star',
    path: 'assets/img/mat-icons/icomoon/level-star.svg',
  },
  {
    name: 'login',
    path: 'assets/img/mat-icons/icomoon/login.svg',
  },
  {
    name: 'logo-baccarat',
    path: 'assets/img/mat-icons/icomoon/logo-baccarat.svg',
  },
  {
    name: 'logo-match-bets',
    path: 'assets/img/mat-icons/icomoon/logo-bets.svg',
  },
  {
    name: 'logo-cases',
    path: 'assets/img/mat-icons/icomoon/logo-cases.svg',
  },
  {
    name: 'logo-case-battle',
    path: 'assets/img/mat-icons/icomoon/logo-case-battle.svg',
  },
  {
    name: 'logo-classic',
    path: 'assets/img/mat-icons/icomoon/logo-classic.svg',
  },
  {
    name: 'logo-craft',
    path: 'assets/img/mat-icons/icomoon/logo-craft.svg',
  },
  {
    name: 'logo-crash',
    path: 'assets/img/mat-icons/icomoon/logo-crash.svg',
  },
  {
    name: 'logo-double',
    path: 'assets/img/mat-icons/icomoon/logo-double.svg',
  },
  {
    name: 'logo-fast',
    path: 'assets/img/mat-icons/icomoon/logo-fast.svg',
  },
  {
    name: 'logo-hilo',
    path: 'assets/img/mat-icons/icomoon/logo-hilo.svg',
  },
  {
    name: 'logo-slot',
    path: 'assets/img/mat-icons/icomoon/logo-slot.svg',
  },
  {
    name: 'logo-tower',
    path: 'assets/img/mat-icons/icomoon/logo-tower.svg',
  },
  {
    name: 'logo-poggi',
    path: 'assets/img/mat-icons/icomoon/logo-poggi.svg',
  },
  {
    name: 'logo-fortune-wheel',
    path: 'assets/img/mat-icons/icomoon/logo-wheel.svg',
  },
  {
    name: 'logo-x50',
    path: 'assets/img/mat-icons/icomoon/logo-x50.svg',
  },
  {
    name: 'logout',
    path: 'assets/img/mat-icons/icomoon/logout.svg',
  },
  {
    name: 'minus-circle',
    path: 'assets/img/mat-icons/icomoon/minus-circle.svg',
  },
  {
    name: 'new-referral',
    path: 'assets/img/mat-icons/icomoon/new-referral.svg',
  },
  {
    name: 'notifications',
    path: 'assets/img/mat-icons/icomoon/notifications.svg',
  },
  {
    name: 'notifications-check',
    path: 'assets/img/mat-icons/icomoon/notifications-check.svg',
  },
  {
    name: 'percent-block',
    path: 'assets/img/mat-icons/icomoon/percent-block.svg',
  },
  {
    name: 'percent-block-back',
    path: 'assets/img/mat-icons/icomoon/percent-block-back.svg',
  },
  {
    name: 'player-play',
    path: 'assets/img/mat-icons/icomoon/player-play.svg',
  },
  {
    name: 'plus-circle',
    path: 'assets/img/mat-icons/icomoon/plus-circle.svg',
  },
  {
    name: 'pubg',
    path: 'assets/img/mat-icons/icomoon/pubg.svg',
  },
  {
    name: 'qiwi-logo',
    path: 'assets/img/mat-icons/icomoon/qiwi-logo.svg',
  },
  {
    name: 'quest',
    path: 'assets/img/mat-icons/icomoon/quest.svg',
  },
  {
    name: 'referral',
    path: 'assets/img/mat-icons/icomoon/referral.svg',
  },
  {
    name: 'referrals',
    path: 'assets/img/mat-icons/icomoon/referrals.svg',
  },
  {
    name: 'refresh',
    path: 'assets/img/mat-icons/icomoon/refresh.svg',
  },
  {
    name: 'send',
    path: 'assets/img/mat-icons/icomoon/send.svg',
  },
  {
    name: 'settings',
    path: 'assets/img/mat-icons/icomoon/settings.svg',
  },
  {
    name: 'shop-logo',
    path: 'assets/img/mat-icons/icomoon/shop-logo.svg',
  },
  {
    name: 'shop-logo',
    path: 'assets/img/mat-icons/icomoon/shop-logo.svg',
  },
  {
    name: 'slots-mini',
    path: 'assets/img/mat-icons/icomoon/slots-mini.svg',
  },
  {
    name: 'smile',
    path: 'assets/img/mat-icons/icomoon/smile.svg',
  },
  {
    name: 'steam',
    path: 'assets/img/mat-icons/icomoon/steam.svg',
  },
  {
    name: 'steam-logo',
    path: 'assets/img/mat-icons/icomoon/steam-logo.svg',
  },
  {
    name: 'success',
    path: 'assets/img/mat-icons/icomoon/success.svg',
  },
  {
    name: 'telegram',
    path: 'assets/img/mat-icons/icomoon/telegram.svg',
  },
  {
    name: 'ticket',
    path: 'assets/img/mat-icons/icomoon/ticket.svg',
  },
  {
    name: 'ticket-border',
    path: 'assets/img/mat-icons/icomoon/ticket-border.svg',
  },
  {
    name: 'tower-mini',
    path: 'assets/img/mat-icons/icomoon/tower-mini.svg',
  },
  {
    name: 'trophy-cup-silhouette',
    path: 'assets/img/mat-icons/icomoon/trophy-cup-silhouette.svg',
  },
  {
    name: 'twitch',
    path: 'assets/img/mat-icons/icomoon/twitch.svg',
  },
  {
    name: 'twitter',
    path: 'assets/img/mat-icons/icomoon/twitter.svg',
  },
  {
    name: 'vgo',
    path: 'assets/img/mat-icons/icomoon/vgo.svg',
  },
  {
    name: 'vkontakte',
    path: 'assets/img/mat-icons/icomoon/vkontakte.svg',
  },
  {
    name: 'volume-off',
    path: 'assets/img/mat-icons/icomoon/volume-off.svg',
  },
  {
    name: 'volume-on',
    path: 'assets/img/mat-icons/icomoon/volume-on.svg',
  },
  {
    name: 'sound-muted',
    path: 'assets/img/mat-icons/icomoon/sound-muted.svg',
  },
  {
    name: 'sound-level',
    path: 'assets/img/mat-icons/icomoon/sound-level.svg',
  },
  {
    name: 'play',
    path: 'assets/img/mat-icons/icomoon/play.svg',
  },
  {
    name: 'play_2',
    path: 'assets/img/mat-icons/icomoon/play_2.svg',
  },
  {
    name: 'pause',
    path: 'assets/img/mat-icons/icomoon/pause.svg',
  },
  {
    name: 'stop',
    path: 'assets/img/mat-icons/icomoon/stop.svg',
  },
  {
    name: 'webmoney-logo',
    path: 'assets/img/mat-icons/icomoon/webmoney-logo.svg',
  },
  {
    name: 'withdrawal-choose',
    path: 'assets/img/mat-icons/icomoon/withdrawal-choose.svg',
  },
  {
    name: 'x50-mini',
    path: 'assets/img/mat-icons/icomoon/x50-mini.svg',
  },
  {
    name: 'ya-wallet-logo',
    path: 'assets/img/mat-icons/icomoon/ya-wallet-logo.svg',
  },
  {
    name: 'yandex',
    path: 'assets/img/mat-icons/icomoon/yandex.svg',
  },
  // {
  //   name: 'youtube',
  //   path: 'assets/img/mat-icons/icomoon/youtube.svg',
  // },
];
