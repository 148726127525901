import { Injectable } from '@angular/core';
import { AuthService, AuthState } from '@csgofast/auth';
import { Init, UpdateCurrentUser, UserState } from '@csgofast/core/state/user-store';
import { ActivePanel, ICurrencyRate, ILocales, IUserDetailed, SocialItem, SocialType, Themes, UserCountry } from '@dev-fast/types';
import { Observable } from 'rxjs';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select, Store } from '@ngxs/store';
import { CurrencyState, GetCurrencySettings } from '@csgofast/core/state/currency-store';
import { GetAllGamesSettings } from '@csgofast/core/state/games-store';
import { ChangeActivePanel, LayoutState } from '@csgofast/core/state/layout';
import { LanguageState } from "@csgofast/core/language-service";
import { GetLocales, SetLanguage } from "@csgofast/core/language-service";

/** селекторы и экшоны для(из) стейтов используемых во многих частях приложухи. Прим. UserState, CurrencyState, LayoutState
 *
 * @deprecated использовать в корневых сервисах отдельные куски состояний
 */
@Injectable({
  providedIn: 'root',
})
export class CommonStoreService {
  @Select(UserState.user)
  public readonly user$!: Observable<IUserDetailed | null | undefined>;

  @Select(UserState.userRequestState)
  public readonly userRequestState$!: Observable<boolean | null>;

  @Select(AuthState.isAuth)
  public readonly isAuth$!: Observable<boolean>;

  @Select(LayoutState.theme)
  public theme$!: Observable<Themes>;
  @Select(LayoutState.activePanel)
  public activePanel$!: Observable<ActivePanel | null>;

  @Select(LanguageState.locales)
  public locales$!: Observable<ILocales[]>;
  @Select(LanguageState.lang)
  public currentLocale$!: Observable<ILocales>;

  @Select(CurrencyState.currency)
  public readonly currency$!: Observable<ICurrencyRate | null>;
  @Select(CurrencyState.currencyList)
  public readonly currencyList$!: Observable<ICurrencyRate[]>;

  constructor(private readonly authService: AuthService, private readonly store: Store) {}

  public switchTheme(theme: Themes) {
    this.patchCurrentUser({ theme });
  }
  public switchLang(locale: ILocales) {
    // FIXME Убрать бы отсюда юзера или вынести эту логику в стейт целиком.
    // Не переключается при запуске прилки локально если проставлены куки
    // FIXME А куки на локале как-то криво работают и не переставляются, не сильно выяснял почему.
    // в проде с этим проблем нет
    const user = this.store.selectSnapshot(UserState.user);
    user ? this.patchCurrentUser({ locale: locale.path }) : this.setLanguage(locale);
  }
  public patchCurrentUser(data: Partial<IUserDetailed>) {
    this.patchUser(data);
  }
  public init = () => {
    this.getLocales();
    this.initUserState();
    this.getCurrencySettings();
    this.getGamesSettings();
  };
  // методы авторизации и привязки соц сетей
  public login = () => {
    this.authService.openLoginProvidersModal();
  };
  public bindProvider = (provider: SocialType) => {
    this.authService.bindProvider(provider);
  };
  public removeProvider = (provider: SocialType) => {
    this.authService.onRemoveAccount(provider);
  };
  public logout = () => {
    this.authService.onLogout();
  };
  //------------------------------------------------------
  @Dispatch() public getCurrencySettings = () => new GetCurrencySettings();
  @Dispatch() public initUserState = () => new Init();
  @Dispatch() public getLocales = () => new GetLocales();
  @Dispatch() public getGamesSettings = () => new GetAllGamesSettings();
  // @Dispatch() public getUser = () => new GetUser();
  @Dispatch() public patchUser = (payload: Partial<IUserDetailed>) => new UpdateCurrentUser(payload);
  @Dispatch() public changeActivePanel = (panel: ActivePanel | null) => new ChangeActivePanel(panel);
  @Dispatch() public setLanguage = (locale: ILocales) => new SetLanguage(locale);
}
