<div class="drop-item">
  <div class="stats-contaiter">
    <div class="stats-items">
      <div class="stats-item-name loading-gradient">
      </div>
      <div class="stats-skin-name loading-gradient"></div>
    </div>
    <div class="price-item loading-gradient">
    </div>
  </div>
</div>
