import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NyRewardModalComponent} from './ny-reward-modal/ny-reward-modal.component';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatIconModule} from '@angular/material/icon';
import { NyEventProgressBarModule } from '@dev-fast/ui-components';


@NgModule({
  declarations: [NyRewardModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    MatCheckboxModule,
    NyEventProgressBarModule,
    MatTooltipModule,
    MatDialogModule,
    MatIconModule,
    TranslateModule.forChild(),
  ],
  exports: [NyRewardModalComponent],
})
export class NyEventModalsModule {}
