import {IChatAccess, IMessage, IStickerPack, stickers} from '@dev-fast/types';

export interface ChatStateModel {
  items: IMessage[];
  stickers: IStickerPack[];
  chatAccess: IChatAccess;
  activeStickerPack: IStickerPack;
  chatId: string;
}

export const CHAT_INITIAL_STATE: ChatStateModel = {
  items: [],
  chatId: 'en',
  activeStickerPack: stickers[0],
  stickers: stickers,
  chatAccess: { hasAccess: true },
};
