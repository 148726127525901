import {InjectionToken, Provider} from '@angular/core';
import {GameStatus} from '@dev-fast/types';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DesktopLeftSidebarService} from './desktop-left-sidebar.service';

export const STATUSES = new InjectionToken<Observable<Record<string, GameStatus>>>('');
export function statusFactory(moduleService: DesktopLeftSidebarService): Observable<Record<string, GameStatus>> {
  return moduleService.statuses$.pipe(
    map((value) => {
      return value['tower'] ? { ...value, tower: { ...value['tower'], notCurrencyValue: true } } : value;
    })
  );
}

export const LEFT_SIDEBAR_PROVIDERS: Provider[] = [
  {
    provide: STATUSES,
    deps: [DesktopLeftSidebarService],
    useFactory: statusFactory,
  },
];
