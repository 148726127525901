import {ModalNames} from '@dev-fast/types';

export class OpenModal {
  public static readonly type = '[Modals] Open modal';
  constructor(public name: ModalNames, public payload?: any, public payloadBeforeOpen?: any) {}
}
export class CloseModal {
  public static readonly type = '[Modals] Close modal';
  constructor(public name: ModalNames, public payload?: any, public payloadAfterClose?: any) {}
}
export class CloseAllModals {
  public static readonly type = '[Modals] Close all modals';
}
