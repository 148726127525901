<div class="possible-card-container" [style]="getStyle(revisions[0])" (clickOutside)="showRevisions = false"
  clickOutside>
  <div class="possible-card-wrapper" (click)="onCard()">
    <div class="possible-card-avatar-container">
      <img class="card-avatar" lazyLoadImage [src]="revisions[0].inventoryItem.baseItem.icon" alt=""/>
    </div>
    <div class="possible-card-description-container">
      <div class="card-description_item-name">{{ revisions[0].inventoryItem.baseItem.shortName }}</div>
      <div class="card-description_item-skin">{{ revisions[0].inventoryItem.baseItem.skin }}</div>
      <div class="card-description_item-price" *ngIf="getSkinPrice(revisions) as prices">
        <ng-container *ngIf="prices.max; else onePriceTemplate">
          <span>{{ prices.min | appCurrency }}</span>
          <span> &ndash; {{ prices.max | appCurrency }}</span>
        </ng-container>
        <ng-template #onePriceTemplate>
          <span>{{ prices.current | appCurrency }}</span>
        </ng-template>

        <!-- {{ getSkinPrice(revisions, 'min') | appCurrency }}
        - {{ getSkinPrice(revisions, 'max') | appCurrency }} -->

        <!-- {{ revisions[0].inventoryItem.price | appCurrency }}
        <ng-container *ngIf="revisions.length > 1">
          - {{ revisions[revisions.length - 1].inventoryItem.price / 100 }}
        </ng-container> -->
      </div>
    </div>
  </div>
  <div class="revision-variables-container" [class]="showRevisions ? 'visible' : 'hidden'"
    (click)="showRevisions = false">
    <div class="stats-title">
      <div>{{ 'GOFAST.CASES.SPIN_GAME.POSSIBLE_ITEMS.POSSIBLE_CARD.CARD_WEAR' | translate }}</div>
      <div>{{ 'GOFAST.CASES.SPIN_GAME.POSSIBLE_ITEMS.POSSIBLE_CARD.CARD_PRICE' | translate }}</div>
      <div>{{ 'GOFAST.CASES.SPIN_GAME.POSSIBLE_ITEMS.POSSIBLE_CARD.CARD_ODDS' | translate }}</div>
    </div>
    <div class="revision-variables" *ngFor="let revision of revisions">
      <div class="revision-wear" [class.stat-trak]="revision.inventoryItem.baseItem.statTrak">
        {{ toShortRevisionWear(revision.inventoryItem.baseItem.exterior) }}
      </div>
      <div class="revision-price">{{ revision.inventoryItem.price | appCurrency }}</div>
      <div class="revision-odds" [class.stat-trak]="revision.inventoryItem.baseItem.statTrak">
        {{ revision.count | appPercent: { rank: 1000 } }}
      </div>
    </div>
  </div>
  <fast-ui-increase (click)="toggleDropdown($event)" [valueType]="'percent'" [maxValue]="95000"
    [setCurValue]="countSum * tracksAmount" [index]="index" [differencePosition]="'bottom'">
  </fast-ui-increase>
</div>