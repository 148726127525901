import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AutoHeightNgxScrollDirective} from './auto-height-ngx-scroll.directive';

@NgModule({
  declarations: [AutoHeightNgxScrollDirective],
  imports: [CommonModule],
  exports: [AutoHeightNgxScrollDirective],
})
export class AutoHeightNgxScrollModule {}
