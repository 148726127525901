import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IAudio, ISoundDictionary } from './sound.interface';
import { AudioController } from './audio-controller/audio-controller.class';
import * as sounds from './sound.dictionary';
import { LocalStorageService } from '@csgofast/core/local-storage-service';
// import {FrameMessageTypes, IFrameMessageService} from '@csgofast/core/iframe';

@Injectable({
  providedIn: 'root',
})
export class SoundService {
  private readonly audioList: ISoundDictionary;
  private list: BehaviorSubject<ISoundDictionary>;
  private status: BehaviorSubject<boolean>;
  public status$: Observable<boolean>;
  private enabled: boolean;
  private volume: BehaviorSubject<number>;
  public volume$: Observable<number>;
  public readonly DEFAULT_VOLUME = 50;

  constructor(private storage: LocalStorageService) {
    this.audioList = {};
    const volume = this.storage.get('volume');

    this.volume = new BehaviorSubject(typeof volume === 'number' ? volume : this.DEFAULT_VOLUME);
    this.volume$ = this.volume.asObservable();
    // this.storage.set('volume', this.volume.value);

    this.enabled = this.volume.value > 0;

    this.status = new BehaviorSubject(this.enabled) as BehaviorSubject<boolean>;
    this.status$ = this.status.asObservable();
    this.list = new BehaviorSubject(this.audioList) as BehaviorSubject<ISoundDictionary>;
    // Object.keys(sounds).map((key) => sounds[key as keyof typeof sounds].forEach((sound: IAudio) => this.addAudio(sound)));

    this.initAllSounds();
    this.setVolume(this.volume.value);
  }
  public initModuleSounds(moduleName: string): void {
    
    sounds[moduleName as keyof typeof sounds].forEach((sound: IAudio) => this.addAudio(sound));
    const soundStoreModel = this.generateStoreModel();
    this.compareAndUpdateSoundSettings(soundStoreModel);
  }

  public addAudio(audio: IAudio): void {
    if (!this.audioList[audio.group]) {
      this.audioList[audio.group] = new Map();
    }

    if (this.audioList[audio.group].has(audio.name)) {
      return
      // throw new Error(`Audio sound with name '${audio.name}' already exists in '${audio.group}' group`);
    }
    const soundSettings = this.storage.get('soundSettings');
    if (soundSettings) {
      const status = soundSettings[`${audio.group}.${audio.name}`];
      audio.enabled = status !== undefined ? status : true;
    } else {
      audio.enabled = true;
    }
    this.audioList[audio.group].set(audio.name, new AudioController(audio));
    this.list.next(this.audioList);
  }

  public setVolume(volume: number): void {
    this.volume.next(volume);
    this.storage.set('volume', volume);
    volume > 0 ? this.storage.set('sound', true) : this.storage.set('sound', false);
    this.enabled = volume !== 0;
    this.status.next(this.enabled);

    // this.frameMessageService.sendMessage({
    //   type: FrameMessageTypes.MESSAGE_TO_BB,
    //   eventName: 'soundToggle',
    //   payload: { status: this.enabled },
    // });
  }

  public muteAudio(group: string, name: string, phase: string = '', type: string = '', enabled: boolean): void {
    const fullName = this.soundNameConstructor(name, phase, type);
    if (this.checkSoundPathExisting(group, fullName)) {
      if (this.audioList[group] && this.audioList[group].get(name)) {
        this.audioList[group]?.get(name)?.mute(enabled);
        this.list.next(this.audioList);
        this.updateStorageValue(fullName, enabled);
      }
    }
  }

  public muteAudioGroup(group: string, enabled: boolean): void {
    if (this.checkSoundPathExisting(group)) {
      for (const sound of this.audioList[group].values()) {
        sound.mute(enabled);
        this.updateStorageValueGroup(group, enabled);
      }
      this.list.next(this.audioList);
    }
  }

  public muteAudioCategory(category: string, enabled: boolean): void {
    const keys = Object.keys(this.audioList).filter((name: string) => (category === 'common' ? name === 'common' : name !== 'common'));
    keys.forEach((key: string) => this.muteAudioGroup(key, enabled));
  }

  public getSoundStatus(): Observable<boolean> {
    return this.status.asObservable();
  }

  public toggleSoundStatus(): void {
    if (this.volume.value === 0) {
      this.setVolume(this.DEFAULT_VOLUME);
    } else {
      this.setVolume(0);
    }
  }

  // public playAudio(path: string) {
  //   // return; // TODO: reformat;
  //   if (!this.enabled) return;
  //   if (this.volume.value === 0) return;
  //   if (this.checkSoundPathExisting(path)) {
  //     const [group, name] = path.split('.');
  //     this.audioList[group].get(name)?.volume(this.volume.value / 100);
  //     this.audioList[group].get(name)?.play();
  //     return this.audioList[group].get(name)
  //   }
  //   return null
  // }
  /**
   * excl это имена звуков для исключения если основной звук name="RANDOM"
   * */
  public playAudio(group: string, name: string, phase: string = '', type: string = '', excl?:string[]) {
    let fullName: string;
    if (name === 'RANDOM') {
      // todo работает но тяжелая конструкция + считается каждый раз
      const keys = [...this.audioList[group].keys()].filter(value => !excl?.find(el=>value.includes(el)));
      const randomIndex = Math.round(Math.random() * (keys.length - 1) + 1);
      fullName = this.soundNameConstructor(this.audioList[group].get(keys[randomIndex])?.shortName || 'STANDART', phase, type);
    } else {
      fullName = this.soundNameConstructor(name, phase, type);
    }
    if (!this.enabled) return;
    if (this.volume.value === 0) return;
    if (this.checkSoundPathExisting(group, fullName)) {
      this.audioList[group].get(fullName)?.volume(this.volume.value / 100);
      this.audioList[group].get(fullName)?.play();
      return this.audioList[group].get(fullName);
    }
    return null;
  }

  public testAudio(path: string): void {
    // return;
    // if (this.checkSoundPathExisting(path)) {
    //   const [group, name] = path.split('.');
    //   this.audioList[group].get(name)?.volume(0.5);
    //   this.audioList[group].get(name)?.play(true);
    // }
  }

  public pauseAudio(group: string, name: string, phase: string = '', type: string = '') {
    const fullName = this.soundNameConstructor(name, phase, type);
    if (this.checkSoundPathExisting(group, fullName)) {
      this.audioList[group]?.get(fullName)?.pause();
    }
  }

  public stopAudio(group: string, name: string, phase: string = '', type: string = '') {
    const fullName = this.soundNameConstructor(name, phase, type);
    if (this.checkSoundPathExisting(group, fullName)) {
      this.audioList[group]?.get(fullName)?.stop();
    }
  }

  public getAudioList(): Observable<ISoundDictionary> {
    return this.list.asObservable();
  }

  private checkSoundPathExisting(group: string, name: string = ''): boolean {
    if (!this.audioList[group]) {
      console.error(new Error(`No audio group with name '${group}'`));
      return false;
    }
    if (name !== '' && !this.audioList[group].has(name)) {
      console.error(new Error(`No audio sound with name '${name}' in '${group}' group`));
      return false;
    }
    return true;
  }

  public generateStoreModel(): { [key: string]: boolean } {
    return Object.keys(this.audioList).reduce(
      (dictionary: { [key: string]: boolean }, key: string) => ({
        ...dictionary,
        ...Array.from(this.audioList[key].keys()).reduce((group, name) => ({ ...group, [`${key}.${name}`]: true }), {}),
      }),
      {}
    );
  }

  public compareAndUpdateSoundSettings(dictionary: { [key: string]: boolean }): void {
    const soundSettings = this.storage.get('soundSettings');
    if (!soundSettings) {
      this.storage.set('soundSettings', dictionary);
      return;
    }
    const settingsKeys = Object.keys(soundSettings);
    const newSettingsKeys = Object.keys(dictionary).filter((key) => !settingsKeys.includes(key));
    if (newSettingsKeys.length > 0) {
      const settings = Object.keys(newSettingsKeys).reduce((obj, key) => ({ ...obj, [newSettingsKeys[Number(key)]]: true }), soundSettings);
      this.storage.set('soundSettings', settings);
      return;
    }
  }

  private soundNameConstructor(name: string, firstPostfix?: string, soundPostfix?: string): string {
    let fullName: string;
    if (!name) {
      fullName = '';
    } else {
      fullName = `${name}${firstPostfix ? '_' : ''}${firstPostfix}${soundPostfix ? '_' : ''}${soundPostfix}`;
    }
    return fullName;
  }
  private updateStorageValue(path: string, value: boolean) {
    const soundSettings = this.storage.get('soundSettings');
    if (soundSettings) {
      soundSettings[path] = value;
      this.storage.set('soundSettings', soundSettings);
    }
  }

  private updateStorageValueGroup(group: string, value: boolean) {
    const soundSettings = this.storage.get('soundSettings');
    if (soundSettings) {
      Object.keys(soundSettings)
        .filter((key: string) => key.startsWith(group))
        .map((key: string) => (soundSettings[key] = value));
      this.storage.set('soundSettings', soundSettings);
    }
  }

  private initAllSounds() {
    // Object.keys(this.audioList).forEach((key) => {
    //   for (const name of this.audioList[key].keys()) {
    //     const audio = this.audioList[key].get(name);
    //     if (audio) {
    //       // audio.volume(0);
    //       // audio.play();
    //       audio.load();
    //     }
    //   }
    // });
  }
}
