import {IconConfig} from "./icon.model";

export const iconsLevels: IconConfig[] = [
  {
    name: 'chevron',
    path: 'assets/img/mat-icons/levels/chevron.svg',
  },
  {
    name: 'level-achieved',
    path: 'assets/img/mat-icons/levels/level-achieved.svg',
  },
  {
    name: 'level-current',
    path: 'assets/img/mat-icons/levels/level-current.svg',
  },
  {
    name: 'level-unachieved',
    path: 'assets/img/mat-icons/levels/level-unachieved.svg',
  },
  {
    name: 'progress',
    path: 'assets/img/mat-icons/levels/progress.svg',
  },
  {
    name: 'start',
    path: 'assets/img/mat-icons/levels/start.svg',
  },
];
