import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { AppCurrencyModule, CustomAsyncPipeModule } from '@csgofast/shared/pipes';
import { BestCardComponent } from './user-profile/best-card/best-card.component';
import { CaseCardComponentModule, CaseFullInfoModule, SkinCardsListModule, UserAvatarModule } from '@dev-fast/ui-components';
import { CaseRevisionComponent } from './games/cases/case-revision/case-revision.component';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { LazyLoadImageModule } from '@csgofast/shared/directives';

@NgModule({
  imports: [
    CommonModule,
    UserAvatarModule,
    TranslateModule,
    RouterModule,
    NgxSkeletonLoaderModule,
    LazyLoadImageModule,
    MatIconModule,
    MatSelectModule,
    MatMenuModule,
    MatIconModule,
    CustomAsyncPipeModule,
    AppCurrencyModule,
    NgScrollbarModule,
    MatTooltipModule,
    CaseCardComponentModule,
    SkinCardsListModule,
    CaseFullInfoModule
  ],
  declarations: [UserProfileComponent, BestCardComponent, CaseRevisionComponent],
  exports: [UserProfileComponent],
})
export class ModalsModule {}
