import {Injectable} from '@angular/core';
import {Environments, EnvironmentService} from '@csgofast/core/environment-service';
import {IMessage, IStickerMessage} from '@dev-fast/types';
import {Socket as WrappedSocket} from "ngx-socket-io";
import {Observable} from 'rxjs';
import {ApiService} from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ChatApiService {
  private environment: Environments;

  constructor(
    private readonly api: ApiService,
    private readonly environmentService: EnvironmentService,
    private readonly ws: WrappedSocket
  ) {
    this.environment = this.environmentService.environments;
  }

  public addMessage(chatId: string, message: { value: string | IStickerMessage; game: string }): Observable<void> {
    return this.api.post<void>(`${this.environment.GATEWAY_URL}/api/chats/${chatId}/messages`, message, { withCredentials: true });
  }
  public getMessagesList(chatId: string, callback: (messages: IMessage[]) => void): void {
    this.ws.emit('chat.messages', { chatId }, callback);
  }
  public messageCreatedEvent(callback: (message: IMessage) => void): void {
    this.ws.on('chat.message.created', callback);
  }
  public messageDeletedEvent(callback: (message: IMessage) => void): void {
    this.ws.on('chat.message.deleted', callback);
  }
  // public messageCreatedEvent(): Observable<IMessage> {
  //   return this.ws.fromEvent<IMessage>('chat.message.created');
  // }
  // public messageDeletedEvent(): Observable<IMessage> {
  //   return this.ws.fromEvent<IMessage>('chat.message.deleted');
  // }
}
