import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ChatraIntegration, ChatraSetup } from './chatra.interface';
declare global {
  interface Window {
    Chatra?: any;
    ChatraID: string;
    ChatraSetup: ChatraSetup;
    ChatraIntegration: ChatraIntegration;
  }
}
@Injectable({
  providedIn: 'root',
})
export class ChatraService {
  private readonly window: Window | null;
  constructor(@Inject(DOCUMENT) private readonly document: Document) {
    this.window = this.document.defaultView;
  }
  // прикрепление пока по старинке, не разобрался иногда стек переполняется
  // public appendScript = () => {
  //   if (!this.window) return;
  //
  //   this.window.ChatraID = 'qvZgbeMnBjhM34itq';
  //   const script = this.document.createElement('script');
  //   script.src = 'https://call.chatra.io/chatra.js';
  //   script.async = true;
  //   // TODO фигово
  //   this.window.Chatra =
  //     this.window.Chatra ||
  //     function () {
  //       // eslint-disable-next-line prefer-rest-params
  //       (window.Chatra.q = window.Chatra.q || []).push(arguments);
  //     };
  //
  //   this.document.head.appendChild(script);
  // };
  public setIntegrationData = (info: ChatraIntegration):void => {
    if (!this.window) return;

    if(this.window.Chatra){
      this.window.Chatra('setIntegrationData', info);
    }
    // if (info.USER_ID) {
    //   this.chatraSetup({ clientId: info.USER_ID });
    // }
  };
  public chatraSetup = (conf: ChatraSetup):void => {
    // не получилось пока
    if (!this.window) return;
    this.window.ChatraSetup = conf;
    this.window.Chatra('restart');
  };
  public openChat = ():void => {
    if (!this.window) return;
    if(this.window.Chatra){
      this.window.Chatra('openChat', true);
    }
  };
  public hasChatra = ():boolean => {
    if (!this.window) return false;
    return !!this.window.Chatra
  };
}
