import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NyEventRoutingModule } from './ny-event-routing.module';
import { NyEventComponent } from './ny-event.component';
import { RoomComponent } from './components/room/room.component';
import { GiftsGridComponent } from './components/gifts-grid/gifts-grid.component';
import { NyProgressBlockComponent } from './components/progress-block/progress-block.component';
import { NyMotivatorComponent } from './components/motivator/motivator.component';
import { GiftCardComponent } from './components/gifts-grid/gift-card/gift-card.component';
import { NyMotvatorItemComponent } from './components/motivator/item/item.component';
import { PrizeOverlayComponent } from './components/room/prize-overlay/prize-overlay.component';
import { UserAvatarModule, CubeGridLoaderComponentModule, NyEventProgressBarModule, CasesCollectionComponentModule } from '@dev-fast/ui-components';
import { NyEventCaseMotivatorComponent } from './shared/ny-event-free-case-motivator/ny-event-case-motivator.component';
import { MatIconModule } from '@angular/material/icon';
import { AppCurrencyModule, CustomAsyncPipeModule } from '@csgofast/shared/pipes';
import { NgScrollbarModule } from 'ngx-scrollbar';

@NgModule({
  declarations: [
    NyProgressBlockComponent,
    RoomComponent,
    NyEventComponent,
    NyMotvatorItemComponent,
    GiftCardComponent,
    GiftsGridComponent,
    NyMotivatorComponent,
    PrizeOverlayComponent,
    NyEventCaseMotivatorComponent,
  ],
  exports: [NyEventCaseMotivatorComponent, NyEventComponent,GiftsGridComponent,NyProgressBlockComponent],
  imports: [
    CommonModule,
    NyEventRoutingModule,
    CubeGridLoaderComponentModule,
    CasesCollectionComponentModule,
    MatIconModule,
    UserAvatarModule,
    NyEventProgressBarModule,
    AppCurrencyModule,
    NgScrollbarModule,
    TranslateModule,
    CustomAsyncPipeModule,

  ],
})
export class NyEventModule {}
