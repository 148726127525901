import {Component, Input, OnDestroy} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {Subject} from 'rxjs';
import {IP2pDepositItem} from '@dev-fast/types';
import {P2pDepositService} from '@csgofast/modules/p2p';
import {P2pSellService} from '../p2p-sell.service';

@Component({
  selector: 'csgofast-p2p-sell-setup-item',
  templateUrl: './p2p-sell-setup-item.component.html',
  styleUrls: ['./p2p-sell-setup-item.component.scss'],
})
export class P2pSellSetupItemComponent implements OnDestroy {
  @Input() public item: IP2pDepositItem | undefined;
  private destroyed$: Subject<void>;
  // private currentHundler: boolean;

  constructor(
    public dialog: MatDialog,
    private readonly p2pDepositService: P2pDepositService,
    private readonly moduleService: P2pSellService
  ) {
    this.destroyed$ = new Subject<void>();
    // this.currentHundler = false;
    // this.subscribeEmitters();
  }

  public removeAt(): void {
    if (this.item) this.p2pDepositService.removeDepositItem(this.item);
  }

  public openModifierDialog(): void {
    if (this.item) {
      const config = { data: { id: this.item?.steamInventoryId } };
      this.moduleService.openModifierDialog(this.item);
      // this.dialog.open(P2pSellModifierModalComponent, config);
    }
  }
  // public openModifierDialog(): void {
  //   //FIXME кака-то срака, переделать
  //   this.currentHundler = true;
  //   this.moduleService.getDepositModifier();
  // }
  // private subscribeEmitters() {
  //   this.moduleService.depositModifier$.pipe(takeUntil(this.destroyed$)).subscribe((preview) => {
  //     if (!this.currentHundler) return;

  //     const config = { data: { id: this.item?.steamInventoryId } };

  //     if (preview && preview?.agree) {
  //       this.dialog.open(P2pSellModifierModalComponent, config);
  //     } else {
  //       this.dialog.open(P2pPreviewSellModifierModalComponent, config);
  //     }

  //     this.currentHundler = false;
  //   });

  // }
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
