<ng-container *ngIf="onlyText; else styledTemplate">
  <ng-container *ngIf="showDays">
    <ng-container *ngIf="days$ | async as days">
      <ng-container *ngFor="let day of days">
        <span>{{ day }}</span>
      </ng-container>
    </ng-container>
    <span style="padding: 0 0.325rem;">{{ ' : ' }}</span>
  </ng-container>

  <ng-container *ngIf="showHours">
    <ng-container *ngIf="hours$ | async as hours">
      <ng-container *ngFor="let hour of hours">
        <span>{{ hour }}</span>
      </ng-container>
    </ng-container>
    <span style="padding: 0 0.325rem;">{{ ' : ' }}</span>
  </ng-container>

  <ng-container *ngIf="minutes$ | async as minutes">
    <ng-container *ngFor="let minute of minutes">
      <span> {{ minute }}</span>
    </ng-container>
  </ng-container>
  <span style="padding: 0 0.325rem;">{{ ' : ' }}</span>

  <ng-container *ngIf="seconds$ | async as seconds">
    <ng-container *ngFor="let second of seconds">
      <span>{{ second }}</span>
    </ng-container>
  </ng-container>
</ng-container>
<ng-template #styledTemplate>
  <ng-container *ngIf="showDays">
    <div class="countdown-item-box">
      <div
        class="countdown-item"
        *ngIf="days$ | async as days"
      >
        <div
          class="countdown-item-digit"
          *ngFor="let day of days"
        >
          {{ day }}
        </div>
      </div>
      <div
        *ngIf="viewSign"
        class="time-sign"
      >
        {{ 'GOFAST.CASES.SERVICE_PAGES.PROMO_TIMER_DURATION' | translate | titlecase }}
      </div>
    </div>
    <div class="countdown-item-digit">:</div>
  </ng-container>
  <ng-container *ngIf="showHours">
    <div class="countdown-item-box">
      <div
        class="countdown-item"
        *ngIf="hours$ | async as hours"
      >
        <div
          class="countdown-item-digit"
          *ngFor="let hour of hours"
        >
          {{ hour }}
        </div>
      </div>
      <div
        *ngIf="viewSign"
        class="time-sign"
      >
        {{ 'GOFAST.CASES.SERVICE_PAGES.PROMO_TIMER_DURATION_HOURS' | translate | titlecase }}
      </div>
    </div>
    <div class="countdown-item-digit">:</div>
  </ng-container>
  <div class="countdown-item-box">
    <div
      class="countdown-item"
      *ngIf="minutes$ | async as minutes"
    >
      <div
        class="countdown-item-digit"
        *ngFor="let minute of minutes"
      >
        {{ minute }}
      </div>
    </div>
    <div
      *ngIf="viewSign"
      class="time-sign"
    >
      {{ 'GOFAST.CASES.SERVICE_PAGES.PROMO_TIMER_DURATION_MINUTES' | translate | titlecase }}
    </div>
  </div>
  <ng-container *ngIf="showSeconds">
    <div class="countdown-item-digit">:</div>
    <div class="countdown-item-box">
      <div
        class="countdown-item"
        *ngIf="seconds$ | async as seconds"
      >
        <div
          class="countdown-item-digit"
          *ngFor="let second of seconds"
        >
          {{ second }}
        </div>
      </div>
      <div
        *ngIf="viewSign"
        class="time-sign"
      >
        {{ 'GOFAST.CASES.SERVICE_PAGES.PROMO_TIMER_DURATION_SECONDS' | translate | titlecase }}
      </div>
    </div>
  </ng-container>
</ng-template>
