import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {P2pSellingItemComponent} from './p2p-selling-item.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {
  P2pAuctionCompletedModalModule
} from '../../modals/p2p-auction-completed-modal/p2p-auction-completed-modal.module';
import {MatIconModule} from '@angular/material/icon';
import {AppCurrencyModule, ReplacePipeModule} from '@csgofast/shared/pipes';
import {SkinItemImageModule} from '@csgofast/shared/directives';
import {CountdownComponentModule} from '@dev-fast/ui-components';

@NgModule({
  declarations: [P2pSellingItemComponent],
    imports: [
        CommonModule,
        RouterModule,
        MatIconModule,
        TranslateModule,
        AppCurrencyModule,
        SkinItemImageModule,
        CountdownComponentModule,
        MatProgressSpinnerModule,
        P2pAuctionCompletedModalModule,
        ReplacePipeModule,
    ],
  exports: [P2pSellingItemComponent],
})
export class P2pSellingItemModule {}
