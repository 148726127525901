import {Injectable} from '@angular/core';
import {AuthService} from '@csgofast/auth';
import {OpenModal} from '@csgofast/core/state/modals';
import {UserState} from '@csgofast/core/state/user-store';
import {
  GetDepositModifier,
  GetDepositModifierSuccess,
  OpenModifierDialogInBB,
  P2pCommonState,
  SetDepositModifier,
} from '@csgofast/modules/p2p';
import {IP2pDepositItem, IUserP2pPermissionInterface, ModalNames, SocialType} from '@dev-fast/types';
import {Dispatch} from '@ngxs-labs/dispatch-decorator';
import {Actions, ofActionSuccessful, Select} from '@ngxs/store';
import {map, Observable} from 'rxjs';

@Injectable()
export class P2pSellService {
  @Select(UserState.p2pPermissions)
  public readonly p2pPermissions$!: Observable<IUserP2pPermissionInterface>;
  @Select(P2pCommonState.sellerBanEndAt)
  public readonly sellerBanEndAt$!: Observable<number | null>;

  public depositModifier$ = this.actions$.pipe(
    ofActionSuccessful(GetDepositModifierSuccess),
    map((action: GetDepositModifierSuccess) => action.payload)
  );

  constructor(private readonly actions$: Actions, private readonly authService: AuthService) {}

  public bindProvider(provider: SocialType) {
    this.authService.bindProvider(provider);
  }
  @Dispatch() public getDepositModifier = () => new GetDepositModifier();
  @Dispatch() public setDepositModifier = (payload: { agree: boolean }) => new SetDepositModifier(payload);
  @Dispatch() public openModifierDialog = (item: IP2pDepositItem) => new OpenModifierDialogInBB(item);
  @Dispatch() public openSupportModal = () => new OpenModal(ModalNames.TECH_SUPPORT_SELECT_METHOD);
}
