import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Logout} from "@csgofast/auth";
import {NotificationsService} from '@csgofast/core/notification-service';
import {RefreshActivePanel} from "@csgofast/core/state/layout";
import {OpenModal} from "@csgofast/core/state/modals";
import {InitSuccess} from "@csgofast/core/state/user-store";
import {RouterStateParams} from "@csgofast/core/state/utils";
import {CasesQuestBackendService, MotivatorBackendService} from '@dev-fast/backend-services';
import {
  AgentKeys,
  configs,
  EventsConfig,
  ICaseQuestMotivatorLastDto,
  ICaseQuestResponse,
  ICaseQuestTotalProgress,
  ICasesQuestItem,
  ICasesQuestStatus,
  ModalNames,
  Panel,
} from '@dev-fast/types';
import {Navigate, RouterNavigation} from '@ngxs/router-plugin';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {insertItem, patch, updateItem} from '@ngxs/store/operators';
import moment from "moment/moment";
import {EMPTY, Observable, of, switchMap, timer} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';

import {
  GetMotivator,
  GetQuest,
  OpenCongratsModal,
  PickUpPrize, ResetQuest,
  UpdateMotivator,
  UpdateQuestItem
} from './ny-event.actions';
import {NY_EVENT_INITIAL_STATE, NYEventStateModel} from './ny-event.model';

@State<NYEventStateModel>({
  name: 'ny_event',
  defaults: NY_EVENT_INITIAL_STATE,
})
@Injectable()
export class NyEventState {
  constructor(
    private readonly eventApiService: CasesQuestBackendService,
    private readonly notificationsService: NotificationsService,
    private readonly motivatorService: MotivatorBackendService
  ) {}

  @Selector()
  public static items({ items }: NYEventStateModel): ICasesQuestItem[] {
    return items;
  }
  @Selector()
  public static reward({ reward }: NYEventStateModel): ICasesQuestItem | null {
    return reward;
  }
  @Selector()
  public static overlay({ overlay }: NYEventStateModel): ICasesQuestItem | null {
    return overlay;
  }
  @Selector()
  public static motivatorList({ motivatorList }: NYEventStateModel): ICaseQuestMotivatorLastDto[] {
    return motivatorList;
  }
  @Selector()
  public static eventName({ eventName }: NYEventStateModel): keyof EventsConfig |null{
    return eventName;
  }
  @Selector()
  public static untilEventTime({ untilEventTime }: NYEventStateModel): number|null {
    return untilEventTime;
  }
  @Selector()
  public static eventHistoryName({ eventHistoryName }: NYEventStateModel): keyof EventsConfig |null{
    return eventHistoryName;
  }
  @Selector()
  public static atOnceWithHistory({ eventHistoryName,eventName }: NYEventStateModel): boolean{
    return eventName !== null && eventHistoryName !== null;
  }
  @Selector()
  public static untilEventHistoryTime({ untilEventHistoryTime }: NYEventStateModel): number|null {
    return untilEventHistoryTime;
  }
  @Selector()
  public static totalProgress({ items }: NYEventStateModel): ICaseQuestTotalProgress {
    const found = items.filter((el) => el.status !== ICasesQuestStatus.BLOCKED).length;
    const total = items.length;
    return { total, found, percent: (found / total) * 100 };
  }
  @Selector()
  static item(state: NYEventStateModel) {
    return (id: number) => {
      return state.items.find((item) => item.entityId === id);
    };
  }
  public ngxsOnInit({ dispatch,patchState }: StateContext<NYEventStateModel>): void {
    this.eventApiService.casesQuestProgressEvent((msg) => dispatch(new UpdateQuestItem(msg)));
    // this.eventApiService.luckyWonEvent((msg) => dispatch(new GetQuest()));
    this.motivatorService.onLastWon((msg) => msg.agentKey === AgentKeys.CASES_QUEST && dispatch(new UpdateMotivator(msg.data)));
    for (const key in configs) {
      const now = moment();
      const startDate =configs[key as keyof EventsConfig]?.startEvent
      const endDate =configs[key as keyof EventsConfig]?.endEvent
      const hideDate =configs[key as keyof EventsConfig]?.hide
      const isBetween =( )=>now.isBetween(startDate, endDate)
      const isBetweenEndHide =( )=>now.isBetween(endDate, hideDate)
      if(isBetween()){
        patchState({eventName:key as keyof EventsConfig, untilEventTime: this.calcUntilEventTime(endDate)})
      }
      if(isBetweenEndHide()){
        patchState({eventHistoryName:key as keyof EventsConfig, untilEventHistoryTime: this.calcUntilEventTime(hideDate )})
      }
    }
  }
  private calcUntilEventTime( endDate: string | undefined):number | null | undefined{
    const now = moment();
    const diffDaysEndNow = moment(endDate).diff(now, 'days')
    return diffDaysEndNow <=4 ? moment(endDate).diff(now):null;
  }
  @Action(RouterNavigation)
  public navigate(
    { dispatch, patchState, getState }: StateContext<NYEventStateModel>,
    { routerState }: RouterNavigation<RouterStateParams>
  ): void {
    if (routerState.url.includes('/events/ny-event')) {
      dispatch([new GetQuest(), new GetMotivator()])
    }
  }
  @Action([InitSuccess])
  public initSuccess({  dispatch }: StateContext<NYEventStateModel>) {
    dispatch([new GetQuest()])
  }
  @Action([Logout])
  public logout({  dispatch }: StateContext<NYEventStateModel>) {
    return timer(1000).pipe(tap(() => dispatch([new GetQuest()])))
  }
  @Action([GetQuest, RefreshActivePanel])
  public calcEventName({  patchState,getState }: StateContext<NYEventStateModel>,{payload}:RefreshActivePanel) {
    const {eventName,eventHistoryName}= getState();
    // косяк если другую панель закрыть то всегда вызовет
    if (payload && payload.panel !==Panel.EVENT_MENU) return;
    if(eventName){
      const endDate =configs[eventName as keyof EventsConfig]?.endEvent
      patchState({untilEventTime: this.calcUntilEventTime(endDate)})
    }
    if(eventHistoryName){
      const hideDate =configs[eventHistoryName as keyof EventsConfig]?.hide
      patchState({untilEventHistoryTime: this.calcUntilEventTime(hideDate)})
    }
  }
  @Action([ResetQuest])
  public resetQuest({  patchState }: StateContext<NYEventStateModel>) {
    patchState({items:[]})
  }
  @Action(GetQuest,{cancelUncompleted:true})
  public init({ patchState, dispatch }: StateContext<NYEventStateModel>): Observable<ICaseQuestResponse> {
    return this.eventApiService.getQuestInfo().pipe(
      tap((items) => {
        //прям наверника))
        const blocked = items.categories.filter((val) => val.status === ICasesQuestStatus.BLOCKED);
        const available = items.categories.filter((val) => val.status === ICasesQuestStatus.AVAILABLE);
        const reward = items.reward && items.reward.status === ICasesQuestStatus.AVAILABLE ? items.reward : null;
        if (blocked.length === 0 && reward) {
          dispatch(new OpenCongratsModal(reward));
        }

        patchState({
          items: items.categories,
          // items: items.categories.map(el=>({...el,status: ICasesQuestStatus.TAKEN})),
          reward: items.reward ? items.reward : null,
          overlay: reward ? reward : available.length ? available[0] : null,
        });
      })
    );
  }
  @Action(OpenCongratsModal)
  public openCongratsModal({ patchState, dispatch }: StateContext<NYEventStateModel>, { prize }: OpenCongratsModal) {
    dispatch(new OpenModal(ModalNames.NY_EVENT_REWARD,prize))

  }
  @Action(GetMotivator,{cancelUncompleted:true})
  public getMotivator({ patchState }: StateContext<NYEventStateModel>) {
    return this.eventApiService.getMotivatorList().pipe(tap((value) => patchState({ motivatorList: value.last?.map((el) => el.data) })));
  }
  @Action(UpdateMotivator)
  public updateMotivator({ setState }: StateContext<NYEventStateModel>, { payload }: UpdateMotivator) {
    setState(
      patch({
        motivatorList: insertItem(payload),
      })
    );
  }
  @Action(UpdateQuestItem)
  public updateItem({ setState, dispatch }: StateContext<NYEventStateModel>, { payload }: UpdateQuestItem): void {
    if (payload.quest.rewardAvailable) {
      dispatch(new GetQuest());
    } else {
      setState(
        patch({
          items: updateItem<ICasesQuestItem>(
            (o) => o?.entityId === payload.entityId,
            patch({
              progress: payload.progress,
            })
          ),
        })
      );
    }
  }
  @Action(PickUpPrize)
  public pickUpPrize({ setState, dispatch }: StateContext<NYEventStateModel>, { item }: PickUpPrize): Observable<void | Observable<never>> {
    if (item.status === ICasesQuestStatus.TAKEN) {
      return dispatch(new Navigate([`game/cases/case/${item.case?.id}`]));
    }
    return this.eventApiService.pickUpPrize(item.entityId, item.questId).pipe(
      tap(() => {
        setState(
          patch({
            items: updateItem<ICasesQuestItem>(
              (o) => o?.entityId === item.entityId,
              patch({
                status: ICasesQuestStatus.TAKEN,
              })
            ),
          })
        );
      }),
      // TODO роут проверить
      switchMap(() => dispatch(new Navigate([`game/cases/case/${item.case?.id}`]))),
      catchError((error: HttpErrorResponse) => {
        this.notificationsService.addErrorNotification(error.message);
        return of(EMPTY);
      })
    );
  }
}
