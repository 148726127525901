import {IconConfig} from './icon.model';

export const iconsSocials: IconConfig[] = [
  {
    name: 'socials-facebook',
    path: 'assets/img/mat-icons/socials/facebook.svg',
  },
  {
    name: 'socials-twitter',
    path: 'assets/img/mat-icons/socials/twitter.svg',
  },
  {
    name: 'discord',
    path: 'assets/img/mat-icons/socials/discord.svg',
  },
  {
    name: 'socials-vkontakte',
    path: 'assets/img/mat-icons/socials/vkontakte.svg',
  },
  {
    name: 'youtube',
    path: 'assets/img/mat-icons/socials/youtube-btn.svg',
  },
  {
    name: 'instagram',
    path: 'assets/img/mat-icons/socials/instagram.svg',
  },
  {
    name: 'steam',
    path: 'assets/img/mat-icons/socials/steam.svg',
  },
  {
    name: 'socials-google',
    path: 'assets/img/mat-icons/socials/google.svg',
  },
  {
    name: 'socials-yandex',
    path: 'assets/img/mat-icons/socials/yandex.svg',
  },
  {
    name: 'socials-twitch',
    path: 'assets/img/mat-icons/socials/twitch.svg',
  },
  {
    name: 'socials-telegram',
    path: 'assets/img/mat-icons/socials/telegram.svg',
  },
  {
    name: 'socials-metamask',
    path: 'assets/img/mat-icons/socials/metamask.svg',
  },
];
