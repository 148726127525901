import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {GameStatus, IGame} from '@dev-fast/types';

@Component({
  selector: 'fast-ui-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuItemComponent {
  @Input() public game!: IGame;
  @Input() public status!: GameStatus;
  @Input() public isShowFullView: boolean | null;
  @Input() public isNew: boolean;
  @Input() public isActive: boolean = false;

  constructor() {
    this.isShowFullView = false;
    this.isNew = false;
  }
}
