<div class="setup-container">
  <div class="setup-container__title">
    <mat-icon svgIcon="failure"></mat-icon>
    <span>
      {{ 'P2P_SETTINGS.SETUP_TITLE' | translate }}
    </span>
  </div>
  <div class="setup-container__subtitle">
    {{ 'P2P_SETTINGS.ADD_TRADE_LINK_AND_API_KEY' | translate }}
  </div>
  <div class="setup-container__setup-steps">
    <ng-container *ngIf="data.includes('steam')">
      <div class="step-container">
        <ng-container *ngIf="data.includes('apiKey')">
          <div
            [ngClass]="{ 'color-success': tradelinkIsValid$ | async }"
            class="circle-step"
          ></div>
          <div class="dashed-line"></div>
        </ng-container>
      </div>
      <form
        class="setup-form"
        [formGroup]="tradeLinkForm"
      >
        <div class="setup-form__label">{{ 'P2P_SETTINGS.TRADE_LINK.LABEL' | translate }}</div>
        <label>
          <input
            class="setup-form__input"
            type="text"
            [ngClass]="{
              'setup-form__input--submitted': tradeLinkForm.value['submitted'],
              'setup-form__input--errors': !tradeLinkForm.valid
            }"
            formControlName="steam"
            [placeholder]="'P2P_SETTINGS.TRADE_LINK.INPUT_PLACEHOLDER' | translate"
          />
        </label>
        <div class="setup-form__actions">
          <button
            type="button"
            *ngIf="steam$ | async as steam; else emptySteam"
            class="btn btn-primary btn-sm"
            (click)="onSubmitTradeLink(tradeLinkForm.value['steam'])"
            [disabled]="isDisabled(tradeLinkForm.value['steam'], steam)"
          >
            {{ 'P2P_SETTINGS.TRADE_LINK.ADD_TRADE_LINK' | translate }}
          </button>
          <ng-template #emptySteam>
            <button
              type="button"
              class="btn btn-primary btn-sm"
              (click)="onSubmitTradeLink(tradeLinkForm.value['steam'])"
              [disabled]="!tradeLinkForm.value['steam']"
            >
              {{ 'P2P_SETTINGS.TRADE_LINK.ADD_TRADE_LINK' | translate }}
            </button>
          </ng-template>
          <a
            href="//steamcommunity.com/id/me/tradeoffers/privacy#trade_offer_access_url"
            target="_blank"
          >
            {{ 'P2P_SETTINGS.TRADE_LINK.WHERE_GET_TRADE_LINK' | translate }}
          </a>
        </div>
      </form>
    </ng-container>
    <ng-container *ngIf="data.includes('apiKey')">
      <div class="step-container">
        <div
          [ngClass]="{ 'color-success': apikeyIsValid$ | async }"
          class="circle-step"
        ></div>
        <div class="dashed-line"></div>
      </div>
      <form
        class="setup-form"
        [formGroup]="apiKeyForm"
      >
        <div class="setup-form__label">{{ 'P2P_SETTINGS.API_KEY.LABEL' | translate }}</div>
        <input
          class="setup-form__input"
          type="text"
          [ngClass]="{
            'setup-form__input--submitted': apiKeyForm.value['submitted'],
            'setup-form__input--errors': !apiKeyForm.valid
          }"
          formControlName="apikey"
          [placeholder]="'P2P_SETTINGS.API_KEY.INPUT_PLACEHOLDER' | translate"
        />
        <div class="setup-form__actions">
          <button
            type="button"
            *ngIf="steamApiKey$ | async as apiKey; else emptyKey"
            class="btn btn-primary btn-sm"
            (click)="onSubmitApiKey(apiKeyForm.value['apikey'])"
            [disabled]="isDisabled(apiKeyForm.value['apikey'], apiKey)"
          >
            {{ 'P2P_SETTINGS.API_KEY.ADD_API_KEY' | translate }}
          </button>
          <ng-template #emptyKey>
            <button
              type="button"
              class="btn btn-primary btn-sm"
              (click)="onSubmitApiKey(apiKeyForm.value['apikey'])"
              [disabled]="!apiKeyForm.value['apikey']"
            >
              {{ 'P2P_SETTINGS.API_KEY.ADD_API_KEY' | translate }}
            </button>
          </ng-template>
          <a
            href="//steamcommunity.com/dev/apikey"
            target="_blank"
          >
            {{ 'P2P_SETTINGS.API_KEY.WHERE_GET_API_KEY' | translate }}
          </a>
        </div>
        <div class="setup-form__description">
          {{ 'P2P_SETTINGS.API_KEY.CREATE_API_KEY' | translate }}
        </div>
      </form>
    </ng-container>
  </div>
</div>
<div class="banner-container">
  <img
    class="logo"
    src="//d2lomvz2jrw9ac.cloudfront.net/common/p2p/sih/logo.svg"
    alt=""
  />
  <div>
    <span>{{ 'P2P_SETTINGS.SELL_WITH_SIH' | translate }}</span>
    <a
      class="btn btn-sm btn-primary"
      href="//chrome.google.com/webstore/detail/steam-inventory-helper/cmeakgjggjdlcpncigglobpjbkabhmjl"
      target="_blank"
    >
      {{ 'P2P_SETTINGS.INSTALL' | translate }}
    </a>
  </div>
  <img
    class="money-hands"
    src="//d2lomvz2jrw9ac.cloudfront.net/common/p2p/sih/money-hands.svg"
    alt=""
  />
</div>
