<div class="case-revesion-modal" *ngIf="caseRevision$ | async as caseRevision">
  <fast-ui-case-full-info 
    [caseItem]="caseRevision.revision.case"
    [caseRevision]="caseRevision"
    (onselectRevision)="getCaseRevision($event)">
  </fast-ui-case-full-info>
  <fast-ui-skin-cards-list [caseRevision]="caseRevision">
  </fast-ui-skin-cards-list>
  <button class="btn close-btn" (click)="close()">
    <mat-icon [title]="'GOFAST.CASES.MODAL.REVISION.CLOSE' | translate" class="close-icon" [svgIcon]="'close'">
    </mat-icon>
  </button>
</div>
