<ng-container *ngIf="task">
  <div
    style="position: relative"
    class="ny-quest-card"
    [class.quest-done]="task.rewardTaken"
    [class.hover-done]="task.progress >= task.params.count && !task.rewardTaken"
  >
    <div class="ny-quest-card--term">
      {{ translate | appCurrencyTranslate: task.params }}
      <span
        style="color: var(--color-primary)"
        *ngIf="questGame(task.gameTypeId)"
      >
        <a href="/game/{{ questGame(task.gameTypeId) | lowercase }}">{{ questGame(task.gameTypeId) + ' game' }}</a>
      </span>
    </div>
    <div class="ny-quest-card--progress">
      <ng-container *ngIf="eventName$ | async as eventName">
        <div
          *ngIf="task.progress < task.params.count"
          class="ny-quest-card--progress-bar"
        >
          <div style="padding-bottom: 0.25rem">{{ task.progress }}/{{ task.params.count }}</div>
          <fast-ui-ny-event-progress-bar
            [progress]="questPercent(task.params.count, task.progress)"
            [eventName]="eventName"
          ></fast-ui-ny-event-progress-bar>
        </div>
        <div
          class="ny-quest-card--progress-done"
          *ngIf="task.progress >= task.params.count && task.rewardTaken"
        >
          <div
            class="red-pop"
            *ngIf="eventName === 'ny_china'"
          ></div>
          <div
            class="red-cane"
            *ngIf="eventName === 'ny'"
          ></div>
          <mat-icon
            class="complete-icon"
            svgIcon="success"
          ></mat-icon>
        </div>
      </ng-container>
    </div>

    <div
      *ngIf="task.progress >= task.params.count && !task.rewardTaken"
      style="
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        top: 0;
        left: 0;
        background: rgba(24, 35, 40, 0.9);
        border-radius: 12px;
        align-items: center;
      "
    >
      <ng-container *ngIf="eventName$ | async as eventName">
        <div
          class="red-cane"
          *ngIf="eventName === 'ny'"
          style="width: 2rem; height: 4rem"
        ></div>
        <div
          class="red-pop"
          *ngIf="eventName === 'ny_china'"
          style="width: 2rem; height: 4rem"
        ></div>
      </ng-container>
      <button
        style="width: 90%"
        (click)="takeQuestReward(task.id)"
        class="btn btn-primary"
      >
        {{ 'GOFAST.REGULAR_EVENT.TAKE' | translate }}
      </button>
    </div>
  </div>
</ng-container>
