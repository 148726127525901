export class SetH1 {
  public static readonly type = '[Seo] Set H1';
  constructor(public h1: {value: string, data: any}) {}
}

export class SetMain {
  public static readonly type = '[Seo] Set main';
  constructor(public main: {value: string, data: any}) {}
}


export class SetFaq {
  public static readonly type = '[Seo] Set faq';
  constructor(public faq: {value: string, data: any}) {}
}

export class SetTitle {
  public static readonly type = '[Seo] Set Title';
  constructor(public title: {value: string, data: any}) {}
}

export class SetDescription {
  public static readonly type = '[Seo] Set Description';
  constructor(public description: {value: string, data: any}) {}
}
