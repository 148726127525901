import {DOCUMENT} from "@angular/common";
import {Inject, Injectable} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, Scroll} from "@angular/router";
import {NgScrollbar} from "ngx-scrollbar";
import {map, Observable, of, tap} from "rxjs";
import {delay, filter} from "rxjs/operators";

/** надо доработать щас не очень выглядит*/
@Injectable({
  providedIn: 'root',
})
export class ScrollDispatcherService {
  public scrollContainers: Map<string, NgScrollbar>
  constructor( @Inject(DOCUMENT) private readonly document: Document, private readonly activatedRoute:ActivatedRoute,private readonly router:Router) {
    this.router.events.pipe(filter(event=>event instanceof Scroll)).subscribe((value) => {
      value && value instanceof Scroll && value.anchor && this.scrollTo(value.anchor)
    })

    this.scrollContainers = new Map()
  }
  public registerScroll(scroll:NgScrollbar, name:string){
    if (this.scrollContainers.has(name)){
      return;
    } else {
      this.scrollContainers.set(name,scroll)
    }
  }
  public getScroll(name:string):NgScrollbar|undefined{
      return this.scrollContainers.get(name)
  }
  public scrollTo(selector:string):void{
    this.scrollToGlobal(selector);
  }

  public reachedBottom(scrollName:string,reachedOffset:number=200): Observable<any>{
    const scroll = this.scrollContainers.get(scrollName);
    if(scroll){
     return scroll.scrolled.pipe(
       //доработать возможно с направлением
        map((e: any) => e.target.scrollHeight - e.target.scrollTop<= e.target.offsetHeight+reachedOffset ),
      )
    }
    return of()
  }
  public scrollToGlobal(selector:string):void{
    const getElement =()=>this.document.querySelector(`#${selector}`);
    // const element = getElement();
    //  if(element) {
    //    element.scrollIntoView();
    //  } else {
       //Знаю что костыляка санная, но когданибудь еще вернусь сюда
       const timeout = setTimeout(()=>{
         const el = getElement();
         if(el) {
           el.scrollIntoView();
         }
         clearTimeout(timeout);
       },300)

     // }
  }
}
