import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, NgModule } from '@angular/core';
import { LazyLoadImageModule } from '@csgofast/shared/directives';
import { AppCurrencyModule } from '@csgofast/shared/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { colorDictionary } from '@csgofast/shared/utils';

@Component({
  selector: 'fast-ui-skin-card',
  templateUrl: './skin-card.component.html',
  styleUrls: ['./skin-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkinCardComponent {
  @Input() public index = 0;
  @Input() public weaponName: string | null = null;
  @Input() public skinName: string | null = null;
  @Input() public skinWear: string | null = null;
  @Input() public price = 0;
  @Input() public chance = 0;
  @Input() public avatarSrc: string | null = null;
  @Input() public backgroundColor: string | null = null;
  @Input() public range?: {min: number | undefined, max: number | undefined};

  public indexColor(index: number): string {
    return index % 2 === 0 ? 'light' : 'dark';
  }
  public calcChancePercent(count: number): string {
    return `${count / 1000} %`;
  }
  public getStyle(color: string): { background: string } {
    const colorDTO = color;
    const newColor = colorDictionary[colorDTO.toUpperCase()];
    return {
      background: `linear-gradient(180.57deg, #213137 0%, ${newColor} 100%)`,
    };
  }
}
@NgModule({
  imports: [CommonModule, TranslateModule, LazyLoadImageModule, AppCurrencyModule, MatTooltipModule],
  declarations: [SkinCardComponent],
  exports: [SkinCardComponent],
})
export class SkinCardModule {}
