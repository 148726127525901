import {
  GameStatusesFF,
  IActivities,
  IGame,
  IGameSettings,
  IInventoryItem,
  LegacyGameConfig,
  LegacyGameState,
  LegacyLiteGameState,
} from '@dev-fast/types';

export interface GamesStateModel {
  gamesList: IGameSettings[];
  currentGame: IGameSettings | null;
  statuses: GameStatusesFF;
  online: number;
  availableGames: IGame[];
  currentGameActivities: IActivities | null;

  // TODO считал не целесообразным делать отдельный стейт для легаси игр. Короче пока так, поидеи это не на долго
  curLegacyGame: 'classic' | 'fast' | 'x50' | 'crash' | 'none';
  autoBetIsOn: boolean;
  legacyState: LegacyGameState | null;
  legacyLiteState: LegacyLiteGameState | null;
  legacyConfig: LegacyGameConfig | null;
  legacyParticipation: IInventoryItem[];
  legacyLastGameNumber: number | null;
}

export const GAMES_INITIAL_STATE: GamesStateModel = {
  gamesList: [],
  currentGame: null,
  statuses: {},
  online: 0,
  availableGames: [],
  currentGameActivities: null,

  curLegacyGame: 'none',
  autoBetIsOn: false,
  legacyState: null,
  legacyLiteState: null,
  legacyConfig: null,
  legacyParticipation: [],
  legacyLastGameNumber: null,
};
