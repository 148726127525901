<div class="category-container">
  <div class="items-category-wrapper">
    <ng-container *ngIf="!isServer && (collection?.cases); else mock">
      <ng-container *ngIf="(collection?.cases); else emptySearchTemplate">
        <div class="items-category-container">
        <fast-ui-case-card
          *ngFor="let item of (collection?.cases) || [];
          trackBy: trackById; let playerIndex = index"
          [item]="item"
          [eventName]="eventName"
          @swipeCategory>
        </fast-ui-case-card>
        </div>
      </ng-container>
    </ng-container>
    <ng-template #mock>
      <div class="items-category-container items-category-container_mock">
        <fast-ui-case-card-mock></fast-ui-case-card-mock>
        <fast-ui-case-card-mock></fast-ui-case-card-mock>
        <fast-ui-case-card-mock></fast-ui-case-card-mock>
        <fast-ui-case-card-mock></fast-ui-case-card-mock>
        <fast-ui-case-card-mock></fast-ui-case-card-mock>
        <fast-ui-case-card-mock></fast-ui-case-card-mock>
        <fast-ui-case-card-mock></fast-ui-case-card-mock>
        <fast-ui-case-card-mock></fast-ui-case-card-mock>
        <fast-ui-case-card-mock></fast-ui-case-card-mock>
        <fast-ui-case-card-mock></fast-ui-case-card-mock>
        <fast-ui-case-card-mock></fast-ui-case-card-mock>
        <fast-ui-case-card-mock></fast-ui-case-card-mock>
      </div>
    </ng-template>
    <ng-template #emptySearchTemplate>
      <div class="collection_is_none">
        <mat-icon class="empty_search" [svgIcon]="'empty-search'"></mat-icon>
        <div>
          <div class="empty_search-title">
            {{ 'GOFAST.CASES.CATEGORIES.FILTERS.NOT_FOUND_WARNING.WARNING_TITLE' | translate }}
          </div>
          <div class="empty_search-description">
            {{ 'GOFAST.CASES.CATEGORIES.FILTERS.NOT_FOUND_WARNING.WARNING_TEXT' | translate }}
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
<ng-template #loaderTemplate>
  <div class="loader--container" *ngIf="!collection?.cases">
    <fast-ui-cube-grid-loader class="inventory-loader-wrapper" [size]="'5rem'"
      [color]="'var(--color-primary)'"></fast-ui-cube-grid-loader>
  </div>
</ng-template>
