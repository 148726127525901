/**
 - taken награда забрана
 - available доступна награда
 - blocked в процессе типа
 - opened когда кейс открыл из приза
 */
export enum ICasesQuestStatus {
  TAKEN = 'taken',
  OPENED = 'opened',
  AVAILABLE = 'available',
  BLOCKED = 'blocked',
}
export enum ICaseType {
  PAID = 'paid',
  FREE = 'free',
  PAID_FREE = 'paid-free',
}
