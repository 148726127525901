import {SocialGroup} from '../entities';
import {SocialType} from '../enums';

export const SOCIAL_GROUP: SocialGroup[] = [
  {
    link: 'https://discord.gg/NZmNbBj',
    type: SocialType.Discord,
  },
  {
    link: 'https://www.youtube.com/channel/UC8W2x0NiTj0hGAza6RuCJIg',
    type: SocialType.Youtube,
  },
  {
    link: 'https://www.facebook.com/csgofastcom',
    type: SocialType.Facebook,
  },
  {
    link: 'https://vk.com/csgofast_official',
    type: SocialType.Vkontakte,
  },

  {
    link: 'https://www.instagram.com/csgofastcom',
    type: SocialType.Instagram,
  },

  {
    link: 'https://twitter.com/csgofast_com',
    type: SocialType.Twitter,
  },
];
