import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, Renderer2 } from '@angular/core';
// import { ChatraService } from '@csgofast/core/chatra-service';
// import { CoreLicenseService } from '@csgofast/core/license-service';
import { getBaseDomain } from '@csgofast/shared/utils';
import { fromEvent } from 'rxjs';
import { CookieService } from "@csgofast/core/cookie-service";
import { environment } from "../environments/environment";
import { RouterService } from "@csgofast/core/router-service";

@Component({
  selector: 'csgofast-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    private elementRef: ElementRef,
    // private readonly coreLicenseService: CoreLicenseService,
    private readonly cookieService: CookieService,
    private readonly routerService: RouterService,
    @Inject('isServer') private isServer: boolean,
) {
    this.setupResizeWatcher();
    if (window.location.protocol === 'http:' && environment.production && !isServer) {
      window.location.href = location.href.replace('http', 'https');
    }
    cookieService.setCookie('angularHost', window.location.host, {
      path: '/',
      expires: 'Tue, 19 Jan 2038 03:14:07 GMT'
    });
  }
  public ngOnInit(): void {
    if(!this.isServer) {
      // this.coreLicenseService.appendLicenseScript();
    }
  }
  private setupResizeWatcher(): void {
    this.updatePosition();
    fromEvent(window, 'resize').subscribe(() => this.updatePosition());
  }

  private measureHeight(): number | null {
    if (!this.isClient()) return null;
    return document.documentElement?.clientHeight || window.innerHeight;
  }

  private isClient(): boolean {
    return typeof window !== 'undefined' && typeof document !== 'undefined';
  }

  private updatePosition() {
    const height = this.measureHeight();
    this.elementRef.nativeElement.style.height = height ? `${height}px` : '100vh';
    const vh = window.innerHeight * 0.01;
    // TODO: will be fixed in SSR v16 angular https://github.com/angular/angular/issues/42170
    if (!this.isServer) {
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
  }

  // public ngOnInit() {
  // if (environment.production) {
  //   this.loadScript(
  //     `(function (d, w, c) {
  //     w.ChatraID = 'qvZgbeMnBjhM34itq';
  //     var s = d.createElement('script');
  //     w[c] =
  //       w[c] ||
  //       function () {
  //         (w[c].q = w[c].q || []).push(arguments);
  //       };
  //     s.async = true;
  //     s.src = 'https://call.chatra.io/chatra.js';
  //     if (d.head) d.head.appendChild(s);
  //   })(document, window, 'Chatra');`
  //     // () => {
  //     //   console.log('loaded');
  //
  //     //   // do your work after load
  //     // }
  //   );
  // this.loadScript(
  //   `(function(d, t){
  //     var key = 'VTGxT';
  //     var g = d.createElement(t), s = d.getElementsByTagName(t)[0];
  //       g.src = "https://js.gleam.io/"+key+"/trk.js"; s.parentNode.insertBefore(g, s);
  //   }(document, "script"));
  //   window.Gleam = window.Gleam || [];
  //   `
  // );
  // // <!-- Google Tag Manager -->
  // this.loadScript(
  //   `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  //   j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  //   'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  //       })(window,document,'script','dataLayer','GTM-TQKXK3');`
  // );
  // // <!-- End Google Tag Manager -->
  // }
  // }

  // public loadScript(text: string) {
  //   const script: any = this._renderer2.createElement('script');
  //   script.type = `text/javascript`;
  //   script.text = text;
  //   this._renderer2.appendChild(this._document.body, script);
  //   // script.addEventListener('load', () => {
  //   //   afterload();
  //   // });
  // }
}
