export enum ModalNames {
  TECH_SUPPORT_SELECT_METHOD,
  USER_PROFILE,
  LOTTERY_RULES,
  AUTH,
  REFERRAL,
  P2P_SETUP,
  P2P_AUCTION_COMPLETED,
  NY_EVENT_TERMS,
  NY_EVENT_REWARD,
  GLEAM,
  DDOS,
  BAN,
  OFFERWALL,
  STEAM_ERROR,
  SERVER_SEED_HISTORY,
  CLIENT_SEED_HISTORY,
  // --------------Cases-----------//
  CASE_REVISION,
  AUTOPLAY_GAME,
  // --------------Case-battle-----------//
  CASE_BATTLE_WINNERS,
  CASE_BATTLE_ACCOUNT_NOTY,
  CASE_BATTLE_GAME_BREAK,
  CASE_BATTLE_CASE_INFO,
  CASE_BATTLE_PROVABLY_FAIR,
}
