import { ChangeDetectionStrategy, Component, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ChatraService } from "@csgofast/core/chatra-service";
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'csgofast-tech-support',
  templateUrl: './tech-support.component.html',
  styleUrls: ['./tech-support.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TechSupportComponent {
  constructor(private readonly dialogRef: MatDialogRef<TechSupportComponent>,private readonly chatraService: ChatraService) {}

  public close(): void {
    this.dialogRef.close();
  }
  public openChatra(): void {
    this.chatraService.openChat();
    this.close();
  }
  public hasChatra(): boolean {
   return this.chatraService.hasChatra();
  }
}

@NgModule({
  imports: [CommonModule, MatIconModule, TranslateModule, RouterModule],
  declarations: [TechSupportComponent],
  exports: [TechSupportComponent],
})
export class TechSupportComponentModule {}
