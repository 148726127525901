<div
  *ngIf="item$ | async as data"
  class="p2p-price-range-selector"
>
  <div
    class="modal-dialog modal-content"
    role="document"
  >
    <div class="p2p-price-range-selector-wrapper">
      <h4 class="p2p-price-range-selector-header">
        {{ 'P2P_WIDGETS.DEPOSIT_MODIFIER_DIALOG.SET_CUSTOM_PRICE' | translate }}
      </h4>
      <div class="p2p-price-range-selector-description">
        {{ 'P2P_WIDGETS.DEPOSIT_MODIFIER_DIALOG.TIME_BETWEEN_BETS_IS_REDUCED' | translate }}
      </div>
      <div class="p2p-price-range-selector-container skin-lot-item-container">
        <div class="auction-complete-item-image-container skin-lot-item-image-container">
          <img
            class="auction-complete-item-image skin-lot-item-image"
            skinItemImage
            [src]="data.baseItem.icon"
           alt=""/>
        </div>
        <ng-container *ngIf="data.baseItem.name; else noNameTemplate">
          <div class="auction-complete-item-name skin-lot-item-name">{{ data.baseItem.name }}</div>
        </ng-container>
        <ng-template #noNameTemplate>
          <div class="auction-complete-item-float skin-lot-item-float">
            {{ data.baseItem.exterior }}
          </div>
          <div class="auction-complete-item-name skin-lot-item-name">{{ data.baseItem.shortName }} | {{ data.baseItem.skin }}</div>
        </ng-template>

        <div class="auction-complete-item-price skin-lot-item-price">{{ price(data.price) | appCurrency }}</div>

        <!-- <div class="p2p-price-range-selector-image-container">
          <img
            class="p2p-price-range-selector-image"
            rv-src="model:baseItem.icon | itemSrc"
            alt=""
          />
        </div>
        <div class="p2p-price-range-selector-item-container">
          <div class="p2p-price-range-selector-name">model:baseItem.name</div>
          <div class="p2p-price-range-selector-price">data.selectedPrice | currency</div>
        </div> -->
      </div>
      <div class="p2p-price-slider-container">
        <div class="modifier-container__range">
          <div
            class="range-label"
            [style.left]="sliderLabelOffset(range(data.price, data.priceRange.start), range(data.price, data.priceRange.end))"
          >
            <ng-container *ngIf="increase; else modifying">
              <span [ngClass]="{ positive: increase > 4, negative: increase < -4 }"> {{ increase }}% </span>
            </ng-container>
            <ng-template #modifying>
              <span>0%</span>
            </ng-template>
          </div>
          <mat-slider
            (input)="onChange($event)"
            [min]="range(data.price, data.priceRange.start)"
            [max]="range(data.price, data.priceRange.end)"
            [step]="1"
            [value]="increase"
            formControlName="increase"
          >
          </mat-slider>
        </div>
        <div class="slider-description">
          <div
            class="p2p-price-slider-text-green"
            *ngIf="increase < 1"
          >
            {{ 'P2P_WIDGETS.DEPOSIT_MODIFIER_DIALOG.QUICK_SELL' | translate }}
          </div>
          <div
            class="p2p-price-slider-text-red"
            *ngIf="increase > 1"
          >
            {{ 'P2P_WIDGETS.DEPOSIT_MODIFIER_DIALOG.SLOW_SELL' | translate }}
          </div>
        </div>
      </div>
      <button
        class="btn btn-primary btn-lg p2p-price-accept-button"
        (click)="confirm(price(data.price))"
      >
        {{ 'P2P_WIDGETS.DEPOSIT_MODIFIER_DIALOG.ACCEPT_BUTTON' | translate }}
      </button>
      <button
        class="btn btn-sm btn-link p2p-price-close-button"
        (click)="cancel()"
      >
        {{ 'P2P_WIDGETS.DEPOSIT_MODIFIER_DIALOG.CANCEL_BUTTON' | translate }}
      </button>
    </div>
    <div class="p2p-price-settings">
      <h4 class="p2p-price-settings__header">
        {{ 'P2P_WIDGETS.DEPOSIT_MODIFIER_DIALOG.ADDITION_SETTINGS' | translate }}
      </h4>

      <div class="p2p-settings-control">
        <csgofast-p2p-settings></csgofast-p2p-settings>
      </div>
    </div>
  </div>
</div>

<!-- <form
  class="modifier-container"
  [formGroup]="modifierForm"
  *ngIf="item$ | async as item"
>
  <div class="modifier-container__header">
    <mat-icon svgIcon="failure"></mat-icon>
    <span>
      {{ 'P2P_WIDGETS.DEPOSIT_MODIFIER_DIALOG.SET_CUSTOM_PRICE' | translate }}
    </span>
  </div>
  <div class="modifier-container__deposit-lot">
    <div class="thumbnail">
      <img
        [src]="item.baseItem.icon"
        alt=""
      />
    </div>
    <div class="details">
      <div class="shortName">
        {{ item.baseItem.shortName || item.baseItem.name }}
      </div>
      <div class="display-name">
        {{ item.baseItem.skin }}
      </div>
      <div class="price">
        <span>
          {{ price(item.price) | appCurrency }}
        </span>
      </div>
    </div>
  </div>
  <div class="modifier-container__range">
    <div class="range-label">
      <span>
        {{ 'P2P_WIDGETS.DEPOSIT_MODIFIER_DIALOG.PRICE_INCREASE' | translate }}
      </span>
      <ng-container *ngIf="increase; else modifying">
        <span [ngClass]="{ positive: increase > 0, negative: increase < -4 }"> {{ increase }}% </span>
      </ng-container>
      <ng-template #modifying>
        <span>0%</span>
      </ng-template>
    </div>
    <mat-slider
      (input)="onChange($event)"
      [min]="range(item.price, item.priceRange.start)"
      [max]="range(item.price, item.priceRange.end)"
      [step]="1"
      formControlName="increase"
    >
    </mat-slider>
    <div class="range-sublabel">
      {{ 'P2P_WIDGETS.DEPOSIT_MODIFIER_DIALOG.TIME_BETWEEN_BETS_IS_REDUCED' | translate }}
    </div>
  </div>
  <div class="modifier-container__action">
    <button
      class="btn btn-primary"
      (click)="confirm(price(item.price))"
    >
      {{ 'CONFIRM' | translate }}
    </button>
  </div>
</form> -->
