<!-- <ng-container *ngIf="message">
  <fast-ui-user-avatar
    *ngIf="message.user"
    [url]="message.user.avatar"
    [userId]="message.user.id"
  ></fast-ui-user-avatar>
  <fast-ui-user-avatar *ngIf="!message.user"></fast-ui-user-avatar>

  <div class="message-box">
    <div class="message-name">
      <div
        class="message-game-type icomoon"
        *ngIf="message.game !== 'common'"
        [ngClass]="getGameModeAndType(message.game)"
      ></div>
      <div
        class="message-username"
        *ngIf="message.user"
        [ngClass]="message.user.profileType"
      >
        {{ message.user.name }}
      </div>
      <div class="message-date">{{ message.createdAt | date: 'hh:mm' }}</div>
    </div>

    <div
      class="message-text"
      *ngIf="checkMessageType(message.value)"
    >
      {{ message.value }}
    </div>

    <div
      class="message-sticker"
      *ngIf="!checkMessageType(message.value)"
    >
      <img
        lazyLoadImage
        [src]="getSticker(message.value)"
        alt=""
      />
    </div>
  </div>
</ng-container> -->

<ng-container *ngIf="message">
  <div class="chat-message">
    <div class="chat-message__avatar">
      <fast-ui-user-avatar
        *ngIf="message.user as user"

        [url]="user.avatar"
        [type]="user.profileType"
        [userId]="user.id"
      ></fast-ui-user-avatar>
    </div>
    <div class="chat-message__username">{{ message.user.name }}:</div>
    <div
      class="chat-message__text"
      *ngIf="checkMessageType(message.value)"
    >
      {{ message.value }}
    </div>
    <div
      class="chat-message__sticker"
      *ngIf="!checkMessageType(message.value)"
    >
      <img
        lazyLoadImage
        [src]="getSticker(message.value)"
        alt=""
      />
    </div>
    <div class="chat-message__time">{{ message.createdAt | date: 'HH:mm:ss' }}</div>
  </div>
</ng-container>
