export class Timer {
  public id: number;
  private timerId: number;
  private start: number;
  private remaining: number;
  private callback: () => void;

  constructor(id: number, callback: () => void, delay: number) {
    this.id = id;
    this.timerId = delay;
    this.start = delay;
    this.remaining = delay;
    this.callback = callback;
    this.resume();
  }

  public pause() {
    clearTimeout(this.timerId);
    this.remaining -= Date.now() - this.start;
  }

  public resume() {
    this.start = Date.now();
    clearTimeout(this.timerId);
    // @ts-ignore
    this.timerId = setTimeout(this.callback, this.remaining);
  }
}
