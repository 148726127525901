<!-- <a class="games-item"
     [ngClass]="[game.key, (currentGame$ | async)?.key === game.key ? 'selected' : '']"
     [routerLink]="'/game/' + game.key"
     (click)="closePanel()"
     tabindex="1"
  > -->
<div
  class="games-item"
  [ngClass]="[game.key]"
>
  <div class="games-item-wrapper">
    <img
      class="games-item-substrate"
      [src]="getGameSrc(game, 'substrate')"
      [ngClass]="game.key"
      alt=""
    />
    <img
      class="games-item-layer"
      [src]="getGameSrc(game, 'layer')"
      alt=""
    />
  </div>
  <div class="games-item-name">
    <!-- <div class="games-item-icon">
      <span
        class="icomoon"
        [ngClass]="'icon-logo-' + game.key"
      ></span>
    </div> -->
    <span>{{ game.title | titlecase }}</span>
  </div>
</div>
<!-- </a> -->
