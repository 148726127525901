import { Injectable } from '@angular/core';
import { AuthModalComponent } from '@csgofast/auth';
import { NyRewardModalComponent, NyTermsModalComponent } from '@csgofast/modules/events';
import {
  BanComponent,
  DdosModalComponent,
  GleamModalComponent,
  LotteryRulesModalComponent,
  OfferwallModalComponent,
  ReferralModalComponent,
  SteamErrorModalComponent,
  TechSupportComponent,
  UserProfileComponent,
} from '@csgofast/shared/modals';
import { P2pAuctionCompletedModalComponent, P2pSetupModalComponent } from '@csgofast/widgets/p2p';
import { ModalsService } from './modals.service';

import { ModalModel, ModalNames } from '@dev-fast/types';

@Injectable()
export class MainModalsService {
  private modals: ModalModel[] = [
    {
      name: ModalNames.USER_PROFILE,
      component: UserProfileComponent,
      config: {
        panelClass: 'user-profile-modal',
        autoFocus: false,
      },
    },
    {
      name: ModalNames.AUTH,
      component: AuthModalComponent,
      config: {
        height: 'auto',
        panelClass: 'auth-modal',
      },
    },
    {
      name: ModalNames.TECH_SUPPORT_SELECT_METHOD,
      component: TechSupportComponent,
      config: {
        // width: '35.75rem',
        height: 'auto',
        panelClass: 'tech-support-modal',
      },
    },
    {
      name: ModalNames.LOTTERY_RULES,
      component: LotteryRulesModalComponent,
      config: {
        panelClass: 'lottery-rules-modal',
      },
    },

    {
      name: ModalNames.REFERRAL,
      component: ReferralModalComponent,
      config: {
        height: 'auto',
        panelClass: 'referral-modal',
        backdropClass: 'referral-modal-backdrop',
      },
    },
    {
      name: ModalNames.P2P_SETUP,
      component: P2pSetupModalComponent,
      config: {
        panelClass: 'p2p-setup-modal',
      },
    },
    {
      name: ModalNames.P2P_AUCTION_COMPLETED,
      component: P2pAuctionCompletedModalComponent,
      config: {
        panelClass: 'p2p-auction-completed-modal',
      },
    },
    {
      name: ModalNames.NY_EVENT_TERMS,
      component: NyTermsModalComponent,
      config: {
        maxHeight: '90vh',
      },
    },
    {
      name: ModalNames.NY_EVENT_REWARD,
      component: NyRewardModalComponent,
      config: {
        height: 'auto',
      },
    },
    {
      name: ModalNames.GLEAM,
      component: GleamModalComponent,
      config: {
        panelClass: 'gleam-modal',
        maxHeight: '100vh',
        minWidth: '100vw',
        height: 'calc(var(--vh, 1vh) * 100)',
        width: '100vw',
        autoFocus: false,
      },
    },
    {
      name: ModalNames.DDOS,
      component: DdosModalComponent,
      config: {
        panelClass: 'ddos-modal',
        height: '95vh',
        width: '95vw',
        maxWidth: '95vw',
      },
    },
    {
      name: ModalNames.BAN,
      component: BanComponent,
      config: {
        panelClass: 'ban-modal',
        maxWidth: '95vw',
        autoFocus: false,
        disableClose: true,
      },
    },
    {
      name: ModalNames.OFFERWALL,
      component: OfferwallModalComponent,
      config: {
        panelClass: 'offerwall-modal',
        maxHeight: 'calc(var(--vh, 1vh) * 100)',
        maxWidth: '100vw',
        height: '700px',
        width: '500px',
        autoFocus: false,
      },
    },
    {
      name: ModalNames.STEAM_ERROR,
      component: SteamErrorModalComponent,
      config: {
        height: 'auto',
        panelClass: 'steam-error-modal',
        autoFocus: false,
      },
    },
  ];
  constructor(private readonly commonModalsService: ModalsService) {}

  public registerModals(): void {
    this.commonModalsService.registerModals(this.modals);
  }
}
