import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NyResultsShopComponent } from './ny-results-shop.component';
import { NyResultsShopRoutingModule } from './ny-results-shop-routing.module';
import { MatIconModule } from '@angular/material/icon';
import { AppCurrencyModule, CustomAsyncPipeModule } from '@csgofast/shared/pipes';
import { UserAvatarModule, CountdownComponentModule, CasesCollectionComponentModule } from '@dev-fast/ui-components';
import { TranslateModule } from '@ngx-translate/core';
import { NyEventModule } from '../ny-event';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
  declarations: [NyResultsShopComponent],
  imports: [
    CommonModule,
    NyResultsShopRoutingModule,
    NyEventModule,
    MatIconModule,
    UserAvatarModule,
    CountdownComponentModule,

    CasesCollectionComponentModule,
    TranslateModule,
    AppCurrencyModule,
    CustomAsyncPipeModule,
    NgxSkeletonLoaderModule,

  ],
})
export class NyResultsShopModule {}
