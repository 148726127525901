import {Environments, ENVIRONMENTS_DEFAULT} from '@csgofast/core/environment-service';

// NOTE: Отдельная среда по которой будет собираться проект на отдельный стейдж. По дефолту дублирует дев среду
export const environment: Environments = {
  ...ENVIRONMENTS_DEFAULT,
  production: false,
  ESPORTS_API_URL: 'stage',
  PAYMENT_CONFIG_URL: 'https://backend.cs1go.com/pay-config',
  HOSTNAME: `https://${location.host}`,
  BACKBONE_URL: `https://bridge.devfast.io`,
  POGGI_URL: 'https://d1phhoo4f618zo.cloudfront.net',
  MODULES: {
    account: ['statistics', 'levels', 'settings', 'premium'],
    balance: {
      refill: [],
      withdrawal: ['marketplace', 'currency'],
      history: true,
      referral: true,
    },
    games: [
      'classic',
      'case-battle',
      'fast',
      'double',
      'poggi',
      'baccarat',
      'hilo',
      'x50',
      'crash',
      'slot',
      'tower',
      'fortune-wheel',
      'cases',
      // 'match-bets',
      // 'craft',
    ],
  },
};
