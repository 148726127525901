import { Inject, Injectable } from '@angular/core';
import { Environments, EnvironmentService } from '@csgofast/core/environment-service';
import { IFaq, ILocales } from '@dev-fast/types';
import { Observable, pluck, tap } from 'rxjs';
import { ApiService } from './api.service';
import { makeStateKey, TransferState } from "@angular/platform-browser";

@Injectable({
  providedIn: 'root',
})
export class LocalizationApiService {
  private environment: Environments;


  private readonly DEFAULT_LOCALE_KEY = makeStateKey('default-locale');

  constructor(
    private readonly api: ApiService,
    private readonly environmentService: EnvironmentService,
    private readonly transferState: TransferState,
    @Inject('isServer') private isServer: boolean,
  ) {
    this.environment = this.environmentService.environments;
  }

  public getFAQ = (lang: string): Observable<IFaq[]> =>
    this.api.get<{ data: IFaq[] }>(`${this.environment.LOCALIZATION_URL}/faq/${this.environment.PROJECT}_${lang}`,{ params: { project: this.environment.PROJECT } }).pipe(pluck('data'));

  public getTranslation = (lang: string): Observable<any> => {
    const headers = new Headers({
      'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      'Pragma': 'no-cache',
      'Expires': '0'
    });

    return this.api.get(`${this.environment.LOCALIZATION_URL}/locales/${lang}`, { params: { project: this.environment.PROJECT, headers } })
      .pipe(
        tap((locale) => {
          if(this.isServer) {
            this.transferState.set(this.DEFAULT_LOCALE_KEY, locale);
          }
        }),
      );
  }

  public getTranslationFromAssets = (lang: string): Observable<object> => this.api.get(`${this.environment.HOSTNAME}/assets/i18n/${['ru_RU', 'en_US'].includes(lang) ? lang : 'en_US'}.json`);

  public getAvailableLocales = (): Observable<ILocales[]> =>
    this.api.get<ILocales[]>(`${this.environment.LOCALIZATION_URL}/locales/list`, { params: { project: this.environment.PROJECT } });
}
