import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {
  ActivePanel,
  EventsConfig,
  GameTypes,
  ICaseQuestTotalProgress,
  ICasesQuestItem,
  ILRBalance,
  ILRQuest,
  IUserDetailed,
  lrEventsTranslateDictionary,
  Panel,
  ProfileTypes,
} from '@dev-fast/types';
import {Observable, of, switchMap, take, tap} from 'rxjs';
import { NyAllEventService, NyEventService } from '../../../core';
import { daysToGo, isDays } from '../../../shared/utils';

@Component({
  selector: 'csgofast-ny-event-menu',
  templateUrl: './ny-event-menu.component.html',
  styleUrls: ['./ny-event-menu.component.scss'],
})
export class NyEventMenuComponent {
  public translateDic = lrEventsTranslateDictionary;
  public dailyTasks$: Observable<ILRQuest[]> | undefined;
  public keys$: Observable<ILRBalance | null> | undefined;
  public weeklyTasks$: Observable<ILRQuest[]> | undefined;
  public items$: Observable<ICasesQuestItem[]>;
  public untilDayTaskTime$: Observable<number> | undefined;
  public untilWeeklyTaskTime$: Observable<number> | undefined;
  public totalProgress$: Observable<ICaseQuestTotalProgress> | undefined;
  public user$: Observable<IUserDetailed | null> | undefined;

  public eventName$: Observable<keyof EventsConfig | null> | undefined;
  public untilEventTime$: Observable<number | null>;
  public activePanel$: Observable<ActivePanel | null>;
  public panels = Panel;
  private activePanel: ActivePanel | null | undefined;

  public isEventResults: boolean;

  constructor(
    private readonly nyAllEventService: NyAllEventService,
    private readonly nyEventService: NyEventService,
    private router: Router
  ) {
    this.isEventResults = this.router.url.includes('events/leaders') || this.router.url.includes('events/shop');

    this.untilWeeklyTaskTime$ = this.nyAllEventService.untilWeeklyTaskTime$;
    this.untilDayTaskTime$ = this.nyAllEventService.untilDayTaskTime$;
    this.totalProgress$ = this.nyEventService.totalProgress$;
    this.weeklyTasks$ = this.nyAllEventService.weeklyTasks$;
    this.dailyTasks$ = this.nyAllEventService.dailyTasks$;
    this.keys$ = this.nyAllEventService.keys$;
    this.items$ = this.nyEventService.items$;
    this.user$ = this.nyEventService.user$;

    this.eventName$ = this.nyEventService.eventName$.pipe(switchMap(value => value === null?this.nyEventService.eventHistoryName$:of(value)));
    this.untilEventTime$ = this.nyEventService.untilEventTime$.pipe(switchMap(value => value===null?this.nyEventService.untilEventHistoryTime$:of(value)));
    this.activePanel$ = this.nyEventService.activePanel$.pipe(tap((value) => (this.activePanel = value)));
  }
  public translate(item: ILRQuest): string {
    if (!item) return '';
    return this.translateDic[item.name as keyof typeof lrEventsTranslateDictionary];
  }

  public activeWeeklyQuest(quests: ILRQuest[]): ILRQuest {
    return quests.filter((quest) => quest.isOpened && !quest.rewardTaken)[0];
  }
  public questPercent(count: number, progress: number) {
    return (progress / count) * 100;
  }
  public isDays(date: number) {
    return isDays(date);
  }
  public daysToGo(date: number) {
    return daysToGo(date);
  }

  // EVENT
  public activateEvetMenu() {
    this.handleShowMenu();
    // this.showEventMenu = !this.showEventMenu;
    // if (this.showEventMenu) {
    //   // this.nyAllEventService.getQuests()
    //   // this.nyEventService.getQuest()
    // }
  }
  public naviagateEventMenu(url: string = '/events/ny-event') {
    this.handleShowMenu();

    // this.showEventMenu = !this.showEventMenu;
    this.router.navigate([url]);
  }
  private handleShowMenu() {
    if (this.activePanel && this.activePanel.panel === Panel.EVENT_MENU) {
      this.nyEventService.closeMenu();
    } else {
      this.nyEventService.openMenu();
    }
  }
  public questGame(gameTypeId: number): string {
    return GameTypes[gameTypeId];
  }
  public takeQuestReward(questId: number) {
    console.log('take Quest Reward...');
    this.nyAllEventService.takeReward(questId);
  }
  public rewardQuest(quests: ILRQuest[]): ILRQuest {
    const completed = quests.filter((quest) => quest.isOpened && quest.rewardTaken);
    return completed[completed.length - 1];
  }
  public userStatus(profileType: ProfileTypes): boolean {
    return profileType === ProfileTypes.DEMO || profileType === ProfileTypes.BRONZE;
  }
  public onLogin = () => this.nyEventService.login();

  public onRefill() {
    this.activateEvetMenu();
  }
  public onPresentClick(id: number) {
    this.router.navigate(['/game/cases?'], { fragment: 'casesMain', queryParams: {type: id} });
    this.nyEventService.closeMenu();
  }

  public isEventEnded(eventName: string | null) {
    if (eventName === null) {
      this.eventName$ = this.nyEventService.eventHistoryName$;
      this.untilEventTime$ =  this.nyEventService.untilEventHistoryTime$;
      // this.nyEventService.eventHistoryName$.pipe(take(1)).subscribe((x) => (eventName = x));
      this.isEventResults = true
    }
    return eventName;
  }
}
