import {Component, OnInit} from '@angular/core';
import {validateAccountLevel} from "@csgofast/shared/utils";
import {
  EventsConfig,
  GameTypes,
  ICaseCollection,
  ILRQuest,
  IUserDetailed,
  lrEventsTranslateDictionary,
  ProfileTypes
} from '@dev-fast/types';
import moment from 'moment';
import {map, Observable} from 'rxjs';
import {NyAllEventService, NyEventService} from '../core';
import { isDays, daysToGo } from '../shared/utils';

@Component({
  selector: 'csgofast-ny-all-event',
  templateUrl: './ny-all-event.component.html',
  styleUrls: ['./ny-all-event.component.scss'],
})
export class NyAllEventComponent {
  public translateDic = lrEventsTranslateDictionary;
  public categoriesIsLoad$: Observable<boolean>;
  public snowDecals = [
    [
      {
        image: '/assets/img/ny-event/snow/snow1.png',
        position: 'z-index:1; position: absolute; top: -7px; left: -7px',
      },
      {
        image: '/assets/img/ny-event/snow/snow3.png',
        position: 'z-index:1; position: absolute; top: -4px; left: 70%',
      },
    ],
    [
      {
        image: '/assets/img/ny-event/snow/snow2.png',
        position: 'z-index:1; position: absolute; top: -4px; left: 36%',
      },
    ],
    [
      {
        image: '/assets/img/ny-event/snow/snow3.png',
        position: 'z-index:1; position: absolute; top: -4px; left: 4%',
      },
    ],
    [
      {
        image: '/assets/img/ny-event/snow/snow5.png',
        position: 'z-index:1; position: absolute; top: -4px; left: 4%',
      },
    ],
    [
      {
        image: '/assets/img/ny-event/snow/snow1.png',
        position: 'z-index:1; position: absolute; top: -7px; left: -7px',
      },
      {
        image: '/assets/img/ny-event/snow/snow2.png',
        position: 'z-index:1; position: absolute; top: -4px; left: 36%',
      },
    ],
    [
      {
        image: '/assets/img/ny-event/snow/snow6.png',
        position: 'z-index:1; position: absolute; top: -4px; left: 48%',
      },
    ],
  ];
  public dailyTasks$: Observable<ILRQuest[]> | undefined;
  public weeklyTasks$: Observable<ILRQuest[]> | undefined;
  public untilDayTaskTime$: Observable<number> | undefined;
  public untilWeeklyTaskTime$: Observable<number> | undefined;
  public casesCollection$: Observable<ICaseCollection> | undefined;
  public eventName$: Observable<keyof EventsConfig | null> | undefined;
  public user$: Observable<IUserDetailed | null> | undefined;

  constructor(private readonly nyAllEventService: NyAllEventService, private readonly nyEventService: NyEventService) {
    this.categoriesIsLoad$ = this.nyAllEventService.getCasesIsExecuting$.pipe(map((el) => !!el));

    this.eventName$ = this.nyEventService.eventName$;

    this.user$ = this.nyAllEventService.user$;
    this.casesCollection$ = this.nyAllEventService.cases$;
    this.dailyTasks$ = this.nyAllEventService.dailyTasks$;
    this.weeklyTasks$ = this.nyAllEventService.weeklyTasks$;
    this.untilDayTaskTime$ = this.nyAllEventService.untilDayTaskTime$;
    this.untilWeeklyTaskTime$ = this.nyAllEventService.untilWeeklyTaskTime$;


  }
  public translate(item: ILRQuest): string {
    if (!item) return '';
    return this.translateDic[item.name as keyof typeof lrEventsTranslateDictionary];
  }

  public openEventTerms() {
    this.nyAllEventService.showEventTermsModal();
  }
  public calcProgressPercent(count: number, progress: number): number {
    // return `clc(100+${progress})`
    return (progress * 100) / count;
  }

  public takeQuestReward(questId: number) {
    this.nyAllEventService.takeReward(questId);
  }
  public isDays(date: number) {
    return isDays(date);
  }
  public daysToGo(date: number) {
    return daysToGo(date);
  }
  // public isDays(date: number) {
  //   return +moment(date).format('D') <= 1;
  // }
  // public daysToGo(date: number) {
  //   return moment(date).format('D');
  // }
  public questGame(gameTypeId: number): string {
    return GameTypes[gameTypeId];
  }
  public activeWeeklyQuest(quests: ILRQuest[]): ILRQuest {
    return quests.filter((quest) => quest.isOpened && !quest.rewardTaken)[0];
  }
  public canGame(user: IUserDetailed|null):boolean{
    if (!user) return false
   return validateAccountLevel(user.profileType,ProfileTypes.SILVER)
  }
}
