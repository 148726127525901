import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { Environments, EnvironmentService } from '@csgofast/core/environment-service';
import { AnalyticsApiService } from '@dev-fast/backend-services';
import { Affise, RefillSystems } from '@dev-fast/types';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private readonly window: Window | null;
  private environment: Environments;
  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly environmentService: EnvironmentService,
    private readonly api: AnalyticsApiService
  ) {
    this.environment = this.environmentService.environments;
    this.window = this.document.defaultView;
    if (this.window) {
      this.window.dataLayer = this.window.dataLayer || [];
    }
  }

  public registrationEvent(provider?: string): void {
    if (!this.window || !this.environment.production) return;
    this.window.dataLayer.push({
      event: 'event-signup',
      // loginMethod: provider,
      Category: 'signup',
      Action: 'Success',
      Lable: 'signup',
    });
  }

  public successfulDepositEvent(payment: { userId?: number; amount: number }): void {
    if (!this.window || !this.environment.production) return;
    this.window.dataLayer.push({
      event: 'event-deposit',
      Category: 'deposit',
      Action: 'Success',
      Lable: 'deposit',
      Amount: payment.amount,
    });
  }

  public depositEvent(payment: { payment: RefillSystems; amount: number }): void {
    if (!this.window || !this.environment.production) return;
    this.window.dataLayer.push({
      event: 'event-deposit',
      Category: 'deposit',
      Action: 'begin',
      Lable: payment.payment,
      Amount: payment.amount / 100,
    });
  }

  public betEvent(gameName: string): void {
    if (!this.window || !this.environment.production) return;
    this.window.dataLayer.push({
      event: `event-${gameName}`,
      Category: 'Bet',
      Action: 'Success',
      Lable: gameName,
    });
  }

  public addUserIdEvent(userid: number): void {
    if (!this.window || !this.environment.production) return;
    if (this.window.dataLayer.find((val) => val.userID)) return;

    this.window.dataLayer.push({
      userID: userid,
    });
  }

  /** FIXME нельзя вроде так делать */
  public resetDataLayer(): void {
    if (!this.window || !this.environment.production) return;
    this.window.dataLayer = [];
  }

  public affiseEvent(payload: Affise): Observable<void> {
    return this.api.affisePost(payload);
  }
}
