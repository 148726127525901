import {animate, style, transition, trigger} from '@angular/animations';

export const notificationSlideLeftAnimation = trigger('notificationSlideLeftAnimation', [
  transition(':enter', [
    style({ opacity: 0, transform: 'translateX(100%)' }),
    animate('300ms ease-in', style({ opacity: 1, transform: 'translateX(0%)' })),
  ]),
  transition(':leave', [
    style({ opacity: 1, transform: 'translateX(0%)' }),
    animate('300ms ease-out', style({ opacity: 0, transform: 'translateX(100%)' })),
    animate('150ms ease-out', style({ height: 0 })),
  ]),
]);
