import moment from "moment";

export const calcDuration = (date: number): number => {
  const duration = moment.duration(date, 'milliseconds');
  return duration.asDays();
};
export const isDays = (date: number) => {
  return calcDuration(date) <= 1;
};
export const daysToGo = (date: number) => {
  return Math.ceil(calcDuration(date));
};
