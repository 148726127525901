import {IconConfig} from "./icon.model";

export const iconsPaymentProviders: IconConfig[] = [
  {
    name: 'g2a',
    path: 'assets/img/mat-icons/payment-providers/g2a.svg',
  },
  {
    name: 'game-money',
    path: 'assets/img/mat-icons/payment-providers/game-money.svg',
  },
  {
    name: 'skrill',
    path: 'assets/img/mat-icons/payment-providers/skrill.svg',
  },
];
