<!-- sell ban -->
<ng-container *ngIf="sellerBanEndAt$ | async as sellerBanEndAt; else sellerLayout">
  <div class="seller-ban-container">
    <mat-icon svgIcon="p2p-ban-icon"></mat-icon>
    <span>{{ 'P2P_WIDGETS.DEPOSITING.YOU_ARE_BANNED' | translate }}</span>

    <fast-ui-countdown
      [until]="sellerBanEndAt"
      [showHours]="true"
      [minutes]="0.92"
      [onlyText]="true"
    >
    </fast-ui-countdown>
    <p>{{ 'P2P_WIDGETS.DEPOSITING.YOU_CAN_SEND_MAIL_TO_SUPPORT' | translate }}</p>
    <button
      class="btn btn-primary"
      (click)="openTechSupportDialog()"
    >
      {{ 'P2P_WIDGETS.DEPOSITING.SEND_MAIL' | translate }}
    </button>
  </div>
</ng-container>

<ng-template #sellerLayout>
  <!-- <ng-container *ngIf="true; else inviteLayout"> -->
  <ng-container *ngIf="isEmptySeller$ | async; else inviteLayout">
    <ng-container *ngIf="selected$ | async as selected">
      <button
        class="btn btn-link p2p-cancel-button"
        *ngIf="selected.length"
        (click)="onResetSelected()"
      >
        {{ 'Reset selected' | translate }}
      </button>
    </ng-container>
    <ng-container *ngIf="depositing$ | async as depositing">
      <div
        class="p2p-control-header-panel"
        *ngIf="depositing.length"
      >
        <ng-container *ngIf="isItemsOnPause$ | async; else notPauseTemplate">
          <button
            class="btn p2p-control-button p2p-stop-button"
            (click)="onStopAvailableItems()"
          >
            <mat-icon svgIcon="p2p-remove-trade-icon"></mat-icon>
            <span>{{ 'Stop' | translate }}</span>
          </button>
          <button
            class="btn p2p-control-button p2p-start-button"
            (click)="onResumeAvailableItems()"
          >
            <mat-icon svgIcon="p2p-start-trade-icon"></mat-icon>
            <span>{{ 'Start' | translate }}</span>
          </button>
        </ng-container>
        <ng-template #notPauseTemplate>
          <button
            class="btn p2p-control-button p2p-pause-button"
            (click)="onPauseAvailableItems()"
          >
            {{ 'Stop trades' | translate }}
          </button>
        </ng-template>
      </div>
    </ng-container>

    <ng-scrollbar
      class="container-viewport"
      [autoHeightDisabled]="false"
    >
      <div class="container-content">
        <ng-container *ngIf="selected$ | async as selected">
          <csgofast-p2p-sell-setup-item
            *ngFor="let entity of selected"
            class="container-content__item"
            [item]="entity"
          >
          </csgofast-p2p-sell-setup-item>
        </ng-container>
        <ng-container *ngIf="depositing$ | async as depositing">
          <csgofast-p2p-selling-item
            *ngFor="let entity of depositing; trackBy: trackingFunction"
            class="container-content__item"
            [id]="entity.id"
          >
          </csgofast-p2p-selling-item>
        </ng-container>
      </div>
    </ng-scrollbar>
    <ng-container *ngIf="permissions$ | async as permissions">
      <ng-container *ngIf="permissions?.success">
        <div class="footer">
          <div
            class="p2p-income-container"
            *ngIf="selectedSum$ | async as sum"
          >
            <span class="p2p-income-container__text">{{ 'Your income:' }}</span>
            <span class="p2p-income-container__sum"> {{ sum | appCurrency }}</span>
          </div>
          <ng-container
            *ngIf="
              permissions.canTrade && permissions.canTrade.success && permissions.canSteamAPI && permissions.canSteamAPI.success;
              else steam_setup
            "
          >
            <div
              class="deposit-action"
              *ngIf="selected$ | async as selected"
            >
              <button
                class="btn btn-primary"
                (click)="onSell()"
                [disabled]="!selected.length"
              >
                {{ 'P2P_WIDGETS.DEPOSITING.SELL_ALL' | translate }}
              </button>
            </div>
          </ng-container>
          <ng-container
            *ngIf="permissions.canTrade && permissions.canTrade.error && permissions.canTrade.error === steamErrorsEnum.STEAM_TRADE_BAN"
          >
            <div class="deposit-action">
              <button
                class="btn btn-primary"
                disabled
              >
                <span>{{ 'You are banned' | translate }}</span>
              </button>
            </div>
          </ng-container>
          <ng-template #steam_setup>
            <div class="deposit-action">
              <button
                class="btn btn-primary"
                (click)="onSetupSteam()"
              >
                {{ 'P2P_WIDGETS.ADD_STEAM_GUARD' | translate }}
              </button>
            </div>
          </ng-template>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
  <!-- if empty -->
  <ng-template #inviteLayout>
    <div class="invite-container">
      <mat-icon svgIcon="p2p-trading-icon"></mat-icon>
      <span>{{ 'P2P_WIDGETS.INVITE.SELL_SKINS' | translate }}</span>
      <p>{{ 'P2P_WIDGETS.INVITE.YOU_CAN_SELL_SKINS' | translate }}</p>
      <a
        class="btn btn-primary"
        routerLink="refill/skins"
      >
        {{ 'P2P_WIDGETS.INVITE.INVENTORY' | translate }}
      </a>
    </div>
  </ng-template>
</ng-template>
