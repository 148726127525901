import {Portal} from "@angular/cdk/portal";
import {Injectable} from '@angular/core';
import {BehaviorSubjectItem} from "@csgofast/shared/utils";



@Injectable({
  providedIn: 'root',
})
export class PortalService {
  public sectionHeaderPortal: BehaviorSubjectItem<Portal<any>|undefined> = new BehaviorSubjectItem<Portal<any>|undefined>(undefined);
  public customWidgetsPortal: BehaviorSubjectItem<Portal<any>|undefined> = new BehaviorSubjectItem<Portal<any>|undefined>(undefined);
}
