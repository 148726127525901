import {ModuleWithProviders, NgModule, TypeProvider} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';

import {ChatComponent} from './chat.component';
import {StickersComponent} from './stickers/stickers.component';
import {MessageInputComponent} from './message-input/message-input.component';
import {ChatItemComponent} from './chat-item/chat-item.component';

import {ChatDirective} from './chat.directive';

import {LazyLoadImageModule} from '@csgofast/shared/directives';
import {UserAvatarModule} from '@dev-fast/ui-components';
import {NgxsFormPluginModule} from '@ngxs/form-plugin';
import {CHAT_ENGINE} from './symbols';
// import { ChatService } from './chat.service';
import {MatIconModule} from '@angular/material/icon';
import {NgScrollbarModule} from 'ngx-scrollbar';

@NgModule({
  declarations: [ChatComponent, StickersComponent, MessageInputComponent, ChatItemComponent, ChatDirective],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    UserAvatarModule,
    ScrollingModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatIconModule,
    NgScrollbarModule,
    LazyLoadImageModule,
    NgxsFormPluginModule,
    TranslateModule.forChild(),
  ],
  exports: [ChatComponent],
  // bootstrap: [ChatComponent],
})
export class ChatModule {
  static forRoot(engine: TypeProvider): ModuleWithProviders<ChatModule> {
    return {
      ngModule: ChatModule,
      providers: [
        {
          provide: CHAT_ENGINE,
          useClass: engine,
        },
      ],
    };
  }
}
