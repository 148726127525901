<ng-container
  *ngIf="{
    dailyTasks: dailyTasks$ | async,
    weeklyTasks: weeklyTasks$ | async,
    untilDayTaskTime: untilDayTaskTime$ | async,
    untilWeeklyTaskTime: untilWeeklyTaskTime$ | async,
    eventName: eventName$ | async,
    user: user$ | async
  } as data"
>
  <div class="ny-event-challenges f-column-center">
    <div class="ny-event-challenges--header f-column-center">
      <span class="ny-event-challenges--header--strong title-strong">{{ 'GOFAST.NY_EVENT.DESCRIPTION_HEADER' | translate }}</span>
      <span class="ny-event-challenges--header--muted text-muted">{{ 'GOFAST.NY_EVENT.DESCRIPTION' | translate }} </span>
      <a
        class="ny-event-challenges--header-details"
        (click)="openEventTerms()"
        >{{ 'GOFAST.NY_EVENT.DESCRIPTION_BUTTON' | translate }}</a
      >
    </div>

    <div class="tasks-wrapper">
      <div
        class="ny-event-challenges--daily-list"
        *ngIf="data.dailyTasks && data.dailyTasks.length"
      >
        <div class="ny-event-challenges--daily-list--header title-strong">
          {{ 'GOFAST.NY_EVENT.DAILY_QUESTS' | translate }}
          <span
            class="text-muted"
            style="display: flex"
          >
            <span> (</span>
            <fast-ui-countdown
              *ngIf="data.untilDayTaskTime"
              [until]="data.untilDayTaskTime"
              [showHours]="true"
              [reset]="false"
              [minutes]="0.92"
            >
            </fast-ui-countdown>
            <span>)</span>
          </span>
        </div>
        <div class="ny-event-challenges--daily-list--container">
          <!-- <csgofast-ny-quest-card [task]="dailyTask" *ngFor="let dailyTask of data.dailyTasks"></csgofast-ny-quest-card> -->

          <div
            *ngFor="let dailyTask of data.dailyTasks; let i = index"
            style="position: relative; flex-grow: 1; display: flex; flex-basis: 10rem"
          >
            <ng-container *ngIf="data.eventName ==='ny'">
              <img
              *ngFor="let decal of snowDecals[i]"
              [src]="decal.image"
              [style]="decal.position"
             alt=""/>
            </ng-container>
            <csgofast-ny-quest-card [task]="dailyTask"></csgofast-ny-quest-card>
          </div>
        </div>
      </div>
      <div
        class="ny-event-challenges--weekly-list"
        *ngIf="data.weeklyTasks && data.weeklyTasks.length"
      >
        <div class="ny-event-challenges--weekly-list--header title-strong">
          {{ 'GOFAST.NY_EVENT.WEEKLY_QUESTS' | translate }}
          <span
            class="text-muted"
            style="display: flex"
            *ngIf="data.untilWeeklyTaskTime"
          >
            <span> (</span>
            <ng-container *ngIf="isDays(data.untilWeeklyTaskTime); else daysLeft">
              <fast-ui-countdown
                *ngIf="data.untilWeeklyTaskTime"
                [until]="data.untilWeeklyTaskTime"
                [showHours]="true"
                [showSeconds]="false"
                [reset]="false"
                [minutes]="0.92"
              >
              </fast-ui-countdown>
            </ng-container>
            <ng-template #daysLeft> {{ daysToGo(data.untilWeeklyTaskTime) }} {{ 'GOFAST.REGULAR_EVENT.DAYS' | translate }} </ng-template>
            <span>)</span>
          </span>
        </div>

        <ng-scrollbar
          style="max-width: 63.5rem; height: 12.5rem; width: 100%"
          track="horizontal"
          visibility="hover"
          [minThumbSize]="30"
        >
          <div
            class="ny-event-challenges--weekly-list--scroll"
            *ngIf="data.weeklyTasks"
          >
            <div
              class="ny-event-challenges--weekly-list--scroll-item open"
              *ngFor="let card of data.weeklyTasks"
            >
              <ng-container *ngIf="card.isOpened && card.rewardTaken">
                <div
                  class="ny-event-challenges--weekly-list--scroll-item--icon"
                  *ngIf="data.eventName"
                >
                  <div
                    *ngIf="data.eventName === 'ny'"
                    class="currency currency--green-cane"
                    [class.currency--blue-cane]="card.priority === 7"
                    style="height: 2rem"
                  ></div>
                  <div
                    *ngIf="data.eventName === 'ny_china'"
                    class="currency currency--green-pop"
                    [class.currency--blue-pop]="card.priority === 7"
                    style="height: 2rem"
                  ></div>
                </div>
                <mat-icon
                  style="color: #74ff0c"
                  class="quest-complete-icon"
                  svgIcon="success"
                ></mat-icon>
                <div
                  *ngIf="!card.isHidden || card.priority === 7"
                  class="ny-event-challenges--weekly-list--scroll-item--quest-text"
                >
                  {{ translate(card) | appCurrencyTranslate: card.params }}
                  <span
                    *ngIf="questGame(card.gameTypeId)"
                    style="color: var(--color-primary)"
                  >
                    <a href="/game/{{ questGame(card.gameTypeId) | lowercase }}">{{ questGame(card.gameTypeId) + ' game' }}</a>
                  </span>
                </div>
              </ng-container>
              <div
                class="ny-event-challenges--weekly-list--scroll-item--active"
                *ngIf="card === activeWeeklyQuest(data.weeklyTasks)"
              >
                <div class="ny-event-challenges--weekly-list--scroll-item--active-container">
                  <svg>
                    <circle
                      cx="50%"
                      cy="50%"
                      r="2rem"
                      fill="none"
                      stroke="#182328"
                      stroke-width="2"
                    />
                    <circle
                      class="percent fifty"
                      cx="50%"
                      cy="50%"
                      r="2rem"
                      fill="none"
                      stroke="#74ff0c"
                      stroke-width="2"
                      pathLength="100"
                      [style.stroke-dashoffset]="100 + calcProgressPercent(card.params.count, card.progress)"
                    />
                  </svg>
                  <div
                    class="ny-event-challenges--weekly-list--scroll-item--active-icon"
                    (click)="card.progress >= card.params.count && takeQuestReward(card.id)"
                    *ngIf="data.eventName as eventName"
                  >
                    <div
                      *ngIf="eventName === 'ny'"
                      [class.active-cane]="card.progress >= card.params.count && !card.rewardTaken"
                      class="currency currency--green-cane"
                      [class.currency--blue-cane]="card.priority === 7"
                    ></div>
                    <div
                      *ngIf="eventName === 'ny_china'"
                      [class.active-cane]="card.progress >= card.params.count && !card.rewardTaken"
                      class="currency currency--green-pop"
                      [class.currency--blue-pop]="card.priority === 7"
                    ></div>
                  </div>
                  <div
                    *ngIf="!card.isHidden"
                    class="ny-event-challenges--weekly-list--scroll-item--quest-text"
                  >
                    {{ translate(card) | appCurrencyTranslate: card.params }}
                    <span
                      *ngIf="questGame(card.gameTypeId)"
                      style="color: var(--color-primary)"
                    >
                      <a href="/game/{{ questGame(card.gameTypeId) | lowercase }}">{{ questGame(card.gameTypeId) + ' game' }}</a>
                    </span>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="!card.isOpened && !card.rewardTaken">
                <div style="display: flex; justify-content: center; align-items: center; width: 100%; height: 100%">
                  <mat-icon
                    *ngIf="card.priority !== 7"
                    class="quest-locked-icon"
                    svgIcon="game-lock"
                  ></mat-icon>
                  <mat-icon
                    *ngIf="card.priority === 7"
                    class="quest-final-icon"
                    svgIcon="faq"
                  ></mat-icon>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-scrollbar>
      </div>
      <ng-container *ngIf="canGame(data.user)">
        <div class="task-wrapper-overlay">
          <div class="account-requirement-wrapper">
            <div class="account-requirement-icon">
              <mat-icon svgIcon="color-lock"></mat-icon>
            </div>
            <div class="account-requirement-description">
              {{ 'GOFAST.REGULAR_EVENT.EVENT_BLOCK_TEXT_STATUS' | translate }}
            </div>
            <a
              [routerLink]="['../../', 'refill']"
              class="btn btn-primary"
              >{{ 'Refill' | translate }}</a
            >
          </div>
        </div>
      </ng-container>
    </div>

    <div class="ny-event-shop">
      <div class="title-strong ny-event-shop--title">{{ 'GOFAST.NY_EVENT.CASES_SHOP' | translate }}</div>
      <ng-container *ngIf="casesCollection$ | async as cases">
        <fast-ui-cases-collection
          [eventName]="data.eventName"
          [collection]="cases"
          [categoriesIsLoad]="categoriesIsLoad$ | myAsync"
          style="padding: 0px 2px"
        ></fast-ui-cases-collection>
      </ng-container>
    </div>
  </div>
</ng-container>
