import {IPayments} from '@dev-fast/types';

export interface PaymentStateModel {
  paymentTrust: boolean;
  payments: IPayments | null;
}

export const PAYMENT_INITIAL_STATE: PaymentStateModel = {
  paymentTrust: false,
  payments: null,
};
