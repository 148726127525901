import {Component, NgModule, Input, Inject} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule} from "@angular/material/icon";
import { EventsConfig, ICaseCollection, ICaseCollectionType, ICaseItemDtoV2 } from "@dev-fast/types";
import { TranslateModule } from "@ngx-translate/core";
import { CubeGridLoaderComponentModule } from "../../loaders/cube-grid/cube-grid.component";
import { cardEnterAnimation } from "./cases-collection.animation";
import { CaseCardComponentModule } from '../cards';

@Component({
  selector: 'fast-ui-cases-collection',
  templateUrl: './cases-collection.component.html',
  styleUrls: ['./cases-collection.component.scss', '../cases-collection/cases-collection.media.scss'],
  animations: [cardEnterAnimation],
})
export class CasesCollectionComponent  {
  @Input() public collection: ICaseCollection | null = null;
  @Input() public index: number | undefined;
  @Input() public activeCategory: ICaseCollectionType = 0;
  @Input() public categoriesIsLoad: boolean;
  @Input() public eventName: keyof EventsConfig | null ;

  constructor(
    @Inject('isServer') public isServer: boolean
  ) {
    this.categoriesIsLoad = false;
    this.eventName = null
  }
  public trackById(index: number, caseItem: ICaseItemDtoV2): number {
    return caseItem.id;
  }
}

@NgModule({
  imports: [ CommonModule, MatIconModule, TranslateModule,CubeGridLoaderComponentModule,CaseCardComponentModule ],
  declarations: [ CasesCollectionComponent ],
  exports: [ CasesCollectionComponent ],
})
export class CasesCollectionComponentModule {}
