import { Portal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { Params, NavigationExtras } from '@angular/router';
import { PortalService } from '@csgofast/core/portal-service';
import { GamesState } from '@csgofast/core/state/games-store';
import { LayoutState } from '@csgofast/core/state/layout';
import { IActivities, Widgets } from '@dev-fast/types';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Navigate } from '@ngxs/router-plugin';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { RecentActivitiesEngine } from './symbols';

@Injectable()
export class RecentActivitiesService implements RecentActivitiesEngine {
  @Select(LayoutState.activeWidgets)
  public activitiesWidgets$!: Observable<Widgets[]>;

  @Select(GamesState.currentGameActivities)
  public activities$!: Observable<IActivities | null>;
  public readonly customWidgetsPortal$: Observable<Portal<any> | undefined>;

  @Dispatch()
  public navigateTo(path: any[], queryParams?: Params | undefined, extras?: NavigationExtras | undefined) {
    return new Navigate(path, queryParams, extras);
  }


  constructor(
    private readonly portalService: PortalService,
  ) {
    this.customWidgetsPortal$ = this.portalService.customWidgetsPortal.value$;
  }
}
