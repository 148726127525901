import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'sortBy',
})
export class SortByPipe implements PipeTransform {
  public transform (value: any[], key: string, order?: 'asc' | 'desc') {
    if (!Array.isArray(value)) throw Error(`InvalidPipeArgument: '${value}' for pipe sortBy`);
    if (order) return value.sort((l, r) => l[key] && r[key] ? order === 'asc' ? (l[key] - r[key]) : r[key] - l[key] : 0);
    return value.sort((l, r) => l[key] && r[key] ? l[key] > r[key] ? -1 : 1 : 0);
  }
}
