import {Component} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {map} from 'rxjs/operators';
import {P2pAuctionCompletedModalComponent, P2pSetupModalComponent} from '../modals';
import {IP2pDepositingItem, IP2pDepositItem, IUserP2pPermissionInterface, SteamErrorsEnum} from '@dev-fast/types';
import {P2pDepositService} from '@csgofast/modules/p2p';
import {P2pSellService} from './p2p-sell.service';

@Component({
  selector: 'csgofast-p2p-sell',
  templateUrl: './p2p-sell.component.html',
  styleUrls: ['./p2p-sell.component.scss'],
})
export class P2pSellComponent {
  public readonly sellerBanEndAt$: Observable<number | null>;
  public readonly isEmptySeller$: Observable<boolean>;
  public readonly permissions$: Observable<IUserP2pPermissionInterface>;
  public selected$: Observable<IP2pDepositItem[]>;
  public selectedSum$: Observable<number>;
  public depositing$: Observable<IP2pDepositingItem[]>;
  public steamErrorsEnum = SteamErrorsEnum;
  public isItemsOnPause$: Observable<boolean>;

  constructor(
    private readonly p2pDepositService: P2pDepositService,
    public readonly dialog: MatDialog,
    private readonly moduleService: P2pSellService,

  ) {
    this.permissions$ = this.moduleService.p2pPermissions$;
    this.selected$ = this.p2pDepositService.selected$;
    this.selectedSum$ = this.p2pDepositService.selectedSum$;
    this.isItemsOnPause$ = this.p2pDepositService.isItemsOnPause$;
    this.depositing$ = this.p2pDepositService.depositing$;
    this.sellerBanEndAt$ = this.moduleService.sellerBanEndAt$;
    this.isEmptySeller$ = combineLatest([this.selected$, this.depositing$]).pipe(
      map(([selected, depositing]) => !!(selected.length + depositing.length))
    );
  }

  public onSell(): void {
    this.p2pDepositService.depositSelected();
  }
  public onResetSelected(): void {
    this.p2pDepositService.resetSelected();
  }
  public onSetupSteam(): void {
    this.dialog.open(P2pSetupModalComponent, {
      data: ['steam', 'apiKey'],
    });
  }
  public onStopAvailableItems() {
    this.p2pDepositService.stop();
  }
  public onPauseAvailableItems() {
    this.p2pDepositService.pause();
  }
  public onResumeAvailableItems() {
    this.p2pDepositService.resume();
  }
  public trackingFunction(index: number, item: IP2pDepositingItem) {
    return item.id;
  }
  public openTechSupportDialog(): void {
    this.moduleService.openSupportModal()
  }


  test(){
    this.dialog.open(P2pAuctionCompletedModalComponent, {
      data: this.depositing$
    });
  }
}
