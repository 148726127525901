import {IconConfig} from "./icon.model";

export const iconsWidgetsMenu: IconConfig[] = [
  {
    name: 'chat',
    path: 'assets/img/mat-icons/widgets-menu/chat.svg',
  },
  {
    name: 'clover',
    path: 'assets/img/mat-icons/widgets-menu/clover.svg',
  },
  {
    name: 'crown',
    path: 'assets/img/mat-icons/widgets-menu/crown.svg',
  },
  {
    name: 'history',
    path: 'assets/img/mat-icons/widgets-menu/history.svg',
  },
  {
    name: 'settings',
    path: 'assets/img/mat-icons/widgets-menu/settings.svg',
  },
  {
    name: 'star',
    path: 'assets/img/mat-icons/widgets-menu/star.svg',
  },
  {
    name: 'ticket',
    path: 'assets/img/mat-icons/widgets-menu/ticket.svg',
  },
  {
    name: 'top',
    path: 'assets/img/mat-icons/widgets-menu/top.svg',
  },
  {
    name: 'winner-limb',
    path: 'assets/img/mat-icons/widgets-menu/winner-limb.svg',
  },
];
