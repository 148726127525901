// import { Observable } from 'rxjs';
// import { Select } from '@ngxs/store';
import { Injectable } from '@angular/core';
// import { ReferralsState } from './referrals.ny';
// import { ActivatePromoCode } from './referrals.actions';
// import { Dispatch } from '@ngxs-labs/dispatch-decorator';
// import {IError, PromoCode} from '@dev-fast/types';

@Injectable()
export class ReferralsService {
  // @Select(ReferralsState.activeReferralCampaign)
  // public $referral!: Observable<PromoCode>;
  //
  // @Select(ReferralsState.activationErr)
  // public activationErr$!: Observable<IError>;

  // @Dispatch() public activatePromoCode = (code: string) => new ActivatePromoCode(code);

  // public activateReferralCode(code: string) {
  //  return this.activatePromoCode(code);
  // }
}
// export class ReferralsService {

//   private static OnReferralClickError(error: HttpErrorResponse): Observable<any> {
//     console.log(error);
//     return of();
//   }

//   public requestInfo (): void {
//     this.infoRequest.next();
//   }

//   public requestCampaigns (): void {
//     this.campaignsRequest.next();
//   }

//   public requestCurrentCampaign (): void {
//     this.currentCampaignRequest.next();
//   }

//   public requestReferralsReport (params: any): void {
//     this.campaignReferralsReportRequest.next(params);
//   }

//   public requestCampaignProfit (params: { campaignId?: number, referralId?: number }): Observable<any> {
//     return this.http.post(`${environment.GATEWAY_URL}/api/referral-income/take`, params, { withCredentials: true });
//   }

//   public requestCampaignCreate (params: Record<string, any>): Observable<any> {
//     const appId = 2; // TODO: CrossService id from nowhere (csgo);
//     return this.http.post(`${environment.GATEWAY_URL}/api/referral-campaigns`, {
//       ...params,
//       appId,
//     }, { withCredentials: true });
//   }

//   public requestCampaignPatch (params: Record<string, any>): Observable<any> {
//     const { id, ...rest } = params;
//     return this.http.patch(`${environment.GATEWAY_URL}/api/referral-campaigns/${id}`, rest, { withCredentials: true });
//   }

//   public requestCampaignCodeExisting (code: string): Observable<any> {
//     return this.http.post(`${environment.GATEWAY_URL}/api/referral-campaigns/code`, { code });
//   }

//   /**
//    * Отключение синей всплывашки "вам доступен бонус" по ключу кода
//    */
//   disableNotifications(code: string, userId: number) {
//     // Обернуто в try для случая, когда у пользователя нет места в localStorage, чтобы ошибка не помешала закрыть уведомление
//     try{
//       localStorage.setItem(`hide-notification-${code}-${userId}`, 'Y');
//     } catch (e) {console.log(e);}
//   }

//   /**
//    * Проверка доступности показа всплывашки "вам доступен бонус" по ключу кода
//    */
//   isNotificationAvailable(code: string, userId: number): boolean {
//     return !Boolean(localStorage.getItem(`hide-notification-${code}-${userId}`))
//   }

//   public setActiveCampaign (id: number): void {
//     this.activeCampaignId.next(id);
//   }

//   public updateCampaigns (campaign: any): void {
//     const campaigns = [...this.campaigns.getValue()];
//     const index = campaigns.map((c: any) => c.id).indexOf(campaign.id);
//     campaigns[index] = { ...campaigns[index], ...campaign };
//     this.campaigns.next(campaigns);
//   }

//   calcReward(currentCampaign: ICurrentReferralCampaign): string {
//     let reward = '';
//     if ( currentCampaign && currentCampaign.reward && !currentCampaign.rewarded ) {
//       if( currentCampaign.type === ReferralCampaignTypes.PERCENT ) {
//         reward = `${ parseFloat(currentCampaign.reward.toFixed(1)) }%`
//       } else {
//         //Перевод из "коинов" в доллары. Курс 100 к 1
//         reward = `${ currentCampaign.reward / 100 }$`
//       }
//     } else {
//       reward = null;
//     }
//     return reward;
//   }

//   public appendCode (code: string): void {
//     this.storage.set('referral-code', code);
//   }

//   public requestPromoActivation (code: string): Observable<IActivateCampaignResponse> {
//     return this.http.post<IActivateCampaignResponse>(`${environment.GATEWAY_URL}/api/referral-codes/${encodeURIComponent(code)}/activate`,
//       {}, { withCredentials: true },
//     ).pipe(tap(campaign => {
//       if(campaign) {
//         campaign.type = campaign.type.replace('cases-custom-', '');
//       }
//       this.currentCampaign.next(campaign)
//     }));
//   }

//   public requestWithDrawActivation (code: string): Observable<string> {
//     return this.http.post<string>(`${environment.GATEWAY_URL}/api/referral-codes/${encodeURIComponent(code)}/activate`,
//       {}, { withCredentials: true },
//     );
//   }

//   public requestPromoClicks (code: string): Observable<ICurrentReferralCampaign> {
//     return this.http.post<ICurrentReferralCampaign>(`${environment.GATEWAY_URL}/api/referral-codes/${encodeURIComponent(code)}/click`, {}).pipe(
//       catchError((error) => ReferralsService.OnReferralClickError(error)),
//       tap(campaign => {
//         if(!this.currentCampaign.getValue() && campaign.canActivate) {
//           if(campaign) {
//             campaign.type = campaign.type.replace('cases-custom-', '');
//           }
//           this.currentCampaign.next(campaign);
//         }
//       })
//     );
//   }

//   private setupCampaign (campaigns: any, id: number): void {
//     const campaign = campaigns.find((item: any) => item.id === id);
//     if (campaign === undefined) {
//       return this.campaign.next(null);
//     }
//     this.campaign.next(campaign);
//   }

//   private static onError (error: HttpErrorResponse): Observable<any> {
//     console.log(error);
//     return of();
//   }

//   private subscribeEmitters (): void {
//     this.campaignRequest.pipe(
//       switchMap(() => this.http.get(`${environment.GATEWAY_URL}/referrals/kek`, { withCredentials: true })),
//       catchError((error: HttpErrorResponse) => ReferralsService.onError(error)),
//     ).subscribe(
//       (campaign: any) => this.campaign.next(campaign),
//     );

//     this.campaignsRequest.pipe(
//       switchMap(() => this.http.get<IReferralCampaign[]>(`${environment.GATEWAY_URL}/api/referral-campaigns`, { withCredentials: true })),
//       catchError((error: HttpErrorResponse) => ReferralsService.onError(error)),
//     ).subscribe(
//       (campaigns: IReferralCampaign[]) => this.campaigns.next(campaigns),
//     );

//     combineLatest([this.currentCampaignRequest, this.authService.authorized$])
//       .pipe(
//         filter(([_, allowed]) => allowed),
//         switchMap(() => this.http.get<ICurrentReferralCampaign>(`${environment.GATEWAY_URL}/api/referral-campaigns/current`, { withCredentials: true })),
//         catchError((error: HttpErrorResponse) => ReferralsService.onError(error)),
//     ).subscribe(
//       (campaign: ICurrentReferralCampaign) => {
//         // TODO убрать когда бэк закончит перевод типов с cases-custom-percent на percent
//         if(campaign) {
//           campaign.type = campaign.type.replace('cases-custom-', '');
//         }
//         this.currentCampaign.next(campaign)
//       },
//       () => (this.campaign.next(null))
//     );

//     this.infoRequest.pipe(
//       switchMap(() => this.http.get(`${environment.GATEWAY_URL}/api/referral-income`, { withCredentials: true })),
//       catchError((error: HttpErrorResponse) => ReferralsService.onError(error)),
//     ).subscribe(
//       (info: any) => this.info.next(info),
//     );

//     combineLatest([this.campaigns$, this.activeCampaignId$])
//       .subscribe(([campaigns, id]: [any[], number]) => this.setupCampaign(campaigns, id));

//     this.authService.authorized$.pipe(
//       filter((allowed: boolean) => allowed),
//       switchMap(() => of(this.storage.get('referral-code'))),
//       filter((code: string) => code !== undefined),
//       switchMap((code: string) => this.requestPromoActivation(code)),
//       catchError((error: HttpErrorResponse) => ReferralsService.onError(error)),
//     ).subscribe(
//       (e) => this.storage.remove('referral-code'),
//     );

//     this.campaignReferralsReportRequest.pipe(
//       switchMap((params: Record<string, any>) => this.http.get<IReferralCampaignReport[]>(`${environment.GATEWAY_URL}/api/referral-reports/incomes/${QueryUtils.paramsToQuery(params)}`, { withCredentials: true })),
//     ).subscribe(
//       (result: IReferralCampaignReport[]) => this.campaignReferralsReport.next(result),
//     );
//   }
// }
