import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { configs, EventsConfig, ILRBalance } from '@dev-fast/types';
import moment from 'moment';
import { first, Observable, take } from 'rxjs';
import { NyAllEventService, NyEventService } from '../core';
import { daysToGo, isDays } from '../shared/utils';

@Component({
  selector: 'csgofast-events-home',
  templateUrl: './events-home.component.html',
  styleUrls: ['./events-home.component.scss'],
})
export class EventsHomeComponent implements OnInit, OnDestroy {
  public keys$: Observable<ILRBalance | null> | undefined;
  public eventName$: Observable<keyof EventsConfig | null> | undefined;
  public untilEventTime$: Observable<number | null>;

  public isEventResults: boolean;

  constructor(
    private readonly nyAllEventService: NyAllEventService,
    private readonly nyEventService: NyEventService,
    private router: Router
  ) {
    this.keys$ = this.nyAllEventService.keys$;
    this.isEventResults = this.router.url.includes('events/leaders') || this.router.url.includes('events/shop');
    this.eventName$ = this.isEventResults ? this.nyEventService.eventHistoryName$ : this.nyEventService.eventName$;
    this.untilEventTime$ = this.isEventResults ? this.nyEventService.untilEventHistoryTime$ : this.nyEventService.untilEventTime$;
  }

  public ngOnInit(): void {
    this.nyEventService.toggleBackground('#0c0d11');
    if(this.isEventResults){
      this.nyAllEventService.getLeaders();
      this.nyAllEventService.getCases();
    }
  }
  public ngOnDestroy(): void {
    this.nyEventService.toggleBackground();
    this.nyAllEventService.resetState();
  }

  public isDays(date: number) {
    return isDays(date);
  }
  public daysToGo(date: number) {
    return daysToGo(date);
  }
}
