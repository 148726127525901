import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OutsideClickDirective} from './click-outside.directive';

@NgModule({
  declarations: [
    OutsideClickDirective,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    OutsideClickDirective,
  ],
})
export class ClickOutsideModule { }
