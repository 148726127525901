import {ICaseCollection, ICaseItemDtoV2, ILRBalance, ILRLeaders, ILRQuest} from "@dev-fast/types";

export interface NYAllEventStateModel {
  // dailyTasks: ILRQuest[];
  // weeklyTasks: ILRQuest[];
  tasks: ILRQuest[];
  /** в милисекондах */
  // untilDayTaskTime: number;
  /** в милисекондах */
  // untilWeeklyTaskTime: number;
  keys: ILRBalance | null;
  cases: ICaseCollection | null
  leaders: ILRLeaders|null

}
export const NY_ALL_EVENT_INITIAL_STATE: NYAllEventStateModel = {
  // dailyTasks: [],
  // weeklyTasks: [],
  tasks: [],
  // untilDayTaskTime: 0,
  // untilWeeklyTaskTime: 0,
  keys: null,
  cases: null,
  leaders: null,
};
