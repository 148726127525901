import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ActivePanel, IGame, ILocales, IUserDetailed, Themes} from '@dev-fast/types';
import {CommonStoreService} from '@csgofast/core-state-common';
import {combineLatest, Observable} from 'rxjs';
import {Select} from '@ngxs/store';
import {GamesState} from '@csgofast/core/state/games-store';
import {BalanceWidgetConfig} from '@dev-fast/ui-components';
import {Dispatch} from '@ngxs-labs/dispatch-decorator';
import {GetInventoryInfo, InventoryState} from '@csgofast/core/state/inventory';
import {UserState} from '@csgofast/core/state/user-store';
import {ChangeActivePanel, LayoutState} from '@csgofast/core/state/layout';
import {AccountState} from '@csgofast/core/state/account';

@Injectable()
export class MobileHeaderService {
  public theme$: Observable<Themes>;
  public locales$: Observable<ILocales[]>;
  public currentLocale$: Observable<ILocales>;
  public balance$: Observable<BalanceWidgetConfig>;

  @Select(GamesState.availableGames)
  public readonly availableGames$!: Observable<IGame[]>;
  @Select(InventoryState.inventoryCount)
  public readonly inventoryCount$!: Observable<number>;
  @Select(UserState.user)
  public readonly user$!: Observable<IUserDetailed | null>;
  @Select(AccountState.canLevelUp)
  public canLevelUp$!: Observable<boolean>;
  @Select(LayoutState.activePanel)
  public activePanel$!: Observable<ActivePanel | null>;
  // tap((user) => {
  //   if (user) {
  //     this.coinsAmount = user.walletType === 'demo' ? user.demoCoinsAmount : user?.coinsAmount;
  //   }
  // })

  constructor(private commonService: CommonStoreService) {
    this.theme$ = this.commonService.theme$;
    this.locales$ = this.commonService.locales$;
    this.currentLocale$ = this.commonService.currentLocale$;
    this.balance$ = combineLatest([this.inventoryCount$, this.user$]).pipe(
      map(([inventoryCount, user]) => {
        return {
          countSkins: inventoryCount ? inventoryCount : 0,
          countCoins: user ? (user.walletType === 'demo' ? user.demoCoinsAmount : user?.coinsAmount) : 0,
        };
      })
    );
  }
  switchTheme(theme: Themes) {
    this.commonService.switchTheme(theme);
  }
  switchLang(locale: ILocales) {
    this.commonService.switchLang(locale);
  }
  logout() {
    this.commonService.logout();
  }
  login() {
    this.commonService.login();
  }

  @Dispatch() public getInventoryInfo = () => new GetInventoryInfo();
  @Dispatch() public changeActivePanel = (panel: ActivePanel | null) => new ChangeActivePanel(panel);
}
