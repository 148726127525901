import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {P2pSellComponent} from './p2p-sell.component';
import {P2pSellSetupItemModule} from './p2p-sell-setup-item/p2p-sell-setup-item.module';
import {P2pSellingItemModule} from './p2p-selling-item/p2p-selling-item.module';
import {TranslateModule} from '@ngx-translate/core';
// import { P2pSetupModalModule } from '@shared/p2p';
import {RouterModule} from '@angular/router';
import {MatIconModule} from '@angular/material/icon';
import {P2pSellService} from './p2p-sell.service';
import {NgScrollbarModule} from 'ngx-scrollbar';
import {AppCurrencyModule} from '@csgofast/shared/pipes';
import {CountdownComponentModule} from '@dev-fast/ui-components';

@NgModule({
  declarations: [P2pSellComponent],
  imports: [
    CommonModule,
    TranslateModule,
    NgScrollbarModule,
    P2pSellSetupItemModule,
    P2pSellingItemModule,
    // P2pSetupModalModule,
    CountdownComponentModule,
    AppCurrencyModule,
    MatIconModule,
    RouterModule,
  ],
  exports: [P2pSellComponent],
  providers: [P2pSellService],
})
export class P2pSellModule {}
