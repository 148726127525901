import {IconConfig} from "./icon.model";

export const iconsUser: IconConfig[] = [
  {
    name: 'user',
    path: 'assets/img/mat-icons/user/user.svg',
  },
  {
    name: 'additional-flag',
    path: 'assets/img/mat-icons/user/additional-flag.svg',
  },
  {
    name: 'join-game',
    path: 'assets/img/mat-icons/user/join-game.svg',
  },
  {
    name: 'versus',
    path: 'assets/img/mat-icons/user/versus.svg',
  },
];
