import {GameStatusesFF, IActivities, LegacyGameConfig, LegacyGameState, LegacyLiteGameState} from '@dev-fast/types';

export class GetAllGamesSettings {
  public static readonly type = '[Games] Get items';
}
export class FindGameSettings {
  public static readonly type = '[Games] Find item';
  constructor(public key: string) {}
}
export class SetCurrentGame {
  public static readonly type = '[Games] Set current game';
  constructor(public key: string) {}
}
export class SetCurrentGameActivities {
  public static readonly type = '[Games] Set current game activities';
  constructor(public activities: IActivities) {}
}
export class RefreshOnlineCount {
  public static readonly type = '[Games] Refresh Online Count';
  constructor(public count: number) {}
}
export class UpdStatuses {
  public static readonly type = '[Games] Upd Statuses';
  constructor(public statuses: GameStatusesFF) {}
}
/**
 * делал для Gleam
 */
export class SuccessfulBid {
  public static readonly type = '[Games] Successful Bid';
  constructor(public gameName: string) {}
}

export class PlaceBetLegacy {
  public static readonly type = '[Games] Place Bet Legacy';
  constructor(public gameName: 'classic' | 'fast', public payload?: any) {}
}
export class ParticipateLegacy {
  public static readonly type = '[Games] Participate Legacy';
  // constructor(public gameName: 'classic' | 'fast', public payload?: any) {}
}
export class ConfirmParticipateLegacy {
  public static readonly type = '[Games] Confirm Participate Legacy';
  constructor(public payload: { items: any[]; timeout: number }) {}
}
export class ConfirmParticipateLegacySuccess {
  public static readonly type = '[Games] Confirm Participate Legacy Success';
  constructor(public payload: { items: any[]; timeout: number }) {}
}
export class GameResultLegacy {
  public static readonly type = '[Games] Game Result Legacy';
  constructor(public gameName: 'classic' | 'fast', public payload?: any) {}
}
export class ChangeConfigLegacy {
  public static readonly type = '[Games] Change Config Legacy';
  constructor(public payload: LegacyGameConfig, public gameName?: 'classic' | 'fast') {}
}
export class ChangeStateLegacy {
  public static readonly type = '[Games] Change State Legacy';
  constructor(public payload: LegacyGameState, public gameName: 'classic' | 'fast') {}
}
export class ChangeLiteStateLegacy {
  public static readonly type = '[Games] Change Lite State Legacy';
  constructor(public payload: LegacyLiteGameState) {}
}
export class StartAutoBetLegacy {
  public static readonly type = '[Games] Start AutoBet  Legacy';
  constructor(public gameName: 'x50' | 'crash', public payload?: any) {}
}
export class StopAutoBetLegacy {
  public static readonly type = '[Games] Stop AutoBet  Legacy';
  constructor(public gameName: 'x50' | 'crash', public payload?: any) {}
}
