import {IP2pDepositingItem, IP2pDepositItem, MarketAction} from '@dev-fast/types';

export class ResetSelected {
  public static readonly type = '[p2p_deposit] Reset Selected';
}
export class ChangeSelectedItem {
  public static readonly type = '[p2p_deposit] Change Selected Item';
  constructor(public payload: IP2pDepositItem) {}
}
export class StopDepositing {
  public static readonly type = '[p2p_deposit] Stop Depositing';
  // constructor(public payload: IP2pDepositItem) {}
}
export class PauseDepositing {
  public static readonly type = '[p2p_deposit] Pause Depositing';
  constructor(public id?: number) {}
}
export class ResumeDepositing {
  public static readonly type = '[p2p_deposit] Resume Depositing';
  constructor(public id?: number) {}
}
export class ToggleDepositItem {
  public static readonly type = '[p2p_deposit] Toggle Deposit Item';
  constructor(public payload: IP2pDepositItem) {}
}
export class RemoveDepositItem {
  public static readonly type = '[p2p_deposit] Remove Deposit Item';
  constructor(public payload: IP2pDepositItem) {}
}
export class ReqAppointing {
  public static readonly type = '[p2p_deposit] Req Appointing';
  constructor(public id: number, public action: MarketAction) {}
}
export class GetDepositing {
  public static readonly type = '[p2p_deposit] Get Depositing';
}
export class RemoveMarketItems {
  public static readonly type = '[p2p_deposit] Remove Market Items';
  constructor(public ids: number[]) {}
}
export class SellNow {
  public static readonly type = '[p2p_deposit] Sell Now';
  constructor(public id: number) {}
}
export class DepositSelected {
  public static readonly type = '[p2p_deposit] Deposit Selected';
  // constructor(public payload: IDepositBatchPayload) {}
}
export class Deposit {
  public static readonly type = '[p2p_deposit] Deposit';
  constructor(public id: number, public price: number) {}
}
export class ParticipantItemUpdatedEvent {
  public static readonly type = '[p2p_deposit] Participant Item Updated Event';
  constructor(public payload: IP2pDepositingItem) {}
}
export class OpenModifierDialogInBB {
  public static readonly type = '[p2p_deposit] Open Modifier Dialog In BB';
  constructor(public item: IP2pDepositItem) {}
}
export class GetDepositModifier {
  public static readonly type = '[p2p_deposit] Get Deposit Modifier';
}
export class SetDepositModifier {
  public static readonly type = '[p2p_deposit] Set Deposit Modifier';
  constructor(public payload: { agree: boolean }) {}
}
export class AutoShowP2PAuctionCompletedModal {
  public static readonly type = '[p2p_deposit] AutoShow P2P Auction Completed Modal';
  // constructor(public payload?: IP2pDepositingItem) {}
}
export class GetDepositModifierSuccess {
  public static readonly type = '[p2p_deposit] Get Deposit Modifier Success';
  constructor(
    public payload:
      | {
          agree: boolean;
        }
      | undefined
  ) {}
}

//ToggleDepositItem

// autoApprove: false
// baseItem: {weapon: 'Pistol', type: 'weapon', …}
// custom: false
// customStatus: true
// fastDelivery: false
// float: null
// inspectLink: "steam://rungame/730/76561202255233023/+csgo_econ_action_preview%20S76561198065861898A26414869583D12295470071038527270"
// passed: true
// preferredPrice: 2590
// price: 1295
// priceRange: {start: 1101, end: 2590}
// selected: true
// selectedPercent: 100
// steamBotId: 76561198065861900
// steamInventoryId: 26414869583
// stickers: []
// stickersString: "[]"
// tradable: true
// tradeLockEndAt: null
// tradesDuration: 1
