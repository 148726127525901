import { Portal } from '@angular/cdk/portal';
import { Observable } from 'rxjs';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { RECENT_ACTIVITIES_ENGINE, RecentActivitiesEngine } from './symbols';
import { IActivities, Widgets } from '@dev-fast/types';
import { DesktopRightsidebarService } from "@csgofast/desktop-app/right-sidebar";

@Component({
  selector: 'csgofast-recent-activities',
  templateUrl: './recent-activities.component.html',
  styleUrls: ['./recent-activities.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecentActivitiesComponent {
  public activitiesWidgets$: Observable<Widgets[]>;
  public activities$: Observable<IActivities | null>;
  public widgets = Widgets;
  // @HostBinding('@widgetsMenuComponentAnimation') private animation = true;
  public customWidgetsPortal$: Observable<Portal<any> | undefined>;
  public isTabletByWidth$!: Observable<boolean> | undefined;

  constructor(
    @Inject('isServer') public isServer: boolean,
    @Inject(RECENT_ACTIVITIES_ENGINE) private recentActivitiesEngine: RecentActivitiesEngine,
    private readonly service: DesktopRightsidebarService,
  ) {
    this.customWidgetsPortal$ = this.recentActivitiesEngine.customWidgetsPortal$;
    this.activitiesWidgets$ = this.recentActivitiesEngine.activitiesWidgets$;
    this.activities$ = this.recentActivitiesEngine.activities$;
    this.isTabletByWidth$ = this.service.isTabletByWidth;
  }
  public navigateToCaseItem(params: { skin: string; shortName: string }) {
    if(params.skin && params.shortName) {
      return this.recentActivitiesEngine.navigateTo([`/game/cases/case/item/${params.shortName.replace(' ', '-').trim()}_${params.skin.replace(' ', '-').trim()}`]);
    } else {
      return this.recentActivitiesEngine.navigateTo(['not-found']);
    }
  }
  public widgetToData(activities: IActivities, widget: string) {
    return activities[widget as keyof IActivities]
  }
}
