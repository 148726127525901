<div class="switcher">
  <ng-container *ngIf="isDataLoading$ | async; else dataIsLoaded">
    <div class="preloader">
      <mat-spinner color="accent"></mat-spinner>
    </div>
  </ng-container>
  <ng-template #dataIsLoaded>
    <form
      [formGroup]="switcherBalance"
      class="switcher__balance"
    >
      <div class="balance-type">
        <label class="balance-type__label">
<!--          <input-->
<!--            class="balance-type__checkbox"-->
<!--            type="checkbox"-->
<!--            formControlName="balanceType"-->
<!--          />-->
<!--          <div class="tabs">-->
<!--            <div class="tab">Balance</div>-->
<!--            <div class="tab">Demo</div>-->
<!--          </div>-->
          <div class="currency-picker">
            <span
              class="currency-picker__value"
              [style.color]="switcherBalance.value.balanceType ? 'var(--color-secondary)' : 'var(--color-primary)'"
              >{{ coinsAmount | appCurrency }}</span
            >
          </div>
        </label>
      </div>
      <!-- <div class="currency-picker">
        <span class="currency-picker__value">{{ coinsAmount | appCurrency }}</span>
        <ng-container *ngIf="currencyList">
          <mat-form-field appearance="fill">
            <mat-select [(value)]="currency.key" disableOptionCentering panelClass="switcher__overlay-panel">
              <mat-option *ngFor="let currency of currencyList" [value]="currency.key">
                {{currency.key}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
      </div> -->
    </form>
  </ng-template>
</div>
