import {Injectable} from '@angular/core';
import {Environments, EnvironmentService} from '@csgofast/core/environment-service';
import {IWithdrawalMethods, IWithdrawalReceive, IWithdrawResponse} from '@dev-fast/types';
import {Observable} from 'rxjs';
import {ApiService} from './api.service';

@Injectable({
  providedIn: 'root',
})
export class WithdrawalApiService {
  private environment: Environments;

  constructor(private readonly api: ApiService, private readonly environmentService: EnvironmentService) {
    this.environment = this.environmentService.environments;
  }
  public withdrawalReceive(payload: { coinsAmount: number; paymentSystem: string; feeIndex: number }): Observable<IWithdrawalReceive> {
    return this.api.post<IWithdrawalReceive>(`${this.environment.PAYMENT_URL}/withdraw/calculate`, payload, { withCredentials: true });
  }

  public withdraw(paymentSystem: string, coinsAmount: number, wallet: string, recaptcha: string): Observable<IWithdrawResponse> {
    return this.api.post<IWithdrawResponse>(
      `${this.environment.PAYMENT_URL}/withdraw`,
      { paymentSystem, coinsAmount, wallet, recaptcha },
      { withCredentials: true }
    );
  }

  public getCardsList(): Observable<string[]> {
    return this.api.get<string[]>(`${this.environment.PAYMENT_URL}/cards`, { withCredentials: true });
  }

  public addCard(): Observable<{ url: string }> {
    return this.api.post<{ url: string }>(
      `${this.environment.PAYMENT_URL}/cards`,
      { domain: window.location.hostname },
      { withCredentials: true }
    );
  }

  public removeCard(card: string): Observable<void> {
    return this.api.delete(`${this.environment.PAYMENT_URL}/cards/${card}`, { withCredentials: true });
  }

  public getMethods(userId: number): Observable<{ success: boolean; methods: IWithdrawalMethods }> {
    // TODO вынести в конфиги url
    return this.api.get<{ success: boolean; methods: IWithdrawalMethods }>(`https://pay.csgofastbackend.com/payout-methods/${userId}`, {
      withCredentials: true,
    });
  }
}
