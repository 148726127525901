export enum WithdrawalTypes {
  BTC = 'btc',
  WMZ = 'wmz',
  WMR = 'wmr',
  YANDEX = 'yandex',
  STEAM = 'steam',
  QIWI = 'qiwi',
  SKRILL = 'skrill',
  NETELLER = 'neteller',
}

export enum WithdrawalWalletType {
  PHONE = 'phone',
  EMAIL = 'email',
  WALLET = 'wallet',
  DEFAULT = 'default',
}
