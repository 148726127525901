<div class="card-container">
  <div class="item-tag-container"></div>
  <button class="avatar-container"></button>
  <div class="description-container">
    <button class="case-info">
      <div class="info-box box_1">
        <div class="btn item-name loading-gradient"></div>
      </div>
      <div class="info-box box_2">
        <div class="btn case-price loading-gradient">
          <div></div>
        </div>
      </div>
    </button>
  </div>
  <div class="shadow-wrapper">
  </div>
</div>

