import {Injectable} from '@angular/core';
import {P2pEngine} from '@csgofast/widgets/p2p';
import {IUserDetailed} from '@dev-fast/types';
import {Observable} from 'rxjs';
// import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';

// import { IMessage, ISticker } from '@dev-fast/types';
// import { ChatApiService } from '@dev-fast/backend-services';
// import { Store } from '@ngxs/store';
// import { Dispatch } from '@ngxs-labs/dispatch-decorator';
//FIXME обязательно доделать такую работу с виджетами
/** Не сделано, идея была в том что бы чат и другие виджеты работали на внешнем движке так скажем.
 *  Пока не хватило времени додумкать */
@Injectable()
export class TradesService implements P2pEngine {
  public user$!: Observable<IUserDetailed | null>;
  public userId: number | null = null;

  // private _subject: Subject<void>;
  // constructor(
  //   private readonly store: Store
  // ) {
  //   this._subject = new Subject();
  //   this._api
  //     .messageCreatedEvent()
  //     .pipe(takeUntil(this._subject))
  //     .subscribe((msg) => this.store.dispatch(new CreateMsgEventHandling(msg)));
  //   this._api
  //     .messageDeletedEvent()
  //     .pipe(takeUntil(this._subject))
  //     .subscribe((msg) => this.store.dispatch(new DeleteMsgEventHandling(msg)));
  // }
  // public ngOnDestroy(): void {
  //   this._subject.next();
  //   this._subject.complete();
  // }
  // @Dispatch() public addMessage = () => new SendMessage();
  // @Dispatch() public getMessagesList = () => new GetMessagesList();
}
