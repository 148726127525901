import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';

if (environment.production) {
  Sentry.init({
    dsn: environment.SENTRY_DSN,
    integrations: [
      // new RewriteFrames(),
      new BrowserTracing({
        tracingOrigins: ['localhost', /^\//],
        routingInstrumentation: Sentry.routingInstrumentation,

      }),
    ],
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
    environment:  environment.production ? 'prod' : 'dev',
    release: `${environment.PROJECT}${environment.VERSION}`,

    tracesSampleRate: 1,
  });
  enableProdMode();
}

function bootstrap() {
     platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
   };


 if (document.readyState === 'complete') {
   bootstrap();
 } else {
   document.addEventListener('DOMContentLoaded', bootstrap);
 }

