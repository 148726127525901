import { Component, ViewEncapsulation, NgModule, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LazyLoadImageModule } from '@csgofast/shared/directives';
import { AppCurrencyModule } from '@csgofast/shared/pipes';
import { TagComponentModule } from '../../../tag/tag.component';
import { EventsConfig, ICaseItemDtoV2, ICaseType, MatchPopTypeImg, MatchTicketTypeImg, TicketTypeEnum } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';
import { CaseCardMockComponent } from "./mock/case-card.component.mock";
import { SelectionInputModule } from '../../../selection-input/selection-input.component';
import { MatIconModule } from '@angular/material/icon';
//fixme

@Component({
  selector: 'fast-ui-case-card',
  templateUrl: './case-card.component.html',
  styleUrls: ['./case-card.component.scss', './case-card.media.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class CaseCardComponent {
  public caseType = ICaseType;
  public ticketColor = TicketTypeEnum;
  public ticketsImgDic = MatchTicketTypeImg;
  public popImgDic = MatchPopTypeImg;

  @Input() public item!: ICaseItemDtoV2;
  @Input() public eventName: keyof EventsConfig | null | undefined;
  @Input() public totalCasesAmount = 0;
  @Input() public currentCaseAmount = 0;
  @Input() public showDescription = true;
  @Input() public showInput = false;
  @Input() public showTags = true;
  @Input() public showRevision = false;
  @Input() public isHovered = true;
  @Output() public onaddCase: EventEmitter<ICaseItemDtoV2[]> = new EventEmitter();
  @Output() public onremoveCase: EventEmitter<ICaseItemDtoV2[]> = new EventEmitter();
  @Output() public openRevisionModal: EventEmitter<number> = new EventEmitter();

  public getBackgroundImage(item: ICaseItemDtoV2): string {
    if (item.backgroundImage) {
      return item.backgroundImage;
    }
    return '';
  }
  public isCaseEnabled(): boolean {
    if (this.item?.enabled) {
      if (this.item.type !== ICaseType.FREE) {
        return true;
      } else if (this.item.type === ICaseType.FREE && this.item.free?.count) {
        return true;
      }
      return false;
    }
    return false;
  }
  public openModal(): void {
    this.openRevisionModal.emit(this.item.id);
  }
  public addCase(caseItem: ICaseItemDtoV2): void {
    this.onaddCase.emit([caseItem]);
  }
  public removeCase(caseItem: ICaseItemDtoV2): void {
    this.onremoveCase.emit([caseItem]);
  }
  public changeAmount(amount: number, caseItem: ICaseItemDtoV2) {
    if (this.currentCaseAmount < amount) {
      this.addCase(caseItem);
    } else {
      this.removeCase(caseItem);
    }
  }
}

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    LazyLoadImageModule,
    RouterModule,
    AppCurrencyModule,
    MatIconModule,
    SelectionInputModule,
    TagComponentModule,
  ],
  declarations: [CaseCardComponent, CaseCardMockComponent],
  exports: [CaseCardComponent, CaseCardMockComponent],
})
export class CaseCardComponentModule {}
