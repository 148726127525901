import {Injectable} from '@angular/core';
import {Environments, EnvironmentService} from '@csgofast/core/environment-service';
import {
  IActivateCampaignResponse,

  IReferralCampaign,
  IReferralCampaignReport,
  PromoCode,
} from '@dev-fast/types';

import {Observable} from 'rxjs';
import {ApiService} from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ReferralsApiService {
  private environment: Environments;

  constructor(private readonly api: ApiService, private readonly environmentService: EnvironmentService) {
    this.environment = this.environmentService.environments;
  }

  public requestCampaignProfit(params: { campaignId?: number; referralId?: number }): Observable<any> {
    return this.api.post(`${this.environment.GATEWAY_URL}/api/referral-income/take`, params, { withCredentials: true });
  }

  public requestCampaignCreate(params: Record<string, any>): Observable<any> {
    const appId = 2; // TODO: CrossService id from nowhere (csgo);
    return this.api.post(
      `${this.environment.GATEWAY_URL}/api/referral-campaigns`,
      {
        ...params,
        appId,
      },
      { withCredentials: true }
    );
  }

  public requestCampaignPatch(params: Record<string, any>): Observable<any> {
    const { id, ...rest } = params;
    return this.api.patch(`${this.environment.GATEWAY_URL}/api/referral-campaigns/${id}`, rest, { withCredentials: true });
  }

  public requestCampaignCodeExisting(code: string): Observable<any> {
    return this.api.post(`${this.environment.GATEWAY_URL}/api/referral-campaigns/code`, { code });
  }

  public requestPromoActivation(code: string): Observable<IActivateCampaignResponse> {
    return this.api.post<IActivateCampaignResponse>(
      `${this.environment.GATEWAY_URL}/api/referral-codes/${encodeURIComponent(code)}/activate`,
      {},
      { withCredentials: true }
    );
  }

  // public requestWithDrawActivation(code: string): Observable<string> {
  //   return this.api.post<string>(
  //     `${this.environment.GATEWAY_URL}/api/referral-codes/${encodeURIComponent(code)}/activate`,
  //     {},
  //     { withCredentials: true }
  //   );
  // }

  public requestPromoClicks(code: string): Observable<PromoCode> {
    return this.api.post<PromoCode>(`${this.environment.GATEWAY_URL}/api/referral-codes/${encodeURIComponent(code)}/click`);
  }

  public getReferralsKek() {
    return this.api.get(`${this.environment.GATEWAY_URL}/referrals/kek`, { withCredentials: true });
  }

  public getReferralCampaigns() {
    return this.api.get<IReferralCampaign[]>(`${this.environment.GATEWAY_URL}/api/referral-campaigns`, { withCredentials: true });
  }
  public getCurrentReferralCampaign() {
    return this.api.get<PromoCode>(`${this.environment.GATEWAY_URL}/api/referral-campaigns/current`, {
      withCredentials: true,
    });
  }
  public getInfo() {
    return this.api.get(`${this.environment.GATEWAY_URL}/api/referral-income`, { withCredentials: true });
  }
  public getCampaignReferralsReport(params: Record<string, any>) {
    return this.api.get<IReferralCampaignReport[]>(`${this.environment.GATEWAY_URL}/api/referral-reports/incomes`, {
      withCredentials: true,
      params,
    });
  }
}
