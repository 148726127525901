import {Component, EventEmitter, Input, NgModule, OnChanges, OnDestroy, Output, SimpleChanges,} from '@angular/core';
import {CommonModule} from '@angular/common';
// import { SoundService } from '@csgofast/core/sound-service';
import { BehaviorSubject, Observable, Subject, switchMap, timer, NEVER, map, tap, filter, takeUntil, startWith } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'fast-ui-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountdownComponent implements OnChanges, OnDestroy {
  @Input() public onlyText: boolean;
  @Input() public freeze: boolean;
  @Input() public showHours: boolean;
  @Input() public showDays: boolean;
  @Input() public showSeconds: boolean;
  @Input() public viewSign: boolean;
  @Input()
  public until!: number;

  @Input()
  public set status(state: boolean) {
    if (this.countdown.getValue() === state) return;
    this.toggleCountdown(state);
  }

  @Input()
  public set minutes(minutes: number) {
    this.percent = minutes;
  }
  @Input()
  public reset!: boolean;
  @Input() public muted: boolean;
  private countdown: BehaviorSubject<boolean>;
  private countdown$: Observable<boolean>;
  private readonly interval = 1000;
  private percent = 0;
  private time: number;
  private destroyed$: Subject<void>;

  @Output() public countdownFinished: EventEmitter<void>;

  public days$!: Observable<number[]>;
  public hours$!: Observable<number[]>;
  public minutes$!: Observable<number[]>;
  public seconds$!: Observable<number[]>;
  private ms$!: Observable<number>;
  private remainingSeconds$!: Observable<number>;

  private timeout!: number;

  constructor() {
    this.countdownFinished = new EventEmitter<void>();

    this.countdown = new BehaviorSubject<boolean>(false);
    this.countdown$ = this.countdown.asObservable();
    this.destroyed$ = new Subject<void>();

    this.time = this.percent * this.interval * 60;
    this.muted = false;
    this.onlyText = true;
    this.freeze = true;
    this.showSeconds = true;
    this.showHours = false;
    this.showDays = false;
    this.viewSign = false;

    this.subscribeEmitters();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['until'].currentValue && changes['until'].currentValue !== changes['until'].previousValue) {
      this.time = changes['until'].currentValue;
      this.countdown.next(true);
    }

    if (changes?.['reset']?.currentValue) {
      this.time = this.percent * this.interval * 60;
      this.countdown.next(true);
      this.timeout = window.setTimeout(() => this.countdown.next(false), 1000);
    }
  }

  public ngOnDestroy(): void {
    clearTimeout(this.timeout);
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public timeToString(t: number): string {
    return t < 10 ? `0${t}` : t.toString();
  }

  public toggleCountdown(state: boolean): void {
    this.countdown.next(state);
  }

  private toMs(t: number) {
    return t * this.interval;
  }

  private toDays(ms: number): number[] {
    return this.timeToString(Math.floor(ms / this.interval / 3600 / 24))
      .split('')
      .map((t: string) => parseInt(t, 10));
  }
  private toHours(ms: number): number[] {
    const norm = Math.floor((ms / this.interval / 3600) % (this.showDays ? 24 : 60));
    return this.timeToString(norm)
      .split('')
      .map((t: string) => parseInt(t, 10));
  }
  private toMinutes(ms: number): number[] {
    const norm = Math.floor((ms / this.interval / 60) % 60);

    return this.timeToString(norm)
      .split('')
      .map((t: string) => parseInt(t, 10));
  }

  private toSeconds(ms: number): number[] {
    return this.timeToString(Math.floor((ms / this.interval) % 60))
      .split('')
      .map((t: string) => parseInt(t, 10));
  }

  private currentSeconds(): number {
    return this.time / this.interval;
  }

  private remainingSeconds(t: number) {
    return this.currentSeconds() - t;
  }

  private subscribeEmitters(): void {
    this.remainingSeconds$ = this.countdown$.pipe(
      switchMap((running: boolean) => (running ? timer(0, this.interval) : NEVER)),
      map((t: number) => this.remainingSeconds(t)),
      tap((t) => {
        if (this.muted) return;
        if (t > 5) {
          if (Math.round(t) % 2 === 0) {
            // this.soundService.playAudio('classic.tak');
          } else {
            // this.soundService.playAudio('classic.tik');
          }
        } else {
          // this.soundService.playAudio('classic.raffling-five-seconds');
        }
      }),
      filter((time: number) => time >= 0)
    );

    this.remainingSeconds$.pipe(takeUntil(this.destroyed$)).subscribe((time: number) => {
      if (Math.floor(time) === 0) {
        this.countdownFinished.emit();
      }
    });

    this.ms$ = this.remainingSeconds$.pipe(map((t: number) => this.toMs(t)));
    this.days$ = this.ms$.pipe(
      map((t: number) => this.toDays(t)),
      startWith(this.toDays(this.time))
    );
    this.hours$ = this.ms$.pipe(
      map((t: number) => this.toHours(t)),
      startWith(this.toHours(this.time))
    );
    this.minutes$ = this.ms$.pipe(
      map((t: number) => this.toMinutes(t)),
      startWith(this.toMinutes(this.time))
    );
    this.seconds$ = this.ms$.pipe(
      map((t: number) => this.toSeconds(t)),
      startWith(this.toSeconds(this.time))
    );
  }
}

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [CountdownComponent],
  exports: [CountdownComponent],
})
export class CountdownComponentModule {}
