import { NotFoundPageComponent } from './not-found-page.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [
    TranslateModule,
    MatIconModule,
    CommonModule,
    RouterModule
  ],
  declarations: [NotFoundPageComponent],
  exports: [NotFoundPageComponent],
})
export class NotFoundPageModule {
}
