<ng-container *ngIf="reward$ | async as reward">
  <div
    class="progress-block prize-available"
    *ngIf="reward && !reward.caseRevisionResult"
  >
    <div class="prize-available--greetings">
      <div class="prize-available--greetings-title">{{ 'GOFAST.NY_EVENT.CONGRATULATIONS' | translate }}</div>
      <div class="prize-available--greetings-info">
        {{ 'GOFAST.NY_EVENT.CONGRATULATIONS_TEXT1' | translate }} <br />
        {{ 'GOFAST.NY_EVENT.CONGRATULATIONS_TEXT2' | translate }}
      </div>

      <div class="prize-available--case w-medium">
        <div class="prize-available--case-title">{{ reward.case?.name }}</div>
        <button
          class="btn btn-primary"
          (click)="onOpenCase(reward)"
          style="text-transform: uppercase"
        >
          {{ 'GOFAST.NY_EVENT.OPEN_CASE' | translate }}
        </button>
      </div>
    </div>
    <div style="flex: 1">
      <div
        class="prize-available--image replay-img"
        (click)="onOpenCase(reward)"

      >
        <img
          style="width: 100%; height: 100%;"
          class="skin-avatar"
          [src]="reward.case?.backgroundImage ? reward.case?.backgroundImage : '/assets/img/ny-event/box6.jpg'"
          lazyLoadImage
         alt=""/>
      </div>
    </div>
    <div class="prize-available--case w-medium-n">
      <div class="prize-available--case-title">{{ reward.case?.name }}</div>
      <button
        class="btn btn-primary"
        (click)="onOpenCase(reward)"
      >
        {{ 'GOFAST.NY_EVENT.OPEN_CASE' | translate }}
      </button>
    </div>
  </div>
</ng-container>
