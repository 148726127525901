import {Component, OnDestroy} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Location} from '@angular/common';
import {merge, Observable, Subject} from 'rxjs';
// import { widgetsMenuComponentAnimation } from '../widgets-menu.animation';
import {distinct, map, mapTo, skip, takeUntil} from 'rxjs/operators';
import {P2pBuyingService, P2pDepositService} from '@csgofast/modules/p2p';
import {SectionType} from './p2p.constants';
import {IP2pDepositItem, IP2pPurchaseItem} from '@dev-fast/types';

@Component({
  selector: 'csgofast-p2p',
  templateUrl: './p2p.component.html',
  styleUrls: ['./p2p.component.scss'],
  // animations: [widgetsMenuComponentAnimation],
})
export class P2pComponent implements OnDestroy {
  public readonly selected$: Observable<IP2pDepositItem[]>;
  public readonly purchasing$: Observable<IP2pPurchaseItem[]>;
  private destroyed$: Subject<void>;
  public selected: FormControl;
  public readonly SectionType = SectionType;
  // @HostBinding('@widgetsMenuComponentAnimation') private animation = true;

  constructor(
    private readonly location: Location,
    private readonly p2pDepositService: P2pDepositService,
    private readonly p2pBuyingService: P2pBuyingService
  ) {
    this.selected$ = this.p2pDepositService.selected$;
    this.purchasing$ = this.p2pBuyingService.purchasing$;
    this.destroyed$ = new Subject();

    this.selected = new FormControl(this.location.path().includes('store') ? SectionType.Purchase : SectionType.Sell);

    this.subscribeEmitters();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private subscribeEmitters(): void {
    // FIXME всю логику по определению активной вкладки возложить на стейт, тут только отображать

    merge(
      this.selected$.pipe(skip(1), mapTo(SectionType.Sell)),
      this.purchasing$.pipe(
        skip(1),
        map((purchasing) => purchasing.length),
        distinct(),
        mapTo(SectionType.Purchase)
      )
    )
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (this.selected) {
          this.selected.patchValue(value);
        }
      });
  }
}
