import { trigger, transition, style, animate, query } from '@angular/animations'

export const winnerIconAnimation = trigger('winnerIconAnimation', [
	transition(':enter',  [
		query(':self',
		[
		  style({opacity: 0}),
		  animate('150ms ease', style({opacity: 1})),
		]	  
		)
	]),
  ])
