import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NyResultsLeadersComponent} from "./ny-results-leaders.component";


const routes: Routes = [
  {
    path: '',
    component: NyResultsLeadersComponent,
    pathMatch: 'full',
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NyResultsLeadersRoutingModule {}
