import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Environments, EnvironmentService} from '@csgofast/core/environment-service';
import {Socket as WrappedSocket} from "ngx-socket-io";
// import { NotificationsService } from '@csgofast/notifications';
import {ApiService} from '@dev-fast/backend-services';
// FIXME сокеты
// import { Socket } from 'ngx-socket-io';
import {catchError, Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  private environment: Environments;
  private endpoint = 'api/authorization';

  constructor(
    private readonly api: ApiService,
    private readonly environmentService: EnvironmentService,
    private readonly ws: WrappedSocket // private readonly ws: Socket,
  ) // @Optional() private readonly notifyService: NotificationsService
  {
    this.environment = this.environmentService.environments;
  }
  public login = (payload: { accessToken: string }) => this.ws.emit('user.auth', payload);
  // public login = (payload: { accessToken: string }) => console.log(payload);
  public logout = (): Observable<void> =>
    this.api.post(`${this.environment.GATEWAY_URL}/${this.endpoint}/logout`, null, { withCredentials: true });

  public updateTokens = (
    refreshToken: string
  ): Observable<{
    accessToken: string;
    refreshToken: string;
  }> =>
    this.api.post<{ accessToken: string; refreshToken: string }>(`${this.environment.GATEWAY_URL}/${this.endpoint}/refresh-token`, {
      refreshToken,
    });

  public getAuthorizationTokens = (code: string): Observable<{ accessToken: string }> =>
    this.api.post<{ accessToken: string }>(`${this.environment.GATEWAY_URL}/${this.endpoint}/tokens`, { code }, { withCredentials: true });

  public removeAccount = (account: string): Observable<void> =>
    this.api
      .delete<void>(`${this.environment.GATEWAY_URL}/api/providers/${account}`, {
        withCredentials: true,
      })
      .pipe(catchError((error: HttpErrorResponse) => this.onError(error)));

  private onError(e: HttpErrorResponse): Observable<void> {
    // this.notifyService.addNotification({
    //   id: Date.now(),
    //   type: 'error',
    //   icon: 'warning',
    //   message: e.error.message || e.error.error,
    //   createDate: Date.now(),
    //   status: NotificationStatus.new,
    //   system: true,
    // });
    return of();
  }
}
