import { Injectable } from '@angular/core';
import { CurrencyService } from '@csgofast/core/currency-service';
import { FrameMessageTypes, IFrameMessageService } from '@csgofast/core/iframe';
import { RefreshActivePanel } from '@csgofast/core/state/layout';

import { InventoryService } from '@dev-fast/backend-services';
import {
  GameIds,
  IInventoryItem,
  IInventoryRequestParams,
  InventoryTradeBalance,
  IShopMeta,
  ISkinItem,
  ISkinItemV2,
  IUserInventoryItemV2,
  Panel,
  SubPanel,
} from '@dev-fast/types';
import { Action, Actions, ofAction, Selector, State, StateContext } from '@ngxs/store';
import { append, insertItem, patch, removeItem } from '@ngxs/store/operators';
import { delay, switchMap, takeUntil, tap } from 'rxjs';
import { INVENTORY_INITIAL_STATE, InventoryStateModel } from './inventory-state.model';
import {
  ChangeParamsInventory,
  ChangeParamsShop,
  ClickOnInventoryItem,
  ClickOnShopItem,
  GetInventoryInfo,
  GetInventoryItems,
  GetShopItems,
  Purchase,
  RemoveInventoryItems,
  SellAllItems,
  SellAllItemsSuccess,
  SellItems,
  ToggleAllInventoryItems,
  ToggleGameStatus,
  Trade,
  UnselectInventoryItemById,
} from './inventory.actions';

@State<InventoryStateModel>({
  name: 'inventory',
  defaults: INVENTORY_INITIAL_STATE,
})
@Injectable()
export class InventoryState {
  constructor(
    private readonly apiService: InventoryService,
    private readonly currencyService: CurrencyService,
    private readonly frameMessageService: IFrameMessageService,
    private readonly actions$: Actions
  ) {}

  @Selector()
  public static items({ items }: InventoryStateModel): IInventoryItem[] {
    return items;
  }
  @Selector()
  public static inventorySum({ inventorySum }: InventoryStateModel): number {
    return inventorySum;
  }
  @Selector()
  public static inventoryCount({ inventoryCount }: InventoryStateModel): number {
    return inventoryCount;
  }
  @Selector()
  public static tradeBalance({ contracts, selectedItems }: InventoryStateModel): InventoryTradeBalance {
    const selectedItemsSum = selectedItems.reduce((a, b) => a + b.price, 0);
    const selectedShopItemsSum = contracts.reduce((a, b) => a + b.price, 0);

    return { selectedItemsSum, selectedShopItemsSum, tradeBalance: selectedItemsSum - selectedShopItemsSum };
  }
  @Selector()
  public static shopItems({ shopItems }: InventoryStateModel): ISkinItem[] {
    return shopItems;
  }
  @Selector()
  public static contracts({ contracts }: InventoryStateModel): ISkinItem[] {
    return contracts;
  }
  @Selector()
  public static selectedItems({ selectedItems }: InventoryStateModel): ISkinItem[] {
    return selectedItems;
  }
  @Selector()
  public static inventorySortingParams({ params }: InventoryStateModel): string | boolean {
    return params.sortBy ? params.sortBy : true;
  }
  @Selector()
  public static shopParams({ shopParams }: InventoryStateModel): IInventoryRequestParams {
    return shopParams;
  }
  @Selector()
  public static inventoryParams({ params }: InventoryStateModel): IInventoryRequestParams {
    return params;
  }
  @Selector()
  public static shopMeta({ shopMeta }: InventoryStateModel): IShopMeta {
    return shopMeta;
  }
  @Selector()
  public static gameInProgress({ gameInProgress }: InventoryStateModel): boolean {
    return gameInProgress;
  }
  ngxsOnInit({ dispatch }: StateContext<InventoryStateModel>) {
    this.frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'requestInventory', () => dispatch([new GetInventoryItems()]), 600);
  }
  @Action(RefreshActivePanel)
  public refreshActivePanel({ dispatch }: StateContext<InventoryStateModel>, { payload }: RefreshActivePanel) {
    if (payload && payload.panel === Panel.INVENTORY) {
      dispatch([new GetInventoryItems()]);
    }
    if (payload && payload.subPanel === SubPanel.EXHANGE) {
      dispatch(new GetShopItems());
    }
  }

  @Action(SellItems)
  public sellInventoryItems({ dispatch }: StateContext<InventoryStateModel>, { ids }: SellItems) {
    return this.apiService.sellInventoryItems({ ids }).pipe(
      delay(500),
      tap(() => {
        dispatch([new RemoveInventoryItems(ids), new GetInventoryInfo()]);
      }),
      takeUntil(this.actions$.pipe(ofAction(SellItems)))
    );
  }

  @Action(SellAllItems)
  public sellAllItems({ dispatch, getState }: StateContext<InventoryStateModel>) {
    const { itemsIds, params } = getState();
    return this.apiService
      .sellAll()
      .pipe(switchMap(() => dispatch([new ChangeParamsInventory({ ...params, page: 1 }), new SellAllItemsSuccess(itemsIds)])));
  }

  @Action(Purchase)
  public purchase({ patchState, dispatch }: StateContext<InventoryStateModel>, { ids, userInventoryIds }: Purchase) {
    return this.apiService.requestCreateTrade({ ids, userInventoryIds }).pipe(
      switchMap(() => dispatch([new GetInventoryItems()])),
      tap(() => patchState({ contracts: [] }))
    );
  }

  @Action(Trade)
  public trade({ dispatch, getState }: StateContext<InventoryStateModel>) {
    const { selectedItems, contracts } = getState();

    const ids = contracts.map((el) => el.id);
    const userInventoryIds = selectedItems.map((el) => el.id);

    if (ids.length) {
      return dispatch([new Purchase(ids, userInventoryIds)]);
    } else {
      return dispatch([new SellItems(userInventoryIds)]);
    }
  }

  @Action(GetInventoryItems, { cancelUncompleted: true })
  public getInventoryItems({ getState, dispatch, setState }: StateContext<InventoryStateModel>) {
    const { params, appId } = getState();
    const normalizedParams = this.normalizeParams(params, appId);
    const isFirstPage = params.page ? params.page === 1 : false;

    return this.apiService.requestInventory(normalizedParams).pipe(
      tap((response) => {
        const responseItems = response[appId].map((i) => this.IUserInventoryItemV2ToIInventoryItem(i, appId));
        setState(
          patch({
            items: isFirstPage ? responseItems : append(responseItems),
            itemsIds: isFirstPage ? responseItems.map((item) => item.id) : append(responseItems.map((item) => item.id)),
            selectedItems: [] as IInventoryItem[],
          })
        );
      }),
      switchMap(() => dispatch(new GetInventoryInfo()))
    );
  }
  // TODO перенес но не понимаю нахуа вообще нужен этот запрос
  @Action(GetInventoryInfo)
  public getInventoryInfo({ patchState }: StateContext<InventoryStateModel>) {
    return this.apiService.requestInventoryInfo().pipe(
      tap((response) => {
        const currentInfo = response.find((item) => item.appId === GameIds.CSGO);
        if (currentInfo) {
          const nullInfo = response.find((item) => !item.appId);
          patchState({
            inventoryCount: (currentInfo?.itemsCount ?? 0) + (nullInfo?.itemsCount ?? 0),
            inventorySum: currentInfo.itemsSum,
          });
        } else {
          patchState({
            inventoryCount: 0,
            inventorySum: 0,
          });
        }
      })
    );
  }

  @Action(GetShopItems)
  public getShopItems({ patchState, getState }: StateContext<InventoryStateModel>) {
    const { shopParams, appId } = getState();
    const normalizedParams = this.normalizeParams(shopParams, appId);

    return this.apiService.requestShop(normalizedParams).pipe(
      tap((response) => {
        patchState({ shopItems: response.items.map((i) => this.ISkinItemV2ToISkinItem(i, response.appId)), shopMeta: response.meta });
      })
    );
  }

  @Action(ChangeParamsShop)
  public changeParamsShop({ patchState, dispatch, getState }: StateContext<InventoryStateModel>, { params }: ChangeParamsShop) {
    const state = getState();
    const page = state.shopParams.page === params.page ? 1 : params.page;
    patchState({ shopParams: { ...params, page } });
    return dispatch([new GetShopItems()]);
  }

  @Action(ChangeParamsInventory)
  public changeParamsInventory({ patchState, dispatch, getState }: StateContext<InventoryStateModel>, { params }: ChangeParamsInventory) {
    const { inventoryCount, ...state } = getState();
    const page = state.params.page === params.page ? 1 : params.page;
    const totalPages = Math.ceil(inventoryCount / (params.pageSize ? params.pageSize : 50));

    if (totalPages < (page || 1)) {
      return;
    }
    patchState({ params: { ...params, page } });
    return dispatch([new GetInventoryItems()]);
  }

  @Action(ClickOnShopItem)
  public clickOnShopItem({ setState, getState }: StateContext<InventoryStateModel>, { id }: ClickOnShopItem) {
    const { contracts, shopItems } = getState();
    const item = shopItems.find((el) => el.id === id);
    const selected = contracts.some((el) => el.id === id);

    if (selected) {
      setState(
        patch({
          contracts: removeItem<ISkinItem>((x) => x?.id === id),
        })
      );
    } else {
      setState(patch<any>({ contracts: insertItem(item) }));
    }
  }
  @Action(ClickOnInventoryItem)
  public clickOnInventoryItem({ setState, getState }: StateContext<InventoryStateModel>, { id }: ClickOnInventoryItem) {
    const { selectedItems, items } = getState();
    const item = items.find((el) => el.id === id);
    const selected = selectedItems.some((el) => el.id === id);

    if (selected) {
      setState(
        patch({
          selectedItems: removeItem<IInventoryItem>((x) => x?.id === id),
        })
      );
    } else {
      setState(patch<any>({ selectedItems: insertItem(item) }));
    }
  }
  @Action(RemoveInventoryItems)
  public removeInventoryItems({ patchState, getState }: StateContext<InventoryStateModel>, { itemsIds }: RemoveInventoryItems) {
    const { items } = getState();

    const filteredItems = items.filter((el) => !itemsIds.includes(el.id));

    patchState({
      selectedItems: [],
      items: filteredItems,
      itemsIds: filteredItems.map((item) => item.id),
    });
  }

  @Action(ToggleAllInventoryItems)
  public toggleAllInventoryItems({ patchState, getState }: StateContext<InventoryStateModel>) {
    const { selectedItems, items } = getState();

    if (selectedItems.length === items.length) {
      patchState({
        selectedItems: [],
      });
    } else {
      patchState({
        selectedItems: [...items],
      });
    }
  }

  @Action(ToggleGameStatus)
  public toggleGameStatus({ patchState }: StateContext<InventoryStateModel>, { gameInProgress }: ToggleGameStatus): void {
    patchState({
      gameInProgress: gameInProgress,
    });
  }

  @Action(UnselectInventoryItemById)
  public clearSelection({ patchState, getState }: StateContext<InventoryStateModel>, { ids }: UnselectInventoryItemById) {
    const { selectedItems } = getState();
    const filtered = selectedItems.filter((item: IInventoryItem) => !ids.includes(item.id));

    patchState({
      selectedItems: filtered,
    });
  }

  // private onError (e: HttpErrorResponse): Observable<object> {
  //   this.inventoryExists.next(false);
  //   this.notificationsService.addNotification({
  //     id: Date.now(),
  //     type: 'error',
  //     icon: 'warning',
  //     message: e.error.message || e.error.error,
  //     createDate: Date.now(),
  //     system: true,
  //     status: NotificationStatus.new,
  //   });
  //   return of(undefined);
  // }

  ////////////////////
  private normalizeParams(params: IInventoryRequestParams, appId: GameIds) {
    const { page, pageSize, sortBy, minPrice, maxPrice, ...rest } = params;
    const normPrice = (val: number | null | undefined) => val && this.currencyService.revert(val);
    return {
      ...rest,
      minPrice: normPrice(minPrice),
      maxPrice: normPrice(maxPrice),
      appId,
      page: {
        number: page,
        size: pageSize,
      },
      sortBy: sortBy ? 'price' : '-price',
    };
  }
  private ISkinItemV2ToISkinItem(item: ISkinItemV2, appId: GameIds | undefined): ISkinItem {
    return {
      appId: appId,
      available: item.available,
      color: item.baseItem.color,
      icon: item.baseItem.icon,
      id: item.id,
      name: item.baseItem.name,
      price: item.price,
    };
  }
  private IUserInventoryItemV2ToIInventoryItem(item: IUserInventoryItemV2, appId: GameIds): IInventoryItem {
    return {
      appId,
      available: item.inventoryItem.available,
      color: item.inventoryItem.baseItem.color,
      icon: item.inventoryItem.baseItem.icon,
      id: item.id,
      inventoryItemId: item.inventoryItemId,
      name: item.inventoryItem.baseItem.name,
      price: item.inventoryItem.price,
      //todo
      type: 'skin',
    };
  }
}
