import {animate, query, style, transition, trigger} from '@angular/animations';

export const changeAnimation = trigger('changeAnimation', [
  transition((fromState, toState) => fromState !== toState, [
    query(':self', [
      style({ opacity: 0 }),
      animate('500ms ease-in-out', style({ transform: 'rotate3d(0, 1, 0, 360deg)', opacity: 1 })),
    ]),
  ]),
]);
