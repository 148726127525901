import {Injectable} from '@angular/core';
import {AuthService} from '@csgofast/auth';
import {UserState} from '@csgofast/core/state/user-store';

import {IUserP2pPermissionInterface, SocialType} from '@dev-fast/types';

import {Actions, Select} from '@ngxs/store';
import {Observable} from 'rxjs';

@Injectable()
export class P2pPurchaseService {
  @Select(UserState.p2pPermissions)
  public readonly p2pPermissions$!: Observable<IUserP2pPermissionInterface>;

  // public tradeLinkUpdated$ = this.actions$.pipe(ofActionSuccessful(UpdateTradeLinkSuccess));
  // public apiKeyUpdated$ = this.actions$.pipe(ofActionSuccessful(UpdateApiKeySuccess));

  constructor(private readonly actions$: Actions, private readonly authService: AuthService) {}

  public bindProvider(provider: SocialType) {
    this.authService.bindProvider(provider);
  }
  // @Dispatch() public updateApiKey = (apiKey: string) => new UpdateApiKey(apiKey);
  // @Dispatch() public updateTradeLink = (links: TradeLinks) => new UpdateTradeLink(links);
}
