import { ComponentType } from '@angular/cdk/overlay';
import { Injectable, OnDestroy } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import {CloseAllModals, CloseModal, OpenModal} from '@csgofast/core/state/modals';
import { ModalModel, ModalNames } from '@dev-fast/types';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { map, Observable, Subject, switchMap, takeUntil, throwError } from 'rxjs';

// FIXME лучше чем было но мне всеравно не нравится так
@Injectable()
export class ModalsService implements OnDestroy {
  public modalsDictionary: Map<ModalNames, MatDialogRef<any> | null>;
  private subject$: Subject<void>;
  protected modalsList: Record<number, (...args: any[]) => Observable<any>>;

  public modalOpened$ = this.actions$.pipe(
    ofActionSuccessful(OpenModal),
    map((action: OpenModal) => ({ name: action.name, payload: action.payload }))
  );

  public modalClosed$ = this.actions$.pipe(
    ofActionSuccessful(CloseModal),
    map((action: CloseModal) => ({ name: action.name, payload: action.payload }))
  );
  public allModalClosed$ = this.actions$.pipe(
    ofActionSuccessful(CloseAllModals),
  );

  constructor(protected dialog: MatDialog, protected actions$: Actions) {
    this.modalsList = {};
    this.modalsDictionary = new Map();
    this.subject$ = new Subject();

    // this.registerModals()
    this.modalOpened$
      .pipe(
        takeUntil(this.subject$),
        switchMap((el) => this.openDialog(el.name, el.payload))
      )
      .subscribe((val) => {
        if (this.modalsDictionary.has(val.name)) {
          this.modalsDictionary.delete(val.name);
          this.closeModal(val.name, val.payload);
        }
      });
    this.modalClosed$.pipe(takeUntil(this.subject$)).subscribe((val) => {
      if (this.modalsDictionary.has(val.name)) {
        this.modalsDictionary.get(val.name)?.close();
        this.modalsDictionary.delete(val.name);
      }
    });
    this.allModalClosed$.pipe(takeUntil(this.subject$)).subscribe((val) => {

      this.modalsDictionary.forEach(value => {
        if(value){
          value.close();
        }
      })
      this.modalsDictionary.clear();
    });
  }
  public registerModals(modals: ModalModel[]): void {
    modals.forEach((el) => {
      this.modalsList[el.name] = this.openModalFactory(el.name, el.component, el.config);
    });
  }
  private openModalFactory(name: ModalNames, component: ComponentType<any>, config?: MatDialogConfig<any>) {
    const openMethod = (name: ModalNames, payload: any) => {
      const params: MatDialogConfig = {
        ...config,
        data: payload,
      };
      const dialogRef = this.dialog.open(component, params);
      this.modalsDictionary.set(name, dialogRef);

      return dialogRef.afterClosed().pipe(map((el) => ({ name, payload: el })));
    };
    return openMethod;
  }
  public openDialog(name: ModalNames, payload: any) {
    if (!this.modalsList) return throwError(() => new Error(`Modals list empty`));
    if (!this.modalsList[name]) return throwError(() => new Error(`Modal not found`));
    return this.modalsList[name](name, payload);
  }
  public ngOnDestroy(): void {
    this.subject$.next();
    this.subject$.complete();
  }
  @Dispatch() public closeModal = <T>(name: ModalNames, payload?: T) => {
    if (this.modalsDictionary.has(name)) {
      this.modalsDictionary.delete(name);
      return;
    }
    return new CloseModal(name, payload);
  };
}
