import {ChangeDetectionStrategy, Component, Input, NgModule, OnDestroy, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BaseType} from '@dev-fast/types';
import {NavigationEnd, Router, RouterModule} from '@angular/router';
import {BehaviorSubject, filter, Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'fast-ui-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  private readonly destroyed$: Subject<void> = new Subject<void>();

  private readonly breadcrumbsList: BehaviorSubject<BaseType[]> = new BehaviorSubject<BaseType[]>([]);
  public readonly breadcrumbsList$: Observable<BaseType[]> = this.breadcrumbsList.asObservable();

  @Input() items: BaseType[] = [];
  private breadcrumbs: BaseType[] = [];

  constructor(private router: Router) {}

  public ngOnInit(): void {
    // При первой загрузке генерирует крошки
    this.addBreadcrumbsToList(this.router.routerState.snapshot.url);
    // Подписка на события роута внутри компонента и генерация крошек
    this.router.events
      .pipe(
        takeUntil(this.destroyed$),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe((e) => {
        this.breadcrumbs = [];
        if (e instanceof NavigationEnd) {
          this.addBreadcrumbsToList(e.urlAfterRedirects);
        }
      });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private addBreadcrumbsToList(url: string): void {
    this.items.forEach((item) => {
      if (url.includes(item.value)) {
        this.breadcrumbs.push(item);
      }
      this.breadcrumbs.sort((a, b) => a.value.length - b.value.length);
      this.breadcrumbsList.next(this.breadcrumbs);
    });
  }
}

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [BreadcrumbsComponent],
  exports: [BreadcrumbsComponent],
})
export class BreadcrumbsComponentModule {}
