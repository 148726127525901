import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, tap} from 'rxjs';

export const LAYOUT_SHORT_TYPES_MAP = {
  // [Breakpoints.Small]: Breakpoints.Handset,
  // [Breakpoints.XSmall]: Breakpoints.Handset,
  // [Breakpoints.Medium]: Breakpoints.Web,
  // [Breakpoints.Large]: Breakpoints.Web,
  // [Breakpoints.XLarge]: Breakpoints.Web,
  '(max-width: 499px)': Breakpoints.XSmall,
  '(max-width: 840px)': Breakpoints.Tablet,
  '(max-width: 980px)': Breakpoints.Handset,
  '(min-width: 981px)': Breakpoints.Web,
};

export const LAYOUT_TYPES = [
  // Breakpoints.Small,
  // Breakpoints.XSmall,
  // Breakpoints.Medium,
  // Breakpoints.Large,
  // Breakpoints.XLarge,
  '(max-width: 499px)',
  '(max-width: 840px)',
  '(max-width: 980px)',
  '(min-width: 981px)',
];

@Injectable({
  providedIn: 'root',
})
export class LayoutTypeService {
  private readonly layoutSubject$ = new BehaviorSubject<string>(Breakpoints.Handset);

  get layoutType$(): Observable<string> {
    return this.layoutSubject$.asObservable();
  }

  get snapshotLayoutType(): string {
    return this.layoutSubject$.value;
  }

  constructor(private readonly breakpointObserver: BreakpointObserver) {
    this.breakpointObserver
      .observe(LAYOUT_TYPES)
      .pipe(
        tap((result) => {
          let type;
          for (const query of Object.keys(result.breakpoints)) {
            if (result.breakpoints[query]) {
              const getKeyValue = <T, K extends keyof T>(obj: T, key: K): T[K] => obj[key];
              // type = (LAYOUT_SHORT_TYPES_MAP as any)[query];
              type = getKeyValue(LAYOUT_SHORT_TYPES_MAP, query as any);
              break;
            }
          }

          this.layoutSubject$.next(type ?? Breakpoints.Handset);
        })
      )
      .subscribe();
  }

  is(size: string): boolean {
    return size === this.snapshotLayoutType;
  }
}
