<div
  class="iframe"
  #iframeWrapper
  *ngIf="showIframe"
  [ngClass]="{ none: !showOtherComponent }">
  <iframe
    width="100%"
    height="100%"
    frameBorder="0"
    allow="autoplay"
    [src]="urlSafe"
    name="legacy"
    #iframe
    [id]="'bb_frame'"
    (load)="frameLoaded($event)"
    (error)="frameRejected($event)"
  ></iframe>
  <div
    class="loader"
    *ngIf="showLoader"
  >
    <!-- <fast-ui-cube-grid-loader
      class="inventory-loader-wrapper"
      [size]="'5rem'"
      [color]="'var(--color-typography-light)'"
    ></fast-ui-cube-grid-loader> -->
    <mat-spinner color="accent"></mat-spinner>
  </div>
</div>
<ng-container *ngIf="!showOtherComponent">
  <ng-content></ng-content>
</ng-container>

<div class="seo" *ngIf="(h1$ | async)?.value || (main$ | async)?.value || (faq$ | async)?.value">
  <ng-container *ngIf="(h1$ | async) as h1"><h1 *ngIf="h1.value | translate" [innerHTML]="h1.value | translate : h1.data"></h1></ng-container>
  <ng-container *ngIf="(main$ | async) as main"><div *ngIf="main.value | translate" [innerHTML]="main.value | translate : main.data"></div></ng-container>
  <ng-container *ngIf="(faq$ | async) as faq"><div *ngIf="faq.value | translate" [innerHTML]="faq.value | translate : faq.data"></div></ng-container>
</div>
