import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {P2pAuctionCompletedModalComponent} from './p2p-auction-completed-modal.component';
import {MatIconModule} from '@angular/material/icon';
import {AppCurrencyModule} from '@csgofast/shared/pipes';
import {SkinItemImageModule} from '@csgofast/shared/directives';
import {UserAvatarModule} from '@dev-fast/ui-components';

@NgModule({
  declarations: [P2pAuctionCompletedModalComponent],
  imports: [CommonModule, MatIconModule, TranslateModule, AppCurrencyModule, SkinItemImageModule, UserAvatarModule],
  exports: [P2pAuctionCompletedModalComponent],
})
export class P2pAuctionCompletedModalModule {}
