<ng-container *ngIf="widget === 'luckOfTheDay'">
  <div class="widget widget_luck">
    <h4 class="widget-header">
      {{ 'RECENT_ACTIVITIES_WIDGET.LUCK_OF_THE_DAY' | translate }}
    </h4>
    <div class="widget-content drop-card"></div>
    <div class="widget-footer">
      <div class="widget-footer--card-info --plain-text">
        <div class="widget-footer--card-info-title">{{'GOFAST.CASES.BATTLE_GAME.BATTLE.WINNER' | translate}}</div>
        <div class="widget-footer--card-info-value loading-gradient"></div>
      </div>
      <div class="widget-footer--card-info --plain-text">
        <div class="widget-footer--card-info-title">{{'CHANCE' | translate}}</div>
        <div class="widget-footer--card-info-value loading-gradient"></div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="widget === 'playerOfTheDay'">
  <div class="widget widget_player">
    <h4 class="widget-header">
      {{ 'GOFAST.CASES.MOTIVATOR.TOP_PLAYER' | translate }}
    </h4>
    <div class="widget-content drop-card"></div>
    <div class="widget-footer">
      <div class="widget-footer--card-info --plain-text">
        <div class="widget-footer--card-info-title loading-gradient"></div>
        <div class="widget-footer--card-info-value loading-gradient"></div>
      </div>
    </div>
  </div>
</ng-container>


<!--  <ng-container *ngIf="data">-->
<!--    <div class="widget" [style.height]="data.height || '19.5rem'" >-->
<!--      <h4 class="widget-header">-->
<!--        {{ data.header | translate }}-->
<!--      </h4>-->

<!--      <ng-container *ngIf="data.replayDrop; else avatarTemplate">-->
<!--        <div class="widget-content drop-card">-->
<!--          <fast-ui-drop-card-->
<!--            [drop]="data.replayDrop"-->
<!--            [className]="'bestdrop-widget'"-->
<!--            (clickOnCard)="navigateToCaseItem($event)"-->
<!--            [isFullView]="true"-->
<!--          ></fast-ui-drop-card>-->
<!--        </div>-->
<!--      </ng-container>-->

<!--      <ng-template #avatarTemplate>-->
<!--        <div class="widget-content">-->
<!--          <div class="widget-avatar-container">-->
<!--            <fast-ui-user-avatar-->
<!--              [url]="data.avaLink"-->
<!--              [userId]="data.id"-->
<!--              [@changeAnimation]="data.id"-->
<!--            ></fast-ui-user-avatar>-->
<!--          </div>-->
<!--          <div class="widget-player-name">-->
<!--            <div class="widget-player-name-wrapper">{{ data.name }}</div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </ng-template>-->

<!--      <div-->
<!--        class="widget-footer"-->
<!--        *ngIf="data.payload as payload"-->
<!--      >-->
<!--        <div-->
<!--          class="widget-footer&#45;&#45;card-info &#45;&#45;plain-text"-->
<!--          *ngFor="let item of payload"-->
<!--        >-->
<!--          <div class="widget-footer&#45;&#45;card-info-title">{{ item.label | translate }}:</div>-->
<!--          <div-->
<!--            class="widget-footer&#45;&#45;card-info-value"-->
<!--            [ngSwitch]="item.valueType"-->
<!--          >-->
<!--            <ng-container *ngSwitchCase="'currency'">-->
<!--              {{ item.value | appCurrency }}-->
<!--            </ng-container>-->
<!--            <ng-container *ngSwitchCase="'percent'"> {{ item.value }}% </ng-container>-->
<!--            <ng-container *ngSwitchCase="'user'">-->
<!--              <fast-ui-user-avatar-->
<!--                [url]="data.avaLink"-->
<!--                [userId]="data.id"-->
<!--                [@changeAnimation]="data.id"-->
<!--              ></fast-ui-user-avatar>-->
<!--              <span class="widget-footer&#45;&#45;card-info-value&#45;&#45;user-nickname"> {{ data.name }}</span>-->
<!--            </ng-container>-->
<!--            <ng-container *ngSwitchDefault>-->
<!--              {{ item.value }}-->
<!--            </ng-container>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </ng-container>-->
