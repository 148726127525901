export interface FrameMessage<T = Record<string, any>> {
  type: FrameMessageTypes;
  eventName: string;
  payload: T;
}

export enum FrameMessageTypes {
  ROUTING_FROM_BB = 'routingFromBB',
  ROUTING_TO_BB = 'routingToBB',
  MESSAGE_FROM_BB = 'fromBB',
  MESSAGE_TO_BB = 'toBB',
  MESSAGE_FROM_BB_API = 'apiResponseFromBB',
  MESSAGE_TO_BB_API = 'apiRequestToBB',
  MESSAGE_FROM_BB_SOCKET = 'socketResponseFromBB',
  MESSAGE_TO_BB_SOCKET = 'socketRequestToBB',
}

/** Маршруты в текущем проекте (Ангуляр) */
export const excludesRoutesHome = [
  // '/',
  '/block',
  '/not-found',
  '/game/cases',
  '/game/case-battle',
  '/events/ny',
  '/events',
  '/events/ny-event',
  '/events/ny-all-event',
  '/support',
  '/support/requests',
  '/provably-fair',
  // '/faq',
  // '/leaders-race-next'
  // '/game/match-bets',
  // '/game/poggi',
];

export const excludesRoutesFrame = ['/service-route'];
/** для формирования маршрутов в ангуляре */

export const legacyRoutes = [
  'refill',
  'refill/method',
  'refill/skins',
  'refill-bonus',
  'refill/prepaid',
  'refill/history',
  'bonuses',
  'bonuses/quest',
  'bonuses/referral',
  // 'balance/withdrawal',
  'balance/refill',
  'withdrawal/method',
  'withdrawal/common',
  'withdrawal/history',
  // 'support',
  // 'support/requests',
  'championship/classic',
  'championship/fast',
  'championship/crash',
  'championship/double',
  'faq',
  'history/classic',
  'history/classic/all/:id',
  'history/fast',
  'history/crash',
  'history/double',
  'history/craft',
  'game/baccarat/history',
  'game/baccarat/how-to-play',
  'account',
  'account/settings',
  'account/experience',
  'account/referral',
  'account/referral/:id',
  'account/balance',
  'store',
  'service-route',
  'bonuses/referral/:id',
  'premium',
  'profile/:id',
  'tos',
  'privacy-policy',
  'aml-policy',
  'anti-bribery-policy',
  'refund-policy',
  'bug-bounty',
  'refill/undefined',
  'fail-payment/:userId',
  'success-payment/:userId',
  'free-coins',
// TODO при переносе faq на ангуляр переделать под формат /faq?category=craft
  'faq/craft',
  'faq/fortune-wheel',
  'faq/x50',
  'faq/poggi',
  'faq/hilo',
  'faq/fast',
  'faq/baccarat/:id',
  'faq/general/:id',
  'faq/tower',
  'faq/crash',
  'faq/casebattle',
];

/** для маршрутизации из ангуляра */
export const MatchedRoutesToBB: Record<string, string> = {
  '/balance/withdrawal': 'withdrawal.method',
  '/balance/withdrawal/hub': 'withdrawal.method',
  '/withdrawal/method': 'withdrawal.method',
  '/balance/refill/hub': 'coins.store',
  '/balance/refill': 'coins',
  '/buy-coins': 'coins',
  '/refill': 'coins',
  '/refill/method': 'coins.method',
  '/refill/skins': 'coins.steam',
  '/balance/refill/skins': 'coins.steam',
  '/store': 'marketplace',
  '/balance/refill/currency': 'coins.method',
  '/balance/refill/items/skins': 'coins.steam',
  '/bonuses': 'bonuses',
  '/bonuses/faucet': 'bonuses.faucet',
  '/bonuses/quest': 'bonuses.quest',
  '/bonuses/referral': 'bonuses.referral',
  '/account/settings': 'account.settings',
  '/account/balance': 'account.balance',
  '/account/experience': 'account.experience',
  '/account': 'account',
  // '/support': '/support',
  '/bug-bounty': 'bug-bounty',
  // '/refill-bonus': 'refill-bonus',
  '/game/fortune-wheel': 'fortune-wheel',
  '/game/tower': 'tower',
  '/game/hilo': 'hilo',
  // '/game/cases': 'cases',
  '/game/baccarat': 'baccarat',
  '/game/poggi': 'poggi',
};

export const SpecialGameRoutesToBB: Record<string, string> = {
  '/game/fast': '/game/fast',
  '/game/x50': '/game/x50',
  '/game/classic': '/game/classic',
  '/game/double': '/game/double',
  '/game/crash': '/game/crash',
  '/game/king': '/game/king',
  '/game/craft': '/game/craft',
  '/game/slot': '/game/slot',
  '/game/match-bets': '/game/match-bets',
  '/ladder': '/ladder',
  '/faq': '/faq',
  // '/faq/casebattle': '/faq/casebattle',
  '/tos': '/tos',
  '/privacy-policy': '/privacy-policy',
};

export const MatchedRoutesFromBB: Record<string, string> = {
  // '/refill/method': '/balance/refill/currency',
  // '/refill/method': '/refill/method',
  // '/refill': '/refill',
  '/support': '/support',
  '/event': '/events',
  '/event/ny-all': '/events/ny-all-event',
};

export const SpecialPartRoutesFrmBB: string[] =[
  // '/faq'
];

/**
 * Страницы, которые должны быть добавлены в SiteMap
 * комментарии [START-PARSE] и [END-PARSE]
 * нужны скрипту по созданию путей рендеров.
 * Они указывают где начинать и где заканчивать брать пути для формирования рендеров
 * Внутренние комментарии // пропускаются скриптом
 * Скрипты сидят тут: \fast-angular\scripts
 */
export const seoRoutes: string[] = [
  /*[[START-PARSE]]*/
  '/',
  '/faq',
  '/game/fast',
  '/game/classic',
  '/game/x50',
  '/game/double',
  '/game/crash',
  '/game/king',
  '/game/craft',
  '/game/slot',
  '/game/match-bets',
  '/game/cases',
  '/game/fortune-wheel',
  '/game/tower',
  '/game/hilo',
  '/game/baccarat',
  '/game/case-battle',
  '/game/poggi',
  /*[[END-PARSE]]*/
]
