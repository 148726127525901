import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {IStickerMessage, IStickerPack} from '@dev-fast/types';

@Component({
  selector: 'csgofast-stickers',
  templateUrl: './stickers.component.html',
  styleUrls: ['./stickers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StickersComponent {
  @Input() public stickers: IStickerPack | undefined;
  @Output() public stickerClick: EventEmitter<IStickerMessage>;

  constructor() {
    this.stickerClick = new EventEmitter<IStickerMessage>();
  }

  public getStickerPath(pack: IStickerPack, type: string = 'pack', position: number = 0): string {
    return `${pack.path}/${type}/${position}.png`;
  }

  public getActiveStickers(pack: IStickerPack): number[] {
    const array = new Array(pack.size);
    for (let i = 0; i < array.length; i++) {
      array[i] = i;
    }
    return array;
  }

  public onStickerClick(sticker: number, pack: IStickerPack): void {
    this.stickerClick.emit({ id: sticker, path: pack.path });
  }
}
