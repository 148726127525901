import { Component, Inject, OnInit, Optional } from '@angular/core';
import { Response } from "express";
import { RESPONSE } from "@nguniversal/express-engine/tokens";

@Component({
  selector: 'csgofast-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss'],
})
export class NotFoundPageComponent implements OnInit {
  response: Response;

  constructor(@Optional() @Inject(RESPONSE) response: any) {
    this.response = response;
  }

  ngOnInit() {
    if (this.response) {
      this.response.status(404);
    }
  }
}
