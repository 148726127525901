import {IMarketplaceItem, IP2pPurchaseItem} from '@dev-fast/types';

export class GetMyBids {
  public static readonly type = '[p2p_buying] Get My Bids';
}
export class ToggleSelected {
  public static readonly type = '[p2p_buying] Toggle Selected';
  constructor(public payload: IMarketplaceItem) {}
}
export class DeleteBid {
  public static readonly type = '[p2p_buying] Delete Bid';
  constructor(public id: number) {}
}
export class RemoveItem {
  public static readonly type = '[p2p_buying] Remove Item';
  constructor(public id: number, public isActive: boolean = false) {}
}
export class ConfirmBid {
  public static readonly type = '[p2p_buying] Confirm Bid';
  constructor(public id: number) {}
}
export class CreateBid {
  public static readonly type = '[p2p_buying] Create Bid';
  constructor(public id: number) {}
}
export class MergePurchasing {
  public static readonly type = '[p2p_buying] Merge Purchasing';
  constructor(public id: number, public data: Partial<IP2pPurchaseItem>) {}
}
export class UpdateShowWarnValue {
  public static readonly type = '[p2p_buying] Update Show Warn Value';
  constructor(public value: boolean) {}
}
