import {IconConfig} from "./icon.model";

export const iconsCases: IconConfig[] = [
  {
    name: 'case-placeholder',
    path: 'assets/img/mat-icons/cases/case-placeholder.svg',
  },
  {
    name: 'case-plus',
    path: 'assets/img/mat-icons/cases/case-plus.svg',
  },
  {
    name: 'thunder',
    path: 'assets/img/mat-icons/cases/thunder.svg',
  },
  {
    name: 'delete',
    path: 'assets/img/mat-icons/cases/delete.svg',
  },
  {
    name: 'gun',
    path: 'assets/img/mat-icons/cases/gun.svg',
  },
  {
    name: 'hud-line-bottom',
    path: 'assets/img/mat-icons/cases/hud-line-bottom.svg',
  },
  {
    name: 'hud-line-middle',
    path: 'assets/img/mat-icons/cases/hud-line-middle.svg',
  },
  {
    name: 'hud-line-top',
    path: 'assets/img/mat-icons/cases/hud-line-top.svg',
  },
  {
    name: 'hud-track',
    path: 'assets/img/mat-icons/cases/hud-track.svg',
  },
];
