<perfect-scrollbar [scrollIndicators]="false">
  <ng-container *ngFor="let mode of modes">
    <div
      *ngIf="itemsDictionary[mode]"
      [class]="'wrapper-group wrapper-group--' + mode"
    >
      <div class="menu-group">
        <fast-ui-game-item
          *ngFor="let game of itemsDictionary[mode]"
          [game]="game"
        >
        </fast-ui-game-item>
      </div>
    </div>
  </ng-container>
</perfect-scrollbar>
