<ng-container *ngIf="caseRevision">
    <div class="cards-list-container">
        <ng-container *ngIf="caseRevision.revision.items.length > 0 && showTitle">
            <div class="case-header_title-container" >
                <div class="title title_skin">{{ 'GOFAST.CASES.MODAL.REVISION.SKIN' | translate }}</div>
                <div class="title title_price">{{ 'GOFAST.CASES.MODAL.REVISION.PRICE' | translate }}</div>
                <ng-container *ngIf="showRange">
                  <div class="title title_range">{{ 'GOFAST.CASES.MODAL.REVISION.RANGE' | translate }}</div>
                </ng-container>
                <div class="title title_odds">{{ 'GOFAST.CASES.MODAL.REVISION.CHANCE' | translate }}</div>
              </div>
        </ng-container>
        <ng-scrollbar [autoHeightDisabled]="false">
            <div class="case-revision_items-container">
                <ng-container *ngFor="let item of caseRevision.revision.items; let index = index">
                  <fast-ui-skin-card 
                    [index]="index"
                    [weaponName]="item.inventoryItem.baseItem.shortName ? item.inventoryItem.baseItem.shortName : item.inventoryItem.baseItem.type | titlecase"
                    [skinName]="item.inventoryItem.baseItem.skin"
                    [skinWear]="item.inventoryItem.baseItem.exterior"
                    [price]="item.inventoryItem.price"
                    [chance]="item.count"
                    [avatarSrc]="item.inventoryItem.baseItem.icon"
                    [range]="showRange ? {min: item.rangeFrom, max: item.rangeTo} : undefined">
                  </fast-ui-skin-card>
                </ng-container>
            </div>
        </ng-scrollbar>
    </div>
</ng-container>
