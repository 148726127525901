import {Component} from '@angular/core';
import {Observable} from 'rxjs';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {P2pBuyingService} from '@csgofast/modules/p2p';
import {IP2pPurchaseItem} from '@dev-fast/types';

@Component({
  selector: 'csgofast-p2p-purchase',
  templateUrl: './p2p-purchase.component.html',
  styleUrls: ['./p2p-purchase.component.scss'],
})
export class P2pPurchaseComponent {
  public purchasing$: Observable<IP2pPurchaseItem[]>;
  public showWarn$: Observable<boolean>;

  constructor(private readonly p2pBuyingService: P2pBuyingService, public readonly dialog: MatDialog) {
    this.showWarn$ = this.p2pBuyingService.showWarn$;
    this.purchasing$ = this.p2pBuyingService.purchasing$;
  }

  public onChangeNotifiedStatus(value: boolean): void {
    this.p2pBuyingService.updateShowWarnValue(value);
  }

  public trackingFunction(index: number, item: IP2pPurchaseItem): number {
    return item.id;
  }
}
