import { Injectable } from '@angular/core';
import { Environments, EnvironmentService } from "@csgofast/core/environment-service";
import { ApiService } from "@dev-fast/backend-services";
import { Observable } from "rxjs";
import { Affise } from "@dev-fast/types";

@Injectable({
  providedIn: 'root'
})
export class AnalyticsApiService {

  private environment: Environments;

  constructor(
    private readonly api: ApiService,
    private readonly environmentService: EnvironmentService,
  ) {
    this.environment = this.environmentService.environments;
  }


  public affisePost(data: Affise): Observable<void> {
    return this.api.post<void>(`${this.environment.GATEWAY_URL}/api/affise/register`, data, {
      withCredentials: true,
    });
  }
}
