import {Injectable} from '@angular/core';
import {Environments, EnvironmentService} from '@csgofast/core/environment-service';
import {Socket as WrappedSocket} from "ngx-socket-io";
import {
  IDepositBatchPayload,
  IMarketplaceItem,
  IP2pDepositingItem,
  IP2pItemUpdated,
  IP2pParticipateItemUpdated,
  IP2pPurchaseItem,
  ISteamStore,
  MarketAction,
} from '@dev-fast/types';
import {Observable} from 'rxjs';
import {ApiService} from './api.service';

@Injectable({
  providedIn: 'root',
})
export class P2pApiService {
  private environment: Environments;

  constructor(
    private readonly api: ApiService,
    private readonly environmentService: EnvironmentService,
    private readonly ws: WrappedSocket
  ) {
    this.environment = this.environmentService.environments;
  }

  public getInventory() {
    return this.api.get<ISteamStore>(`${this.environment.GATEWAY_URL}/api/market/steam/inventory`, { withCredentials: true });
  }
  // TODO throttleTime(700),
  public reqAppointing(id: number, action: MarketAction): Observable<IMarketplaceItem> {
    return this.api.post(`${this.environment.GATEWAY_URL}/api/market/items/${id}/${action}`, {}, { withCredentials: true });
  }
  // TODO catchError,
  public reqDeposit(id: number, price: number): Observable<void> {
    return this.api.post(`${this.environment.GATEWAY_URL}/api/market/items/new`, { id, price }, { withCredentials: true });
  }
  // TODO throttleTime(700),
  public reqDepositSelected(
    items: IDepositBatchPayload[]
  ): Observable<{ errors: { error: string; steamInventoryId: number }[]; items: IMarketplaceItem[] }> {
    return this.api.post(`${this.environment.GATEWAY_URL}/api/market/items/new/batch`, { items }, { withCredentials: true });
  }
  // TODO throttleTime(700), catchError
  public reqSellNow(id: number): Observable<IMarketplaceItem> {
    return this.api.post(`${this.environment.GATEWAY_URL}/api/market/items/${id}/sell-now`, {}, { withCredentials: true });
  }
  // TODO throttleTime(700), catchError
  public reqDelete(itemIds: number[]): Observable<{ items: IMarketplaceItem[] }> {
    return this.api.post(`${this.environment.GATEWAY_URL}/api/market/items/my/delete`, { itemIds }, { withCredentials: true });
  }
  public reqPause(itemIds: number[]): Observable<{ items: IMarketplaceItem[] }> {
    return this.api.post(`${this.environment.GATEWAY_URL}/api/market/items/my/pause`, { itemIds }, { withCredentials: true });
  }
  public reqResume(
    itemIds: number[]
  ): Observable<{ errors: { error: string; id?: number; steamInventoryId?: number }[]; items: IMarketplaceItem[] }> {
    return this.api.post(`${this.environment.GATEWAY_URL}/api/market/items/my/resume`, { itemIds }, { withCredentials: true });
  }
  // public reqDelete(id: number): Observable<void> {
  //   return this.api.post(`${this.environment.GATEWAY_URL}/api/market/items/${id}/delete`, {}, { withCredentials: true });
  // }
  public getMyItems(): Observable<{ items: IP2pDepositingItem[] }> {
    return this.api.get(`${this.environment.GATEWAY_URL}/api/market/my/items`, { withCredentials: true });
  }
  // TODO catchError,
  public reqPurchasing(): Observable<{ items: IP2pPurchaseItem[] }> {
    return this.api.get(`${this.environment.GATEWAY_URL}/api/market/my/bids`, { withCredentials: true });
  }
  // TODO throttleTime(700), catchError
  public reqBid(id: number): Observable<void> {
    return this.api.post(`${this.environment.GATEWAY_URL}/api/market/items/${id}/bid`, {}, { withCredentials: true });
  }
  // TODO throttleTime(700), catchError
  public reqConfirm(id: number): Observable<Partial<IP2pPurchaseItem>> {
    return this.api.post(`${this.environment.GATEWAY_URL}/api/market/items/${id}/confirm`, {}, { withCredentials: true });
  }
  // TODO throttleTime(700), catchError
  public reqBidDelete(id: number): Observable<void> {
    return this.api.post(`${this.environment.GATEWAY_URL}/api/market/items/${id}/bid/delete`, {}, { withCredentials: true });
  }

  public itemUpdatedEvent(callback: (response: IP2pItemUpdated) => void): void {
    this.ws.on('market.item.updated', callback);
  }
  // public participantItemUpdatedEvent(callback: (response: number[]) => void): void {
  //   this.ws.on('market.participant.item.updated', callback);
  // }
  public participantItemUpdatedEvent(callback: (response: IP2pParticipateItemUpdated) => void): void {
    this.ws.on('market.participant.item.updated', callback);
  }
  public itemDeletedEvent(callback: (response: { id: number }) => void): void {
    this.ws.on('market.item.deleted', callback);
  }
  public getSteamStatus(): Observable<{ status: string }> {
    return this.api.get(`${this.environment.GATEWAY_URL}/api/steam/status`);
  }
  // public itemUpdatedEvent(): Observable<IP2pItemUpdated> {
  //   return this.ws.fromEvent<IP2pItemUpdated>('market.item.updated');
  // }
  // public participantItemUpdatedEvent(): Observable<IP2pParticipateItemUpdated> {
  //   return this.ws.fromEvent<IP2pParticipateItemUpdated>('market.participant.item.updated');
  // }
  // public itemDeletedEvent(): Observable<{ id }: Partial<IP2pPurchaseItem>> {
  //   return this.ws.fromEvent<{ id }: Partial<IP2pPurchaseItem>>('market.item.deleted');
  // }
}
