<ng-container
  *ngIf="{
    eventName: eventName$ | async,
    untilEventTime: untilEventTime$ | async
  } as data"
>
<div
    class="snowflakes"
    aria-hidden="true"
    *ngIf="data.eventName === 'ny'"
  >
    <div class="snowflake">❅</div>
    <div class="snowflake">❅</div>
    <div class="snowflake">❆</div>
    <div class="snowflake">❄</div>
    <div class="snowflake">❅</div>
    <div class="snowflake">❆</div>
    <div class="snowflake">❄</div>
    <div class="snowflake">❅</div>
    <div class="snowflake">❆</div>
    <div class="snowflake">❄</div>
  </div>

  <ng-container *ngIf="data.untilEventTime && !isEventResults; else active">
    <div class="event-header">
      <ng-container *ngIf="isDays(data.untilEventTime); else daysLeft">
        <fast-ui-countdown
          *ngIf="data.untilEventTime"
          [until]="data.untilEventTime"
          [showHours]="true"
          [showSeconds]="true"
          [reset]="false"
          [minutes]="0.92"
        >
        </fast-ui-countdown>
      </ng-container>
      <ng-template #daysLeft> {{ daysToGo(data.untilEventTime) }} {{ 'GOFAST.REGULAR_EVENT.DAYS' | translate }} </ng-template>

      {{ 'GOFAST.NY_EVENT.FINAL.HEADER2' | translate }}
    </div>
    <div class="event-subheader">
      {{ 'GOFAST.NY_EVENT.FINAL.SUBHEADER2' | translate }}
    </div>
  </ng-container>
  <ng-template #active>
    <div class="event-header">
      {{ 'GOFAST.NY_EVENT.HEADER' | translate }}
    </div>
  </ng-template>

  <div
    style="
      color: var(--color-primary);
      font-size: 1.375rem;
      font-weight: 600;
      padding-bottom: 1.5rem;
      display: flex;
      gap: 0.25rem;
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;
    "
    *ngIf="data.untilEventTime && isEventResults"
  >
    <span> {{ 'GOFAST.NY_EVENT.FINAL.SUBHEADER' | translate }}: </span>
    <ng-container *ngIf="isDays(data.untilEventTime); else daysLeft">
      <fast-ui-countdown
        *ngIf="data.untilEventTime"
        [until]="data.untilEventTime"
        [showHours]="true"
        [showSeconds]="true"
        [reset]="false"
        [minutes]="0.92"
      >
      </fast-ui-countdown>
    </ng-container>
    <ng-template #daysLeft>
      <span>{{ daysToGo(data.untilEventTime) }} {{ 'GOFAST.REGULAR_EVENT.DAYS' | translate }}</span>
    </ng-template>
  </div>

  <div class="events-home--ny-event">
    <div
      class="events-home--ny-event--btn presents-btn"
      [routerLink]="data.untilEventTime && isEventResults ? '/events/leaders' : '/events/ny-event'"
      routerLinkActive="active-btn"
      [class.ny-btn-decal]="data.eventName === 'ny'"
    >
      <img
        src="/assets/img/ny-event/snow/snow1.png"
        style="z-index: 1; position: absolute; top: -7px; left: -7px"
        *ngIf="data.eventName === 'ny'"
       alt=""/>
      <span>
        {{
          (data.untilEventTime && isEventResults ? 'GOFAST.NY_EVENT.FINAL.TOP_PLAYERS' : 'GOFAST.NY_EVENT.SEARCH_EVENT_BUTTON') | translate
        }}
        <!-- {{ 'GOFAST.NY_EVENT.SEARCH_EVENT_BUTTON' | translate }} -->
      </span>
    </div>
    <div
      class="events-home--ny-event--btn quest-btn"
      routerLinkActive="active-btn"
      [class.ny-btn-decal]="data.eventName === 'ny'"
      [routerLink]="data.untilEventTime && isEventResults ? '/events/shop' : '/events/ny-all-event'"
    >
      <img
        src="/assets/img/ny-event/snow/snow2.png"
        style="z-index: 1; position: absolute; top: -4px; left: 21%"
        *ngIf="data.eventName === 'ny'"
       alt=""/>

      <span style="white-space: nowrap; font-size: 14px">
        {{
          (data.untilEventTime && isEventResults ? 'GOFAST.NY_EVENT.FINAL.HAVE_TO_SPEND' : 'GOFAST.NY_EVENT.QUEST_EVENT_BUTTON') | translate
        }}
        <!-- {{ 'GOFAST.NY_EVENT.QUEST_EVENT_BUTTON' | translate }} -->
      </span>
      <div
        class="keys-container m-hide"
        *ngIf="keys$ | async as keys"
      >
        <div class="keys-container--node">
          <div class="keys-container--node-icon">
            <div
              class="currency currency--red-cane"
              *ngIf="data.eventName === 'ny'"
            ></div>
            <div
              class="currency currency--red-pop"
              *ngIf="data.eventName === 'ny_china'"
            ></div>
          </div>
          <div class="keys-container--node-count">{{ keys.orangeKeys }}</div>
        </div>
        <div class="keys-container--node">
          <div class="keys-container--node-icon">
            <div
              class="currency currency--green-cane"
              *ngIf="data.eventName === 'ny'"
            ></div>
            <div
              class="currency currency--green-pop"
              *ngIf="data.eventName === 'ny_china'"
            ></div>
          </div>
          <div class="keys-container--node-count">{{ keys.greenKeys }}</div>
        </div>
        <div class="keys-container--node">
          <div class="keys-container--node-icon">
            <div
              class="currency currency--blue-cane"
              *ngIf="data.eventName === 'ny'"
            ></div>
            <div
              class="currency currency--blue-pop"
              *ngIf="data.eventName === 'ny_china'"
            ></div>
          </div>
          <div class="keys-container--node-count">{{ keys.blueKeys }}</div>
        </div>
      </div>
    </div>
    <div
      class="keys-container m-show"
      *ngIf="keys$ | async as keys"
    >
      <div class="keys-container--node">
        <div class="keys-container--node-icon">
          <div
            class="currency currency--red-cane"
            *ngIf="data.eventName === 'ny'"
          ></div>
          <div
            class="currency currency--red-pop"
            *ngIf="data.eventName === 'ny_china'"
          ></div>
        </div>
        <div class="keys-container--node-count">{{ keys.orangeKeys }}</div>
      </div>
      <div class="keys-container--node">
        <div class="keys-container--node-icon">
          <div
            class="currency currency--green-cane"
            *ngIf="data.eventName === 'ny'"
          ></div>
          <div
            class="currency currency--green-pop"
            *ngIf="data.eventName === 'ny_china'"
          ></div>
        </div>
        <div class="keys-container--node-count">{{ keys.greenKeys }}</div>
      </div>
      <div class="keys-container--node">
        <div class="keys-container--node-icon">
          <div
            class="currency currency--blue-cane"
            *ngIf="data.eventName === 'ny'"
          ></div>
          <div
            class="currency currency--blue-pop"
            *ngIf="data.eventName === 'ny_china'"
          ></div>
        </div>
        <div class="keys-container--node-count">{{ keys.blueKeys }}</div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="data.eventName === 'ny_china'">
    <div class="left-bg common-event-bg"></div>
    <div class="right-bg common-event-bg"></div>
  </ng-container>

  <div>
    <router-outlet></router-outlet>
  </div>
</ng-container>
