import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {Subject} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {takeUntil} from 'rxjs/operators';
import {IChatAccess, IGame, IInputMessage, IStickerMessage, IStickerPack, IUserDetailed} from '@dev-fast/types';

@Component({
  selector: 'csgofast-message-input',
  templateUrl: './message-input.component.html',
  styleUrls: ['./message-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageInputComponent implements OnDestroy, OnInit {
  @Output() public sizeChanges: EventEmitter<void>;
  @Output() public addMessage: EventEmitter<IInputMessage>;
  @Output() public setActivePack: EventEmitter<string>;
  @ViewChild('stickersWrapper') public stickersWrapper: ElementRef | undefined;

  @Input() public currentGame: IGame | undefined;
  @Input() public activeStickerPack: string | undefined;
  @Input() public user: IUserDetailed | undefined;
  @Input() public stickers: IStickerPack[];
  @Input() public chatAccess: IChatAccess | undefined;
  @Input() public banTimer: string | undefined;

  public messageForm: FormGroup;
  public stickersIsOpen: boolean;
  // public currentSection: string;

  private offsetHeight: number;
  private readonly destroyed$: Subject<void>;

  constructor(private fb: FormBuilder, private elementRef: ElementRef, private cdr: ChangeDetectorRef) {
    this.offsetHeight = 0;
    this.stickersIsOpen = false;
    this.stickers = [];
    this.destroyed$ = new Subject();

    this.messageForm = this.fb.group({
      message: ['', [Validators.minLength(2), Validators.required, Validators.maxLength(139)]],
    });

    this.sizeChanges = new EventEmitter<void>();
    this.addMessage = new EventEmitter(false);
    this.setActivePack = new EventEmitter(false);

    // this.banTimer$ = interval(1000).pipe(
    //   startWith(''),
    //   map(() => {
    //     const banDuration: any = moment.duration(new Date(access.bannedUntil).getTime() - Date.now());
    //     return banDuration.format('HH:mm:ss');
    //   })
    // );
  }

  public addSticker(sticker: IStickerMessage): void {
    const game = this.currentGame ? this.currentGame.key : 'common';
    this.addMessage.emit({ value: sticker, game });
  }

  public changeActiveTab(pack: string): void {
    this.setActivePack.emit(pack);
  }

  public changeActiveTabWithRelocate(pack: string): void {
    this.changeActiveTab(pack);
    if (this.stickersWrapper) {
      this.stickersWrapper.nativeElement.querySelector(`[data-id="${pack}"]`).scrollIntoView();
    }
  }

  public toggleStickers(): void {
    this.stickersIsOpen = !this.stickersIsOpen;
    this.sizeChanges.emit();
  }

  public onAddMessage(e: Event): void {
    e.preventDefault();
    const { message } = this.messageForm.value;
    if (!message.trim() || !this.messageForm.valid) {
      return;
    }

    const game = this.currentGame ? this.currentGame.key : 'common';
    this.addMessage.emit({ value: message, game });
    this.messageForm.patchValue({ message: '' });
  }

  public getStickerPath(pack: IStickerPack, type: string = 'pack', position: number = 0): string {
    return `${pack.path}/${type}/${position}.png`;
  }

  public getTemplateType(user: IUserDetailed): string {
    if (!user) return 'login';
    if (
      this.chatAccess &&
      !this.chatAccess.hasAccess &&
      this.chatAccess.bannedUntil &&
      new Date(this.chatAccess.bannedUntil).getTime() > Date.now()
    )
      return 'banned';
    return 'access';
  }

  // public onScroll($event): void {
  //   let currentSection = null;
  //   const { scrollTop } = $event.target;
  //   const stickers = <HTMLElement[]>Array.from($event.target.querySelectorAll('app-stickers'));
  //   for (let i = 0; i < stickers.length; i++) {
  //     const element = stickers[i];
  //     if (scrollTop >= element.offsetTop && scrollTop <= element.offsetTop + element.clientHeight) {
  //       currentSection = element.dataset.id;
  //     }
  //   }
  //   if (currentSection !== this.currentSection) {
  //     this.currentSection = currentSection;
  //     this.changeActiveTab(currentSection);
  //   }
  // }

  public ngOnInit(): void {
    this.subscribeEmitters();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private validateSize(): void {
    const { offsetHeight } = this.elementRef.nativeElement;
    if (this.offsetHeight !== offsetHeight) {
      this.sizeChanges.emit();
      this.offsetHeight = offsetHeight;
    }
  }

  private subscribeEmitters(): void {
    const control = this.messageForm.get('message');
    if (control) control.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => this.validateSize());
  }
}
