export enum SortingOrder {
  Asc = 1,
  Desc = -1,
}

/**
 * Информация о сортировки
 */
export interface Sorting {
  field: string;
  order: SortingOrder;
}

/**
 * Метод для специальной сортировки
 */
export type SpecialSort<TSource> = (items: TSource[], sorting: Sorting) => TSource[];

/**
 * Информация о специальных сортировках
 */
export interface SpecialSortings<TSource> {
  [fieldName: string]: SpecialSort<TSource>;
}
