import {Injectable} from '@angular/core';
import {LocalStorageService} from '@csgofast/core/local-storage-service';
import {RefreshCurrentUser} from '@csgofast/core/state/user-store';

import {CurrencyService} from '@dev-fast/backend-services';
import {countriesDic, currencyDic, ICurrency, ICurrencyRate} from '@dev-fast/types';
import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {CURRENCY_INITIAL_STATE, CurrencyStateModel} from './currency-state.model';
import {ChangeCurrency, GetCurrencySettings, SelectCurrencyByCountry} from './currency.actions';

@State<CurrencyStateModel>({
  name: 'currency',
  defaults: CURRENCY_INITIAL_STATE,
})
@Injectable()
export class CurrencyState {
  constructor(private readonly apiService: CurrencyService, private readonly store: Store, private readonly storage: LocalStorageService) {}

  @Selector()
  public static currency({ currency }: CurrencyStateModel): ICurrencyRate | null {
    return currency;
  }
  @Selector()
  public static currencyList({ currencyList }: CurrencyStateModel): ICurrencyRate[] {
    return currencyList;
  }

  @Action(RefreshCurrentUser)
  public refreshUser({ dispatch }: StateContext<CurrencyStateModel>, { payload }: RefreshCurrentUser): void {
    // if (payload.currency && payload.currency !== null) {
    //   dispatch(new ChangeCurrency(payload.currency));
    // } else if (payload.country) {
    //   dispatch(new SelectCurrencyByCountry(payload.country));
    // }
  }

  @Action(GetCurrencySettings)
  public getSettings({ patchState }: StateContext<CurrencyStateModel>): Observable<ICurrency[]> {
    return this.apiService.getCurrencySettings().pipe(
      tap((response: ICurrency[]) => {
        const currencies: ICurrencyRate[] = response.map((value) => ({
          key: value.key,
          rate: value.rate,
          prefix:
            value.prefix || value.postfix || (value.key === 'fastcoins' && ' ◎ ') || (value.key === 'fastcoins_refill' && ' ◎ ') || '',
        }));

        if (currencies.length > 0) {
          const currency = currencies.find((item: ICurrencyRate) => item.key === 'fastcoins');
          patchState({
            currency: currency || null,
            currencyList: currencies,
          });
          this.storage.set('currency', currency ? currency.key : currencyDic[0].key);
        }
      })
    );
  }

  @Action(ChangeCurrency)
  public changeCurrency({ patchState, getState }: StateContext<CurrencyStateModel>, { key }: ChangeCurrency) {
    const { currency, currencyList } = getState();
    if (currency && currency.key === key) return;

    if (key !== undefined) {
      const nextCurrency = currencyList.find((rate) => rate.key === key);
      patchState({
        currency: nextCurrency || null,
      });
      this.storage.set('currency', nextCurrency?.key);
    } else {
      const newCurrency = currency && currency.key === currencyList[0].key ? currencyList[1] : currencyList[0];
      patchState({
        currency: newCurrency || null,
      });
      this.storage.set('currency', newCurrency.key);
    }
  }

  @Action(SelectCurrencyByCountry)
  public selectCurrencyByCountry({ dispatch, getState }: StateContext<CurrencyStateModel>, { country }: SelectCurrencyByCountry) {
    const { currency, currencyList } = getState();
    let currencyByCountry: ICurrencyRate | undefined;
    if (countriesDic.china.includes(country)) {
      currencyByCountry = currencyList.find((c) => c.key === 'CNY');
    }
    if (countriesDic.europe.includes(country)) {
      currencyByCountry = currencyList.find((c) => c.key === 'EUR');
    }
    if (countriesDic.poland.includes(country)) {
      currencyByCountry = currencyList.find((c) => c.key === 'PLN');
    }
    if (countriesDic.usa.includes(country)) {
      currencyByCountry = currencyList.find((c) => c.key === 'USD');
    }
    if (countriesDic.russia.includes(country)) {
      currencyByCountry = currencyList.find((c) => c.key === 'RUB');
    }
    currencyByCountry = currencyByCountry || currencyList[0];
    if (currency && currency.key === currencyByCountry.key) return;
    // dispatch(new ChangeCurrency(currencyByCountry.key));
  }
}
