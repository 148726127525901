<div class="possible-card-container">
  <div class="possible-card-wrapper">
    <div class="possible-card-header-container">
      <div class="loading-gradient"></div>
    </div>
    <div class="possible-card-avatar-container">
      <div class="card-avatar loading-gradient"></div>
    </div>
    <div class="possible-card-description-container">
      <div class="card-description_item-name"><div class="loading-gradient"></div></div>
      <div class="card-description_item-skin"><div class="loading-gradient"></div></div>
      <div class="card-description_item-price"><div class="loading-gradient"></div></div>
    </div>
  </div>
</div>
