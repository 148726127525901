interface IAnimKeyframes {
  value: number | string;
  duration?: number;
  delay?: number;
  easing?: string;
}
interface IAnimConfig {
  translateY: IAnimKeyframes[];
  translateX: IAnimKeyframes[];
}
export const casesAnimationConstructor = (
  trackIndex: number,
  fastAnimation: boolean,
  direction: 'horizontal' | 'vertical' | 'mobile',
  complete: boolean,
  offset?: number
): IAnimConfig => {
  const duration = fastAnimation ? 1000 : 4000;
  const delay = (trackIndex + 1) * 75;
  let randomOffset;
  let cardOffset;
  let value;
  let symbol;
  let dropValue;
  if (direction === 'horizontal') {
    dropValue = 'calc(50vw - 49rem)';
    randomOffset = 7.1 - Math.random() * 14;
    cardOffset = offset ? offset : 399;
    value = '50vw';
    symbol = '-';
  } else if (direction === 'vertical') {
    dropValue = '54rem';
    randomOffset = 9.1 - Math.random() * 18;
    cardOffset = offset ? offset : 504;
    value = '';
    symbol = '';
  } else {
    dropValue = 'calc(50vw - 63rem)';
    randomOffset = 9.1 - Math.random() * 18;
    cardOffset = offset ? offset : 513;
    value = '50vw';
    symbol = '-';
  }

  const config: IAnimConfig = {
    translateY: [],
    translateX: [],
  };
  const droppingPosition = {
    value: dropValue,
    duration: 0,
  };
  const nullPosition = {
    value: 0,
    duration: 0,
  };
  const rafflePosition = {
    value: `calc(${value} ${symbol} ${cardOffset + randomOffset}rem)`,
    duration: duration,
    delay: delay,
    easing: 'cubicBezier(0.2, 0, 0.15, 1)',
  };
  const victoryPosition = {
    value: `calc(${value} ${symbol} ${cardOffset}rem)`,
    duration: 450,
    easing: 'cubicBezier(0.85, 0.28, 0.67, 1.25)',
  };
  const outFromAnimation = {
    value: `calc(${value} ${symbol} ${cardOffset}rem)`,
    delay: delay,
  };
  if (complete) {
    if (direction === 'horizontal') {
      config.translateY.push(nullPosition);
      config.translateX.push(droppingPosition, rafflePosition, victoryPosition, outFromAnimation);
    }
    if (direction === 'vertical') {
      config.translateX.push(nullPosition);
      config.translateY.push(droppingPosition, rafflePosition, victoryPosition, outFromAnimation);
    }
    if (direction === 'mobile') {
      config.translateY.push(nullPosition);
      config.translateX.push(droppingPosition, rafflePosition, victoryPosition, outFromAnimation);
    }
    return config;
  } else {
    if (direction === 'horizontal') {
      config.translateY.push(nullPosition);
      config.translateX.push(droppingPosition);
    }
    if (direction === 'vertical') {
      config.translateX.push(nullPosition);
      config.translateY.push(droppingPosition);
    }
    if (direction === 'mobile') {
      config.translateY.push(nullPosition);
      config.translateX.push(droppingPosition);
    }
    return config;
  }
};
export const caseBattleAnimationConstructor = (playerIndex: number, isMobile: boolean, lineLength: number, continueAnimation: boolean): IAnimConfig => {
  const duration = 1300;
  const delay = (playerIndex + 1) * 75;
  const oneItemLenght = 100 / lineLength; // длина одного айтема
  const randomOffset = oneItemLenght / 2 - Math.random() * oneItemLenght;
  const defaultPosition = `-${oneItemLenght * (isMobile ? 2 : 1)}%`; // начальная позиция
  const finishPosition = 100 - oneItemLenght * (isMobile ? 3 : 4); //финишная позиция - 100% - 2 длины айтема
  const randomPosition = finishPosition + randomOffset; //финишная позиция - 100% - 2 длины айтема

  const config: IAnimConfig = {
    translateY: [],
    translateX: [],
  };
  const nullPosition = {
    value: 0,
    duration: 0,
  };
  const droppingPosition = {
    value: defaultPosition,
    duration: 0,
  };
  const rafflePosition = {
    value: `-${randomPosition}%`,
    duration: duration,
    // delay: delay,
    easing: 'easeInOutExpo',
  };
  const victoryPosition = {
    value: `-${finishPosition}%`,
    duration: 450,
    easing: 'cubicBezier(0.20, 0.35, 0.67, 1.25)',
  };
  const breakPosition = {
    value: `-${finishPosition}%`,
    duration: 0,
  };
  if (isMobile) {
    config.translateX.push(nullPosition);
    if (!continueAnimation) {
      config.translateY.push(droppingPosition, rafflePosition, victoryPosition);
    } else {
      config.translateY.push(breakPosition);
    }
  }
  if (!isMobile) {
    config.translateY.push(nullPosition);
    if (!continueAnimation) {
      config.translateX.push(droppingPosition, rafflePosition, victoryPosition);
    } else {
      config.translateX.push(breakPosition);
    }  
  }
  return config;
};
// export const caseBattleAnimationConstructor = (playerIndex: number, isMobile: boolean, lineLength: number, progress?: number): IAnimConfig => {
//   const duration = 1300;
//   const delay = (playerIndex + 1) * 75;
//   const oneItemLenght = 100 / lineLength // длина одного айтема
//   const randomOffset = (((oneItemLenght / 2) + 1) - Math.random() * oneItemLenght);
//   const defaultValue = `-${(oneItemLenght * (isMobile ? 2 : 1))}%`; // начальная позиция 
//   const finishValue = 100 - (oneItemLenght * (isMobile ? 3 : 4)) //финишная позиция - 100% - 2 длины айтема
//   const randomValue = finishValue + randomOffset //финишная позиция - 100% - 2 длины айтема

//   const config: IAnimConfig = {
//     translateY: [],
//     translateX: [],
//   };
//   const nullPosition = {
//     value: 0,
//     duration: 0,
//   };
//   const droppingPosition = {
//     value: defaultValue,
//     duration: 0,
//   };
//   const rafflePosition = {
//     value: `-${randomValue}%`,
//     duration: duration,
//     delay: delay,
//     easing: 'easeInOutExpo',
//   };

//   if (isMobile) {
//     config.translateX.push(nullPosition);
//     if (progress) {
//       const continuePosition = {
//         value: `-${randomValue}%`,
//         duration: (duration / 100) * Math.ceil(progress),
//       };
//       const victoryPosition = {
//         value: `-${finishValue}%`,
//         duration: Math.ceil(progress) < 50 ? 450 : 0,
//         easing: Math.ceil(progress) < 50 ? 'cubicBezier(0.85, 0.28, 0.67, 1.25)' : 'easeOutExpo',
//       };
//       config.translateY.push(continuePosition, victoryPosition);
//     } else {
//       const victoryPosition = {
//         value: `-${finishValue}%`,
//         duration: 450,
//         easing: 'cubicBezier(0.85, 0.28, 0.67, 1.25)',
//       };
//       config.translateY.push(droppingPosition, rafflePosition, victoryPosition);
//     }
//   }
//   if (!isMobile) {
//     config.translateY.push(nullPosition);
//     if (progress) {
//       const continuePosition = {
//         value: `-${randomValue}%`,
//         duration: (duration / 100) * Math.ceil(progress),
//       };
//       const victoryPosition = {
//         value: `-${finishValue}%`,
//         duration: Math.ceil(progress) < 50 ? 450 : 0,
//         easing: Math.ceil(progress) < 50 ? 'cubicBezier(0.85, 0.28, 0.67, 1.25)' : 'easeOutExpo',
//       };
//       config.translateX.push(continuePosition, victoryPosition);
//     } else {
//       const victoryPosition = {
//         value: `-${finishValue}%`,
//         duration: 450,
//         easing: 'cubicBezier(0.85, 0.28, 0.67, 1.25)',
//       };
//       config.translateX.push(droppingPosition, rafflePosition, victoryPosition);
//     }  
//   }
//   return config;
// };

