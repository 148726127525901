import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { TransferState } from "@angular/platform-browser";
import { ILocales, LOCALES_DIC } from "@dev-fast/types";
import { DEFAULT_LANGS, MAIN_LANG } from "./language.const";
import { Dispatch } from "@ngxs-labs/dispatch-decorator";
import { SetLanguage } from "./language.action";
import { FrameMessageTypes, IFrameMessageService } from "@csgofast/core/iframe";
import { CookieService } from "@csgofast/core/cookie-service";

@Injectable({ providedIn: 'root' })
export class LanguageService {
  // Статика, а не store, для того, чтобы можно было использовать это свойство еще до того как Ангуляр проинициализирует всё и создаст экземпляр сервиса
  static initLangs: { [route: string]: ILocales } | null;

  /**
   * Переданный адрес преобразуется в тот же самый, но без языкового кода (/pl/aaa -> /aaa; /aaa -> /aaa)
   */
  static getBaseUrl(url: string): string {
    url = url.split('?')[0];
    return LanguageService.isSecondaryLanguageUrl(url) ? url.substring(3) : url
  }

  /**
   * Проверяет содержит ли ссылка второстепенный язык (/ru/smth => true, /smth => false)
   */
  static isSecondaryLanguageUrl(url: string): boolean {
    return Object.keys(LanguageService.getRouteLanguages()).includes(url.split('/')[1].substring(0, 2));
  }

  /**
   * Возвращает список языков, которые учавствуют в маршрутизации
   * - По умолчанию они берутся от пререндеров
   * - Если пререндера нет или он не отдал язык (например проект запущен без SSR), то возвращается дефолтный список из констант
   */
  static getRouteLanguages(): { [route: string]: ILocales } {
    // После первого же возврата сохраним результат и будем возвращать его впредь
    // NOTE: В этом месте языки для маршрутов сохраняются не через store из-за того, что использовать их нужно еще до того как проиницилизируется весь ангуляр, компоненты и сервисы (в том числе и store сервис)
    if(!LanguageService.initLangs) {
      let locales: ILocales[] = [];
      let langs: { [route: string]: ILocales } = DEFAULT_LANGS;
      // Если это браузер, то SSR передал нам данные о языках. Достаем их и строим на их основе маршруты
      if (typeof window !== 'undefined') {
        const element = document.getElementById('serverApp-state')?.innerHTML;
        if (element) {
          locales = JSON.parse(element.replace(/&q;/g, '"')).locales;
        }
      } else {
        locales = (global as any).locales;
      }

      if (locales && locales.length > 0) {
        langs = locales.reduce((routes: { [route: string]: ILocales }, item: ILocales) => {
          const route = item.path.substring(0, 2);
          if(!routes[route]) { routes[route] = item; }
          return routes
        }, {});
      }

      LanguageService.initLangs = langs
    }
    return LanguageService.initLangs;
  }

  /**
   * Проверяет по ключу языка разрешен ли он (en, pl, pt etc.)
   */
  static isAcceptedLanguage(lang: string): boolean {
    return Object.keys(LanguageService.getRouteLanguages()).includes(lang);
  }

  /**
   * Проверяет по коду языка разрешен ли он (en_US, pl_PL, pt_PT etc.)
   */
  static isAcceptedLanguageCode(lang: string): boolean {
    return !!Object.values(LanguageService.getRouteLanguages()).find(item => item.path === lang);
  }

  /**
   * При получении кода возвращает ключ языка (en_US => en)
   */
  static getLangByCode(code: string): string {
    const lang = Object.entries(LanguageService.getRouteLanguages()).find(([key, value]: any[]) => code === value.path)?.[0];
    return lang || MAIN_LANG;
  }

  constructor (
    private http: HttpClient,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private readonly cookieService: CookieService,
    private readonly frameMessageService: IFrameMessageService,
    private transferState: TransferState,
    @Inject('isServer') private isServer: boolean,
  ) {
  }

  // ------------ ACTIONS ------------

  /**
   * Заменяет текущий адрес на сайте на аналогичный, но подходящий по переданному языку (/en/games => /pl/games)
   */
  updateUrlState(locale: ILocales): void {
    const langs = LanguageService.getRouteLanguages();
    const [url, params] = this.router.url.split('?');
    const currentLangPath = url.split('/')[1];
    const stateLang = locale.path === langs[MAIN_LANG].path ? '' : LanguageService.getLangByCode(locale.path);
    const stateRoute = Object.keys(langs).filter(lang => lang !== MAIN_LANG).includes(currentLangPath)
      // Смотрим, есть ли в текущем пути языки или нет. Если нет, вставляем актуальный, если есть, заменяем на актуальный
      ? url.replace(stateLang ? currentLangPath : '/'+currentLangPath, stateLang)
      : url !== '/'
        ? (stateLang) + url
        : '/' + (stateLang);
    this.location.replaceState(`${stateRoute}${params? ('?'+params) : ''}`);
  }

  /**
   * Передает выбранный язык в проект на BB
   */
  sendLocaleToBB(locale: ILocales): void {
    //TODO for bb if loaded ng but not loaded bb
    this.cookieService.setCookie('lang', LOCALES_DIC[locale.path as keyof typeof LOCALES_DIC], {
      expires: 60 * 60 * 24 * 30,
      path: '/',
    });
    this.frameMessageService.sendMessage({ type: FrameMessageTypes.MESSAGE_TO_BB, eventName: 'changeLang', payload: locale });
  }

  @Dispatch() public setLanguage = (locale: ILocales) => new SetLanguage(locale);
}
