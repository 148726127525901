export enum GameTypes {
  CLASSIC = 1,
  FAST = 2,
  DOUBLE = 3,
  DUEL = 4,
  SLOT = 5,
  CSGO_BOTS = 6,
  CRASH = 7,
  CRAFT = 9,
  CASES = 10,
  X50 = 13,
  TOWER = 14,
  WHEEL = 15,
  HILO = 16,
  POGGI = 19,
  BACCARAT = 18,
  SHOP = 33,
  INVENTORY = 34,
}

export enum GameTypesNames {
  CLASSIC = 'CLASSIC',
  FAST = 'FAST',
  DOUBLE = 'DOUBLE',
  DUEL = 'DUEL',
  SLOT = 'SLOT',
  CSGO_BOTS = 'CSGO BOTS',
  CRASH = 'CRASH',
  CRAFT = 'CRAFT',
  CASES = 'CASES',
  CASE_BATTLE = 'CASE_BATTLE',
  X50 = 'X50',
  TOWER = 'TOWER',
  WHEEL = 'WHEEL',
  HILO = 'HILO',
  POGGI = 'POGGI',
  BACCARAT = 'BACCARAT',
  SHOP = 'SHOP',
  INVENTORY = 'INVENTORY',
}
