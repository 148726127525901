import {Injectable} from '@angular/core';
import {IMotivatorDto} from "@dev-fast/types";
import {ApiService} from '../api.service';
import {Environments, EnvironmentService} from '@csgofast/core/environment-service';
import {Socket as WrappedSocket} from "ngx-socket-io";

@Injectable({
  providedIn: 'root',
})
export class MotivatorBackendService {
  private environment: Environments;

  constructor(
    private readonly api: ApiService,
    private readonly environmentService: EnvironmentService,
    private readonly ws: WrappedSocket
  ) {
    this.environment = this.environmentService.environments;
  }


  public onLastWon(callback: (payload: IMotivatorDto<any>) => void): void {
    this.ws.on('motivator.last.won', callback);
  }
}
