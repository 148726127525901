export enum GameIds {
  CSGO = 730,
  DOTA = 570,
  // VGO = 2,
}

export enum GameDisplayNames {
  CSGO = 'cs:go',
  DOTA = 'dota 2',
  VGO = 'vgo',
}
