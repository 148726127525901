import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { P2pSellSetupItemComponent } from './p2p-sell-setup-item.component';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { ReactiveFormsModule } from '@angular/forms';
import { P2pPreviewSellModifierModalComponent } from './modals/p2p-preview-sell-modifier-modal/p2p-preview-sell-modifier-modal.component';
import { P2pSellModifierModalComponent } from './modals/p2p-sell-modifier-modal/p2p-sell-modifier-modal.component';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatIconModule } from '@angular/material/icon';
import { AppCurrencyModule } from '@csgofast/shared/pipes';
import { SkinItemImageModule } from '@csgofast/shared/directives';
import { P2pSettingsModule } from '../../p2p-settings/p2p-settings.module';

@NgModule({
  declarations: [P2pSellSetupItemComponent, P2pPreviewSellModifierModalComponent, P2pSellModifierModalComponent],
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule,
    AppCurrencyModule,
    SkinItemImageModule,
    P2pSettingsModule,
    MatSliderModule,
    ReactiveFormsModule,
    MatCheckboxModule,
  ],
  exports: [P2pSellSetupItemComponent],
})
export class P2pSellSetupItemModule {}
