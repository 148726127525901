<div
  class="icomoon skin-item-popup-close"
  (click)="close()"
>
  <span class="icon-close"></span>
</div>
<div
  class="skin-item-container"
  [ngStyle]="{ 'border-top-color': skin.color }"
>
  <div class="skin-item-content">
    <div class="skin-item-delivery">{{ 'SKIN_ITEM_TOOLTIP.PURCHASE' | translate }}.</div>
    <div class="skin-item">
      <img
        class="skin-item-image"
        skinItemImage
        [src]="skin.icon"
        [alt]="skin.name"
      />
      <div class="skin-item-name">{{ skin.name }}</div>
    </div>
    <button class="button-purchase btn btn-outline-primary btn-sm">
      {{ 'SKIN_ITEM_TOOLTIP.PURCHASE_FOR' | translate }} {{ skin.price | appCurrency }}
    </button>
  </div>
</div>
