export const colorDictionary: Record<string, string>  = {
    '#ACBFD4': '#213137',   //дефолтный
    '#B0C3D9': '#273546',   //дефолтный
    '#5E98D9': '#1D2E41',   //дефолтный
    '#5E97D8': '#1A2C40',   //светло-синий
    '#4662E8': '#222345',   //синий
    '#4B69FF': '#1E2D4E',   //синий
    '#8546F9': '#2E1F41',   //фиолетовый
    '#8847FF': '#2F2241',   //фиолетовый
    '#D32CE6': '#3F1F41',   //розово-фиолетовый
    '#D22CE5': '#351B3A',   //розовый
    '#FA8072': '#2F222B',   //бледно-красный
    '#EB4B4B': '#513333',   //ярко-красный
}