import {Observable} from 'rxjs';
import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {INotification, NotificationStatus} from '@dev-fast/types';
import {NOTIFICATIONS_ENGINE, NotificationsEngine} from './symbols';

@Component({
  selector: 'csgofast-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsComponent {
  // @ViewChild('scrollable', { static: false, read: ElementRef }) scrollbar: ElementRef | undefined;
  // public contentWrapper: HTMLElement | null;

  public notificationList$: Observable<INotification[]>;
  public notificationStatus=NotificationStatus;

  constructor(@Inject(NOTIFICATIONS_ENGINE) private notiEngine: NotificationsEngine) {
    this.notificationList$ = this.notiEngine.notifications$;
    // this.contentWrapper = null;
  }
  // public autoHeight() {
  //   if (!this.contentWrapper) {
  //     this.contentWrapper = document.querySelector('.notifications__list');
  //   }

  //   if (this.scrollbar && this.contentWrapper) {
  //     this.scrollbar.nativeElement.style.height = this.contentWrapper.clientHeight + 'px';
  //   }
  // }
  public trackingFunction(index: number, item: INotification): number {
    return item.id;
  }
  public watchAll(): void {
    this.notiEngine.watchAllNotifications();
  }
  public close(): void {
    this.notiEngine.closeWidget();
  }
  public deleteSelectedNotification(id: number): void {
    this.notiEngine.removeNotification(id);
  }
}
