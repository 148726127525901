import {ChangeDetectorRef, OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {CurrencyPipe} from '@angular/common';
import {Subject} from 'rxjs';

import {filter, takeUntil} from 'rxjs/operators';
import {ICurrencyRate} from '@dev-fast/types';
import {CurrencyService} from '@csgofast/core/currency-service';

@Pipe({
  name: 'appCurrency',
  pure: false,
})
export class AppCurrencyPipe implements PipeTransform, OnDestroy {
  public currency: ICurrencyRate | undefined;
  private readonly destroyed$: Subject<void>;
  constructor(private currencyPipe: CurrencyPipe, private currencyService: CurrencyService, private cdr: ChangeDetectorRef) {
    this.destroyed$ = new Subject<void>();
    this.currencyService.currency$
      .pipe(
        takeUntil(this.destroyed$),
        filter((val): val is ICurrencyRate => !!val)
      )
      .subscribe((currency: ICurrencyRate) => {
        this.currency = currency;
        this.cdr.markForCheck();
      });
  }

  public transform(value: any, args?: { currencyCode?: string, display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean, digitsInfo?: string, locale?: string, hidePrefix: boolean }): string|null {
    if (value !== null) {
      value = this.currencyService.convert(value);
      const result = this.currencyPipe.transform(value, '', (!args?.hidePrefix && this.currency && this.currency.prefix) || '', args?.digitsInfo);
      return result && result.endsWith('.00') && result.length >= 5 ? result.slice(0, -3) : result;
    }
    return this.currencyPipe.transform(0, args?.currencyCode, args?.display, args?.digitsInfo, args?.locale);
  }

  public ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
