import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {IGame} from '@dev-fast/types';

@Component({
  selector: 'fast-ui-game-item',
  templateUrl: './game-item.component.html',
  styleUrls: ['./game-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GameItemComponent {
  @Input()
  public game!: IGame;

  public getGameSrc(game: IGame, type: 'substrate' | 'layer'): string {
    return `//d2lomvz2jrw9ac.cloudfront.net/common/games-logo/${game.key}/${type}.png`;
  }
}
