import {IInventoryRequestParams} from '@dev-fast/types';

export class SellItems {
  public static readonly type = '[Inventory] Sell items';
  constructor(public ids: number[]) {}
}
export class SellAllItems {
  public static readonly type = '[Inventory] Sell all items';
}
export class SellAllItemsSuccess {
  public static readonly type = '[Inventory] Sell all items success';
  constructor(public itemsIds: number[]){}
}
export class RemoveInventoryItems {
  public static readonly type = '[Inventory] Remove Inventory Items';
  constructor(public itemsIds: number[]){}
}
export class Purchase {
  public static readonly type = '[Inventory] Purchase';
  constructor(public ids: number[], public userInventoryIds: number[]) {}
}
export class Trade {
  public static readonly type = '[Inventory] Trade';
}
export class GetInventoryItems {
  public static readonly type = '[Inventory] Get inventory items';
}
export class GetInventoryInfo {
  public static readonly type = '[Inventory] Get inventory info';
}
export class GetShopItems {
  public static readonly type = '[Inventory] Get shop items';
}
export class ChangeParamsShop {
  public static readonly type = '[Inventory] Change Params Shop';
  constructor(public params: IInventoryRequestParams) {}
}
export class ChangeParamsInventory {
  public static readonly type = '[Inventory] Change Params Inventory';
  constructor(public params: IInventoryRequestParams) {}
}
export class ClickOnShopItem {
  public static readonly type = '[Inventory] Click On Shop Item';
  constructor(public id: number) {}
}
export class ClickOnInventoryItem {
  public static readonly type = '[Inventory] Click On Inventory Item';
  constructor(public id: number) {}
}
export class ToggleAllInventoryItems {
  public static readonly type = '[Inventory] Toggle All Inventory Items';
}
export class ToggleGameStatus {
  public static readonly type = '[Inventory] Toggle game progress status';
  constructor(public gameInProgress: boolean) {}
}
export class UnselectInventoryItemById {
  public static readonly type = '[Inventory] Unselect Inventory Item By Id';
  constructor(public ids: number[]) {}
}
export class FreezeParticipatedItems {
  public static readonly type = '[Inventory] Freeze Participated Items';
  constructor(public ids: number[]) {}
}
