import {InjectionToken} from '@angular/core';

export const FILTER_TOKEN = new InjectionToken<string>('FILTER_TOKEN');

export const CONFIG = {
  sortBy: new Map<string, string>([
    ['DESC_PRICE', '-price'],
    ['ASC_PRICE', 'price'],
    ['ASC_NAME', 'name'],
    ['DESC_NAME', '-name'],
    ['UNDERPRICE', 'overprice'],
    ['OVERPRICE', '-overprice'],
  ]),
  inventorySort: new Map<string, string>([
    ['DESC_PRICE', '-price'],
    ['ASC_PRICE', 'price'],
    ['ASC_NAME', 'name'],
    ['DESC_NAME', '-name'],
  ]),
  type: new Map<string, string>([
    ['ALL', ''],
    ['COLLECTIBLE', 'collectible'],
    ['CONTAINER', 'container'],
    ['GIFT', 'gift'],
    ['GLOVES', 'gloves'],
    ['GRAFFITI', 'graffiti'],
    ['KEY', 'key'],
    ['MUSIC_KIT', 'music kit'],
    ['PASS', 'pass'],
    ['WEAPON', 'weapon'],
  ]),
  price: new Map<string, number[]>([
    ['ALL', []],
    ['0-5', [0, 500]],
    ['5-25', [500, 2500]],
    ['25-100', [2500, 10000]],
    ['100-500', [10000, 50000]],
    ['500-1000', [50000, 100000]],
    ['1000+', [100000]],
  ]),
  phase: new Map<string, string>([
    ['Emerald', 'EMERALD'],
    ['Sapphire', 'SAPPHIRE'],
    ['Ruby', 'RUBY'],
    ['Black Pearl', 'BLACK_PEARL'],
  ]),
  overprice: new Map<string, number>([
    ['0', 0],
    ['1', 1],
    ['2', 2],
    ['3', 3],
    ['5', 5],
    ['7', 7],
    ['15', 15],
    ['30', 30],
    ['60', 60],
  ]),
  exterior: new Map<string, string>([
    ['Not Painted', 'NOT_PAINTED'],
    ['Factory New', 'FACTORY_NEW'],
    ['Minimal Wear', 'MINIMAL_WEAR'],
    ['Field-Tested', 'FIELD_TESTED'],
    ['Well-Worn', 'WELL_WORN'],
    ['Battle-Scarred', 'BATTLE_SCARRED'],
  ]),
  weapon: new Map<string, string>([
    ['Knife', 'KNIFE'],
    ['Machinegun', 'MACHINEGUN'],
    ['Pistol', 'PISTOL'],
    ['Rifle', 'RIFLE'],
    ['SMG', 'SMG'],
    ['Shotgun', 'SHOTGUN'],
    ['Sniper Rifle', 'SNIPER_RIFLE'],
  ]),
};
