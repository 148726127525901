<div class="page-not-found-container">
  <div class="number-container">
    <div class="number-box">
      <mat-icon
        class="number-img first"
        [svgIcon]="'number-four'"
      ></mat-icon>
    </div>
    <div class="number-box">
      <mat-icon
        class="number-img"
        [svgIcon]="'number-zero'"
      ></mat-icon>
    </div>
    <div class="number-box">
      <mat-icon
        class="number-img last"
        [svgIcon]="'number-four'"
      ></mat-icon>
    </div>
  </div>
  <div class="text-container">
    <div class="text-title">
      {{ 'GOFAST.SERVICE_PAGES.404.HEADER' | translate }}
    </div>
    <div class="text-description">
      {{ 'GOFAST.SERVICE_PAGES.404.TEXT' | translate }}
    </div>
  </div>
  <a
    class="btn back-btn"
    [routerLink]="'/game/classic'"
    >{{ 'GOFAST.SERVICE_PAGES.404.BUTTON' | translate }}</a
  >
</div>
