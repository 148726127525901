import { Component, EventEmitter, NgModule, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { debounceTime, Subject, takeUntil } from "rxjs";

@Component({
  selector: 'fast-ui-trades-widget',
  templateUrl: './trades-widget.component.html',
  styleUrls: ['./trades-widget.component.scss'],
})
export class TradesWidgetComponent implements OnInit, OnDestroy {

  @Output() public closeAttentionEmit: EventEmitter<void> = new EventEmitter<void>();
  @Output() public tradesSettingsEmit: EventEmitter<object> = new EventEmitter<object>();

  public tradesSettings: FormGroup = this.fb.group({
    'duration' : false,
    'autoapprove': false,
    'quickDelivery': false,
  })
  private destroyed$: Subject<void>;

  constructor(
    private readonly fb: FormBuilder,
  ) {
    this.destroyed$ = new Subject<void>();
  }

  ngOnInit(): void {
    this.tradesSettings.valueChanges.pipe(
      debounceTime(300),
      takeUntil(this.destroyed$),
    ).subscribe(()=> {
      this.tradesSettingsEmit.emit(this.tradesSettings.value);
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}

@NgModule({
  imports: [CommonModule, MatIconModule, MatTabsModule, RouterModule, TranslateModule, ReactiveFormsModule],
  declarations: [TradesWidgetComponent],
  exports: [TradesWidgetComponent],
})
export class TradesWidgetComponentModule {}
