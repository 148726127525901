import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { ConfirmDialogComponent } from './confirm-dialog.component';
import { ConfirmDialogDirective } from './confirm-dialog.directive';

@NgModule({
  declarations: [ConfirmDialogComponent, ConfirmDialogDirective],
  imports: [CommonModule, MatIconModule, MatDialogModule, MatButtonModule, TranslateModule],
  exports: [
    ConfirmDialogDirective
  ],
})
export class ConfirmDialogModule {}
