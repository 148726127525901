import {Injectable} from '@angular/core';
import {CommonStoreService} from '@csgofast/core-state-common';
import {ChangeActivePanel, LayoutState, ToggleBackground} from '@csgofast/core/state/layout';
import {CloseModal} from '@csgofast/core/state/modals';
import {UserState} from '@csgofast/core/state/user-store';
import {
  ActivePanel,
  EventsConfig,
  ICaseQuestMotivatorLastDto,
  ICaseQuestTotalProgress,
  ICasesQuestItem,
  IUserDetailed,
  ModalNames,
  Panel,
  SubPanel
} from '@dev-fast/types';
import {Dispatch} from '@ngxs-labs/dispatch-decorator';
import {Select, Store} from '@ngxs/store';
import {map, Observable} from 'rxjs';
import {GetMotivator, GetQuest, NyEventState, PickUpPrize, ResetQuest} from '../states/ny';

@Injectable()
export class NyEventService {
  @Select(UserState.user)
  public readonly user$: Observable<IUserDetailed | null> | undefined;

  @Select(NyEventState.eventName)
  readonly eventName$!: Observable<keyof EventsConfig | null>;
  @Select(NyEventState.items)
  readonly items$!: Observable<ICasesQuestItem[]>;
  @Select(NyEventState.untilEventTime)
  readonly untilEventTime$!: Observable<number|null>;
  @Select(NyEventState.untilEventHistoryTime)
  readonly untilEventHistoryTime$!: Observable<number|null>;
  @Select(NyEventState.eventHistoryName)
  readonly eventHistoryName$!: Observable<keyof EventsConfig | null>;
  @Select(NyEventState.atOnceWithHistory)
  readonly atOnceWithHistory$!: Observable<boolean>;

  @Select(NyEventState.reward)
  readonly reward$!: Observable<ICasesQuestItem | null>;
  @Select(NyEventState.totalProgress)
  readonly totalProgress$!: Observable<ICaseQuestTotalProgress>;
  @Select(NyEventState.overlay)
  readonly overlay$!: Observable<ICasesQuestItem | null>;
  @Select(NyEventState.motivatorList)
  readonly motivatorList$: Observable<ICaseQuestMotivatorLastDto[]> | undefined;
  @Select(LayoutState.activePanel)
  readonly activePanel$!: Observable<ActivePanel | null> ;

  constructor(private readonly store: Store, private readonly commonService: CommonStoreService) {}

  public getItemById(id: number): Observable<ICasesQuestItem | undefined> {
    return this.store.select(NyEventState.item).pipe(map((filterFn) => filterFn(id)));
  }
  public login() {
    this.commonService.login();
  }

  @Dispatch() public initEvent = () => [new GetQuest(), new GetMotivator()];
  @Dispatch() public getMotivator = () => new GetMotivator();
  @Dispatch() public getQuest = () => new GetQuest();
  @Dispatch() public pickUpPrize = (item: ICasesQuestItem) => new PickUpPrize(item);
  @Dispatch() public toggleBackground = (color?: string) => new ToggleBackground(color);
  @Dispatch() public closeModal = () => new CloseModal(ModalNames.NY_EVENT_REWARD);
  @Dispatch() public openMenu = () => new ChangeActivePanel({panel:Panel.EVENT_MENU,subPanel:SubPanel.NONE});
  @Dispatch() public closeMenu = () => new ChangeActivePanel(null);

  @Dispatch() public resetState = () => new ResetQuest()
}
