<footer>
  <div class="footer__inner">
    <div class="footer__column">
      <p class="footer__text">
       Gamevio Ltd. HE 404862. Loutrakiou 5, Chara Benezia Bld, 1st floor, Office 101, Strovolos, 2027, Nicosia, Cyprus.
      </p>
      <!--    FIXME Добавить ключи-->
      <div class="footer__links">
        <a
          [routerLink]="'/tos'"
          class="footer__link"
          >Terms of service</a
        >
        |
        <a
          [routerLink]="'/privacy-policy'"
          class="footer__link"
          >Privacy policy</a
        >
        |
        <a
          [routerLink]="'/bug-bounty'"
          class="footer__link"
          >Bug bounty</a
        >
      </div>
    </div>
    <div class="footer__row antillephone_container">
      <div class="footer__image">
        <img
          lazyLoadImage
          src="/assets/img/footer/18.png"
          alt="18+"
        />
      </div>
<!--      <div class="footer__image">-->
<!--        <div style="display: block;position: relative;overflow: hidden;max-width: 128px;min-width: 32px;-->
<!--        background-image: url(/assets/img/footer/verif.png);">-->
<!--          <a target="_blank" rel="nonoopener" href="https://validator.antillephone.com/validate?domain=local.csgofast.com:1617&amp;seal_id=9e0a4cc45ab2e46aa7e9ce7774634920221ce3651e10949e51c16ffc5cb60d2e4ce0544838816b69ec05440351102ed1&amp;stamp=e4e847d265e0148c43b43d64b48477bd">-->
<!--            <img alt="" style="width: 100%; height: auto" src="https://fea4d2c6-3a5c-41c6-87d8-b62705c91616.snippet.antillephone.com/sealassets/e4e847d265e0148c43b43d64b48477bd-local.csgofast.com:1617-9e0a4cc45ab2e46aa7e9ce7774634920221ce3651e10949e51c16ffc5cb60d2e4ce0544838816b69ec05440351102ed1-c2VhbC5wbmc%3D?status=valid">-->
<!--          </a>-->
<!--        </div>-->
<!--                <img-->
<!--                  lazyLoadImage-->
<!--                  src="/assets/img/footer/verif.png"-->
<!--                  alt="cert-verification"-->

<!--                />-->
<!--        <div-->
<!--          id="apg-fea4d2c6-3a5c-41c6-87d8-b62705c91616"-->
<!--          data-apg-seal-id="fea4d2c6-3a5c-41c6-87d8-b62705c91616"-->
<!--          data-apg-image-size="128"-->
<!--          data-apg-image-type="basic-small"-->
<!--        ></div>-->
<!--      </div>-->
    </div>
  </div>
</footer>
