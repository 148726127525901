import {ChangeDetectionStrategy, Component, Input, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {MatIconModule} from '@angular/material/icon';

@Component({
  selector: 'fast-ui-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrandComponent {
  @Input() public link = '';
}

@NgModule({
  imports: [CommonModule, MatIconModule, RouterModule],
  declarations: [BrandComponent],
  exports: [BrandComponent],
})
export class BrandComponentModule {}
