import {NgModule} from '@angular/core';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {AppCurrencyTranslatePipe} from './currency-translate.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [AppCurrencyTranslatePipe],
  exports: [AppCurrencyTranslatePipe],
  providers: [CurrencyPipe, AppCurrencyTranslatePipe],
})
export class AppCurrencyTranslateModule {}
