import {IconConfig} from './icon.model';

export const iconsArrows: IconConfig[] = [
  {
    name: 'caret-down',
    path: 'assets/img/mat-icons/arrows/caret-down.svg',
  },
  {
    name: 'chevron-double-right',
    path: 'assets/img/mat-icons/arrows/chevron-double-right.svg',
  },
  {
    name: 'chevron-double-left',
    path: 'assets/img/mat-icons/arrows/chevron-double-left.svg',
  },
  {
    name: 'chevron-left',
    path: 'assets/img/mat-icons/arrows/chevron-left.svg',
  },
  {
    name: 'arrow-right',
    path: 'assets/img/mat-icons/arrows/arrow-right.svg',
  },
  {
    name: 'arrow-right-up',
    path: 'assets/img/mat-icons/arrows/arrow-right-up.svg',
  },
];
