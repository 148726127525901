import {ChangeDetectionStrategy, Component, NgModule, OnInit,} from '@angular/core';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'fast-ui-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackBarComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

@NgModule({
  imports: [CommonModule],
  declarations: [SnackBarComponent],
  exports: [SnackBarComponent],
})
export class SnackBarComponentModule {}
