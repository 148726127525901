import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ICaseRevisionItem } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';
import { IncreaseModule } from '../../increase/increase.component';
import { AppCurrencyModule, AppPercentModule } from '@csgofast/shared/pipes';
import { PossibleCardComponent } from './possible-card/possible-card.component';
import { RouterModule } from '@angular/router';
import { PossibleItemsListMockComponent } from './mock/possible-items-list.component.mock';
import { PossibleCardMockComponent } from "./possible-card/mock/possible-card.component.mock";
import { NgScrollbarModule } from "ngx-scrollbar";

@Component({
  selector: 'fast-ui-possible-items-list',
  templateUrl: './possible-items-list.component.html',
  styleUrls: ['./possible-items-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PossibleItemsListComponent implements OnInit {
  @Input() public caseId!: number;
  @Input() public rollId: number | undefined;
  @Input() public caseItems: ICaseRevisionItem[] = [];
  @Input() public tracksAmount = 1;
  @Input() public isHovered = true;
  @Input() public isCompact = false;
  @Input() public clientSeed: string | undefined;
  @Output() public onopenModal: EventEmitter<number> = new EventEmitter();
  @Output() public onnavigateToItem: EventEmitter<ICaseRevisionItem> = new EventEmitter();
  @Output() public onchangeSeed: EventEmitter<string> = new EventEmitter();
  public sortedItems: ICaseRevisionItem[][] = [];
  public isClientSeedEditing = false;

  // constructor(private casesService: CasesService) {}

  ngOnInit(): void {
    this.sortedItems = this.sortItems(this.previewCardsConstructor(this.caseItems), '-price');
  }
  public totalPossibleWin(): number {
    return this.sortedItems[0][0].inventoryItem.price * this.tracksAmount;
  }
  public previewCardsConstructor(items: ICaseRevisionItem[]): Record<string, ICaseRevisionItem[]> {
    const possibleItems: Record<string, ICaseRevisionItem[]> = {};
    items.forEach((item: ICaseRevisionItem) => {
      const shortName = item.inventoryItem.baseItem.shortName;
      const skinName = item.inventoryItem.baseItem.skin;
      const fullName = shortName + " | " + skinName;
      if (!Object.keys(possibleItems).includes(fullName)) {
        possibleItems[fullName] = [item];
      } else {
        possibleItems[fullName].push(item);
      }
    });
    return possibleItems;
  }
  public sortItems(possibleItems: Record<string, ICaseRevisionItem[]>, sortDirection: string): ICaseRevisionItem[][] {
    return Object.keys(possibleItems)
      .map((key) => possibleItems[key])
      .sort((a, b) => {
        if (sortDirection === '-price') {
          return b[0].inventoryItem.price - a[0].inventoryItem.price;
        }
        return a[0].inventoryItem.price - b[0].inventoryItem.price;
      });
  }
  public openCaseRevisionModal(): void {
    this.onopenModal.emit(this.caseId);
  }
  public navigateToCaseItem(itemRevisions: ICaseRevisionItem[]): void {
    // const params = { skin: itemRevisions[0].inventoryItem.baseItem.skin, shortName: itemRevisions[0].inventoryItem.baseItem.shortName };
    // this.casesService.navigateToCaseItem(params);
    this.onnavigateToItem.emit(itemRevisions[0]);
  }
  public toggleClientSeedEditing(): void {
    this.isClientSeedEditing = !this.isClientSeedEditing;
  }
  public changeClientSeed(event: Event): void {
    const value = (event.target as HTMLInputElement).value
    if (value === this.clientSeed) {
      return;
    }
    this.clientSeed = value
    this.toggleClientSeedEditing();
    this.onchangeSeed.emit(value.trim());
    // if (value === this.info?.clientSeed?.seed) {
    //   return;
    // }
    // this.provablyFairService.requestClientSeedChange(value.trim());

  }
}

@NgModule({
  imports: [CommonModule, MatIconModule, AppCurrencyModule, AppPercentModule, TranslateModule, IncreaseModule, RouterModule, NgScrollbarModule],
  declarations: [PossibleItemsListComponent, PossibleCardComponent, PossibleItemsListMockComponent, PossibleCardMockComponent],
  exports: [PossibleItemsListComponent, PossibleItemsListMockComponent, PossibleCardComponent, PossibleCardMockComponent],
})
export class PossibleItemsListComponentModule {}
