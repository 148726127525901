export enum IEmailStatus {
  CONFIRMED = 'confirmed',
  PENDING = 'pending',
  NOT_ACTIVATED = 'not activated',
  NOT_CONFIRMED = 'not confirmed',
  CHANGING = 'changing',
  EMPTY = 'empty',
  NEED_UNSUBSCRIBE = 'need unsubscribe',
  UNSUBSCRIBE = 'unsubscribe',
}
