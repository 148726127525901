import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgModule, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { AppCurrencyModule, AppPercentModule } from '@csgofast/shared/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { IncreaseModule } from '../../increase/increase.component';
import { RouterModule } from '@angular/router';
import { CaseCardComponentModule } from '../cards';
import { ICaseItemDtoV2, ICaseRevisionInfo } from '@dev-fast/types';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';

@Component({
  selector: 'fast-ui-case-full-info',
  templateUrl: './case-full-info.component.html',
  styleUrls: ['./case-full-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaseFullInfoComponent {
  @Input() public selectedCases: ICaseItemDtoV2[] | null = null;
  @Input() public caseItem: ICaseItemDtoV2 | undefined;
  @Input() public showSelectRevision = true;
  @Input() public isTable = true;
  @Input() public set caseRevision(value: ICaseRevisionInfo | undefined) { 
    this.activeRevision = value
    if (this.showSelectRevision && value) {
      this.revisionsListConstructor(value)
      this.selectedRevision = value.revision.id
    }
  };

  @Output() onaddCase: EventEmitter<ICaseItemDtoV2> = new EventEmitter()
  @Output() onselectRevision: EventEmitter<number> = new EventEmitter()
  @Output() oncloseModal: EventEmitter<void> = new EventEmitter()
  @Output() ontoggleView: EventEmitter<void> = new EventEmitter()

  public revisionsList: { value: number; viewValue: number }[] | undefined
  public activeRevision: ICaseRevisionInfo | undefined;
  public selectedRevision: number | undefined;
  public isOpenMenu = false;

  private revisionsListConstructor(caseRevision: ICaseRevisionInfo): void {
    const revisionsList: { value: number; viewValue: number }[] = [];
    caseRevision.revisionIds.forEach((revisionId: number, index: number) => {
      const revision = { value: index, viewValue: revisionId };
      revisionsList.push(revision);
    });
    this.revisionsList = revisionsList;
  }
  public addCase(caseItem: ICaseItemDtoV2): void {
    this.onaddCase.emit(caseItem)
  }
  public getCaseRevision(range: number) {
    this.onselectRevision.emit(range);
  }
  public menuToggle(value: boolean): void {
    this.isOpenMenu = value;
  }
  public closeModal(): void {
    this.oncloseModal.emit()
  }
  public toggleListView(): void {
    this.ontoggleView.emit()
  }
}
@NgModule({
  imports: [
    CommonModule, 
    MatIconModule, 
    MatMenuModule,
    AppCurrencyModule, 
    AppPercentModule, 
    TranslateModule, 
    IncreaseModule, 
    RouterModule, 
    CaseCardComponentModule, 
    NgScrollbarModule
  ],
  declarations: [CaseFullInfoComponent],
  exports: [CaseFullInfoComponent],
})
export class CaseFullInfoModule { }