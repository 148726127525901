<div class="pagination pagination__marketplace">
  <button class="btn btn-sm btn-dark pagination__button"
          [disabled]="page.value <= min"
          (click)="setPrevPage()">
    <mat-icon
      svgIcon="chevron-left"
      class="pagination__arrow"></mat-icon>
  </button>
  <input class="pagination__counter"
         [style.width]="pageDigitCount * 0.875 + 'rem'"
         type="number"
         [formControl]="page"/>
  <button class="btn btn-sm btn-dark pagination__button"
          [disabled]="page.value >= max"
          (click)="setNextPage()">
    <mat-icon
      svgIcon="chevron-left"
      class="pagination__arrow pagination__arrow_right"></mat-icon>
  </button>
  <span class="pagination__limit">From {{max}}</span>
</div>
