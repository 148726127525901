import {Store} from '@ngxs/store';
import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {map, Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {GameMode, IPublicGameStatistic, IPublicUserDetailed, SocialType} from '@dev-fast/types';
import {UserApiService} from '@dev-fast/backend-services';
import {GamesState} from '@csgofast/core/state/games-store';

@Component({
  selector: 'csgofast-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent {
  public userId: number;
  public $user: Observable<IPublicUserDetailed | null>;
  public hiddenItemsArr: unknown[];
  public hiddenItem: IPublicGameStatistic;
  // public providers = []
  public socialTypes = SocialType;

  constructor(
    public dialogRef: MatDialogRef<UserProfileComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { userId: number },
    private userService: UserApiService,
    private store: Store
  ) {
    this.userId = data.userId;
    // FIXME мне не нравится что тут делается запрос и таким образом. Но пока ничего лучше в голову не пришло
    this.$user = (this.userService.getUserProfile(this.userId, { needDetailedInfo: true }) as Observable<IPublicUserDetailed>).pipe(
      // tap(user=>{
      //   provider
      // }),
      catchError((error: HttpErrorResponse) => of(null))
    );
    this.hiddenItemsArr = new Array(6);
    this.hiddenItem = {
      key: 'baccarat',
      mode: GameMode.PVE,
      top: {
        isCurrency: true,
        key: 'baccarat_max_bet',
        title: 'Max bet',
        value: '9999',
      },
    };
  }
  public getGameTitle(key: string): Observable<string> {
    return this.store.select(GamesState.gameTitle).pipe(map((filterFn) => filterFn(key)));
  }
  public close() {
    this.dialogRef.close();
  }

  public getUserCountry(user: IPublicUserDetailed): string {
    return user.country ? `//d2lomvz2jrw9ac.cloudfront.net/country-flags/${user.country.toLowerCase()}.png` : '';
  }
}
