import {Breakpoints} from '@angular/cdk/layout';
import {ActivePanel, Panel, Themes, Widgets} from '@dev-fast/types';

export interface LayoutStateModel {
  theme: Themes;
  background: string;
  activePanel: ActivePanel | null;
  activeWidgets: Widgets[];
  gameSelectorOpened: boolean;
  layoutType: string;
  /** задумка (не реализовано) чтобы в зависимости от проекта подключать модули или состояния
   * Что бы совсем не показывать кнопки перехода
   */
  allowedPanels: Panel[];
  /** Эти модули доступны но в даный момент времени ими нельзя воспользоваться */
  lockedPanels: Panel[];
  isFadeSides: boolean;
  // для открывания десктопа в мобиле
  hasDesktopParams: boolean;
  isTabletByWidth: boolean | null;
}

export const LAYOUT_INITIAL_STATE: LayoutStateModel = {
  theme: Themes.BLUE,
  allowedPanels: [],
  // activePanel: { panel: Panel.INVENTORY, subPanel: SubPanel.BET },
  activePanel: null,
  gameSelectorOpened: false,
  isFadeSides: false,
  lockedPanels: [],
  activeWidgets: [],
  layoutType: Breakpoints.Handset,
  hasDesktopParams: false,
  background: 'url(/assets/img/background/bg-csgofast.jpg)',
  isTabletByWidth: null,
};
