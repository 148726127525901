import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IError, ILocales, IUserDetailed, ModalNames, Permissions, PromoCode, Themes } from '@dev-fast/types';
import { ActionsExecuting } from '@ngxs-labs/actions-executing';
import { Observable, tap } from 'rxjs';
import { DesktopHeaderService } from './desktop-header.service';

@Component({
  selector: 'csgofast-desktop-header',
  templateUrl: './desktop-header.component.html',
  styleUrls: ['./desktop-header.component.scss', './desktop-header.media.scss'],
  providers: [DesktopHeaderService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesktopHeaderComponent {
  public themeList: Themes[] = Object.values(Themes);
  public permissions = Permissions;

  public readonly theme$: Observable<Themes>;
  public readonly user$: Observable<IUserDetailed | null | undefined>;
  public readonly locales$: Observable<ILocales[]>;
  public readonly currentLocale$: Observable<ILocales>;
  public readonly isTabletByWidth$: Observable<boolean> | undefined;

  // public readonly currentPromo$: Observable<PromoCode>;
  public readonly activationIsExecuting$: Observable<ActionsExecuting>;
  public readonly promoData$: Observable<{
    activationErr: IError | null;
    activePromoCode: PromoCode | null;
    promoActivated: boolean;
  }>;

  public readonly soundStatus$: Observable<boolean>;
  public coinsAmount: number;
  public showEventMenu = false;
  public craneHover = false;

  constructor(private readonly moduleService: DesktopHeaderService) {
    this.coinsAmount = 0;
    this.theme$ = this.moduleService.theme$;
    // TODO думаю минимум в сервис вынести, а лучше в стейте отдавать отдельно этот кусочек
    this.user$ = this.moduleService.user$.pipe(
      tap((user) => {
        if (user) {
          this.coinsAmount = user.walletType === 'demo' ? user.demoCoinsAmount : user?.coinsAmount;
        }
      })
    );
    this.locales$ = this.moduleService.locales$;
    this.currentLocale$ = this.moduleService.currentLocale$;
    this.isTabletByWidth$ = this.moduleService.isTabletByWidth;

    // this.currentPromo$ = this.moduleService.activePromoCode$;
    this.activationIsExecuting$ = this.moduleService.activationIsExecuting$;
    this.promoData$ = this.moduleService.promoData$;
    this.soundStatus$ = this.moduleService.soundStatus$;
  }
  public onLogin = () => this.moduleService.login();
  public onLogout = () => this.moduleService.logout();
  public onSwitchTheme = (theme: Themes) => {
    this.moduleService.switchTheme(theme);
  };
  public onSwitchLang = (locale: ILocales) => {
    this.moduleService.switchLang(locale);
  };
  public changeSoundFlag(): void {
    this.moduleService.toggleSounds();
  }

  public changeType(): void {
    this.moduleService.switchBalance();
  }

  public getPromo(): void {
    this.moduleService.getCurrentPromo();
  }
  public activatePromocode(code: string): void {
    this.moduleService.activatePromoCode(code);
  }

  public onOpenGleamModal() {
    this.moduleService.openModal(ModalNames.GLEAM);
  }
  public onOpenOfferWallModal(userId: number) {
    this.moduleService.openModal(ModalNames.OFFERWALL, userId);
  }
}
