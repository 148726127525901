<div
  *ngIf="{
    items: items$ | async,
    reward: reward$ | async,
    eventHistoryName: eventHistoryName$ | async,
    cases: casesCollection$ | async,
    totalProgress: totalProgress$ | async
  } as data"
>
  <div class="ny-event-shop">
    <div
      class="title-strong"
      style="padding-bottom: 0; padding-top: 1.5rem"
    >
      {{ 'GOFAST.NY_EVENT.CASES_SHOP' | translate }}
    </div>
    <ng-container *ngIf="data.cases && data.items?.length; else cases_grid_loader">
      <fast-ui-cases-collection
        [collection]="data.cases"
        [eventName]="data.eventHistoryName"
        [categoriesIsLoad]="categoriesIsLoad$ | myAsync"
        style="padding: 0px 2px"
      ></fast-ui-cases-collection>
    </ng-container>
    <ng-template #cases_grid_loader>
     <div style="display: flex;flex-wrap: wrap; gap: 1rem;">
      <ngx-skeleton-loader
      animation="progress-dark"
      [theme]="{
        width: '15rem',
        'border-radius': '15px',
        height: '20rem',
        margin: '0'
      }"
    ></ngx-skeleton-loader>
    <ngx-skeleton-loader
        animation="progress-dark"
        [theme]="{
          width: '15rem',
          'border-radius': '15px',
          height: '20rem',
          margin: '0'
        }"
      ></ngx-skeleton-loader>
      <ngx-skeleton-loader
        animation="progress-dark"
        [theme]="{
          width: '15rem',
          'border-radius': '15px',
          height: '20rem',
          margin: '0'
        }"
      ></ngx-skeleton-loader>
     </div>
    </ng-template>
  </div>

  <ng-container *ngIf="data.items?.length">
    <div class="gifts-grid--title title-strong">{{ 'GOFAST.NY_EVENT.FINAL.UNOPENED' | translate }}</div>
    <csgofast-gifts-grid
      class="ny-event--gifts-grid"
      [presents]="data.items"
      [eventName]="data.eventHistoryName"
      [isEventComplete]="true"
    ></csgofast-gifts-grid>
  </ng-container>

  <csgofast-ny-progress-block
    class="ny-event--special-prize-motivator"
    [totalProgress]="data.totalProgress"
    [eventName]="data.eventHistoryName"
    [isEventComplete]="true"
    [reward]="data.reward"
  ></csgofast-ny-progress-block>
</div>
