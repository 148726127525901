import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NyResultsLeadersComponent } from './ny-results-leaders.component';
import { NyResultsLeadersRoutingModule } from './ny-results-leaders-routing.module';
import { MatIconModule } from '@angular/material/icon';
import { AppCurrencyModule, CustomAsyncPipeModule } from '@csgofast/shared/pipes';
import { UserAvatarModule, CountdownComponentModule, CasesCollectionComponentModule } from '@dev-fast/ui-components';
import { TranslateModule } from '@ngx-translate/core';
import { NyEventModule } from '../ny-event';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
  declarations: [NyResultsLeadersComponent],
  imports: [
    CommonModule,
    NyResultsLeadersRoutingModule,

    NyEventModule,
    MatIconModule,
    UserAvatarModule,
    CountdownComponentModule,

    CasesCollectionComponentModule,
    TranslateModule,
    AppCurrencyModule,
    CustomAsyncPipeModule,
    NgxSkeletonLoaderModule,

  ],
})
export class NyResultsLeadersModule {}
