export class GetCurrencySettings {
  public static readonly type = '[Currency] Get currency settings';
}
export class ChangeCurrency {
  public static readonly type = '[Currency] Change currency';
  constructor(public key?: string) {}
}
export class SelectCurrencyByCountry {
  public static readonly type = '[Currency] Select currency by country';
  constructor(public country: string) {}
}
