import {Component, Input} from '@angular/core';

@Component({
  selector: 'fast-ui-not-found-stub',
  templateUrl: './not-found-stub.component.html',
  styleUrls: ['./not-found-stub.component.scss'],
})
export class NotFoundStubComponent {
  @Input() public message: string;
  @Input() public image: string;

  constructor() {
    this.image = '';
    this.message = '';
  }
}
