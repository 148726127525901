import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IFrameMessageService} from '@csgofast/core/iframe';
import {NotificationsService} from '@csgofast/core/notification-service';
import {GetUser, RefreshCurrentUser, UserState} from '@csgofast/core/state/user-store';
import {LevelsApiService, UserApiService} from '@dev-fast/backend-services';
import {
  IExperience,
  IExperienceLeader,
  IExperienceStatistic,
  IGameStatistics,
  ILevel,
  IUserDetailed,
  prelude,
  zeroLevel,
} from '@dev-fast/types';

import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {catchError, defer, forkJoin, iif, mergeMap, of, tap, throwError} from 'rxjs';
import {ACCOUNT_INITIAL_STATE, AccountStateModel} from './account-state.model';
import {
  ChangePrivacy,
  GetExperienceLeaders,
  GetExperienceStatistic,
  GetLevelsRoadMap,
  GetRecentExperienceGainList,
  GetUserStatistics,
  LevelUp,
  UpdateApiKey,
  UpdateApiKeySuccess,
  UpdateTradeLink,
  UpdateTradeLinks,
  UpdateTradeLinkSuccess,
} from './account.actions';

@State<AccountStateModel>({
  name: 'account',
  defaults: ACCOUNT_INITIAL_STATE,
})
@Injectable()
export class AccountState {
  constructor(
    private readonly apiUserService: UserApiService,
    private readonly apiLevelsService: LevelsApiService,
    private readonly store: Store,
    private readonly notificationsService: NotificationsService,
    private readonly messageService: IFrameMessageService
  ) {}

  @Selector()
  public static gameStatistics({ gameStatistics }: AccountStateModel): IGameStatistics[] {
    return gameStatistics;
  }
  @Selector()
  public static leaders({ leaders }: AccountStateModel): IExperienceLeader[] {
    return leaders;
  }
  @Selector()
  public static experience({ experience }: AccountStateModel): IExperience[] {
    return experience;
  }
  @Selector()
  public static statistics({ statistics }: AccountStateModel): IExperienceStatistic[] {
    return statistics;
  }
  @Selector()
  public static levels({ levels }: AccountStateModel): ILevel[] {
    return levels;
  }
  // @Selector()
  // public static maxLevel({ levels }: AccountStateModel): number {
  //   return levels[levels.length - 1].level;
  // }
  @Selector([UserState.user])
  public static canLevelUp({ levels }: AccountStateModel, user: IUserDetailed): boolean {
    const maxLevel = levels[levels.length - 1].level;
    return user && user.experience.level < maxLevel ? user.experience.xp > user.experience.nextLevelXp : false;
  }

  @Action(RefreshCurrentUser)
  public refreshUser({ dispatch }: StateContext<AccountStateModel>, { payload }: RefreshCurrentUser): void {
    // const userId = this.store.selectSnapshot(UserState.user)?.id;
    // if (payload.refillCountry && payload.refillCountry !== null && userId) {
    //   dispatch(new GetMethods(payload.refillCountry, userId));
    // }
  }

  @Action(GetUserStatistics)
  public getStatistics({ patchState }: StateContext<AccountStateModel>, { userId }: GetUserStatistics) {
    return this.apiUserService.getUserStatistics(userId).pipe(tap((response) => patchState({ gameStatistics: response })));
  }
  @Action(GetExperienceLeaders)
  public getExperienceLeaders({ patchState }: StateContext<AccountStateModel>) {
    return this.apiLevelsService.getExperienceLeaders().pipe(tap((leaders) => patchState({ leaders })));
  }
  @Action(GetLevelsRoadMap)
  public getLevelsRoadMap({ patchState }: StateContext<AccountStateModel>) {
    return this.apiLevelsService.getLevelsRoadMap().pipe(
      tap((levels) => {
        // console.log(sortBy([prelude, zeroLevel, ...levels], 'level', 'asc')[levels.length - 1].level);
        return patchState({ levels: [prelude, zeroLevel, ...levels] });
      })
    );
  }
  @Action(GetRecentExperienceGainList)
  public getRecentExperienceGainList({ patchState }: StateContext<AccountStateModel>) {
    return this.apiLevelsService.getRecentExperienceGainList().pipe(tap((experience) => patchState({ experience })));
  }
  @Action(GetExperienceStatistic)
  public getExperienceStatistic({ patchState }: StateContext<AccountStateModel>) {
    return this.apiLevelsService.getExperienceStatistic().pipe(tap((statistics) => patchState({ statistics })));
  }
  @Action(LevelUp)
  public levelUp({ dispatch }: StateContext<AccountStateModel>) {
    return this.apiLevelsService.levelUp().pipe(tap(() => dispatch(new GetUser())));
  }
  @Action(ChangePrivacy)
  public changePrivacy({ dispatch }: StateContext<AccountStateModel>, { payload }: ChangePrivacy) {
    return this.apiUserService.savePrivacy(payload).pipe(tap(() => dispatch(new GetUser())));
  }
  @Action(UpdateTradeLink)
  public updateTradeLink({ dispatch }: StateContext<AccountStateModel>, { links }: UpdateTradeLink) {
    return this.apiUserService.updateTradeLinks(links).pipe(
      catchError((error: HttpErrorResponse) => this.onError(error, 'P2P_SETTINGS.TRADE_LINK.STEAM_MUST_BE_STEAM_TRADE_LINK')),
      tap(() => dispatch([new GetUser(), new UpdateTradeLinkSuccess()]))
    );
  }
  @Action(UpdateApiKey)
  public updateApiKey({ dispatch }: StateContext<AccountStateModel>, { apiKey }: UpdateApiKey) {
    return this.apiUserService.updateApiKey(apiKey).pipe(
      mergeMap((res) =>
        iif(
          () => res?.error,
          throwError(() => ({ message: res.error })),
          of(res)
        )
      ),
      catchError((error: HttpErrorResponse) => this.onError(error, 'P2P_SETTINGS.TRADE_LINK.STEAM_MUST_BE_STEAM_TRADE_LINK')),
      tap(() => dispatch([new GetUser(), new UpdateApiKeySuccess()]))
    );
  }
  @Action(UpdateTradeLinks)
  public updateTradeLinks({ dispatch }: StateContext<AccountStateModel>, { links, apiKey }: UpdateTradeLinks) {
    //TODO вроде все как нужно но встарой было updateTradeLinks вместо updateApiKey. Проверить
    // FIXME пересмотреть когда настройки буду делать
    return defer(() =>
      apiKey
        ? forkJoin([this.apiUserService.updateTradeLinks(links), this.apiUserService.updateApiKey(apiKey)])
        : this.apiUserService.updateTradeLinks(links)
    ).pipe(
      catchError((error: HttpErrorResponse) => this.onError(error)),
      tap(() => {
        this.notificationsService.addSuccessNotification('ACCOUNT_SETTINGS.LINK_UPDATED', { icon: 'info', system: true });
        dispatch(new GetUser());
      })
    );
  }
  private onError(e: HttpErrorResponse, msg?: string, system: boolean = true) {
    const { error } = e;
    this.notificationsService.addErrorNotification(msg || error.message || error.error, {
      icon: 'warning',
      system: error.system || system,
    });
    return of();
  }
}
