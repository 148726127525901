import {InjectionToken} from '@angular/core';
import packageJson from '../../../../../package.json';
export interface Environments {
  production: boolean;
  SENTRY_DSN: string;
  LOG_ACTIONS?: boolean;
  FULL_LOGIN_FLOW: boolean;
  WS_GATEWAY_URL: string;
  GATEWAY_URL: string;
  BACKBONE_URL: string;
  ESPORTS_API_URL: string;
  PAYMENT_URL: string;
  LOCALIZATION_URL: string;
  PAYMENT_CONFIG_URL: string;
  RECAPTCHA_SITE_KEY: string;
  SUPPORTS_EMAIL: string;
  HOSTNAME: string;
  PROJECT: string;
  VERSION: string;
  POGGI_URL: string;
  CHIP_FALLBACK_URL: string;
  MODULES: {
    account: string[];
    balance: {
      refill: any[];
      withdrawal: string[];
      history: boolean;
      referral: boolean;
    };
    games: string[];
  };
  // CONTROL_PANELS: Object
}

export const ENVIRONMENTS = new InjectionToken<Environments>('ENVIRONMENTS');

export const ENVIRONMENTS_DEFAULT: Environments = {
  production: false,
  SENTRY_DSN:'https://92502b2352f14009811555ec05210d9a@sentry.onmoon.io/29',
  LOG_ACTIONS: false,
  FULL_LOGIN_FLOW: true,
  WS_GATEWAY_URL: 'https://backend.test.devfast.io',
  GATEWAY_URL: 'https://backend.test.devfast.io',
  BACKBONE_URL: 'https://bridge.devfast.io',
  ESPORTS_API_URL: `https://backend.test.devfast.io/esports-api`,
  PAYMENT_URL: 'https://pay.test.devfast.io',
  LOCALIZATION_URL: 'https://locales.csgofastbackend.com/api',
  PAYMENT_CONFIG_URL: 'https://pay.csgofastbackend.com',
  RECAPTCHA_SITE_KEY: '6Lego1oUAAAAAAY8jdGEFL2zfNI-JyDnIIW-C4k8',
  SUPPORTS_EMAIL: 'support@csgofast.com',
  HOSTNAME: ``,
  PROJECT: 'csgofast',
  VERSION: packageJson.version,
  POGGI_URL: 'https://slots-test.csgofastbackend.com',
  CHIP_FALLBACK_URL: '//d2lomvz2jrw9ac.cloudfront.net/common/currency/money-bag-pngrepo-com.png',
  MODULES: {
    account: ['statistics', 'levels', 'settings', 'premium'],
    balance: {
      refill: [],
      withdrawal: ['marketplace', 'currency'],
      history: true,
      referral: true,
    },
    games: ['classic', 'double', 'cases', 'poggi', 'crash', 'x50', 'case_battle'],
  },
  // CONTROL_PANELS: {
  //   'account': {
  //     [UserPanelTypes.WIDGETS]: ['cashback', 'promocode'],
  //   },
  //   'withdrawal': {
  //     [UserPanelTypes.WITHDRAWAL]: [],
  //   },
  // },
};
