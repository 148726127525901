<!--FIXME Добавить добавление роутов через перебор-->
<a
  [routerLink]="'/game/' + game.key"
  [class.active]="isActive"
  class="menu-item"
  [ngClass]="{ opened: isShowFullView }"
>
  <span class="menu-item__icon-wrapper">
    <mat-icon
      class="menu-item__icon"
      [svgIcon]="'logo-' + game.key"
    ></mat-icon>
    <ng-container *ngIf="isNew">
      <fast-ui-tag
        [tag]="'new' | uppercase"
      ></fast-ui-tag>
    </ng-container>

    <!--  FIXME Добавить иконку замка по условию, если демо аккаунт и т.п-->
    <!--  <mat-icon-->
    <!--  class="menu-item__icon-lock"-->
    <!--  [svgIcon]="'game-lock'"-->
    <!--  ></mat-icon>-->
  </span>
  <!--FIXME Изменить название заголовков, добавить ключи для подзаголков и добавить сумму денег-->
  <span class="menu-item__body">
    <span class="menu-item__title">{{ game.title }}</span>
    <span
      class="menu-item__status"
      *ngIf="status"
    >
      <span class="menu-item__subtitle">{{ status.title }}</span>
      <ng-container *ngIf="!status.notCurrencyValue; else notCurrencyTemplate">
        <span class="menu-item__value">{{ status.value | appCurrency }}</span>
      </ng-container>
      <ng-template #notCurrencyTemplate>
        <span class="menu-item__value">{{ status.value }}</span>
      </ng-template>
    </span>
    <span class="menu-item__events"></span>
  </span>
</a>
