import { MainModalsService, ModalsService } from "@csgofast/core/modal-service";
import { CustomRouterStateSerializer } from "@csgofast/core/state/utils";
import { EventsHomeModule, NyAllEventService, NyAllEventState, NyEventService, NyEventState } from "@csgofast/modules/events";
import { ConfirmDialogModule, ConfirmDialogService } from "@csgofast/shared/modules";
import { LocalizationApiService } from "@dev-fast/backend-services";
import { NoIndexComponentModule, PlugComponentModule } from "@dev-fast/ui-components";
import { SocketIoConfig, SocketIoModule } from "ngx-socket-io";
import { STATES } from '../../../../libs/core/state';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, TransferState } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ENVIRONMENTS } from '@csgofast/core/environment-service';
import { customTranslateLoader } from '@csgofast/core/custom-trans-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UiIconsModule } from '@csgofast/ui/icons';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsActionsExecutingModule } from '@ngxs-labs/actions-executing';
import { NgxsDispatchPluginModule } from '@ngxs-labs/dispatch-decorator';
import { NgxsModule } from '@ngxs/store';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { AuthState } from '@csgofast/auth';
import { GofastLayoutModule } from '@csgofast/gofast/layout';
import { NAVIGATION_PATHS, PATHS } from '@csgofast/core/navigation/common';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsRouterPluginModule, RouterStateSerializer } from '@ngxs/router-plugin';
import { RecentActivitiesWidgetsModule } from '@csgofast/widgets/recent-activities';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { LanguageGuard } from "@csgofast/core/language-service";

const socketConfig: SocketIoConfig = {
  url: environment.WS_GATEWAY_URL,
  options: {
    // withCredentials: true,
    reconnection: true,
    reconnectionDelay: 1000,
    transports: ['websocket'],
  },
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    SocketIoModule.forRoot(socketConfig),
    TranslateModule.forRoot({
      useDefaultLang: true,
      defaultLanguage: 'default-locale',
      loader: {
        provide: TranslateLoader,
        useFactory: customTranslateLoader,
        deps: [LocalizationApiService, TransferState],
      },
      isolate: false,
    }),
    UiIconsModule,
    MatTooltipModule,
    MatDialogModule,
    MatMenuModule,
    OverlayModule,
    ConfirmDialogModule,
    BrowserAnimationsModule,
    EventsHomeModule,
    GofastLayoutModule,
    PlugComponentModule,
    NoIndexComponentModule,
    // EventsModule,
    RecentActivitiesWidgetsModule.forRoot(),

    // TODO возможно распределить некоторые стейты в лэзи модули
    NgxsModule.forRoot([AuthState, ...STATES, NyAllEventState,NyEventState], {
      developmentMode: !environment.production,
    }),
    NgxsRouterPluginModule.forRoot(),
    // NgxsNotificationPluginModule.forRoot(NotificationsService),
    NgxsDispatchPluginModule.forRoot(),
    NgxsResetPluginModule.forRoot(),
    NgxsActionsExecutingModule.forRoot(),
    NgxsFormPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    NgxsLoggerPluginModule.forRoot({
      // Do not collapse log groups
      collapsed: true,
      // Do not log in production mode
      disabled: environment.production || !environment.LOG_ACTIONS,
    }),

    // TODO если будет нужно кропать изображение на мобилке то разобраться с Hummer JS
    // HammerModule,
    // EventsCoreModule
  ],
  providers: [
    ConfirmDialogService,
    NyEventService,
    NyAllEventService,
    ModalsService,
    MainModalsService,
    LanguageGuard,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    {
      provide: ENVIRONMENTS,
      useValue: environment,
    },
    {
      provide: PATHS,
      useValue: NAVIGATION_PATHS,
    },
    {
      provide: 'isServer',
      useValue: false
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
