import {IconConfig} from "./icon.model";

export const iconsLeftSidebar: IconConfig[] = [
  {
    name: 'logo-v2-all-games',
    path: 'assets/img/mat-icons/left-sidebar/all-games.svg',
  },
  {
    name: 'logo-v2-market',
    path: 'assets/img/mat-icons/left-sidebar/market.svg',
  },
  {
    name: 'logo-v2-classic',
    path: 'assets/img/mat-icons/left-sidebar/classic.svg',
  },
  {
    name: 'logo-v2-fast',
    path: 'assets/img/mat-icons/left-sidebar/fast.svg',
  },
  {
    name: 'logo-v2-match-bets',
    path: 'assets/img/mat-icons/left-sidebar/bets.svg',
  },
  {
    name: 'logo-v2-fortune-wheel',
    path: 'assets/img/mat-icons/left-sidebar/wheel.svg',
  },
  {
    name: 'logo-v2-cases',
    path: 'assets/img/mat-icons/left-sidebar/cases.svg',
  },
  {
    name: 'logo-v2-tower',
    path: 'assets/img/mat-icons/left-sidebar/tower.svg',
  },
  {
    name: 'logo-v2-x50',
    path: 'assets/img/mat-icons/left-sidebar/x50.svg',
  },
  {
    name: 'logo-v2-baccarat',
    path: 'assets/img/mat-icons/left-sidebar/baccarat.svg',
  },
  {
    name: 'logo-v2-double',
    path: 'assets/img/mat-icons/left-sidebar/double.svg',
  },
  {
    name: 'logo-v2-hilo',
    path: 'assets/img/mat-icons/left-sidebar/hilo.svg',
  },
  {
    name: 'logo-v2-crash',
    path: 'assets/img/mat-icons/left-sidebar/crash.svg',
  },
  {
    name: 'logo-v2-slot',
    path: 'assets/img/mat-icons/left-sidebar/slots.svg',
  },
  {
    name: 'logo-v2-events',
    path: 'assets/img/mat-icons/left-sidebar/events.svg',
  },
  {
    name: 'logo-v2-poggi',
    path: 'assets/img/mat-icons/left-sidebar/poggi.svg',
  },
  {
    name: 'logo-v2-case-battle',
    path: 'assets/img/mat-icons/left-sidebar/case-battle.svg',
  },
  {
    name: 'menu-close-v2',
    path: 'assets/img/mat-icons/left-sidebar/menu-close.svg',
  },
  
]
