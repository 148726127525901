<ng-container *ngIf="eventName$ |async as eventName">
  <ng-container *ngIf="case && case.ticketQuest && count <=5 ">
    <!--  [class.event-active-btn]="caseCanesCost <= userCanesCount"-->
    <button
      class="ny-event-btn btn event-active-btn"
      [disabled]="isDisabled"
      (click)="openCaseForKeys(case)"
    >
      <span>{{'GOFAST.NY_EVENT.OPEN_CASE'|translate}}</span>
      <div class="ny-event-btn-icon">
        <img *ngIf="eventName ==='ny'" [src]="'/assets/img/ny-event/'+ticketsImgDic[case.ticketQuest.ticketColor]" alt="">
        <img *ngIf="eventName ==='ny_china'" [src]="'/assets/img/ny-event/china/'+popImgDic[case.ticketQuest.ticketColor]" alt="">
      </div>
      <span> {{ sum }}</span>
    </button>
  </ng-container>
</ng-container>


