export enum SocialType {
  MetaMask = 'metamask',
  Vkontakte = 'vkontakte',
  Facebook = 'facebook',
  Twitter = 'twitter',
  Odnoklassniki = 'odnoklassniki',
  Youtube = 'youtube',
  Instagram = 'instagram',
  Telegram = 'telegram',
  Whatsapp = 'whatsapp',
  Viber = 'viber',
  WeChat = 'wechat',
  Github = 'github',
  Google = 'google',
  Gosuslugi = 'gosuslugi',
  Tinkoff = 'tinkoff',
  IOMoney = 'iomoney',
  Yandex = 'yandex',
  Habr = 'habr',
  Discord = 'discord',
  Steam = 'steam',
  Twitch = 'twitch',
}
