<ng-container *ngIf="item">
  <div class="card-container" 
    [ngClass]="{ changed: currentCaseAmount > 0, hovered: isHovered, enabled: isCaseEnabled(), disabled: !isCaseEnabled() }"
    [bgSrc]="getBackgroundImage(item)"
    lazyLoadImage>
    <div class="item-tag-container">
      <ng-container *ngIf="item.tags && showTags">
        <fast-ui-tag
          *ngFor="let tag of item.tags"
          [class]="tag"
          [tag]="tag | uppercase">
        </fast-ui-tag>
      </ng-container>
    </div>
    <button 
        class="avatar-container"
        [class.moving]="showInput"
        [disabled]="totalCasesAmount > 9 || !showInput"
        (click)="addCase(item)">
        <ng-container *ngIf="item.itemImage !== null">
            <img class="avatar-img" [src]="item.itemImage" lazyLoadImage />
        </ng-container>
        <ng-container *ngIf="item.itemImage === null">
            <ng-container *ngIf="item.mainItem?.icon !== null">
                <img class="avatar-img" [src]="item.mainItem.icon" lazyLoadImage />
            </ng-container>
        </ng-container>
        <ng-container *ngIf="item.image !== null">
            <img class="avatar-img" [src]="item.image" lazyLoadImage />
        </ng-container>
    </button>
    <ng-container *ngIf="showDescription">
      <div 
        class="description-container">
        <button 
          class="case-info"
          [disabled]="totalCasesAmount > 9 || !showInput"
          (click)="addCase(item)">
          <div class="info-box box_1">
            <div class="btn case-name">
              {{ item.name }}
            </div>
          </div>
          <div class="info-box box_2">
            <div class="btn previous-price" *ngIf="item.lastRevision.prevPrice > item.lastRevision.price">
              {{ item.lastRevision.prevPrice | appCurrency }}
            </div>
            <div class="btn case-price">
              {{ item.lastRevision.price | appCurrency }}
            </div>
          </div>
        </button>
        <ng-container *ngIf="showInput">
          <div class="case-item-amount-container">
            <fast-ui-selection-input 
                [className]="'case_amount'" 
                [showPlaceholder]="false"
                [disabledInput]="true"
                [minValue]="0"
                [maxValue]="10 - (totalCasesAmount - currentCaseAmount)" 
                [currentValue]="currentCaseAmount"
                (valueChange)="changeAmount($event, item)">
            </fast-ui-selection-input>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <div class="shadow-wrapper">
    </div>
    <ng-container *ngIf="!showInput && isHovered">
      <a class="shadow-wrapper link-wrapper" [routerLink]="['/game/cases/case', item.id]">
      </a>
    </ng-container>
  <ng-container *ngIf="showRevision">
    <button class="btn revision-btn" (click)="openModal()">
      <mat-icon class="info-icon" [svgIcon]="'information'"></mat-icon>
    </button>
  </ng-container>
  </div>
</ng-container>
