import { Component, Input, OnInit } from '@angular/core';
import {EventsConfig, GameTypes, ICaseQuestTotalProgress, ILRQuest, lrEventsTranslateDictionary} from '@dev-fast/types';
import { Observable } from 'rxjs';
import { NyAllEventService } from '../../../core/services/ny-all-event.service';
import { NyEventService } from '../../../core/services/ny-event.service';

@Component({
  selector: 'csgofast-ny-quest-card',
  templateUrl: './ny-quest-card.component.html',
  styleUrls: ['./ny-quest-card.component.scss'],
})
export class NyQuestCardComponent {
  @Input() task: ILRQuest | undefined;

  public eventName$: Observable<keyof EventsConfig | null> | undefined;

  public translateDic = lrEventsTranslateDictionary

  public get translate():string{
    if (!this.task) return '';
    return this.translateDic[this.task.name as keyof typeof lrEventsTranslateDictionary]
  }
  constructor(private readonly nyAllEventService: NyAllEventService, private readonly nyEventService: NyEventService) {
    this.eventName$ = this.nyEventService.eventName$;
  }
  public questPercent(count: number, progress: number){
    return progress*100/count
  }
  public takeQuestReward(questId: number){
    this.nyAllEventService.takeReward(questId)
  }
  public questGame(gameTypeId: number): string{
    return GameTypes[gameTypeId]
  }
}
