import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Panel} from '@dev-fast/types';

@Component({
  selector: 'csgofast-widgets',
  templateUrl: './widgets.component.html',
  styleUrls: ['./widgets.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetsComponent implements OnInit {
  @Input() public activePanel: Panel | null;
  constructor() {
    this.activePanel = null;
  }

  public ngOnInit(): void {}
}
