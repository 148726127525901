import {Action, NgxsOnInit, Selector, State, StateContext, Store} from '@ngxs/store';
import {Injectable} from '@angular/core';

import {LotteryApiService} from '@dev-fast/backend-services';
import {IFrameMessageService} from '@csgofast/core/iframe';
import {Socket as WrappedSocket} from "ngx-socket-io";
import {ILottery, LotteryType} from '@dev-fast/types';
import {tap} from 'rxjs';
import {LOTTERY_INITIAL_STATE, LotteryStateModel} from './lottery-state.model';
import {GetLotteryItems} from './lottery.actions';
import moment from 'moment';

@State<LotteryStateModel>({
  name: 'lottery',
  defaults: LOTTERY_INITIAL_STATE,
})
@Injectable()
export class LotteryState implements NgxsOnInit {
  @Selector()
  public static items({ items }: LotteryStateModel): ILottery[] {
    return items;
  }
  @Selector()
  public static collectionToShow({ collectionToShow }: LotteryStateModel): ILottery[] {
    return collectionToShow;
  }

  constructor(
    private readonly apiService: LotteryApiService,
    private readonly store: Store,
    private readonly frameMessageService: IFrameMessageService,
    private readonly ws: WrappedSocket
  ) {}
  ngxsOnInit({ getState, patchState, dispatch }: StateContext<LotteryStateModel>): void {
    // this.apiService.onStateUpdate((payload: ILottery[]) => {
    //   // console.log(payload);
    //   // dispatch(new CreateMsgEventHandling(msg))
    // });
    // refill-game:games:ny
    // refill-game:games:${gameId}:user:ny
    // refill-game:games:${gameId}:update
  }

  @Action(GetLotteryItems)
  public getItems({ patchState, getState }: StateContext<LotteryStateModel>) {
    const { countToShow } = getState();
    return this.apiService
      .getLotteryState()
      .pipe(tap((response) => patchState({ items: response, collectionToShow: this._getCollectionToShow(response, countToShow) })));
  }
  private _getCollectionToShow(items: ILottery[], countToShow: number): ILottery[] {
    // последние незаконченые игры.
    let collection = items.filter((model) => moment(model.gameEndTimestamp).diff(moment()) > 0 && model.type !== LotteryType.FAST);

    // если есть минимум одна активная игра, то дополнить ее одной законченой (при условии ее наличия)
    if (collection.length !== 0 && collection.length < countToShow) {
      collection.push(...items.slice(0, countToShow - collection.length));
    } else if (collection.length === 0) {
      // если нет активных игр - показать неактивные
      collection = items.slice(0, countToShow);
    }
    // рандомные два розыгрыша
    return collection.sort((a: ILottery, b: ILottery) => {
      if (a.type === LotteryType.FREE) return -1;
      if (b.type === LotteryType.FREE) return 1;

      if (a.type === LotteryType.DEPOSIT) return -1;
      if (b.type === LotteryType.DEPOSIT) return 1;

      if (a.type === LotteryType.HOURLY) return -1;
      if (b.type === LotteryType.HOURLY) return 1;

      if (a.type === LotteryType.DAILY) return -1;
      if (b.type === LotteryType.DAILY) return 1;

      if (a.type === LotteryType.WEEKLY) return -1;
      if (b.type === LotteryType.WEEKLY) return 1;
      return 0;
    });
  }
}
