<div class="auth-modal-container">
  <h2 class="header">{{ 'AUTHORIZATION.SELECT_SYSTEM' | translate }}:</h2>
  <div *ngIf="socials$ | async as socials">
    <div class="steam-login--container">
      <div class="steam-login--item">
        <button
          class="btn social-item-button"
          [ngClass]="socials[0].name"
          (click)="authorize(socials[0].name)"
        >
          <mat-icon
            class="icon"
            [class]="'icon-' + socials[0].name"
            [svgIcon]="socials[0].name"
          ></mat-icon>
          <div class="social-item-name">{{ 'AUTHORIZATION.LOGIN_WITH' | translate }} {{ socials[0].title | titlecase }}</div>
        </button>
      </div>
    </div>
    <div class="other-socials--header">
      {{ 'AUTHORIZATION.LOGIN_WITH' | translate }}
    </div>
    <div class="other-socials--container">
      <div class="socials-array">
        <div
          class="other-socials--item"
          *ngFor="let social of socials; index as i"
        >
          <button
            *ngIf="i !== 0 && i !== 1"
            class="btn social-item-button"
            [ngClass]="social.name"
            (click)="authorize(social.name)"
          >
            <mat-icon
              class="icon"
              [class]="'icon-' + social.name"
              [svgIcon]="'socials-' + social.name"
            ></mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="agreement"
    [class.auth-err]="loginAttempt && !agreementChecked"
  >
    <mat-checkbox
      [(ngModel)]="agreementChecked"
      (change)="loginAttempt = agreementChecked"
      color="accent"
    ></mat-checkbox>
    <div
      class="agreement-description"
      matTooltip="{{ 'AUTHORIZATION.ADDITION_TOOLTIP' | translate }}"
      matTooltipPosition="above"
      [innerHTML]="'AUTHORIZATION.TERMS_AND_CONDITIONS' | translate"
    ></div>
  </div>
  <div class="attention-message-container" *ngIf="showAttention">
    <div>
      <mat-icon
        class="icon-attention"
        svgIcon="failure"
      ></mat-icon>
    </div>
    <div class="attention-message">
      <div>{{ 'AUTHORIZATION.WARNING' | translate }}</div>
      <div>
        {{ 'AUTHORIZATION.WARNING_PT2' | translate }}
        <a
          class="email-link"
          href="mailto:support@csgofast.com"
        >
          support@csgofast.com
        </a>
      </div>
    </div>
  </div>
</div>
