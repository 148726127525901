import {IP2pPurchaseItem, OrderStatusEnum} from '@dev-fast/types';

export const BYING_INIT_ITEM: IP2pPurchaseItem[] = [
  {
    isActive: true,
    id: 1,
    baseItem: {
      name: 'Item name',
      icon: 'https://steamcommunity-a.akamaihd.net/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgposbaqKAxf0Ob3djFN79eJh46KlPz1J6_QlWBu5Mx2gv3--Y3nj1H6-kdpN2H1J4GUdlBsYQ6E_Ae5x-i605S06p7PynswunV0sSqJmhW2iQYMMLKO7TPsAg',
      color: '',
      weapon: 'weapon name',
      type: 'type',
      rarity: 'rarity',
      shortName: 'Negev',
      skin: 'dev-texture',
      exterior: 'Factory New',
      statTrak: true,
      steamPrice: 100,
    },
    status: OrderStatusEnum.NEW,
    statusAt: '',
    nextStatusAt: '2022-10-06T19:38:00.838Z',
    isUserSeller: false,
    isLastBidByUser: false,
    price: 85,
    express: false,
    stickers: [],
    recommendedPrice: 99,
    sellerPrice: 100,
    nextBidPrice: 150,
    steamInspectLink: '',
    isCrossFast: false,
  },

  {
    isActive: true,
    id: 2,
    baseItem: {
      name: 'Item name',
      icon: 'https://steamcommunity-a.akamaihd.net/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgposbaqKAxf0Ob3djFN79eJh46KlPz1J6_QlWBu5Mx2gv3--Y3nj1H6-kdpN2H1J4GUdlBsYQ6E_Ae5x-i605S06p7PynswunV0sSqJmhW2iQYMMLKO7TPsAg',
      color: '',
      weapon: 'weapon name',
      type: 'type',
      rarity: 'rarity',
      shortName: 'Negev',
      skin: 'dev-texture',
      exterior: 'Factory New',
      statTrak: true,
      steamPrice: 100,
    },
    status: OrderStatusEnum.FIRST_BID,
    statusAt: '',
    nextStatusAt: '2022-10-20T19:38:00.838Z',
    isUserSeller: false,
    isLastBidByUser: false,
    price: 85,
    express: false,
    stickers: [],
    recommendedPrice: 99,
    sellerPrice: 100,
    nextBidPrice: 150,
    steamInspectLink: '',
    isCrossFast: false,
  },
  {
    isActive: true,
    id: 3,
    baseItem: {
      name: 'Item name',
      icon: 'https://steamcommunity-a.akamaihd.net/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgposbaqKAxf0Ob3djFN79eJh46KlPz1J6_QlWBu5Mx2gv3--Y3nj1H6-kdpN2H1J4GUdlBsYQ6E_Ae5x-i605S06p7PynswunV0sSqJmhW2iQYMMLKO7TPsAg',
      color: '',
      weapon: 'weapon name',
      type: 'type',
      rarity: 'rarity',
      shortName: 'Negev',
      skin: 'dev-texture',
      exterior: 'Factory New',
      statTrak: true,
      steamPrice: 100,
    },
    status: OrderStatusEnum.WAIT_FOR_BUYER_ACCEPT,
    statusAt: '',
    nextStatusAt: '2022-10-20T19:38:00.838Z',
    isUserSeller: false,
    isLastBidByUser: true,
    price: 101,
    express: false,
    stickers: [],
    recommendedPrice: 99,
    sellerPrice: 100,
    steamInspectLink: '',
    isCrossFast: false,
  },

  {
    isActive: true,
    id: 4,
    baseItem: {
      name: 'Item name',
      icon: 'https://steamcommunity-a.akamaihd.net/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgposbaqKAxf0Ob3djFN79eJh46KlPz1J6_QlWBu5Mx2gv3--Y3nj1H6-kdpN2H1J4GUdlBsYQ6E_Ae5x-i605S06p7PynswunV0sSqJmhW2iQYMMLKO7TPsAg',
      color: '',
      weapon: 'weapon name',
      type: 'type',
      rarity: 'rarity',
      shortName: 'Negev',
      skin: 'dev-texture',
      exterior: 'Factory New',
      statTrak: true,
      steamPrice: 100,
    },
    status: OrderStatusEnum.WAIT_FOR_CONFIRM,
    statusAt: '',
    nextStatusAt: '2022-10-20T19:38:00.838Z',
    isUserSeller: false,
    isLastBidByUser: true,
    price: 101,
    express: false,
    stickers: [],
    recommendedPrice: 99,
    sellerPrice: 100,
    steamInspectLink: '',
    isCrossFast: false,
  },

  {
    isActive: true,
    id: 5,
    baseItem: {
      name: 'Item name',
      icon: 'https://steamcommunity-a.akamaihd.net/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgposbaqKAxf0Ob3djFN79eJh46KlPz1J6_QlWBu5Mx2gv3--Y3nj1H6-kdpN2H1J4GUdlBsYQ6E_Ae5x-i605S06p7PynswunV0sSqJmhW2iQYMMLKO7TPsAg',
      color: '',
      weapon: 'weapon name',
      type: 'type',
      rarity: 'rarity',
      shortName: 'Negev',
      skin: 'dev-texture',
      exterior: 'Factory New',
      statTrak: true,
      steamPrice: 100,
    },
    status: OrderStatusEnum.WAIT_FOR_TRADE,
    statusAt: '',
    nextStatusAt: '2022-10-30T19:38:00.838Z',
    isUserSeller: false,
    isLastBidByUser: true,
    price: 101,
    express: false,
    stickers: [],
    recommendedPrice: 99,
    sellerPrice: 100,
    steamInspectLink: '',
    isCrossFast: false,
  },

  {
    isActive: true,
    id: 6,
    baseItem: {
      name: 'Item name',
      icon: 'https://steamcommunity-a.akamaihd.net/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgposbaqKAxf0Ob3djFN79eJh46KlPz1J6_QlWBu5Mx2gv3--Y3nj1H6-kdpN2H1J4GUdlBsYQ6E_Ae5x-i605S06p7PynswunV0sSqJmhW2iQYMMLKO7TPsAg',
      color: '',
      weapon: 'weapon name',
      type: 'type',
      rarity: 'rarity',
      shortName: 'Negev',
      skin: 'dev-texture',
      exterior: 'Factory New',
      statTrak: true,
      steamPrice: 100,
    },
    status: OrderStatusEnum.CANCELED_BY_BUYER,
    statusAt: '',
    nextStatusAt: '2022-10-30T19:38:00.838Z',
    isUserSeller: false,
    isLastBidByUser: true,
    price: 101,
    express: false,
    stickers: [],
    recommendedPrice: 99,
    sellerPrice: 100,
    steamInspectLink: '',
    isCrossFast: false,
  },

  {
    isActive: true,
    id: 7,
    baseItem: {
      name: 'Item name',
      icon: 'https://steamcommunity-a.akamaihd.net/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgposbaqKAxf0Ob3djFN79eJh46KlPz1J6_QlWBu5Mx2gv3--Y3nj1H6-kdpN2H1J4GUdlBsYQ6E_Ae5x-i605S06p7PynswunV0sSqJmhW2iQYMMLKO7TPsAg',
      color: '',
      weapon: 'weapon name',
      type: 'type',
      rarity: 'rarity',
      shortName: 'Negev',
      skin: 'dev-texture',
      exterior: 'Factory New',
      statTrak: true,
      steamPrice: 100,
    },
    status: OrderStatusEnum.AUCTION_FINISHED,
    statusAt: '',
    nextStatusAt: '2022-10-30T19:38:00.838Z',
    isUserSeller: false,
    isLastBidByUser: true,
    price: 101,
    express: false,
    stickers: [],
    recommendedPrice: 99,
    sellerPrice: 100,
    steamInspectLink: '',
    isCrossFast: false,
  },
  {
    isActive: true,
    id: 8,
    baseItem: {
      name: 'Item name',
      icon: 'https://steamcommunity-a.akamaihd.net/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgposbaqKAxf0Ob3djFN79eJh46KlPz1J6_QlWBu5Mx2gv3--Y3nj1H6-kdpN2H1J4GUdlBsYQ6E_Ae5x-i605S06p7PynswunV0sSqJmhW2iQYMMLKO7TPsAg',
      color: '',
      weapon: 'weapon name',
      type: 'type',
      rarity: 'rarity',
      shortName: 'Negev',
      skin: 'dev-texture',
      exterior: 'Factory New',
      statTrak: true,
      steamPrice: 100,
    },
    status: OrderStatusEnum.COMPLETED,
    statusAt: '',
    nextStatusAt: '2022-10-30T19:38:00.838Z',
    isUserSeller: false,
    isLastBidByUser: true,
    price: 101,
    express: false,
    stickers: [],
    recommendedPrice: 99,
    sellerPrice: 100,
    steamInspectLink: '',
    isCrossFast: false,
  },
];
export interface P2pBuyingStateModel {
  purchasing: IP2pPurchaseItem[];
  showWarn: boolean;
}
export const P2P_BUYING_INITIAL_STATE: P2pBuyingStateModel = {
  purchasing: [],
  // purchasing: BYING_INIT_ITEM,
  showWarn: true,
};
