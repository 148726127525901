<ng-container *ngIf="eventName && totalProgress && totalProgress.total">
  <div
    class="progress-block"
    *ngIf="totalProgress !== null && !reward && eventName === 'ny'"
    [class.disabled]="isEventComplete"
  >
    <div>
      <img
        src="/assets/img/ny-event/box6.jpg"
        alt=""
      />
    </div>
    <div
      style="font-size: 22px; font-weight: 600; text-align: center"
      [innerHtml]="'GOFAST.NY_EVENT.SEARCH_EVENT_SUPERPRIZE' | translate"
    ></div>
    <fast-ui-ny-event-progress-bar
      *ngIf="totalProgress.percent > 0"
      class="progress-block--progress-bar"
      [progress]="totalProgress.percent"
      [eventName]="eventName"
    ></fast-ui-ny-event-progress-bar>
    <div style="font-size: 12px; font-weight: 400; color: #888b8e; text-transform: uppercase">
      {{ 'GOFAST.NY_EVENT.SEARCH_EVENT_SUPERPRIZE_COUNTER' | translate }}: {{ totalProgress.found }}/{{ totalProgress.total }}
    </div>
  </div>

  <div
    class="chinese-new-year progress-block-component"
    *ngIf="totalProgress !== null && !reward && eventName === 'ny_china'"
    [class.disabled]="isEventComplete"
  >
    <div class="gift-card--container available">
      <div class="gift-card--default-card">
        <div class="letter-cap"></div>
        <div class="gift-number-container">
          <div style="position: relative">
            <svg>
              <circle
                cx="50%"
                cy="50%"
                r="2rem"
                fill="none"
                stroke="rgba(176,25,51,0.7)"
                stroke-width="5"
              />
              <circle
                class="percent fifty"
                cx="50%"
                cy="50%"
                r="2rem"
                fill="none"
                stroke="#91142a"
                stroke-width="5"
                pathLength="100"
                [style.stroke-dashoffset]="100 + totalProgress.percent"
              />
            </svg>
            <span class="gift-number">{{ totalProgress.found }}/{{ totalProgress.total }}</span>
          </div>
        </div>

        <div class="gift-location">
          <div
            style="font-size: 1.125rem; font-weight: 600"
            [innerHtml]="'GOFAST.NY_EVENT.SEARCH_EVENT_SUPERPRIZE' | translate"
          ></div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="chinese-new-year progress-block-component"
    *ngIf="reward && !reward.caseRevisionResult && eventName === 'ny_china'"
  >
    <div class="gift-card--container available">
      <div class="gift-card--default-card">
        <div class="letter-cap"></div>

        <div
          class="btn btn-primary gift-open"
          (click)="pickupReward(reward)"
        >
          <span>{{ 'GOFAST.NY_EVENT.OPEN_CASE' | translate | uppercase }}</span>
        </div>
        <span
          class="gift-card--strong-text"
          style="text-align: center; width: 60%"
          >{{ reward.case?.name }}</span
        >
      </div>
    </div>
  </div>
  <div
    class="progress-block prize-available"
    *ngIf="reward && !reward.caseRevisionResult && eventName === 'ny'"
  >
    <div class="prize-available--greetings">
      <div class="prize-available--greetings-title">{{ 'GOFAST.NY_EVENT.CONGRATULATIONS' | translate }}</div>
      <div class="prize-available--greetings-info">
        {{ 'GOFAST.NY_EVENT.CONGRATULATIONS_TEXT1' | translate }} <br />
        {{ 'GOFAST.NY_EVENT.CONGRATULATIONS_TEXT2' | translate }}
      </div>

      <div class="prize-available--case w-medium">
        <div class="prize-available--case-title">{{ reward.case?.name }}</div>
        <button
          class="btn btn-primary"
          (click)="pickupReward(reward)"
        >
          {{ 'GOFAST.NY_EVENT.OPEN_CASE' | translate }}
        </button>
      </div>
    </div>
    <div style="flex: 1">
      <div
        class="prize-available--image replay-img"
        (click)="pickupReward(reward)"
      >
        <img
          style="max-width: 80%"
          class="skin-avatar"
          [src]="reward.case?.backgroundImage ? reward.case?.backgroundImage : '/assets/img/ny-event/box6.jpg'"
         alt=""/>
      </div>
    </div>
    <div class="prize-available--case w-medium-n">
      <div class="prize-available--case-title">{{ reward.case?.name }}</div>
      <button
        class="btn btn-primary"
        (click)="pickupReward(reward)"
      >
        {{ 'GOFAST.NY_EVENT.OPEN_CASE' | translate }}
      </button>
    </div>
  </div>
  <div
    *ngIf="reward && reward.caseRevisionResult"
    class="progress-block prize-available"
    style="background: #12181d"
  >
    <div class="prize-available--greetings">
      <div class="prize-available--greetings-title">{{ 'GOFAST.NY_EVENT.CONGRATULATIONS' | translate }}</div>
      <div
        class="replay-img w-medium"
        style="width: 177px; height: 120px; margin-bottom: 1rem"
        [routerLink]="'/game/cases/case/item'"
        [queryParams]="{
          skin: reward.caseRevisionResult.caseRevisionItem.inventoryItem.baseItem.skin,
          shortName: reward.caseRevisionResult.caseRevisionItem.inventoryItem.baseItem.shortName
        }"
      >
        <img
          [src]="reward.caseRevisionResult.caseRevisionItem.inventoryItem.baseItem.icon"
          style="max-width: 80%"
         alt=""/>
      </div>

      <div class="prize-available--greetings-info">
       <span>
        {{ 'GOFAST.NY_EVENT.YOUR_WINNING' | translate }}:
       </span>
        <span style="color: var(--color-primary)">
          {{ reward.caseRevisionResult.caseRevisionItem.inventoryItem.price | appCurrency }}
        </span>
      </div>
    </div>
    <div
      class="w-medium"
      style="flex: 0.2; text-align: center"
    >
      <mat-icon
        [routerLink]="'/game/cases/case/item'"
        [queryParams]="{
          skin: reward.caseRevisionResult.caseRevisionItem.inventoryItem.baseItem.skin,
          shortName: reward.caseRevisionResult.caseRevisionItem.inventoryItem.baseItem.shortName
        }"
        class="replay-arrow"
        [svgIcon]="'play'"
      ></mat-icon>
    </div>
    <div style="flex: 1">
      <div
        class="prize-available--image replay-img"
        [routerLink]="'/game/cases/case/' + reward.caseRevisionResult.case.id"
      >
        <img
          style="max-width: 80%"
          class="skin-avatar"
          [src]="
            reward.caseRevisionResult.case.backgroundImage
              ? reward.caseRevisionResult.case.backgroundImage
              : '/assets/img/ny-event/box6.jpg'
          "
          lazyLoadImage
         alt=""/>
      </div>
    </div>

    <div class="prize-available--case w-medium-n">
      <div
        class="replay-img"
        style="width: 177px; height: 120px; margin-bottom: 1rem"
        [routerLink]="'/game/cases/case/item'"
        [queryParams]="{
          skin: reward.caseRevisionResult.caseRevisionItem.inventoryItem.baseItem.skin,
          shortName: reward.caseRevisionResult.caseRevisionItem.inventoryItem.baseItem.shortName
        }"
      >
        <img
          [src]="reward.caseRevisionResult.caseRevisionItem.inventoryItem.baseItem.icon"
          style="max-width: 80%"
         alt=""/>
      </div>
      <button
        class="btn btn-outline"
        [routerLink]="['/game/cases/case/', reward.caseRevisionResult.case.id]"
        [queryParams]="{ openUUID: reward.caseRevisionResult.openUUID }"
      >
        <mat-icon
          class="replay-arrow"
          [svgIcon]="'play'"
        ></mat-icon>
        <span>{{ 'GOFAST.CASES.SPIN_GAME.BUTTONS.REPLAY' | translate }}</span>
      </button>
    </div>
  </div>
</ng-container>
