import {ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, NgModule, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {headerSettingsAnimation, settingsAnimation} from './settings-menu.animation';
import {ICurrencyRate, ILocales, IUserDetailed, Themes} from '@dev-fast/types';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {UserAvatarModule} from '../user-avatar';

@Component({
  selector: 'fast-ui-settings-menu',
  templateUrl: './settings-menu.component.html',
  styleUrls: ['./settings-menu.component.scss'],
  animations: [headerSettingsAnimation, settingsAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsMenuComponent {
  @HostBinding('@headerSettingsAnimation') private animation = true;
  //TODO заюзать
  // @Input() public links: Themes;

  @Input() public soundState: boolean;
  @Input() public user: IUserDetailed | null;

  @Input() public currentTheme: Themes | undefined;
  @Input() public themeList: Themes[];

  @Input() public currentLang: ILocales | undefined;
  @Input() public langList: ILocales[];

  @Input() public currentCurrency: ICurrencyRate | undefined;
  @Input() public currencyList: ICurrencyRate[];

  @Output() public closeClick: EventEmitter<void>;
  @Output() public changeTheme: EventEmitter<Themes>;
  @Output() public changeLang: EventEmitter<ILocales>;
  @Output() public changeCurrency: EventEmitter<ICurrencyRate>;

  @Output() public login: EventEmitter<void>;
  @Output() public logout: EventEmitter<void>;

  public selectedSetting: string;
  public isSettingsShowed: boolean;

  constructor() {
    this.themeList = [];
    this.langList = [];
    this.currencyList = [];
    this.closeClick = new EventEmitter(false);
    this.changeTheme = new EventEmitter(false);
    this.changeLang = new EventEmitter(false);
    this.changeCurrency = new EventEmitter(false);
    this.login = new EventEmitter(false);
    this.logout = new EventEmitter(false);
    this.selectedSetting = '';
    this.isSettingsShowed = false;
    this.soundState = false;
    this.user = null;
  }

  public selectSetting = (setting?: string): void => {
    if (!setting || this.selectedSetting === setting) {
      this.selectedSetting = '';
    } else {
      this.selectedSetting = setting;
    }
  };

  public onOverlayClick = (): void => {
    this.closeClick.emit();
    this.toggleSettingsComponent();
  };

  public onTogleSound = (): void => {
    this.soundState = !this.soundState;
    // this.toggleSettingsComponent();
  };

  public selectLocale = (locale: ILocales): void => {
    this.changeLang.next(locale);
    this.selectSetting();
  };

  public selectCurrency = (currency: ICurrencyRate): void => {
    // this.currencyService.changeCurrency(key);
    this.changeCurrency.next(currency);
    this.selectSetting();
  };

  public selectTheme = (theme: Themes): void => {
    this.changeTheme.next(theme);
    this.selectSetting();
  };

  public toggleSettingsComponent = () => {
    this.isSettingsShowed = !this.isSettingsShowed;
  };

  public onLogin = () => {
    this.login.emit();
    this.toggleSettingsComponent();
  };

  public onLogout = () => {
    this.logout.emit();
    this.toggleSettingsComponent();
  };
}

@NgModule({
  imports: [CommonModule, MatIconModule, RouterModule, TranslateModule, UserAvatarModule],
  declarations: [SettingsMenuComponent],
  exports: [SettingsMenuComponent],
})
export class SettingsMenuComponentModule {}
