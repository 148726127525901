import { SEO_INITIAL_STATE, SeoStateModel } from "./seo.state.model";
import { Action, NgxsOnInit, Selector, State, StateContext } from "@ngxs/store";
import { Injectable } from "@angular/core";
import {SetDescription, SetFaq, SetH1, SetMain, SetTitle} from "./seo.action";

@State<SeoStateModel>({
  name: 'seo',
  defaults: SEO_INITIAL_STATE,
})
@Injectable()
export class SeoState implements NgxsOnInit {
  @Selector()
  public static h1({ h1 }: SeoStateModel): {value: string, data: any} {
    return h1;
  }

  @Selector()
  public static main({ main }: SeoStateModel): {value: string, data: any}  {
    return main;
  }

  @Selector()
  public static faq({ faq }: SeoStateModel): {value: string, data: any}  {
    return faq;
  }

  @Selector()
  public static title({ title }: SeoStateModel): {value: string, data: any} {
    return title;
  }

  @Selector()
  public static description({ description }: SeoStateModel): {value: string, data: any}  {
    return description;
  }

  public ngxsOnInit({ getState, dispatch, patchState }: StateContext<SeoStateModel>) {}

  @Action(SetH1)
  public setH1({ patchState }: StateContext<SeoStateModel>, { h1 }: SetH1) {
    patchState({h1});
  }

  @Action(SetMain)
  public setMain({ patchState }: StateContext<SeoStateModel>, { main }: SetMain) {
    patchState({main});
  }

  @Action(SetFaq)
  public setFaq({ patchState }: StateContext<SeoStateModel>, { faq }: SetFaq) {
    patchState({faq});
  }

  @Action(SetDescription)
  public setDescription({ patchState }: StateContext<SeoStateModel>, { description }: SetDescription) {
    patchState({description});
  }

  @Action(SetTitle)
  public setTitle({ patchState }: StateContext<SeoStateModel>, { title }: SetTitle) {
    patchState({title});
  }
}
