import {IExperience, IExperienceLeader, IExperienceStatistic, IGameStatistics, ILevel} from '@dev-fast/types';

export interface AccountStateModel {
  gameStatistics: IGameStatistics[];
  leaders: IExperienceLeader[];
  experience: IExperience[];
  statistics: IExperienceStatistic[];
  levels: ILevel[];
}

export const ACCOUNT_INITIAL_STATE: AccountStateModel = {
  gameStatistics: [],
  leaders: [],
  experience: [],
  statistics: [],
  levels: [],
};
