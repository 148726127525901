import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { ICasesQuestItem } from '@dev-fast/types';
import { Observable } from 'rxjs';
import { NyEventService } from '../../../core';


@Component({
  selector: 'csgofast-ny-reward-modal',
  templateUrl: './ny-reward-modal.component.html',
  styleUrls: ['./ny-reward-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // encapsulation: ViewEncapsulation.None,
})
export class NyRewardModalComponent {
  public reward$!: Observable<ICasesQuestItem | null>;

  constructor(private readonly nyEventService: NyEventService) {
    this.reward$ = this.nyEventService.reward$;
  }
  public onOpenCase(item: ICasesQuestItem): void {
    this.nyEventService.closeModal();
    this.nyEventService.pickUpPrize(item);
  }
 }
