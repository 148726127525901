import {ChangeDetectionStrategy, Component, Input, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {ExpandedAreaComponent} from './expanded-area/expanded-area.component';
import {GameItemComponent} from './game-item/game-item.component';
import {GameMode, IGame} from '@dev-fast/types';
import {RouterModule} from '@angular/router';
// FIXME заменить на ngx-scrollbar (уже установлен на проекте)
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';

@Component({
  selector: 'fast-ui-game-selector',
  templateUrl: './game-selector.component.html',
  styleUrls: ['./game-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GameSelectorComponent {
  //FIXME в стейт или сервис по хорошему
  @Input() public set availableGames(payload: IGame[] | null) {
    if (!payload) return;
    this.gamesDict = {};
    payload.forEach((game) => {
      if (this.gamesDict[game.mode]) {
        this.gamesDict[game.mode]?.push(game);
      } else {
        this.gamesDict[game.mode] = [game];
      }
    });
    // this.modes = (Object.keys(this.gamesDict) as GameMode[]).sort((a: GameMode, b: GameMode) => {
    //   if (a === GameMode.PVP) return -1;
    //   if (b === GameMode.PVP) return 0;
    //   if (a < b) return -1;
    //   if (a > b) return 1;
    //   return 0;
    // });
  }
  public gamesDict: Partial<Record<GameMode, IGame[]>>;
  public isListShowed = false;
  constructor() {
    this.gamesDict = {};
  }

  public toggleList = () => {
    this.isListShowed = !this.isListShowed;
  };
}

@NgModule({
  imports: [CommonModule, MatIconModule, RouterModule, PerfectScrollbarModule],
  declarations: [GameSelectorComponent, GameItemComponent, ExpandedAreaComponent],
  exports: [GameSelectorComponent],
})
export class GameSelectorComponentModule {}
