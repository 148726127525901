import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {GameStatus, IGame} from '@dev-fast/types';
import {filter, Observable} from 'rxjs';
import {LEFT_SIDEBAR_PROVIDERS, STATUSES} from './desktop-left-sidebar.providers';
import {DesktopLeftSidebarService} from './desktop-left-sidebar.service';

@Component({
  selector: 'csgofast-desktop-left-sidebar',
  templateUrl: './desktop-left-sidebar.component.html',
  styleUrls: ['./desktop-left-sidebar.component.scss'],
  providers: [LEFT_SIDEBAR_PROVIDERS],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesktopLeftSidebarComponent {
  public online$: Observable<number>;
  public gameSelectorOpened$: Observable<boolean>;
  public availableGames$: Observable<IGame[]>;
  public statuses$: Observable<Record<string, GameStatus>>;

  constructor(
    @Inject(STATUSES) private readonly statusesProvider$: Observable<Record<string, GameStatus>>,
    private readonly moduleService: DesktopLeftSidebarService
  ) {
    this.online$ = this.moduleService.online$;
    this.gameSelectorOpened$ = this.moduleService.gameSelectorOpened$;
    this.availableGames$ = this.moduleService.availableGames$;
    this.statuses$ = this.statusesProvider$.pipe(filter((val) => !!val));
  }

  public toggleFullView(active: boolean) {
    this.moduleService.toggle(active);
  }
}
