import { PortalModule } from "@angular/cdk/portal";
import { ModuleWithProviders, NgModule, TypeProvider } from '@angular/core';
import { CommonModule } from '@angular/common';
// FIXME заменить на ngx-scrollbar (уже установлен на проекте)
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { RecentActivitiesComponent } from './recent-activities.component';
import { RECENT_ACTIVITIES_ENGINE } from './symbols';
import { RecentActivitiesService } from './recent-activities.service';
import { MotivatorWidgetModule } from './components/motivator-widget/motivator-widget.module';
import { DesktopRightsidebarService } from "@csgofast/desktop-app/right-sidebar";

@NgModule({
  declarations: [RecentActivitiesComponent],
  providers: [DesktopRightsidebarService],
    imports: [CommonModule, MotivatorWidgetModule, PerfectScrollbarModule, PortalModule],
  exports: [RecentActivitiesComponent],
})
/** Shared activities */
export class RecentActivitiesWidgetsModule {
  static forRoot(engine?: TypeProvider): ModuleWithProviders<RecentActivitiesWidgetsModule> {
    return {
      ngModule: RecentActivitiesWidgetsModule,
      providers: [
        {
          provide: RECENT_ACTIVITIES_ENGINE,
          useClass: engine ? engine : RecentActivitiesService,
        },
      ],
    };
  }

  static forChild(engine?: TypeProvider): ModuleWithProviders<RecentActivitiesWidgetsModule> {
    return {
      ngModule: RecentActivitiesWidgetsModule,
      providers: [
        {
          provide: RECENT_ACTIVITIES_ENGINE,
          useClass: engine ? engine : RecentActivitiesService,
        },
      ],
    };
  }
}
