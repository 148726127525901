import { Inject, Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OnlyServerGuard implements CanActivate {
  constructor(
    @Inject('isServer') private isServer: boolean,
    private readonly router: Router) {
  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(this.isServer) return true;
    else return this.router.navigate(['not-found']);
  }
}
