import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Environments, EnvironmentService } from '@csgofast/core/environment-service';
import { ApiService } from '../../../api.service';
import {
  ICaseBattleCreateNew,
  ICaseBattleDto,
  ICaseBattleGameCreateNotify,
  ICaseBattleGameCreateDto,
  ICaseBattleGameJoinNotify,
  ICaseBattleStartDto,
  ICaseBattleUserJoinDto,
  ICaseItemDtoV2,
  ICaseBattleOneGame,
  ICaseBattleFilter,
  ICaseBattleGameFinishDto,
  ICaseBattleRoundDto,
  ICasesFilter,
  ICaseBattleHistory, SocketResponse, IBattleEmojiNoty, IBattleEmojiSocketMessage, IBattleEndEmojisNoty,
} from '@dev-fast/types';
import { paramsToQuery } from '@csgofast/shared/utils';
import { Socket } from 'ngx-socket-io';

@Injectable({
  providedIn: 'root',
})
export class CaseBattleBackendService {
  private environment: Environments;

  constructor(
    private readonly api: ApiService,
    private readonly environmentService: EnvironmentService,
    private readonly ws: Socket
  ) {
    this.environment = this.environmentService.environments;
  }


  public getBattleGames = (me: boolean, params?: { filters: ICaseBattleFilter, offset: number, limit: number }): Observable<ICaseBattleDto> => {
    return this.api.get(`${this.environment.GATEWAY_URL}/api/cases/battles${paramsToQuery({ me: [me], ...params?.filters, limit: params?.limit, offset: params?.offset })}`, { withCredentials: true });
  };

  public getCaseBattleById = (battleId: number): Observable<ICaseBattleOneGame> => {
    return this.api.get(`${this.environment.GATEWAY_URL}/api/cases/battles/${battleId}`, {
      withCredentials: true,
    });
  };

  public getAllCasesForRenderingInGames = (): Observable<ICaseItemDtoV2[]> => {
    return this.api.get(`${this.environment.GATEWAY_URL}/api/cases/suitcases?battles=true&detailed=true`);
  };

  public getAllCasesForBattleCreate = (casesFilter: ICasesFilter | null): Observable<ICaseItemDtoV2[]> => {
    return this.api.get(`${this.environment.GATEWAY_URL}/api/cases/suitcases`, { params: { ...casesFilter, battles: true } });
  };
  public getCasesByIds = (ids: number[]): Observable<ICaseItemDtoV2[]> => {
    return this.api.get(`${this.environment.GATEWAY_URL}/api/cases/suitcases/many/${ids.join('%2C%20')}`);
  };
  public createNewCaseBattle = (newBattleInfo: ICaseBattleCreateNew): Observable<ICaseBattleGameCreateNotify> => {
    const params = { ...newBattleInfo, double: newBattleInfo.settings.double, settings: { winnersSplit: newBattleInfo.settings.winnersSplit } }
    return this.api.post(`${this.environment.GATEWAY_URL}/api/cases/battles/create`, params, { withCredentials: true });
  }
  public joinCaseBattle = (payload: { battleId: number, teamNumber?: number }): Observable<ICaseBattleGameJoinNotify> =>
    this.api.post(`${this.environment.GATEWAY_URL}/api/cases/battles/${payload.battleId}/join`, { team: payload.teamNumber }, { withCredentials: true });

  public addBot = (payload: { battleId: number, teamNumber?: number }): Observable<ICaseBattleGameJoinNotify> =>
    this.api.post(`${this.environment.GATEWAY_URL}/api/cases/battles/${payload.battleId}/join/bot`, { team: payload.teamNumber }, { withCredentials: true });

  public getCaseBattleHistory = (offset: number, limit: number = 20): Observable<ICaseBattleHistory> => {
    return this.api.get(`${this.environment.GATEWAY_URL}/api/cases/battles/history${paramsToQuery({ sortBy: '-created_at', user: true, offset: offset, limit: limit })}`,
      { withCredentials: true });
  };
  public setEmoji = (payload: { battleId: number, emojiId: number }): Observable<IBattleEmojiNoty> => {
    return this.api.post(`${this.environment.GATEWAY_URL}/api/cases/battles/${payload.battleId}/emoji`, { emojiId: payload.emojiId }, { withCredentials: true });
  };
  public onCreateBattle(): Observable<SocketResponse<ICaseBattleGameCreateDto>> {
    return this.ws.fromEvent<ICaseBattleGameCreateDto>('battle:new').pipe(map(value => ({ payload: value, name: 'new' })));
  }

  public onStartBattle(): Observable<SocketResponse<ICaseBattleStartDto>> {
    return this.ws.fromEvent<ICaseBattleStartDto>('battle:start').pipe(map(value => ({ payload: value, name: 'start' })));
  }

  public onJoinBattle(): Observable<SocketResponse<ICaseBattleUserJoinDto>> {
    return this.ws.fromEvent<ICaseBattleUserJoinDto>('battle:join').pipe(map(value => ({ payload: value, name: 'join' })));
  }

  public onEndBattleRound(): Observable<SocketResponse<ICaseBattleRoundDto[]>> {
    return this.ws.fromEvent<ICaseBattleRoundDto[]>('battle:roll').pipe(map(value => ({ payload: value, name: 'roll' })));
  }

  public onEndBattle(): Observable<SocketResponse<ICaseBattleGameFinishDto>> {
    return this.ws.fromEvent<ICaseBattleGameFinishDto>('battle:finish').pipe(map(value => ({ payload: value, name: 'finish' })));
  }
  public onEmodjiGet(): Observable<SocketResponse<IBattleEmojiSocketMessage>> {
    return this.ws.fromEvent<IBattleEmojiSocketMessage>('battle:new-emoji').pipe(map(value => ({ payload: value, name: 'emoji' })));
  }
  public onFinalyEmodjisGet(): Observable<SocketResponse<IBattleEndEmojisNoty>> {
    return this.ws.fromEvent<IBattleEndEmojisNoty>('battle:emoji').pipe(map(value => ({ payload: value, name: 'finaly-emojis' })));
  }
}
