import {GameStatusesFF, IGame} from '@dev-fast/types';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Dispatch} from '@ngxs-labs/dispatch-decorator';
import {Select} from '@ngxs/store';
import {GamesState} from '@csgofast/core/state/games-store';
import {LayoutState, ToggleGameSelector} from '@csgofast/core/state/layout';

@Injectable()
export class DesktopLeftSidebarService {
  @Select(GamesState.online)
  public readonly online$!: Observable<number>;
  @Select(GamesState.statuses)
  public readonly statuses$!: Observable<GameStatusesFF>;
  @Select(GamesState.availableGames)
  public readonly availableGames$!: Observable<IGame[]>;
  @Select(LayoutState.gameSelectorOpened)
  public readonly gameSelectorOpened$!: Observable<boolean>;

  // constructor(){

  // }

  @Dispatch() public toggle = (active: boolean) => new ToggleGameSelector(active);
}
