import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HasPermissionDirective} from "./has-permission.directive";
import {HasPermissionService} from "./has-permission.service";



@NgModule({
  declarations: [HasPermissionDirective],
  imports: [
    CommonModule
  ],
  providers: [HasPermissionService],
  exports: [HasPermissionDirective]
})
export class HasPermissionModule { }
