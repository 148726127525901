export enum SortingCaseBattleTypes {
  FIRST_NEW = '-created_at',
  FIRST_OLD = 'created_at',
  FIRST_CHEAP = '-total_price',
  FIRST_EXPENSIVE = 'total_price',
}

export enum DefaulSortingTypes {
  DEFAULT = 'popular',
  MIN_PRICE = 'price',
  MAX_PRICE = '-price',
  MIN_NAME = 'name',
  MAX_NAME = '-name',
}

