<ng-container *ngIf="config">
  <div class="skins">
    <div
      class="item flex fx-align-center"
      (click)="onSkin()"
    >
      <div class="icon">
        <mat-icon
          class="menu-icon"
          svgIcon="automate-gun"
        ></mat-icon>
      </div>
      <div class="value">
        {{ config.countSkins }}
      </div>
    </div>
  </div>
  <div class="money">
    <div
      class="item flex fx-align-center"
      (click)="onCoin()"
    >
      <!-- <div class="icon">
        <mat-icon
          class="menu-icon"
          svgIcon="coin"
        ></mat-icon>
      </div> -->
      <!-- <div class="value"> -->
      {{ config.countCoins | appCurrency }}
      <!-- </div> -->
    </div>
  </div>
</ng-container>
