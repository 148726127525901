import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ICaseQuestMotivatorLastDto } from '@dev-fast/types';

@Component({
  selector: 'csgofast-ny-motivator',
  templateUrl: './motivator.component.html',
  styleUrls: ['./motivator.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NyMotivatorComponent {
  @Input() public motivatorList: ICaseQuestMotivatorLastDto[] | undefined | null;
  @Input() public eventName: string | undefined | null;

  get motivators() {
    return this.motivatorList ? this.motivatorList.slice(3) : [];
  }

  constructor() {
    //
  }
}
