import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'fast-ui-selection-input',
  templateUrl: './selection-input.component.html',
  styleUrls: ['./selection-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectionInputComponent implements OnInit {
  @Input() public minValue = 0;
  @Input() public maxValue = Number.MAX_SAFE_INTEGER;
  @Input() public step = 1;
  @Input() public className = '';
  @Input() public showPlaceholder = true;
  @Input() public iconName = '';
  @Input() public showButtons = true;
  @Input() public disabledInput = false;
  @Input() public set currentValue(value: number) {
    this.currentAmount = value;
    this.formControl = new FormControl<string>({ value: this.minValue.toString(), disabled: this.disabledInput } , [
      Validators.min(this.minValue),
      Validators.max(this.maxValue || Number.MAX_SAFE_INTEGER),
      Validators.required,
    ]);
    this.formControl?.setValue(value);
  }
  @Output() public valueChange: EventEmitter<number> = new EventEmitter();

  public currentAmount = 0;
  public formControl: FormControl | undefined;

  ngOnInit() {
    this.currentAmount = this.minValue;
  }
  public changePlayerAmount(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    if (value) {
      this.currentAmount = Number(value);
      this.formControl?.patchValue(value);
    }
  }
  public onAmountInput(event: KeyboardEvent): boolean {
    const reg = new RegExp(/(\d)/);
    return reg.test(String.fromCharCode(event.which));
  }
  public checkValue(): void {
    if (!this.currentAmount || (this.currentAmount && !this.formControl?.value)) {
      this.currentAmount = this.minValue;
      this.formControl?.setValue(this.minValue.toString());
    } else if (this.currentAmount > this.maxValue || this.currentAmount < this.minValue) {
      this.currentAmount = this.minValue;
      this.formControl?.setValue(this.minValue.toString());
    }
    if (this.currentAmount <= this.maxValue) {
      this.valueChange.emit(this.currentAmount);
    }
  }
  public placeholder(): string {
    if (this.showPlaceholder && this.maxValue) return `${this.minValue} - ${this.maxValue}`;
    return '';
  }
  public increment(): void {
    this.currentAmount = this.currentAmount + this.step;
    this.valueChange.emit(this.currentAmount);
  }
  public decrement(): void {
    if (this.currentAmount < this.minValue) {
      this.currentAmount = this.minValue;
    } else {
      this.currentAmount = this.currentAmount - this.step;
    }
    this.valueChange.emit(this.currentAmount);
  }
}
@NgModule({
  declarations: [SelectionInputComponent],
  imports: [CommonModule, MatIconModule, FormsModule, ReactiveFormsModule],
  exports: [SelectionInputComponent],
})
export class SelectionInputModule {}
