import { Injectable } from '@angular/core';
import { OpenModal } from '@csgofast/core/state/modals';
import {UserState} from "@csgofast/core/state/user-store";
import {
  ICaseCollection,
  ILRBalance,
  ILRLeaderboards,
  ILRQuest,
  ILRTop,
  IUserDetailed,
  ModalNames
} from '@dev-fast/types';
import {ActionsExecuting, actionsExecuting} from "@ngxs-labs/actions-executing";
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import {  Select } from '@ngxs/store';
import {  Observable } from 'rxjs';
import {
  GetCases,
  GetLeaders,
  GetQuests,
  GetTickets,
  NyAllEventState,
  ResetQuestsCases,
  TakeReward
} from '../states/ny-all';

@Injectable()
export class NyAllEventService {
  @Select(UserState.user)
  public readonly user$: Observable<IUserDetailed | null> | undefined;

  @Select(NyAllEventState.dailyTasks)
  readonly dailyTasks$!: Observable<ILRQuest[]>;
  @Select(NyAllEventState.weeklyTasks)
  readonly weeklyTasks$!: Observable<ILRQuest[]>;
  @Select(NyAllEventState.keys)
  readonly keys$!: Observable<ILRBalance | null>;
  @Select(NyAllEventState.cases)
  readonly cases$!: Observable<ICaseCollection>;
  @Select(NyAllEventState.untilDayTaskTime)
  readonly untilDayTaskTime$!: Observable<number>;
  @Select(NyAllEventState.untilWeeklyTaskTime)
  readonly untilWeeklyTaskTime$!: Observable<number>;
  @Select(NyAllEventState.topPlayers)
  readonly topPlayers$!: Observable<ILRTop[]>;
  @Select(NyAllEventState.leaderboards)
  readonly leaderboards$!: Observable<ILRLeaderboards[]>;

  @Select(actionsExecuting([GetCases]))
  public getCasesIsExecuting$!: Observable<ActionsExecuting>;
  // constructor(private readonly actions$: Actions) {
  // }

  // public purchaseCaseSuccess$ = this.actions$.pipe(
  //   ofActionSuccessful(PurchaseCaseSuccess),
  //   map((action: PurchaseCaseSuccess) => ({ ...action }))
  // );
  public initEvent = () => {
    this.getQuests();
    this.getTickets();
  };
  @Dispatch() public getCases = () => new GetCases();
  @Dispatch() public getLeaders = () => new GetLeaders();
  @Dispatch() public getQuests = () =>  new GetQuests();
  @Dispatch() public getTickets = () =>  new GetTickets();
  @Dispatch() public takeReward = (questId: number) => new TakeReward(questId);
  @Dispatch() public showEventTermsModal = () => new OpenModal(ModalNames.NY_EVENT_TERMS);
  // @Dispatch() public purchaseCase = (freeCaseId: number, ticketColor: TicketTypeEnum) => new PurchaseCase(freeCaseId, ticketColor);
  @Dispatch() public resetState = () => new ResetQuestsCases()
}
