import {IconConfig} from "./icon.model";

export const iconsLogos: IconConfig[] = [
  {
    name: 'cs1go.com-domain',
    path: 'assets/img/mat-icons/logos/domain/cs1go.com-domain.svg',
  },
  {
    name: 'cs1go.com-new-year-domain',
    path: 'assets/img/mat-icons/logos/domain/cs1go.com-new-year-domain.svg',
  },
  {
    name: 'cs2gofast.com-domain',
    path: 'assets/img/mat-icons/logos/domain/cs2gofast.com-domain.svg',
  },
  {
    name: 'csgetfast.com-domain',
    path: 'assets/img/mat-icons/logos/domain/csgetfast.com-domain.svg',
  },
  {
    name: 'csgocasino.ru',
    path: 'assets/img/mat-icons/logos/domain/csgocasino.ru.svg',
  },
  {
    name: 'csgofastcom-domain',
    path: 'assets/img/mat-icons/logos/domain/csgofast.com.svg',
  },
  {
    name: 'csgofast.ru',
    path: 'assets/img/mat-icons/logos/domain/csgofast.ru.svg',
  },
  {
    name: 'csgofast.tl',
    path: 'assets/img/mat-icons/logos/domain/csgofast.tl.svg',
  },
  {
    name: 'csgofast4.com',
    path: 'assets/img/mat-icons/logos/domain/csgofast4.com.svg',
  },
  {
    name: 'csgofastbackend.com',
    path: 'assets/img/mat-icons/logos/domain/csgofastbackend.com.svg',
  },
  {
    name: 'dapubg.com',
    path: 'assets/img/mat-icons/logos/domain/dapubg.com.svg',
  },
  {
    name: 'gojackpod.com',
    path: 'assets/img/mat-icons/logos/domain/gojackpod.com.svg',
  },
  {
    name: 'opdota2.com',
    path: 'assets/img/mat-icons/logos/domain/opdota2.com.svg',
  },
  {
    name: 'pubgfast.com',
    path: 'assets/img/mat-icons/logos/domain/pubgfast.com.svg',
  },
  {
    name: 'rucsgofast.ru',
    path: 'assets/img/mat-icons/logos/domain/rucsgofast.ru.svg',
  },
  {
    name: 'CSGO-mirror',
    path: 'assets/img/mat-icons/logos/mirror/csgo.svg',
  },
  {
    name: 'currency',
    path: 'assets/img/mat-icons/logos/mirror/currency.svg',
  },
  {
    name: 'dota2',
    path: 'assets/img/mat-icons/logos/mirror/dota2.svg',
  },
  {
    name: 'halloween',
    path: 'assets/img/mat-icons/logos/mirror/halloween.svg',
  },
  {
    name: 'NY',
    path: 'assets/img/mat-icons/logos/mirror/NY.svg',
  },
  {
    name: 'vgo',
    path: 'assets/img/mat-icons/logos/mirror/vgo.svg',
  },
];
