<ng-container *ngIf="formControl">
  <div class="amount-container" [ngClass]="{ invalid: formControl.invalid }">
    <button 
      *ngIf="showButtons" 
      class="btn amount-btn" 
      [disabled]="minValue >= currentAmount" 
      (click)="decrement()">
        -
    </button>
    <div class="input-wrapper">
      <label class="input-label" [for]="className">
          <mat-icon *ngIf="!!iconName" class="input-icon" [svgIcon]="iconName"></mat-icon>
          <input 
              autocomplete="off"
              type="text"
              class="input"
              [ngClass]="{ text_center: !showPlaceholder, hovered: !disabledInput }"
              [id]="className"
              [placeholder]="placeholder()" 
              [formControl]="formControl"
              [value]="currentAmount"
              (keypress)="onAmountInput($event)"
              (input)="changePlayerAmount($event)"
              (change)="checkValue()"/>
      </label>
    </div>
    <button 
      *ngIf="showButtons" class="btn amount-btn" 
      [disabled]="maxValue <= currentAmount" 
      (click)="increment()">
        +
    </button>
  </div>
</ng-container>
