import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { EventsHomeComponent } from './events-home.component';
import { TranslateModule } from '@ngx-translate/core';
import { CountdownComponentModule } from '@dev-fast/ui-components';

@NgModule({
  declarations: [EventsHomeComponent],
  imports: [CommonModule, CountdownComponentModule, TranslateModule.forChild(), RouterModule],
  exports: [EventsHomeComponent],
})
export class EventsHomeModule {}
