export type DocumentFull = Document & { msHidden?: boolean; webkitHidden?: boolean };

export function getHiddenKey(document: DocumentFull): { hidden: string; visibilityChange: string } {
  if (typeof document['msHidden'] !== 'undefined') {
    return {
      hidden: 'msHidden',
      visibilityChange: 'msvisibilitychange',
    };
  } else if (typeof document['webkitHidden'] !== 'undefined') {
    return {
      hidden: 'webkitHidden',
      visibilityChange: 'webkitvisibilitychange',
    };
  }
  return {
    hidden: 'hidden',
    visibilityChange: 'visibilitychange',
  };
}
