export enum Panel {
  CHAT = 'chat',
  NOTIFICATIONS = 'notify',
  INVENTORY = 'inventory',
  TRADES = 'trades',
  MOBILE_LANG = 'm_lang',
  EVENT_MENU = 'event_menu',
}
export enum SubPanel {
  NONE = 'none',
  EXHANGE = 'exchange',
  BET = 'bet',
}
