import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {GameMode, IGame} from '@dev-fast/types';

@Component({
  selector: 'fast-ui-expanded-area',
  templateUrl: './expanded-area.component.html',
  styleUrls: ['./expanded-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpandedAreaComponent implements OnInit {
  @Input()
  public itemsDictionary!: Partial<Record<GameMode, IGame[]>>;
  public modes: GameMode[] | undefined;

  public ngOnInit(): void {
    this.modes = Object.keys(this.itemsDictionary) as GameMode[];
  }
}
