import { Component, Input, OnInit } from '@angular/core';
import {configs, ICaseQuestMotivatorLastDto} from '@dev-fast/types';
import moment from 'moment';

@Component({
  selector: 'csgofast-ny-motivator-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
})
export class NyMotvatorItemComponent {
  @Input() public motivator: ICaseQuestMotivatorLastDto | undefined | null;

  public get date() {
    return this.motivator
      ? moment(this.motivator.date).format(moment(this.motivator.date).isSame(moment(), 'd') ? 'HH:mm:ss' : 'MMM D')
      : '';

  }
  constructor() {
 }
}
