import { DOCUMENT } from '@angular/common';
import {Inject, Injectable, Optional} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Environments, EnvironmentService } from '@csgofast/core/environment-service';
import { AuthType, ModalNames, NotificationStatus, NotificationType, SocialItem, SocialType } from '@dev-fast/types';
import { Observable } from 'rxjs';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AuthModalComponent } from './modals/auth-modal/auth-modal.component';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import {BindAccount, GetAuthorizationToken, Logout, OpenAuthModal, RemoveAccount} from './state/auth.actions';
// import { NotificationsService } from '@csgofast/notifications';
import { Select } from '@ngxs/store';
import { AuthState } from './state/auth.state';
import { FrameMessageTypes, IFrameMessageService } from '@csgofast/core/iframe';
import { CloseModal, OpenModal } from '@csgofast/core/state/modals';
import { NotificationsService } from '@csgofast/core/notification-service';
import { AnalyticsService } from '@csgofast/core/analytics-service';

import isWebview from 'is-ua-webview';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  @Select(AuthState.socials)
  public socials$!: Observable<SocialItem[]>;
  @Select(AuthState.unlinkedSocials)
  public unlinkedSocials$!: Observable<SocialItem[]>;
  @Select(AuthState.isAuth)
  public isAuth$!: Observable<boolean>;

  private authWindow: Window | undefined | null;
  private environment: Environments;
  private authWindowInterval: ReturnType<typeof setInterval> | undefined;
  private dialogRef: MatDialogRef<AuthModalComponent> | undefined;
  private window: Window | null;

  constructor(
    private readonly environmentService: EnvironmentService,
    private readonly notifyService: NotificationsService,
    public dialog: MatDialog,
    @Optional() private readonly frameMessageService: IFrameMessageService,
    private readonly analyticsService: AnalyticsService,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.window = this.document.defaultView;
    this.environment = this.environmentService.environments;
    if(this.frameMessageService){
      this.frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'openAuthWindow', (payload: { provider: SocialType }) =>
        this.onLogin(payload.provider)
      );
    }

    this.activatedRoute.queryParams.subscribe((params) => {
      if ((params['event'] && params['event'] === AuthType.Auth) || params['event'] === AuthType.Reg || params['event'] === AuthType.Bind) {
        const { event, code, p, success, error } = params;
        this.handleAuthResult({ data: { event, success, provider: p, code, source: 'WebView', error } });
      }
    });
  }
  public isWebview = (): boolean => {
    // return true;
    return isWebview(this.window ? this.window.navigator.userAgent : 'WebView');
  };
  public onLogin = (provider: SocialType): void => {
    // TODO всегда приходит null в оригинале
    // const token = this.userService.getToken();
    // const token = '';
    const authUrl = `${this.environment.GATEWAY_URL}/api/authorization/provider/${provider}${
      this.isWebview() ? '?from=' + window.location.host : ''
    }`;
    this.openAuthWindow(authUrl);
  };

  public onLogout = () => {
    this.logout();
    // this.analyticsService.resetDataLayer()
  };

  public onRemoveAccount = (account: string) => this.removeAccount(account);

  public bindProvider(provider: SocialType): void {
    const authUrl = `${this.environment.GATEWAY_URL}/api/providers/${provider}/bind${
      this.isWebview() ? '?from=' + window.location.host : ''
    }`;
    this.openAuthWindow(authUrl);
  }

  public openLoginProvidersModal = () => {
    // this.dialogRef = this.dialog.open(AuthModalComponent, { panelClass: 'auth-modal' });
    this.openAuthModal();
  };

  private openAuthWindow = (url: string): void => {
    // const isWebView = isWebview(this.window && this.window?.navigator.userAgent);

    if (this.authWindow) this.authWindow.close();
    const params = {
      width: 450,
      height: 600,
      resizable: 'yes',
      scrollbars: 'yes',
    };
    this.window && this.window.addEventListener('message', this.handleAuthResult, false);
    this.authWindow = this.window && this.window.open(url, 'socialAuth', this.paramsToString(params));

    this.authWindow && this.authWindow.focus();
    this.authWindowInterval = setInterval(() => {
      if (this.authWindow && this.authWindow.closed) {
        this.cleanAuthMessageListener();
        this.authWindowInterval && clearInterval(this.authWindowInterval);
      }
    }, 1000);
  };

  private paramsToString(params: Record<string, number | string>): string {
    return Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join(',');
  }

  private cleanAuthMessageListener(): void {
    this.window && this.window.removeEventListener('message', this.handleAuthResult);
  }

  private handleAuthResult = (message: {
    data: {
      source: string;
      success: boolean;
      code: string;
      event: AuthType;
      provider: SocialType;
      error: { message?: string; error: string; system: boolean };
    };
  }): void => {
    if (message.data.source === 'auth-server' || message.data.source === 'WebView') {
      this.cleanAuthMessageListener();
      const { success, code, event, provider } = message.data;
      // console.log({success:message.data.success, error:message.data.error, event: message.data.event, source: message.data.source})
      if (success) {
        // this.dialogRef?.close();
        this.closeAuthModal();
        if ([AuthType.Reg, AuthType.Auth].includes(event)) {
          // this.login(code);
          if (event === AuthType.Reg) {
            //TODO мож вынести в экшон где получаю юзера и там чекать по дате
            this.analyticsService.registrationEvent(provider);
          }
          this.window?.location.reload();
        } else if (event === AuthType.Bind) {
          this.bind();
        } else {
          console.warn('Unknown auth event:', event);
        }

      } else {
        const { error } = message.data;
        this.notifyService.addNotification({
          id: Date.now(),
          type: NotificationType.error,
          icon: 'warning',
          message: error ? error.message || error.error : 'Auth error. Try again later or write to support',
          createDate: Date.now(),
          status: NotificationStatus.new,
          system: error ? error.system : true,
        });
      }
      this.authWindow && this.authWindow.close();
    }
  };

  @Dispatch() private login = (code: string) => new GetAuthorizationToken(code);
  @Dispatch() private removeAccount = (account: string) => new RemoveAccount(account);
  @Dispatch() private logout = () => new Logout();
  @Dispatch() private bind = () => new BindAccount();
  // @Dispatch() private openAuthWindowAction = (url: string) => new OpenAuthWindow(url);
  @Dispatch() private openAuthModal = () => new OpenAuthModal();
  @Dispatch() private closeAuthModal = () => new CloseModal(ModalNames.AUTH);

  // @Dispatch() private getUser = () => new Init();
}
