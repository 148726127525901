import {IconConfig} from "./icon.model";

export const iconsEsport: IconConfig[] = [
  {
    name: 'attention',
    path: 'assets/img/mat-icons/esport/attention.svg',
  },
  {
    name: 'bet-divider',
    path: 'assets/img/mat-icons/esport/bet-divider.svg',
  },
  {
    name: 'close-bet-icon',
    path: 'assets/img/mat-icons/esport/close-bet-clover.svg',
  },
  {
    name: 'esport-live-stream-icon',
    path: 'assets/img/mat-icons/esport/esport-live-stream-clover.svg',
  },
  {
    name: 'score-block',
    path: 'assets/img/mat-icons/esport/score-block.svg',
  },
  {
    name: 'score-block-active',
    path: 'assets/img/mat-icons/esport/score-block-active.svg',
  },
  {
    name: 'trophy-cup',
    path: 'assets/img/mat-icons/esport/trophy-cup.svg',
  },
];
