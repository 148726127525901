import {ChangeDetectorRef, OnDestroy, Pipe, PipeTransform,} from '@angular/core';
import {Observable, Subscription} from 'rxjs';

@Pipe({ name: 'myAsync', pure: false })
export class CustomAsyncPipe implements PipeTransform, OnDestroy {
  private lastValue?: any;
  private observable?: Observable<any>;
  private subscription?: Subscription;

  constructor(private readonly ref: ChangeDetectorRef) {}

  ngOnDestroy() {
    this.dispose();
  }

  transform<T>(observable: Observable<T>): T {
    if (!this.observable) {
      this.observable = observable;
      this.subscription = observable.subscribe((value) => {
        this.lastValue = value;
        this.ref.markForCheck();
      });
      return this.lastValue;
    }

    if (observable !== this.observable) {
      this.dispose();
      return this.transform(observable);
    }

    return this.lastValue;
  }

  private dispose() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
    }
    this.observable = undefined;
    this.lastValue = undefined;
  }
}
