import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NyAllEventRoutingModule } from './ny-all-event-routing.module';
import { NyAllEventComponent } from './ny-all-event.component';
import { CasesCollectionComponentModule, CountdownComponentModule, NyEventProgressBarModule } from '@dev-fast/ui-components';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NyQuestCardComponent } from './components/ny-quest-card/ny-quest-card.component';
import { NyEventMenuComponent } from './components/ny-event--menu/ny-event-menu.component';
import { AppCurrencyModule, AppCurrencyTranslateModule, CustomAsyncPipeModule } from '@csgofast/shared/pipes';
import { NyEventBtnComponent } from './shared/ny-event-btn/ny-event-btn.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
  declarations: [NyAllEventComponent, NyQuestCardComponent, NyEventMenuComponent, NyEventBtnComponent],
  exports: [NyAllEventComponent, NyEventMenuComponent, NyEventBtnComponent],
  imports: [
    RouterModule,
    CommonModule,
    MatIconModule,
    NyAllEventRoutingModule,
    NyEventProgressBarModule,
    NgScrollbarModule,
    AppCurrencyModule,
    TranslateModule.forChild(),
    CountdownComponentModule,
    CasesCollectionComponentModule,
    AppCurrencyTranslateModule,
    CustomAsyncPipeModule,
    NgxSkeletonLoaderModule,

  ],
})
export class NyAllEventModule {}
