import {Portal} from "@angular/cdk/portal";
import {InjectionToken} from '@angular/core';
import {NavigationExtras, Params} from "@angular/router";
import {IActivities, IInputMessage, Widgets} from '@dev-fast/types';
import {Observable} from 'rxjs';

export const RECENT_ACTIVITIES_ENGINE = new InjectionToken('RECENT_ACTIVITIES_ENGINE');

export interface RecentActivitiesEngine {
  readonly activitiesWidgets$: Observable<Widgets[]>;
  readonly activities$: Observable<IActivities | null>;
  readonly customWidgetsPortal$: Observable<Portal<any> | undefined>;
  navigateTo(path: any[], queryParams?: Params | undefined, extras?: NavigationExtras | undefined): void;
}
