import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NyEventService } from '../core/services/ny-event.service';
import { iif, map, Observable, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NyEventGuard implements CanActivate {
  constructor(private readonly router: Router, private readonly nyEventService: NyEventService) {}
  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.nyEventService.eventName$.pipe(
      map((value) => value !== null),
      // (v => iif(() => v % 4 === 0, r$, x$))
      switchMap((value) =>
        !value
          ? this.nyEventService.eventHistoryName$.pipe(
              map((value) => value !== null),
              switchMap((value) => (value ? this.router.navigate(['events/leaders']) : this.router.navigate(['game/cases'])))
            )
          : of(value)
      )
      // switchMap((value) => iif(() => value, of(value), this.nyEventService.eventHistoryName$)),
    );
    // const isEventActive = diff(configs['ny']?.endEvent) > 0;
    // const isEventHiden = diff(configs['ny']?.hide) <= 0;
    // if(isEventHiden && !state.url.includes('events/leaders')){
    //   return this.router.navigate(['events/leaders'])
    // }
    // if (state.url.includes('ny-event') || state.url.includes('ny-all-event')) {
    //   if (isEventActive) {
    //     return true;
    //   } else {
    //     return this.router.navigate(['events/shop']);
    //   }
    // } else{
    //   if (isEventActive) {
    //     return this.router.navigate(['events/ny-event']);
    //   } else {
    //     return true
    //   }
    // }
    // if (state.url.includes('ny-event')) {
    //   return isEventActive ? true : this.router.navigate(['events/leaders']);
    // }
    // if (state.url.includes('ny-all-event')) {
    //   return isEventActive ? true : this.router.navigate(['events/shop']);
    // }
  }
}
