import {Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import {TranslateService} from "@ngx-translate/core";
import {Meta, Title} from '@angular/platform-browser';
import {LanguageService, MAIN_LANG} from "@csgofast/core/language-service";
import {Environments, EnvironmentService} from "@csgofast/core/environment-service";
import {Dispatch} from "@ngxs-labs/dispatch-decorator";
import {SetDescription, SetFaq, SetH1, SetMain, SetTitle} from "./seo.action";
import {Select, Store} from "@ngxs/store";
import {Observable} from "rxjs";
import {SeoState} from "./seo.state";

/**
 * Сервис для всего, что связано с маршрутами, ссылками, редиректами внутри проекта
 */
@Injectable({
  providedIn: 'root'
})
export class SeoService {
  environment: Environments;

  private readonly emptySeoData = {value: '', data: null};
  constructor(
    private translateService: TranslateService,
    private readonly environmentService: EnvironmentService,
    private router: Router,
    private title: Title,
    private meta: Meta,
    private readonly store: Store,
    @Inject('isServer') private readonly isServer: boolean,
    @Inject(DOCUMENT) private doc: Document
  ) {
    this.environment = this.environmentService.environments;

    this.meta.updateTag({ name: 'og:site_name', content: this.environment.HOSTNAME });
    this.meta.updateTag({name: 'og:image', content: `${this.environment.HOSTNAME}/og.image.png`});
    this.updateDescription('SEO.DESCRIPTION.DEFAULT', {hostName: this.environment.HOSTNAME});
    if(isServer) {
      this.setFacebookMeta();
      this.setYandexMeta();
      this.setGoogleMeta();
    }
  }

  @Select(SeoState.h1)
  public h1$!: Observable<{value: string, data: any}>;
  @Select(SeoState.main)
  public main$!: Observable<{value: string, data: any}>;
  @Select(SeoState.faq)
  public faq$!: Observable<{value: string, data: any}>;


  /**
   * Вставляет в head ссылку на альтернативный язык сайта
   */
  updateAlternateLinks(link: string): void {
    const alternates: NodeListOf<HTMLLinkElement> = this.doc.querySelectorAll('link[rel="alternate"]');
    if(!alternates.length) {
      [MAIN_LANG, ...Object.keys(LanguageService.getRouteLanguages())].forEach((lang, index) => {
        const el = document.createElement('link');
        el.rel= 'alternate';
        el.hreflang = lang;
        el.href = this.environment.HOSTNAME + (lang === MAIN_LANG ? '' : '/' + lang) + (link !== '/'? link : '');
        if (index === 0) {
          el.hreflang = 'x-default'
        }
        this.doc.head.appendChild(el);
      });
    } else {
      alternates.forEach(el => el.href = this.environment.HOSTNAME +  (el.hreflang === 'x-default' || el.hreflang === MAIN_LANG? '':'/' + el.hreflang) + (link !== '/'? link : ''));
    }
  }

  /**
   * Добавление метатега домена для фейсбука
   */
  setFacebookMeta(): void {
    const el = this.doc.createElement('meta');
    el.content = 'z0zu48idnk8ra1xmxvlzkwr70za3wi'
    el.name = 'facebook-domain-verification'
    this.doc.head.appendChild(el);
  }

  /**
   * Добавление метатега домена для фейсбука
   */
  setGoogleMeta(): void {
    const el = this.doc.createElement('meta');
    el.content = 'ktrCgruh4_bdILgHL6nns6rzZRAr0jztBG3LeYFTLK0'
    el.name = 'google-site-verification'
    this.doc.head.appendChild(el);
  }

  /**
   * Добавление метатега домена для фейсбука
   */
  setYandexMeta(): void {
    const el = this.doc.createElement('meta');
    el.content = '30f87b3d378673ba'
    el.name = 'yandex-verification'
    this.doc.head.appendChild(el);
  }

  /**
   * Установка значения в заголовке страницы
   */
  updateTitle(titleLocale: string | null = this.store.selectSnapshot(SeoState.title).value, data: {[key: string]: string} = this.store.selectSnapshot(SeoState.title).data) {
    if(titleLocale) {
      this.setTitle({value: titleLocale, data});
      this.translateService.stream(titleLocale, data).subscribe(title => {
        this.title.setTitle(title);
      })
    }

  }

  /**
   * обновления og:url
   */
  updateOgUrl(url: string) {
    this.meta.updateTag({ name: 'og:url', content: url });
  }

  /**
   * Обновление каноничной страницы
   */
  updateCanonicalUrl(url: string) {
    const canonical: HTMLLinkElement | null = this.doc.querySelector('link[rel="canonical"]');
    if (canonical) {
      canonical.href = url;
    } else {
      const el: HTMLLinkElement = this.doc.createElement('link');
      el.href = url;
      el.rel = 'canonical'
      this.doc.head.appendChild(el);
    }
  }

  /**
   * Обновление описания сайта
   */
  updateDescription(descLocale: string | null = this.store.selectSnapshot(SeoState.description).value, data: {[key: string]: string} = this.store.selectSnapshot(SeoState.description).data) {
    if (descLocale) {
      this.setDescription({value: descLocale, data});

      this.translateService.stream(descLocale, data).subscribe(desc => {
        this.meta.updateTag({ name: 'description', content: desc})
        this.meta.updateTag({ name: 'og:description', content: desc})
      })
    }
  }

  /**
   * Очищение всех сео-текстов текущей страницы
   * (используется в случае, когда текущая страница не важна для СЕО или уже имеет сео-текста хардкодом)
   */
  resetSeoTexts(): void {
    this.resetH1();
    this.resetMain();
    this.resetFaq();
  }

  /**
   * Очищение главного сео-текста текущей страницы
   * (используется в случае, когда текущая страница не важна для СЕО или уже имеет сео-текста хардкодом)
   */
  resetMain(): void {
    this.setMain(this.emptySeoData);
  }

  /**
   * Очищение дополнительного сео-текста текущей страницы
   * (используется в случае, когда текущая страница не важна для СЕО или уже имеет сео-текста хардкодом)
   */
  resetFaq(): void {
    this.setFaq(this.emptySeoData);
  }

  /**
   * Очищение h1 заголовка текущей страницы
   * (используется в случае, когда текущая страница не важна для СЕО или уже имеет сео-текста хардкодом)
   */
  resetH1(): void {
    this.setH1(this.emptySeoData);
  }

  /**
   * Помечает текущую страницу как недоступную для ботов
   * (используется для неважных для сео страниц)
   */
  setNoIndexMeta(): void {
    this.meta.updateTag({ name: 'robots', content: 'noindex'})
  }

  /**
   * Помечает текущую страницу как доступную для ботов
   * (используется для важных для сео страниц)
   */
  setIndexMeta(): void {
    this.meta.updateTag({ name: 'robots', content: 'all'})
  }

  @Dispatch() public setH1 = (h1: { value: string, data: any }) => new SetH1(h1);
  @Dispatch() public setMain = (main: { value: string, data: any }) => new SetMain(main);
  @Dispatch() public setFaq = (faq: { value: string, data: any }) => new SetFaq(faq);
  @Dispatch() public setTitle = (title: {value: string, data: any}) => new SetTitle(title);
  @Dispatch() public setDescription = (desc: {value: string, data: any}) => new SetDescription(desc);
}
