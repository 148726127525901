
import {ICaseItemDtoV2, ICaseQuestProgress, ICasesQuestItem} from "@dev-fast/types";

export class GetQuest {
  public static readonly type = '[Cases ny-event] Get Quest';
}
export class ResetQuest {
  public static readonly type = '[Cases ny-event] Reset Quest';
}
export class GetMotivator {
  public static readonly type = '[Cases ny-event] Get Motivator';
}
export class UpdateMotivator {
  public static readonly type = '[Cases ny-event] Update Motivator';
  constructor(public payload: any) {}
}
export class UpdateQuestItem {
  public static readonly type = '[Cases ny-event] Update Quest Item';
  constructor(public payload: ICaseQuestProgress) {}
}
export class PickUpPrize {
  public static readonly type = '[Cases ny-event] Pick Up Prize';
  constructor(public item:ICasesQuestItem) {}
}
export class OpenCongratsModal {
  public static readonly type = '[Cases ny-event] Open Congrats Modal';
  constructor(public prize: ICasesQuestItem ) {}
}

