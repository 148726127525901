import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { AppCurrencyModule, AppPercentModule } from '@csgofast/shared/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { IncreaseModule } from '../../increase/increase.component';
import { RouterModule } from '@angular/router';
import { SkinCardModule } from '../cards';
import { ICaseRevisionInfo } from '@dev-fast/types';
import { NgScrollbarModule } from 'ngx-scrollbar';

@Component({
  selector: 'fast-ui-skin-cards-list',
  templateUrl: './skin-cards-list.component.html',
  styleUrls: ['./skin-cards-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkinCardsListComponent {
  @Input() public caseRevision: ICaseRevisionInfo | undefined;
  @Input() public showTitle = true;
  @Input() public showRange = true;

}
@NgModule({
  imports: [CommonModule, MatIconModule, AppCurrencyModule, AppPercentModule, TranslateModule, IncreaseModule, RouterModule, SkinCardModule, NgScrollbarModule],
  declarations: [SkinCardsListComponent],
  exports: [SkinCardsListComponent],
})
export class SkinCardsListModule { }