import { Directive, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2 } from '@angular/core';

// FIXME не робит пока не вяснял
@Directive({
  selector: '[scrolled-to]',
})
export class ScrolledToDirective {
  @Input()
  public targetPoint: number | undefined;
  @Output()
  public scrolledBelow: EventEmitter<boolean>;

  @Input('scrolled-to')
  public styleClass = '';

  private scrolled = 0;

  constructor(private el: ElementRef<HTMLElement>, private renderer: Renderer2) {
    this.scrolledBelow = new EventEmitter(false);
  }
  private host = this.el.nativeElement;

  @HostListener('document:scroll', ['$event']) onScroll(e: any) {
    const elementBottom = this.el.nativeElement.offsetTop + this.el.nativeElement.offsetHeight;
    const elementTop = this.el.nativeElement.offsetTop;

    const scrollTop = e.target.scrollingElement.scrollTop;
    const scrollDown = scrollTop > this.scrolled;

    if (this.targetPoint) {
      if (scrollTop > this.targetPoint && scrollDown) {
        this.renderer.addClass(this.host, this.styleClass);
        this.scrolledBelow.emit(true);
      } else if (scrollTop < this.targetPoint) {
        this.renderer.removeClass(this.host, this.styleClass);
        this.scrolledBelow.emit(false);
      }
    } else {
      if (scrollTop > elementBottom && scrollDown) {
        this.renderer.addClass(this.host, this.styleClass);
        this.scrolledBelow.emit(true);
      } else if (scrollTop < elementBottom || !scrollDown) {
        this.renderer.removeClass(this.host, this.styleClass);
        // this.scrolledBelow.emit(false);
      }
    }
    this.scrolled = scrollTop;
  }
}
