<div class="skin-item-checked">
  <mat-icon
    class="skin-item-checked_icon"
    svgIcon="checked"
  ></mat-icon>
</div>
<div
  class="skin-item-timeout"
  *ngIf="countdown$ | async"
>
  <span *ngIf="seconds$ | async as seconds">{{ seconds }}s</span>
</div>
<img
  class="skin-item-image"
  skinItemImage
  [src]="icon"
 alt=""/>
<div class="skin-item-info">
  <div
    class="skin-item-name"
    *ngIf="name"
  >
    {{ name }}
  </div>
  <div
    class="skin-item-fullname"
    *ngIf="name && !['highlight', 'popup'].includes(hoverBehavior)"
  >
    {{ name }}
  </div>
  <div
    class="skin-item-price"
    *ngIf="price"
  >
    {{ price | appCurrency }}
  </div>
</div>
<div
  class="skin-item-popup"
  *ngIf="hoverBehavior === 'popup'"
  [ngClass]="{ 'skin-item-popup-fix': popupFix, 'skin-item-popup-show': showPopup }"
>
  <img
    class="skin-item-popup-image"
    skinItemImage
    [src]="icon"
   alt=""/>
  <div
    class="skin-item-popup-fullname"
    *ngIf="isPopupFullname"
  >
    {{ name }}
  </div>
</div>
