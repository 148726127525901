import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { colorDictionary, getMaxOfArray, getMinOfArray, toShortRevisionWear } from '@csgofast/shared/utils';
import { ICaseRevisionItem } from '@dev-fast/types';

@Component({
  selector: 'fast-ui-possible-card',
  templateUrl: './possible-card.component.html',
  styleUrls: ['./possible-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PossibleCardComponent {
  @Input() public index = 0;
  @Input() public set itemRevisions(items: ICaseRevisionItem[]) {
    this.revisions = items;
    this.countSum = items.reduce((a, b) => a + b.count, 0);
  }
  @Input() public tracksAmount = 1;
  @Input() public isHovered = true;
  @Output()
  public clickOnCard: EventEmitter<boolean> = new EventEmitter(false);

  public showRevisions = false;
  public countSum: number;
  public revisions: ICaseRevisionItem[];
  constructor() {
    this.revisions = [];
    this.countSum = 0;
  }

  public toggleDropdown(event: Event): void {
    event.stopPropagation();
    this.showRevisions = !this.showRevisions;
  }
  public onCard(): void {
    this.clickOnCard.emit(true);
  }
  public getStyle(itemRevisions: ICaseRevisionItem): { background: string } {
    const colorDTO = itemRevisions.inventoryItem.baseItem.color;
    const newColor = colorDictionary[colorDTO.toUpperCase()];
    return {
      background: `linear-gradient(180.57deg, #213137 0%, ${newColor} 100%)`,
    };
  }
  // public calcPercent(itemRevisions: ICaseRevisionItem[]): number {
  //   return (
  //     itemRevisions.reduce((sum, item) => {
  //       return sum + item.count;
  //     }, 0) / 1000
  //   );
  // }
  public getSkinPrice(itemRevisions: ICaseRevisionItem[]): { min?: number; max?: number; current: number } | null {
    if (itemRevisions.length === 1) {
      return itemRevisions[0].inventoryItem && itemRevisions[0].inventoryItem.price
        ? { current: itemRevisions[0].inventoryItem.price }
        : null;
    }
    if (itemRevisions.length > 1) {
      const arrPrices = itemRevisions.map((el) => el.inventoryItem.price).filter((val) => !!val);
      return { current: itemRevisions[0].inventoryItem.price, max: getMaxOfArray(arrPrices), min: getMinOfArray(arrPrices) };
    }
    return null;
  }
  public toShortRevisionWear(wear: string): string {
    return toShortRevisionWear(wear);
  }
}
