import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomAsyncPipe} from './custom-async.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [CustomAsyncPipe],
  exports: [CustomAsyncPipe],
  providers: [CustomAsyncPipe],
})
export class CustomAsyncPipeModule {}
