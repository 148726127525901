<div class="wrapper">
  <div class="header">
    {{ data.title | uppercase}}
  </div>
  <div class="dialog-message">
    <p >{{ data.message | translate}}</p>
  </div>
  <div >
    <button
      *ngIf="data.cancelText"
      class="btn btn-cancel btn-alert"
      (click)="cancel()"
    >
      {{ data.cancelText | translate}}
    </button>
    <button
      class="btn btn-primary"
      (click)="confirm()"
    >
      {{ data.confirmText |translate}}
    </button>
  </div>
</div>
  <button class="close-btn" (click)="cancel()"> <mat-icon
    class="close-icon"
    svgIcon="close"
  ></mat-icon></button>

