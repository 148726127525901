<div class="container">
  <button
    class="btn control-button btn-sm"
    (click)="toggleList()"
  >
    <ng-content #button></ng-content>
  </button>
  <!-- <div
    class="header-control-selected"
    @settingsAnimation
    *ngIf="isSettingsShowed"
    (click)="toggleSettingsComponent()"
  >
    <div class="header-control-selected-header">{{ 'HEADER.COMMON_SETTINGS' | translate }}</div>
    <div class="header-control-selected-icon">
      <mat-icon svgIcon="settings-selector"></mat-icon>
    </div>
  </div> -->
  <div
    class="overlay"
    (click)="toggleList()"
    *ngIf="isListShowed"
  >
    <div class="dropdown-header">
      <button
        class="btn control-button btn-sm"
        (click)="toggleList()"
      >
        <mat-icon
          class="close-btn"
          [svgIcon]="'close'"
        ></mat-icon>
      </button>
      <div class="title">
        {{ 'Игры' }}
      </div>
    </div>
    <fast-ui-expanded-area
      [itemsDictionary]="gamesDict"
      class="dropdown"
    >
    </fast-ui-expanded-area>
    <!-- <div class="header-settings-dropdown">dropdown</div> -->
    <!-- <div
      class="header-settings-overlay"
      (click)="toggleList()"
    ></div> -->
  </div>
</div>
