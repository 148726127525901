import {ModalNames} from '@dev-fast/types';

export interface ModalsStateModel {
  activeModal: ModalNames | null;
  lastClosedModal: ModalNames | null;
  payloadBeforeOpen: any | null;
  payloadAfterClose: any | null;
}

export const MODALS_INITIAL_STATE: ModalsStateModel = {
  activeModal: null,
  lastClosedModal: null,
  payloadBeforeOpen: null,
  payloadAfterClose: null,
};
