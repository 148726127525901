import {ChangeDetectionStrategy, Component, EventEmitter, Input, NgModule, OnDestroy, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserAvatarModule} from '../user-avatar';
import {IMessage} from '@dev-fast/types';
import {FormBuilder, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {Subject} from 'rxjs';

@Component({
  selector: 'fast-ui-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatComponent implements OnDestroy {
  @Input() public messageList: IMessage[];

  @Output() public sendMessageEmit: EventEmitter<object> = new EventEmitter<object>();

  public messageForm: FormGroup;

  private destroyed$: Subject<void>;

  constructor(private readonly fb: FormBuilder) {
    this.messageList = [];
    this.destroyed$ = new Subject<void>();
    // FIXME Не уверен, что должны быть такие типы стоять для sticker и send
    this.messageForm = this.fb.group({
      message: '',
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public trackingFunction(index: number, item: any): number {
    return item.id;
  }

  // FIXME Добавить функционал открытие контейнера
  public onToggleStickerContainer(): void {
    // console.log('open chat sticker container');
  }

  public sendMessage(): void {
    this.sendMessageEmit.emit(this.messageForm.value);
  }
}

@NgModule({
  imports: [CommonModule, UserAvatarModule, ReactiveFormsModule, MatIconModule],
  declarations: [ChatComponent],
  exports: [ChatComponent],
})
export class ChatComponentModule {}
