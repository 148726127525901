import { Breakpoints } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { CookieService } from '@csgofast/core/cookie-service';
import { FrameMessageTypes, IFrameMessageService } from '@csgofast/core/iframe';
import { LocalStorageService } from '@csgofast/core/local-storage-service';
import { RefreshCurrentUser, UserState } from '@csgofast/core/state/user-store';
import { RouterStateParams } from '@csgofast/core/state/utils';
import { getKeyByValue } from '@csgofast/shared/utils';
import { LocalizationApiService } from '@dev-fast/backend-services';
import { ActivePanel, ILocales, LOCALES_DIC, Panel, SubPanel, Themes, Widgets } from '@dev-fast/types';
import { TranslateService } from '@ngx-translate/core';
import { RouterNavigation } from '@ngxs/router-plugin';
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { append, patch } from '@ngxs/store/operators';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LAYOUT_INITIAL_STATE, LayoutStateModel } from './layout-state.model';
import {
  ChangeActivePanel,
  ChangeActiveWidgets,
  ChangeTheme,
  ClickOnLockedSide,
  LockPanels,
  LockSides,
  NavigateToMobile,
  OpenAvailablePanel,
  RefreshActivePanel,
  SetLayoutType,
  ToggleBackground,
  ToggleGameSelector,
  ToggleTabletByWidth,
  UnLockPanels,
  UnLockSides,
} from './layout.actions';
import {makeStateKey, TransferState} from "@angular/platform-browser";
import {LanguageService} from "@csgofast/core/language-service";
import {LanguageState} from "../../../../language-service/src/lib/language.state";
import {SetLanguage} from "../../../../language-service/src/lib/language.action";

@State<LayoutStateModel>({
  name: 'layout',
  defaults: LAYOUT_INITIAL_STATE,
})
@Injectable()
export class LayoutState implements NgxsOnInit {
  private readonly ACTIVE_WIDGETS_KEY = makeStateKey('activeWidgets');

  private window: Window | null;
  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject('isServer') private isServer: boolean,
    private readonly translate: TranslateService,
    private readonly storage: LocalStorageService,
    private readonly apiService: LocalizationApiService,
    private readonly frameMessageService: IFrameMessageService,
    private readonly store: Store,
    private readonly deviceService: DeviceDetectorService,
    private readonly cookieService: CookieService,
    private readonly transferState: TransferState,
  ) // private readonly scrollService:ScrollDispatcherService
  {
    this.window = this.document.defaultView;
  }

  @Selector()
  public static theme({ theme }: LayoutStateModel): Themes {
    return theme;
  }

  @Selector()
  public static activePanel({ activePanel }: LayoutStateModel): ActivePanel | null {
    return activePanel;
  }

  @Selector()
  public static activeWidgets({ activeWidgets }: LayoutStateModel): Widgets[] {
    return activeWidgets;
  }

  @Selector()
  public static gameSelectorOpened({ gameSelectorOpened }: LayoutStateModel): boolean {
    return gameSelectorOpened;
  }

  @Selector()
  public static isFadeSides({ isFadeSides }: LayoutStateModel): boolean {
    return isFadeSides;
  }

  @Selector()
  public static lockedPanels({ lockedPanels }: LayoutStateModel): Panel[] {
    return lockedPanels;
  }

  @Selector()
  public static isTabletByWidth({ isTabletByWidth }: LayoutStateModel): boolean | null {
    return isTabletByWidth;
  }

  public ngxsOnInit({ getState, dispatch, patchState }: StateContext<LayoutStateModel>) {
    this.frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'render.modal', () => dispatch(new LockSides()));
    this.frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'close.modal', () => dispatch(new UnLockSides()));
    this.frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'inited', () => dispatch(new UnLockSides()));
    // dispatch(new ToggleBackground('url(/assets/img/background/bg-csgofast.jpg)'));
    // const localeFromStorage = this.storage.get('lang');
    // localeFromStorage && dispatch(new ChangeLocale(localeFromStorage));

    const localeFromCookie = this.cookieService.getCookie('lang');
    const key = getKeyByValue(LOCALES_DIC, localeFromCookie);
    if (key) {
      dispatch(new SetLanguage({ path: key }));
    }

    // Получаем с пререндера виджеты. Нужно чтобы со старта их не было 0 и не мелькала пустота
    const activeWidgets = this.transferState.get(this.ACTIVE_WIDGETS_KEY, [] as any);
    activeWidgets.length && patchState({activeWidgets});


    const isDesktop = this.storage.get('isDesktop');
    isDesktop && patchState({ hasDesktopParams: isDesktop });
  }

  @Action(LockSides)
  public lockSides({ patchState }: StateContext<LayoutStateModel>) {
    patchState({ isFadeSides: true });
  }

  @Action(UnLockSides)
  public unLockSides({ patchState }: StateContext<LayoutStateModel>) {
    patchState({ isFadeSides: false });
  }

  @Action(ClickOnLockedSide)
  public clickOnLockedSide() {
    this.frameMessageService.sendMessage({
      type: FrameMessageTypes.MESSAGE_TO_BB,
      eventName: 'click.OnLockedSide',
      payload: { message: 'click.OnLockedSide' },
    });
  }

  @Action(RefreshCurrentUser)
  public refreshUser({ dispatch, getState }: StateContext<LayoutStateModel>, { payload }: RefreshCurrentUser): void {
    const locales = this.store.selectSnapshot(LanguageState.locales);
    if (payload.locale) {
      const locale = locales.find((item: ILocales) => item.path === payload.locale);
      const localeFromCookie = this.cookieService.getCookie('lang');
      const key = getKeyByValue(LOCALES_DIC, localeFromCookie);

      if (!key || key !== payload.locale) {
        console.log('refreshUser');
        dispatch(new SetLanguage(locale ? locale : { path: payload.locale }));
      }

      // else {
      //   //TODO возможно не нужно потому что по умолчанию достаточно. Нужно проверить
      //   dispatch(
      //     new ChangeLocale({
      //       path: 'en_US',
      //       isActive: true,
      //       title: 'English',
      //     })
      //   );
      // }
    }
    // if (payload.theme && payload.theme !== null) {
    //   dispatch(new ChangeTheme(payload.theme));
    // }
    if (payload.walletType) {
      payload.walletType === 'demo' ? dispatch(new LockPanels([Panel.INVENTORY])) : dispatch(new UnLockPanels([Panel.INVENTORY]));
    }
  }

  @Action(LockPanels)
  public lockPanel({ dispatch, getState, setState }: StateContext<LayoutStateModel>, { panels }: LockPanels) {
    const { activePanel, lockedPanels } = getState();
    if (activePanel && activePanel.panel === Panel.INVENTORY && panels.includes(Panel.INVENTORY)) {
      dispatch(new ChangeActivePanel({ panel: Panel.NOTIFICATIONS, subPanel: SubPanel.NONE }));
    }
    const newPanels = panels.filter((value) => !lockedPanels.includes(value));
    if (newPanels.length === 0) return;
    setState(patch({ lockedPanels: append<Panel>(newPanels) }));
  }

  @Action(UnLockPanels)
  public unLockPanel({ patchState, getState }: StateContext<LayoutStateModel>, { panels }: UnLockPanels) {
    const { lockedPanels } = getState();
    const newPanels = lockedPanels.filter((value) => !panels.includes(value));
    patchState({ lockedPanels: newPanels });
  }
  // @Action(LockInventory)
  // public lockInventory({ dispatch, getState }: StateContext<LayoutStateModel>) {
  //   const { activePanel } = getState();
  //   if (activePanel && activePanel.panel === Panel.INVENTORY) {
  //     dispatch(new ChangeActivePanel({ panel: Panel.NOTIFICATIONS, subPanel: SubPanel.NONE }));
  //   }
  // }

  @Action(RouterNavigation)
  public routerNavigation(
    { dispatch, getState, patchState }: StateContext<LayoutStateModel>,
    { routerState }: RouterNavigation<RouterStateParams>
  ) {
    const { queryParams, params, fragment } = routerState;
    // if(fragment){
    //   this.scrollService.scrollTo(fragment);
    // }
    if (queryParams && queryParams['desktop']) {
      const value = queryParams['desktop'].toLowerCase() == 'true';
      patchState({ hasDesktopParams: value });
      this.storage.set('isDesktop', value);
    }
    const { activePanel } = getState();
    if (activePanel) {
      dispatch(new ChangeActivePanel({ panel: activePanel?.panel, subPanel: SubPanel.NONE }));
    }
  }

  @Action(ChangeTheme)
  public changeTheme({ patchState }: StateContext<LayoutStateModel>, { theme }: ChangeTheme) {
    const body = this.document.querySelector('body');
    if (!body) return;

    const { classList } = body;
    Array.from(classList).forEach((classname) => classList.remove(classname));
    classList.add(theme);

    patchState({
      theme,
    });

    this.storage.set('theme', theme);
  }
  /**Если ничего не передать поставит бэкграунд по умолчанию */
  @Action(ToggleBackground)
  public toggleBackground({ patchState }: StateContext<LayoutStateModel>, { background }: ToggleBackground) {
    this.document.documentElement.setAttribute(
      'style',
      `--main-background: ${
        background ? background : 'url(/assets/img/background/bg-csgofast.jpg) center top / 100% no-repeat rgb(12, 13, 17)'
      }`
    );

    patchState({
      background,
    });
  }

  // FIXME
  @Action(OpenAvailablePanel)
  public openAvailablePanel({ patchState, dispatch }: StateContext<LayoutStateModel>) {
    const user = this.store.selectSnapshot(UserState.user);
    if (user) {
      user.permLock
        ? dispatch(new ChangeActivePanel({ panel: Panel.NOTIFICATIONS,subPanel: SubPanel.NONE }))
        : dispatch(new ChangeActivePanel({ panel: Panel.INVENTORY, subPanel: SubPanel.NONE }));
    } else {
      dispatch(new ChangeActivePanel({ panel: Panel.CHAT, subPanel:SubPanel.NONE }));
    }
  }
  // FIXME проверять можно ли открыть панель
  @Action(ChangeActivePanel)
  public changeActivePanel({ patchState, getState, dispatch }: StateContext<LayoutStateModel>, { panel }: ChangeActivePanel) {
    const { activePanel, lockedPanels } = getState();
    if (panel && lockedPanels.includes(panel.panel)) return;

    patchState({
      // activePanel: panel ? { ...panel, subPanel: SubPanel.BET } : panel,
      activePanel: panel,
    });
    if (activePanel && panel && activePanel.panel === panel.panel) {
      dispatch(new RefreshActivePanel({ subPanel: panel.subPanel }));
    } else {
      dispatch(new RefreshActivePanel(panel));
    }

    // FIXME кост но надеюсь временно
    if (panel === null || (panel && panel.subPanel === SubPanel.NONE))
      this.frameMessageService.sendMessage({
        type: FrameMessageTypes.MESSAGE_TO_BB,
        eventName: 'closeParticipationPanel',
        payload: {},
      });
  }
  @Action(ToggleGameSelector)
  public toggleGameSelector({ patchState }: StateContext<LayoutStateModel>, { active }: ToggleGameSelector) {
    patchState({
      gameSelectorOpened: active,
    });
  }
  @Action(ChangeActiveWidgets)
  public changeActiveWidgets({ patchState }: StateContext<LayoutStateModel>, { widgets }: ChangeActiveWidgets) {
    if (this.isServer) {
      this.transferState.set(this.ACTIVE_WIDGETS_KEY, widgets as any);
    }

    patchState({
      activeWidgets: widgets,
    });
  }
  @Action(ToggleTabletByWidth)
  public toggleTableByWidth({ patchState }: StateContext<LayoutStateModel>, { isTablet }: ToggleTabletByWidth) {
    patchState({
      isTabletByWidth: isTablet,
    });
  }
  @Action(NavigateToMobile)
  public navigateToMobile({ patchState, getState }: StateContext<LayoutStateModel>) {
    if (this.window) {
      if (this.window.location.host.startsWith('www')) {
        this.window.location.href = `//m.${window.location.host.split('.').slice(1).join('.')}`;
      } else {
        this.window.location.href = `//m.${window.location.host}`;
      }
    }
    patchState({
      hasDesktopParams: false,
    });
    this.storage.set('isDesktop', false);
  }
  @Action(SetLayoutType)
  public setLayoutType({ dispatch, patchState, getState }: StateContext<LayoutStateModel>, { type }: SetLayoutType) {
    const { hasDesktopParams } = getState();
    const isMobile = this.deviceService.isMobile();
    if (isMobile && type === Breakpoints.XSmall && !hasDesktopParams) {
      dispatch(new NavigateToMobile());
    }
    patchState({
      layoutType: type,
    });
    return dispatch(new ToggleTabletByWidth([Breakpoints.Tablet, Breakpoints.XSmall].indexOf(type) != -1));
  }
}
