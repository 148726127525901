import {ModuleWithProviders, NgModule, TypeProvider} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotificationsComponent} from './notifications.component';
import {MatIconModule} from '@angular/material/icon';
// FIXME заменить на ngx-scrollbar (уже установлен на проекте)
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {AppCurrencyTranslateModule} from '@csgofast/shared/pipes';
import {NOTIFICATIONS_ENGINE} from './symbols';
import {NgScrollbarModule} from 'ngx-scrollbar';
import {TranslateModule} from '@ngx-translate/core';
import {NotFoundStubModule} from '@dev-fast/ui-components';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    NotFoundStubModule,
    NgScrollbarModule,
    TranslateModule,
    PerfectScrollbarModule,
    AppCurrencyTranslateModule,
  ],
  declarations: [NotificationsComponent],
  exports: [NotificationsComponent],
})
export class NotificationsWidgetsModule {
  static forRoot(engine: TypeProvider): ModuleWithProviders<NotificationsWidgetsModule> {
    return {
      ngModule: NotificationsWidgetsModule,
      providers: [
        {
          provide: NOTIFICATIONS_ENGINE,
          useClass: engine,
        },
      ],
    };
  }
}
