import { AutoGameActivateChildGuard, AutoGameActivateGuard, AutoGameDeactivateGuard, BanActivateGuard } from "@csgofast/core/guards";
import { EventsHomeComponent, NyEventGuard, NyEventHistoryGuard } from "@csgofast/modules/events";
import { PlugComponent } from "@dev-fast/ui-components";
import { legacyRoutes } from "@csgofast/core/iframe";
import { Routes } from '@angular/router';

const getLegacyRoute = () =>
  legacyRoutes.map((route) => ({
    path: route,
    component: PlugComponent,
    canDeactivate: [AutoGameDeactivateGuard],
    canActivate: [AutoGameActivateGuard, BanActivateGuard],
    canActivateChild: [AutoGameActivateChildGuard, BanActivateGuard],
  }));

export const routes: Routes = [
  {
    path: '',
    children: [{
        path: '',
        redirectTo: 'game',
        pathMatch: 'full',
      },
      ...getLegacyRoute(),
      {
        path: 'balance/withdrawal',
        redirectTo: 'withdrawal/method',
        pathMatch: 'full',
      }, {
        path: 'buy-coins',
        redirectTo: 'refill',
        pathMatch: 'full',
      }, {
        path: 'cases',
        redirectTo: 'game/cases',
        pathMatch: 'full',
      }, {
        path: 'classic',
        redirectTo: 'game/classic',
        pathMatch: 'full',
      }, {
        path: 'fast',
        redirectTo: 'game/fast',
        pathMatch: 'full',
      }, {
        path: 'double',
        redirectTo: 'game/double',
        pathMatch: 'full',
      }, {
        path: 'x50',
        redirectTo: 'game/x50',
        pathMatch: 'full',
      }, {
        path: 'slot',
        redirectTo: 'game/slot',
        pathMatch: 'full',
      }, {
        path: 'personal',
        redirectTo: 'account',
        pathMatch: 'full',
      }, {
        path: 'personal/balance',
        redirectTo: 'account/balance',
        pathMatch: 'full',
      }, {
        path: 'inventory',
        redirectTo: 'refill/skins',
        pathMatch: 'full',
      }, {
        path: 'market/inventory',
        redirectTo: 'refill/skins',
        pathMatch: 'full',
      }, {
        path: 'faucet',
        redirectTo: 'bonuses',
        pathMatch: 'full',
      }, {
        path: 'support',
        redirectTo: 'game',
        pathMatch: 'full',
      },
      {
        path: 'support/requests',
        redirectTo: 'game',
        pathMatch: 'full',
      }, {
        path: 'game',
        loadChildren: () => import('@csgofast/games').then((modules) => modules.GamesModule),
        canDeactivate: [AutoGameDeactivateGuard],
        canActivate: [AutoGameActivateGuard],
        canActivateChild: [AutoGameActivateChildGuard],
      }, {
        path: 'faq',
        loadChildren: () => import('@csgofast/modules-faq-home').then((modules) => modules.FaqHomeModule),
        canDeactivate: [AutoGameDeactivateGuard],
        canActivate: [AutoGameActivateGuard],
        canActivateChild: [AutoGameActivateChildGuard],
        data: {seo: 'FAQ'},
      }, {
      //   path: 'provably-fair',
      //   loadChildren: () => import('@csgofast/modules/provably-fair').then((modules) => modules.ProvablyFairModule),
      //   canDeactivate: [AutoGameDeactivateGuard],
      //   canActivate: [AutoGameActivateGuard],
      //   canActivateChild: [AutoGameActivateChildGuard],
      // },{
        path: 'events',
        component: EventsHomeComponent,
        children: [{
            path: '',
            redirectTo: 'ny-event',
            pathMatch: 'full',
          }, {
            path: 'ny-event',
            loadChildren: () => import('@csgofast/modules/events').then((modules) => modules.NyEventModule),
            canActivate: [NyEventGuard],
          }, {
            path: 'ny-all-event',
            loadChildren: () => import('@csgofast/modules/events').then((modules) => modules.NyAllEventModule),
            canActivate: [NyEventGuard],
          }, {
            path: 'leaders',
            loadChildren: () => import('@csgofast/modules/events').then((modules) => modules.NyResultsLeadersModule),
            canActivate: [NyEventHistoryGuard],
          }, {
            path: 'shop',
            loadChildren: () => import('@csgofast/modules/events').then((modules) => modules.NyResultsShopModule),
            canActivate: [NyEventHistoryGuard],
          },
        ],
      }, {
        path: 'block',
        component: PlugComponent,
      }
    ],
  }
];

