import {AppCurrencyModule} from '@csgofast/shared/pipes';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, NgModule, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BalanceWidgetConfig} from './balance-widget.model';
import {MatIconModule} from '@angular/material/icon';

@Component({
  selector: 'fast-ui-balance-widget',
  templateUrl: './balance-widget.component.html',
  styleUrls: ['./balance-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BalanceWidgetComponent {
  @Input() public config: BalanceWidgetConfig | null;

  @Output() public clickOnCoin = new EventEmitter<Event>(false);
  @Output() public clickOnSkin = new EventEmitter<Event>(false);
  constructor() {
    this.config = { countCoins: 0, countSkins: 0, urlCoins: '', urlSkins: '' };
  }
  public onSkin = () => this.clickOnCoin.emit();
  public onCoin = () => this.clickOnSkin.emit();
}

@NgModule({
  imports: [CommonModule, MatIconModule, AppCurrencyModule],
  declarations: [BalanceWidgetComponent],
  exports: [BalanceWidgetComponent],
})
export class BalanceWidgetComponentModule {}
