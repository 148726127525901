import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {INotification} from '@dev-fast/types';
import {NotificationsEngine} from '@csgofast/widgets/notifications';
import {NotificationsService as CoreNotiService} from '@csgofast/core/notification-service';
import {Dispatch} from '@ngxs-labs/dispatch-decorator';
import {ChangeActivePanel} from '@csgofast/core/state/layout';

@Injectable()
export class NotificationsService implements NotificationsEngine {
  public notifications$: Observable<INotification[]>;

  // @Select(ChatState.items)
  // public messages$!: Observable<IMessage[]>;
  constructor(private readonly notificationsService: CoreNotiService) {
    this.notifications$ = this.notificationsService.notifications$;
  }

  public removeNotification(id: number): void {
    this.notificationsService.removeNotification(id);
  }
  public watchAllNotifications(): void {
    this.notificationsService.watchAllNotifications();
  }

  @Dispatch() public closeWidget = () => new ChangeActivePanel(null);
}
