import {IAudio} from '../sound.interface';
import {Howl} from 'howler';

export class AudioController {
  public audio: Howl;
  public group: string;
  public name: string;
  public shortName: string;
  public description: string;
  public enabled: boolean | undefined;
  public type: string;
  public phase: string;

  constructor(options: IAudio) {
    this.audio = new Howl({
      src: [options.link],
      loop: options?.loop || false,
      format: options.format || 'mp3',
    });
    this.group = options.group;
    this.name = options.name;
    this.shortName = options.shortName;
    this.type = options.type || '';
    this.phase = options.phase || '';
    this.description = options.description;
    this.enabled = options.enabled;
  }

  public play(tested: boolean = false): void {
    if (!tested && !this.enabled) return;
    this.audio.play();
  }

  public pause(): void {
    this.audio.pause();
  }

  public stop(): void {
    this.audio.stop();
  }
  
  public load(): void {
    this.audio.load();
  }

  public seek(position: number): void {
    this.audio.seek(position, undefined);
  }

  public volume(value: number): void {
    this.audio.volume(value);
  }

  public toggleAudio(): void {
    if (!this.audio.playing()) {
      this.audio.play();
    } else {
      this.audio.pause();
    }
  }

  public mute(enabled: boolean): void {
    this.enabled = enabled;
  }
}
