import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {IUserDetailed} from '@dev-fast/types';
import {BehaviorSubject, debounceTime, Observable, Subject, takeUntil, tap} from 'rxjs';

@Component({
  selector: 'csgofast-switcher-balance',
  templateUrl: './switcher.component.html',
  styleUrls: ['./switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SwitcherBalanceComponent implements OnInit, OnDestroy {
  // @Input() public totalBalance: number;
  // @Input() public currencyList: ICurrencyRate[];
  // @Input() public currency!: ICurrencyRate;
  @Input() public set user(val: IUserDetailed) {
    // const type = val.walletType;
    // if (type !== 'demo') {
    //   this.switcherBalance.patchValue({ balanceType: false }, { emitEvent: false });
    //   this.isDataLoading.next(false);
    // } else {
    //   this.switcherBalance.patchValue({ balanceType: true }, { emitEvent: false });
    //   this.isDataLoading.next(false);
    // }
    this.switcherBalance.patchValue({ balanceType: false }, { emitEvent: false });
      this.isDataLoading.next(false);

  }
  // @Input() public set walletType(type: string) {

  // }
  @Input() public coinsAmount!: number;
  @Output() public changeType: EventEmitter<void> = new EventEmitter<void>();

  public isDataLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public isDataLoading$: Observable<boolean> = this.isDataLoading.asObservable();

  public switcherBalance: FormGroup = this.fb.group({
    balanceType: false,
  });
  private destroyed$: Subject<void>;

  constructor(private readonly fb: FormBuilder) {
    this.destroyed$ = new Subject<void>();
    // this.currencyList = [];
  }

  ngOnInit(): void {
    // this.switcherBalance.controls['balanceType'].valueChanges
    //   .pipe(
    //     tap(() => {
    //       this.isDataLoading.next(true);
    //     }),
    //     debounceTime(500),
    //     takeUntil(this.destroyed$)
    //   )
    //   .subscribe(() => {
    //     this.changeType.emit();
    //     // FIXME Удалить строку ниже когда будут обновляться данные в сетере, а в сетере при поступлении нового значения убирает лоадер
    //     // this.isDataLoading.next(false);
    //   });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
