import { Component, OnInit } from '@angular/core';
import {
  EventsConfig,
  ICaseCollection,
  ICaseQuestMotivatorLastDto,
  ICaseQuestTotalProgress,
  ICasesQuestItem
} from '@dev-fast/types';
import { map, Observable } from 'rxjs';
import { NyAllEventService, NyEventService } from '../core';

@Component({
  selector: 'csgofast-ny-event',
  templateUrl: './ny-event.component.html',
  styleUrls: ['./ny-event.component.scss'],
})
export class NyEventComponent implements OnInit {
  public motivatorList$: Observable<ICaseQuestMotivatorLastDto[]> | undefined;
  public totalProgress$: Observable<ICaseQuestTotalProgress> | undefined;
  public casesCollection$: Observable<ICaseCollection> | undefined;
  public overlay$: Observable<ICasesQuestItem | null> | undefined;
  public reward$!: Observable<ICasesQuestItem | null>;
  public items$: Observable<ICasesQuestItem[]> | undefined;
  public categoriesIsLoad$: Observable<boolean>;
  public eventName$: Observable<keyof EventsConfig | null> | undefined;


  constructor(private readonly nyEventService: NyEventService, private readonly nyAllEventService: NyAllEventService) {
    this.categoriesIsLoad$ = this.nyAllEventService.getCasesIsExecuting$.pipe(map((el) => !!el));
    this.casesCollection$ = this.nyAllEventService.cases$;
    this.eventName$ = this.nyEventService.eventName$;

  }
  public ngOnInit(): void {
    // this.nyEventService.getQuest();
    this.motivatorList$ = this.nyEventService.motivatorList$;
    this.totalProgress$ = this.nyEventService.totalProgress$;
    this.overlay$ = this.nyEventService.overlay$;
    this.reward$ = this.nyEventService.reward$;
    this.items$ = this.nyEventService.items$;
  }
}
