import {Injectable} from '@angular/core';
import {Environments, EnvironmentService} from '@csgofast/core/environment-service';
import {
  GameIds,
  IInventoryInfo,
  IInventoryItem,
  IInventoryItemDetailed,
  IInventoryItemDetailedReq,
  IShop, IUserInventoryItemV2
} from '@dev-fast/types';
import {Observable} from 'rxjs';
import {ApiService} from './api.service';

@Injectable({
  providedIn: 'root',
})
export class InventoryService {
  private environment: Environments;

  constructor(private readonly api: ApiService, private readonly environmentService: EnvironmentService) {
    this.environment = this.environmentService.environments;
  }
  public sellInventoryItems(data: { ids: number[] }): Observable<void> {
    return this.api.post<void>(`${this.environment.GATEWAY_URL}/api/inventory/items/sell`, data, { withCredentials: true });
  }
  public sellAll(): Observable<void> {
    return this.api.post<void>(`${this.environment.GATEWAY_URL}/api/inventory/items/sell/all`, {}, { withCredentials: true });
  }

  // public exchangeByContract(data: { ids: number[] }): Observable<IInventoryItem> {
  //   return this.api.post<IInventoryItem>(`${this.environment.GATEWAY_URL}/api/inventory/items/contract`, data, {
  //     withCredentials: true,
  //   });
  // }

  public requestSihImage(): Observable<void> {
    return this.api.get<void>('chrome-extension://cmeakgjggjdlcpncigglobpjbkabhmjl/assets/icon128.png');
  }

  // public withdrawSelectedItems(data: IWithdrawalRequest): Observable<IWithdrawalResponse[]> {
  //   return this.api.post<IWithdrawalResponse[]>(`${this.environment.GATEWAY_URL}/api/inventory/items/withdraw`, data, {
  //     withCredentials: true,
  //   });
  // }
  // public createOrder(store: any, params: { itemName: string; appId: number; price: number }): Observable<ISkinsBuyResponse> {
  //   return this.api.post<ISkinsBuyResponse>(`${this.environment.GATEWAY_URL}/api/${store}/create-order`, params, { withCredentials: true });
  // }

  // public requestStore(store: any, params?: any): Observable<{ items: ISkinItem[] } | void> {
  //   return this.api.get<{ items: ISkinItem[] } | void>(`${this.environment.GATEWAY_URL}/api/${store}/skin-items`, {
  //     withCredentials: true,
  //     params,
  //   });
  // }
  public getInventoryItemDetailed(params: IInventoryItemDetailedReq): Observable<IInventoryItemDetailed> {
    return this.api.get(`${this.environment.GATEWAY_URL}/api/inventory/items/detailed`, {
      withCredentials: true,
      params,
    });
  }
  public requestInventory(params?: object): Observable< Record<GameIds, IUserInventoryItemV2[]>>{
    return this.api.get<Record<GameIds, IUserInventoryItemV2[]>>(`${this.environment.GATEWAY_URL}/api/inventory/v2/items`, {
      withCredentials: true,
      params,
    });
  }

  public requestInventoryInfo(): Observable<IInventoryInfo[]> {
    return this.api.get<IInventoryInfo[]>(`${this.environment.GATEWAY_URL}/api/inventory/info`, { withCredentials: true });
  }

  public requestShop(params: object): Observable<IShop> {
    return this.api.get<IShop>(`${this.environment.GATEWAY_URL}/api/inventory/v2/shop`, { withCredentials: true, params });
  }

  public requestCreateTrade(params: { ids: number[]; userInventoryIds: number[] }): Observable<IInventoryItem[]> {
    return this.api.post(`${this.environment.GATEWAY_URL}/api/inventory/items/purchase`, params, { withCredentials: true });
  }

  // public requestSteamInventory(): Observable<ISteamStore> {
  //   return this.api.get<ISteamStore>(`${this.environment.GATEWAY_URL}/api/steam-store/inventory`, { withCredentials: true });
  // }

  // public requestSteamTradeBots(): Observable<ISteamStoreTradeBots> {
  //   return this.api.get<ISteamStoreTradeBots>(`${this.environment.GATEWAY_URL}/api/steam-store/bots`, { withCredentials: true });
  // }
}
