import {GameIds, IInventoryItem, IInventoryRequestParams, IShopMeta, ISkinItem} from '@dev-fast/types';

export interface InventoryStateModel {
  // FIXME убрать отсюда
  appId: GameIds;
  items: IInventoryItem[];
  itemsIds: number[];
  selectedItems: IInventoryItem[];
  inventoryCount: number;
  inventorySum: number;
  params: IInventoryRequestParams;

  shopItems: ISkinItem[];
  contracts: ISkinItem[];
  shopParams: IInventoryRequestParams;
  shopMeta: IShopMeta;
  gameInProgress: boolean;
}

export const INVENTORY_INITIAL_STATE: InventoryStateModel = {
  appId: GameIds.CSGO,
  items: [],
  itemsIds: [],
  selectedItems: [],
  inventoryCount: 0,
  inventorySum: 0,
  params: {
    sortBy: true,
    page: 1,
    pageSize: 50,
    append: false,
  },

  shopItems: [],
  contracts: [],
  shopParams: {
    sortBy: true,
    page: 1,
    pageSize: 50,
    append: false,
  },
  shopMeta: {
    amount: 0,
    limit: 0,
    offset: 0,
  },
  gameInProgress: false,
};
