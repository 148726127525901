import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {P2pPurchaseComponent} from './p2p-purchase.component';
// FIXME заменить на ngx-scrollbar (уже установлен на проекте)
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {TranslateModule} from '@ngx-translate/core';
import {P2pPurchaseItemModule} from './p2p-purchase-item/p2p-purchase-item.module';
import {RouterModule} from '@angular/router';
import {MatIconModule} from '@angular/material/icon';
import {P2pPurchaseService} from './p2p-purchase.service';

@NgModule({
  declarations: [P2pPurchaseComponent],
  imports: [CommonModule, TranslateModule, PerfectScrollbarModule, P2pPurchaseItemModule, MatIconModule, RouterModule],
  exports: [P2pPurchaseComponent],
  providers: [P2pPurchaseService],
})
export class P2pPurchaseModule {}
