import {IP2pDepositingItem, IP2pDepositItem, OrderStatusEnum} from '@dev-fast/types';

const P2P_SELECTED_INIT: IP2pDepositItem[] = [
  {
    steamInventoryId: 1,
    steamBotId: 2,
    baseItem: {
      name: 'Item name',
      icon: 'https://steamcommunity-a.akamaihd.net/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgposbaqKAxf0Ob3djFN79eJh46KlPz1J6_QlWBu5Mx2gv3--Y3nj1H6-kdpN2H1J4GUdlBsYQ6E_Ae5x-i605S06p7PynswunV0sSqJmhW2iQYMMLKO7TPsAg',
      color: '',
      weapon: 'weapon name',
      type: 'type',
      rarity: 'rarity',
      shortName: 'Negev',
      skin: 'dev-texture',
      exterior: 'Factory New',
      statTrak: true,
      steamPrice: 100,
    },
    tradable: true,
    passed: true,
    price: 8745,
    priceRange: {
      start: 800,
      end: 9000,
    },
    float: 8710.5,
    stickers: [],
    inTrade: false,
    custom: false,
    tradeLockEndAt: '2022-10-06T19:38:00.838Z',
    selectedPercent: 50,
    preferredPrice: 500,
    extra: {
      price: 8770,
      increase: 8,
    },
  },
  {
    steamInventoryId: 2,
    steamBotId: 2,
    baseItem: {
      name: 'Item name',
      icon: 'https://steamcommunity-a.akamaihd.net/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgposbaqKAxf0Ob3djFN79eJh46KlPz1J6_QlWBu5Mx2gv3--Y3nj1H6-kdpN2H1J4GUdlBsYQ6E_Ae5x-i605S06p7PynswunV0sSqJmhW2iQYMMLKO7TPsAg',
      color: '',
      weapon: 'weapon name',
      type: 'type',
      rarity: 'rarity',
      shortName: 'Negev',
      skin: 'dev-texture',
      exterior: 'Factory New',
      statTrak: true,
      steamPrice: 100,
    },
    tradable: true,
    passed: true,
    price: 8745,
    priceRange: {
      start: 800,
      end: 9000,
    },
    float: 8710.5,
    stickers: [],
    inTrade: false,
    custom: false,
    tradeLockEndAt: '2022-10-06T19:38:00.838Z',
    selectedPercent: 50,
    preferredPrice: 500,
    extra: {
      price: 8770,
      increase: -8,
    },
  },
  {
    steamInventoryId: 3,
    steamBotId: 2,
    baseItem: {
      name: 'Item name',
      icon: 'https://steamcommunity-a.akamaihd.net/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgposbaqKAxf0Ob3djFN79eJh46KlPz1J6_QlWBu5Mx2gv3--Y3nj1H6-kdpN2H1J4GUdlBsYQ6E_Ae5x-i605S06p7PynswunV0sSqJmhW2iQYMMLKO7TPsAg',
      color: '',
      weapon: 'weapon name',
      type: 'type',
      rarity: 'rarity',
      shortName: 'NegevNegevNegevNegevNegev',
      skin: 'dev-texture',
      exterior: 'Factory New',
      statTrak: true,
      steamPrice: 100,
    },
    tradable: true,
    passed: true,
    price: 8745,
    priceRange: {
      start: 800,
      end: 9000,
    },
    float: 8710.5,
    stickers: [],
    inTrade: false,
    custom: false,
    tradeLockEndAt: '2022-10-06T19:38:00.838Z',
    selectedPercent: 50,
    preferredPrice: 500,
    extra: {
      price: 8770,
      increase: 0,
    },
  },
];
const P2P_DEPOSIT_INIT: IP2pDepositingItem[] = [
  {
    order: {
      buyerSteamName: 'buyer steam name',
      buyerId: 42069,
      buyerMarket: 'market?',
      buyerSteamAvatar: '',
      buyerSteamCreatedAt: '22-10-10',
      buyerSteamId: 69420,
      buyerSteamLevel: 42,
      buyerTradeLink: '',
      fastOfferError: null,
      fastOfferReason: null,
      id: 880005553535,
      steamOfferId: null,
      steamItemPosition: [2, 3, 2],
    },
    isCrossFast: false,

    status: OrderStatusEnum.NEW,
    statusAt: '2022-10-30T19:38:00.838Z',
    nextStatusAt: '2022-10-30T19:38:00.838Z',
    isUserSeller: true,
    isLastBidByUser: false,
    // nextBidPrice?: number;
    price: 8100,
    express: true,
    // phase?: null;
    // float?: number;
    stickers: [],
    recommendedPrice: 110,
    sellerPrice: 150,
    steamInspectLink: '',
    id: 2,
    baseItem: {
      name: 'Item name',
      icon: 'https://steamcommunity-a.akamaihd.net/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgposbaqKAxf0Ob3djFN79eJh46KlPz1J6_QlWBu5Mx2gv3--Y3nj1H6-kdpN2H1J4GUdlBsYQ6E_Ae5x-i605S06p7PynswunV0sSqJmhW2iQYMMLKO7TPsAg',
      color: '',
      weapon: 'weapon name',
      type: 'type',
      rarity: 'rarity',
      shortName: 'Negev',
      skin: 'dev-texture',
      exterior: 'Factory New',
      statTrak: true,
      steamPrice: 100,
    },
  },
  {
    order: {
      buyerSteamName: 'buyer steam name',
      buyerId: 42069,
      buyerMarket: 'market?',
      buyerSteamAvatar: '',
      buyerSteamCreatedAt: '22-10-10',
      buyerSteamId: 69420,
      buyerSteamLevel: 42,
      buyerTradeLink: '',
      fastOfferError: null,
      fastOfferReason: null,
      id: 880005553535,
      steamOfferId: null,
      steamItemPosition: [2, 3, 2],
    },
    isCrossFast: false,
    status: OrderStatusEnum.WAIT_FOR_BUYER_ACCEPT,
    statusAt: '2022-10-30T19:38:00.838Z',
    nextStatusAt: '2022-10-30T19:38:00.838Z',
    isUserSeller: true,
    isLastBidByUser: false,
    // nextBidPrice?: number;
    price: 8100,
    express: true,
    // phase?: null;
    // float?: number;
    stickers: [],
    recommendedPrice: 110,
    sellerPrice: 150,
    steamInspectLink: '',
    id: 11,
    baseItem: {
      name: 'Item name',
      icon: 'https://steamcommunity-a.akamaihd.net/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgposbaqKAxf0Ob3djFN79eJh46KlPz1J6_QlWBu5Mx2gv3--Y3nj1H6-kdpN2H1J4GUdlBsYQ6E_Ae5x-i605S06p7PynswunV0sSqJmhW2iQYMMLKO7TPsAg',
      color: '',
      weapon: 'weapon name',
      type: 'type',
      rarity: 'rarity',
      shortName: 'Negev',
      skin: 'dev-texture',
      exterior: 'Factory New',
      statTrak: true,
      steamPrice: 100,
    },
  },
  {
    order: {
      buyerSteamName: 'buyer steam name',
      buyerId: 42069,
      buyerMarket: 'market?',
      buyerSteamAvatar: '',
      buyerSteamCreatedAt: '22-10-10',
      buyerSteamId: 69420,
      buyerSteamLevel: 42,
      buyerTradeLink: '',
      fastOfferError: null,
      fastOfferReason: null,
      id: 880005553535,
      steamOfferId: null,
      steamItemPosition: [2, 3, 2],
    },
    isCrossFast: false,
    status: OrderStatusEnum.FIRST_BID,
    statusAt: '2022-10-30T19:38:00.838Z',
    nextStatusAt: '2022-10-30T19:38:00.838Z',
    isUserSeller: true,
    isLastBidByUser: false,
    // nextBidPrice?: number;
    price: 8100,
    express: true,
    // phase?: null;
    // float?: number;
    stickers: [],
    recommendedPrice: 110,
    sellerPrice: 150,
    steamInspectLink: '',
    id: 3,
    baseItem: {
      name: 'Item name',
      icon: 'https://steamcommunity-a.akamaihd.net/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgposbaqKAxf0Ob3djFN79eJh46KlPz1J6_QlWBu5Mx2gv3--Y3nj1H6-kdpN2H1J4GUdlBsYQ6E_Ae5x-i605S06p7PynswunV0sSqJmhW2iQYMMLKO7TPsAg',
      color: '',
      weapon: 'weapon name',
      type: 'type',
      rarity: 'rarity',
      shortName: 'Negev',
      skin: 'dev-texture',
      exterior: 'Factory New',
      statTrak: true,
      steamPrice: 100,
    },
  },
  {
    order: {
      buyerSteamName: 'buyer steam name',
      buyerId: 42069,
      buyerMarket: 'market?',
      buyerSteamAvatar: '',
      buyerSteamCreatedAt: '22-10-10',
      buyerSteamId: 69420,
      buyerSteamLevel: 42,
      buyerTradeLink: '',
      fastOfferError: null,
      fastOfferReason: null,
      id: 880005553535,
      steamOfferId: null,
      steamItemPosition: [2, 3, 2],
    },
    isCrossFast: false,
    status: OrderStatusEnum.SECOND_BID,
    statusAt: '2022-10-30T19:38:00.838Z',
    nextStatusAt: '2022-10-30T19:38:00.838Z',
    isUserSeller: true,
    isLastBidByUser: false,
    // nextBidPrice?: number;
    price: 8100,
    express: true,
    // phase?: null;
    // float?: number;
    stickers: [],
    recommendedPrice: 110,
    sellerPrice: 150,
    steamInspectLink: '',
    id: 123,
    baseItem: {
      name: 'Item name',
      icon: 'https://steamcommunity-a.akamaihd.net/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgposbaqKAxf0Ob3djFN79eJh46KlPz1J6_QlWBu5Mx2gv3--Y3nj1H6-kdpN2H1J4GUdlBsYQ6E_Ae5x-i605S06p7PynswunV0sSqJmhW2iQYMMLKO7TPsAg',
      color: '',
      weapon: 'weapon name',
      type: 'type',
      rarity: 'rarity',
      shortName: 'Negev',
      skin: 'dev-texture',
      exterior: 'Factory New',
      statTrak: true,
      steamPrice: 100,
    },
  },
  {
    order: {
      buyerSteamName: 'buyer steam name',
      buyerId: 42069,
      buyerMarket: 'market?',
      buyerSteamAvatar: '',
      buyerSteamCreatedAt: '22-10-10',
      buyerSteamId: 69420,
      buyerSteamLevel: 42,
      buyerTradeLink: '',
      fastOfferError: null,
      fastOfferReason: null,
      id: 880005553535,
      steamOfferId: null,
      steamItemPosition: [2, 3, 2],
    },
    isCrossFast: false,
    status: OrderStatusEnum.THIRD_PLUS_BID,
    statusAt: '2022-10-30T19:38:00.838Z',
    nextStatusAt: '2022-10-30T19:38:00.838Z',
    isUserSeller: true,
    isLastBidByUser: false,
    // nextBidPrice?: number;
    price: 8100,
    express: true,
    // phase?: null;
    // float?: number;
    stickers: [],
    recommendedPrice: 110,
    sellerPrice: 150,
    steamInspectLink: '',
    id: 312,
    baseItem: {
      name: 'Item name',
      icon: 'https://steamcommunity-a.akamaihd.net/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgposbaqKAxf0Ob3djFN79eJh46KlPz1J6_QlWBu5Mx2gv3--Y3nj1H6-kdpN2H1J4GUdlBsYQ6E_Ae5x-i605S06p7PynswunV0sSqJmhW2iQYMMLKO7TPsAg',
      color: '',
      weapon: 'weapon name',
      type: 'type',
      rarity: 'rarity',
      shortName: 'Negev',
      skin: 'dev-texture',
      exterior: 'Factory New',
      statTrak: true,
      steamPrice: 100,
    },
  },
  {
    order: {
      buyerSteamName: 'buyer steam name',
      buyerId: 42069,
      buyerMarket: 'market?',
      buyerSteamAvatar: '',
      buyerSteamCreatedAt: '2022-10-30T19:38:00.838Z',
      buyerSteamId: 69420,
      buyerSteamLevel: 42,
      buyerTradeLink: '',
      fastOfferError: null,
      fastOfferReason: null,
      id: 880005553535,
      steamOfferId: null,
      steamItemPosition: [10, 3, 2],
    },
    isCrossFast: false,
    status: OrderStatusEnum.WAIT_FOR_CONFIRM,
    statusAt: '2022-10-30T19:38:00.838Z',
    nextStatusAt: '2022-10-30T19:38:00.838Z',
    isUserSeller: true,
    isLastBidByUser: false,
    // nextBidPrice?: number;
    price: 8100,
    express: true,
    // phase?: null;
    // float?: number;
    stickers: [],
    recommendedPrice: 110,
    sellerPrice: 150,
    steamInspectLink: '',
    id: 28,
    baseItem: {
      name: 'Item name',
      icon: 'https://steamcommunity-a.akamaihd.net/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgposbaqKAxf0Ob3djFN79eJh46KlPz1J6_QlWBu5Mx2gv3--Y3nj1H6-kdpN2H1J4GUdlBsYQ6E_Ae5x-i605S06p7PynswunV0sSqJmhW2iQYMMLKO7TPsAg',
      color: '',
      weapon: 'weapon name',
      type: 'type',
      rarity: 'rarity',
      shortName: 'Negev',
      skin: 'dev-texture',
      exterior: 'Factory New',
      statTrak: true,
      steamPrice: 100,
    },
  },
  {
    order: {
      buyerSteamName: 'buyer steam name',
      buyerId: 42069,
      buyerMarket: 'market?',
      buyerSteamAvatar: '',
      buyerSteamCreatedAt: '2022-10-30T19:38:00.838Z',
      buyerSteamId: 69420,
      buyerSteamLevel: 42,
      buyerTradeLink: '',
      fastOfferError: null,
      fastOfferReason: null,
      id: 880005553535,
      steamOfferId: null,
      steamItemPosition: [10, 3, 2],
    },
    isCrossFast: false,
    status: OrderStatusEnum.WAIT_FOR_TRADE,
    statusAt: '2022-10-30T19:38:00.838Z',
    nextStatusAt: '2022-10-30T19:38:00.838Z',
    isUserSeller: true,
    isLastBidByUser: false,
    // nextBidPrice?: number;
    price: 8100,
    express: true,
    // phase?: null;
    // float?: number;
    stickers: [],
    recommendedPrice: 110,
    sellerPrice: 150,
    steamInspectLink: '',
    id: 18,
    baseItem: {
      name: 'Item name',
      icon: 'https://steamcommunity-a.akamaihd.net/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgposbaqKAxf0Ob3djFN79eJh46KlPz1J6_QlWBu5Mx2gv3--Y3nj1H6-kdpN2H1J4GUdlBsYQ6E_Ae5x-i605S06p7PynswunV0sSqJmhW2iQYMMLKO7TPsAg',
      color: '',
      weapon: 'weapon name',
      type: 'type',
      rarity: 'rarity',
      shortName: 'Negev',
      skin: 'dev-texture',
      exterior: 'Factory New',
      statTrak: true,
      steamPrice: 100,
    },
  },
  {
    order: {
      buyerSteamName: 'buyer steam name',
      buyerId: 42069,
      buyerMarket: 'market?',
      buyerSteamAvatar: '',
      buyerSteamCreatedAt: '22-10-10',
      buyerSteamId: 69420,
      buyerSteamLevel: 42,
      buyerTradeLink: '',
      fastOfferError: null,
      fastOfferReason: null,
      id: 880005553535,
      steamOfferId: null,
      steamItemPosition: [2, 3, 2],
    },
    isCrossFast: false,
    status: OrderStatusEnum.CANCELED_BY_SELLER_BEFORE_TRADE,
    statusAt: '2022-10-30T19:38:00.838Z',
    nextStatusAt: '2022-10-30T19:38:00.838Z',
    isUserSeller: true,
    isLastBidByUser: false,
    // nextBidPrice?: number;
    price: 8100,
    express: true,
    // phase?: null;
    // float?: number;
    stickers: [],
    recommendedPrice: 110,
    sellerPrice: 150,
    steamInspectLink: '',
    id: 1256,
    baseItem: {
      name: 'Item name',
      icon: 'https://steamcommunity-a.akamaihd.net/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgposbaqKAxf0Ob3djFN79eJh46KlPz1J6_QlWBu5Mx2gv3--Y3nj1H6-kdpN2H1J4GUdlBsYQ6E_Ae5x-i605S06p7PynswunV0sSqJmhW2iQYMMLKO7TPsAg',
      color: '',
      weapon: 'weapon name',
      type: 'type',
      rarity: 'rarity',
      shortName: 'Negev',
      skin: 'dev-texture',
      exterior: 'Factory New',
      statTrak: true,
      steamPrice: 100,
    },
  },
  {
    order: {
      buyerSteamName: 'buyer steam name',
      buyerId: 42069,
      buyerMarket: 'market?',
      buyerSteamAvatar: '',
      buyerSteamCreatedAt: '22-10-10',
      buyerSteamId: 69420,
      buyerSteamLevel: 42,
      buyerTradeLink: '',
      fastOfferError: null,
      fastOfferReason: null,
      id: 880005553535,
      steamOfferId: null,
      steamItemPosition: [2, 3, 2],
    },
    isCrossFast: false,
    status: OrderStatusEnum.COMPLETED,
    statusAt: '2022-10-30T19:38:00.838Z',
    nextStatusAt: '2022-10-30T19:38:00.838Z',
    isUserSeller: true,
    isLastBidByUser: false,
    // nextBidPrice?: number;
    price: 8100,
    express: true,
    // phase?: null;
    // float?: number;
    stickers: [],
    recommendedPrice: 110,
    sellerPrice: 150,
    steamInspectLink: '',
    id: 57,
    baseItem: {
      name: 'Item name',
      icon: 'https://steamcommunity-a.akamaihd.net/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgposbaqKAxf0Ob3djFN79eJh46KlPz1J6_QlWBu5Mx2gv3--Y3nj1H6-kdpN2H1J4GUdlBsYQ6E_Ae5x-i605S06p7PynswunV0sSqJmhW2iQYMMLKO7TPsAg',
      color: '',
      weapon: 'weapon name',
      type: 'type',
      rarity: 'rarity',
      shortName: 'Negev',
      skin: 'dev-texture',
      exterior: 'Factory New',
      statTrak: true,
      steamPrice: 100,
    },
  },
  {
    order: {
      buyerSteamName: 'buyer steam name',
      buyerId: 42069,
      buyerMarket: 'market?',
      buyerSteamAvatar: '',
      buyerSteamCreatedAt: '22-10-10',
      buyerSteamId: 69420,
      buyerSteamLevel: 42,
      buyerTradeLink: '',
      fastOfferError: null,
      fastOfferReason: null,
      id: 880005553535,
      steamOfferId: null,
      steamItemPosition: [2, 3, 2],
    },
    isCrossFast: false,
    status: OrderStatusEnum.DELETED,
    statusAt: '2022-10-30T19:38:00.838Z',
    nextStatusAt: '2022-10-30T19:38:00.838Z',
    isUserSeller: true,
    isLastBidByUser: false,
    // nextBidPrice?: number;
    price: 8100,
    express: true,
    // phase?: null;
    // float?: number;
    stickers: [],
    recommendedPrice: 110,
    sellerPrice: 150,
    steamInspectLink: '',
    id: 789,
    baseItem: {
      name: 'Item name',
      icon: 'https://steamcommunity-a.akamaihd.net/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgposbaqKAxf0Ob3djFN79eJh46KlPz1J6_QlWBu5Mx2gv3--Y3nj1H6-kdpN2H1J4GUdlBsYQ6E_Ae5x-i605S06p7PynswunV0sSqJmhW2iQYMMLKO7TPsAg',
      color: '',
      weapon: 'weapon name',
      type: 'type',
      rarity: 'rarity',
      shortName: 'Negev',
      skin: 'dev-texture',
      exterior: 'Factory New',
      statTrak: true,
      steamPrice: 100,
    },
  },
];

export interface P2pDepositStateModel {
  depositingItems: IP2pDepositingItem[];
  selected: IP2pDepositItem[];
}
export const P2P_DEPOSIT_INITIAL_STATE: P2pDepositStateModel = {
  depositingItems: [],
  // depositingItems: P2P_DEPOSIT_INIT,
  selected: [],
};
