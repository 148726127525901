import {SocialItem, SOCIALS} from '@dev-fast/types';

export interface AuthStateModel {
  isAuth: boolean;
  socials: SocialItem[];
  unlinkedSocials: SocialItem[];
}

export const AUTH_INITIAL_STATE: AuthStateModel = {
  isAuth: false,
  socials: SOCIALS,
  unlinkedSocials: [],
};
