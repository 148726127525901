import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EventsConfig } from '@dev-fast/types';
import { Observable } from 'rxjs';
import { NyEventService } from '../../../core';

@Component({
  selector: 'csgofast-ny-terms-modal',
  templateUrl: './terms-modal.component.html',
  styleUrls: ['./terms-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // encapsulation: ViewEncapsulation.None,
})
export class NyTermsModalComponent {
  public eventName$: Observable<keyof EventsConfig | null> | undefined;

  constructor(private readonly nyEventService: NyEventService) {
    this.eventName$ = this.nyEventService.eventName$;
  }
}
