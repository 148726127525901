import { Component } from '@angular/core';


@Component({
  selector: 'fast-ui-drop-card-mock',
  templateUrl: './drop-card.component.mock.html',
  styleUrls: ['../drop-card.component.scss', './drop-card.component.mock.scss'],
})
export class DropCardMockComponent {
}
