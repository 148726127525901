import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {PlayerImageDirective} from './player-image.directive';

@NgModule({
  declarations: [PlayerImageDirective],
  imports: [CommonModule],
  exports: [PlayerImageDirective],
})
export class PlayerImageModule {}
