<!-- <ng-container
  *ngTemplateOutlet="
    appTpl;
    context: {
      user: user$ | async
    }
  "
></ng-container> -->

<!-- <ng-template
  #appTpl
  let-user="user"
> -->
<div class="logo-outer">
  <!--FIXME ссылка и иконки(есть компонент отдельный)-->
  <a
    [routerLink]="'/'"
    class="logo"
  >
    <mat-icon
      class="logo__icon"
      svgIcon="CSGO-mirror"
    ></mat-icon>
    <mat-icon
      class="logo__text mobile-hide"
      svgIcon="csgofastcom-domain"
    ></mat-icon>
  </a>
</div>
<div
  class="balance-panel"
  *ngIf="user$ | async as user"
>
  <!--      FIXME Прокинуть параметры-->
  <!-- <ng-container *ngIf="currencyList$ | async as currencyList"> -->
  <csgofast-switcher-balance
    clickStop
    *ngIf="user$ | async as user"
    [user]="user"
    [coinsAmount]="coinsAmount"
    (changeType)="changeType()"
  ></csgofast-switcher-balance>

  <!-- </ng-container> -->
  <div class="balance-panel__buttons">
    <!--        FIXME Вместо REFILL нужно добавить DEPOSIT-->
    <a
      routerLink="refill"
      class="btn btn-primary refill-btn"
    >
      <span class="balance-panel__buttons--min-content">+</span>
      <span class="balance-panel__buttons--max-content">{{ 'BALANCE_REFILL.LINK.REFILL' | translate | titlecase }}</span>
    </a>
    <a
      routerLink="withdrawal/method"
      class="btn btn-outline-secondary withdrawal-btn"
    >
      <span class="balance-panel__buttons--min-content">-</span>
      <span class="balance-panel__buttons--max-content">{{ 'BALANCE_REFILL.LINK.WITHDRAWAL' | translate | titlecase }}</span>
    </a>
    <!-- <a
      routerLink="bonuses/quest"
      class="btn btn-outline-primary"
      >{{ 'BALANCE_REFILL.LINK.FREE COINS' | translate | titlecase }}</a
    > -->
    <a
      *hasPermission="[permissions.MEMBER, permissions.CAN_USE]"
      class="btn btn-outline-primary btn-bonuses"
      (click)="onOpenGleamModal()"
    >
      <!-- <div class="d-flex align-items-center justify-content-around">
        <div
          rv-if="data.faucet"
          class="balance-panel__faucet d-flex align-items-center justify-content-around mr-2"
        >
          <span
            rv-unless="data.faucet.icon | eq 'default'"
            class="balance-panel__faucet_svg"
            rv-svg="data.faucet.icon"
          ></span>
          <span>x{data.faucet.multiplier}</span>
        </div>
        <span rv-translate>Free coins</span>
      </div> -->
      <img
        src="/assets/img/header/may-23.png"
        class="btn-bonuses-image"
        alt="may-23"
      />
      <span class="btn-bonuses-image--alt-text">Free</span>

      <div class="btn-bonuses-middle">
        <!-- <div class="btn-bonuses-text">John Doe</div> -->
      </div>
    </a>
    <a
      routerLink="bonuses"
      class="btn btn-outline-secondary crane-btn"
      (mouseenter)="craneHover = !craneHover"
      (mouseleave)="craneHover = !craneHover"
    >
      <mat-icon
        class="promo__icon"
        [svgIcon]="craneHover ? 'crane-hook-hover' : 'crane-hook'"
      ></mat-icon>
    </a>
  </div>

  <!--      FIXME Прокинуть параметры-->
  <csgofast-promo
    *hasPermission="[permissions.MEMBER, permissions.CAN_USE]"
    [promoData]="promoData$ | async"
    [activationIsExecuting]="activationIsExecuting$ | async"
    (activateEmit)="activatePromocode($event)"
    (getPromo)="getPromo()"
  ></csgofast-promo>
  <!-- <button
    *hasPermission="[permissions.MEMBER, permissions.CAN_USE]"
    (click)="onOpenOfferWallModal(user.id)"
  >
    {{ 'Offers' | translate }}
  </button> -->
</div>

<div
  class="controls-panel"
  style="height: 100%"
>
  <csgofast-ny-event-menu></csgofast-ny-event-menu>

  <ng-container *ngIf="locales$ | async as locales">
    <csgofast-language-dropdown
      *ngIf="currentLocale$ | async as locale"
      [items]="locales"
      [selected]="locale"
      (changeSelect)="onSwitchLang($event)"
    ></csgofast-language-dropdown>
  </ng-container>
  <ng-container *ngIf="isTabletByWidth$ | async; else control_panel"> </ng-container>
  <ng-template #control_panel>
    <!--    FIXME Прокинуть параметры-->

    <div class="controls-panel__buttons mobile-hide">
      <a
        class="transparent-btn"
        (click)="changeSoundFlag()"
      >
        <ng-container *ngIf="soundStatus$ | async; else soundOff">
          <mat-icon svgIcon="volume-on"></mat-icon>
        </ng-container>
        <ng-template #soundOff>
          <mat-icon svgIcon="volume-off"></mat-icon>
        </ng-template>
      </a>
      <a
        class="transparent-btn"
        [routerLink]="'/faq'"
      >
        <mat-icon svgIcon="faq"></mat-icon>
      </a>
      <ng-container *ngIf="user$ | async as user; else notLoginedTemplate">
        <a
          class="transparent-btn"
          (click)="onLogout()"
        >
          <mat-icon svgIcon="log-out"></mat-icon>
        </a>
      </ng-container>
      <ng-template #notLoginedTemplate>
        <div class="login-btn">
          <button
            class="btn btn-primary"
            (click)="onLogin()"
          >
            <mat-icon
              class="login__icon"
              svgIcon="login"
            ></mat-icon>
            {{ 'LOGIN' | translate | titlecase }}
          </button>
        </div>
      </ng-template>
    </div>
  </ng-template>

  <!--    <fast-ui-settings-menu-->
  <!--      [user]="user"-->
  <!--      [currentLang]="(currentLocale$ | async)!"-->
  <!--      [currentTheme]="(theme$ | async)!"-->
  <!--      [themeList]="themeList"-->
  <!--      [langList]="(locales$ | async)!"-->
  <!--      [currencyList]="(currencyList$ | async)!"-->
  <!--      [currentCurrency]="(currency$ | async)!"-->
  <!--      (login)="onLogin()"-->
  <!--      (logout)="onLogout()"-->
  <!--      (changeTheme)="onSwitchTheme($event)"-->
  <!--      (changeLang)="onSwitchLang($event)"-->
  <!--    >-->
  <!--    </fast-ui-settings-menu>-->
</div>
<!-- </ng-template> -->
