
import {Navigate} from "@ngxs/router-plugin";
import {map, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {CommonStoreService} from '@csgofast/core-state-common';
import {
  ActivePanel,
  IInventoryItem,
  IInventoryRequestParams,
  ILocales,
  INotification,
  InventoryTradeBalance,
  IShopMeta,
  ISkinItem,
  IUserDetailed,
  LegacyGameConfig,
  LegacyGameState,
  LegacyLiteGameState,
  ModalNames,
  Panel,
  SkinClickEvent,
} from '@dev-fast/types';
import {NotificationsService} from '@csgofast/core/notification-service';
import {Dispatch} from '@ngxs-labs/dispatch-decorator';
import {OpenModal} from '@csgofast/core/state/modals';
import {Actions, ofActionSuccessful, Select, Store} from '@ngxs/store';
import {
  ChangeParamsInventory,
  ChangeParamsShop,
  ClickOnInventoryItem,
  ClickOnShopItem,
  GetShopItems,
  InventoryState,
  SellAllItems,
  Trade,
  UnselectInventoryItemById,
} from '@csgofast/core/state/inventory';
import {ConfirmParticipateLegacySuccess, GamesState, ParticipateLegacy} from '@csgofast/core/state/games-store';
import {AuthService} from '@csgofast/auth';
import {ActionsExecuting, actionsExecuting} from '@ngxs-labs/actions-executing';
import {LayoutState, NavigateToMobile, OpenAvailablePanel} from '@csgofast/core/state/layout';
import {AccountState} from '@csgofast/core/state/account';

@Injectable()
export class DesktopRightsidebarService {
  public user$: Observable<IUserDetailed | null | undefined>;
  public activePanel$: Observable<ActivePanel | null>;
  public notifications$: Observable<INotification[]>;
  public hasNewNotifications$: Observable<boolean>;
  public countNewNotifications$: Observable<number>;
  public locales$: Observable<ILocales[]>;
  public currentLocale$: Observable<ILocales>;

  @Select(actionsExecuting([GetShopItems, ChangeParamsShop]))
  public inventoryShopLoading$!: Observable<ActionsExecuting>;
  @Select(InventoryState.items)
  public inventoryItems$!: Observable<IInventoryItem[]>;
  @Select(InventoryState.selectedItems)
  public selectedInventoryItems$!: Observable<IInventoryItem[]>;
  @Select(InventoryState.shopItems)
  public shopItems$!: Observable<ISkinItem[]>;
  @Select(InventoryState.contracts)
  public selectedShopItems$!: Observable<ISkinItem[]>;
  @Select(InventoryState.tradeBalance)
  public inventoryTradeBalance$!: Observable<InventoryTradeBalance>;
  @Select(InventoryState.inventorySum)
  public totalBalance$!: Observable<number>;
  @Select(InventoryState.inventorySortingParams)
  public inventorySortByPrice$!: Observable<boolean>;
  @Select(InventoryState.shopParams)
  public shopParams$!: Observable<IInventoryRequestParams>;
  @Select(InventoryState.inventoryParams)
  public inventoryParams$!: Observable<IInventoryRequestParams>;
  @Select(InventoryState.shopMeta)
  public shopMeta$!: Observable<IShopMeta>;
  @Select(InventoryState.gameInProgress)
  readonly gameInProgress$!: Observable<boolean>;

  @Select(GamesState.legacyConfig)
  public legacyConfig$!: Observable<LegacyGameConfig | null>;
  @Select(GamesState.legacyState)
  public legacyState$!: Observable<LegacyGameState | null>;
  @Select(GamesState.legacyLiteState)
  public legacyLiteState$!: Observable<LegacyLiteGameState | null>;
  @Select(GamesState.legacyParticipation)
  public legacyParticipation$!: Observable<IInventoryItem[]>;
  @Select(LayoutState.lockedPanels)
  public lockedPanels$!: Observable<Panel[]>;

  @Select(AccountState.canLevelUp)
  public canLevelUp$!: Observable<boolean>;

  @Select(LayoutState.isTabletByWidth)
  public isTabletByWidth: Observable<boolean> | undefined;

  public participatedSuccess$ = this.actions$.pipe(
    ofActionSuccessful(ConfirmParticipateLegacySuccess),
    map((action: ConfirmParticipateLegacySuccess) => action.payload)
  );

  constructor(
    private commonService: CommonStoreService,
    private readonly authService: AuthService,
    private readonly notificationsService: NotificationsService,
    private readonly actions$: Actions,
    private store: Store
  ) {
    this.user$ = this.commonService.user$;
    this.activePanel$ = this.commonService.activePanel$;
    this.notifications$ = this.notificationsService.notifications$;
    this.hasNewNotifications$ = this.notificationsService.hasNewNotifications$;
    this.countNewNotifications$ = this.notificationsService.countNewNotifications$;





    this.locales$ = this.commonService.locales$;
    this.currentLocale$ = this.commonService.currentLocale$;

  }
  public changeActivePanel = (panel: ActivePanel | null) => this.commonService.changeActivePanel(panel);
  @Dispatch() public openAvailablePanel = () => new OpenAvailablePanel()
  public removeNoty = (notyId: number) => this.notificationsService.removeNotification(notyId);
  public watchAllNotifications = () => this.notificationsService.watchAllNotifications();

  @Dispatch() public openSupportSelectorModal = () => new OpenModal(ModalNames.TECH_SUPPORT_SELECT_METHOD);
  @Dispatch() public getShopItems = () => new GetShopItems();
  // @Dispatch() public getInventoryItems = () => new GetInventoryItems();
  @Dispatch() public clickOnSkinInventory = (event: SkinClickEvent) => event.id && new ClickOnInventoryItem(event.id);
  @Dispatch() public clickOnSkinShop = (event: SkinClickEvent) => event.id && new ClickOnShopItem(event.id);
  @Dispatch() public sellAll = () => new SellAllItems();
  @Dispatch() public createTrade = () => new Trade();
  @Dispatch() public changeParamsInventory = (params: IInventoryRequestParams) => new ChangeParamsInventory(params);

  @Dispatch() public changeParamsShop = (params: IInventoryRequestParams) => new ChangeParamsShop(params);
  @Dispatch() public participate = () => new ParticipateLegacy();
  @Dispatch() public unselectInventoryItems = (ids: number[]) => new UnselectInventoryItemById(ids);
  @Dispatch() public navigateToMobile = () => new NavigateToMobile();
  @Dispatch() public navigate = (path: any[]) => new Navigate(path);

  public login = () => {
    this.authService.openLoginProvidersModal();
  };
  public logout = () => {
    this.authService.onLogout();
  };
  public switchLang(locale: ILocales) {
    this.commonService.switchLang(locale);
  }
}
