<ng-container *ngIf="stickers">
  <button
    class="btn btn-dark btn-sm chat-sticker"
    *ngFor="let sticker of getActiveStickers(stickers)"
    (click)="onStickerClick(sticker, stickers)"
  >
    <img
      class="chat-sticker-image"
      lazyLoadImage
      [src]="getStickerPath(stickers, 'previews', sticker)"
      alt=""
    />
  </button>
</ng-container>
