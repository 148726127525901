import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { getBaseDomain } from "@csgofast/shared/utils";

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  constructor(@Inject(DOCUMENT) private readonly document: Document) {}
  public getCookie(name: string) {
    const matches = this.document.cookie.match(new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  //FIXME корявенько взял из бб как есть
  public setCookie(
    name: string,
    value: any,
    options: {
      expires?: number | Date | string;
      domain?:string;
      path?:string
      httpOnly?: boolean
    }
  ) {
    options = options || {};

    let expires = options.expires;

    if (expires && typeof expires === 'number') {
      const d = new Date();
      d.setTime(d.getTime() + expires * 1000);
      expires = options.expires = d;
    }
    if (expires && typeof expires === 'object') {
      options.expires = expires.toUTCString();
    }

    value = encodeURIComponent(value);

    let updatedCookie = name + '=' + value;

    for (const propName in options) {
      updatedCookie += '; ' + propName;
      const propValue = options[propName as keyof typeof options];
      if (propValue !== true) {
        updatedCookie += '=' + propValue;
      }
    }

    this.document.cookie = `${updatedCookie}; domain=${getBaseDomain(
      window.location.host
    )}`;
  }

  public removeCookie(name: string) {
    this.setCookie(name, '', {
      expires: -1,
    });
  }
}
