import {ILevel} from '../entities';

export const prelude: ILevel = {
  level: -1,
  xp: 0,
  rank: 'string;',
  chevron: 0,
  stars: 0,
  prize: {
    reward: 0,
    count: 0,
    type: 'bonus',
    name: '',
  },
};

export const zeroLevel: ILevel = {
  level: 0,
  xp: 0,
  rank: 'string;',
  chevron: 0,
  stars: 0,
  prize: {
    reward: 0,
    count: 0,
    type: 'bonus',
    name: '',
  },
};
