import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {debounceTime, filter, Subject, takeUntil} from 'rxjs';
import {P2pSettingsService} from './p2p-settings.service';

@Component({
  selector: 'csgofast-p2p-settings',
  templateUrl: './p2p-settings.component.html',
  styleUrls: ['./p2p-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class P2pSettingsComponent implements OnInit, OnDestroy {
  public tradesSettings: FormGroup = this.fb.group({
    duration: 1,
    autoapprove: true,
    quickDelivery: false,
  });
  private destroyed$: Subject<void>;

  constructor(private readonly fb: FormBuilder, private readonly settingsService: P2pSettingsService) {
    this.destroyed$ = new Subject<void>();
    this.settingsService.additionalSettings$
      .pipe(
        takeUntil(this.destroyed$),
        filter((val) => !!val)
      )
      .subscribe((val) => {
        this.tradesSettings.patchValue(
          {
            duration: val.market && val.market.tradesDuration ? val.market.tradesDuration : 1,
            autoapprove: val.market?.autoApprove,
            quickDelivery: val.fastDelivery,
          },
          { emitEvent: false }
        );
      });
  }

  public ngOnInit(): void {
    this.tradesSettings.valueChanges.pipe(debounceTime(700), takeUntil(this.destroyed$)).subscribe((val) => {
      this.changeSettings(val);
    });
  }
  public changeSettings(tradesSettings: { duration: number; autoapprove: boolean; quickDelivery: boolean }) {
    this.settingsService.changeAdditionalSettings({
      fastDelivery: tradesSettings.quickDelivery,
      market: {
        autoApprove: tradesSettings.autoapprove,
        tradesDuration: tradesSettings.duration,
      },
    });
  }
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
