<div class="ny-motivator--container">
  <div class="ny-motivator--header">
    <div *ngIf="eventName">
      <img
        src="/assets/img/ny-event/bonus.png"
        *ngIf="eventName==='ny'"
         alt=""/>
      <img
      src="/assets/img/ny-event/bonus-red.png"
      *ngIf="eventName==='ny_china'"
     alt=""/>
    </div>
    <div
      style="font-size: 22px; font-weight: 600"
      [innerHtml]="'GOFAST.NY_EVENT.SEARCH_EVENT_BONUS_HEADER' | translate"
    ></div>
    <div style="font-size: 18px; font-weight: 400; color: #888b8e">{{ 'GOFAST.NY_EVENT.SEARCH_EVENT_BONUS_DESCRIPTION' | translate }}</div>
  </div>
  <div class="ny-motivator--cards-grid">
    <div class="ny-motivator--cards-grid-header">{{ 'GOFAST.NY_EVENT.SEARCH_EVENT_LAST_ACHIEVEMENTS' | translate }}</div>
    <ng-scrollbar
      track="horizontal"
      visibility="hover"
      [minThumbSize]="30"
    >
      <div
        class="ny-motivator--cards-grid-body"
        *ngIf="motivatorList"
      >
        <csgofast-ny-motivator-item
          *ngFor="let motivatorItem of motivatorList.slice(0, 4)"
          [motivator]="motivatorItem"
        ></csgofast-ny-motivator-item>
      </div>
    </ng-scrollbar>
  </div>
</div>
