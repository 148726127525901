<ng-container
  *ngTemplateOutlet="
    appTpl;
    context: {
      user: user$ | async,
      balance: balance$ | async
    }
  "
></ng-container>

<ng-template
  #appTpl
  let-user="user"
  let-balance="balance"
>
  <div class="game-selector">
    <fast-ui-game-selector [availableGames]="games$ | async">
      <mat-icon
        class="menu-icon"
        svgIcon="games-menu"
      ></mat-icon>
      <mat-icon
        class="menu-icon"
        svgIcon="caret-down"
      ></mat-icon>
    </fast-ui-game-selector>
  </div>
  <div class="common-info">
    <div
      class="balance-widget"
      *ngIf="user"
    >
      <fast-ui-balance-widget [config]="balance"></fast-ui-balance-widget>
    </div>
    <div class="account">
      <fast-ui-mobile-menu
        [links]="links"
        [user]="user"
        [canLevelUp]="(canLevelUp$ | async)!"
        [balance]="balance"
        [currentLang]="(currentLocale$ | async)!"
        [currentTheme]="(theme$ | async)!"
        [themeList]="themeList"
        [langList]="(locales$ | async)!"
        [currentPanel]="currentPanel$ | myAsync"
        (login)="onLogin()"
        (logout)="onLogout()"
        (changeTheme)="onSwitchTheme($event)"
        (changeLang)="onSwitchLang($event)"
        (changePanel)="onChangeActivePanel($event)"
      >
        <csgofast-chat></csgofast-chat>
        <csgofast-notifications></csgofast-notifications>
      </fast-ui-mobile-menu>
    </div>
  </div>
</ng-template>
