import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {ReactiveFormsModule} from '@angular/forms';
import {LanguageDropDownComponent, LanguageDropdownDirective} from './language/language.component';
import {MatIconModule} from '@angular/material/icon';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {LazyLoadImageModule} from "@csgofast/shared/directives";

@NgModule({
  imports: [CommonModule, MatFormFieldModule, MatSelectModule, ReactiveFormsModule, MatIconModule, RouterModule, TranslateModule,LazyLoadImageModule],
  declarations: [LanguageDropDownComponent, LanguageDropdownDirective],
  exports: [LanguageDropDownComponent, LanguageDropdownDirective],
})
export class SharedComponentsModule {}
