import {IUserDetailed, IUserSettingsDetailed, ProfileTypes} from '@dev-fast/types';

export class Init {
  public static readonly type = '[User] Init state';
}
export class InitSuccess {
  public static readonly type = '[User] Init state success';
  constructor(public user?: IUserDetailed) {}
}
export class InitError {
  public static readonly type = '[User] Init state error';
}
export class GetUser {
  public static readonly type = '[User] Get user';
}
export class GetAdditionalSettings {
  public static readonly type = '[User] Get User Additional Settings';
}
export class GetExperience {
  public static readonly type = '[User] Get Experience';
}
export class ChangeAdditionalSettings {
  public static readonly type = '[User] Change User Additional Settings';
  constructor(public payload: IUserSettingsDetailed) {}
}
// export class SetUser {
//   public static readonly type = '[User] Set user';
//   constructor(public payload?: IUserDetailed) {}
// }
export class GetUserCountry {
  public static readonly type = '[User] Get user country';
}
export class GetP2pPermissions {
  public static readonly type = '[User] Get P2p Permissions';
}

export class UpdateCurrentUser {
  public static readonly type = '[User] Upd current user';
  constructor(public payload: Partial<IUserDetailed>) {}
}
export class RefreshCurrentUser {
  public static readonly type = '[User] Refresh current user';
  constructor(public payload: Partial<IUserDetailed>) {}
}
export class Unsubscribe {
  public static readonly type = '[User] Unsubscribe from the mailing list';
  constructor(public email: string) {}
}
export class UpdateEmail {
  public static readonly type = '[User] Upd email';
  constructor(public address: string, public isSubscribed: boolean) {}
}
export class UpdateAvatar {
  public static readonly type = '[User] Upd avatar';
  constructor(public file: Blob) {}
}
export class ConfirmEmail {
  public static readonly type = '[User] Confirm email';
  constructor(public code: number) {}
}
export class SwitchWalletType {
  public static readonly type = '[User] Switch wallet type';
}
export class SwitchWalletTypeComplete {
  public static readonly type = '[User] Switch wallet type complete';
  constructor(public payload: { success: boolean; walletType: number }) {}
}
export class UpdateCoinsAmount {
  public static readonly type = '[User] Update Coins Amount';
  constructor(public payload: number) {}
}
export class UpdateDemoCoinsAmount {
  public static readonly type = '[User] Update Demo Coins Amount';
  constructor(public payload: number) {}
}
export class UpdateXp {
  public static readonly type = '[User] Update Xp';
  constructor(public payload: number) {}
}
export class UpdateAccountType {
  public static readonly type = '[User] Update Account Type';
  constructor(public payload: ProfileTypes) {}
}
