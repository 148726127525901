import {IconConfig} from "./icon.model";

export const iconsClassic: IconConfig[] = [
  {
    name: 'award-limb',
    path: 'assets/img/mat-icons/classic/award-limb.svg',
  },
  {
    name: 'drop-placement',
    path: 'assets/img/mat-icons/classic/drop-placement.svg',
  },
  {
    name: 'lent-pointer',
    path: 'assets/img/mat-icons/classic/lent-pointer.svg',
  },
  {
    name: 'question',
    path: 'assets/img/mat-icons/classic/question.svg',
  },
];
