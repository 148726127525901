import { Injectable } from '@angular/core';
import { FrameMessageTypes, IFrameMessageService } from '@csgofast/core/iframe';
import { LocalStorageService } from '@csgofast/core/local-storage-service';
import {Socket as WrappedSocket} from "ngx-socket-io";
import { NotificationService } from '@dev-fast/backend-services';
import {INotification, IOldNotificationDto, NotificationStatus, NotificationType} from '@dev-fast/types';
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { insertItem, patch, removeItem } from '@ngxs/store/operators';
import moment from 'moment';
import { finalize, Observable, tap } from 'rxjs';

import { NOTIFICATIONS_INITIAL_STATE, NotificationsStateModel } from './notifications-state.model';
import { AddNotification, GetNotifications, RemoveNotification, WatchAllNotification } from './notifications.actions';

@State<NotificationsStateModel>({
  name: 'notification',
  defaults: NOTIFICATIONS_INITIAL_STATE,
})
@Injectable()
export class NotificationsState implements NgxsOnInit {
  @Selector()
  public static items({ items }: NotificationsStateModel): INotification[] {
    return items;
  }
  @Selector()
  public static hasNewItems({ items }: NotificationsStateModel): boolean {
    return items.some((el) => el.status === NotificationStatus.new);
  }
  @Selector()
  public static countNewItems({ items }: NotificationsStateModel): number {
    return items.filter((el) => el.status === NotificationStatus.new).length;
  }
  constructor(
    private readonly store: Store,
    private readonly ws: WrappedSocket,
    private readonly storage: LocalStorageService,
    private readonly apiService: NotificationService,
    private readonly frameMessageService: IFrameMessageService
  ) {}
  ngxsOnInit() {
    this.frameMessageService.on(
      FrameMessageTypes.MESSAGE_FROM_BB,
      'show.noty',
      (payload: { header?: string; text: string; type?: NotificationType; params: Record<string, any>; legacy: boolean }) => {
        if(payload.header && payload.header !== 'Battle'){
          this.store.dispatch(
            new AddNotification({
              id: Date.now(),
              type: payload.type ? payload.type : NotificationType.info,
              icon: payload.type ? payload.type : NotificationType.info,
              category: payload.header,
              message: payload.text,
              createDate: Date.now(),
              system: true,
              status: NotificationStatus.new,
              legacy: payload.legacy,
            })
          )
        }
      }
    );
    this.ws.on('notifications.message.created', (message: INotification) => this.store.dispatch(new AddNotification(message)));
    // this.ws.on('notify:add', (message: IOldNotificationDto) => this.store.dispatch(new AddNotification({
    //   createDate: Date.now(),
    //   icon: message.type ? message.type : NotificationType.info,
    //   id: Date.now(),
    //   status: NotificationStatus.new,
    //   type: message.type,
    //   message: message.body,
    //   category: message.header
    // }))
    // );
    this.ws.on('exception', (exception: INotification) =>
      this.store.dispatch(
        new AddNotification({
          id: Date.now(),
          type: NotificationType.error,
          icon: 'warning',
          message: exception.message,
          createDate: Date.now(),
          system: true,
          status: NotificationStatus.new,
          params: exception?.params ?? {},
        })
      )
    );
  }
  @Action(AddNotification)
  addNotification({ setState, getState }: StateContext<NotificationsStateModel>, { noty }: AddNotification) {
    if (noty.message === undefined || typeof noty.message !== 'string') return;
    // const { items } = getState();
    if (noty.system) {
      const items = this.storage.get('notifications') || [];
      items.length > 9 && items.shift();
      items.push(noty);
      this.storage.set('notifications', items);
    }
    setState(patch({ items: insertItem(noty) }));
  }
  @Action(GetNotifications)
  getNotifications({ setState }: StateContext<NotificationsStateModel>): Observable<INotification[]> {
    return this.apiService.getNotifications().pipe(
      tap((notys: INotification[]) => {
        const itemsInStorage = this.storage.get('notifications') || [];
        const filtered = itemsInStorage.filter(
          (value) =>
            value.status !== NotificationStatus.deleted &&
            value.message &&
            typeof value.message === 'string' &&
            typeof value.type === 'string'
        );
        const sortedNotys = [...notys, ...filtered].sort((a, b) => +moment(b.createDate) - +moment(a.createDate));
        setState(patch({ items: sortedNotys }));
      })
    );
  }
  @Action(RemoveNotification)
  removeNotification(
    { setState, getState }: StateContext<NotificationsStateModel>,
    { notyId }: RemoveNotification
  ): Observable<void> | void {
    const noty = getState().items.find((x) => x?.id === notyId);
    if (!noty) return;
    if (noty.system) {
      const itemsInStorage = this.storage.get('notifications');
      itemsInStorage &&
        this.storage.set(
          'notifications',
          itemsInStorage.filter((noty) => noty.id !== notyId)
        );
      setState(
        patch({
          items: removeItem<INotification>((x) => x?.id === notyId),
        })
      );
    } else {
      return this.apiService.deleteNotification(notyId).pipe(
        finalize(() => {
          setState(
            patch({
              items: removeItem<INotification>((x) => x?.id === notyId),
            })
          );
        })
      );
    }
  }
  @Action(WatchAllNotification)
  watchAllNotification({ setState, getState }: StateContext<NotificationsStateModel>) {
    const { items } = getState();
    if (items.length === 0) return;

    const newItems = items.filter((notification) => notification.status === NotificationStatus.new);

    const newItemsFromStorage = newItems.filter((value) => value.system);
    const newItemsFromServer = newItems.filter((value) => !value.system);

    const watchInState = () => {
      const watchedItems = [...items.map((val) => ({ ...val, status: NotificationStatus.watched }))];
      setState({ items: watchedItems });
    };

    const watchInStorage = () => {
      const itemsInStorage = this.storage.get('notifications');
      itemsInStorage &&
        this.storage.set(
          'notifications',
          itemsInStorage.map((value) => ({ ...value, status: NotificationStatus.deleted }))
        );
    };

    if (newItemsFromStorage.length) {
      watchInStorage();
    }
    if (newItemsFromServer.length) {
      return this.apiService.watchNotifications(newItemsFromServer).pipe(
        tap(() => {
          watchInState();
        })
      );
    } else {
      watchInState();
    }
    return;
  }
}
