<div
  class="ny-motivator-item--container"
  *ngIf="motivator"
>
  <div class="ny-motivator-item--container-column">
    <div>
      <fast-ui-user-avatar
        [userId]="motivator.user.id"
        [url]="motivator.user.avatar"
      ></fast-ui-user-avatar>
    </div>
    <div class="ny-motivator-item--text">{{ motivator.user.name }}</div>
  </div>
  <div class="ny-motivator-item--container-column">
    <div>{{ motivator.passed }}/{{ motivator.total }}</div>
    <div></div>
  </div>
  <div class="ny-motivator-item--container-column">
    <div class="ny-motivator-item--container-column--img">
      <img
        src="/assets/img/ny-event/box1.png"
        [src]="motivator.image"
        alt=""
      />
    </div>
    <div class="ny-motivator-item--text muted">{{ date }}</div>
  </div>
</div>
