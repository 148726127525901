import {Privacy, TradeLinks} from '@dev-fast/types';

export class GetUserStatistics {
  public static readonly type = '[Account] Get user statistics';
  constructor(public userId: string | number) {}
}
export class GetExperienceLeaders {
  public static readonly type = '[Account] Get experience leaders';
}
export class GetLevelsRoadMap {
  public static readonly type = '[Account] Get levels roadMap';
}
export class GetRecentExperienceGainList {
  public static readonly type = '[Account] Get recent experience gain list';
}
export class GetExperienceStatistic {
  public static readonly type = '[Account] Get experience statistic';
}
export class LevelUp {
  public static readonly type = '[Account] Level up';
}
export class ChangePrivacy {
  public static readonly type = '[Account] Change privacy';
  constructor(public payload: Privacy) {}
}
export class UpdateTradeLinks {
  public static readonly type = '[Account] Update trade links';
  constructor(public links: TradeLinks, public apiKey?: string) {}
}
export class UpdateTradeLink {
  public static readonly type = '[Account] Update trade link';
  constructor(public links: TradeLinks) {}
}
export class UpdateApiKey {
  public static readonly type = '[Account] Update Api Key';
  constructor(public apiKey: string) {}
}
export class UpdateApiKeySuccess {
  public static readonly type = '[Account] Update Api Key Success';
}
export class UpdateTradeLinkSuccess {
  public static readonly type = '[Account] Update trade link Success';
}
