import {Injectable} from '@angular/core';
import {LocalStorageService} from '@csgofast/core/local-storage-service';

import {Action, Selector, State, StateContext} from '@ngxs/store';
import {FrameMessageTypes} from '../iframe.model';
import {IFrameMessageService} from '../services/message.service';

import {IFRAME_INITIAL_STATE, IFrameStateModel} from './iframe-state.model';
import {ChangeFrameLocalStorage, SetFrameLoadedStatus} from './iframe.actions';

@State<IFrameStateModel>({
  name: 'iframe',
  defaults: IFRAME_INITIAL_STATE,
})
@Injectable()
export class IFrameState {
  constructor(private readonly storage: LocalStorageService, private readonly frameMessageService: IFrameMessageService) {}

  @Selector()
  public static loaded({ loaded }: IFrameStateModel): boolean {
    return loaded;
  }

  @Action(SetFrameLoadedStatus)
  public setStatus({ patchState, dispatch }: StateContext<IFrameStateModel>): void {
    patchState({ loaded: true });
    const localData = { fastDelivery: this.storage.get('fastDelivery') };
    dispatch(new ChangeFrameLocalStorage(localData, 3000));
  }
  @Action(ChangeFrameLocalStorage)
  public changeFrameLocalStorage(
    { patchState, dispatch }: StateContext<IFrameStateModel>,
    { payload, delay }: ChangeFrameLocalStorage
  ): void {
    // FIXME знаю знаю херь, но фрейм же временно, да потомки?)
    setTimeout(
      () =>
        this.frameMessageService.sendMessage({
          type: FrameMessageTypes.MESSAGE_TO_BB,
          eventName: 'change.localStorage',
          payload,
        }),
      delay ? delay : 0
    );
  }
}
