import {Injectable} from '@angular/core';

import {ICurrencyRate} from '@dev-fast/types';
import {CurrencyState} from '@csgofast/core/state/currency-store';
import {Select, Store} from '@ngxs/store';
import {Observable, tap} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  @Select(CurrencyState.currency)
  public readonly currency$!: Observable<ICurrencyRate | null>;

  constructor(private readonly store: Store) {}

  public convertBySelectedRateKey(key: string, value: number): number {
    const currencyList = this.store.selectSnapshot(CurrencyState.currencyList);

    const currencyRate = currencyList.find((c: ICurrencyRate) => c.key === key);
    if (currencyRate) {
      return parseFloat((value * currencyRate.rate).toFixed(0));
    }
    return 0;
  }
  public revertBySelectedRateKey(key: string, value: number): number {
    const currencyList = this.store.selectSnapshot(CurrencyState.currencyList);
    const currencyRate = currencyList.find((c: ICurrencyRate) => c.key === key);
    if (currencyRate) {
      return parseFloat((value / currencyRate.rate).toFixed(0));
    }
    return 0;
  }
  public convert(value: number): number {
    const currency = this.store.selectSnapshot(CurrencyState.currency);
    if (currency) {
      const { rate } = currency;
      return parseFloat((value * rate).toFixed(3));
    }
    return value;
  }
  public revert(value: number): number {
    const currency = this.store.selectSnapshot(CurrencyState.currency);
    if (currency) {
      const { rate } = currency;
      return parseFloat((value / rate).toFixed(3));
    }
    return value;
  }
}
