import {IconConfig} from "./icon.model";

export const iconsObjects: IconConfig[] = [
  {
    name: 'automate-gun',
    path: 'assets/img/mat-icons/objects/automate-gun.svg',
  },
  {
    name: 'clever',
    path: 'assets/img/mat-icons/objects/clever.svg',
  },
  {
    name: 'coin',
    path: 'assets/img/mat-icons/objects/coin.svg',
  },
  {
    name: 'theme-selector',
    path: 'assets/img/mat-icons/objects/theme-selector.svg',
  },
];
