import {Injectable} from '@angular/core';
import {ChangeAdditionalSettings, UserState} from '@csgofast/core/state/user-store';

import {IUserSettingsDetailed} from '@dev-fast/types';
import {Dispatch} from '@ngxs-labs/dispatch-decorator';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';

@Injectable()
export class P2pSettingsService {
  @Select(UserState.additionalSettings)
  public readonly additionalSettings$!: Observable<IUserSettingsDetailed>;

  @Dispatch() public changeAdditionalSettings = (payload: IUserSettingsDetailed) => new ChangeAdditionalSettings(payload);
}
