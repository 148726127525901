  <div class="wrapper wrapper--desktop"
    [ngClass]="{ 'fade-sides': isFadeSides$ | async }">
    <div class="header">
      <div class="fade-layer" (click)="onClickLockedSide()"></div>
      <csgofast-desktop-header></csgofast-desktop-header>
    </div>
    <div class="content">
      <div class="left-sidebar">
        <div class="fade-layer"
          (click)="onClickLockedSide()"></div>
        <csgofast-desktop-left-sidebar></csgofast-desktop-left-sidebar>
      </div>
      <ng-container *ngTemplateOutlet="template"></ng-container>

      <div class="right-sidebar">
        <div class="fade-layer" (click)="onClickLockedSide()"></div>
        <csgofast-desktop-right-sidebar></csgofast-desktop-right-sidebar>
      </div>
    </div>
  </div>

<ng-template #template let-mode="mode">
  <div class="gofast-layout__content-wrapper main main-background">
<!--    <csgofast-advertisements></csgofast-advertisements>-->
    <div class="iframe">
      <csgofast-iframe>
        <div class="portal-section-header-outlet" *ngIf="sectionHeaderPortal$ | async as sectionHeaderPortal">
          <ng-template [cdkPortalOutlet]="sectionHeaderPortal"></ng-template>
        </div>
        <div class="content">
          <router-outlet></router-outlet>
          <csgofast-recent-activities></csgofast-recent-activities>
        </div>
      </csgofast-iframe>
      <footer>
        <fast-ui-footer></fast-ui-footer>
      </footer>
    </div>
  </div>
</ng-template>

<csgofast-notification></csgofast-notification>
