import {Injectable} from '@angular/core';
import {Environments, EnvironmentService} from '@csgofast/core/environment-service';
import {ISteamStoreInventory} from '@dev-fast/types';
import {Dispatch} from '@ngxs-labs/dispatch-decorator';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';

import {GetSteamInventory, P2pCommonState} from '../states';

@Injectable()
export class P2pCommonService {
  @Select(P2pCommonState.steamInventory)
  public steamItems$!: Observable<ISteamStoreInventory[]>;

  private _environment: Environments;

  constructor(private readonly environmentService: EnvironmentService) {
    this._environment = this.environmentService.environments;
    // console.log('P2pCommonService');
  }

  @Dispatch() public getSteamInventory = () => new GetSteamInventory();
}
