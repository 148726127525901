<div class="notification-wrapper">
  <div
    class="notification"
    [@notificationSlideLeftAnimation]
    (mouseover)="pauseNotifications()"
    (mouseleave)="resumeNotifications()"
    *ngFor="let notification of notifications$ | async; trackBy: trackByIndex"
  >
    <div
      class="notification-close"
      (click)="deleteNotification(notification)"
    >
      <mat-icon>close</mat-icon>
    </div>
    <div [ngClass]="'notification-icon ' + notification.type">
      <mat-icon>{{ notification.icon }}</mat-icon>
    </div>
    <div class="notification-message">
      {{ notification.message | appCurrencyTranslate: notification.params }}
      <!-- {{ notification.message | translate }} -->
      <div
        class="notification-actions"
        *ngIf="notification.actions && notification.actions.length"
      >
        <div
          class="notification-action"
          *ngFor="let action of notification.actions"
          (click)="action.callback ? action.callback() : null"
        >
          <button class="btn btn-outline-primary btn-sm action-button">
            {{ action.name }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
