<ng-container
  *ngIf="{
    eventHistoryName: eventHistoryName$ | async,
    topPlayers: topPlayers$ | async,
    leaderboards: leaderboards$ | async
  } as data"
>
  <div
    class="ny-event--players-top"
    *ngIf="data.topPlayers && data.topPlayers.length && data.eventHistoryName"
  >
    <span
      class="ny-event--players-top--category-header"
      style="padding: 5rem; display: block"
      >{{ 'GOFAST.NY_EVENT.FINAL.TOP' | translate |uppercase}}</span
    >

    <div class="ny-event--players-top--cards-container">
      <div
        class="ny-event--players-top--card"
        *ngFor="let topPlayer of topPlayers(data.topPlayers); let i = index"
      >
        <div class="ny-event--players-top--card-user-avatar">
          <fast-ui-user-avatar
            [userId]="topPlayer.user.id"
            [url]="topPlayer.user.avatar"
          ></fast-ui-user-avatar>
          <mat-icon
            class="ny-event--players-top--card-winner-limb"
            svgIcon="winner-limb"
            [style.color]="limbColor(i)"
          >
          </mat-icon>
          <div class="limb-glow" [style]="glowColor(i)"></div>
        </div>

        <div class="ny-event--players-top--card-user-name">{{ topPlayer.user.name }}</div>
        <div class="ny-event--players-top--card-statistics">
          <div>
            <span class="ny-event--players-top--card-statistics-header text-muted"
              >{{ 'GOFAST.NY_EVENT.FINAL.EARNED_CANDIES' | translate }}:</span
            >
            <div class="ny-event--players-top--card-statistics--keys-container">
              <div class="key">
                <div
                  class="event-currency"
                  [ngClass]="'red-' + eventCurrencyType(data.eventHistoryName)"
                ></div>
                {{ topPlayer.tickets.orangeKeys }}
              </div>
              <div class="key">
                <div
                  class="event-currency"
                  [ngClass]="'green-' + eventCurrencyType(data.eventHistoryName)"
                ></div>
                {{ topPlayer.tickets.greenKeys }}
              </div>
              <div class="key">
                <div
                  class="event-currency"
                  [ngClass]="'blue-' + eventCurrencyType(data.eventHistoryName)"
                ></div>
                {{ topPlayer.tickets.blueKeys }}
              </div>
            </div>
          </div>
          <div class="ny-event--players-top--card-statistics--presents-count">
            <span class="ny-event--players-top--card-statistics-header text-muted"
              >{{ 'GOFAST.NY_EVENT.FINAL.GIFTS_OPENED' | translate }}:</span
            >
            <span>{{ prizesCount(topPlayer.prizes) }}</span>
          </div>
          <div class="ny-event--players-top--card-statistics--gain">
            <span class="ny-event--players-top--card-statistics-header text-muted"
              >{{ 'GOFAST.NY_EVENT.FINAL.SKINS_WON' | translate }}:</span
            >
            <div class="ny-event--players-top--card-statistics--gain-count">
              <span > {{ topPlayer.count }} </span>
<!--              <mat-icon svgIcon="caret-down"></mat-icon>-->
<!--              <span class="gain-currency">{{ topPlayer.total | appCurrency }}</span>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="ny-event--players-statistics"
    *ngIf="data.leaderboards && data.eventHistoryName"
  >
    <span
      class="ny-event--players-top--category-header"
      style="padding: 5rem; display: block; padding-bottom: 3.5rem;"
      >{{ 'GOFAST.NY_EVENT.FINAL.STATISTICS' | translate }}</span
    >
    <div class="ny-event--players-statistics--cards-container">
      <div
        class="ny-event--players-statistics--card"
        *ngFor="let board of leaderBoards(data.leaderboards)"
      >
        <div class="card--header-icon">
          <div
            class="event-currency"
            style="width: 2.5rem; height: 2.5rem"
            [ngClass]="eventCurrencyColor(board.color) + '-' + eventCurrencyType(data.eventHistoryName)"
          ></div>
        </div>
        <div class="card--header">
          <span class="card--header-text"> {{ 'GOFAST.NY_EVENT.FINAL.TOTAL_EARNED' | translate }}</span>
          <div class="card--header-cane">
            <div
              class="event-currency"
              [ngClass]="eventCurrencyColor(board.color) + '-' + eventCurrencyType(data.eventHistoryName)"
            ></div>
            <span>{{ board.totalTickets }}</span>
          </div>
        </div>
        <div class="card-body">
          <div class="table-header text-muted">
            <span>{{ 'GOFAST.NY_EVENT.FINAL.PLACE' | translate }}</span>
            <span>{{ 'GOFAST.NY_EVENT.FINAL.EARNED' | translate }}</span>
          </div>
          <div class="table-body">
            <div
              class="table-body-item"
              *ngFor="let player of board.players"
            >
              <div class="table-body-item-user">
                <span class="user-place text-muted">{{ player.place }}</span>
                <fast-ui-user-avatar
                  [userId]="player.user.id"
                  [url]="player.user.avatar"
                ></fast-ui-user-avatar>
                <span class="nickname-text">{{ player.user.name }}</span>
              </div>
              <div class="table-body-item-reward">
                <div
                  class="event-currency"
                  [ngClass]="eventCurrencyColor(board.color) + '-' + eventCurrencyType(data.eventHistoryName)"
                ></div>
                <span>{{ player.tickets }}</span>
              </div>
            </div>

            <div
              class="table-body-item dashed"
              *ngIf="board.firstTop > 0"
            >
              <span class="user-place text-muted">{{ board.players.length + 1 }} - {{ board.firstTop }}</span>
              <div class="dash-border"></div>
              <div class="table-body-item-reward">
                <div
                  class="event-currency"
                  [ngClass]="eventCurrencyColor(board.color) + '-' + eventCurrencyType(data.eventHistoryName)"
                ></div>
                <span>{{ board.players[board.players.length - 1].tickets - 1 }}</span>
              </div>
            </div>
            <div
              class="table-body-item dashed"
              *ngIf="board.secondTop > 0"
            >
              <span class="user-place text-muted">{{ board.firstTop + 1 }} - {{ board.secondTop }}</span>
              <div class="dash-border"></div>
              <div class="table-body-item-reward">
                <div
                  class="event-currency"
                  [ngClass]="eventCurrencyColor(board.color) + '-' + eventCurrencyType(data.eventHistoryName)"
                ></div>
                <span>{{ board.players[board.players.length - 1].tickets - 2 }}</span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="card-divider"
          *ngIf="board.myStats"
        >
          <div class="circle circle-left"></div>
          <div class="dashed-line"></div>
          <div class="circle circle-right"></div>
        </div>
        <div
          class="card-results"
          *ngIf="board.myStats"
        >
          <div class="results-item">
            <span class="results-item-title">{{ 'GOFAST.NY_EVENT.FINAL.YOUR_PLACE' | translate }}:</span>
            <span class="results-item-value text-muted">{{ placeCount(board.myStats.place) }}</span>
          </div>
          <div class="results-item">
            <span class="results-item-title">{{ 'GOFAST.NY_EVENT.FINAL.YOUR_EARN' | translate }}:</span>
            <span class="results-item-value text-muted">{{ board.myStats.tickets }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
