import { Component,  NgModule, ChangeDetectionStrategy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { MatIconModule } from "@angular/material/icon";

@Component({
  selector: 'csgofast-gleam-modal',
  templateUrl: './gleam-modal.component.html',
  styleUrls: ['./gleam-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GleamModalComponent  {
  constructor(private readonly dialogRef: MatDialogRef<GleamModalComponent> ) {}
  public onClose(){
  this.dialogRef.close()
  }
}

@NgModule({
  imports: [CommonModule,MatIconModule],
  declarations: [GleamModalComponent],
  exports: [GleamModalComponent],
})
export class GleamModalComponentModule {}
