import { Component, Input} from '@angular/core';
import { EventsConfig, ICasesQuestItem } from '@dev-fast/types';

@Component({
  selector: 'csgofast-gifts-grid',
  templateUrl: './gifts-grid.component.html',
  styleUrls: ['./gifts-grid.component.scss'],
})
export class GiftsGridComponent {
  @Input() public presents: ICasesQuestItem[] | undefined | null;
  @Input() public eventName: keyof EventsConfig | null | undefined;
  @Input() public isEventComplete: boolean | undefined;

  // @Output()
  // public pickUpPrize: EventEmitter<ICasesQuestItem[]> = new EventEmitter<ICasesQuestItem[]>();
  constructor() {
    //
  }
}
