import {Component, ElementRef, EventEmitter, Input, NgModule, OnDestroy, Output} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {CommonModule} from '@angular/common';
import {execute, RecaptchaService} from './recaptcha.service';
import {EnvironmentService} from '@csgofast/core/environment-service';

declare global {
  interface Window {
    onRecaptchaLoaded: () => any;
    grecaptcha: any;
  }
}
export interface IReCaptchaSettings {
  theme: 'dark' | 'light';
  size: 'compact' | 'normal';
}

enum ReCaptchaSettings {
  theme = 'dark',
  size = 'normal',
}

@Component({
  selector: 'csgofast-recaptcha',
  template: '',
})
export class RecaptchaComponent implements OnDestroy {
  public executed$: Observable<execute>;
  private destroyed$: Subject<void>;
  @Input() public theme?: IReCaptchaSettings['theme'];
  @Input() public size?: IReCaptchaSettings['size'];

  @Output() public resolve = new EventEmitter<string>();
  @Output() public reject = new EventEmitter<string>();

  constructor(
    private recaptchaService: RecaptchaService,
    private elementRef: ElementRef,
    private readonly environmentService: EnvironmentService
  ) {
    this.executed$ = this.recaptchaService.executed$;
    this.destroyed$ = new Subject();
    this.subscribeEmitters();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private subscribeEmitters(): void {
    this.executed$
      .pipe(
        filter((v) => v === execute.loaded),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => {
        this.onRender();
      });
  }

  private onRender(): void {
    window.grecaptcha.render(this.elementRef.nativeElement, {
      sitekey: this.environmentService.environments.RECAPTCHA_SITE_KEY,
      size: this.size || ReCaptchaSettings.size,
      theme: this.theme || ReCaptchaSettings.theme,
      callback: (key: string) => this.onResponse(key),
      'expired-callback': this.onExpired,
    });
  }

  private onResponse(key: string): void {
    this.resolve.emit(key);
  }

  private onExpired(): void {
    this.reject.emit();
  }
}
@NgModule({
  declarations: [RecaptchaComponent],
  imports: [CommonModule],
  exports: [RecaptchaComponent],
})
export class RecaptchaModule {}
