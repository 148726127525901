// FIXME доработать с датами
export function sortBy(value: any[], key: string, order?: 'asc' | 'desc') {
  if (!Array.isArray(value)) throw Error(`InvalidArgument: '${value}' for sortBy`);
  if (order) return value.sort((l, r) => (l[key] && r[key] ? (order === 'asc' ? l[key] - r[key] : r[key] - l[key]) : 0));
  return value.sort((l, r) => (l[key] && r[key] ? (l[key] > r[key] ? -1 : 1) : 0));
}

export function sortByPriority(value: Array<any>, key: string, priorityList: PriorityList, order: 'asc' | 'desc' = 'asc'): Array<any> {
  if (!Array.isArray(value)) throw Error(`InvalidArgument: '${value}' for sortByPriority`);
  if (!value.every(element => !!Object.keys(element).length)) throw Error(`InvalidObjectType: '${value}' for sortByPriority`);

  const sortedByPriority: {
    high: Array<any>,
    medium: Array<any>,
    low: Array<any>,
    unprioritized: Array<any>
  } = {
    high: [],
    medium: [],
    low: [],
    unprioritized: []
  }
  value.forEach(element => {
    if (priorityList.high && priorityList.high.includes(element[key])) {
      sortedByPriority.high.push(element);
      return;
    }
    if (priorityList.medium && priorityList.medium.includes(element[key])) {
      sortedByPriority.medium.push(element);
      return;
    }
    if (priorityList.low && priorityList.low.includes(element[key])) {
      sortedByPriority.low.push(element);
      return;
    }
    sortedByPriority.unprioritized.push(element);
  });
  return [
    ...sortBy(sortedByPriority.high, key, order),
    ...sortBy(sortedByPriority.medium, key, order),
    ...sortBy(sortedByPriority.low, key, order),
    ...sortBy(sortedByPriority.unprioritized, key, order),
  ]
}

export interface PriorityList {
  high?: Array<string | number>,
  medium?: Array<string | number>,
  low?: Array<string | number>,
}
