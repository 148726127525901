import { PortalModule } from '@angular/cdk/portal';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecentActivitiesWidgetsModule } from '@csgofast/widgets/recent-activities';
import { GofastLayoutComponent } from './gofast-layout.component';
import { RouterModule } from '@angular/router';
import { MobileAppHeaderModule } from '@csgofast/mobile-app/header';
import { DesktopAppHeaderModule } from '@csgofast/desktop-app/header';
import { DesktopAppLeftSidebarModule } from '@csgofast/desktop-app/left-sidebar';
import { DesktopAppRightSidebarModule } from '@csgofast/desktop-app/right-sidebar';
import { AdvertisementsModule, NotificationsModule } from '@csgofast/notifications';
import { CoreIframeModule } from '@csgofast/core/iframe';
import { P2pModule } from '@csgofast/modules/p2p';
import { ChatModule } from '@csgofast/widgets/chat';
import { ChatService } from './services/chat.service';
import { NotificationsWidgetsModule } from '@csgofast/widgets/notifications';
import { NotificationsService } from './services/notifications.service';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { FooterModule } from "@dev-fast/ui-components";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MobileAppHeaderModule,
    P2pModule,
    CoreIframeModule,
    DesktopAppHeaderModule,
    DesktopAppLeftSidebarModule,
    DesktopAppRightSidebarModule,
    NgScrollbarModule,
    NotificationsModule,
    ChatModule.forRoot(ChatService),
    NotificationsWidgetsModule.forRoot(NotificationsService),
    AdvertisementsModule,
    PortalModule,
    RecentActivitiesWidgetsModule,
    FooterModule,
  ],
  declarations: [GofastLayoutComponent],
  exports: [GofastLayoutComponent],
})
export class GofastLayoutModule {}
