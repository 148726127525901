import {Component, Input} from '@angular/core';
import {configs, EventsConfig, ICasesQuestItem, ICasesQuestStatus} from '@dev-fast/types';
import {Observable, tap} from 'rxjs';
import {NyEventService} from '../../../core';

@Component({
  selector: 'csgofast-ny-event-case-motivator',
  templateUrl: './ny-event-case-motivator.component.html',
  styleUrls: ['./ny-event-case-motivator.component.scss'],
})
export class NyEventCaseMotivatorComponent {
  public item$!: Observable<ICasesQuestItem | undefined>;

  public progress: number;

  @Input() public disableBtn: boolean;
  @Input() public set categoryId(categoryId: number | string | undefined) {
    if (!categoryId) return;
    this.item$ = this.nyEventService.getItemById(+categoryId).pipe(
      tap((item) => {
        this.progress = this.calcProgress(item);
      })
    );
  }
  public eventName$: Observable<keyof EventsConfig | null> | undefined;

  constructor(private readonly nyEventService: NyEventService) {
    this.progress = 0;
    this.disableBtn = false;
    this.eventName$ = this.nyEventService.eventName$;

  }

  public onOpenCase(item: ICasesQuestItem): void {
    this.nyEventService.pickUpPrize(item);
  }

  public canShow(item: ICasesQuestItem): boolean {
    return( item.status === ICasesQuestStatus.BLOCKED || item.status === ICasesQuestStatus.AVAILABLE) ;
  }

  public calcProgress(item: ICasesQuestItem | undefined): number {
    const progress = item ? (item.progress / item.count) * 100 : 0;
    return progress > 100 ? 100 : progress;
  }
}
