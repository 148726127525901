import {IFilterMethod} from "../entities";
import { DefaulSortingTypes} from "../enums";

export const DefaultSortMethods:IFilterMethod<DefaulSortingTypes>[] = [
  { value: DefaulSortingTypes.DEFAULT, viewValue: 'DEFAULT' },
  { value: DefaulSortingTypes.MIN_PRICE, viewValue: 'MIN_PRICE' },
  { value: DefaulSortingTypes.MAX_PRICE, viewValue: 'MAX_PRICE' },
  { value: DefaulSortingTypes.MIN_NAME, viewValue: 'MIN_NAME' },
  { value: DefaulSortingTypes.MAX_NAME, viewValue: 'MAX_NAME' },
  // { value: SortingCasesTypes.MIN_NAME, viewValue: 'NAME_ZA' },
  // { value: SortingCasesTypes.MAX_NAME, viewValue: 'NAME_AZ' },
]
