import {Injectable} from '@angular/core';
import {Dispatch} from '@ngxs-labs/dispatch-decorator';
import {SetFrameLoadedStatus} from '../state/iframe.actions';

@Injectable()
export class IFrameCommonService {
  constructor() {
    // console.log('IFrameCommonService');
  }

  @Dispatch() public setFrameStatus = () => new SetFrameLoadedStatus();
}
