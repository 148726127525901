import {IconConfig} from "./icon.model";

export const iconsInventory: IconConfig[] = [
  {
    name: 'contract-direction',
    path: 'assets/img/mat-icons/inventory/contract-direction.svg',
  },
  {
    name: 'contract-drag-n-drop',
    path: 'assets/img/mat-icons/inventory/contract-drag-n-drop.svg',
  },
  {
    name: 'contract-piece',
    path: 'assets/img/mat-icons/inventory/contract-piece.svg',
  },
  {
    name: 'exchange',
    path: 'assets/img/mat-icons/inventory/exchange.svg',
  },
  {
    name: 'inventory-bag',
    path: 'assets/img/mat-icons/inventory/inventory-bag.svg',
  },
  {
    name: 'refresh',
    path: 'assets/img/mat-icons/inventory/refresh.svg',
  },
  {
    name: 'withdrawal',
    path: 'assets/img/mat-icons/inventory/withdrawal.svg',
  },
];
