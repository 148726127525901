import {ChangeDetectionStrategy, Component, Input, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'fast-ui-online-widget',
  templateUrl: './online-widget.component.html',
  styleUrls: ['./online-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnlineWidgetComponent {
  @Input() public count: number;
  constructor() {
    this.count = 0;
  }
}

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [OnlineWidgetComponent],
  exports: [OnlineWidgetComponent],
})
export class OnlineWidgetComponentModule {}
