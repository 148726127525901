import {ActivePanel, ILocales, Panel, Themes, Widgets} from '@dev-fast/types';

export class ChangeTheme {
  public static readonly type = '[Layout] Change theme';
  constructor(public theme: Themes) {}
}
export class ChangeAllowedPanels {
  public static readonly type = '[Layout] Change Allowed Panels';
  constructor(public panels: Panel[]) {}
}
export class ChangeActivePanel {
  public static readonly type = '[Layout] Change active panel';
  constructor(public panel: ActivePanel | null) {}
}
export class OpenAvailablePanel {
  public static readonly type = '[Layout] Open Available Panel';
}
export class ChangeActiveWidgets {
  public static readonly type = '[Layout] Change active widgets';
  constructor(public widgets: Widgets[]) {}
}
export class RefreshActivePanel {
  public static readonly type = '[Layout] Refresh active panel';
  constructor(public payload: Partial<ActivePanel> | null) {}
}
export class ToggleGameSelector {
  public static readonly type = '[Layout] Toggle Game Selector';
  constructor(public active: boolean) {}
}
export class LockSides {
  public static readonly type = '[Layout] Lock Sides';
}
export class UnLockSides {
  public static readonly type = '[Layout] UnLock Sides';
}
export class ClickOnLockedSide {
  public static readonly type = '[Layout] Click On Locked Side';
}
export class LockPanels {
  public static readonly type = '[Layout] Lock Panels';
  constructor(public panels: Panel[]) {}
}
export class UnLockPanels {
  public static readonly type = '[Layout] UnLock Panels';
  constructor(public panels: Panel[]) {}
}
export class SetLayoutType {
  public static readonly type = '[Layout] Set Layout Type';
  constructor(public type: string) {}
}
export class ToggleBackground {
  public static readonly type = '[Layout] Toggle Background';
  constructor(public background?: string) {}
}

export class ToggleTabletByWidth {
  public static readonly type = '[Layout] Toggle isTableByWidth';
  constructor(public isTablet: boolean) {}
}
export class NavigateToMobile {
  public static readonly type = '[Layout] Navigate To Mobile';
}


/** не используется */
export class NavigateToBack {
  public static readonly type = '[Layout] Navigate To Back';
}
