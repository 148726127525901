import {CurrencyPipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ICurrencyRate} from '@dev-fast/types';
import {CurrencyService} from '@csgofast/core/currency-service';
import {Observable} from 'rxjs';

@Component({
  selector: 'csgofast-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrencyComponent {
  @Input() public value = 0;
  public readonly currency$: Observable<ICurrencyRate | null> = this.currencyService.currency$;
  constructor (
    private readonly currencyPipe: CurrencyPipe,
    private readonly currencyService: CurrencyService,
  ) {
  }

  public transform (currency: ICurrencyRate | null): { rest: string, sum: string } {
    if (currency === null) {
      return { sum: '0', rest: '0' }
    }
    const value = this.currencyPipe.transform(this.currencyService.convert(this.value), '') || '';
    const [big, rest] = value.split('.');
    const [sum] = big.split(currency.prefix).filter((value: string) => value);
    return { sum, rest }
  }
}
