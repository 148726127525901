import {CommonModule} from '@angular/common';
import {Component, NgModule} from '@angular/core';

@Component({
  selector: 'fast-ui-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {}

@NgModule({
  imports: [CommonModule],
  declarations: [CardComponent],
  exports: [CardComponent],
})
export class CardComponentModule {}
