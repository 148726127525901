import {Inject, NgModule} from '@angular/core';
import {MatIconModule, MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {IconConfig} from "./icon.model";
import {iconsFunctional} from "./functional.index";
import {iconsArrows} from "./arrows.index";
import {iconsMenu} from "./menu.index";
import {iconsWidgets} from "./widget.index";
import {iconsGames} from "./games.index";
import {iconsNotifications} from "./notifications.index";
import {iconsSocials} from "./socials.index";
import {iconsUser} from "./user.index";
import {iconsInformational} from "./information.index";
import {iconsObjects} from "./objects.index";
import {iconsLogos} from "./logos.index";
import {iconsMedia} from "./media.index";
import {iconsCases} from "./cases.index";
import {iconsClassic} from "./classic.index";
import {iconsCommon} from "./common.index";
import {iconsDouble} from "./double.index";
import {iconsEsport} from "./esport.index";
import {iconsFaucet} from "./faucet.index";
import {iconsHeader} from "./header.index";
import {iconsNumbers} from "./numbers.index";
import {iconsHilo} from "./hilo.index";
import {iconsInventory} from "./inventory.index";
import {iconsLevels} from "./levels.index";
import {iconsPaymentProviders} from "./payment-providers.index";
import {iconsPaymentSystems} from "./payment-systems.index";
import {iconsPeerToPeer} from "./peer-to-peer.index";
import {iconsPremiumTypes} from "./premium-types.index";
import {iconsSettings} from "./settings.index";
import {iconsSupport} from "./support.index";
import {iconsWidgetsMenu} from "./widget-menu.index";
import {iconsWithdraw} from "./withdraw.index";
import {iconsMoon} from "./icomoon.index";
import {iconsRightSidebar} from "./right-sidebar";
import {iconsBugBounty} from "./bug-bounty";
import {substratesImg} from "./substrates.index";
import {iconsEmojis} from './emojis.index';
import {iconsLeftSidebar} from './left-sidebar.index';

const icons = [
  ...iconsArrows,
  ...iconsGames,
  ...iconsLogos,
  ...iconsMenu,
  ...iconsNotifications,
  ...iconsObjects,
  ...iconsSocials,
  ...iconsWidgets,
  ...iconsFunctional,
  ...iconsUser,
  ...iconsInformational,
  ...iconsMedia,
  ...iconsCases,
  ...iconsClassic,
  ...iconsCommon,
  ...iconsDouble,
  ...iconsEsport,
  ...iconsFaucet,
  ...iconsHeader,
  ...iconsNumbers,
  ...iconsHilo,
  ...iconsInventory,
  ...iconsLevels,
  ...iconsPaymentProviders,
  ...iconsPaymentSystems,
  ...iconsPeerToPeer,
  ...iconsPremiumTypes,
  ...iconsSettings,
  ...iconsSupport,
  ...iconsWidgetsMenu,
  ...iconsWithdraw,
  ...iconsMoon,
  ...iconsRightSidebar,
  ...iconsLeftSidebar,
  ...iconsBugBounty,
  ...substratesImg,
  ...iconsEmojis,
];
@NgModule({
  imports: [MatIconModule],
  exports: [MatIconModule],
})
export class UiIconsModule {
  constructor(
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    @Inject('isServer') private readonly isServer: boolean,
    ) {
    icons.forEach((icon) => this.add(icon));
  }

  private add(config: IconConfig): void {
    this.matIconRegistry.addSvgIcon(config.name,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
          this.isServer ? `http://localhost:${process.env['PORT'] || 4000}/` + config.path : config.path)
    );
  }
}
