import {Injectable} from '@angular/core';
import {CasesBackendService} from '@dev-fast/backend-services';
import {ICaseRevisionInfo} from '@dev-fast/types';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CaseRevisionService {

  public caseRevision$: Observable<ICaseRevisionInfo> | undefined

  constructor(private casesBackendService: CasesBackendService) { }

  public getCaseRevisionById(id: number, round: number):  Observable<ICaseRevisionInfo> {
   return this.casesBackendService.getCaseRevisionById(id, round)
  }
}
