import { IAudio } from './sound.interface';

// const soundSourceUrl = 'https://d2lomvz2jrw9ac.cloudfront.net/audio';
const soundSourceUrl = '/assets/sounds';

export const cases_sound: IAudio[] = [
  {
    name: 'STANDART_START',
    shortName: 'STANDART',
    description: 'STANDART_START',
    group: 'cases_sound',
    phase: 'START',
    link: `${soundSourceUrl}/cases/start/standart/STANDART_START.mp3`,
  },
  {
    name: 'STANDART_START_FAST',
    shortName: 'STANDART',
    description: 'STANDART_START_FAST',
    group: 'cases_sound',
    phase: 'START',
    type: 'FAST',
    link: `${soundSourceUrl}/cases/start/standart/STANDART_START_FAST.mp3`,
  },
  {
    name: 'DANGER_START',
    shortName: 'DANGER',
    description: 'DANGER_START',
    group: 'cases_sound',
    phase: 'START',
    link: `${soundSourceUrl}/cases/start/danger/DANGER_START.mp3`,
  },
  {
    name: 'DANGER_START_FAST',
    shortName: 'DANGER',
    description: 'DANGER_START_FAST',
    group: 'cases_sound',
    phase: 'START',
    type: 'FAST',
    link: `${soundSourceUrl}/cases/start/danger/DANGER_START_FAST.mp3`,
  },
  {
    name: 'JINGLE_START',
    shortName: 'JINGLE',
    description: 'JINGLE_START',
    group: 'cases_sound',
    phase: 'START',
    link: `${soundSourceUrl}/cases/start/jingle/JINGLE_START.mp3`,
  },
  {
    name: 'JINGLE_START_FAST',
    shortName: 'JINGLE',
    description: 'JINGLE_START_FAST',
    group: 'cases_sound',
    phase: 'START',
    type: 'FAST',
    link: `${soundSourceUrl}/cases/start/jingle/JINGLE_START_FAST.mp3`,
  },
  {
    name: 'MIX_START',
    shortName: 'MIX',
    description: 'MIX_START',
    group: 'cases_sound',
    phase: 'START',
    link: `${soundSourceUrl}/cases/start/mix/MIX_START.mp3`,
  },
  {
    name: 'MIX_START_FAST',
    shortName: 'MIX',
    description: 'MIX_START_FAST',
    group: 'cases_sound',
    phase: 'START',
    type: 'FAST',
    link: `${soundSourceUrl}/cases/start/mix/MIX_START_FAST.mp3`,
  },

  {
    name: 'STANDART_RAFFLE',
    shortName: 'STANDART',
    description: 'STANDART_RAFFLE',
    group: 'cases_sound',
    phase: 'RAFFLE',
    link: `${soundSourceUrl}/cases/raffle/standart/STANDART_RAFFLE.mp3`,
  },
  {
    name: 'STANDART_RAFFLE_FAST',
    shortName: 'STANDART',
    description: 'STANDART_RAFFLE_FAST',
    group: 'cases_sound',
    phase: 'RAFFLE',
    type: 'FAST',
    link: `${soundSourceUrl}/cases/raffle/standart/STANDART_RAFFLE_FAST.mp3`,
  },
  {
    name: 'DANGER_RAFFLE',
    shortName: 'DANGER',
    description: 'DANGER_RAFFLE',
    group: 'cases_sound',
    phase: 'RAFFLE',
    link: `${soundSourceUrl}/cases/raffle/danger/DANGER_RAFFLE.mp3`,
  },
  {
    name: 'DANGER_RAFFLE_FAST',
    shortName: 'DANGER',
    description: 'DANGER_RAFFLE_FAST',
    group: 'cases_sound',
    phase: 'RAFFLE',
    type: 'FAST',
    link: `${soundSourceUrl}/cases/raffle/danger/DANGER_RAFFLE_FAST.mp3`,
  },
  {
    name: 'JINGLE_RAFFLE',
    shortName: 'JINGLE',
    description: 'JINGLE_RAFFLE',
    group: 'cases_sound',
    phase: 'RAFFLE',
    link: `${soundSourceUrl}/cases/raffle/jingle/JINGLE_RAFFLE.mp3`,
  },
  {
    name: 'JINGLE_RAFFLE_FAST',
    shortName: 'JINGLE',
    description: 'JINGLE_RAFFLE_FAST',
    group: 'cases_sound',
    phase: 'RAFFLE',
    type: 'FAST',
    link: `${soundSourceUrl}/cases/raffle/jingle/JINGLE_RAFFLE_FAST.mp3`,
  },
  {
    name: 'MIX_RAFFLE',
    shortName: 'MIX',
    description: 'MIX_RAFFLE',
    group: 'cases_sound',
    phase: 'RAFFLE',
    link: `${soundSourceUrl}/cases/raffle/mix/MIX_RAFFLE.mp3`,
  },
  {
    name: 'MIX_RAFFLE_FAST',
    shortName: 'MIX',
    description: 'MIX_RAFFLE_FAST',
    group: 'cases_sound',
    phase: 'RAFFLE',
    type: 'FAST',
    link: `${soundSourceUrl}/cases/raffle/mix/MIX_RAFFLE_FAST.mp3`,
  },

  {
    name: 'STANDART_END',
    shortName: 'STANDART',
    description: 'STANDART_END',
    group: 'cases_sound',
    phase: 'END',
    link: `${soundSourceUrl}/cases/end/standart/STANDART_END.mp3`,
  },
  {
    name: 'STANDART_END_FAST',
    shortName: 'STANDART',
    description: 'STANDART_END_FAST',
    group: 'cases_sound',
    phase: 'END',
    type: 'FAST',
    link: `${soundSourceUrl}/cases/end/standart/STANDART_END_FAST.mp3`,
  },
  {
    name: 'DANGER_END',
    shortName: 'DANGER',
    description: 'DANGER_END',
    group: 'cases_sound',
    phase: 'END',
    link: `${soundSourceUrl}/cases/end/danger/DANGER_END.mp3`,
  },
  {
    name: 'DANGER_END_FAST',
    shortName: 'DANGER',
    description: 'DANGER_END_FAST',
    group: 'cases_sound',
    phase: 'END',
    type: 'FAST',
    link: `${soundSourceUrl}/cases/end/danger/DANGER_END_FAST.mp3`,
  },
  {
    name: 'JINGLE_END',
    shortName: 'JINGLE',
    description: 'JINGLE_END',
    group: 'cases_sound',
    phase: 'END',
    link: `${soundSourceUrl}/cases/end/jingle/JINGLE_END.mp3`,
  },
  {
    name: 'JINGLE_END_FAST',
    shortName: 'JINGLE',
    description: 'JINGLE_END_FAST',
    group: 'cases_sound',
    phase: 'END',
    type: 'FAST',
    link: `${soundSourceUrl}/cases/end/jingle/JINGLE_END_FAST.mp3`,
  },
  {
    name: 'MIX_END',
    shortName: 'MIX',
    description: 'MIX_END',
    group: 'cases_sound',
    phase: 'END',
    link: `${soundSourceUrl}/cases/end/mix/MIX_END.mp3`,
  },
  {
    name: 'MIX_END_FAST',
    shortName: 'MIX',
    description: 'MIX_END_FAST',
    group: 'cases_sound',
    phase: 'END',
    type: 'FAST',
    link: `${soundSourceUrl}/cases/end/mix/MIX_END_FAST.mp3`,
  },

];


// export const poggiSounds: IAudio[] = [{ name: 'poggi', description: 'SOUND.POGGI.GAME', group: 'poggi', link: `` }];

// ToDo: add all sounds
