import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { configs, EventsConfig, ICasesQuestItem } from '@dev-fast/types';
import { Observable } from 'rxjs';
import { NyEventService } from '../../../../core/services/ny-event.service';

@Component({
  selector: 'csgofast-gift-card',
  templateUrl: './gift-card.component.html',
  styleUrls: ['./gift-card.component.scss'],
})
export class GiftCardComponent {
  @Input() public present: ICasesQuestItem | undefined;
  @Input() public cardNumber: number | undefined;
  @Input() public eventName: keyof EventsConfig | null | undefined;
  @Input() public isEventComplete: boolean | undefined;

  // @Output()
  // public pickUpPrize: EventEmitter<ICasesQuestItem> = new EventEmitter<ICasesQuestItem>();

  constructor(private readonly nyEventService: NyEventService) {
    //
  }
  public get questProgress() {
    return this.present ? (this.present.progress * 100) / this.present.count : 0;
  }

  //fixme move to ny-event.component
  public pickupReward(present: ICasesQuestItem): void {
    // this.pickUpPrize.next(present)
    this.nyEventService.pickUpPrize(present);
  }

  // public onPresentClick(id: number) {
  //   this.router.navigate(['/game/cases/categories/' + id], { fragment: 'casesMain' });
  // }
}
