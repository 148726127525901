import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { EnvironmentService } from '@csgofast/core/environment-service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GamesGuard implements CanActivate {
  constructor(private readonly router: Router, private readonly environmentService: EnvironmentService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const path = route.routeConfig && route.routeConfig.path ? route.routeConfig.path : '';
    const hasModule = (path: string) => this.environmentService.getEnvironment().MODULES.games.includes(path);
    return hasModule(path) ? true : hasModule('classic') ? this.router.navigate(['game/classic']) : this.router.navigate(['not-found']);
  }
}
