import {CommonModule} from '@angular/common';
import {Component, HostBinding, Input, NgModule} from '@angular/core';

@Component({
  selector: 'fast-ui-cube-grid-loader',
  templateUrl: './cube-grid.component.html',
  styleUrls: ['./cube-grid.component.scss'],
})
export class CubeGridLoaderComponent {
  @Input() size = '2rem';
  @Input() color = '#333';

  @HostBinding(`style.--sk-size`)
  public get sizeVar(): string {
    return this.size;
  }
  @HostBinding(`style.--sk-color`)
  public get colorVar(): string {
    return this.color;
  }
}
@NgModule({
  imports: [CommonModule],
  declarations: [CubeGridLoaderComponent],
  exports: [CubeGridLoaderComponent],
})
export class CubeGridLoaderComponentModule {}
