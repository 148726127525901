import {Injectable} from '@angular/core';
import {Environments, EnvironmentService} from '@csgofast/core/environment-service';
import {
  EmailSubscribe,
  IChatAccess,
  IGameStatistics,
  IPublicUserDetailed,
  IUser,
  IUserDetailed,
  IUserExperience,
  IUserP2pPermissionInterface,
  IUserSettings,
  Privacy,
  TradeLinks,
  UserCountry,
} from '@dev-fast/types';
// FIXME сокеты
// import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { MAIN_LANG } from "@csgofast/core/language-service";

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  private environment: Environments;
  private endpoint = 'api/users';
  //TODO убрать any везде
  constructor(
    private readonly api: ApiService,
    private readonly environmentService: EnvironmentService //  private readonly ws: Socket
  ) {
    this.environment = this.environmentService.environments;
    // console.log('userService');
    // this.ws.emit('message', 'msg');

    // this.ws.on('user.coinsAmount.updated', (coinsAmount: number) => {
    //   console.log(coinsAmount);
    // });
  }

  // public listenCoinsAmount(): Observable<number> {
  //   return this.ws.fromEvent<number>('user.coinsAmount.updated');
  // }
  public savePrivacy(payload: Privacy): Observable<any> {
    return this.api.patch<any>(`${this.environment.GATEWAY_URL}/${this.endpoint}/me/privacy`, payload, { withCredentials: true });
  }

  public updateTradeLinks(links: TradeLinks): Observable<any> {
    return this.api.patch<any>(`${this.environment.GATEWAY_URL}/${this.endpoint}/me/links`, links, {
      withCredentials: true,
    });
  }

  public updateApiKey(key: string): Observable<any> {
    return this.api.patch(`${this.environment.GATEWAY_URL}/${this.endpoint}/me/steam-api-key`, { key }, { withCredentials: true });
  }

  public patchUserData(data: Partial<IUserDetailed>): Observable<void> {
    return this.api.patch<void>(`${this.environment.GATEWAY_URL}/${this.endpoint}/me`, data, { withCredentials: true });
  }
  public getMeSettings(): Observable<IUserSettings> {
    return this.api.get<IUserSettings>(`${this.environment.GATEWAY_URL}/${this.endpoint}/me/settings`, { withCredentials: true });
  }
  public patchUserSettings(data: IUserSettings): Observable<IUserSettings> {
    return this.api.patch<IUserSettings>(`${this.environment.GATEWAY_URL}/${this.endpoint}/me/settings`, data, { withCredentials: true });
  }

  public updateUserAvatar(file: Blob): Observable<{ avatar: string }> {
    const formData = new FormData();
    formData.append('file', file);
    return this.api.put<{ avatar: string }>(`${this.environment.GATEWAY_URL}/${this.endpoint}/me/avatar`, formData, {
      withCredentials: true,
    });
  }

  public getMeProfile(params: object): Observable<IUserDetailed> {
    return this.api.get<IUserDetailed>(`${this.environment.GATEWAY_URL}/${this.endpoint}/me`, { withCredentials: true, params });
  }
  public getMeExperience(): Observable<IUserExperience> {
    return this.api.get<IUserExperience>(`${this.environment.GATEWAY_URL}/${this.endpoint}/me/experience`, { withCredentials: true });
  }
  // FIXME возвращаемый тип подправить
  public getUserProfile(id: number, params: object): Observable<IUser | IPublicUserDetailed> {
    return this.api.get<IUser | IPublicUserDetailed>(`${this.environment.GATEWAY_URL}/${this.endpoint}/${id}`, {
      withCredentials: true,
      params,
    });
  }
  public getUserCountry = (): Observable<UserCountry> => this.api.get(`${this.environment.GATEWAY_URL}/api/country`);

  public getP2pPermissions = (): Observable<IUserP2pPermissionInterface> =>
    this.api.get(`${this.environment.GATEWAY_URL}/api/market/steam/check-user`, { withCredentials: true });

  public getUserStatistics(id: string | number): Observable<IGameStatistics[]> {
    return this.api.get<IGameStatistics[]>(`${this.environment.GATEWAY_URL}/${this.endpoint}/${id}/statistics`, {
      withCredentials: true,
    });
  }

  public checkCanChat(): Observable<IChatAccess> {
    return this.api.get<IChatAccess>(`${this.environment.GATEWAY_URL}/${this.endpoint}/me/chat-access`, { withCredentials: true });
  }

  public switchWalletType(): Observable<void> {
    return this.api.post<void>(`${this.environment.GATEWAY_URL}/${this.endpoint}/me/balance/switch`, {}, { withCredentials: true });
  }

  public unsubscribeFromEmails(address: string): Observable<EmailSubscribe> {
    return this.api.post(
      `${this.environment.GATEWAY_URL}/${this.endpoint}/me/email/unsubscribe`,
      { address, domain: this.environment.PROJECT },
      { withCredentials: true }
    );
  }

  public patchEmail(address: string, isSubscribed: boolean): Observable<EmailSubscribe> {
    return this.api.patch(
      `${this.environment.GATEWAY_URL}/${this.endpoint}/me/email`,
      { address, isSubscribed, lang: MAIN_LANG, domain: this.environment.PROJECT },
      { withCredentials: true }
    );
  }

  public confirmEmail(code: number): Observable<EmailSubscribe> {
    return this.api.post(
      `${this.environment.GATEWAY_URL}/${this.endpoint}/me/email/confirm`,
      { code, domain: this.environment.PROJECT },
      { withCredentials: true }
    );
  }
}
