import {Injectable} from '@angular/core';
import {Socket as WrappedSocket} from "ngx-socket-io";
import {RefreshCurrentUser, UserState} from '@csgofast/core/state/user-store';
import {addEventToGleam} from '@csgofast/core/state/utils';
import {PayService} from '@dev-fast/backend-services';
import {IPaymentGate, IPaymentMethod, IPayments} from '@dev-fast/types';

import {Action, NgxsOnInit, Selector, State, StateContext, Store} from '@ngxs/store';
import {Observable, tap} from 'rxjs';

import {PAYMENT_INITIAL_STATE, PaymentStateModel} from './payment-state.model';
import {CheckPaymentTrust, GetItems, SuccessfulRefill} from './payment.actions';
import {filterMethod} from './utils';
import {AnalyticsService} from "@csgofast/core/analytics-service";

@State<PaymentStateModel>({
  name: 'payment',
  defaults: PAYMENT_INITIAL_STATE,
})
@Injectable()
export class PaymentState implements NgxsOnInit {
  constructor(private readonly apiService: PayService, private readonly analytics:AnalyticsService,private readonly store: Store, private readonly ws: WrappedSocket) {}

  @Selector()
  public static payments({ payments }: PaymentStateModel): IPayments | null {
    return payments;
  }
  public ngxsOnInit(ctx: StateContext<any>): void {
    this.ws.on('deposit:confirmed', ({ amount }:any) => {
      ctx.dispatch(new SuccessfulRefill(amount));
    });
  }

  @Action(RefreshCurrentUser)
  public refreshUser({ dispatch }: StateContext<PaymentStateModel>, { payload }: RefreshCurrentUser): void {
    const userId = this.store.selectSnapshot(UserState.user)?.id;

    if (payload.refillCountry && payload.refillCountry !== null && userId) {
      // dispatch(new GetItems(payload.refillCountry, userId));
    }
  }

  @Action(GetItems)
  public get({ patchState }: StateContext<PaymentStateModel>, { country, userId }: GetItems): Observable<IPayments> {
    return this.apiService.getPaymentMethodsByCountry(country, userId).pipe(
      tap((methods) => {
        const { popular, regional, lastSuccess, systems, ...rest } = methods;

        /** Disclaimer:
         Очередное "впадлу делать, нахуярьте на фронтенде"
         **/
        const cryptoTemplate: {
          title: string;
          gates: IPaymentGate[];
          group_name: string;
          name: string;
          is_enabled: boolean;
          is_terminal: boolean;
        } = {
          title: 'Crypto',
          gates: [],
          group_name: 'crypto',
          name: 'crypto',
          is_enabled: true,
          is_terminal: false,
        };
        const crypto = regional
          .filter((method: IPaymentMethod) => method.group_name === 'crypto')
          .reduce((cryptoMethod, method) => {
            const gates = method.gates.map((gate: IPaymentGate) => ({ ...gate, title: method.title, name: method.name }));
            cryptoMethod.gates = [...cryptoMethod.gates, ...gates];
            return cryptoMethod;
          }, cryptoTemplate);
        const copyPopular = [...popular, crypto];
        /**  **/

        const payments: IPayments = {
          popular: filterMethod(copyPopular),
          regional: filterMethod(regional),
          systems: filterMethod(systems),
          lastSuccess: lastSuccess ? filterMethod([lastSuccess])[0] : null,
          ...rest,
        };

        patchState({
          payments,
        });
      })
    );
  }

  @Action(CheckPaymentTrust)
  public checkTrust(
    { patchState }: StateContext<PaymentStateModel>,
    { userId }: CheckPaymentTrust
  ): Observable<void | {
    status: boolean;
  }> {
    return this.apiService.checkPaymentTrust(userId).pipe(
      tap((response) =>
        patchState({
          paymentTrust: response ? response.status : true,
        })
      )
    );
  }
  @Action(SuccessfulRefill)
  public successfulRefill({ patchState, dispatch }: StateContext<PaymentStateModel>, {amount}:SuccessfulRefill) {
    const user = this.store.selectSnapshot(UserState.user);
    if (user && user.id) {
      addEventToGleam('deposit', user.id);
      this.analytics.successfulDepositEvent({amount})
    }
  }
}
