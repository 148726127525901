import { LANGUAGE_INITIAL_STATE, LanguageStateModel } from "./language.state.model";
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { makeStateKey, TransferState } from "@angular/platform-browser";
import { GetLocales, SetLanguage } from "./language.action";
import { LocalizationApiService } from '@dev-fast/backend-services';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LocalStorageService } from '@csgofast/core/local-storage-service';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from "./language.service";
import { MAIN_LANG } from "./language.const";
import { DOCUMENT } from '@angular/common';
import { ILocales } from '@dev-fast/types';
import { tap } from 'rxjs/operators';

@State<LanguageStateModel>({
  name: 'language',
  defaults: LANGUAGE_INITIAL_STATE,
})
@Injectable()
export class LanguageState implements NgxsOnInit {
  private readonly CURRENT_LANGUAGE_KEY = makeStateKey('currentLang');

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject('isServer') private isServer: boolean,
    @Inject(DOCUMENT) private doc: Document,
    private readonly deviceService: DeviceDetectorService,
    private readonly apiService: LocalizationApiService,
    private readonly languageService: LanguageService,
    private readonly storage: LocalStorageService,
    private readonly transferState: TransferState,
    private readonly translate: TranslateService
  ) {}

  @Selector()
  public static locales({ locales }: LanguageStateModel): ILocales[] {
    return locales.filter((el: ILocales) => el.isActive);
  }

  @Selector()
  public static lang({ lang }: LanguageStateModel): ILocales {
    return lang;
  }

  /**
   * Если мы находимся на клиенте, то при инициализации запрашиваем локали с рендера
   */
  public ngxsOnInit({ getState, dispatch, patchState }: StateContext<LanguageStateModel>) {
    if(!this.isServer) {
      const defaultLocale = this.transferState.get(this.CURRENT_LANGUAGE_KEY, LanguageService.getRouteLanguages()[MAIN_LANG] as any);
      if (LanguageService.isAcceptedLanguageCode(defaultLocale.path)) {
        dispatch(new SetLanguage({...defaultLocale, isLocal: true}));
      }
    }
  }

  /**
   * Обновление <html lang="  ">
   */
  updateHeadLang(lang: string): void {
    this.doc.querySelector('html')?.setAttribute('lang', lang);
  }

  @Action(SetLanguage)
  public setLanguage({ patchState }: StateContext<LanguageStateModel>, { locale }: SetLanguage) {
    console.log(123, locale);
    return this.translate.use(locale.isLocal ? 'default-locale': locale.path).pipe(
      tap(() => {
        console.log(2);
        this.updateHeadLang(locale.path.substring(0, 2));
        patchState({
          lang: locale,
        });
        if (this.isServer) {
          this.transferState.set(this.CURRENT_LANGUAGE_KEY, locale as any);
        } else {
          // BB не нужно передавать денные, которые передаются из ССР. Передадим их после загрузки актуальных
          if(!locale.isLocal) {
            localStorage.setItem('lang', locale.path);
            this.languageService.updateUrlState(locale);
            this.languageService.sendLocaleToBB(locale);
          }
        }
      }));
  }

  /**
   * Запрос локалей
   */
  @Action(GetLocales)
  public getLocales({ patchState }: StateContext<LanguageStateModel>) {
    return this.apiService.getAvailableLocales().pipe(
      tap((locales) =>
        patchState({
          locales,
        })
      ),
    );
  }
}
