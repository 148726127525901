import {Injectable} from '@angular/core';
import {Environments, EnvironmentService} from '@csgofast/core/environment-service';
import {Socket as WrappedSocket} from "ngx-socket-io";
import {IGameSettings} from '@dev-fast/types';

import {Observable} from 'rxjs';
import {ApiService} from './api.service';

@Injectable({
  providedIn: 'root',
})
export class GameService {
  private environment: Environments;

  constructor(
    private readonly api: ApiService,
    private readonly environmentService: EnvironmentService,
    private readonly ws: WrappedSocket
  ) {
    this.environment = this.environmentService.environments;
    // console.log('gameService');
    // this.ws.on('user:update', (user: any) => {
    //   console.log(user);
    // });
  }

  public listenOnline(): Observable<number> {
    return this.ws.fromEvent<number>('online');
  }
  public getAvailableGames = (): Observable<IGameSettings[]> =>
    this.api.get<IGameSettings[]>(`${this.environment.GATEWAY_URL}/api/games-settings`);

  public getGameSettingsByKey = (key: string): Observable<IGameSettings> =>
    this.api.get<IGameSettings>(`${this.environment.GATEWAY_URL}/api/games-settings?game=${key}`, { withCredentials: true });
  // .pipe(
  //   switchMap((games: IGameSettings[]) =>
  //     environment.production
  //       ? of(games.filter((game: IGameSettings) => game.enabled))
  //       : of(games.filter((game: IGameSettings) => environment.MODULES.games.includes(game.name))),
  //   ),
  //   tap((games: IGameSettings[]) =>
  //     games.forEach((game: IGameSettings) => this.gamesSettings.set(game.name, game)),
  //   ),
  //   map((games: IGameSettings[]) => games.map((game: IGameSettings) =>
  //     ({ key: game.name, mode: game.mode, title: game.title, description: game.description })),
  //   ),
  // );
}
