<div class="item-container" [class]="indexColor(index)">
    <div class="item-info item-info_main">
        <div class="left-box">
            <ng-container *ngIf="!!avatarSrc">
                <div class="avatar-wrapper">
                    <img class="avatar-image" lazyLoadImage [src]="avatarSrc"  alt=""/>
                </div>
            </ng-container>
            <div class="item-weapon">
                <ng-container *ngIf="!!weaponName">
                    <span class="item-name">{{ weaponName }} |</span>
                </ng-container>
            </div>
        </div>
        <div class="right-box">
            <ng-container *ngIf="!!skinName">
                <span class="skin-name">{{ skinName }}</span>
            </ng-container>
            <ng-container *ngIf="!!skinWear">
                <span class="skin-name">({{ skinWear }})</span>
            </ng-container>
        </div>
            <!-- <div class="item-avatar-wrapper" 
                matTooltip="{{ !!weaponName ? weaponName + ' |' : '' }} {{ skinName || '' }} {{ skinWear }}" matTooltipPosition="right">
                <img class="item-avatar" lazyLoadImage [src]="avatarSrc" />
            </div> -->
    </div>
    <div class="item-info item-info_stats">
        <div class="price">
            <span class="title">{{ 'GOFAST.CASES.MODAL.REVISION.PRICE' | translate }}: </span>
            <span class="stat">{{ price | appCurrency }}</span>
        </div>
        <ng-container *ngIf="range">
            <div class="range">
                <span class="title">{{ 'GOFAST.CASES.MODAL.REVISION.RANGE' | translate }}: </span>
                <span class="stat">
                <ng-container *ngIf="range.min">
                    {{ range.min }}
                </ng-container>
                <ng-container *ngIf="range.max">
                    - {{ range.max }}
                </ng-container></span>
            </div>
        </ng-container>
        <div class="chance">
            <span class="title">{{ 'GOFAST.CASES.MODAL.REVISION.CHANCE' | translate }}: </span>
            <span class="stat">{{ calcChancePercent(chance) }}</span>
        </div>
    </div>
</div>
