import {InjectionToken} from '@angular/core';
import {INotification} from '@dev-fast/types';
import {Observable} from 'rxjs';

export const NOTIFICATIONS_ENGINE = new InjectionToken('NOTIFICATIONS_ENGINE');

export interface NotificationsEngine {
  readonly notifications$: Observable<INotification[]>;

  removeNotification(id: number): void;
  watchAllNotifications(): void;
  closeWidget(): void;
}
