import {IPriceFilter} from "../entities";

export const DefaultPriceFilters: IPriceFilter[] = [
  { minValue: null, maxValue: null, viewValue: 'ALL' },
  { minValue: 0, maxValue: 899, viewValue: '◎ 0 - 8.99' },
  { minValue: 900, maxValue: 4999, viewValue: '◎ 9 - 49.99' },
  { minValue: 5000, maxValue: 14999, viewValue: '◎ 50 - 149.99' },
  { minValue: 15000, maxValue: 35000, viewValue: '◎ 150 - 350' },
  // { minValue: 0, maxValue: 0, viewValue: 'FREE' },
]
