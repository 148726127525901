import {Inject, Injectable} from '@angular/core';
import {EnvironmentService} from '@csgofast/core/environment-service';
import {FrameMessageTypes, IFrameMessageService} from '@csgofast/core/iframe';
import {LocalStorageService} from '@csgofast/core/local-storage-service';
import {Socket as WrappedSocket} from "ngx-socket-io";
import {GetInventoryItems, InventoryState} from '@csgofast/core/state/inventory';
import {ChangeActivePanel, LayoutState} from '@csgofast/core/state/layout';
import {UserState} from '@csgofast/core/state/user-store';
import {addEventToGleam} from '@csgofast/core/state/utils';
import {throttle} from "@csgofast/shared/utils";

import {GameService} from '@dev-fast/backend-services';
import {
  GameMode,
  GAMES,
  GameStatusesFF,
  IActivities,
  IGame,
  IGameSettings,
  IInventoryItem,
  LegacyGameConfig,
  LegacyGameState,
  LegacyLiteGameState,
  Panel,
  SubPanel,
} from '@dev-fast/types';
import {Action, NgxsOnInit, Selector, State, StateContext, Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';
import {GAMES_INITIAL_STATE, GamesStateModel} from './games-state.model';
import {
  ChangeConfigLegacy,
  ChangeLiteStateLegacy,
  ChangeStateLegacy,
  ConfirmParticipateLegacy,
  ConfirmParticipateLegacySuccess,
  FindGameSettings,
  GetAllGamesSettings,
  ParticipateLegacy,
  PlaceBetLegacy,
  RefreshOnlineCount,
  SetCurrentGame,
  SetCurrentGameActivities,
  StartAutoBetLegacy,
  StopAutoBetLegacy,
  SuccessfulBid,
  UpdStatuses,
} from './games.actions';
import {AnalyticsService} from "@csgofast/core/analytics-service";
import {makeStateKey, TransferState} from "@angular/platform-browser";
import {LotteryStateModel} from "@csgofast/core/state/lottery";

@State<GamesStateModel>({
  name: 'games',
  defaults: GAMES_INITIAL_STATE,
})
@Injectable()
export class GamesState implements NgxsOnInit {
  private readonly AVAILABLE_GAMES_KEY = makeStateKey('availableGames');

  constructor(
    private readonly apiService: GameService,
    private readonly store: Store,
    private readonly storage: LocalStorageService,
    private readonly environmentService: EnvironmentService,
    private readonly frameMessageService: IFrameMessageService,
    private readonly ws: WrappedSocket,
    private readonly analytics:AnalyticsService,
    private readonly transferState: TransferState,
    @Inject('isServer') private isServer: boolean,
  ) {
  }

  @Selector()
  public static currentGameActivities({ currentGameActivities }: GamesStateModel): IActivities | null {
    return currentGameActivities;
  }

  @Selector()
  public static online({ online }: GamesStateModel): number {
    return online;
  }

  @Selector()
  public static currentGameSettings({ currentGame }: GamesStateModel): IGameSettings | null {
    return currentGame;
  }

  @Selector()
  public static currentGame({ currentGame }: GamesStateModel): IGame | null {
    if (!currentGame) return currentGame;
    return GamesState.mapToIGame(currentGame);
  }

  @Selector()
  public static gamesList({ gamesList }: GamesStateModel): IGameSettings[] {
    return gamesList;
  }

  @Selector()
  public static availableGames({ availableGames }: GamesStateModel): IGame[] {
    return availableGames;
  }

  @Selector()
  public static statuses({ statuses }: GamesStateModel): GameStatusesFF {
    return statuses;
  }

  @Selector()
  public static autoBetIsOn({ autoBetIsOn }: GamesStateModel): boolean {
    return autoBetIsOn;
  }

  @Selector()
  public static legacyConfig({ legacyConfig }: GamesStateModel): LegacyGameConfig | null {
    return legacyConfig;
  }

  @Selector()
  public static legacyState({ legacyState }: GamesStateModel): LegacyGameState | null {
    return legacyState;
  }

  @Selector()
  public static legacyLiteState({ legacyLiteState }: GamesStateModel): LegacyLiteGameState | null {
    return legacyLiteState;
  }

  @Selector()
  public static legacyParticipation({ legacyParticipation }: GamesStateModel): IInventoryItem[] {
    return legacyParticipation;
  }

  @Selector()
  static gameTitle(state: GamesStateModel) {
    return (key: string) => {
      const game = state.gamesList.find((game) => game.name === key);
      return game !== undefined ? (game.title ? game.title : `${key} game`) : `${key} game`;
    };
  }

  static mapToIGame(game: IGameSettings): IGame {
    return {
      key: this.linksDictionary(game.name),
      mode: game.mode || GameMode.PVE,
      title: game.title || '',
      description: game.description,
    };
  }

  static linksDictionary(name: string): string {
    const dictionary: any = {
      'wheel': 'fortune-wheel',
      'bets': 'match-bets',
      'case_battle': 'case-battle'
    }
    return dictionary[name] ? dictionary[name] : name
  }

  ngxsOnInit({patchState}: StateContext<any>) {
    this.frameMessageService.on(
      FrameMessageTypes.MESSAGE_FROM_BB,
      'change.gameStatus',
      (payload: { statuses: GameStatusesFF }) => {
        const gameSelectorOpened = this.store.selectSnapshot(LayoutState.gameSelectorOpened);
        if (gameSelectorOpened) this.store.dispatch(new UpdStatuses(payload.statuses));
      },
      900
    );

    this.frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'participation', (payload: { gameName: 'classic' | 'fast' }) =>
      this.store.dispatch(new PlaceBetLegacy(payload.gameName))
    );

    this.frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'SuccessfulBid', (payload: { gameName: string }) =>
      this.store.dispatch(new SuccessfulBid(payload.gameName))
    );

    this.frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'startAutoBet', (payload: { gameName: 'x50' | 'crash' }) =>
      this.store.dispatch(new StartAutoBetLegacy(payload.gameName))
    );

    this.frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'stopAutoBet', (payload: { gameName: 'x50' | 'crash' }) =>
      this.store.dispatch(new StopAutoBetLegacy(payload.gameName))
    );

    this.frameMessageService.on(
      FrameMessageTypes.MESSAGE_FROM_BB,
      'ChangeStateLegacy',
      (payload: { state: LegacyGameState; gameName: 'classic' | 'fast' }) =>
        this.store.dispatch(new ChangeStateLegacy(payload.state, payload.gameName))
    );

    this.frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'ChangeConfigLegacy', (payload: LegacyGameConfig) =>
      this.store.dispatch(new ChangeConfigLegacy(payload))
    );

    this.frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'ChangeLiteStateLegacy', (payload: LegacyLiteGameState) =>
      this.store.dispatch(new ChangeLiteStateLegacy(payload))
    );

    this.frameMessageService.on(
      FrameMessageTypes.MESSAGE_FROM_BB,
      'participated',
      (payload: { items: IInventoryItem[]; timeout: number }) =>
        this.store.dispatch([
          new ConfirmParticipateLegacy(payload),
          // new UnselectInventoryItemById(payload.items.map((el) => el.id)),
        ])
    );

    this.ws.on('online', throttle((amount: string) => this.store.dispatch(new RefreshOnlineCount(Number(amount))), 15000));

    const availableGames = this.transferState.get(this.AVAILABLE_GAMES_KEY, [] as any);
    if(availableGames) {
      patchState({availableGames});
    }
  }
  // FIXME один большой костыль. Я прям кайфану когда выпилю фрейм
  @Action(ConfirmParticipateLegacy)
  public confirmParticipateLegacy(
    { dispatch, patchState, getState }: StateContext<GamesStateModel>,
    { payload }: ConfirmParticipateLegacy
  ) {
    const { curLegacyGame } = getState();
    return dispatch(new ConfirmParticipateLegacySuccess(payload)).pipe(
      // delay(payload.timeout * 1000),
      switchMap(() => dispatch([new GetInventoryItems()])),
      tap(() => {
        if (curLegacyGame === 'fast') {
          patchState({
            legacyParticipation: payload.items,
          });
        }
      })
    );
  }

  @Action(SetCurrentGameActivities)
  public setCurrentGameActivities({ patchState }: StateContext<GamesStateModel>, { activities }: SetCurrentGameActivities) {
    patchState({ currentGameActivities: activities });
  }

  @Action(ChangeConfigLegacy)
  public changeConfigLegacy({ patchState }: StateContext<GamesStateModel>, { payload }: ChangeConfigLegacy) {
    patchState({ legacyConfig: payload });
  }

  @Action(ChangeStateLegacy)
  public changeStateLegacy({ patchState }: StateContext<GamesStateModel>, { payload }: ChangeStateLegacy) {
    const user = this.store.selectSnapshot(UserState.user);
    const newItem = payload.trades.find((trade) => trade.playerId === user?.id);

    patchState({
      legacyLastGameNumber: payload.number,
      legacyState: payload,
      legacyParticipation: newItem ? newItem.original : [],
    });
  }

  @Action(ChangeLiteStateLegacy)
  public changeLiteStateLegacy({ patchState }: StateContext<GamesStateModel>, { payload }: ChangeLiteStateLegacy) {
    patchState({ legacyLiteState: payload });
  }

  @Action(StartAutoBetLegacy)
  public startAutoBetLegacy({ patchState }: StateContext<GamesStateModel>, { gameName }: StartAutoBetLegacy) {
    patchState({ curLegacyGame: gameName, autoBetIsOn: true });
  }

  @Action(StopAutoBetLegacy)
  public stopAutoBetLegacy({ patchState }: StateContext<GamesStateModel>, { gameName }: StopAutoBetLegacy) {
    patchState({ curLegacyGame: gameName, autoBetIsOn: false });
  }

  @Action(GetAllGamesSettings)
  public get({ patchState }: StateContext<GamesStateModel>): Observable<IGameSettings[]> {
    return this.apiService.getAvailableGames().pipe(
      tap((response) => {
        const env = this.environmentService.getEnvironment();
        // const isProduction = env.production;
        const enabledModules = GAMES.filter((game) => env.MODULES.games.includes(game.key));
        const mapedResponse = response.filter((el) => el.mode && el.enabled).map((el) => GamesState.mapToIGame(el))
        const availableGames = mapedResponse.filter(el=>enabledModules.map(v=>v.key).includes(el.key))
        // const availableGames = isProduction
        //   ? response.filter((el) => el.mode && el.enabled).map((el) => GamesState.mapToIGame(el))
        //   : GAMES.filter((game) => env.MODULES.games.includes(game.key));

        if(this.isServer) {
          this.transferState.set(this.AVAILABLE_GAMES_KEY, [] as any)
        }

        patchState({ gamesList: response, availableGames });
      })
    );
  }

  @Action(FindGameSettings)
  public find({ patchState, getState }: StateContext<GamesStateModel>, { key }: FindGameSettings) {
    const { gamesList } = getState();
    return this.apiService.getGameSettingsByKey(key).pipe(
      tap((response) => {
        patchState({ gamesList: [...gamesList, response], currentGame: response });
      })
    );
  }

  @Action(SetCurrentGame)
  public set({ patchState, dispatch, getState }: StateContext<GamesStateModel>, { key }: SetCurrentGame) {
    const { gamesList } = getState();
    const currentGame = gamesList.find((game) => game.name === key);

    if (currentGame !== undefined) {
      patchState({
        currentGame,
      });
    } else {
      dispatch(new FindGameSettings(key));
    }
  }

  @Action(RefreshOnlineCount, { cancelUncompleted: true })
  public refreshOnlineCount({ patchState }: StateContext<GamesStateModel>, { count }: RefreshOnlineCount) {
    patchState({ online: count });
  }

  @Action(UpdStatuses, { cancelUncompleted: true })
  public updStatuses({ patchState }: StateContext<GamesStateModel>, { statuses }: UpdStatuses) {
    patchState({ statuses });
  }

  // TODO может вместо curLegacyGame сетить эти игр как и другие
  @Action(PlaceBetLegacy)
  public placeBetLegacy({ patchState, dispatch }: StateContext<GamesStateModel>, { gameName }: PlaceBetLegacy) {
    patchState({ curLegacyGame: gameName });
    dispatch(new ChangeActivePanel({ panel: Panel.INVENTORY, subPanel: SubPanel.BET }));
  }

  @Action(ParticipateLegacy)
  public participateLegacy() {
    // const {curLegacyGame} =getState();
    const selectedItems = this.store.selectSnapshot(InventoryState.selectedItems);

    this.frameMessageService.sendMessage({
      type: FrameMessageTypes.MESSAGE_TO_BB,
      eventName: 'betLegacyGame',
      payload: { items: selectedItems },
    });
  }
  @Action(SuccessfulBid)
  public successfulBid({ patchState, dispatch }: StateContext<GamesStateModel>, { gameName }: SuccessfulBid) {
    const user = this.store.selectSnapshot(UserState.user);
    if (user && user.id) {
      addEventToGleam(gameName, user.id);
      this.analytics.betEvent(gameName)
    }
  }
}
