import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {
  configs,
  EventsConfig,
  ICaseItemDtoV2,
  ILRBalance, MatchPopTypeImg,
  MatchTicketTypeBalanceField,
  MatchTicketTypeImg
} from '@dev-fast/types';
import {filter, Observable, of, Subject, switchMap, takeUntil} from 'rxjs';
import {NyAllEventService, NyEventService} from '../../../core';

@Component({
  selector: 'csgofast-ny-event-btn',
  templateUrl: './ny-event-btn.component.html',
  styleUrls: ['./ny-event-btn.component.scss'],
})
export class NyEventBtnComponent implements OnDestroy {
  @Input() case: ICaseItemDtoV2 | undefined;
  @Input() count: number;
  @Input() set refreshBalance(val: boolean) {
    if (val) {
      this.refreshTicketsBalance();
    }
  }
  @Output() openCase: EventEmitter<void>;

  public ticketsImgDic = MatchTicketTypeImg;
  public popImgDic = MatchPopTypeImg;
  public balance: ILRBalance | undefined | null;
  private readonly destroyed$: Subject<void>;
  public eventName$: Observable<keyof EventsConfig | null> | undefined;

  public get sum(): number {
    if (this.case && this.case.ticketQuest) {
      return (this.count <= 5 ? this.count : 5) * this.case.ticketQuest.ticketsAmount;
    }
    return 0;
  }
  public get isDisabled(): boolean {
    // const isExceededCount = this.count > 5;
    // const notBalance = !this.balance
    // console.log(this.balance)
    // console.log(this.case)
    // console.log(this.balance?this.balance[MatchTicketTypeBalanceField[this.case?.ticketQuest?.ticketColor as keyof typeof MatchTicketTypeBalanceField] as keyof ILRBalance]:'aaaa')
    // console.log(this.sum)
    const notEnoughMoney =
      (this.balance &&
        this.case &&
        this.case.ticketQuest &&
        this.balance[
          MatchTicketTypeBalanceField[this.case.ticketQuest?.ticketColor as keyof typeof MatchTicketTypeBalanceField] as keyof ILRBalance
        ] < this.sum) ||
      this.sum === 0;
    // console.log(!this.balance || notEnoughMoney)
    return !this.balance || notEnoughMoney;
  }
// public get hasTime():boolean{
//  return diff(configs['ny']?.hide)>0
// }
  constructor(private readonly allEventService: NyAllEventService,private readonly cdr:ChangeDetectorRef, private readonly eventService:NyEventService) {
    this.count = 1;
    this.openCase = new EventEmitter<void>(false);
    this.destroyed$ = new Subject();
    // this.allEventService.purchaseCaseSuccess$.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
    //   if (this.case && this.case.freeCaseId && value.freeCaseId === this.case.freeCaseId) {
    //     this.openCase.emit();
    //   }
    // });

    this.eventName$ = this.eventService.eventName$.pipe(switchMap(value => value !== null? of(value):this.eventService.eventHistoryName$));
    this.allEventService.keys$.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      this.balance = value;
      this.cdr.markForCheck();
    });
  }
  public refreshTicketsBalance() {
    this.allEventService.getTickets();
  }
  public openCaseForKeys(item: ICaseItemDtoV2) {
    this.openCase.emit();
    // if (item.freeCaseId && item.ticketColor) {
    // this.allEventService.purchaseCase(item.freeCaseId, item.ticketColor);
    // }
  }
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
