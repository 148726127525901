import {INotification} from '@dev-fast/types';

export class AddNotification {
  static readonly type = '[Notifications] Add Notification';
  constructor(public readonly noty: INotification) {}
}
export class GetNotifications {
  static readonly type = '[Notifications] Fetch notifications';
  // constructor(public readonly noty: INotification[]) {}
}
export class RemoveNotification {
  static readonly type = '[Notifications] Remove notification';
  constructor(public readonly notyId: number) {}
}
export class WatchAllNotification {
  static readonly type = '[Notifications] Watch all notification';
  // constructor(public readonly notys: INotification[]) {}
}
