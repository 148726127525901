import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgModule, OnDestroy, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { AppCurrencyModule, AppPercentModule } from '@csgofast/shared/pipes';
import { IEmojiInfo } from '@dev-fast/types';
import { emojiAnimation } from './emojis.animation';
import { Subject, debounceTime, takeUntil } from 'rxjs';
import anime from 'animejs';
import { debounce } from '@csgofast/shared/utils';

@Component({
  selector: 'fast-ui-emojis-bar',
  templateUrl: './emojis-bar.component.html',
  styleUrls: ['./emojis-bar.component.scss'],
  animations: [emojiAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmojisBarComponent implements OnDestroy {
  @Input() public emojis: IEmojiInfo[] | null = null;
  @Input() public isSticky = false;
  @Input() public bufferSize = 3;
  @Input() public canSend = true;
  @Input() public set emojisByTimeline(timeline: Record<number, number>) {
    this.animSubject$.next(timeline)
  }
  @Output() public onchangeEmoji: EventEmitter<number> = new EventEmitter() 
  
  private destroy$: Subject<void> = new Subject();
  private animSubject$: Subject<Record<number, number>> = new Subject();
  private animation: anime.AnimeInstance | null = null;
  private activeIndex = 0;
  public bufferArray = new Array(this.bufferSize)

  constructor() {
    this.changeEmoji = debounce(this.changeEmoji.bind(this), 150);
    this.animSubject$.pipe(
      takeUntil(this.destroy$),
      debounceTime(0)
      ).subscribe((val) => {
      this.animTimelineEmojis(val)
    })
  }
  ngOnDestroy(): void {
    this.destroy$.next()
    this.animation = null
  }
  private animTimelineEmojis(timeline: Record<number, number>): void {
    Object.keys(timeline).forEach((key: string) => {
      const time = Number(key)
      const emojiId: number = timeline[time]
      this.emodjiAnim(emojiId, time)
    })
  }
  private emodjiAnim(id: number, delay: number = 0): void {
    this.animation = anime({
      targets: '.emoji_' + id + '_index_' + this.activeIndex,
      delay: delay,
      keyframes: [
        {
          opacity: 0,
          top: '0',
          duration: 0,
          zIndex: 1
        },
        {
          opacity: 1,          
          top: '-2.075rem',
          duration: 300,
          easing: 'easeOutQuart',
          zIndex: -1
        },
        {
          opacity: 0,
          top: '0',
          duration: 1500,
          delay: 150,
          easing: 'easeOutQuart',
          zIndex: -1
        },
      ],
      complete: (anim) => {
        anim.tick(0)
      },
    })
    this.activeIndex = this.activeIndex + 1;
    if (this.activeIndex >= this.bufferSize) {
      this.activeIndex = 0
    } 
    this.animation.finished.then(() => {
      this.animation?.tick(0)
      this.animation = null
    })
  }
  public changeEmoji(emojiId: number): void {
    if (this.canSend) {
      this.onchangeEmoji.emit(emojiId)
    }
  }
}
@NgModule({
  declarations: [EmojisBarComponent],
  imports: [CommonModule, AppCurrencyModule, AppPercentModule, MatIconModule],
  exports: [EmojisBarComponent],
})
export class EmojisBarModule {}
