import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DecimalBoldFormatDirective } from './decimal-bold-format.directive'; 

@NgModule({
  declarations: [DecimalBoldFormatDirective],
  imports: [CommonModule],
  exports: [DecimalBoldFormatDirective],
})
export class DecimalBoldFormatModule {}
