<a
  class="games-item"
  [routerLink]="'/game/' + game.key"
  [routerLinkActive]="'selected'"
  tabindex="1"
>
  <mat-icon
    class="menu-item__icon"
    [svgIcon]="'logo-' + game.key"
  ></mat-icon>
  <!-- <div
  class="games-item"
  [ngClass]="[game.key]"
> -->
  <!-- <div class="games-item-wrapper">
    <img
      class="games-item-substrate"
      [src]="getGameSrc(game, 'substrate')"
      [ngClass]="game.key"
      alt=""
    />
    <img
      class="games-item-layer"
      [src]="getGameSrc(game, 'layer')"
      alt=""
    />
  </div>
  <div class="games-item-name">
    <span>{{ game.title | titlecase }}</span>
  </div> -->
  <!-- </div> -->
  <!-- </a> -->
</a>
