import {ChangeDetectionStrategy, Component, Input, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'fast-ui-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagComponent {
  @Input() public tag = '';
  @Input() public backgroundColor: string | undefined;
  @Input() public textColor: string | undefined;
  @Input() public size: string | undefined;

  // @HostBinding(`style.--background-color`)
  // public get backColor(): string {
  //   return this.backgroundColor || 'var(--color-theme-600)';
  // } @HostBinding(`style.--text-color`)
  // public get txtColor(): string {
  //   return this.textColor || 'var(--color-typography-white)';
  // }
  // @HostBinding(`style.--size`)
  // public get sizeVal(): string {
  //   return this.size || '2rem';
  // }
}

@NgModule({
  imports: [CommonModule],
  declarations: [TagComponent],
  exports: [TagComponent],
})
export class TagComponentModule {}
