<div class="steam-error">
  <div class="steam-error__wrapper">
    <div class="steam-error__content">
      <div class="steam-error__column">
        <h2 class="steam-error__title">
          {{'GOFAST.MODAL.STEAM_ISSUES.HEADER' | translate}}
        </h2>
        <div class="steam-error__text">
          {{'GOFAST.MODAL.STEAM_ISSUES.TEXT'| translate}}
          <a href="https://steamstat.us/">https://steamstat.us/</a>
        </div>

        <button (click)="close()" class="btn btn-primary steam-error__button">
          OK
        </button>
      </div>
    </div>
    <button (click)="close()" class="steam-error__icon_close">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </div>
</div>
