import { Injectable } from '@angular/core';
import { FrameMessageTypes, IFrameMessageService } from '@csgofast/core/iframe';

import { ModalNames } from '@dev-fast/types';
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { MODALS_INITIAL_STATE, ModalsStateModel } from './modals-state.model';
import {CloseAllModals, CloseModal, OpenModal} from './modals.actions';

@State<ModalsStateModel>({
  name: 'modals',
  defaults: MODALS_INITIAL_STATE,
})
@Injectable()
export class ModalsState implements NgxsOnInit {
  constructor(private readonly store: Store, private readonly frameMessageService: IFrameMessageService) {}

  @Selector()
  public static activeModal({ activeModal }: ModalsStateModel): ModalNames | null {
    return activeModal;
  }
  @Selector()
  public static payloadBeforeOpen({ payloadBeforeOpen }: ModalsStateModel): any | null {
    return payloadBeforeOpen;
  }

  ngxsOnInit() {
    this.frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'open.user-profile.modal', (payload: { userId: number }) =>
      this.store.dispatch(new OpenModal(ModalNames.USER_PROFILE, payload))
    );
    this.frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'open.lottery-rules.modal', () =>
      this.store.dispatch(new OpenModal(ModalNames.LOTTERY_RULES))
    );
  }

  @Action(OpenModal)
  public open({ patchState }: StateContext<ModalsStateModel>, { name, payload, payloadBeforeOpen }: OpenModal) {
    console.log('open')
    return patchState({ activeModal: name, payloadBeforeOpen });
  }
  @Action(CloseAllModals)
  public closeAllModals({ patchState }: StateContext<ModalsStateModel>) {
    return patchState({ activeModal: null });
  }
  @Action(CloseModal)
  public close({ patchState, getState }: StateContext<ModalsStateModel>, { name, payload, payloadAfterClose }: CloseModal) {
    const { activeModal } = getState();
    if (name === activeModal) return patchState({ activeModal: null, payloadAfterClose, payloadBeforeOpen: null });
    return;
  }
}
