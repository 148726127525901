import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {P2pPurchaseItemComponent} from './p2p-purchase-item.component';
import {TranslateModule} from '@ngx-translate/core';
import {P2pPurchaseTradeModalModule} from '../../modals/p2p-purchase-trade-modal/p2p-purchase-trade-modal.module';
import {RouterModule} from '@angular/router';
import {MatIconModule} from '@angular/material/icon';
import {AppCurrencyModule, ReplacePipeModule} from '@csgofast/shared/pipes';
import {SkinItemImageModule} from '@csgofast/shared/directives';
import {P2pSetupModalModule} from '../../modals/p2p-setup-modal/p2p-setup-modal.module';
import {CountdownComponentModule} from '@dev-fast/ui-components';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';

@NgModule({
  declarations: [P2pPurchaseItemComponent],
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule,
    AppCurrencyModule,
    SkinItemImageModule,
    P2pPurchaseTradeModalModule,
    P2pSetupModalModule,
    RouterModule,
    CountdownComponentModule,
    MatProgressSpinnerModule,
    ReplacePipeModule
  ],
  exports: [P2pPurchaseItemComponent, ],
})
export class P2pPurchaseItemModule {}
