<!--FIXME Добавить ключи так как есть только для HELLTV-->
<!-- TODO ADD KEYS FROM HLTV -->
<div class="promo">
  <div
    class="promo__icon-wrapper"
    (click)="togglePromocode()"
  >
    <mat-icon
      class="promo__icon"
      svgIcon="promo"
    ></mat-icon>
  </div>
  <ng-container *ngIf="opened">

  <div
    class="promo__container"
    [ngClass]="{ opened: opened, centered: activationIsExecuting }"
  >
    <ng-container *ngIf="activationIsExecuting; else mainTemplate">
      <mat-spinner color="primary"></mat-spinner>
</ng-container>
    <ng-template #mainTemplate>
      <span
        class="promo__closed"
        (click)="closePromocode()"
      >
        <mat-icon svgIcon="close"></mat-icon>
      </span>
      <ng-container *ngIf="promoData && promoData.promoActivated; then congratsTemplate; else inputTemplate"></ng-container>
      <ng-template #inputTemplate>
        <span class="promo__title">
          {{ 'GOFAST.PROMOCODE_MODAL.HEADER' | translate }}
        </span>
        <input
          class="promo__input"
          type="text"
          [(ngModel)]="code"
          [placeholder]="'GOFAST.PROMOCODE_MODAL.PLACEHOLDER' | translate"
        />

        <ng-container *ngIf="!isBonusCodeEntered()">
          <span class="promo__default-bonus-wrapper">
            {{'PROMOCODE_WIDGET.DEFAULT_CODE_MESSAGE' | translate}}
            <a (click)="onCodeClick($event)" class="promo__default-bonus-wrapper--code">{{defaultCode}}</a>
          </span>
        </ng-container>

        <ng-container *ngIf="promoData as data">
          <span class="promo__info">
            <ng-container *ngIf="data.activationErr; else appliedPromoTemplate">
              <div class="promo__info--attention">
                <mat-icon svgIcon="method-attention"></mat-icon>
                <!-- {{ data.activationErr.message | translate }} -->
                <div>

                {{ onActivateErr(data.activationErr.message) | translate }}
                </div>
              </div>
            </ng-container>
            <ng-template #appliedPromoTemplate>
              <ng-container *ngIf="data.activePromoCode && !data.activePromoCode.rewarded">
                {{ 'Promocode applied' | translate }}
                <b>
                  {{ data.activePromoCode.code }}:
                  <ng-container *ngIf="data.activePromoCode.type.includes('percent'); else moneyTmpl">
                    {{ data.activePromoCode.reward }}%
                  </ng-container>
                  <ng-template #moneyTmpl>
                    {{ data.activePromoCode.reward | appCurrency }}
                  </ng-template>
                  {{ 'bonus' | translate }}
                </b>
              </ng-container>
            </ng-template>
          </span>
        </ng-container>

        <button
          class="btn btn-primary promo__button"
          [disabled]="!codeValid"
          (click)="onActivate()"
        >
          {{ 'GOFAST.PROMOCODE_MODAL.BUTTON_ACTIVATE' | translate }}
        </button>
      </ng-template>
      <ng-template #congratsTemplate>
        <div class="promo__title">{{ 'Congratilations!' }}</div>
        <div class="congrats">
          <ng-container *ngIf="promoData && promoData.activePromoCode">
            {{ 'By promocode' | translate }}
            <b> {{ promoData.activePromoCode.code }} </b>
            {{ 'available to you' | translate }}
            <b>
              <ng-container *ngIf="promoData.activePromoCode.type.includes('percent'); else moneyTmpl">
                {{ promoData.activePromoCode.reward }}%
              </ng-container>
              <ng-template #moneyTmpl>
                {{ promoData.activePromoCode.reward | appCurrency }}
              </ng-template>
              {{ 'bonus' | translate }}
            </b>
            {{ 'refill balance' | translate }}
          </ng-container>
        </div>

        <button
          class="btn btn-primary promocode-button-refill"
          [routerLink]="['/refill']"
          (click)="closePromocode()"
        >
          {{ 'Refill balance' }}
        </button>
      </ng-template>
    </ng-template>
  </div>
</ng-container>
</div>

<ng-container *ngIf="opened">
  <div
    class="overlay"
    (click)="closePromocode()"
  ></div>
</ng-container>
