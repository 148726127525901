<perfect-scrollbar
  class="container-viewport"
  [config]="{ suppressScrollX: true, wheelPropagation: false }"
>
  <div
    class="market-attention"
    *ngIf="showWarn$ | async as notified"
  >
    <div class="market-attention__container">
      <div class="market-attention__header">
        <mat-icon svgIcon="method-attention"></mat-icon>
        <span>{{ 'P2P_WIDGETS.WARN.WARN' | translate }}</span>
      </div>
      <div class="market-attention__description">
        {{ 'P2P_WIDGETS.WARN.DELIBERATELY_BREAKDOWN_OFFER' | translate }}
      </div>
      <div
        class="market-attention__close"
        (click)="onChangeNotifiedStatus(!notified)"
      >
        <mat-icon svgIcon="close"></mat-icon>
      </div>
    </div>
  </div>

  <div class="container-content">
    <!---->
    <ng-container *ngIf="purchasing$ | async as purchasing">
      <ng-container *ngIf="purchasing.length; else inviteLayout">
        <csgofast-p2p-purchase-item
          *ngFor="let entity of purchasing; trackBy: trackingFunction"
          class="container-content__item"
          [id]="entity.id"
        >
        </csgofast-p2p-purchase-item>
      </ng-container>
    </ng-container>
  </div>
</perfect-scrollbar>
<ng-template #inviteLayout>
  <div class="invite-container">
    <mat-icon svgIcon="p2p-trading-icon"></mat-icon>
    <span>{{ 'P2P_WIDGETS.INVITE.BUY_SKINS' | translate }}</span>
    <p>{{ 'P2P_WIDGETS.INVITE.YOU_CAN_BUY_SKINS' | translate }}</p>
    <a
      class="btn btn-primary"
      routerLink="store"
    >
      {{ 'P2P_WIDGETS.INVITE.MARKETPLACE' | translate }}
    </a>
  </div>
</ng-template>
