import { SocialType } from '../enums';
import { SocialItem } from '../models';

export const SOCIALS: SocialItem[] = [
  { name: SocialType.Steam, title: 'Steam' },
  { name: SocialType.MetaMask, title: 'MetaMask' },
  { name: SocialType.Google, title: 'Google' },
  { name: SocialType.Vkontakte, title: 'VK' },
  // { name: SocialType.Twitter, title: 'Twitter' },
  { name: SocialType.Facebook, title: 'Facebook' },
  { name: SocialType.Yandex, title: 'Yandex' },
  { name: SocialType.Twitch, title: 'Twitch' },
  { name: SocialType.Telegram, title: 'Telegram' },
];
