import {IUserDetailed, IUserP2pPermissionInterface, IUserSettingsDetailed, Permissions} from '@dev-fast/types';

export interface UserStateModel {
  /** IUserDetailed - юзер авторизован, null - юзер неавторизован, undefined - значение до ответа сервера о статусе юзера */
  user: IUserDetailed | null | undefined;
  // country: UserCountry | null;
  userRequestState: boolean | null;
  country: string | null;
  permissions: Permissions[];
  p2pPermissions: IUserP2pPermissionInterface | null;
  additionalSettings: IUserSettingsDetailed | null;
}

export const USER_INITIAL_STATE: UserStateModel = {
  user: null,
  userRequestState: null,
  country: null,
  permissions: [Permissions.CAN_USE],
  p2pPermissions: null,
  additionalSettings: null,
};
