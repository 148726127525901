<div class="stub">
  <div class="stub-title">
    <img [src]="image" *ngIf="image; else notFoundShrug" width="205" height="234" alt="">
    <ng-template #notFoundShrug>
      ¯\_(ツ)_/¯
    </ng-template>
  </div>
  <div class="stub-description">
    {{ (message || 'ESPORT_BET_WIDGET.NOTHING_FOUND') | translate }}
  </div>
</div>
