import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NyEventProgressBarComponent} from "@dev-fast/ui-components";



@NgModule({
  declarations: [NyEventProgressBarComponent],
  imports: [
    CommonModule
  ],
  exports:[NyEventProgressBarComponent]
})
export class NyEventProgressBarModule { }
