<div class="container">
  <svg class="circle-container" viewBox="2 -2 28 36" xmlns="http://www.w3.org/2000/svg">
    <!-- <linearGradient id="gradient">
      <stop class="stop1" offset="0%" />
      <stop class="stop2" offset="100%" />
    </linearGradient> -->
    <circle class="circle-container__background" r="16" cx="16" cy="16" 
            shape-rendering="geometricPrecision"></circle>
    <circle class="circle-container__progress" r="16" cx="16" cy="16" 
            [style]="'stroke-dasharray:' + progressValue + ', 100'" 
            shape-rendering="geometricPrecision">
    </circle>
  </svg>
  <div class="current-value" [ngClass]="{ description: description, long:  maxValue >= 100, full: currValue === maxValue }">
    <ng-container *ngIf="description; else currValueTemplate">
      {{prefixValue}} {{ currValue }}/{{ maxValue }}
    </ng-container>
    <ng-template #currValueTemplate>
      {{ prefixValue }} {{ diffrence }}
    </ng-template>
  </div>
  <div class="max-value">
    <ng-container *ngIf="description; else maxValueTemplate">
      <mat-icon *ngIf="icon" class="description-icon" [svgIcon]="icon"></mat-icon>
      <span class="description">{{ description }}</span>
    </ng-container>
    <ng-template #maxValueTemplate>
      {{ maxValue }}
    </ng-template>
  </div>
</div>