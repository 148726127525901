import { ChangeDetectionStrategy, Component, NgModule, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  template: ``,
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoIndexComponent{
}

@NgModule({
  imports: [CommonModule],
  declarations: [NoIndexComponent],
  exports: [NoIndexComponent],
})
export class NoIndexComponentModule {}
