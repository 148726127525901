import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdvertisementsComponent } from './advertisements.component';

@NgModule({
  declarations: [AdvertisementsComponent],
  imports: [CommonModule],
  exports: [AdvertisementsComponent]
})
export class AdvertisementsModule {}
