import {Injectable} from '@angular/core';
import {Environments, EnvironmentService} from '@csgofast/core/environment-service';
import {CashBackStatus} from '@dev-fast/types';
import {Observable} from 'rxjs';
import {ApiService} from './api.service';

@Injectable({
  providedIn: 'root',
})
export class CashBackService {
  private environment: Environments;

  constructor(private readonly api: ApiService, private readonly environmentService: EnvironmentService) {
    this.environment = this.environmentService.environments;
  }

  public getCashbackStatus = (): Observable<CashBackStatus> => {
    return this.api.get(`${this.environment.GATEWAY_URL}/api/cashback`, {
      withCredentials: true,
    });
  };

  public takeCashback(): Observable<void> {
    return this.api.post(
      `${this.environment.GATEWAY_URL}/api/cashback/take`,
      {},
      {
        withCredentials: true,
      }
    );
  }
}
