import {Injectable, OnDestroy} from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {map, Observable, Subject, take, takeUntil} from 'rxjs';
import { ConfigConfirmDialog } from './config';
import { ConfirmDialogComponent } from './confirm-dialog.component';

@Injectable()
export class ConfirmDialogService implements OnDestroy{
  private readonly destroyed$: Subject<void>;
  constructor(private dialog: MatDialog) {
    this.destroyed$ = new Subject();
  }
  dialogRef!: MatDialogRef<ConfirmDialogComponent>;

  public open(options: ConfigConfirmDialog, func?: (res:boolean) => void ):void  {
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass:'confirm-dialog',
      data: {
        title: options.title,
        message: options.message,
        cancelText: options.cancelText,
        confirmText: options.confirmText,
      },
    });
    this.dialogRef.afterClosed().pipe(
      take(1),
      takeUntil(this.destroyed$),
      map((res: boolean | undefined) => {
        return res !== undefined ? res : false;
      })
    ).subscribe(value => func && func(value));
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
  public confirmed(): Observable<boolean> {
    return this.dialogRef.afterClosed().pipe(
      take(1),
      takeUntil(this.destroyed$),
      map((res: boolean | undefined) => {
        return res !== undefined ? res : false;
      })
    );
  }

}
