import {Injectable} from '@angular/core';
import {UserState} from '@csgofast/core/state/user-store';
import {UpdateApiKey, UpdateApiKeySuccess, UpdateTradeLink, UpdateTradeLinkSuccess} from '@csgofast/core/state/account';
import {IUserDetailed, TradeLinks} from '@dev-fast/types';
import {Dispatch} from '@ngxs-labs/dispatch-decorator';
import {Actions, ofActionSuccessful, Select} from '@ngxs/store';
import {Observable} from 'rxjs';

@Injectable()
export class P2pModalSetupService {
  @Select(UserState.user)
  public readonly user$!: Observable<IUserDetailed>;
  @Select(UserState.tradelinkIsValid)
  public readonly tradelinkIsValid$!: Observable<boolean>;
  @Select(UserState.apikeyIsValid)
  public readonly apikeyIsValid$!: Observable<boolean>;

  public tradeLinkUpdated$ = this.actions$.pipe(ofActionSuccessful(UpdateTradeLinkSuccess));
  public apiKeyUpdated$ = this.actions$.pipe(ofActionSuccessful(UpdateApiKeySuccess));

  constructor(private readonly actions$: Actions) {}

  @Dispatch() public updateApiKey = (apiKey: string) => new UpdateApiKey(apiKey);
  @Dispatch() public updateTradeLink = (links: TradeLinks) => new UpdateTradeLink(links);
}
