<ng-container
  *ngIf="{
    totalProgress: totalProgress$ | async,
    motivatorList: motivatorList$ | async,
    overlay: overlay$ | async,
    reward: reward$ | async,
    items: items$ | async,
    eventName: eventName$ | async
  } as data"
>
  <div
    class="ny-event--container"
    *ngIf="data.items && data.items.length"
  >
    <ng-container *ngIf="data.eventName === 'ny'">
      <div class="ny-event--container-header">
        <div class="ny-event--container-header-title">{{ 'GOFAST.NY_EVENT.SEARCH_EVENT_HEADER' | translate }}</div>
        <div class="ny-event--container-header-info">{{ 'GOFAST.NY_EVENT.SEARCH_EVENT_DESCRIPTION' | translate }}</div>
      </div>
      <div class="ny-event--room-container">
        <csgofast-room
          [presents]="data.items"
          [overlay]="overlay$ | async"
        ></csgofast-room>
      </div>
    </ng-container>
    <div class="ny-event--faq ny-event--container-header">
      <div class="ny-event--container-header-title">{{ 'GOFAST.NY_EVENT.SEARCH_EVENT_HOW_TO_HEADER' | translate }}</div>
      <div class="ny-event--container-header-info">
        {{ 'GOFAST.NY_EVENT.SEARCH_EVENT_HOW_TO_DESCRIPTION' | translate }}
      </div>
    </div>
    <csgofast-gifts-grid
      class="ny-event--gifts-grid"
      [presents]="data.items"
      [eventName]="data.eventName"
      [isEventComplete]="false"
    ></csgofast-gifts-grid>
    <csgofast-ny-progress-block
      class="ny-event--special-prize-motivator"
      [totalProgress]="data.totalProgress"
      [eventName]="data.eventName"
      [isEventComplete]="false"
      [reward]="data.reward"
    ></csgofast-ny-progress-block>
    <csgofast-ny-motivator
      [motivatorList]="data.motivatorList"
      [eventName]="data.eventName"
      class="ny-event--motivator"
    ></csgofast-ny-motivator>
  </div>
</ng-container>

<!-- <csgofast-ny-event-case-motivator class="gift-card--progress-bar" *ngIf="true"></csgofast-ny-event-case-motivator> -->
