import { Affise, PromoCode, IError } from '@dev-fast/types';

export interface ReferralsStateModel {
  activePromoCode: PromoCode | null;
  activeReferralCampaign: PromoCode | null;
  promoActivated: boolean;
  activationErr: IError | null;
  affise: Affise | null;
}
export const REFERRALS_INITIAL_STATE: ReferralsStateModel = {
  activePromoCode: null,
  activeReferralCampaign: null,
  activationErr: null,
  promoActivated: false,
  affise: null,
};
