import {IconConfig} from './icon.model';

export const iconsCommon: IconConfig[] = [
  {
    name: 'account-minimal',
    path: 'assets/img/mat-icons/common/account-minimal.svg',
  },
  {
    name: 'alert',
    path: 'assets/img/mat-icons/common/alert.svg',
  },
  {
    name: 'arrow',
    path: 'assets/img/mat-icons/common/arrow.svg',
  },
  {
    name: 'arrow-mix',
    path: 'assets/img/mat-icons/common/mix-arrow.svg',
  },
  {
    name: 'bot-add',
    path: 'assets/img/mat-icons/common/bot-add.svg',
  },
  {
    name: 'checked',
    path: 'assets/img/mat-icons/common/checked.svg',
  },
  {
    name: 'clock',
    path: 'assets/img/mat-icons/common/clock.svg',
  },
  {
    name: 'coin',
    path: 'assets/img/mat-icons/common/coin.svg',
  },
  {
    name: 'copy',
    path: 'assets/img/mat-icons/common/copy.svg',
  },
  {
    name: 'basket',
    path: 'assets/img/mat-icons/common/basket.svg',
  },
  {
    name: 'direction-arrow',
    path: 'assets/img/mat-icons/common/direction-arrow.svg',
  },
  {
    name: 'edit',
    path: 'assets/img/mat-icons/common/edit.svg',
  },
  {
    name: 'failure',
    path: 'assets/img/mat-icons/common/failure.svg',
  },
  {
    name: 'login',
    path: 'assets/img/mat-icons/common/login.svg',
  },
  {
    name: 'minus-circle',
    path: 'assets/img/mat-icons/common/minus-circle.svg',
  },
  {
    name: 'player',
    path: 'assets/img/mat-icons/common/player.svg',
  },
  {
    name: 'protect-works',
    path: 'assets/img/mat-icons/common/protect-works.svg',
  },
  {
    name: 'other_players',
    path: 'assets/img/mat-icons/common/other_players.svg',
  },
  {
    name: 'plus-circle',
    path: 'assets/img/mat-icons/common/plus-circle.svg',
  },
  {
    name: 'success',
    path: 'assets/img/mat-icons/common/success.svg',
  },
  {
    name: 'search',
    path: 'assets/img/mat-icons/common/search.svg',
  },
  {
    name: 'empty-search',
    path: 'assets/img/mat-icons/common/empty-search.svg',
  },
  {
    name: 'update-arrows',
    path: 'assets/img/mat-icons/common/update-arrows.svg',
  },
  {
    name: 'observe',
    path: 'assets/img/mat-icons/common/observe.svg',
  },
  {
    name: 'parameter',
    path: 'assets/img/mat-icons/common/parameter.svg',
  },
  {
    name: 'promo',
    path: 'assets/img/mat-icons/common/promo-icon.svg',
  },
  {
    name: 'game-lock',
    path: 'assets/img/mat-icons/common/game-lock.svg',
  },
  {
    name: 'method-attention',
    path: 'assets/img/mat-icons/common/method-attention.svg',
  },
  {
    name: 'winner',
    path: 'assets/img/mat-icons/common/winner.svg',
  },
  {
    name: 'color-lock',
    path: 'assets/img/mat-icons/common/color-lock.svg',
  },
];
