import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {BehaviorSubject, switchMap} from 'rxjs';
import {CaseRevisionService} from './case-revision.service';

@Component({
  selector: 'csgofast-case-revision',
  templateUrl: './case-revision.component.html',
  styleUrls: ['./case-revision.component.scss', './case-revision.media.scss'],
})
export class CaseRevisionComponent {
  public activeRevision$: BehaviorSubject<number> = new BehaviorSubject(0);
  public caseRevision$ = this.activeRevision$.pipe(switchMap((activeRevision) => this.caseRevisionService.getCaseRevisionById(this.caseId, activeRevision)))
  
  constructor(
    public dialogRef: MatDialogRef<CaseRevisionComponent>,
    @Inject(MAT_DIALOG_DATA) private caseId: number,
    private caseRevisionService: CaseRevisionService
  ) {}

  public close(): void {
    this.dialogRef.close();
  }
  public getCaseRevision(range: number) {
    this.activeRevision$.next(range);
  }
}
