<ng-container
  *ngIf="{
    item: item$ | async,
    eventName: eventName$ | async
  } as data"
>
  <ng-container *ngIf="data.item && data.eventName">
    <ng-container *ngIf="canShow(data.item)">
      <div
        class="ny-event-case-motivator"
        [class]="progress === 100 ? 'present-ready' : 'present-pending'"
      >
        <div
          class="ny-event-case-motivator--present-img"
          *ngIf="data.item.image"
        >
          <img
            *ngIf="data.eventName === 'ny'"
            [src]="data.item.image"
            alt="itemImage"
          />
          <img
            *ngIf="data.eventName === 'ny_china'"
            class="ny-china-external-img"
            [src]="data.item.externalImage"
            alt="itemImage"
          />
        </div>
        <fast-ui-ny-event-progress-bar
          class="ny-event-case-motivator--progress-bar"
          [progress]="progress"
          [eventName]="data.eventName"
        ></fast-ui-ny-event-progress-bar>
        <div class="ny-event-case-motivator--btn">
          <button
            class="btn btn-primary"
            (click)="onOpenCase(data.item)"
            [disabled]="progress < 100 || disableBtn"
          >
            {{ 'BALANCE_BONUSES.TAKE' | translate }}
          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
