<ng-container *ngIf="present && eventName">
  <ng-container *ngIf="present.caseRevisionResult; else default_card">
    <div
      class="gift-card--container"
      style="background: #12181d"
      [class.nyc-replay]="eventName === 'ny_china'"
    >
      <div class="gift-card--default-card">
        <div
          class="present-icon--container replay-img"
          [routerLink]="'/game/cases/case/' + present.caseRevisionResult.case.id"
        >
          <img
            style="max-width: 80%"
            class="skin-avatar"
            [src]="present.caseRevisionResult.case.backgroundImage ? present.caseRevisionResult.case.backgroundImage : present.image"
            lazyLoadImage
           alt=""/>
        </div>
        <a
          class="replay-arrow"
          [routerLink]="['/game/cases/case/', present.caseRevisionResult.case.id]"
          [queryParams]="{ openUUID: present.caseRevisionResult.openUUID }"
        >
          <mat-icon [svgIcon]="'play'"></mat-icon>
        </a>

        <div
          class="present-icon--container replay-img replay-item"
          [routerLink]="'/game/cases/case/item'"
          [queryParams]="{
            skin: present.caseRevisionResult.caseRevisionItem.inventoryItem.baseItem.skin,
            shortName: present.caseRevisionResult.caseRevisionItem.inventoryItem.baseItem.shortName
          }"
        >
          <img [src]="present.caseRevisionResult.caseRevisionItem.inventoryItem.baseItem.icon"  alt=""/>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #default_card>
    <ng-container *ngIf="present.status === 'taken' || present.status === 'available'; else default_card_template">
      <div
        class="gift-card--container available"
        *ngIf="eventName === 'ny'"
      >
        <div class="gift-card--default-card">
          <div class="present-icon--container replay-img">
            <img [src]="present.image"  alt=""/>
          </div>
          <div class="gift-card--default-card-body">
            <div
              *ngIf="present.case"
              class="gift-card--strong-text"
            >
              {{ present.case.name }}
            </div>
            <button
              class="btn btn-primary gift-card--strong-text"
              (click)="pickupReward(present)"
            >
              {{ 'GOFAST.NY_EVENT.OPEN_CASE' | translate }}
            </button>
          </div>
        </div>
      </div>

      <div
        class="chinese-new-year"
        *ngIf="eventName === 'ny_china'"
      >
        <div class="gift-card--container">
          <div class="gift-card--default-card">
            <div class="letter-cap"></div>
            <div
              class="btn btn-primary gift-open"
              (click)="pickupReward(present)"
            >
              <span>{{ 'GOFAST.NY_EVENT.OPEN_CASE' | translate | uppercase }}</span>
            </div>
            <span
              *ngIf="present.case"
              class="gift-card--strong-text"
              style="text-align: center; width: 60%"
            >
              {{ present.case.name }}
            </span>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #default_card_template>
      <div
        class="gift-card--container"
        [routerLink]="['/game/cases']"
        [queryParams]="{type: present.entityId}"
        [fragment]="'casesMain'"
        *ngIf="eventName === 'ny'"
        [class.disabled]="isEventComplete"
      >
        <div class="gift-card--default-card">
          <div class="present-icon--container">
            <img [src]="present.image"  alt=""/>
          </div>
          <div class="gift-card--default-card-body">
            <div class="gift-card--muted-text">{{ 'GOFAST.NY_EVENT.SEARCH_EVENT_COLLECTION' | translate }}:</div>
            <div class="gift-card--strong-text">{{ present.name }}</div>
            <fast-ui-ny-event-progress-bar
              *ngIf="present.progress > 0"
              class="gift-card--progress-bar"
              [progress]="questProgress"
              [eventName]="eventName"
            ></fast-ui-ny-event-progress-bar>
          </div>
        </div>
      </div>
      <div
        class="chinese-new-year"
        [routerLink]="['/game/cases']"
        [queryParams]="{type: present.entityId}"
        [fragment]="'casesMain'"
        *ngIf="eventName === 'ny_china'"
        [class.disabled]="isEventComplete"
      >
        <div class="gift-card--container">
          <div class="gift-card--default-card">
            <div class="letter-cap"></div>
            <div class="gift-number-container">
              <div style="position: relative">
                <svg>
                  <circle
                    cx="50%"
                    cy="50%"
                    r="2rem"
                    fill="none"
                    stroke="rgba(176,25,51,0.7)"
                    stroke-width="5"
                  />
                  <circle
                    class="percent fifty"
                    cx="50%"
                    cy="50%"
                    r="2rem"
                    fill="none"
                    stroke="#91142a"
                    stroke-width="5"
                    pathLength="100"
                    [style.stroke-dashoffset]="100 + questProgress"
                  />
                </svg>
                <span class="gift-number">{{ cardNumber }}</span>
              </div>
            </div>
            <div class="gift-location">
              <span style="padding-bottom: 0.5rem">{{ 'GOFAST.NY_EVENT.SEARCH_EVENT_COLLECTION' | translate }}:</span>
              <span class="gift-card--strong-text"> {{ present.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-template>
</ng-container>
