import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotFoundStubComponent} from './not-found-stub.component';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
  declarations: [NotFoundStubComponent],
  imports: [
    CommonModule,
    TranslateModule,
  ],
  exports: [NotFoundStubComponent],
})
export class NotFoundStubModule { }
