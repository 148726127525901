<div class="dropdown-wrapper">
  <button
    class="button dropdown-button"
    (click)="toggleDropdown()"
  >
    <img
      lazyLoadImage
      [src]="'//d2lomvz2jrw9ac.cloudfront.net/country-flags/' + selected.path.slice(3).toLowerCase() + '.png'"
     alt=""/>
    <mat-icon
      class="dropdown-button__icon"
      svgIcon="arrow-up"
      [ngClass]="{ opened: (opened$ | async) }"
    ></mat-icon>
  </button>
  <ng-container *ngIf="opened$ | async">
    <div class="dropdown-container">
      <ul class="dropdown-list">
        <ng-container *ngFor="let item of items">
          <li
            class="dropdown-item"
            *ngIf="item !== selected"
          >
            <div
              class="dropdown-item-content"
              *ngIf="content"
            >
              <ng-container [ngTemplateOutlet]="content.templateRef"></ng-container>
            </div>
            <div
              class="dropdown-item-content"
              *ngIf="!content"
              (click)="select(item)"
            >
              <img
                lazyLoadImage
                [src]="'//d2lomvz2jrw9ac.cloudfront.net/country-flags/' + item.path.slice(3).toLowerCase() + '.png'"
               alt=""/>
            </div>
          </li>
        </ng-container>
      </ul>
    </div>
  </ng-container>
</div>
