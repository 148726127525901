<div class="user-avatar-container">
  <div class="user-avatar-wrapper">
    <div class="user-avatar-box loading-gradient">
    </div>
  </div>
</div>
<div class="username-container">
  <div class="nickname loading-gradient">
  </div>
</div>
