import {Injectable} from '@angular/core';
import {Action, NgxsOnInit, Selector, State, StateContext, Store} from '@ngxs/store';
import {insertItem, patch, removeItem, updateItem} from '@ngxs/store/operators';
import {delay, EMPTY, Observable, of} from 'rxjs';
import {catchError, switchMap, tap} from 'rxjs/operators';
import {P2pApiService} from '@dev-fast/backend-services';
import {
  IDepositBatchPayload,
  IP2pDepositingItem,
  IP2pDepositItem,
  IP2pParticipateItemUpdated,
  ISteamStoreInventory,
  ModalNames,
  OrderStatusEnum,
  Panel,
  SubPanel,
} from '@dev-fast/types';
import {P2P_DEPOSIT_INITIAL_STATE, P2pDepositStateModel} from './deposit-state.model';
import {
  AutoShowP2PAuctionCompletedModal,
  ChangeSelectedItem,
  Deposit,
  DepositSelected,
  GetDepositing,
  GetDepositModifier,
  GetDepositModifierSuccess,
  OpenModifierDialogInBB,
  ParticipantItemUpdatedEvent,
  PauseDepositing,
  RemoveDepositItem,
  RemoveMarketItems,
  ReqAppointing,
  ResetSelected,
  ResumeDepositing,
  SellNow,
  SetDepositModifier,
  StopDepositing,
  ToggleDepositItem,
} from './deposit.actions';
import {HttpErrorResponse} from '@angular/common/http';
import {NotificationsService} from '@csgofast/core/notification-service';
import {RefreshCurrentUser, UserState} from '@csgofast/core/state/user-store';
import {LocalStorageService} from '@csgofast/core/local-storage-service';
import {FrameMessageTypes, IFrameMessageService} from '@csgofast/core/iframe';
import {ChangeActivePanel, LayoutState} from '@csgofast/core/state/layout';
import {CloseModal, ModalsState, OpenModal} from '@csgofast/core/state/modals';

@State<P2pDepositStateModel>({
  name: 'deposit',
  defaults: P2P_DEPOSIT_INITIAL_STATE,
})
@Injectable()
export class P2pDepositState implements NgxsOnInit {
  constructor(
    private readonly _api: P2pApiService,
    private readonly _store: Store,
    private readonly _notificationsService: NotificationsService,
    private readonly _localStorage: LocalStorageService,
    private readonly _frameMessageService: IFrameMessageService
  ) {}

  @Selector()
  public static depositingItems({ depositingItems }: P2pDepositStateModel): IP2pDepositingItem[] {
    return depositingItems;
  }
  @Selector()
  public static isItemsOnPause({ depositingItems }: P2pDepositStateModel): boolean {
    return depositingItems.some((el) => el.status === OrderStatusEnum.PAUSED);
  }
  @Selector()
  public static selected({ selected }: P2pDepositStateModel): IP2pDepositItem[] {
    return selected;
  }
  @Selector()
  public static selectedSum({ selected }: P2pDepositStateModel): number {
    return selected.reduce((a, b) => a + (b.preferredPrice ? b.preferredPrice : b.price), 0);
  }
  // public static depositById(id: number) {
  //   return createSelector([P2pDepositState], (ny: P2pDepositStateModel) => {
  //     return ny.selected.find((s) => s.steamInventoryId === id);
  //   });
  // }
  @Selector()
  static depositById(state: P2pDepositStateModel) {
    return (id: number) => {
      return state.selected.find((s) => s.steamInventoryId === id);
    };
  }

  public ngxsOnInit({ getState, dispatch }: StateContext<P2pDepositStateModel>): void {
    // this._api.itemUpdatedEvent((payload: IP2pItemUpdated) => {
    //   const { depositingItems } = getState();
    //   // console.log(payload);
    //   const element = depositingItems.find((el) => el.id === payload.id);

    //   if (element) {
    //     // dispatch(new ParticipantItemUpdatedEvent({ ...element, ...payload }));
    //     dispatch(new GetDepositing());
    //   }
    // });
    this._api.participantItemUpdatedEvent((payload: IP2pParticipateItemUpdated) => {
      const { depositingItems } = getState();
      // console.log(payload);
      const element = depositingItems.find((el) => el.id === payload.id);

      if (element) {
        dispatch(new ParticipantItemUpdatedEvent({ ...element, ...payload }));
        // dispatch(new GetDepositing());
      }
    });
    this._frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'p2p.ToggleDepositItem', ({ item }: { item: ISteamStoreInventory }) =>
      dispatch(new ToggleDepositItem(this._addExtraPrice(item)))
    );
    this._frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'p2p.ChangeSelectedItem', ({ item }: { item: ISteamStoreInventory }) =>
      dispatch(new ChangeSelectedItem(this._addExtraPrice(item)))
    );
    this._frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'p2p.RemoveDepositItem', ({ item }: { item: IP2pDepositItem }) =>
      dispatch(new RemoveDepositItem(item))
    );
  }
  @Action(RefreshCurrentUser)
  public refreshUser({ dispatch, getState }: StateContext<P2pDepositStateModel>, { payload }: RefreshCurrentUser): void {
    if (payload.id && payload.id !== null) {
      dispatch([new GetDepositing()]);
    }
  }
  @Action(AutoShowP2PAuctionCompletedModal)
  public autoShowP2PAuctionCompletedModal({ dispatch, getState }: StateContext<P2pDepositStateModel>): Observable<void> {
    const { depositingItems } = getState();
    const filteredItemForDeposit = depositingItems.filter(
      (el) =>
        el.status === OrderStatusEnum.WAIT_FOR_TRADE &&
        el.order &&
        el.order.steamItemPosition &&
        el.order.steamItemPosition.length &&
        el.order.buyerTradeLink
    );
    const sorted = filteredItemForDeposit.sort((l, r) =>
      Date.parse(l.statusAt) && Date.parse(r.statusAt) ? (Date.parse(l.statusAt) > Date.parse(r.statusAt) ? -1 : 1) : 0
    );
    const lastItemForDeposit = sorted[0];
    const activeModal = this._store.selectSnapshot(ModalsState.activeModal);

    if (lastItemForDeposit && (!activeModal || (activeModal && activeModal !== ModalNames.P2P_AUCTION_COMPLETED))) {
      return dispatch(new OpenModal(ModalNames.P2P_AUCTION_COMPLETED, of(lastItemForDeposit), lastItemForDeposit.id));
    } else {
      return of();
    }
  }

  @Action(RemoveDepositItem)
  public removeDepositItem({ setState }: StateContext<P2pDepositStateModel>, { payload }: RemoveDepositItem): void {
    setState(
      patch({
        selected: removeItem<IP2pDepositItem>((x) => x?.steamInventoryId === payload.steamInventoryId),
      })
    );
    this._frameMessageService.sendMessage({
      type: FrameMessageTypes.MESSAGE_TO_BB,
      eventName: 'p2p.RemoveDepositItem',
      payload: { item: payload },
    });
  }
  @Action(ToggleDepositItem)
  public toggleDepositItem({ setState, getState, dispatch }: StateContext<P2pDepositStateModel>, { payload }: ToggleDepositItem) {
    const { selected } = getState();
    const hasInSelected = selected.some((el) => el.steamInventoryId === payload.steamInventoryId);

    if (hasInSelected) {
      // dispatch(new RemoveDepositItem(payload));
      // так специально что бы отреагировать на RemoveDepositItem в бэкбоне
      setState(
        patch({
          selected: removeItem<IP2pDepositItem>((x) => x?.steamInventoryId === payload.steamInventoryId),
        })
      );
    } else {
      const activePanel = this._store.selectSnapshot(LayoutState.activePanel);
      if ((activePanel === null || activePanel.panel !== Panel.TRADES) && selected.length === 0) {
        dispatch(new ChangeActivePanel({ panel: Panel.TRADES, subPanel: SubPanel.NONE }));
      }
      setState(patch({ selected: insertItem({ ...payload }) }));
    }
  }
  @Action(ParticipantItemUpdatedEvent)
  public participantItemUpdated({ setState, dispatch }: StateContext<P2pDepositStateModel>, { payload }: ParticipantItemUpdatedEvent) {
    if (payload.status && payload.status === OrderStatusEnum.DELETED) {
      setState(
        patch({
          depositingItems: removeItem<IP2pDepositingItem>((x) => x?.id === payload.id),
        })
      );
    } else {
      setState(
        patch({
          depositingItems: updateItem<IP2pDepositingItem>(
            (o) => o?.id === payload.id,
            payload
            // patch({
            //   ...payload,
            // })
          ),
        })
      );
      if (payload.status === OrderStatusEnum.WAIT_FOR_TRADE && payload.order && payload.order.steamItemPosition) {
        dispatch(new AutoShowP2PAuctionCompletedModal());
      }
      const activeModal = this._store.selectSnapshot(ModalsState.activeModal);
      const payloadBeforeOpenModal = this._store.selectSnapshot(ModalsState.payloadBeforeOpen);

      if (
        activeModal &&
        activeModal !== ModalNames.P2P_AUCTION_COMPLETED &&
        payloadBeforeOpenModal &&
        payloadBeforeOpenModal === payload.id &&
        payload.status !== OrderStatusEnum.WAIT_FOR_TRADE
      ) {
        dispatch(new CloseModal(ModalNames.P2P_AUCTION_COMPLETED));
      }
    }
  }

  @Action(OpenModifierDialogInBB)
  public openModifierDialog({ dispatch, getState, patchState }: StateContext<P2pDepositStateModel>, { item }: OpenModifierDialogInBB) {
    this._frameMessageService.sendMessage({
      type: FrameMessageTypes.MESSAGE_TO_BB,
      eventName: 'openModifierDialog',
      payload: { item },
    });
  }
  @Action(GetDepositing)
  public getDepositing({ patchState, dispatch, getState }: StateContext<P2pDepositStateModel>) {
    const { depositingItems } = getState();
    return this._api.getMyItems().pipe(
      tap((response) => {
        // this._frameMessageService.sendMessage({
        //   type: FrameMessageTypes.MESSAGE_TO_BB,
        //   eventName: 'GetDepositing',
        //   payload: { items: response.items },
        // });
        return patchState({ depositingItems: this._sortByStatus(response.items) });
      }),
      delay(7000),
      switchMap(() => {
        if (depositingItems.length === 0) {
          return dispatch(new AutoShowP2PAuctionCompletedModal());
        } else {
          return of();
        }
      })
    );
  }
  @Action(ResetSelected)
  public resetSelected({ patchState }: StateContext<P2pDepositStateModel>) {
    patchState({ selected: [] });
    this._frameMessageService.sendMessage({
      type: FrameMessageTypes.MESSAGE_TO_BB,
      eventName: 'p2p.deposit.ResetSelected',
      payload: { items: [] },
    });
  }
  @Action(StopDepositing)
  public stopDepositing({ dispatch, getState }: StateContext<P2pDepositStateModel>) {
    const { depositingItems } = getState();
    //TODO
    const excludesStatuses: OrderStatusEnum[] = [OrderStatusEnum.AUCTION_FINISHED, OrderStatusEnum.COMPLETED, OrderStatusEnum.DELETED];
    const ids = depositingItems.filter((val) => !excludesStatuses.includes(val.status)).map((el) => el.id);

    return dispatch(new RemoveMarketItems(ids));
  }
  @Action(PauseDepositing)
  public pauseDepositing({ getState, patchState, dispatch }: StateContext<P2pDepositStateModel>, { id }: PauseDepositing) {
    const { depositingItems } = getState();
    //TODO тупо, очень тупо
    const includesStatuses: OrderStatusEnum[] = [OrderStatusEnum.NEW];
    const ids = id ? [id] : depositingItems.filter((val) => includesStatuses.includes(val.status)).map((el) => el.id);
    if (ids.length === 0) {
      return dispatch(new StopDepositing());
    }
    return this._api.reqPause(ids).pipe(
      tap((resp) => {
        const { depositingItems } = getState();
        const respItemsIds = resp.items.map((el) => el.id);

        const filteredItems = depositingItems.filter((el) => !respItemsIds.includes(el.id));
        patchState({ depositingItems: this._sortByStatus([...filteredItems, ...resp.items]) });
      })
    );
  }
  @Action(ResumeDepositing)
  public resumeDepositing({ patchState, getState }: StateContext<P2pDepositStateModel>, { id }: ResumeDepositing) {
    const { depositingItems } = getState();
    //TODO тупо, очень тупо
    const includesStatuses: OrderStatusEnum[] = [OrderStatusEnum.PAUSED];
    const ids = id ? [id] : depositingItems.filter((val) => includesStatuses.includes(val.status)).map((el) => el.id);

    return this._api.reqResume(ids).pipe(
      tap((resp) => {
        const { depositingItems } = getState();
        const respItemsIds = resp.items.map((el) => el.id);

        const filteredItems = depositingItems.filter((el) => !respItemsIds.includes(el.id));
        patchState({ depositingItems: this._sortByStatus([...filteredItems, ...resp.items]) });
      })
    );
  }

  @Action(RemoveMarketItems)
  public removeMarketItem({ getState, patchState }: StateContext<P2pDepositStateModel>, { ids }: RemoveMarketItems) {
    return this._api.reqDelete(ids).pipe(
      tap((resp) => {
        const { depositingItems } = getState();
        const respItemsIds = resp.items.map((el) => el.id);

        const filteredItems = depositingItems.filter((el) => !respItemsIds.includes(el.id));
        patchState({
          depositingItems: this._sortByStatus(filteredItems),
        });
        // setState(
        //   patch({
        //     depositingItems: removeItem<IP2pDepositingItem>((x) => x?.id === id),
        //   })
        // )}
      }),
      catchError((e) => {
        this._onError(e);
        return of(EMPTY);
      })
    );
  }
  @Action(SellNow)
  public sellNow({ dispatch }: StateContext<P2pDepositStateModel>, { id }: SellNow) {
    return this._api.reqSellNow(id).pipe(
      // tap((val) =>
      // setState(
      //   patch({
      //     depositingItems: updateItem<IP2pDepositingItem>(
      //       (o) => o?.id === val.id,
      //       val
      //     ),
      //   })
      // )),
      switchMap((val) => dispatch(new GetDepositing())),
      catchError((e) => {
        this._onError(e);
        return of(EMPTY);
      })
    );
  }
  @Action(DepositSelected)
  public depositSelected({ dispatch, getState }: StateContext<P2pDepositStateModel>) {
    const { selected } = getState();
    const additionalSettings = this._store.selectSnapshot(UserState.additionalSettings);

    const payload: IDepositBatchPayload[] = selected.map(({ autoApprove, steamInventoryId, extra, fastDelivery, tradesDuration }) => ({
      steamInventoryId,
      price: extra.price,
      autoApprove: typeof autoApprove === 'boolean' ? autoApprove : additionalSettings?.market?.autoApprove,
      fastDelivery: typeof fastDelivery === 'boolean' ? fastDelivery : additionalSettings?.fastDelivery,
      tradesDuration: tradesDuration ? Number(tradesDuration) : additionalSettings?.market?.tradesDuration,
    }));

    return this._api.reqDepositSelected(payload).pipe(
      tap((response) => {
        if (response.errors.length) {
          response.errors.forEach((el) => this._notificationsService.addErrorNotification(el.error));
        }
        return dispatch([new GetDepositing(), new ResetSelected()]);
      }),
      catchError((e) => {
        this._onError(e);
        return of(EMPTY);
      })
    );
  }
  @Action(GetDepositModifier)
  public getDepositModifier({ dispatch }: StateContext<P2pDepositStateModel>) {
    const preview = this._localStorage.get('@gofast:preview-deposit-modifier');
    return dispatch(new GetDepositModifierSuccess(preview));
  }
  @Action(SetDepositModifier)
  public setDepositModifier({ dispatch, getState, patchState }: StateContext<P2pDepositStateModel>, { payload }: SetDepositModifier) {
    this._localStorage.set('@gofast:preview-deposit-modifier', payload);
  }
  @Action(Deposit)
  public deposit({ dispatch, getState, setState }: StateContext<P2pDepositStateModel>, { id, price }: Deposit) {
    return this._api.reqDeposit(id, price).pipe(
      catchError((e) => {
        this._onError(e);
        return of(EMPTY);
      })
    );
  }
  @Action(ReqAppointing)
  public reqAppointing({ patchState, setState }: StateContext<P2pDepositStateModel>, { id, action }: ReqAppointing) {
    return this._api.reqAppointing(id, action).pipe(
      tap((val) =>
        setState(
          patch({
            depositingItems: updateItem<IP2pDepositingItem>((o) => o?.id === val.id, val),
          })
        )
      ),
      catchError((e) => {
        this._onError(e);
        return of(EMPTY);
      })
    );
  }
  @Action(ChangeSelectedItem)
  public changeSelectedItem({ setState }: StateContext<P2pDepositStateModel>, { payload }: ChangeSelectedItem) {
    setState(
      patch({
        selected: updateItem<IP2pDepositItem>((x) => x?.steamInventoryId === payload.steamInventoryId, payload),
      })
    );
  }

  private _onError(e: HttpErrorResponse): void {
    const { error } = e;
    this._notificationsService.addErrorNotification(error.message || error.error, {
      icon: 'warning',
      system: error.system,
      params: error?.params ?? {},
    });
  }
  private _addExtraPrice(item: ISteamStoreInventory): IP2pDepositItem {
    let extra = { price: item.price, increase: 0 };

    // if (!item.custom) {
    extra = {
      price: item.preferredPrice ? item.preferredPrice : Math.floor(item.price - item.price * 0.05),
      increase: item.selectedPercent !== null && item.selectedPercent !== undefined ? item.selectedPercent : -5,
    };
    // }
    return { ...item, extra };
  }
  private _sortByStatus(items: IP2pDepositingItem[]): IP2pDepositingItem[] {
    const comparator = (modelA: IP2pDepositingItem, modelB: IP2pDepositingItem) => {
      if (modelA.status === OrderStatusEnum.THIRD_PLUS_BID) return -1;
      if (modelA.status === OrderStatusEnum.SECOND_BID) return -1;
      if (modelA.status === OrderStatusEnum.FIRST_BID) return -1;
      if (modelA.status === OrderStatusEnum.WAIT_FOR_CONFIRM) return -1;
      if (modelA.status === OrderStatusEnum.WAIT_FOR_TRADE) return -1;
      if (modelA.status === OrderStatusEnum.NEW) return -1;
      // if (modelA.status === OrderStatusEnum.CANCELED_BY_SELLER) return 1;
      // return sortBy(newValue, 'statusAt', 'asc');
      return Date.parse(modelA.statusAt);
    };
    return items.sort(comparator);
  }
}
