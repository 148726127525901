import {animate, group, keyframes, query, stagger, style, transition, trigger} from '@angular/animations';

export const notificationAnimation = trigger('notificationAnimation', [
  transition(':enter, * => 0, * => -1', []),
  transition(':increment', [
    group([
      query(
        'mat-icon',
        [
          style({ transform: 'rotate(0)' }),
          stagger(0, [
            animate(
              '250ms linear',
              keyframes([
                style({ transform: 'rotate(0)', offset: 0 }),
                style({ transform: 'rotate(-24deg)', offset: 0.25 }),
                style({ transform: 'rotate(24deg)', offset: 0.75 }),
                style({ transform: 'rotate(0)', offset: 1 }),
              ])
            ),
          ]),
        ],
        {
          optional: true,
        }
      ),
      query(
        '.notifications_unread-circle--pulse',
        [
          style({ transform: 'scale(1)' }),
          stagger(0, [
            animate(
              '1000ms linear',
              keyframes([
                style({ transform: 'scale(1)', offset: 0.5 }),
                style({ transform: 'scale(1.7)', offset: 0.75 }),
                style({ transform: 'scale(1)', offset: 1 }),
              ])
            ),
          ]),
        ],
        {
          optional: true,
        }
      ),
      query(
        '.notifications_unread-circle',
        [
          style({ transform: 'rotate(0)' }),
          stagger(0, [
            animate(
              '1000ms linear',
              keyframes([
                style({ transform: 'rotate(0)', offset: 0 }),
                style({ transform: 'rotate(-360deg)', offset: 0.5 }),
                style({ transform: 'rotate(-360deg)', offset: 1 }),
              ])
            ),
          ]),
        ],
        {
          optional: true,
        }
      ),
    ]),
  ]),
]);
