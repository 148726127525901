import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HasPermissionModule, StopPropagationModule} from "@csgofast/shared/directives";
import {DesktopHeaderComponent} from './desktop-header.component';
import {BalanceWidgetComponentModule, BrandComponentModule, SettingsMenuComponentModule} from '@dev-fast/ui-components';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {MatIconModule} from '@angular/material/icon';
import {PromoComponent} from './promo/promo.component';
import {SwitcherBalanceComponent} from './switcher/switcher.component';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {SharedComponentsModule} from '@csgofast/shared/components';
import {AppCurrencyModule} from '@csgofast/shared/pipes';
import { NyAllEventModule } from '@csgofast/modules/events';

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        BrandComponentModule,
        SettingsMenuComponentModule,
        BalanceWidgetComponentModule,
        TranslateModule,
        RouterModule,
        MatSelectModule,
        FormsModule,
        MatProgressSpinnerModule,
        SharedComponentsModule,
        ReactiveFormsModule,
        AppCurrencyModule,
        NyAllEventModule,
        HasPermissionModule,
      StopPropagationModule
    ],
  declarations: [DesktopHeaderComponent, PromoComponent, SwitcherBalanceComponent],
  exports: [DesktopHeaderComponent],
})
export class DesktopAppHeaderModule {}
