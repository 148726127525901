import {map} from 'rxjs/operators';
import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {ActivePanel, IGame, ILocales, IUserDetailed, MenuLink, Panel, Themes} from '@dev-fast/types';
import {BalanceWidgetConfig} from '@dev-fast/ui-components';
import {Observable} from 'rxjs';
import {LINKS} from './links.const';
import {MobileHeaderService} from './mobile-header.service';

@Component({
  selector: 'csgofast-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.scss'],
  providers: [MobileHeaderService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileHeaderComponent implements OnInit {
  public games$: Observable<IGame[]>;
  public links: MenuLink[];
  public themeList: Themes[] = Object.values(Themes);

  public readonly theme$: Observable<Themes>;
  public readonly user$: Observable<IUserDetailed | null>;
  public readonly locales$: Observable<ILocales[]>;
  public readonly currentLocale$: Observable<ILocales>;
  public readonly balance$: Observable<BalanceWidgetConfig>;
  public readonly currentPanel$: Observable<Panel | null>;
  public readonly canLevelUp$: Observable<boolean>;

  constructor(private readonly moduleService: MobileHeaderService) {
    this.theme$ = this.moduleService.theme$;
    this.user$ = this.moduleService.user$;
    this.canLevelUp$ = this.moduleService.canLevelUp$;
    this.locales$ = this.moduleService.locales$;
    this.currentLocale$ = this.moduleService.currentLocale$;
    this.games$ = this.moduleService.availableGames$;
    this.balance$ = this.moduleService.balance$;
    this.currentPanel$ = this.moduleService.activePanel$.pipe(map((el) => (el ? el.panel : el)));
    this.links = LINKS;
  }
  public ngOnInit(): void {
    this.moduleService.getInventoryInfo();
  }
  public onLogin = () => this.moduleService.login();
  public onLogout = () => this.moduleService.logout();
  public onSwitchTheme = (theme: Themes) => {
    this.moduleService.switchTheme(theme);
  };
  public onSwitchLang = (locale: ILocales) => {
    this.moduleService.switchLang(locale);
  };
  public onChangeActivePanel = (panel: ActivePanel | null) => {
    this.moduleService.changeActivePanel(panel);
  };
}
