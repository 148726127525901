import { ChangeDetectionStrategy, Component, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  template: ``,
  styleUrls: ['./plug.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlugComponent {}

@NgModule({
  imports: [CommonModule],
  declarations: [PlugComponent],
  exports: [PlugComponent],
})
export class PlugComponentModule {}
