<ng-container *ngIf="notificationList$ | async as notificationList">
  <div class="notifications__header">
    <!--  FIXME Добавить ключи для кнопок-->
    <div
      class="notifications__button"
      *ngIf="notificationList.length"
      (click)="watchAll()"
    >
      Watch all
    </div>
    <div
      class="notifications__button"
      (click)="close()"
    >
      Close
    </div>
  </div>
  <ng-container *ngIf="notificationList.length; else noMsgTemplate">
    <div class="notifications__list ng-scrollbar-wrapper">
      <!-- <perfect-scrollbar
      [config]="{ wheelPropagation: false, suppressScrollX: true }"
      [scrollIndicators]="false"
    > -->
      <ng-scrollbar>
        <ng-container *ngFor="let notification of notificationList; trackBy: trackingFunction">
          <div class="notification" [class.new-noty]="notification.status === notificationStatus.new">
            <ng-container [ngSwitch]="notification.type">
              <ng-container *ngSwitchCase="'success'">
                <div
                  class="notification__icon-type"
                  [ngClass]="notification.type"
                >
                  <mat-icon svgIcon="notification-success-outline"></mat-icon>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'error'">
                <div
                  class="notification__icon-type"
                  [ngClass]="notification.type"
                >
                  <mat-icon svgIcon="notification-error-outline"></mat-icon>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'info'">
                <div
                  class="notification__icon-type"
                  [ngClass]="notification.type"
                >
                  <mat-icon svgIcon="notification-attention"></mat-icon>
                </div>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <div
                  class="notification__icon-type"
                  [ngClass]="notification.type"
                >
                  <mat-icon svgIcon="notification-attention"></mat-icon>
                </div>
              </ng-container>
            </ng-container>
            <div class="notification__content">
              <div
                class="notification__title"
                *ngIf="notification.category"
              >
                {{ notification.category }}
              </div>
              <div class="notification__text">
                <ng-container *ngIf="notification.legacy; else elseTemplate">
                  {{ notification.message }}
                </ng-container>
                <ng-template #elseTemplate>
                  {{ notification.message | appCurrencyTranslate: notification.params }}
                </ng-template>
              </div>
              <div class="notification__date">
                {{ notification.createDate | date: 'YYYY-MM-dd HH:mm:ss' }}
              </div>
            </div>
            <div
              class="notification__remove-button"
              (click)="deleteSelectedNotification(notification.id)"
            >
              <mat-icon
                class="notification__remove-button_icon"
                svgIcon="close"
              ></mat-icon>
            </div>
          </div>
        </ng-container>
      </ng-scrollbar>

      <!-- </perfect-scrollbar> -->
    </div>
  </ng-container>
  <ng-template #noMsgTemplate>
    <div class="noMsgTemplate">
      <fast-ui-not-found-stub
        image="/assets/img/not-found-ufo.png"
        message="GOFAST.NOTIFICATION.NO_NEW_MESSAGE"
      ></fast-ui-not-found-stub>
      <!-- {{ 'GOFAST.NOTIFICATION.NO_NEW_MESSAGE' | translate }} -->
    </div>
  </ng-template>
</ng-container>
