import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DesktopLeftSidebarComponent} from './desktop-left-sidebar.component';

import {GameMenuComponentModule} from '@dev-fast/ui-components';
import {DesktopLeftSidebarService} from './desktop-left-sidebar.service';

@NgModule({
  imports: [CommonModule, GameMenuComponentModule],
  declarations: [DesktopLeftSidebarComponent],
  exports: [DesktopLeftSidebarComponent],
  providers: [DesktopLeftSidebarService],
})
export class DesktopAppLeftSidebarModule {}
