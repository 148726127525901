import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NyTermsModalComponent} from './terms-modal/terms-modal.component';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatIconModule} from '@angular/material/icon';
import { NyEventProgressBarModule } from '@dev-fast/ui-components';
import { NgScrollbarModule } from 'ngx-scrollbar';

// import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';

@NgModule({
  declarations: [NyTermsModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    MatCheckboxModule,
    NyEventProgressBarModule,
    MatTooltipModule,
    MatDialogModule,
    // FIXME заменить на ngx-scrollbar (уже установлен на проекте)
    // PerfectScrollbarModule,
    NgScrollbarModule,

    MatIconModule,
    TranslateModule.forChild(),
  ],
  exports: [NyTermsModalComponent],
})
export class NyAllEventModalModule {}
