<ng-container *ngIf="presents">
  <div class="room--container">
    <div style="position: relative; max-width: 1048px">
      <ng-container *ngIf="overlay">
        <div class="room--present-overlay">
          <div class="room--present-overlay-img">
            <ng-container *ngIf="overlay.case">
              <img
                [src]="overlay.case.backgroundImage"
                alt=""
              />
            </ng-container>

          </div>
          <div>
             <button
              class="btn btn-primary gift-card--strong-text"
              (click)="pickupReward(overlay)"
            >
              {{ 'GOFAST.NY_EVENT.OPEN_CASE' | translate }}
            </button>
          </div>
        </div>
      </ng-container>
      <img
        style="display: block"
        src="https://d2lomvz2jrw9ac.cloudfront.net/ny2023/bg.jpg"
        class="room--bg"
        alt="room--bg"
        #roomBackground
      />
      <img
        *ngFor="let present of presents; index as i"
        class="room--present"
        [src]="present.externalImage ? present.externalImage : present.image"
        [attr.width]="scalePresent(i)"
        [class.blocked]="present.status === 'blocked'"
        [style]="presentsAxis[i]"
       alt=""/>
    </div>
  </div>
</ng-container>
<!-- <fast-ui-cube-grid-loader
class="room--loader"
[size]="'5rem'"
[color]="'var(--color-typography-light)'"
></fast-ui-cube-grid-loader> -->
