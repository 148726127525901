<ng-container *ngIf="item">
  <div class="sell-setup-item">

    <div class="icon-close sell-setup-item__remove-at" (click)="removeAt()">
      <mat-icon
        svgIcon="close"
      >
      </mat-icon>
    </div>

    <div class="sell-setup-item__thumbnail">
      <img
        [src]="item.baseItem.icon"
        [alt]="item.baseItem.skin"
      />
    </div>
    <div class="sell-setup-item__details">
      <!-- <div class="rarity">{{ item.baseItem.shortName || item.baseItem.name }}</div>
      <div class="display-name">{{ item.baseItem.skin }}</div> -->
      <div class="display-name">{{ item.baseItem.shortName || item.baseItem.name }} | {{ item.baseItem.skin }}</div>
      <!-- <button
        class="price-configurator"
        [ngClass]="{ custom: item.custom && !item.extra.increase }"
        (click)="openModifierDialog()"
      >
        <mat-icon svgIcon="peer-to-peer-edit-icon"></mat-icon>
        <ng-container *ngIf="item.extra.increase; else no_modifier">
          <div class="price">
            <span>{{ item.extra.price | appCurrency }}</span>
            <span [ngClass]="{ positive: item.extra.increase > 0, negative: item.extra.increase < -4 }">
              {{ item.extra.increase > 0 ? '+' : '' }}{{ item.extra.increase }}%
            </span>
          </div>
        </ng-container>

        <ng-template #no_modifier>
          <div class="price">
            <span>{{ item.extra.price | appCurrency }}</span>
          </div>
        </ng-template>
      </button> -->
      <div
        class="price-container"
        (click)="openModifierDialog()"
      >
        <div class="price-configurator w-100">
          <ng-container *ngIf="item.extra.increase; else no_modifier">
            <div class="price">
              <span [ngClass]="{ positive: item.extra.increase > 0, negative: item.extra.increase < -4 }">{{
                item.extra.price | appCurrency
              }}</span>
            </div>
          </ng-container>

          <ng-template #no_modifier>
            <div class="price w-100">
              <span>{{ item.extra.price | appCurrency }}</span>
            </div>
          </ng-template>
        </div>
        <ng-container *ngIf="item.extra.increase">
          <span
            class="modifier"
            [ngClass]="{ positive: item.extra.increase > 0, negative: item.extra.increase < -4 }"
          >
            {{ item.extra.increase > 0 ? '+' : '' }}{{ item.extra.increase }}%
          </span>
        </ng-container>
      </div>
    </div>

    <!-- <div
      class="recommended"
      *ngIf="!item.custom"
    >
      <span class="recommended__title">Steam price:</span>

      <span class="recommended__price">{{ item.price | appCurrency }}</span>
    </div> -->
  </div>
</ng-container>
