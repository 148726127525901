<ng-container *ngIf="link.length; else noLink">
  <a [routerLink]="[link]">
    <mat-icon
      class="mirror-icon"
      svgIcon="CSGO-mirror"
    ></mat-icon>
    <mat-icon
      class="domain-icon"
      svgIcon="csgofastcom-domain"
    ></mat-icon>
  </a>
</ng-container>
<ng-template #noLink>
  <div>
    <mat-icon
      class="mirror-icon"
      svgIcon="CSGO-mirror"
    ></mat-icon>
    <mat-icon
      class="domain-icon"
      svgIcon="csgofastcom-domain"
    ></mat-icon>
  </div>
</ng-template>
