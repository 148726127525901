<form
  class="preview-deposit-modifier-dialog"
  *ngIf="previewDepositForm"
  [formGroup]="previewDepositForm"
  (ngSubmit)="onSubmit()"
>
  <div class="preview-deposit-modifier-dialog__title">
    <mat-icon svgIcon="failure"></mat-icon>
    <span>
      {{ 'P2P_WIDGETS.PREVIEW_DEPOSIT_MODIFIER_DIALOG.CUSTOM_PRICE' | translate }}
    </span>
  </div>
  <div class="preview-deposit-modifier-dialog__description">
    <span>
      {{ 'P2P_WIDGETS.PREVIEW_DEPOSIT_MODIFIER_DIALOG.CAN_SET_CUSTOM_PRICE' | translate }}
    </span>
    <span>
      {{ 'P2P_WIDGETS.PREVIEW_DEPOSIT_MODIFIER_DIALOG.CUSTOM_PRICE_CAN_TAKE_LONG_TIME' | translate }}
    </span>
  </div>
  <div class="preview-deposit-modifier-dialog__agree">
    <mat-checkbox
      [checked]="false"
      [color]="'primary'"
      formControlName="agree"
    >
      {{ 'P2P_WIDGETS.PREVIEW_DEPOSIT_MODIFIER_DIALOG.AGREE_CHECKBOX_LABEL' | translate }}
    </mat-checkbox>
  </div>
  <div class="preview-deposit-modifier-dialog__action">
    <button class="btn btn-primary">{{ 'CONFIRM' | translate }}</button>
  </div>
</form>
