import {IconConfig} from "./icon.model";

export const iconsBugBounty: IconConfig[] = [
  {
    name: 'bug-bounty-icon',
    path: 'assets/img/mat-icons/bug-bounty/bug-bounty-icon.svg',
  },
  {
    name: 'bug-bounty-logo',
    path: 'assets/img/mat-icons/bug-bounty/bug-bounty-logo.svg',
  },
];
