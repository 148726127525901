import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { EnvironmentService } from '@csgofast/core/environment-service';
import {SoundService} from "@csgofast/core/sound-service";
import {
  excludesRoutesFrame,
  excludesRoutesHome,
  FrameMessage,
  FrameMessageTypes,
  legacyRoutes,
  MatchedRoutesFromBB,
  MatchedRoutesToBB,
  SpecialGameRoutesToBB,
  SpecialPartRoutesFrmBB,
} from './iframe.model';
import { IFrameCommonService } from './services/common.service';
import { IFrameMessageService } from './services/message.service';
import { LanguageService, MAIN_LANG } from "@csgofast/core/language-service";
import { SeoService } from "@csgofast/core/seo-service";
import {Observable} from "rxjs";

@Component({
  selector: 'csgofast-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IframeComponent implements OnInit {
  private soundStatus: boolean;
  h1$: Observable<{value: string, data: any}> = this.seoService.h1$;
  main$: Observable<{value: string, data: any}> = this.seoService.main$;
  faq$: Observable<{value: string, data: any}> = this.seoService.faq$;

  get showOtherComponent(): boolean | undefined {
    return this._showOtherComponent;
  }

  set showOtherComponent(value: boolean | undefined) {
    if(value && value !== this._showOtherComponent){
      //FIXME  костыль сраный на скорую руку
      setTimeout(()=>this.calcFrameScale(), 500)
    }
    this._showOtherComponent = value;
  }

  @ViewChild('iframe', { static: false })
  iframe!: ElementRef<HTMLIFrameElement>;
  @ViewChild('iframeWrapper', { static: false })
  iframeWrapper!: ElementRef<HTMLDivElement>;
  @Input() public excludesRoutes: string[];

  public url = 'https://bridge.csgofast.com';
  public urlSafe: SafeResourceUrl | undefined;
  private _showOtherComponent: boolean | undefined;
  public showIframe = false;
  public environment = this.environmentService.environments;
  readonly hostName = this.environment.HOSTNAME.split('//')[1];
  public excludesRoutesFrame: string[];
  public legacyRoutes: string[];
  public routesToBB: string[];
  public showLoader: boolean;
  private isProduction: boolean;
  private timerId: NodeJS.Timeout | undefined;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.calcFrameScale();
  }

  @HostListener('window:message', ['$event'])
  private message(event: MessageEvent<FrameMessage>) {
    if (event.origin === this.url) {
      if (event.data.type === FrameMessageTypes.MESSAGE_FROM_BB && event.data.eventName === 'inited') {
        this.messageService.setInited = true;
        this.commonService.setFrameStatus();
      }

      if (event.data.type === FrameMessageTypes.ROUTING_FROM_BB) {
        const initRoute = this.router.url.split('?')[0];
        const matcher = (): string | undefined => {
          const path = event.data.payload['path'];
          const match = MatchedRoutesFromBB[path];
          const specialMatch = SpecialPartRoutesFrmBB.some(el=>path.includes(el));
          return match ? match : (specialMatch) ? path : undefined
        }
        const match = matcher();
        if (
          initRoute !== event.data.payload['path'] &&
         ( !this.isExcludedRoute(event.data.payload['path']) &&
          !this.isExcludedRoute(initRoute) || match )
        ) {
          this.router.navigate([match ? match : event.data.payload['path']]);
        }
      }
      if (event.data.type === FrameMessageTypes.MESSAGE_FROM_BB_SOCKET) {
        this.messageService.trigerEvent(FrameMessageTypes.MESSAGE_FROM_BB_SOCKET, event.data.eventName, event.data.payload);
      }
      if (event.data.type === FrameMessageTypes.MESSAGE_FROM_BB) {
        this.messageService.trigerEvent(FrameMessageTypes.MESSAGE_FROM_BB, event.data.eventName, event.data.payload);
      }
      if (!this.isProduction && event.data.eventName !== 'change.gameStatus') {
        console.log(event.data);
      }
    }
  }

  constructor(
    @Inject('isServer') private readonly isServer: boolean,
    private readonly messageService: IFrameMessageService,
    private readonly commonService: IFrameCommonService,
    private readonly environmentService: EnvironmentService,
    private readonly soundService: SoundService,
    private readonly languageService: LanguageService,
    private readonly renderer: Renderer2,
    public readonly sanitizer: DomSanitizer,
    private readonly seoService: SeoService,
    private router: Router
  ) {
    this.environment = this.environmentService.environments;
    this.soundStatus =false;
    this.soundService.status$.subscribe(value => {
      this.soundStatus = value;
      this.toggleSoundInFrame(value)
    })

    this.isProduction = this.environmentService.environments.production;
    const hostName = this.environmentService.environments.HOSTNAME;
    const defaultBBUrl = this.environmentService.environments.BACKBONE_URL;
    this.showLoader = true;
    const getLocalBBHost = () => {
      const urlArr = hostName.split(':');
      urlArr.splice(2, 1, '1616');
      return urlArr.join(':');
    };
    this.url = hostName.includes('local') && !this.environment.BACKBONE_URL ? getLocalBBHost() : defaultBBUrl;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    this.excludesRoutes = excludesRoutesHome.concat(
      ...Object.keys(LanguageService.getRouteLanguages()).reduce((routes: string[], lang) => {
        return routes.concat(...excludesRoutesHome.map(route => lang === MAIN_LANG ? route : `/${lang + route}`))
      }, []));
    this.excludesRoutesFrame = excludesRoutesFrame;
    this.legacyRoutes = legacyRoutes;
    this.routesToBB = [...Object.keys(SpecialGameRoutesToBB), ...Object.keys(MatchedRoutesToBB)];
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        if (!this.isServer && this.iframe && this.iframe.nativeElement.contentWindow) {
          if (!this.isExcludedRoute(event.url)) {
            this.timerId && clearTimeout(this.timerId);
            this.timerId = setTimeout(() => this.navigateTo(event.url), 300);
            this.toggleSoundInFrame(this.soundStatus)
          } else {
            this.toggleSoundInFrame(false)
          }
        }

        if (!this.showIframe) {
          this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.url}${event.url}`);
          this.showIframe = !this.isExcludedRoute(event.url) && this.isLegacyRoute(LanguageService.getBaseUrl(event.url));
        }
        this.showOtherComponent = !this.isExcludedRoute(event.url) && this.isLegacyRoute(LanguageService.getBaseUrl(event.url));
      }
    });
  }

  private toggleSoundInFrame(status:boolean):void{
    this.messageService.sendMessage({
      type: FrameMessageTypes.MESSAGE_TO_BB,
      eventName: 'soundToggle',
      payload: { status },
    });
  }

  private calcFrameScale() {
    if (!this._showOtherComponent || this.isServer) return;
    const currentWidth = this.iframeWrapper.nativeElement.clientWidth;
    // const availableWidth = event.target.innerWidth;
    const scaleX = +(currentWidth / 900).toFixed(2);
    if ( scaleX === 0) return;
    this.iframe.nativeElement.setAttribute(
      'style',
      `
      -ms-transform: scale(${scaleX <= 1 ? scaleX : 1});
      -moz-transform: scale(${scaleX <= 1 ? scaleX : 1});
     -o-transform: scale(${scaleX <= 1 ? scaleX : 1});
      -webkit-transform: scale(${scaleX <= 1 ? scaleX : 1});
      transform: scale(${scaleX <= 1 ? scaleX : 1});
    `
    );
    this.renderer.setStyle(this.iframe.nativeElement, 'zoom', `${scaleX <= 1 ? scaleX : 1}`);
    this.renderer.setStyle(this.iframe.nativeElement, 'width', `${scaleX <= 1 ? 100 / scaleX : 100}%`);
    this.renderer.setStyle(this.iframe.nativeElement, 'height', `${scaleX <= 1 ? 100 / scaleX : 100}%`);

  }

  public isExcludedRoute(url: string): boolean {
    const excludesRoute = this.excludesRoutes.some((route) => url.split('?')[0].startsWith(route));
    return url === '/' || excludesRoute || this.excludesRoutesFrame.includes(url.split('?')[0]);
  }

  public isLegacyRoute(url: string): boolean {
    return this.legacyRoutes.some((route) => {
      let activeRoute = url.split('?')[0];
      let currentRoute = route;
      if(currentRoute.includes(':')) {
        const urlParts = activeRoute.split('/');
        const routeParts = currentRoute.split('/');
        activeRoute = urlParts.slice(0, urlParts.length-1).join('/');
        currentRoute = routeParts.slice(0, routeParts.length-1).join('/');
      }
      return activeRoute === '/' + currentRoute
    }) || this.routesToBB.some((route) => url.split('?')[0] === route);
  }

  public navigateTo(url: string) {
    if (SpecialGameRoutesToBB[url]) {
      this.messageService.sendMessage({
        type: FrameMessageTypes.ROUTING_TO_BB,
        eventName: 'specialGame',
        payload: { path: SpecialGameRoutesToBB[url] },
      });
    } else if (
      url.includes('profile') ||
      url.includes('/ref/') ||
      url.includes('/fail-payment/') ||
      url.includes('/success-payment/')
    ) {
      this.messageService.sendMessage({
        type: FrameMessageTypes.ROUTING_TO_BB,
        eventName: 'routing',
        payload: { path: url },
      });
    } else {
      this.messageService.sendMessage({
        type: FrameMessageTypes.ROUTING_TO_BB,
        eventName: 'routing',
        payload: { path: MatchedRoutesToBB[url] },
      });
    }
  }

  public ngOnInit(): void {
    const initRoute = LanguageService.getBaseUrl(this.router.url.split('?')[0]);
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.url}${initRoute}`);
    this.showIframe = !this.excludesRoutes.some((route) =>
      initRoute === route)
      && this.isLegacyRoute(initRoute)
      && initRoute !== '/';
    this.showOtherComponent = !this.excludesRoutes.some((route) => initRoute.startsWith(route))  && this.isLegacyRoute(initRoute) && initRoute !== '/';
  }

  public frameLoaded(e: Event) {
    if (!this.iframe) return;
    this.calcFrameScale();
    this.messageService.target = this.iframe;
    this.showLoader = false;
  }

  public frameRejected(event: ErrorEvent | string) {
    if (typeof event === 'string') {
      throw new Error(event);
    }
    throw event;
  }
}
