import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'fast-ui-ny-event-progress-bar',
  templateUrl: './ny-event-progress-bar.component.html',
  styleUrls: ['./ny-event-progress-bar.component.scss'],
  animations: [
    trigger('progressBar', [
      state(
        '*',
        style({
          width: '{{newProgress}}%',
        }),
        { params: { newProgress: 0 } }
      ),
      transition(
        'void=>*',
        [
          style({
            width: '{{newProgress}}%',
          }),
          animate('0.1s'),
        ],
        { params: { newProgress: 0 } }
      ),
      // transition(
      //   '*=>*',
      //   [
      //     style({
      //       width: '{{newProgress}}%',
      //     }),
      //     animate('0.3s'),
      //   ],
      //   { params: { newProgress: 0 } }
      // ),
    ]),
  ],
})
export class NyEventProgressBarComponent {
  @Input() public progress!: number;
  @Input() public eventName: string | null | undefined;

  constructor() {
    //
  }
}
