import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, Location } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { REQUEST } from "@nguniversal/express-engine/tokens";
import { Request } from "express";
import { MAIN_LANG} from "./language.const";
import { LanguageService } from "./language.service";
import { Store } from '@ngxs/store';


/**
 * Этот гвард служит одной роли - определять во время маршрутизации какой язык у пользака и выставлять нужный (а далее сервисы подменят адрес нужным языковым кодом)
 */
@Injectable()
export class LanguageGuard implements CanActivate {
  constructor (
    private router: Router,
    private translate: TranslateService,
    private location: Location,
    private languageService: LanguageService,
    private store: Store,
    @Optional() @Inject(REQUEST) private request: Request,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT) private document: Document,
    @Inject('isServer') private isServer: boolean
  ) {
  }

  public canActivate(snapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.updateLanguage(snapshot, state);
  }

  /**
   * Определяем какой язык у пользователя
   */
  private detectLanguage(snapshot: ActivatedRouteSnapshot): string {
    // Если пользак (или гость) уже выбирали язык ранее сами - достаем из localStorage
    const localLang = localStorage.getItem('lang')?.slice(0,2);
    const pathLang = snapshot.data['lang'];

    // Приоритеты: Выбранный язык > Адресная строка > Дефолтное значение 'en'
    // Для ботов (сср) приоритет маршрута идет выше для того, чтобы, условно, китайский язык не закэшировался на русский путь
    const resultLang = this.isServer
      ? pathLang
      : localLang
      // || LanguageService.initSelectedLang
      || pathLang;
    return LanguageService.isAcceptedLanguage(resultLang) ? resultLang : MAIN_LANG;
  }

  private updateLanguage(snapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const selectedLang = this.detectLanguage(snapshot);
    const langs = LanguageService.getRouteLanguages();
    this.languageService.setLanguage(langs[selectedLang] || langs[MAIN_LANG]);
    return true;
  }
}
