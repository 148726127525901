import {StateClass} from '@ngxs/store/internals';
import {AccountState} from './account/src';
import {ChatState} from './chat/src/index';
import {CurrencyState} from './currency-store/src';
import {GamesState} from './games-store/src';
import {InventoryState} from './inventory/src';
import {LayoutState} from './layout/src';
import {LotteryState} from './lottery/src';
import {ModalsState} from './modals/src';
import {NotificationsState} from './notifications/src';
import {PaymentState} from './payment-store/src';
import {ReferralsState} from './referrals/src';
import {UserState} from './user-store/src';
import {LanguageState} from "../language-service/src/lib/language.state";
import {SeoState} from "../seo-service/src/lib/seo.state";

export * from './chat/src/index';
export * from './currency-store/src/index';
export * from './games-store/src/index';
export * from './inventory/src/index';
export * from './layout/src/index';
export * from './lottery/src/index';
export * from './modals/src/index';
export * from './notifications/src/index';
export * from './payment-store/src/index';
export * from './referrals/src/index';
export * from './user-store/src/index';
export * from './account/src/index';

export const STATES: StateClass<any>[] = [
  ChatState,
  CurrencyState,
  GamesState,
  InventoryState,
  LayoutState,
  LotteryState,
  ModalsState,
  NotificationsState,
  PaymentState,
  ReferralsState,
  UserState,
  AccountState,
  LanguageState,
  SeoState
];
