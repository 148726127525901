<div class="possible-items-container" [class.compact]="isCompact">
  <ng-container *ngIf="!isCompact">
    <div class="possible-items-header">
      <div class="header-title">{{ 'GOFAST.CASES.SPIN_GAME.POSSIBLE_ITEMS.CASE_CONTENTS' | translate }}:</div>
      <div class="possible-gain-motivator">
        <span class="possible-gain-motivator-text">{{ 'GOFAST.CASES.SPIN_GAME.POSSIBLE_ITEMS.POTENTIAL_MOTIVATOR' | translate }}: </span>
        <span class="possible-gain-motivator-currency">{{ totalPossibleWin() | appCurrency }}</span>
      </div>
      <div class="case-info">
<!--        <div class="provably-fair">-->
<!--          <a [routerLink]="'/provably-fair'" [queryParams]="{ id: rollId }" type="button" class="btn w-s btn-transparent-theme-500">-->
<!--            <div class="provably-fair__title">{{ 'GOFAST.PROVABLY_FAIR.TITLE' | translate }}</div>-->
<!--            &lt;!&ndash; <mat-icon class="icon" [svgIcon]="'more-info'"></mat-icon> &ndash;&gt;-->
<!--          </a>-->
<!--          &lt;!&ndash; <ng-container *ngIf="clientSeed">-->
<!--            <label class="edit-seed-wrapper">-->
<!--              <input -->
<!--                type="text" -->
<!--                class="edit-input" -->
<!--                [value]="clientSeed" -->
<!--                (change)="changeClientSeed($event)" -->
<!--                [readonly]="!isClientSeedEditing">-->
<!--                <button -->
<!--                    class="btn btn-empty btn-edit" -->
<!--                    [class.active]="isClientSeedEditing"-->
<!--                    type="button" -->
<!--                    (click)="toggleClientSeedEditing()">-->
<!--                  <ng-container *ngIf="!isClientSeedEditing">-->
<!--                    Edit seed client-->
<!--                  </ng-container>-->
<!--                  <ng-container *ngIf="isClientSeedEditing">-->
<!--                    Done seed client-->
<!--                  </ng-container>-->
<!--                </button>-->
<!--            </label>-->
<!--          </ng-container> &ndash;&gt;-->
<!--        </div>-->
        <button type="button" class="btn w-s btn-transparent-theme-500" (click)="openCaseRevisionModal()">
          {{ 'GOFAST.CASES.SPIN_GAME.BUTTONS.BUTTON_CASE_REVISION' | translate }}
        </button>
      </div>
    </div>
  </ng-container>
  <div class="possible-items">
    <ng-container>
      <fast-ui-possible-card
        *ngFor="let item of sortedItems; let index = index"
        (clickOnCard)="navigateToCaseItem(item)"
        [index]="index"
        [itemRevisions]="item"
        [isHovered]="isHovered"
        [tracksAmount]="tracksAmount">
      </fast-ui-possible-card>
    </ng-container>
  </div>
</div>
