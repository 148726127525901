import {Component, Inject} from '@angular/core';
import {Observable} from 'rxjs';
import {DOCUMENT} from '@angular/common';
import {INotification} from '@dev-fast/types';
import {notificationSlideLeftAnimation} from './notification.animation';
import {ToastService} from '../toast.service';
import {getHiddenKey} from '@csgofast/shared/utils';

@Component({
  selector: 'csgofast-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  animations: [notificationSlideLeftAnimation],
})
export class NotificationComponent {
  public notifications$: Observable<INotification[]>;

  constructor(
    public notificationService: ToastService,
    @Inject(DOCUMENT) private document: Document & { msHidden?: boolean; webkitHidden?: boolean }
  ) {
    this.notifications$ = this.notificationService.showcaseNotifications$;

    const { visibilityChange } = getHiddenKey(this.document);
    this.document.addEventListener(visibilityChange, () => this.handleVisibilityChange(), false);
  }

  public handleVisibilityChange(): void {
    const { hidden } = getHiddenKey(this.document);
    if (this.document[hidden as keyof Document]) {
      this.notificationService.pauseTimer();
    } else {
      this.notificationService.resumeTimer();
    }
  }

  public deleteNotification(notification: INotification): void {
    this.notificationService.deleteNotification(notification.id);
  }

  public pauseNotifications(): void {
    this.notificationService.pauseTimer();
  }

  public resumeNotifications(): void {
    this.notificationService.resumeTimer();
  }

  public trackByIndex(index: number, element: INotification): number {
    return element.id;
  }
}
