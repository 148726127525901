export enum BonusTransactionTypes {
  CLASSIC_FAST = 'classic/fast',
  FAUCET = 'faucet',
  ADMIN = 'admin',
  TRADE_REFUND = 'trade-refund',
  MINI_JACKPOT = 'mini-jackpot',
  BOOST = 'boost',
  XP = 'xp',
  CASHBACK = 'cashback',
  REFERRAL = 'referral',
  CONTRACT = 'contract',
}
