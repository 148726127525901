import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgxsModule} from '@ngxs/store';
import {P2pBuyingState, P2pCommonState, P2pDepositState} from './states';
import {P2pBuyingService, P2pDepositService} from './services';

@NgModule({
  imports: [CommonModule, NgxsModule.forFeature([P2pCommonState, P2pDepositState, P2pBuyingState])],
  providers: [P2pDepositService, P2pBuyingService, P2pCommonState],
})
export class P2pModule {}
