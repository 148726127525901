import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

import {IGame, IInputMessage, IMessage, IStickerPack, IUserDetailed} from '@dev-fast/types';
import {Select, Store} from '@ngxs/store';
import {Dispatch} from '@ngxs-labs/dispatch-decorator';
import {ChatState, CheckCanChat, GetMessagesList, SendMessage, SetActiveStickerPack} from '@csgofast/core/state/chat';
import {UserState} from '@csgofast/core/state/user-store';
import {GamesState} from '@csgofast/core/state/games-store';
import {ChatEngine} from '@csgofast/widgets/chat';
import {ChangeActivePanel} from '@csgofast/core/state/layout';

@Injectable()
export class ChatService implements ChatEngine {
  @Select(ChatState.items)
  public messages$!: Observable<IMessage[]>;
  @Select(ChatState.stickers)
  public stickers$!: Observable<IStickerPack[]>;
  @Select(ChatState.activeStickerPack)
  public activeStickerPack$!: Observable<IStickerPack>;
  @Select(ChatState.chatId)
  public chatId$!: Observable<string>;
  @Select(UserState.user)
  public user$!: Observable<IUserDetailed | null>;
  @Select(GamesState.availableGames)
  public availableGames$!: Observable<IGame[]>;
  @Select(GamesState.currentGame)
  public currentGame$!: Observable<IGame | null>;

  private readonly banTimer: BehaviorSubject<string>;
  public readonly banTimer$: Observable<string>;
  constructor(private readonly store: Store) {
    this.banTimer = new BehaviorSubject<string>('00:00:00');
    this.banTimer$ = this.banTimer.asObservable();
  }

  @Dispatch() public addMessage = (msg: IInputMessage) => new SendMessage(msg);
  @Dispatch() public setActiveStickerPack = (name: string) => new SetActiveStickerPack(name);
  @Dispatch() public getMessagesList = () => new GetMessagesList();
  @Dispatch() public checkCanChat = () => new CheckCanChat();
  @Dispatch() public closeWidget = () => new ChangeActivePanel(null);
}
