import {Directive, ElementRef, Input, OnChanges, Renderer2} from '@angular/core';
import {EnvironmentService} from '@csgofast/core/environment-service';
import {LazyLoadImageDirective} from '../lazy-load-image/lazy-load-image.directive';

@Directive({
  selector: 'img[skinItemImage]',
})
export class SkinItemImageDirective extends LazyLoadImageDirective implements OnChanges {
  @Input() public width = 330;
  @Input() public height = 192;

  constructor(el: ElementRef,private render: Renderer2, private readonly environmentService: EnvironmentService) {
    super(el,render);
  }
  public override ngOnChanges(): void {
    if (this.src) {
      if (!(this.src.startsWith('http') || this.src.startsWith('//'))) {
        this.src = `https://steamcommunity-a.akamaihd.net/economy/image/${this.src}/${this.width}x${this.height}`;
      }
    }
    this.fallback = this.environmentService.environments.CHIP_FALLBACK_URL;

    super.ngOnChanges();
  }
}
