import { PromoCode } from '@dev-fast/types';
import moment from 'moment/moment';

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
/**
 * для коротких массивов
 */
export const getMaxOfArray = (numArray: number[]): number => {
  return Math.max.apply(null, numArray);
};
/**
 * для коротких массивов
 */
export const getMinOfArray = (numArray: number[]): number => {
  return Math.min.apply(null, numArray);
};
/*
  для поиска максимального числа в массиве. В зависимости от fieldNameReturn возвращает либо массив объектов, либо массив значений
*/
export const maxValueOfItemField = (items: any[], fieldName: string, fieldNameReturn?: string): any[] => {
  if (items.length) {
    const maxValue = items.reduce((a, b) => (a[fieldName] > b[fieldName] ? a : b))[fieldName];
    let returnedItems = items.filter((item) => item[fieldName] === maxValue);
    if (fieldNameReturn) {
      returnedItems = returnedItems.map((item) => item[fieldNameReturn]);
    }
    return returnedItems;
  }
  return items;
};
export const getKeyByValue = <T extends object, K extends keyof T>(object: T, value: T[K] | undefined): string | undefined => {
  if (!value) return;
  return Object.keys(object).find((key) => (object as T)[key as K] === value);
};
export const getBaseDomain = (host: string, dot: boolean = true) => {
  const regexWithoutPort = /^(.*)\\:\d*$/gm;
  const resultWithoutPort = regexWithoutPort.exec(host);
  const withoutPort = resultWithoutPort ? resultWithoutPort[1] : host;
  const regexBaseDomain = /^.*\.(.*\..*)$/gm;
  const resultBaseDomain = regexBaseDomain.exec(withoutPort);
  return (dot ? '.' : '') + (resultBaseDomain ? resultBaseDomain[1] : withoutPort);
};
export const notNull = (value: any): boolean => value !== null && value !== undefined;

// export const isActualPromo = (promo: PromoCode): boolean => {
//   return (
//     (promo.canActivate === null || promo.canActivate === undefined || promo.canActivate) &&
//     (!promo.activationEndDate || moment(promo.activationEndDate).isAfter(moment()))
//   );
// };

export const getUniqueObjectsFromArr = <T>(origArr: T[], key: string, groupKey?: string): T[] => {
  const uniqArr = <T>(arr: T[], k: string) => {
    return  Array.from(new Set(arr.map((item) => item[k as keyof typeof item])));
  };
  const uniqByKey = <T>(arr: T[], k: string):T[] => {
    const filteredArr = uniqArr(arr,k);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return filteredArr.map((value) => arr.find((el) => el[k as keyof typeof el] === value)!);
  };

  if (groupKey) {
    const uniqGroupValues = uniqArr(origArr, groupKey);
    const groupedArr = uniqGroupValues.map(value => origArr.filter(el=>el[groupKey as keyof typeof el]===value))
    return groupedArr.flatMap(val=> uniqByKey(val,key));
  } else {
    return uniqByKey(origArr,key);
  }
};
