import { Component } from '@angular/core';
import { ICaseQuestMotivatorLastDto, ICaseQuestTotalProgress, ICaseCollection, ICasesQuestItem, EventsConfig } from '@dev-fast/types';
import { Observable, map } from 'rxjs';
import { NyEventService, NyAllEventService } from '../core';

@Component({
  selector: 'csgofast-ny-results-shop',
  templateUrl: './ny-results-shop.component.html',
  styleUrls: ['./ny-results-shop.component.scss'],
})
export class NyResultsShopComponent {
  public motivatorList$: Observable<ICaseQuestMotivatorLastDto[]> | undefined;
  public totalProgress$: Observable<ICaseQuestTotalProgress> | undefined;
  // public eventName$: Observable<keyof EventsConfig | null> | undefined;

  public eventHistoryName$: Observable<keyof EventsConfig | null> | undefined;

  public casesCollection$: Observable<ICaseCollection> | undefined;
  public overlay$: Observable<ICasesQuestItem | null> | undefined;
  public reward$: Observable<ICasesQuestItem | null> | undefined;
  public items$: Observable<ICasesQuestItem[]> | undefined;
  public categoriesIsLoad$: Observable<boolean>;

  constructor(private readonly nyEventService: NyEventService, private readonly nyAllEventService: NyAllEventService) {
    this.categoriesIsLoad$ = this.nyAllEventService.getCasesIsExecuting$.pipe(map((el) => !!el));
    this.motivatorList$ = this.nyEventService.motivatorList$;
    this.totalProgress$ = this.nyEventService.totalProgress$;
    this.casesCollection$ = this.nyAllEventService.cases$;
    // this.eventName$ = this.nyEventService.eventName$;
    this.overlay$ = this.nyEventService.overlay$;
    this.reward$ = this.nyEventService.reward$;
    this.items$ = this.nyEventService.items$;  

    this.eventHistoryName$ = this.nyEventService.eventHistoryName$;

  }
}
