import {Injectable} from '@angular/core';
import {IMarketplaceItem, IP2pPurchaseItem} from '@dev-fast/types';
import {Dispatch} from '@ngxs-labs/dispatch-decorator';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {
  ConfirmBid,
  CreateBid,
  DeleteBid,
  GetMyBids,
  P2pBuyingState,
  RemoveItem,
  ToggleSelected,
  UpdateShowWarnValue
} from '../states';

@Injectable()
export class P2pBuyingService {
  @Select(P2pBuyingState.purchasing)
  public purchasing$!: Observable<IP2pPurchaseItem[]>;
  @Select(P2pBuyingState.showWarn)
  public showWarn$!: Observable<boolean>;

  public addSelected(value: IMarketplaceItem): void {
    this.toggleSelected(value);
  }

  @Dispatch() public close = (idx: number) => new RemoveItem(idx);
  @Dispatch() public deleted = (idx: number) => new DeleteBid(idx);
  @Dispatch() public toggleSelected = (value: IMarketplaceItem) => new ToggleSelected(value);
  @Dispatch() public createBid = (id: number) => new CreateBid(id);
  @Dispatch() public confirmBid = (id: number) => new ConfirmBid(id);
  @Dispatch() public initialize = () => new GetMyBids();
  @Dispatch() public updateShowWarnValue = (value: boolean) => new UpdateShowWarnValue(value);
}
