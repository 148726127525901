import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { AnalyticsService } from '@csgofast/core/analytics-service';
import { ChatraService } from "@csgofast/core/chatra-service";
import { ChangeFrameLocalStorage, FrameMessageTypes, IFrameMessageService, IFrameState } from '@csgofast/core/iframe';
import { LocalStorageService } from '@csgofast/core/local-storage-service';
import { NotificationsService } from '@csgofast/core/notification-service';
import { CloseModal, ModalsState, OpenModal } from '@csgofast/core/state/modals';

import { UserApiService } from '@dev-fast/backend-services';
import {
  EmailSubscribe,
  IUserDetailed,
  IUserExperience,
  IUserP2pPermissionInterface,
  IUserSettings,
  IUserSettingsDetailed,
  ModalNames,
  Permissions,
  ProfileTypes,
  SteamErrorsEnum,
  UserCountry,
} from '@dev-fast/types';
import { Navigate } from '@ngxs/router-plugin';
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import * as Sentry from '@sentry/angular';
import { Socket as WrappedSocket } from 'ngx-socket-io';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { USER_INITIAL_STATE, UserStateModel } from './user-state.model';
import {
  ChangeAdditionalSettings,
  ConfirmEmail,
  GetAdditionalSettings,
  GetExperience,
  GetP2pPermissions,
  GetUser,
  GetUserCountry,
  Init,
  InitError,
  InitSuccess,
  RefreshCurrentUser,
  SwitchWalletType,
  SwitchWalletTypeComplete,
  Unsubscribe,
  UpdateAccountType,
  UpdateAvatar,
  UpdateCoinsAmount,
  UpdateCurrentUser,
  UpdateDemoCoinsAmount,
  UpdateEmail,
  UpdateXp,
} from './user.actions';

@State<UserStateModel>({
  name: 'user',
  defaults: USER_INITIAL_STATE,
})
@Injectable()
export class UserState implements NgxsOnInit {
  constructor(
    private readonly apiService: UserApiService,
    private readonly store: Store,
    private readonly storage: LocalStorageService,
    @Optional() private readonly frameMessageService: IFrameMessageService,
    private readonly ws: WrappedSocket,
    private readonly analyticsService: AnalyticsService,
    private readonly notificationsService: NotificationsService,
    private readonly chatraService: ChatraService
  ) {}

  @Selector()
  public static p2pPermissions({ p2pPermissions }: UserStateModel): IUserP2pPermissionInterface | null {
    return p2pPermissions;
  }
  @Selector()
  public static permissions({ permissions }: UserStateModel): Permissions[] {
    return permissions;
  }
  @Selector()
  public static additionalSettings({ additionalSettings }: UserStateModel): IUserSettingsDetailed | null {
    return additionalSettings;
  }

  @Selector()
  public static user({ user }: UserStateModel): IUserDetailed | null | undefined {
    return user;
  }

  @Selector()
  public static userRequestState({ userRequestState }: UserStateModel): boolean | null {
    return userRequestState;
  }

  @Selector()
  public static userCountry({ country }: UserStateModel): string | null {
    return country;
  }

  @Selector()
  public static tradelinkIsValid({ p2pPermissions }: UserStateModel): boolean {
    if (
      p2pPermissions &&
      p2pPermissions.canTrade &&
      p2pPermissions.canTrade.success === false &&
      (p2pPermissions.canTrade.error === SteamErrorsEnum.INVALID_TRADELINK ||
        p2pPermissions.canTrade.error === SteamErrorsEnum.NO_TRADE_TOKEN)
    ) {
      return false;
    } else {
      return true;
    }
  }
  @Selector()
  public static apikeyIsValid({ p2pPermissions }: UserStateModel): boolean {
    if (
      p2pPermissions &&
      p2pPermissions.canSteamAPI &&
      p2pPermissions.canSteamAPI.success === false &&
      ['invalid API key', 'fake API key'].includes(p2pPermissions.canSteamAPI.error)
    ) {
      return false;
    } else {
      return true;
    }
  }
  public ngxsOnInit({ dispatch, patchState }: StateContext<UserStateModel>): void {
    // нужно, чтобы при логауте значение поля юзера устанавливать в null
    patchState({
      user: undefined,
     })
    // const user = this.storage.get('user');
    // if (user) {
    //   //FIXME
    //   Sentry.configureScope((scope) => {
    //     scope.setUser({
    //       id: `${user.id}`,
    //       username: user.name,
    //       email: user.email.address,
    //     });
    //     scope.setTag('language', user.locale);
    //     // scope.setExtra(
    //     //   'important-information',
    //     //   'a very important information to help!'
    //     // );
    //   });
    //   patchState({ user });
    // }
    if (this.frameMessageService) {
      this.frameMessageService.on(
        FrameMessageTypes.MESSAGE_FROM_BB_SOCKET,
        'wallet-new.switch',
        (payload: { success: boolean; walletType: number }) => dispatch(new SwitchWalletTypeComplete(payload))
      );
      this.frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'level-up', () => dispatch(new GetExperience()));
    }

    this.ws.on('wallet-new.switch', (payload: { success: boolean; walletType: number }) => {
      const frameLoaded = this.store.selectSnapshot(IFrameState.loaded);
      if (!frameLoaded) dispatch(new SwitchWalletTypeComplete(payload));
    });
    this.ws.on('user.coinsAmount.updated', (coinsAmount: number) => dispatch(new UpdateCoinsAmount(coinsAmount)));
    this.ws.on('user.demoCoinsAmount.updated', (demoCoinsAmount: number) => dispatch(new UpdateDemoCoinsAmount(demoCoinsAmount)));
    this.ws.on('user.xp.updated', (value: { xp: number }) => dispatch(new UpdateXp(value.xp)));
    this.ws.on('user.account-type.updated', (value: { type: ProfileTypes }) => dispatch(new UpdateAccountType(value.type)));
    this.ws.on('user.perm-lock', (payload: { value: string | null }) =>
      payload.value
        ? dispatch([new RefreshCurrentUser({ permLock: payload.value })])
        : dispatch([new RefreshCurrentUser({ permLock: payload.value }), new CloseModal(ModalNames.BAN), new Navigate(['./'])])
    );
  }

  @Action(Init)
  public init({ dispatch, patchState }: StateContext<UserStateModel>): Observable<void> {
    return forkJoin([this.apiService.getMeProfile({ detailed: true }) /**this.apiService.getUserCountry()*/]).pipe(
      // tap((response) => {
      //   console.log(response)
      // }),
      switchMap(([user]) => {
        this.analyticsService.addUserIdEvent(user.id);
        const userModified: IUserDetailed = {
          ...user,
          // country: user.country || geo.country,
          // refillCountry: user.refillCountry || geo.country,
        };
        patchState({ country: user.country });
        Sentry.configureScope((scope) => {
          scope.setUser({
            id: `${user.id}`,
            username: user.name,
            email: user.email.address,
          });
          scope.setTag('language', user.locale);
          // scope.setExtra(
          //   'important-information',
          //   'a very important information to help!'
          // );
        });
        this.chatraService.setIntegrationData({name: user.name, USER_ID: user.id.toString(), email: user.email ? user.email.address: ''});
        return dispatch([new RefreshCurrentUser(userModified), new GetP2pPermissions(), new GetAdditionalSettings(), new InitSuccess(user)]);
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403 || error.status === 503) {
          patchState({ user: null, userRequestState: false  });
        }
        return dispatch(new InitError());
      })
    );
  }

  //TODO подумать может сравнивать со старым юзером и патчить только измененные поля, что бы лишний раз не дергать изменения в других стейтах
  @Action(GetUser)
  public get({ dispatch, patchState }: StateContext<UserStateModel>): Observable<void> {
    return this.apiService.getMeProfile({ detailed: true }).pipe(
      switchMap((result: IUserDetailed) => {
        Sentry.configureScope((scope) => {
          scope.setUser({
            id: `${result.id}`,
            username: result.name,
            email: result.email.address,
          });
          scope.setTag('language', result.locale);
          // scope.setExtra(
          //   'important-information',
          //   'a very important information to help!'
          // );
        });
        return dispatch([new RefreshCurrentUser(result), new GetAdditionalSettings()]);
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403 || error.status === 503) {
          patchState({ user: null, userRequestState: false });
        }

        return of();
      })
    );
  }

  // @Action(SetUser)
  // public setUser({ patchState }: StateContext<UserStateModel>, { payload }: SetUser): void {
  //   patchState({ user: payload });
  // }
  @Action(GetAdditionalSettings)
  public getAdditionalSettings({ patchState }: StateContext<UserStateModel>): Observable<IUserSettings> {
    return this.apiService.getMeSettings().pipe(
      tap((response) => {
        const fastDelivery = this.storage.get('fastDelivery');
        patchState({ additionalSettings: { ...response, fastDelivery } });
      })
    );
  }
  @Action(ChangeAdditionalSettings)
  public changeAdditionalSettings(
    { getState, patchState, dispatch }: StateContext<UserStateModel>,
    { payload }: ChangeAdditionalSettings
  ): Observable<IUserSettings> {
    const state = getState();
    if (state.user === null || state.user === undefined) return of();
    if (payload.fastDelivery !== null && payload.fastDelivery !== undefined) {
      this.storage.set('fastDelivery', payload.fastDelivery);
      dispatch(new ChangeFrameLocalStorage({ fastDelivery: payload.fastDelivery }));
    }
    const { fastDelivery, ...main } = payload;
    return this.apiService.patchUserSettings(main).pipe(
      tap((response) => {
        const fastDelivery = this.storage.get('fastDelivery');
        patchState({ additionalSettings: { ...response, fastDelivery } });
      })
    );
  }
  @Action(GetExperience)
  public getExperience({ dispatch }: StateContext<UserStateModel>): Observable<void> {
    return this.apiService
      .getMeExperience()
      .pipe(switchMap((result: IUserExperience) => dispatch([new RefreshCurrentUser({ experience: result })])));
  }
  @Action(GetUserCountry)
  public getCountry({ patchState }: StateContext<UserStateModel>): Observable<UserCountry> {
    return this.apiService.getUserCountry().pipe(
      tap((country: UserCountry) => {
        patchState({ country: country.country });
      })
    );
  }
  @Action(GetP2pPermissions)
  public getP2pPermissions({ patchState }: StateContext<UserStateModel>): Observable<IUserP2pPermissionInterface> {
    return this.apiService.getP2pPermissions().pipe(
      tap((p2pPermissions: IUserP2pPermissionInterface) => {
        if (p2pPermissions.canTrade && !p2pPermissions.canTrade.success) {
          this.notificationsService.addErrorNotification(p2pPermissions.canTrade.error);
        }
        if (p2pPermissions.canSteamAPI && !p2pPermissions.canSteamAPI.success) {
          this.notificationsService.addErrorNotification(p2pPermissions.canSteamAPI.error);
        }
        patchState({ p2pPermissions });
      }),
      catchError((error: HttpErrorResponse) => this.onError(error))
    );
  }
  @Action(UpdateCurrentUser)
  public updUser({ getState, dispatch }: StateContext<UserStateModel>, { payload }: UpdateCurrentUser): Observable<void> {
    const state = getState();
    if (state.user === null || state.user === undefined) return of();

    return this.apiService.patchUserData(payload).pipe(
      switchMap(() => {
        return dispatch([new RefreshCurrentUser(payload)]);
      })
    );
  }
  @Action(RefreshCurrentUser)
  public refreshUser({ patchState, getState, dispatch }: StateContext<UserStateModel>, { payload }: RefreshCurrentUser): void {
    const state = getState();
    const newUser = {
      ...(state.user as IUserDetailed),
      ...payload,
    };

    if (
      state.user &&
      ((payload.steamApiKey && state.user?.steamApiKey !== payload.steamApiKey) ||
        (payload.links?.steam && state.user?.links?.steam !== payload.links?.steam))
    ) {
      dispatch(new GetP2pPermissions());
    }
    if (payload.locale) {
      Sentry.configureScope((scope) => {
        scope.setTag('language', payload.locale);
      });
    }
    if (payload.permLock) {
      dispatch([new OpenModal(ModalNames.BAN, payload.permLock), new Navigate(['block'])]);
    }

    patchState({
      user: newUser,
      userRequestState: true,
      permissions: this.permissionsFactory(newUser),
    });

    // this.storage.set('user', newUser);
  }
  @Action(Unsubscribe)
  public unsubscribe({ dispatch }: StateContext<UserStateModel>, { email }: Unsubscribe): Observable<EmailSubscribe> {
    return this.apiService.unsubscribeFromEmails(email).pipe(
      tap(() => {
        // dispatch(new GetUser());
      })
    );
  }
  @Action(UpdateEmail)
  public updateEmail({ dispatch }: StateContext<UserStateModel>, { address, isSubscribed }: UpdateEmail): Observable<EmailSubscribe> {
    return this.apiService.patchEmail(address, isSubscribed).pipe(
      tap(() => {
        // dispatch(new GetUser());
      })
    );
  }
  @Action(ConfirmEmail)
  public confirmEmail({ dispatch }: StateContext<UserStateModel>, { code }: ConfirmEmail): Observable<EmailSubscribe> {
    return this.apiService.confirmEmail(code).pipe(
      tap(() => {
        dispatch(new GetUser());
      })
    );
  }
  @Action(UpdateAvatar)
  public updateAvatar(
    { dispatch }: StateContext<UserStateModel>,
    { file }: UpdateAvatar
  ): Observable<{
    avatar: string;
  }> {
    return this.apiService.updateUserAvatar(file).pipe(
      tap(() => {
        dispatch(new GetUser());
      })
    );
  }
  @Action(SwitchWalletType)
  public switchWalletType({ dispatch, getState }: StateContext<UserStateModel>) {
    const frameLoaded = this.store.selectSnapshot(IFrameState.loaded);

    if (frameLoaded && this.frameMessageService) {
      return this.frameMessageService.sendMessage({
        type: FrameMessageTypes.MESSAGE_TO_BB_SOCKET,
        eventName: 'wallet-new.switch',
        payload: {},
      });
    } else {
      // return this.apiService.switchWalletType().pipe(switchMap(() => dispatch([new SwitchWalletTypeComplete(true)])));
      return this.apiService.switchWalletType();
    }
  }
  @Action(SwitchWalletTypeComplete)
  public switchWalletTypeComplete({ dispatch, getState }: StateContext<UserStateModel>, { payload }: SwitchWalletTypeComplete) {
    const { user } = getState();
    if (payload.success) {
      dispatch([new RefreshCurrentUser({ walletType: payload.walletType === 1 ? 'demo' : 'real' })]);
    } else {
      dispatch([new RefreshCurrentUser({ walletType: user?.walletType })]);
    }
  }
  @Action(UpdateCoinsAmount)
  public updateCoinsAmount({ dispatch }: StateContext<UserStateModel>, { payload }: UpdateCoinsAmount) {
    dispatch([new RefreshCurrentUser({ coinsAmount: payload })]);
  }
  @Action(UpdateDemoCoinsAmount)
  public updateDemoCoinsAmount({ dispatch }: StateContext<UserStateModel>, { payload }: UpdateCoinsAmount) {
    dispatch([new RefreshCurrentUser({ demoCoinsAmount: payload })]);
  }
  @Action(UpdateAccountType)
  public updateAccountType({ dispatch }: StateContext<UserStateModel>, { payload }: UpdateAccountType) {
    dispatch([new RefreshCurrentUser({ profileType: payload })]);
  }
  @Action(UpdateXp)
  public updateXp({ patchState, getState }: StateContext<UserStateModel>, { payload }: UpdateXp) {
    //FIXME
    // dispatch([new RefreshCurrentUser({ experience: {payload} })]);
    const { user } = getState();
    if (!user) return;
    const experience: IUserExperience = { ...user.experience, xp: payload };
    patchState({ user: { ...user, experience }, userRequestState: true });
  }
  private onError(e: HttpErrorResponse, msg?: string, system: boolean = true) {
    const { error } = e;
    this.notificationsService.addErrorNotification(msg || error.message || error.error, {
      icon: 'error',
      system: error.system || system,
    });
    return of();
  }
  private permissionsFactory(user: IUserDetailed | null): Permissions[] {
    const result: Permissions[] = [];
    if (user) {
      result.push(Permissions.MEMBER);
    }
    if ((user && !user.permLock) || !user) {
      result.push(Permissions.CAN_USE);
    }

    return result;
  }
}
