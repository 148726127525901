import {ILottery} from '@dev-fast/types';

export interface LotteryStateModel {
  items: ILottery[];
  countToShow: number;
  collectionToShow: ILottery[];
}

export const LOTTERY_INITIAL_STATE: LotteryStateModel = {
  items: [],
  collectionToShow: [],
  countToShow: 4,
};
