import { Breakpoints } from '@angular/cdk/layout';
import { Portal } from '@angular/cdk/portal';
import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { LayoutTypeService } from '@csgofast/core/layout-service';
import { PortalService } from '@csgofast/core/portal-service';
import { ScrollDispatcherService } from '@csgofast/core/scroll-service';
import { NgScrollbar } from 'ngx-scrollbar';
import { Observable, tap } from 'rxjs';
import { GofastLayoutService } from './services/gofast-layout.service';
import { MainModalsService } from '@csgofast/core/modal-service';
import { SeoService } from "@csgofast/core/seo-service";

@Component({
  selector: 'ui-gofast-layout',
  templateUrl: './gofast-layout.component.html',
  styleUrls: ['./gofast-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GofastLayoutComponent implements OnInit, AfterViewInit {
  public readonly breakpoints = Breakpoints;

  public layoutType$: Observable<string>;
  public isFadeSides$: Observable<boolean>;
  public sectionHeaderPortal$: Observable<Portal<any> | undefined>;
  @ViewChild(NgScrollbar) public scrollbarRef: NgScrollbar | undefined;

  constructor(
    @Inject('isServer') public readonly isServer: boolean,
    private readonly layoutTypeService: LayoutTypeService,
    private readonly moduleService: GofastLayoutService,
    private readonly modalsService: MainModalsService,
    private readonly scrollService: ScrollDispatcherService,
    private readonly portalService: PortalService,
    private readonly seoService: SeoService,
  ) {
    this.sectionHeaderPortal$ = this.portalService.sectionHeaderPortal.value$;
    this.isFadeSides$ = this.moduleService.isFadeSides$;
    //FIXME
    this.layoutType$ = this.layoutTypeService.layoutType$.pipe(tap((val) => this.moduleService.setLayoutType(val)));
  }

  public ngOnInit(): void {
    this.moduleService.init();
    this.modalsService.registerModals();

    // this.moduleService.openModal(ModalNames.DDOS)
  }
  public ngAfterViewInit(): void {
    if (this.scrollbarRef) {
      this.scrollService.registerScroll(this.scrollbarRef, 'main');
    }
  }
  public onClickLockedSide(): void {
    this.moduleService.clickOnLockedSide();
  }
  public isMobile(currentType: string): boolean {
    return currentType === Breakpoints.Handset || currentType === Breakpoints.XSmall;
  }
}
