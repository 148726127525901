import { Injectable } from '@angular/core';
import { Environments, EnvironmentService } from '@csgofast/core/environment-service';
import {Socket as WrappedSocket} from "ngx-socket-io";
import {ICaseItemDtoV2, ICaseLuckyWonEvent, ILRBalance, ILRLeaders, ILRQuest, TicketTypeEnum} from '@dev-fast/types';
import { Observable } from 'rxjs';
import {ApiService} from "./api.service";

@Injectable({
  providedIn: 'root',
})
export class LeaderRaceBackendService {
  private environment: Environments;

  constructor(
    private readonly api: ApiService,
    private readonly environmentService: EnvironmentService,
    private readonly ws: WrappedSocket
  ) {
    this.environment = this.environmentService.environments;
  }

  /** Returns current user tickets balance */
  public getBalanceTickets = (): Observable<ILRBalance> => {
    return this.api.get(`${this.environment.GATEWAY_URL}/api/users/me/leaders-race/tickets/balance`, {
      withCredentials: true,
    });
  };
  public getLRQuests = (): Observable<ILRQuest[]> => {
    return this.api.get(`${this.environment.GATEWAY_URL}/api/users/me/leaders-race/quests`, {
      withCredentials: true,
    });
  };
  public getLRLeaders = (): Observable<ILRLeaders> => {
    return this.api.get(`${this.environment.GATEWAY_URL}/api/quests/latest`, {
      withCredentials: true,
    });
  };
  public getLRCases = (): Observable<ICaseItemDtoV2[]> => {
    return this.api.get(`${this.environment.GATEWAY_URL}/api/leaders-race/shop/items/cases`, {
      withCredentials: true,
    });
  };
  public takeReward = (questId: number): Observable<void> => {
    return this.api.post(`${this.environment.GATEWAY_URL}/api/users/me/leaders-race/quests/${questId}/take-reward`, null, {
      withCredentials: true,
    });
  };
  public purchaseCase = (freeCaseId: number, ticketColor: TicketTypeEnum): Observable<void> => {
    return this.api.post(
      `${this.environment.GATEWAY_URL}/api/leaders-race/shop/items/cases`,
      { freeCaseId, ticketColor },
      {
        withCredentials: true,
      }
    );
  };
  public lRUpdateEvent(callback: (message: {questId: number, userCount?: number,rewardTaken?:boolean}) => void): void {
    this.ws.on('leaders-race.quest:update', callback);
  }
}
