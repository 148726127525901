import { ChangeDetectionStrategy, Component, Inject, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AuthModalsModule, AuthService } from '@csgofast/auth';
import { UserAvatarModule } from '@dev-fast/ui-components';
import { PromoCode} from '@dev-fast/types';
import { Observable } from 'rxjs';
import { AppCurrencyModule } from '@csgofast/shared/pipes';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'csgofast-referral-modal',
  templateUrl: './referral-modal.component.html',
  styleUrls: ['./referral-modal.component.scss'],

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReferralModalComponent {
  public isAuth$: Observable<boolean | null>;
  public currentReferral!: PromoCode | null;

  constructor(
    private readonly authService: AuthService,
    private readonly dialogRef: MatDialogRef<ReferralModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: PromoCode
  ) {
    this.currentReferral = data;
    this.isAuth$ = this.authService.isAuth$;
  }

  public close(): void {
    this.dialogRef.close();
  }
}

@NgModule({
  imports: [CommonModule, MatIconModule, RouterModule, AuthModalsModule, UserAvatarModule, AppCurrencyModule, TranslateModule],
  declarations: [ReferralModalComponent],
  exports: [ReferralModalComponent],
})
export class ReferralModalComponentModule {}
