import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {NotificationsService} from '@csgofast/core/notification-service';
import {GamesState} from '@csgofast/core/state/games-store';
import {Select} from '@ngxs/store';
import {map, Observable, tap} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AutoGameDeactivateGuard implements CanDeactivate<unknown> {
  @Select(GamesState.autoBetIsOn)
  public readonly autoBetIsOn$!: Observable<boolean>;

  constructor(private readonly notificationsService: NotificationsService) {}
  public canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.autoBetIsOn$.pipe(
      tap((value) => {
        if (value) {
          this.notificationsService.addInfoNotification('You need to stop auto betting first.');
        }
      }),
      map((value) => !value)
    );
  }
}
