import {Component, Input} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {interval, Observable, timer} from 'rxjs';
import {filter, map, startWith, switchMap, takeUntil} from 'rxjs/operators';
import {P2pPurchaseTradeModalComponent} from '../../modals/p2p-purchase-trade-modal/p2p-purchase-trade-modal.component';
import {
  BidCondition,
  IP2pPurchaseItem,
  IUserP2pPermissionInterface,
  OrderStatusEnum,
  SocialType,
  SteamErrorsEnum
} from '@dev-fast/types';
import {P2pBuyingService} from '@csgofast/modules/p2p';
import moment from 'moment';
import {P2pSetupModalComponent} from '../../modals';
import {P2pPurchaseService} from '../p2p-purchase.service';

@Component({
  selector: 'csgofast-p2p-purchase-item',
  templateUrl: './p2p-purchase-item.component.html',
  styleUrls: ['./p2p-purchase-item.component.scss', '../../styles/tooltip.scss', '../../styles/progress-line.scss'],
})
export class P2pPurchaseItemComponent {
  @Input() public id: number | undefined;
  public readonly permissions$: Observable<IUserP2pPermissionInterface>;
  public item$: Observable<IP2pPurchaseItem>;
  public initialProgress$: Observable<string> | undefined;
  public offerProgress$: Observable<number> | undefined;
  public offerTimer$: Observable<number> | undefined;
  public bidCondition$: Observable<BidCondition> | undefined;
  public readonly bibConditions: typeof BidCondition;
  public readonly steamErrors: typeof SteamErrorsEnum;
  public readonly statuses: typeof OrderStatusEnum;
  public readonly socialType: typeof SocialType;
  constructor(
    public readonly dialog: MatDialog,
    private readonly p2pBuyingService: P2pBuyingService,
    private readonly moduleService: P2pPurchaseService
  ) {
    this.permissions$ = moduleService.p2pPermissions$;
    this.statuses = OrderStatusEnum;
    this.steamErrors = SteamErrorsEnum;
    this.bibConditions = BidCondition;
    this.socialType = SocialType;
    this.item$ = this.p2pBuyingService.purchasing$.pipe(
      filter((items) => items.some((el) => el.id === this.id)),
      map((items) => items.find((el) => el.id === this.id)),
      filter((item): item is IP2pPurchaseItem => item !== undefined)
    );
    this.subscribeEmitters();
  }

  public bindingSocial(provider: SocialType): void {
    this.moduleService.bindProvider(provider);
  }

  public createBid(id: number): void {
    this.p2pBuyingService.createBid(id);
  }

  public confirm(id: number): void {
    this.p2pBuyingService.confirmBid(id);
  }

  public onClose(idx: number): void {
    this.p2pBuyingService.close(idx);
  }

  public onDeleted(idx: number): void {
    this.p2pBuyingService.deleted(idx);
  }

  public getTrade(): void {
    this.dialog.open(P2pPurchaseTradeModalComponent, {
      data: this.item$,
    });
  }

  public onSetupSteam(): void {
    this.dialog.open(P2pSetupModalComponent, {
      data: ['steam'],
    });
  }

  private subscribeEmitters(): void {
    this.offerTimer$ = this.item$.pipe(
      filter(({ nextStatusAt }) => Date.parse(nextStatusAt) > Date.now()),
      switchMap(({ nextStatusAt }) =>
        interval(1000).pipe(
          startWith(Date.parse(nextStatusAt) - Date.now()),
          map(() => Date.parse(nextStatusAt) - Date.now()),
          takeUntil(timer(Date.parse(nextStatusAt) - Date.now()))
        )
      )
    );

    this.initialProgress$ = this.item$.pipe(
      map(({ nextStatusAt, statusAt }) =>
        (100 - (moment(nextStatusAt).diff(moment()) / moment(nextStatusAt).diff(statusAt)) * 100).toFixed(2)
      )
    );

    this.offerProgress$ = this.item$.pipe(map(({ nextStatusAt }) => Date.parse(nextStatusAt) - Date.now()));

    this.bidCondition$ = this.item$.pipe(
      map(({ baseItem, nextBidPrice }) =>
        baseItem.steamPrice === nextBidPrice
          ? BidCondition.RECOMMEND_PRICE
          : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          baseItem.steamPrice > nextBidPrice!
          ? BidCondition.UN_PRICE
          : BidCondition.OVERPRICE
      )
    );
  }
}
