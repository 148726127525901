import {Injectable} from '@angular/core';
import {Environments, EnvironmentService} from '@csgofast/core/environment-service';
import {IPayments, IRefillOrder, ITransactionDto} from '@dev-fast/types';
import {catchError, Observable, of} from 'rxjs';
import {ApiService} from './api.service';

@Injectable({
  providedIn: 'root',
})
export class PayService {
  private environment: Environments;

  constructor(private readonly api: ApiService, private readonly environmentService: EnvironmentService) {
    this.environment = this.environmentService.environments;
  }
  public getLastRefill(): Observable<IRefillOrder> {
    return this.api.get<IRefillOrder>(`${this.environment.PAYMENT_URL}/pay/last`, { withCredentials: true });
  }

  public getPaymentMethodsByCountry(country: string, userId: number): Observable<IPayments> {
    return this.api.get<IPayments>(`${this.environment.PAYMENT_CONFIG_URL}/methods/${userId}`, { params: { country } });
  }
  public getPaymentHistory(queryParameters: Record<string, any>): Observable<ITransactionDto[]> {
    return this.api.get<ITransactionDto[]>(`${this.environment.GATEWAY_URL}/api/transactions-history`, {
      withCredentials: true,
      params: queryParameters,
    });
  }

  public checkPaymentTrust(userId: number): Observable<void | {
    status: boolean;
  }> {
    return this.api
      .get(`${this.environment.PAYMENT_CONFIG_URL}/payment-trust/${userId}`, {
        withCredentials: true,
      })
      .pipe(catchError(() => of({ status: true })));
  }
}
