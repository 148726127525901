<div *ngIf="currentReferral">
  <div class="promo-modal">
<!--    <div class="promo-shine">-->
<!--      <div class="promo-shine&#45;&#45;img"></div>-->
<!--    </div>-->
    <div class="modal-content">
      <span
        class="close-btn"
        (click)="close()"
      >
        <mat-icon svgIcon="close"></mat-icon>
      </span>

      <div class="modal-header">
        <h2>{{ 'GOFAST.MODAL.REFERRAL_BONUS.HEADER' | translate }}</h2>
        <div class="user-info">
          {{ 'GOFAST.MODAL.REFERRAL_BONUS.FROM' | translate }}
          <fast-ui-user-avatar [url]="currentReferral.referrer.user.avatar"></fast-ui-user-avatar>
          <div class="user-name">{{ currentReferral.referrer.user.name }}</div>
        </div>
      </div>
      <div class="modal-body">
        <div class="promo-main">
          <div class="promo-main-background"></div>
          <div class="promo-main-amount">
            <ng-container *ngIf="currentReferral.type === 'gofast'; else percent_reward">{{
              currentReferral.reward | appCurrency
            }}</ng-container>
            <ng-template #percent_reward>{{ currentReferral.reward }}%</ng-template>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="isAuth$ | async; else login_form">
      <div class="refil-container">
        <div>
          {{ 'GOFAST.MODAL.REFERRAL_BONUS.DESCRIPTION' | translate }} <span>{{ 1538 | appCurrency }}</span>
        </div>
        <button
          class="btn btn-primary"
          [routerLink]="['refill']"
          (click)="close()"
        >
          {{ 'GOFAST.MODAL.REFERRAL_BONUS.ACTION_BUTTON' | translate }}
        </button>
      </div>
    </ng-container>
  </div>

  <ng-template #login_form>
    <fast-auth-modal></fast-auth-modal>
  </ng-template>
</div>
<!-- {{ 'GOFAST.PROMOCODE_MODAL.BUTTON_REFILL' | translate }} -->
