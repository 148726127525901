import { ChangeDetectionStrategy, Component, Inject, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from "@angular/material/icon";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { RouterLink} from "@angular/router";
import { BrandComponentModule } from "@dev-fast/ui-components";
import { TranslateModule } from "@ngx-translate/core";
import { BanService } from "./ban.service";

@Component({
  selector: 'csgofast-ban',
  templateUrl: './ban.component.html',
  styleUrls: ['./ban.component.scss'],
  providers:[BanService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BanComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: string, private readonly moduleService:BanService,private readonly dialogRef: MatDialogRef<BanComponent>) {
  }
  public onLogout() {
    this.moduleService.logout()
  }
  public onClose(){
    this.dialogRef.close()
  }
}

@NgModule({
  imports: [CommonModule, BrandComponentModule, MatIconModule, TranslateModule, RouterLink],
  declarations: [BanComponent],
  exports: [BanComponent],
})
export class BanComponentModule {}
