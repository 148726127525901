import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthModalComponent} from './auth-modal/auth-modal.component';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatIconModule} from '@angular/material/icon';

// import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';

@NgModule({
  declarations: [AuthModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatDialogModule,
    // FIXME заменить на ngx-scrollbar (уже установлен на проекте)
    // PerfectScrollbarModule,
    MatIconModule,
    TranslateModule.forChild(),
  ],
  exports: [AuthModalComponent],
})
export class AuthModalsModule {}
