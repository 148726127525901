import {HttpErrorResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Logout} from '@csgofast/auth';
import {AnalyticsService} from '@csgofast/core/analytics-service';
import {FrameMessageTypes, IFrameMessageService} from '@csgofast/core/iframe';
import {LocalStorageService} from '@csgofast/core/local-storage-service';
import {NotificationsService} from '@csgofast/core/notification-service';
import {ModalsState, OpenModal} from '@csgofast/core/state/modals';
import {InitSuccess} from '@csgofast/core/state/user-store';
import {RouterStateParams} from "@csgofast/core/state/utils";
import {ReferralsApiService} from '@dev-fast/backend-services';
import {Affise, IError, ModalNames, PromoCode} from '@dev-fast/types';
import {RouterNavigation} from '@ngxs/router-plugin';
import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {StateReset} from 'ngxs-reset-plugin';
import {catchError, finalize, of, switchMap, tap} from 'rxjs';
import {REFERRALS_INITIAL_STATE, ReferralsStateModel} from './referrals-state.model';
import {
  ActivatePromoCode,
  AffiseEvent,
  ClickPromoCode,
  GetCurrentPromo,
  OpenPromoModal,
} from './referrals.actions';

@State<ReferralsStateModel>({
  name: 'referrals',
  defaults: REFERRALS_INITIAL_STATE,
})
@Injectable()
export class ReferralsState {
  constructor(
    private readonly store: Store,
    private readonly storage: LocalStorageService,
    private readonly apiService: ReferralsApiService,
    private readonly analyticsService: AnalyticsService,
    private readonly notificationsService: NotificationsService,
    private readonly frameMessageService: IFrameMessageService,
    @Inject('isServer') private isServer: boolean,
  ) {}

  @Selector()
  public static activePromoCode({ activePromoCode }: ReferralsStateModel): PromoCode | null {
    return activePromoCode;
  }

  @Selector()
  public static activeReferralCampaign({ activeReferralCampaign }: ReferralsStateModel): PromoCode | null {
    return activeReferralCampaign;
  }

  @Selector()
  public static activationErr({ activationErr }: ReferralsStateModel): IError | null {
    return activationErr;
  }

  @Selector()
  public static promoData({ activationErr, activePromoCode, promoActivated }: ReferralsStateModel): {
    activationErr: IError | null;
    activePromoCode: PromoCode | null;
    promoActivated: boolean;
  } {
    return { activationErr, activePromoCode, promoActivated };
  }

  @Action(RouterNavigation)
  public routerNavigation({ dispatch, patchState }: StateContext<ReferralsStateModel>, { routerState }: RouterNavigation<RouterStateParams>) {
    const { queryParams } = routerState;
    if (queryParams && !this.isServer) {
      if(queryParams['clickid'] || queryParams['utm_campaign']) {
        const affise: Affise = {};
        if (queryParams['clickid']) {
          affise['clickId'] = queryParams['clickid'];
        }
        if (queryParams['utm_campaign']) {
          affise['utmCampaign'] = queryParams['utm_campaign'];
        }

        patchState({ affise });
        this.storage.set('affise',affise);
      }
      if(queryParams['r']) {
        dispatch(new ClickPromoCode(queryParams['r']));
      }
    }
  }

  @Action(GetCurrentPromo)
  public getCurrentPromo({ patchState }: StateContext<ReferralsStateModel>) {
    return this.apiService
      .getCurrentReferralCampaign()
      .pipe(tap((response) => patchState({ activePromoCode: response, activationErr: null, promoActivated: false })));
  }

  @Action(AffiseEvent)
  public affiseEvent({ patchState }: StateContext<ReferralsStateModel>, {affise}:AffiseEvent) {
    return this.analyticsService.affiseEvent(affise).pipe(tap(() => {
      this.storage.remove('affise');
      patchState({affise: null})
    }));
  }

  @Action(ActivatePromoCode)
  public activatePromoCode({ patchState }: StateContext<ReferralsStateModel>, { code }: ActivatePromoCode) {
    return this.apiService.requestPromoActivation(code).pipe(
      tap(({ appId, ...response }) => {
        patchState({ activePromoCode: response, activationErr: null, promoActivated: true });
        this.frameMessageService.sendMessage({ type: FrameMessageTypes.MESSAGE_TO_BB, eventName: 'promoActivated', payload: response });
      }),
      // switchMap((val) => dispatch(new ActivatePromoCodeSuccess(val))),
      catchError(({ error }: HttpErrorResponse) => {
        patchState({ activationErr: error, promoActivated: false });
        this.notificationsService.addErrorNotification(error.message);
        return of(error);
      }),
      finalize(() => this.storage.remove('referralCode'))
    );
  }

  @Action(ClickPromoCode)
  public clickPromoCode({ patchState, dispatch }: StateContext<ReferralsStateModel>, { code }: ClickPromoCode) {
    return this.apiService.requestPromoClicks(code).pipe(
      tap((response) => {
        patchState({ activeReferralCampaign: response });
        this.storage.set('referralCode', response);
      }),
      switchMap((code) => dispatch(new OpenPromoModal(code))),
      catchError(({ error }: HttpErrorResponse) => {
        this.notificationsService.addErrorNotification(error.message);
        return of(error);
      })
    );
  }

  // @Action(ProceedPromoCode)
  // public proceedPromoCode({ dispatch }: StateContext<ReferralsStateModel>, { code }: ProceedPromoCode) {
  //   this.store.selectSnapshot(UserState.user) && dispatch(new ActivatePromoCode(code.code));
  // }

  @Action(OpenPromoModal)
  public openPromoModal({ dispatch }: StateContext<ReferralsStateModel>, {code}: OpenPromoModal) {
    this.store.selectSnapshot(ModalsState.activeModal) !== ModalNames.REFERRAL && dispatch(new OpenModal(ModalNames.REFERRAL, code));
  }

  @Action(InitSuccess)
  public initSuccess({ dispatch }: StateContext<ReferralsStateModel>) {
    const promo = this.storage.get('referralCode');
    const affise = this.storage.get('affise');

    // TODO мож проверить не протух ли он
    if (promo) {
      dispatch(new ActivatePromoCode(promo.code));
    }
    if(affise){
      dispatch(new AffiseEvent(affise))
    }
  }

  // @Action(Login)
  // public login({ dispatch,getState }: StateContext<ReferralsStateModel>) {
  //   const { affise } = getState();
  //   if (!affise) return;
  //   return dispatch([new AffiseEvent(affise)]);
  // }

  @Action(Logout)
  public logout({ dispatch }: StateContext<ReferralsStateModel>) {
    return dispatch([new StateReset(ReferralsState)]);
  }
}
