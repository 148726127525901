import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserAvatarComponent} from './user-avatar.component';
import {MatIconModule} from '@angular/material/icon';
import {LazyLoadImageModule} from "@csgofast/shared/directives";
import {UserAvatarMockComponent} from "./mock/user-avatar.component.mock";

@NgModule({
  imports: [CommonModule, MatIconModule, LazyLoadImageModule],
  declarations: [UserAvatarComponent, UserAvatarMockComponent],
  exports: [UserAvatarComponent, UserAvatarMockComponent],
})
export class UserAvatarModule {}
