import { Injectable } from '@angular/core';
import { Logout } from '@csgofast/auth';
import { FrameMessageTypes, IFrameMessageService } from '@csgofast/core/iframe';
import { InitSuccess, UserState } from '@csgofast/core/state/user-store';
// import {NotificationsService} from '@csgofast/core/notification-service';
import { CasesBackendService, LeaderRaceBackendService } from '@dev-fast/backend-services';
import {
  GameTypes,
  ICaseCollection,
  ICaseItemDtoV2,
  ILRBalance, ILRLeaderboards,
  ILRLeaders,
  ILRQuest, ILRTop,
  LotteryType
} from '@dev-fast/types';
import { RouterNavigation } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import moment from 'moment';
import { Observable, switchMap, timer } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  GetCases,
  GetLeaders,
  GetQuests,
  GetTickets,
  ResetQuestsCases,
  TakeReward,
  UpdateItem
} from './ny-all-event.actions';

import { NY_ALL_EVENT_INITIAL_STATE, NYAllEventStateModel } from './ny-all-event.model';

@State<NYAllEventStateModel>({
  name: 'ny_all_event',
  defaults: NY_ALL_EVENT_INITIAL_STATE,
})
@Injectable()
export class NyAllEventState {
  constructor(
    private readonly apiService: LeaderRaceBackendService, // private readonly notificationsService: NotificationsService,
    private readonly apiCasesService: CasesBackendService,
    private readonly frameMessageService: IFrameMessageService,
    private readonly store: Store
  ) {}

  @Selector()
  public static topPlayers({ leaders }: NYAllEventStateModel): ILRTop[] {
    return leaders ? leaders.top : [];
  }
  @Selector()
  public static leaderboards({ leaders }: NYAllEventStateModel): ILRLeaderboards[] {
    return leaders ? leaders.leaderboards : [];
  }
  @Selector()
  public static keys({ keys }: NYAllEventStateModel): ILRBalance | null {
    return keys;
  }
  @Selector()
  public static weeklyTasks({ tasks }: NYAllEventStateModel): ILRQuest[] {
    return tasks.filter((el) => el.type === LotteryType.WEEKLY);
  }
  @Selector()
  public static dailyTasks({ tasks }: NYAllEventStateModel): ILRQuest[] {
    return tasks.filter((el) => el.type === LotteryType.DAILY).slice(0, 6);
  }
  @Selector()
  public static cases({ cases }: NYAllEventStateModel): ICaseCollection | null {
    return cases;
  }

  @Selector()
  public static untilDayTaskTime({ tasks }: NYAllEventStateModel): number {
    const now = moment();
    const date = tasks.filter((el) => el.type === LotteryType.DAILY)[0].finishAt;
    return moment(date).diff(now);
  }
  @Selector()
  public static untilWeeklyTaskTime({ tasks }: NYAllEventStateModel): number {
    const now = moment();
    const date = tasks.filter((el) => el.type === LotteryType.WEEKLY)[0].finishAt;
    return moment(date).diff(now);
  }

  public ngxsOnInit({ dispatch }: StateContext<NYAllEventStateModel>): void {
    this.frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'leadersRace.takeReward', (payload: { id: number }) =>
      dispatch(new TakeReward(payload.id))
    );
    this.frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'leadersRace.quests', () => dispatch(new GetQuests()));
    this.frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'leadersRace.ticketBalance', () => dispatch(new GetTickets()));
    this.apiService.lRUpdateEvent((msg) => dispatch(new UpdateItem(msg)));
  }
  @Action(RouterNavigation)
  public navigation({ dispatch }: StateContext<NYAllEventStateModel>, { routerState }: RouterNavigation) {
    if (routerState.url.includes('/events/ny-all-event')) {
      const user = this.store.selectSnapshot(UserState.user);
      if (user) {
        dispatch([new GetQuests()]);
      }
      dispatch([new GetCases()]);
    }
    // //fixme
    // if (routerState.url.includes('/events/shop')) {
    //   dispatch([new GetCases()]);
    // }
    // if (routerState.url.includes('/events/leaders')) {
    //   dispatch([new GetLeaders()]);
    // }
  }
  @Action(InitSuccess)
  public initSuccess({ dispatch }: StateContext<NYAllEventStateModel>) {
    dispatch([new GetQuests(), new GetTickets()]);
  }

  @Action([Logout])
  public logout({ patchState }: StateContext<NYAllEventStateModel>) {
    patchState({
      tasks: [],
      keys: null,
    });
  }
  @Action(ResetQuestsCases)
  public resetQuestsCases({ patchState }: StateContext<NYAllEventStateModel>) {
    patchState({cases: null,tasks:[]})
  }
  @Action(GetQuests, { cancelUncompleted: true })
  public getQuests({ patchState }: StateContext<NYAllEventStateModel>): Observable<ILRQuest[]> {
    return this.apiService.getLRQuests().pipe(
      tap((response) => {
        this.frameMessageService.sendMessage({
          type: FrameMessageTypes.MESSAGE_TO_BB,
          eventName: 'leadersRace.quests',
          payload: { response },
        });
        patchState({
          tasks: response,
        });
      })
    );
  }
  @Action(UpdateItem)
  public updateItem({ setState, dispatch, getState }: StateContext<NYAllEventStateModel>, { payload }: UpdateItem) {
    const { tasks } = getState();
    //так сложно потому-что в некоторых квестах бэк отдает результат раньше чем хотелось бы
    const task = tasks.find((el) => el.id === payload.questId);
    const isSpecialGame = (): boolean => {
      return task !== undefined && task.name.includes('Win') && task.gameTypeId === GameTypes.SLOT;
    };

    const newValues: Record<string, any> = {};
    newValues['progress'] = payload.userCount ? payload.userCount : task ? task.progress : undefined;
    newValues['rewardTaken'] = payload.rewardTaken ? payload.rewardTaken : task ? task.rewardTaken : undefined;

    const due = isSpecialGame() ? 2000 : 0;
    return timer(due).pipe(
      tap(() => {
        setState(
          patch({
            tasks: updateItem<ILRQuest>(
              (o) => o?.id === payload.questId,
              patch({
                ...newValues,
              })
            ),
          })
        );
      })
    );
  }
  @Action(GetTickets, { cancelUncompleted: true })
  public getTickets({ patchState }: StateContext<NYAllEventStateModel>): Observable<ILRBalance> {
    return this.apiService.getBalanceTickets().pipe(
      tap((response) => {
        this.frameMessageService.sendMessage({
          type: FrameMessageTypes.MESSAGE_TO_BB,
          eventName: 'leadersRace.ticketBalance',
          payload: { response },
        });
        patchState({
          keys: response,
        });
      })
    );
  }
  @Action(GetCases, { cancelUncompleted: true })
  public getCases({ patchState }: StateContext<NYAllEventStateModel>): Observable<ICaseItemDtoV2[]> {
    return this.apiCasesService.getCasesFromCategoryAll({}).pipe(
      tap((response) => {
        const collection: ICaseCollection = {
          id: 0,
          name: 'categoryType',
          cases: response.filter((item) => item.ticketQuest !== undefined),
          image: null,
          grid: '',
          disableAt: null,
          sortable: true,
          tags: []
        };
        patchState({
          cases: collection,
        });
      })
    );
  }
  @Action(GetLeaders, { cancelUncompleted: true })
  public getLeaders({ patchState }: StateContext<NYAllEventStateModel>): Observable<ILRLeaders> {
    return this.apiService.getLRLeaders().pipe(
      tap((response) => {
        patchState({
          leaders: response,
        });
      })
    );
  }
  @Action(TakeReward)
  public takeReward({ setState, dispatch }: StateContext<NYAllEventStateModel>, { questId }: TakeReward): Observable<void> {
    return this.apiService.takeReward(questId).pipe(
      // tap(() =>
      //   setState(
      //     patch({
      //       tasks: updateItem<ILRQuest>(
      //         (o) => o?.id === questId,
      //         patch({
      //           rewardTaken: true,
      //         })
      //       ),
      //     })
      //   )
      // ),

      switchMap(() => {
        this.frameMessageService.sendMessage({ type: FrameMessageTypes.MESSAGE_TO_BB, eventName: 'leadersRace.takeReward', payload: {} });
        return dispatch([new GetTickets(), new GetQuests()]);
      })
    );
  }
  // @Action(PurchaseCase)
  // public purchaseCase({  dispatch }: StateContext<NYAllEventStateModel>, { freeCaseId,ticketColor }: PurchaseCase): Observable<void> {
  //   return this.apiService.purchaseCase(freeCaseId,ticketColor).pipe(
  //     switchMap(() => dispatch(new PurchaseCaseSuccess(freeCaseId,ticketColor)))
  //   );
  // }
}
