export const lrEventsTranslateDictionary = {
  'Win ${count} games with a bet ${currency(amount)} or more': 'GOFAST.REGULAR_EVENT.WIN_X_WITH_Y_BET',
  'Play ${count} games with a bet ${currency(amount)} or more': 'GOFAST.REGULAR_EVENT.PLAY_X_WITH_Y_BET',
  'Win ${count} games in a row with a bet ${currency(amount)} or more': 'GOFAST.REGULAR_EVENT.WIN_X_IN_ROW_WITH_Y_BET',
  'Win ${count} games with a bet more than ${currency(amount)}': 'GOFAST.REGULAR_EVENT.WIN_X_WITH_BET_MORE_Y',
  'Win ${count} games in a row with a bet not more than ${currency(amount)}': 'GOFAST.REGULAR_EVENT.WIN_X_IN_ROW_WITH_BET_NOT_MORE_Y',
  'Win ${count} games with a bet ${currency(amount)} or more on ZERO': 'GOFAST.REGULAR_EVENT.WIN_X_WITH_BET_Y_OR_MORE_ON_ZERO',
  'Win ${count} games with coef more than ${coef} with a bet ${currency(amount)} or more': 'GOFAST.REGULAR_EVENT.WIN_X_WITH_Z_WITH_Y_BET_2',
  'Win ${count} games with a bet ${currency(amount)} or more on x5': 'GOFAST.REGULAR_EVENT.WIN_X_WITH_Y_BET_ON_X5',
  'Climb to the ${floorNumber} floor on easy mode with a bet ${currency(amount)} or more':
    'GOFAST.REGULAR_EVENT.CLIMB_X_FLOOR_EASY_WITH_Y_BET',
  'Climb to the ${floorNumber} floor on medium mode with a bet ${currency(amount)} or more':
    'GOFAST.REGULAR_EVENT.CLIMB_X_FLOOR_MEDIUM_WITH_Y_BET',
  'Play ${count} games with coef ${coef}x or more with a bet ${currency(amount)} or more': 'GOFAST.REGULAR_EVENT.PLAY_X_WITH_Z_WITH_Y_BET',
  'Win ${count} games with coef ${coef}x or more with a bet ${currency(amount)} or more': 'GOFAST.REGULAR_EVENT.WIN_X_WITH_Z_WITH_Y_BET',
  'Win ${count} games in a row with coef ${coef}x or more with a bet ${currency(amount)} or more':
    'GOFAST.REGULAR_EVENT.WIN_X_IN_ROW_WITH_Z_WITH_Y_BET',
  'Craft ${count} items worth ${currency(amount)} or more': 'GOFAST.REGULAR_EVENT.CRAFT_X_WORTH_Y',
  'Win ${count} games with a bet ${currency(amount)} or more on ${betName}': 'GOFAST.REGULAR_EVENT.WIN_X_WITH_Y_BET_ON_Z',
  'Play ${count} games with a bet ${currency(amount)} or more on ${betName}': 'GOFAST.REGULAR_EVENT.PLAY_X_WITH_Y_BET_ON_Z',
  'Win ${count} games in a row with a bet ${currency(amount)} or more on ${betName}': 'GOFAST.REGULAR_EVENT.WIN_X_IN_ROW_WITH_Y_BET_ON_Z',
  'Craft ${count} items in a row worth ${currency(amount)} or more': 'GOFAST.REGULAR_EVENT.CRAFT_X_IN_ROW_WITH_WORTH_Y',
  'Climb to the ${floorNumber} floor on hard mode with a bet ${currency(amount)} or more':
    'GOFAST.REGULAR_EVENT.CLIMB_X_FLOOR_HARD_WITH_Y_BET',
  'Refill your balance for ${currency(amount)}': 'GOFAST.REGULAR_EVENT.REFILL_FOR_X',
};
