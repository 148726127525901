import {AfterViewInit, ContentChild, Directive, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {NgScrollbar} from "ngx-scrollbar";
import {filter, tap, withLatestFrom} from "rxjs/operators";

@Directive({
  selector: '[csgofastScrollable]'
})
export class ScrollableDirective implements  AfterViewInit{

  //   @Input() uiTest: NgScrollbar | undefined;
  @Input() public set uiTest(uiTest: NgScrollbar | undefined) {
    if (uiTest&&uiTest.scrolled) {
      console.log(uiTest);
      uiTest.scrolled.subscribe((x) => console.log(x));
      console.log('yo')
    }
  }
  constructor(private readonly elementRef: ElementRef) {
    //
  }
  ngAfterViewInit(): void {
    console.log(this.elementRef.nativeElement)
    console.log('TestDirective');
    console.log(this.uiTest);
    if(this.uiTest) this.uiTest.scrolled.subscribe((x) => console.log(x));
  }
}
