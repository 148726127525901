<div>
  <span>
  {{'Sorry!' | uppercase}}

  </span>
  <span>
  {{'Under DDOS' | uppercase}}

  </span>
</div>
