import {HasPermissionModule, StopPropagationModule} from "@csgofast/shared/directives";
import {
  BetPanelLegacyComponentModule,
  ExhangePanelModule,
  InventoryWidgetComponentModule,
  UserAvatarModule,
} from '@dev-fast/ui-components';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgScrollbarModule} from "ngx-scrollbar";
import {DesktopRightSidebarComponent} from './desktop-right-sidebar.component';
import {MatIconModule} from '@angular/material/icon';
import {WidgetsComponent} from './widgets/widgets.component';
import {RouterModule} from '@angular/router';
import {CustomAsyncPipeModule} from '@csgofast/shared/pipes';
import {ChatModule} from '@csgofast/widgets/chat';
// import { ChatService } from './services/chat.service';
import {P2pWidgetModule} from '@csgofast/widgets/p2p';
import {TradesService} from './services/trades.service';
import {NotificationsWidgetsModule} from '@csgofast/widgets/notifications';
import { SharedComponentsModule } from '@csgofast/shared/components';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    NgScrollbarModule,
    // TradesWidgetComponentModule,
    InventoryWidgetComponentModule,
    // ChatComponentModule,
    // NotificationsComponentModule,
    NotificationsWidgetsModule,
    UserAvatarModule,
    ExhangePanelModule,
    BetPanelLegacyComponentModule,
    RouterModule,
    CustomAsyncPipeModule,
    ChatModule,
    SharedComponentsModule,
    P2pWidgetModule.forRoot(TradesService),
    HasPermissionModule,
    StopPropagationModule,
  ],
  declarations: [DesktopRightSidebarComponent, WidgetsComponent],
  exports: [DesktopRightSidebarComponent],
})
export class DesktopAppRightSidebarModule {}
