
export class GetQuests {
  public static readonly type = '[Cases ny-all-event] Get Quests';
}
export class GetTickets {
  public static readonly type = '[Cases ny-all-event] Get Tickets';
}
export class GetLeaders {
  public static readonly type = '[Cases ny-all-event] Get Leaders';
}
export class ResetQuestsCases {
  public static readonly type = '[Cases ny-event] Reset Quests Cases';
}
export class GetCases {
  public static readonly type = '[Cases ny-all-event] Get Cases';
}
export class TakeReward {
  public static readonly type = '[Cases ny-all-event] Take Reward';
  constructor(public questId: number) {}
}
export class UpdateItem {
  public static readonly type = '[Cases ny-all-event] Update Quest Item';
  constructor(public payload: {questId: number, userCount?: number,rewardTaken?:boolean}) {}
}
// export class PurchaseCase {
//   public static readonly type = '[Cases ny-all-event] Purchase Case';
//   constructor(public freeCaseId: number, public ticketColor: TicketTypeEnum) {}
// }

// export class PurchaseCaseSuccess {
//   public static readonly type = '[Cases ny-all-event] Purchase Case Success';
//   constructor(public freeCaseId: number, public ticketColor: TicketTypeEnum) {}
// }
