import {ChangeDetectionStrategy, Component, Input, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SOCIAL_GROUP, SocialGroup} from '@dev-fast/types';
import {MatIconModule} from '@angular/material/icon';

@Component({
  selector: 'fast-ui-social-groups',
  templateUrl: './social-groups.component.html',
  styleUrls: ['./social-groups.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialGroupsComponent {
  @Input() public groups: SocialGroup[] | undefined = SOCIAL_GROUP;
}

@NgModule({
  imports: [CommonModule, MatIconModule],
  declarations: [SocialGroupsComponent],
  exports: [SocialGroupsComponent],
})
export class SocialGroupsComponentModule {}
