import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {INotification, NotificationCategory, NotificationStatus, NotificationType} from '@dev-fast/types';
import {Dispatch} from '@ngxs-labs/dispatch-decorator';
import {Select} from '@ngxs/store';
import {
  AddNotification,
  GetNotifications,
  NotificationsState,
  RemoveNotification,
  WatchAllNotification,
} from '@csgofast/core/state/notifications';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  @Select(NotificationsState.items)
  public readonly notifications$!: Observable<INotification[]>;
  @Select(NotificationsState.hasNewItems)
  public readonly hasNewNotifications$!: Observable<boolean>;
  @Select(NotificationsState.countNewItems)
  public readonly countNewNotifications$!: Observable<number>;

  @Dispatch() public addNotification = (noty: INotification) => new AddNotification(noty);
  @Dispatch() public addErrorNotification = (message: string, noty?: Partial<INotification>) =>
    new AddNotification({
      id: Date.now(),
      type: NotificationType.error,
      category: NotificationCategory.error,
      status: NotificationStatus.new,
      message,
      system: true,
      icon: '1',
      createDate: Date.now(),
      ...noty,
    });
  @Dispatch() public addSuccessNotification = (message: string, noty?: Partial<INotification>) =>
    new AddNotification({
      id: Date.now(),
      type: NotificationType.success,
      status: NotificationStatus.new,
      message,
      icon: '1',
      system: true,
      createDate: Date.now(),
      ...noty,
    });
  @Dispatch() public addInfoNotification = (message: string, noty?: Partial<INotification>) =>
    new AddNotification({
      id: Date.now(),
      type: NotificationType.info,
      status: NotificationStatus.new,
      message,
      icon: '1',
      system: true,
      createDate: Date.now(),
      ...noty,
    });
  @Dispatch() public getNotifications = () => new GetNotifications();
  @Dispatch() public removeNotification = (notyId: number) => new RemoveNotification(notyId);
  @Dispatch() public watchAllNotifications = () => new WatchAllNotification();
}
