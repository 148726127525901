import {AppPercentPipe} from './percent.pipe';
import {NgModule} from '@angular/core';
import {CommonModule, PercentPipe} from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    AppPercentPipe,
  ],
  exports: [
    AppPercentPipe,
  ],
  providers: [
    PercentPipe,
    AppPercentPipe,
  ],
})

export class AppPercentModule {
}
