import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, NgModule, OnDestroy, Output} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {MatIconModule} from '@angular/material/icon';
import {RouterModule} from "@angular/router";
import {AppCurrencyModule} from '@csgofast/shared/pipes';
import {validateAccountLevel} from "@csgofast/shared/utils";
import {
  IInventoryRequestParams,
  InventoryTradeBalance,
  IShopMeta,
  ISkinItem,
  IUserDetailed,
  ProfileTypes,
  SkinClickEvent
} from '@dev-fast/types';
import {TranslateModule} from '@ngx-translate/core';
import {debounceTime, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {CubeGridLoaderComponentModule} from '../loaders/cube-grid/cube-grid.component';
import {PaginationModule} from '../pagination/pagination.component';
import {SkinItemModule} from '../skin-item/skin-item.component';

@Component({
  selector: 'fast-ui-exchange-panel',
  templateUrl: './exhange-panel.component.html',
  styleUrls: ['./exhange-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExhangePanelComponent implements OnDestroy {
  @Input() public inventoryList: ISkinItem[];
  @Input() public selectedItems: ISkinItem[];
  @Input() public tradeBalance: InventoryTradeBalance | undefined;
  @Input() public meta: IShopMeta | undefined;
  @Input() public loading: boolean;
  @Input() public user: IUserDetailed | undefined;
  @Input() public set params(params: IInventoryRequestParams) {
    if (params) {
      this.storeForm.patchValue({ ...params }, { emitEvent: false });
    }
  }

  @Output() public closePanelEmit: EventEmitter<void> = new EventEmitter<void>();
  @Output() public createTrade: EventEmitter<void> = new EventEmitter<void>();
  @Output() public clickOnSkinEmit: EventEmitter<SkinClickEvent> = new EventEmitter<SkinClickEvent>();
  @Output() public changeParams: EventEmitter<IInventoryRequestParams> = new EventEmitter<IInventoryRequestParams>();

  public storeForm: FormGroup;

  private destroy$: Subject<void>;

  public get totalPage(): number {
    return this.meta ? Math.ceil(this.meta.amount / this.meta.limit) : 1;
  }
  public get validatePremium(): boolean {
    return this.user ? validateAccountLevel(this.user.profileType,ProfileTypes.SILVER):true;
  }
  public get disabledNextPage(): boolean {
    if (this.storeForm.value.page) {
      return this.totalPage <= this.storeForm.value.page;
    } else {
      return true;
    }
  }

  constructor(private readonly fb: FormBuilder) {
    this.inventoryList = [];
    this.selectedItems = [];
    this.loading = true;

    this.storeForm = this.fb.group({
      query: '',
      sortBy: [true],
      page: [1],
      minPrice: null,
      maxPrice: null,
      pageSize: 48,
    });
    this.destroy$ = new Subject();
    this.storeForm.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(300)).subscribe((val) => {
      this.changeParams.emit(val);
    });
  }

  public onNextPage(): void {
    this.storeForm.patchValue({
      page: this.storeForm.value.page ? this.storeForm.value.page + 1 : 1,
    });
  }

  public onRefresh(): void {
    this.storeForm.patchValue(this.storeForm.value);
  }

  public isSelected(id: number): boolean {
    return this.selectedItems.some((item) => item.id === id);
  }

  public onCreateTrade(): void {
    this.createTrade.emit();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CubeGridLoaderComponentModule,
    MatIconModule,
    SkinItemModule,
    TranslateModule,

    PaginationModule,
    AppCurrencyModule,
    ReactiveFormsModule,
  ],
  declarations: [ExhangePanelComponent],
  exports: [ExhangePanelComponent],
})
export class ExhangePanelModule {}
