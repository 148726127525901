<ng-container
  *ngIf="{
    name: eventName$ | async,
    activePanel: activePanel$ | async,
    untilTime: untilEventTime$ | async

  } as event"
>
  <div class="ny-event-menu-header-btn" *ngIf="event.name">
    <button
      class="ny-event-menu-header-btn-btn event-toggle"
      [class.hover]="event.activePanel && event.activePanel.panel === panels.EVENT_MENU"
      (click)="activateEvetMenu()"
    >
      <ng-container *ngIf="isEventEnded(event.name)">
        <ng-container *ngIf="event.name === 'ny'">
          <img
            src="/assets/img/ny-event/event-tree.svg"
            alt="ny-quest"
          />
        </ng-container>
        <ng-container *ngIf="event.name === 'ny_china'">
          <img
            src="/assets/img/ny-event/china/rabbit-icon.svg"
            alt="ny-quest"
          />
        </ng-container>

        <ng-container *ngIf="event.untilTime">
          <ng-container *ngIf="isDays(event.untilTime); else daysLeft">
            <fast-ui-countdown
              *ngIf="event.untilTime"
              [until]="event.untilTime"
              [showHours]="true"
              [showSeconds]="true"
              [reset]="false"
              [minutes]="0.92"
            >
            </fast-ui-countdown>
          </ng-container>
          <ng-template #daysLeft> {{ daysToGo(event.untilTime) }} {{ 'GOFAST.REGULAR_EVENT.DAYS' | translate }} </ng-template>
        </ng-container>
      </ng-container>
    </button>
    <button
      class="ny-event-menu-header-btn-btn event-link"
      [class.hover]="event.activePanel && event.activePanel.panel === panels.EVENT_MENU"
      (click)="naviagateEventMenu()"
    >
      <ng-container *ngIf="event.name === 'ny'">
        <img
          src="/assets/img/ny-event/event-tree.svg"
          alt="ny-quest"
        />
      </ng-container>
      <ng-container *ngIf="event.name === 'ny_china'">
        <img
          src="/assets/img/ny-event/china/rabbit-icon.svg"
          alt="ny-quest"
        />
      </ng-container>
      <ng-container *ngIf="event.untilTime">
        <ng-container *ngIf="isDays(event.untilTime); else daysLeft">
          <fast-ui-countdown
            *ngIf="event.untilTime"
            [until]="event.untilTime"
            [showHours]="true"
            [showSeconds]="true"
            [reset]="false"
            [minutes]="0.92"
          >
          </fast-ui-countdown>
        </ng-container>
        <ng-template #daysLeft> {{ daysToGo(event.untilTime) }} {{ 'GOFAST.REGULAR_EVENT.DAYS' | translate }} </ng-template>
      </ng-container>
    </button>

    <ng-container *ngIf="event.activePanel && event.activePanel.panel === panels.EVENT_MENU">
      <div class="ny-event-menu-header-btn-container">
        <div
          [class.opacity-anim]="event.activePanel && event.activePanel.panel === panels.EVENT_MENU"
          class="ny-event-menu"
          *ngIf="{
            untilWeeklyTaskTime: untilWeeklyTaskTime$ | async,
            untilDayTaskTime: untilDayTaskTime$ | async,
            untilEventTime: untilEventTime$ | async,
            totalProgress: totalProgress$ | async,
            weeklyTasks: weeklyTasks$ | async,
            dailyTasks: dailyTasks$ | async,
            eventName: eventName$ | async,
            items: items$ | async,
            user: user$ | async,
            keys: keys$ | async
          } as data"
        >
          <div
            style="background: #182328; position: absolute; z-index: 1000; top: 0; width: 100%; height: 100%"
            *ngIf="!data.user || userStatus(data.user.profileType)"
          >
            <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; height: 100%; gap: 2rem">
              <div style="color: #fff; font-size: 20px; font-weight: 700">{{ 'GOFAST.REGULAR_EVENT.EVENT_BLOCK_HEADER' | translate }}</div>
              <ng-container *ngIf="!data.user; else silverStatus">
                <div style="color: #fff; font-size: 14px; font-weight: 400">
                  {{ 'GOFAST.REGULAR_EVENT.EVENT_BLOCK_TEXT_AUTORIZATION' | translate }}
                </div>
                <div>
                  <button
                    class="btn btn-primary"
                    (click)="onLogin()"
                    style="gap: 1rem"
                  >
                    <mat-icon
                      class="login__icon"
                      svgIcon="login"
                    ></mat-icon>
                    {{ 'LOGIN' | translate | titlecase }}
                  </button>
                </div>
              </ng-container>
              <ng-template #silverStatus>
                <div style="color: #fff; font-size: 14px; font-weight: 400">
                  {{ 'GOFAST.REGULAR_EVENT.EVENT_BLOCK_TEXT_STATUS' | translate }}
                </div>
                <div>
                  <a
                    class="btn btn-primary"
                    style="gap: 1rem"
                    [routerLink]="['refill']"
                    (click)="onRefill()"
                  >
                    {{ 'ACCOUNT_PREMIUM.REFILL' | translate | titlecase }}
                  </a>
                </div>
              </ng-template>
            </div>
          </div>

          <div
            style="background: #182328; position: absolute; z-index: 1001; top: 0; width: 100%; height: 100%"
            *ngIf="event.untilTime && isEventResults"
          >
            <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; height: 100%; gap: 2rem">
              <div style="color: #fff; font-size: 20px; font-weight: 700">{{ 'GOFAST.NY_EVENT.FINAL.HEADER' | translate }}</div>
              <div style="color: var(--color-primary); font-size: 14px; font-weight: 400">
                {{ 'GOFAST.NY_EVENT.FINAL.SUBHEADER' | translate }}:
                <ng-container *ngIf="isDays(event.untilTime); else daysLeft">
                  <fast-ui-countdown
                    *ngIf="event.untilTime"
                    [until]="event.untilTime"
                    [showHours]="true"
                    [showSeconds]="true"
                    [reset]="false"
                    [minutes]="0.92"
                  >
                  </fast-ui-countdown>
                </ng-container>
                <ng-template #daysLeft> {{ daysToGo(event.untilTime) }} {{ 'GOFAST.REGULAR_EVENT.DAYS' | translate }} </ng-template>
              </div>
              <div>
                <button
                  class="btn btn-primary"
                  (click)="naviagateEventMenu('/events/shop')"
                  style="text-transform: uppercase"
                >
                  {{ 'GAMES.DETAILS' | translate }}
                </button>
              </div>
            </div>
          </div>

          <div
            class="ny-event-menu--quests"
            style="grid-area: d"
          >
            <div class="ny-event-menu--quests-daily">
              <div class="ny-event-menu--quests-daily--title">
                <div class="ny-event-menu--quests-daily--title-text title-strong">
                  <span>
                    {{ 'GOFAST.NY_EVENT.DAILY_QUESTS' | translate }}
                  </span>
                  <span
                    class="text-muted"
                    style="display: flex"
                    *ngIf="data.untilDayTaskTime"
                  >
                    <span> (</span>
                    <fast-ui-countdown
                      *ngIf="data.untilDayTaskTime"
                      [until]="data.untilDayTaskTime"
                      [showHours]="true"
                      [reset]="false"
                      [minutes]="0.92"
                    >
                    </fast-ui-countdown>
                    <span>)</span>
                  </span>
                </div>
                <div class="ny-event-menu--quests-daily--title-info text-muted">
                  {{ 'GOFAST.NY_EVENT.LIMITATION' | translate }}
                </div>
              </div>
              <div class="ny-event-menu--quests-daily--list">
                <ng-container *ngIf="data.dailyTasks && data.dailyTasks.length; else loading_daily">
                  <csgofast-ny-quest-card
                    *ngFor="let dailyTask of data.dailyTasks"
                    [task]="dailyTask"
                  ></csgofast-ny-quest-card>
                </ng-container>

                <ng-template #loading_daily>
                  <ngx-skeleton-loader
                    animation="progress-dark"
                    [theme]="{
                      width: '190px',
                      'border-radius': '15px',
                      height: '150px',
                      margin: '0'
                    }"
                  ></ngx-skeleton-loader>
                  <ngx-skeleton-loader
                    animation="progress-dark"
                    [theme]="{
                      width: '190px',
                      'border-radius': '15px',
                      height: '150px',
                      margin: '0'
                    }"
                  ></ngx-skeleton-loader>
                </ng-template>
              </div>
            </div>
          </div>
          <div
            class="ny-event-menu--quests-weekly"
            style="grid-area: w"
          >
            <div class="ny-event-menu--quests-weekly--list">
              <div class="ny-event-menu--quests-weekly--list--title">
                <div class="ny-event-menu--quests-weekly--list--title-text title-strong">
                  <span>
                    {{ 'GOFAST.NY_EVENT.WEEKLY_QUESTS' | translate }}
                  </span>
                  <span
                    class="text-muted"
                    style="display: flex"
                    *ngIf="data.untilWeeklyTaskTime"
                  >
                    <span> (</span>
                    <ng-container *ngIf="isDays(data.untilWeeklyTaskTime); else daysLeft">
                      <fast-ui-countdown
                        *ngIf="data.untilWeeklyTaskTime"
                        [until]="data.untilWeeklyTaskTime"
                        [showHours]="true"
                        [showSeconds]="false"
                        [reset]="false"
                        [minutes]="0.92"
                      >
                      </fast-ui-countdown>
                    </ng-container>
                    <ng-template #daysLeft>
                      {{ daysToGo(data.untilWeeklyTaskTime) }} {{ 'GOFAST.REGULAR_EVENT.DAYS' | translate }}
                    </ng-template>
                    <span>)</span>
                  </span>
                </div>
                <div
                  class="ny-event-menu--quests-weekly--list--title-progress-grid"
                  *ngIf="data.weeklyTasks"
                >
                  <div
                    class="grid-node"
                    *ngFor="let card of data.weeklyTasks"
                    [class.hidden]="!card.isOpened"
                  >
                    <ng-container *ngIf="activeWeeklyQuest(data.weeklyTasks) === card || card.isOpened; else lockedQuest">
                      <div
                        *ngIf="event.name === 'ny'"
                        class="cane cane-green"
                        [class.cane-blue]="card.priority === 7"
                      ></div>
                      <div
                        *ngIf="event.name === 'ny_china'"
                        class="cane cane--green-pop"
                        [class.cane--blue-pop]="card.priority === 7"
                      ></div>
                    </ng-container>
                    <ng-template #lockedQuest>
                      <div
                        class="grid-node-icon"
                        style="margin-bottom: -0.45rem"
                      >
                        <mat-icon
                          svgIcon="game-lock"
                          style="width: 1.2rem; margin-bottom: -0.5rem"
                        ></mat-icon>
                      </div>
                    </ng-template>
                    <div
                      class="grid-node-footer"
                      [class.active]="activeWeeklyQuest(data.weeklyTasks) === card"
                      [class.final]="card.priority === 7"
                    ></div>
                  </div>
                </div>
              </div>
              <ngx-skeleton-loader
                *ngIf="data.weeklyTasks && data.weeklyTasks.length === 0"
                animation="progress-dark"
                [theme]="{
                  'border-radius': '15px',
                  height: '80px',
                  margin: '0'
                }"
              ></ngx-skeleton-loader>
              <ng-container *ngIf="data.weeklyTasks && activeWeeklyQuest(data.weeklyTasks) as quest">
                <ng-container *ngIf="quest.isOpened && quest.progress < quest.params.count">
                  <div class="ny-event-menu--quests-weekly--list--banner">
                    <div class="ny-event-menu--quests-weekly--list--banner--progress">
                      <div class="ny-event-menu--quests-weekly--list--banner--progress-icon">
                        <mat-icon
                          svgIcon="gift"
                          style="width: 2.5rem"
                        ></mat-icon>
                      </div>
                      <div class="ny-event-menu--quests-weekly--list--banner--progress-container">
                        <div class="ny-event-menu--quests-weekly--list--banner--progress-container--title">
                          <div class="ny-event-menu--quests-weekly--list--banner--progress-container--title-text">
                            {{ translate(quest) | appCurrencyTranslate: quest.params }}
                            <span
                              *ngIf="questGame(quest.gameTypeId)"
                              style="color: var(--color-primary)"
                            >
                              <a href="/game/{{ questGame(quest.gameTypeId) | lowercase }}">{{ questGame(quest.gameTypeId) + ' game' }}</a>
                            </span>
                          </div>
                          <div class="ny-event-menu--quests-weekly--list--banner--progress-container--title-count">
                            {{ quest.progress }}/{{ quest.params.count }}
                          </div>
                        </div>
                        <fast-ui-ny-event-progress-bar
                          class="ny-event-menu--quests-weekly--list--banner--progress-container--bar"
                          [progress]="questPercent(quest.params.count, quest.progress)"
                          [eventName]="data.eventName"
                        ></fast-ui-ny-event-progress-bar>
                      </div>
                    </div>
                    <div class="ny-event-menu--quests-weekly--list--banner--prize-arrow"></div>
                    <div class="ny-event-menu--quests-weekly--list--banner--prize-icon">
                      <div
                        *ngIf="event.name === 'ny'"
                        (click)="quest.progress >= quest.params.count && takeQuestReward(quest.id)"
                        [class.active-cane]="quest.progress >= quest.params.count && !quest.rewardTaken"
                        class="cane cane-green"
                        [class.cane-blue]="quest.priority === 7"
                      ></div>
                      <div
                        *ngIf="event.name === 'ny_china'"
                        (click)="quest.progress >= quest.params.count && takeQuestReward(quest.id)"
                        [class.active-cane]="quest.progress >= quest.params.count && !quest.rewardTaken"
                        class="cane cane--green-pop"
                        [class.cane--blue-pop]="quest.priority === 7"
                        style="padding-right: 3rem"
                      ></div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="quest.progress >= quest.params.count && !quest.rewardTaken">
                  <div
                    class="ny-event-menu--quests-weekly--list--banner quest-ready"
                    (click)="quest.progress >= quest.params.count && takeQuestReward(quest.id)"
                  >
                    <div class="ny-event-menu--quests-weekly--list--banner--progress">
                      <div class="ny-event-menu--quests-weekly--list--banner--progress-icon">
                        <mat-icon
                          svgIcon="gift"
                          style="width: 2.5rem"
                        ></mat-icon>
                      </div>

                      <div class="ny-event-menu--quests-weekly--list--banner--progress-container">
                        <div
                          class="ny-event-menu--quests-weekly--list--banner--progress-container--title"
                          style="padding-bottom: 0"
                        >
                          <div
                            class="ny-event-menu--quests-weekly--list--banner--progress-container--title-text"
                            style="text-transform: uppercase; font-weight: 600; padding-bottom: 0"
                          >
                            {{ 'GOFAST.REGULAR_EVENT.TAKE' | translate }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="ny-event-menu--quests-weekly--list--banner--prize-arrow"></div>

                    <div class="ny-event-menu--quests-weekly--list--banner--prize-icon">
                      <div
                        *ngIf="event.name === 'ny'"
                        [class.active-cane]="quest.progress >= quest.params.count && !quest.rewardTaken"
                        class="cane cane-green"
                        [class.cane-blue]="quest.priority === 7"
                      ></div>
                      <div
                        *ngIf="event.name === 'ny_china'"
                        [class.active-cane]="quest.progress >= quest.params.count && !quest.rewardTaken"
                        class="cane cane--green-pop"
                        [class.cane--blue-pop]="quest.priority === 7"
                        style="padding-right: 3rem"
                      ></div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="data.weeklyTasks && !activeWeeklyQuest(data.weeklyTasks)">
                <div
                  *ngIf="rewardQuest(data.weeklyTasks) as quest"
                  class="ny-event-menu--quests-weekly--list--banner quest-done"
                >
                  <div class="ny-event-menu--quests-weekly--list--banner--progress">
                    <div class="ny-event-menu--quests-weekly--list--banner--progress-icon">
                      <mat-icon
                        svgIcon="gift"
                        style="width: 2.5rem"
                      ></mat-icon>
                    </div>
                    <div class="ny-event-menu--quests-weekly--list--banner--progress-container">
                      <div class="ny-event-menu--quests-weekly--list--banner--progress-container--title">
                        <div class="ny-event-menu--quests-weekly--list--banner--progress-container--title-text">
                          {{ translate(quest) | appCurrencyTranslate: quest.params }}
                          <span
                            *ngIf="questGame(quest.gameTypeId)"
                            style="color: var(--color-primary)"
                          >
                            <a href="/game/{{ questGame(quest.gameTypeId) | lowercase }}">{{ questGame(quest.gameTypeId) + ' game' }}</a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="ny-event-menu--quests-weekly--list--banner--prize-icon">
                    <mat-icon
                      svgIcon="notification-info"
                      style="width: 2.5rem; color: #0cc4ff"
                    ></mat-icon>
                  </div>
                </div>
              </ng-container>
            </div>
            <div
              class="ny-event-menu--quests-weekly--details-btn"
              *ngIf="data.weeklyTasks && data.weeklyTasks.length"
            >
              <button
                class="btn btn-primary"
                (click)="naviagateEventMenu()"
                style="text-transform: uppercase"
              >
                <ng-container *ngIf="event.untilTime; else details">
                  <ng-container *ngIf="isDays(event.untilTime); else daysLeft">
                    <fast-ui-countdown
                      *ngIf="event.untilTime"
                      [until]="event.untilTime"
                      [showHours]="true"
                      [showSeconds]="true"
                      [reset]="false"
                      [minutes]="0.92"
                    >
                    </fast-ui-countdown>
                  </ng-container>
                  <ng-template #daysLeft>
                    {{ 'GOFAST.NY_EVENT.FINAL.MODAL_TIMER' | translate }} {{ daysToGo(event.untilTime) }}
                  </ng-template>
                </ng-container>

                <ng-template #details>
                  {{ 'GOFAST.NY_EVENT.DESCRIPTION_BUTTON' | translate }}
                </ng-template>
              </button>
            </div>
          </div>
          <div
            class="ny-event-menu--presents"
            style="grid-area: c"
          >
            <div
              class="ny-event-menu--presents--keys"
              *ngIf="!data.keys"
            >
              <ngx-skeleton-loader
                animation="progress-dark"
                [theme]="{
                  width: '190px',

                  'border-radius': '15px',
                  height: '50px',
                  margin: '0'
                }"
              ></ngx-skeleton-loader>
            </div>
            <div
              class="ny-event-menu--presents--keys"
              *ngIf="data.keys"
            >
              <div class="ny-event-menu--presents--keys-title title-strong">
                {{ 'GOFAST.REGULAR_EVENT.KEYS' | translate }}
              </div>
              <div class="ny-event-menu--presents--keys-container">
                <div
                  class="ny-event-menu--presents--keys-container--node"
                  (click)="naviagateEventMenu('/events/ny-all-event')"
                >
                  <div class="ny-event-menu--presents--keys-container--node-icon">
                    <div
                      class="cane cane-red"
                      *ngIf="event.name === 'ny'"
                    ></div>
                    <div
                      class="cane cane--red-pop"
                      *ngIf="event.name === 'ny_china'"
                    ></div>
                  </div>
                  <div class="ny-event-menu--presents--keys-container--node-count">{{ data.keys.orangeKeys }}</div>
                </div>
                <div
                  class="ny-event-menu--presents--keys-container--node"
                  (click)="naviagateEventMenu('/events/ny-all-event')"
                >
                  <div class="ny-event-menu--presents--keys-container--node-icon">
                    <div
                      class="cane cane-green"
                      *ngIf="event.name === 'ny'"
                    ></div>
                    <div
                      class="cane cane--green-pop"
                      *ngIf="event.name === 'ny_china'"
                    ></div>
                  </div>
                  <div class="ny-event-menu--presents--keys-container--node-count">{{ data.keys.greenKeys }}</div>
                </div>
                <div
                  class="ny-event-menu--presents--keys-container--node"
                  (click)="naviagateEventMenu('/events/ny-all-event')"
                >
                  <div class="ny-event-menu--presents--keys-container--node-icon">
                    <div
                      class="cane cane-blue"
                      *ngIf="event.name === 'ny'"
                    ></div>
                    <div
                      class="cane cane--blue-pop"
                      *ngIf="event.name === 'ny_china'"
                    ></div>
                  </div>
                  <div class="ny-event-menu--presents--keys-container--node-count">{{ data.keys.blueKeys }}</div>
                </div>
              </div>
            </div>
            <div
              class="ny-event-menu--presents--cases"
              *ngIf="data.totalProgress"
            >
              <div class="ny-event-menu--presents--cases-title">
                <div class="ny-event-menu--presents--cases-title-text title-strong">{{ 'GOFAST.NY_EVENT.GIFTS_FOUND' | translate }}</div>
                <div class="ny-event-menu--presents--cases-title-count text-muted">
                  {{ data.totalProgress.found }}/{{ data.totalProgress.total }}
                </div>
              </div>
              <div class="ny-event-menu--presents--cases-container">
                <ngx-skeleton-loader
                  *ngIf="data.items && data.items.length === 0"
                  animation="progress-dark"
                  [theme]="{
                    width: '160px',
                    'border-radius': '15px',
                    height: '150px',
                    margin: '0'
                  }"
                ></ngx-skeleton-loader>
                <div
                  class="ny-event-menu--presents--cases-container-row-item"
                  *ngFor="let item of data.items; let i = index"
                  [class.hidden]="item.status === 'blocked'"
                  (click)="onPresentClick(item.entityId)"
                >
                  <img
                    *ngIf="event.name === 'ny'"
                    [src]="'https://d2lomvz2jrw9ac.cloudfront.net/ny2023/box4.png'"
                    alt=""
                    style="width: 100%; height: 100%; padding: 0.2rem"
                  />
                  <img
                    *ngIf="event.name === 'ny_china'"
                    [src]="item.image"
                    alt=""
                    style="width: 100%; height: 100%; padding: 0.2rem"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="ny-event-modal-wrapper"
        (click)="activateEvetMenu()"
      ></div>
    </ng-container>
  </div>
</ng-container>
