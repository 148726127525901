import {Injectable} from '@angular/core';
import {Socket as WrappedSocket} from "ngx-socket-io";
import {
  ICaseLuckyWonEvent, ICaseQuestMotivatorList,
  ICaseQuestProgress,
  ICaseQuestResponse,
} from "@dev-fast/types";
import {Observable} from 'rxjs';
import {ApiService} from '../../api.service';
import {Environments, EnvironmentService} from '@csgofast/core/environment-service';


@Injectable({
  providedIn: 'root',
})
export class CasesQuestBackendService {
  private environment: Environments;

  constructor(private readonly api: ApiService, private readonly environmentService: EnvironmentService,private readonly ws: WrappedSocket) {
    this.environment = this.environmentService.environments;
  }

  /** Общая инфа по квесту и прогрессу*/
  public getQuestInfo = (): Observable<ICaseQuestResponse> => {
    return this.api.get(`${this.environment.GATEWAY_URL}/api/cases/categories/quest`, {
      withCredentials: true,
    });
  };

  /** Забрать приз. возвращает или успех или ошибку например:
   * {
   *   "statusCode": 400,
   *   "message": "ERRORS.CASES.QUEST_REWARD_NOT_AVAILABLE"
   * }
   */
  public pickUpPrize = (categoryId: number, questId: number): Observable<{success:boolean}> => {
    return this.api.post(`${this.environment.GATEWAY_URL}/api/cases/suitcases/${categoryId}/quest/${questId}/reward`, null,{
      withCredentials: true,
    });
  };

  public getMotivatorList = (): Observable<ICaseQuestMotivatorList> => {
    return this.api.get(`${this.environment.GATEWAY_URL}/api/motivator/cases-quest`, {
      withCredentials: true,
    });
  };
/** летит после открытия кейса*/
  public casesQuestProgressEvent(callback: (message: ICaseQuestProgress) => void): void {
    this.ws.on('cases-quest:progress', callback);
  }
/** выпал бонусный(супер редкий) кейс*/
  public luckyWonEvent(callback: (message: ICaseLuckyWonEvent) => void): void {
    this.ws.on('cases-quest:lucky-won', callback);
  }
}
