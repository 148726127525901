import { Injectable } from '@angular/core';
import { CommonStoreService } from '@csgofast/core-state-common';
import { SoundService } from '@csgofast/core/sound-service';
import { LayoutState } from '@csgofast/core/state/layout';
import { OpenModal } from '@csgofast/core/state/modals';
import { ActivatePromoCode, GetCurrentPromo, ReferralsState } from '@csgofast/core/state/referrals';
import { SwitchWalletType } from '@csgofast/core/state/user-store';
import { ICurrencyRate, IError, ILocales, IUserDetailed, ModalNames, PromoCode, Themes } from '@dev-fast/types';
import { ActionsExecuting, actionsExecuting } from '@ngxs-labs/actions-executing';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Actions, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Injectable()
export class DesktopHeaderService {
  public user$: Observable<IUserDetailed | null | undefined>;
  public theme$: Observable<Themes>;
  public locales$: Observable<ILocales[]>;
  public currentLocale$: Observable<ILocales>;
  public currency$: Observable<ICurrencyRate | null>;
  public currencyList$: Observable<ICurrencyRate[]>;

  @Select(actionsExecuting([ActivatePromoCode]))
  public activationIsExecuting$!: Observable<ActionsExecuting>;
  @Select(ReferralsState.activePromoCode)
  public activePromoCode$!: Observable<PromoCode>;
  @Select(ReferralsState.promoData)
  public promoData$!: Observable<{
    activationErr: IError | null;
    activePromoCode: PromoCode | null;
    promoActivated: boolean;
  }>;
  @Select(LayoutState.isTabletByWidth)
  public isTabletByWidth: Observable<boolean> | undefined;

  public soundStatus$: Observable<boolean>;

  // public activationSuccess$ = this.actions$
  //   .pipe(
  //     ofActionSuccessful(ActivatePromoCodeSuccess),
  //     map((action: ActivatePromoCodeSuccess) => action.code)
  //   )
  //   .subscribe((val) => console.log(val));

  constructor(
    private readonly commonService: CommonStoreService,
    private readonly actions$: Actions,
    private readonly soundService: SoundService
  ) {
    this.user$ = this.commonService.user$;
    this.theme$ = this.commonService.theme$;
    this.locales$ = this.commonService.locales$;
    this.currentLocale$ = this.commonService.currentLocale$;
    this.currency$ = this.commonService.currency$;
    this.currencyList$ = this.commonService.currencyList$;
    this.soundStatus$ = this.soundService.status$;
  }

  public switchTheme(theme: Themes) {
    this.commonService.switchTheme(theme);
  }
  public switchLang(locale: ILocales) {
    this.commonService.switchLang(locale);
  }
  public toggleSounds() {
    this.soundService.toggleSoundStatus();
  }
  public logout() {
    this.commonService.logout();
  }
  public login() {
    this.commonService.login();
  }

  @Dispatch() public switchBalance = () => new SwitchWalletType();
  @Dispatch() public activatePromoCode = (code: string) => new ActivatePromoCode(code);
  @Dispatch() public getCurrentPromo = () => new GetCurrentPromo();

  @Dispatch() public openModal = (name: ModalNames, payload?: any) => new OpenModal(name, payload);
}
