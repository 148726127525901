import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {P2pPurchaseTradeModalComponent} from './p2p-purchase-trade-modal.component';
import {TranslateModule} from '@ngx-translate/core';
import {SkinItemImageModule} from '@csgofast/shared/directives';
import {AppCurrencyModule} from '@csgofast/shared/pipes';
import {MatIconModule} from '@angular/material/icon';
import {CountdownComponentModule} from '@dev-fast/ui-components';

@NgModule({
  declarations: [P2pPurchaseTradeModalComponent],
  imports: [CommonModule, MatIconModule, TranslateModule, AppCurrencyModule, SkinItemImageModule,CountdownComponentModule],
})
export class P2pPurchaseTradeModalModule {}
