import { RouterStateParams } from "@csgofast/core/state/utils";
import { delay, EMPTY, iif, Observable, of, throwError, timer } from "rxjs";
import { Injectable } from "@angular/core";
import {Action, NgxsOnInit, Selector, State, StateContext, Store} from "@ngxs/store";
import {
  catchError,
  delayWhen,
  pluck,
  retryWhen,
  startWith,
  switchMap,
  tap,
} from "rxjs/operators";

import {ISteamStore, ISteamStoreInventory, ModalNames, SteamStatuses} from "@dev-fast/types";
import { P2pApiService } from "@dev-fast/backend-services";
import { HttpErrorResponse } from "@angular/common/http";
import { P2pBuyingState } from "./buying";
import { DepositSelected, P2pDepositState } from "./deposit";
import { P2P_INITIAL_STATE, P2pStateModel } from "./p2p-state.model";
import { GetSteamInventory, GetSteamStatus } from "./p2p.actions";
import { NotificationsService } from "@csgofast/core/notification-service";
import { RouterNavigation } from "@ngxs/router-plugin";
import { FrameMessageTypes, IFrameMessageService } from "@csgofast/core/iframe";
import {CloseModal, ModalsState, OpenModal} from "@csgofast/core/state/modals";

@State<P2pStateModel>({
  name: "p2p",
  defaults: P2P_INITIAL_STATE,
  children: [P2pBuyingState, P2pDepositState],
})
@Injectable()
export class P2pCommonState implements NgxsOnInit {
  constructor(
    private readonly p2pApiService: P2pApiService,
    private readonly notificationsService: NotificationsService,
    private readonly _frameMessageService: IFrameMessageService,
    private readonly store:Store
  ) {}

  @Selector()
  public static steamInventory({
    items,
  }: P2pStateModel): ISteamStoreInventory[] {
    return items;
  }
  @Selector()
  public static sellerBanEndAt({
    sellerBanEndAt,
  }: P2pStateModel): number | null {
    return sellerBanEndAt && sellerBanEndAt > 0 ? sellerBanEndAt : null;
  }

  public ngxsOnInit({ getState, dispatch }: StateContext<P2pStateModel>): void {
    this._frameMessageService.on(
      FrameMessageTypes.MESSAGE_FROM_BB,
      "openSteamCredentialsModal",
      () => dispatch(new OpenModal(ModalNames.P2P_SETUP, ["steam", "apiKey"]))
    );
    this._frameMessageService.on(
      FrameMessageTypes.MESSAGE_FROM_BB,
      "checkSteam",
      () => dispatch(new GetSteamStatus())
    );
  }
  @Action(CloseModal)
  public closeModal(
    { dispatch }: StateContext<P2pStateModel>,
    { name }: CloseModal
  ) {
    if (name === ModalNames.P2P_SETUP) {
      this._frameMessageService.sendMessage({
        type: FrameMessageTypes.MESSAGE_TO_BB,
        eventName: "closeSteamCredentialsModal",
        payload: { message: "closeSteamCredentialsModal" },
      });
    }
  }
  @Action(GetSteamStatus)
  public getSteamStatus({ dispatch, patchState }: StateContext<P2pStateModel>) {
    return this.p2pApiService.getSteamStatus().pipe(
      tap((res) => {
        this._frameMessageService.sendMessage({
          type: FrameMessageTypes.MESSAGE_TO_BB,
          eventName: "checkSteamRespons",
          payload: { ...res },
        });
        if ((res.status === SteamStatuses.SURGE || res.status === SteamStatuses.OFFLINE) && this.store.selectSnapshot(ModalsState.activeModal) !== ModalNames.STEAM_ERROR) {
          dispatch(new OpenModal(ModalNames.STEAM_ERROR));
        }
        patchState({ steamStatus: <SteamStatuses>res.status });
      })
    );
  }
  @Action(RouterNavigation)
  public routerNavigation(
    { dispatch }: StateContext<P2pStateModel>,
    { routerState }: RouterNavigation<RouterStateParams>
  ) {
    if (routerState.url.includes("refill/skins")) {
      dispatch(new GetSteamInventory());
    }
  }
  @Action(DepositSelected)
  public depositSelected({ dispatch }: StateContext<P2pStateModel>) {
    return of(EMPTY).pipe(
      delay(1000),
      switchMap(() => dispatch(new GetSteamInventory()))
    );
  }
  @Action(GetSteamInventory)
  public getSteamInventory({
    patchState,
  }: StateContext<P2pStateModel>): Observable<ISteamStore> {
    return this.p2pApiService.getInventory().pipe(
      catchError((e) => {
        this._onError(e);
        return of({ items: [], error: true, sellerBanEndAt: null });
      }),
      switchMap((res) =>
        iif(
          () => !!res?.sellerBanEndAt,
          throwError(() => res).pipe(startWith(res)),
          of(res)
        )
      ),
      retryWhen((err) =>
        err.pipe(
          pluck("sellerBanEndAt"),
          delayWhen((val: string) => timer(Date.parse(val) - Date.now() + 5000))
        )
      ),
      tap(({ items, sellerBanEndAt }: ISteamStore) => {
        this._frameMessageService.sendMessage({
          type: FrameMessageTypes.MESSAGE_TO_BB,
          eventName: "GetSteamInventory",
          payload: { items, sellerBanEndAt },
        });
        const diff =
          (sellerBanEndAt ? Date.parse(sellerBanEndAt) : Date.now()) -
          Date.now();
        patchState({ items, sellerBanEndAt: diff });
      })
    );
  }

  private _onError(e: HttpErrorResponse): void {
    this.notificationsService.addErrorNotification(
      e.error.message || "BALANCE_REFILL.TRADE_BOT.ERROR_UNAVAILABLE",
      {
        icon: "warning",
        system: true,
      }
    );
  }
}
