import {Component, Input} from '@angular/core';
import {endWith, filter, map, startWith, switchMap, takeUntil} from 'rxjs/operators';
import {interval, Observable, timer} from 'rxjs';
import moment from 'moment';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {P2pDepositService} from '@csgofast/modules/p2p';
import {IP2pDepositingItem, OrderStatusEnum} from '@dev-fast/types';

@Component({
  selector: 'csgofast-p2p-selling-item',
  templateUrl: './p2p-selling-item.component.html',
  styleUrls: ['./p2p-selling-item.component.scss', '../../styles/progress-line.scss'],
})
export class P2pSellingItemComponent {
  @Input() public id: number | undefined;
  public item$: Observable<IP2pDepositingItem>;
  public increase$: Observable<number> | undefined;
  public initialProgress$: Observable<string> | undefined;
  public offerTimer$: Observable<number | null> | undefined;
  public offerProgress$: Observable<number> | undefined;
  public confirm: boolean;
  public statuses: typeof OrderStatusEnum;
  constructor(public readonly dialog: MatDialog, private readonly p2pDepositService: P2pDepositService) {
    this.confirm = false;
    this.statuses = OrderStatusEnum;
    this.item$ = this.p2pDepositService.depositing$.pipe(
      filter((items) => items.some((el) => el.id === this.id)),
      map((items) => items.find((el) => el.id === this.id)),
      filter((item): item is IP2pDepositingItem => item !== undefined)
    );
    this.subscribeEmitters();
  }

  public getDataFormat(data: number): string {
    return data > 3600000 ? 'hh:mm:ss' : 'mm:ss';
  }

  private subscribeEmitters(): void {
    this.offerTimer$ = this.item$.pipe(
      filter(({ nextStatusAt }) => Date.parse(nextStatusAt) > Date.now()),
      switchMap(({ nextStatusAt }) =>
        interval(1000).pipe(
          startWith(Date.parse(nextStatusAt) - Date.now()),
          map(() => Date.parse(nextStatusAt) - Date.now()),
          takeUntil(timer(Date.parse(nextStatusAt) - Date.now())),
          endWith(null)
        )
      )
    );

    this.offerProgress$ = this.item$.pipe(map(({ nextStatusAt }) => Date.parse(nextStatusAt) - Date.now()));

    this.initialProgress$ = this.item$.pipe(
      map(({ nextStatusAt, statusAt }) =>
        (100 - (moment(nextStatusAt).diff(moment()) / moment(nextStatusAt).diff(statusAt)) * 100).toFixed(2)
      )
    );

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.increase$ = this.item$.pipe(
      map(({ recommendedPrice, sellerPrice, price }) => {
        return Math.round(((sellerPrice ? sellerPrice : price) / (recommendedPrice ? recommendedPrice : price)) * 100) - 100;
      })
    );
  }

  public toggleConfirm(): void {
    this.confirm = !this.confirm;
  }

  public onSellNow(id: number): void {
    this.p2pDepositService.sellNow(id);
  }

  public onConfirm(id: number): void {
    this.p2pDepositService.requestConfirm(id);
  }

  public onDecline(id: number): void {
    this.p2pDepositService.requestDecline(id);
  }

  public onDeposit(id: number, price: number): void {
    this.p2pDepositService.deposit(id, price);
  }

  public onDeleted(id: number) {
    this.p2pDepositService.deleted([id]);
  }
  public onPauseItem(id: number) {
    this.p2pDepositService.pause(id);
  }
  public onResumeItem(id: number) {
    this.p2pDepositService.resume(id);
  }

  public onSendTrade() {
    // this.dialog.open(P2pAuctionCompletedModalComponent, {
    //   data: this.item$,
    // });
    this.p2pDepositService.showAuctionCompletedModal(this.item$);
  }
}
