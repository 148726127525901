import {IconConfig} from './icon.model';

export const iconsEmojis: IconConfig[] = [
  {
    name: 'angry-emoji',
    path: 'assets/img/mat-icons/emojis/angry-emoji.svg',
  },
  {
    name: 'arrogant-emoji',
    path: 'assets/img/mat-icons/emojis/arrogant-emoji.svg',
  },
  {
    name: 'confused-emoji',
    path: 'assets/img/mat-icons/emojis/confused-emoji.svg',
  },
  {
    name: 'embarrassed-emoji',
    path: 'assets/img/mat-icons/emojis/embarrassed-emoji.svg',
  },
  {
    name: 'greed-emoji',
    path: 'assets/img/mat-icons/emojis/greed-emoji.svg',
  },
  {
    name: 'happy-emoji',
    path: 'assets/img/mat-icons/emojis/happy-emoji.svg',
  },
  {
    name: 'kiss-emoji',
    path: 'assets/img/mat-icons/emojis/kiss-emoji.svg',
  },
  {
    name: 'sad-emoji',
    path: 'assets/img/mat-icons/emojis/sad-emoji.svg',
  },
  {
    name: 'shocked-emoji',
    path: 'assets/img/mat-icons/emojis/shocked-emoji.svg',
  },
  {
    name: 'sick-emoji',
    path: 'assets/img/mat-icons/emojis/sick-emoji.svg',
  },
  {
    name: 'surprised-emoji',
    path: 'assets/img/mat-icons/emojis/surprised-emoji.svg',
  },
];
