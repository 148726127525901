<ng-scrollbar [autoHeightDisabled]="false"> </ng-scrollbar>
<ng-container *ngIf="eventName$ | async as eventName">
  <div class="ny-event--terms-modal f-column-center">
    <div class="ny-event--terms-modal--header title-strong">{{ 'GOFAST.NY_EVENT.DESCRIPTION_HEADER' | translate }}</div>
    <div class="ny-event--terms-modal--terms-grid">
      <div class="terms-grid-item">
        <div class="terms-grid-item--header">
          <div class="daily-terms-card">
            <div class="daily-terms-card--sub">
              <div style="position: relative">
                <div style="position: absolute; top: 15px; left: 10px">
                  <div
                    *ngIf="eventName === 'ny'"
                    style="width: 2.5rem; height: 2rem"
                    class="cane cane-red"
                  ></div>
                  <div
                    *ngIf="eventName === 'ny_china'"
                    style="width: 2.5rem; height: 2rem"
                    class="cane cane--red-pop"
                  ></div>
                </div>
                <mat-icon
                  svgIcon="p2p-status-success"
                  style="
                    width: 14px;
                    position: absolute;
                    top: 12px;
                    left: 31px;
                    height: 16px;
                    background: #253138;
                    color: #74ff0c;
                    border-radius: 100%;
                  "
                ></mat-icon>
              </div>
            </div>

            <div class="daily-terms-card--main">
              <div style="padding-bottom: 0.05rem; font-size: 1rem"><span>6</span><span style="color: #757880">/10</span></div>
              <fast-ui-ny-event-progress-bar [progress]="60" [eventName]="eventName"></fast-ui-ny-event-progress-bar>
            </div>
          </div>
        </div>
        <div class="terms-grid-item--container">
          <div class="terms-grid-item--container-title title-strong">{{ 'GOFAST.NY_EVENT.EVENT_MODAL.DAILY_HEADER' | translate }}</div>
          <div class="terms-grid-item--container-description text-muted">
            <span>{{ 'GOFAST.NY_EVENT.EVENT_MODAL.DAILY_1' | translate }}</span>
            <span>{{ 'GOFAST.NY_EVENT.EVENT_MODAL.DAILY_2' | translate }}</span>
            <span>{{ 'GOFAST.NY_EVENT.EVENT_MODAL.DAILY_3' | translate }}</span>
          </div>
        </div>
      </div>
      <div class="terms-grid-item">
        <div class="terms-grid-item--header">
          <div class="weekly-terms-card">
            <div class="weekly-terms-card--open">
              <svg style="width: 100%; height: 100%">
                <circle
                  cx="50%"
                  cy="50%"
                  r="45%"
                  fill="none"
                  stroke="#182328"
                  stroke-width="2"
                />
                <circle
                  class="percent fifty"
                  cx="50%"
                  cy="50%"
                  r="45%"
                  fill="none"
                  stroke="#74ff0c"
                  stroke-width="2"
                  pathLength="100"
                />
              </svg>
              <div
                style="position: absolute; top: 23%; left: 28%"
                *ngIf="eventName === 'ny'"
              >
                <div
                  style="width: 2.5rem; height: 3rem"
                  class="cane cane-green"
                ></div>
              </div>
              <div
                *ngIf="eventName === 'ny_china'"
                style="position: absolute; top: 20%; left: 28%"
              >
                <div
                  style="width: 2.5rem; height: 3rem"
                  class="cane cane--green-pop"
                ></div>
              </div>
            </div>
            <div class="weekly-terms-card--locked">
              <mat-icon
                class="quest-locked-icon"
                svgIcon="game-lock"
              ></mat-icon>
            </div>
          </div>
        </div>
        <div class="terms-grid-item--container">
          <div class="terms-grid-item--container-title title-strong">{{ 'GOFAST.NY_EVENT.EVENT_MODAL.WEEKLY_HEADER' | translate }}</div>

          <div class="terms-grid-item--container-description text-muted">
            <span>{{ 'GOFAST.NY_EVENT.EVENT_MODAL.WEEKLY_1' | translate }}</span>
            <span>{{ 'GOFAST.NY_EVENT.EVENT_MODAL.WEEKLY_2' | translate }}</span>
            <span>{{ 'GOFAST.NY_EVENT.EVENT_MODAL.WEEKLY_3' | translate }}</span>
            <span>{{ 'GOFAST.NY_EVENT.EVENT_MODAL.WEEKLY_4' | translate }}</span>
          </div>
        </div>
      </div>
      <div class="terms-grid-item">
        <div class="terms-grid-item--header">
          <div class="special-terms-card">
            <div
              class="special-terms-card-cane"
              style="position: relative"
            >
              <div
                style="position: absolute; top: -6%; left: 10%"
                *ngIf="eventName === 'ny'"
              >
                <div
                  style="width: 4.5rem; height: 6rem"
                  class="cane cane-blue"
                ></div>
              </div>
              <div
                style="position: absolute; top: -12%; left: 10%"
                *ngIf="eventName === 'ny_china'"
              >
                <div
                  style="width: 4.5rem; height: 6rem"
                  class="cane cane--blue-pop"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="terms-grid-item--container">
          <div class="terms-grid-item--container-title title-strong">{{ 'GOFAST.NY_EVENT.EVENT_MODAL.SPECIAL_HEADER' | translate }}</div>
          <div class="terms-grid-item--container-description text-muted">
            <span>{{ 'GOFAST.NY_EVENT.EVENT_MODAL.SPECIAL_1' | translate }}</span>
            <span>{{ 'GOFAST.NY_EVENT.EVENT_MODAL.SPECIAL_2' | translate }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="ny-event--terms-modal--terms-keys">
      <div class="ny-event--terms-modal--terms-keys--img">
        <div
          *ngIf="eventName === 'ny'"
          style="width: 4.5rem; position: absolute; height: 6rem; left: -5%"
          class="cane cane-red"
        ></div>
        <div
          *ngIf="eventName === 'ny_china'"
          style="width: 4.5rem; position: absolute; height: 6rem; left: -5%"
          class="cane cane--red-pop"
        ></div>
        <div
          *ngIf="eventName === 'ny'"
          style="width: 4.5rem; height: 6rem; position: absolute; left: 18%"
          class="cane cane-green"
        ></div>
        <div
          *ngIf="eventName === 'ny_china'"
          style="width: 4.5rem; height: 6rem; position: absolute; left: 18%"
          class="cane cane--green-pop"
        ></div>
        <div
          *ngIf="eventName === 'ny'"
          style="width: 4.5rem; height: 6rem; position: absolute; left: 42%"
          class="cane cane-blue"
        ></div>
        <div
          *ngIf="eventName === 'ny_china'"
          style="width: 4.5rem; height: 6rem; position: absolute; left: 42%"
          class="cane cane--blue-pop"
        ></div>
      </div>
      <div class="ny-event--terms-modal--terms-keys--container">
        <div class="ny-event--terms-modal--terms-keys--container-title title-strong">
          {{ 'GOFAST.NY_EVENT.KEYS_HEADER' | translate }}
        </div>
        <div class="ny-event--terms-modal--terms-keys--container-description text-muted">
          {{ 'GOFAST.NY_EVENT.KEYS_DESCRIPTION' | translate }}
        </div>
      </div>
    </div>
  </div>
</ng-container>
