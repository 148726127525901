import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MobileHeaderComponent} from './mobile-header.component';
import {
  BalanceWidgetComponentModule,
  GameSelectorComponentModule,
  MobileMenuComponentModule
} from '@dev-fast/ui-components';
import {MatIconModule} from '@angular/material/icon';
import {ChatModule} from '@csgofast/widgets/chat';
import {CustomAsyncPipeModule} from '@csgofast/shared/pipes';
import {NotificationsWidgetsModule} from '@csgofast/widgets/notifications';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    CustomAsyncPipeModule,
    BalanceWidgetComponentModule,
    GameSelectorComponentModule,
    MobileMenuComponentModule,
    ChatModule,
    NotificationsWidgetsModule,
  ],
  declarations: [MobileHeaderComponent],
  exports: [MobileHeaderComponent],
})
export class MobileAppHeaderModule {}
