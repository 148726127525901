import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import { ConfigConfirmDialog } from './config';

@Component({
  selector: 'csgofast-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent  {
  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfigConfirmDialog, private mdDialogRef: MatDialogRef<ConfirmDialogComponent>) { }
  public cancel() {
    this.close(false);
  }
  public close(value:boolean) {
    this.mdDialogRef.close(value);
  }
  public confirm() {
    this.close(true);
  }
  @HostListener("keydown.esc")
  public onEsc() {
    this.close(false);
  }
}
