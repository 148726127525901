import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Environments, EnvironmentService } from "@csgofast/core/environment-service";
import { LanguageService, MAIN_LANG} from "@csgofast/core/language-service";
import { AnalyticsService } from "@csgofast/core/analytics-service";
import { LanguageState } from "@csgofast/core/language-service";
import { Injectable } from '@angular/core';
import { SeoService } from "@csgofast/core/seo-service";
import { seoRoutes } from "@csgofast/core/iframe";
import { Location } from '@angular/common';
import { Store } from "@ngxs/store";
import { filter } from "rxjs";

/**
 * Сервис для всего, что связано с маршрутами, ссылками, редиректами внутри проекта
 */
@Injectable({
  providedIn: 'root'
})
export class RouterService {
  private environment: Environments;

  constructor(
    private readonly environmentService: EnvironmentService,
    private readonly analyticsService: AnalyticsService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly seoService: SeoService,
    private readonly location: Location,
    private readonly router: Router,
    private readonly store: Store,
  ) {
    this.environment = this.environmentService.getEnvironment();
    this.subscribeEmitters();
  }

  private subscribeEmitters(): void {
    // Отслеживаем все переходы по сайту
    this.router.events
      .pipe(filter(routerEvent => routerEvent instanceof NavigationEnd))
      .subscribe((event: any) => {
        // NOTE: Костыль для того, чтобы старый формат рефералок с # перебрасывал на новый формат
        if(event.url.includes('#r/')) { this.redirectFromLegacyPromo(event) }

        let route = this.activatedRoute;
        // Достаем самый крайний route. Нужно для того, чтобы получить язык, который хранится в крайнем коренном route
        while (route.firstChild) { route = route.firstChild; }

        this.updateLangPath();
        this.setSeoParams(route);
      });
  }

  /**
   * Во время переходов на сайте динамически везде добавляем текущую приставку
   */
  private updateLangPath(): void {
    if(!LanguageService.isAcceptedLanguageCode(this.router.url.split('/')[1])) {
      const lang = LanguageService.getLangByCode(this.store.selectSnapshot(LanguageState.lang).path || LanguageService.getRouteLanguages()[MAIN_LANG].path);
      if(lang !== MAIN_LANG) {

        const [url, params] = this.router.url.split('?');
        const path: string = '/' + url.split('/').slice(
          LanguageService.isAcceptedLanguageCode(url.split('/')[1]) ? 2 : 1
        ).join('/');

        const langs = LanguageService.getRouteLanguages();
        const currentLangPath = url.split('/')[1];

        // Смотрим, есть ли в текущем пути языки или нет. Если нет, вставляем актуальный, если есть, заменяем на актуальный
        const state = (Object.keys(langs).filter(lang => lang !== MAIN_LANG).includes(currentLangPath)
            ? url.replace(currentLangPath ? currentLangPath : '/'+lang, lang)
            : (url !== '/'
              ? lang + (path === '/' ? '': path)
              : '/' + lang))
          + (params ? '?' + params : '')


        if(this.router.url.split('?')[0] !== '/' + state) {
          this.location.replaceState(state);
        }
      }
    }
  }

  /**
   * Переброс пользователя с /#r/123 на ?r=123
   */
  private redirectFromLegacyPromo(event: any): void {
    const [url, code] = event.url.split('#r/');
    this.router.navigate([url], {queryParams: {r: code}});
  }

  /**
   * Добавление к маршрутам СЕО параметров
   */
  private setSeoParams(route: any): void {
    const hostName = this.environment.HOSTNAME.split('//');
    const seo = route.snapshot.data['seo'];
    const seoOptions = route.snapshot.data['seoOptions'];
    const localesData = {hostName}
    const languages = Object.keys(LanguageService.getRouteLanguages()).filter(lang => lang !== MAIN_LANG);

    if (seo) {
      // Если параметры не указаны, то по-умолчанию добавляем все параметры
      this.seoService.updateTitle(`SEO.TITLE.${route.snapshot.data['seo']}`, {hostName: hostName[hostName.length - 1]});
      if (!seoOptions) {
        this.seoService.setH1({value: `SEO.H1.${seo}`, data: localesData});
        this.seoService.setMain({value: `SEO.MAIN.${seo}` , data: localesData});
        this.seoService.setFaq({value: `SEO.FAQ1.${seo}`, data: localesData});
      } else {
        if(seoOptions.h1) { this.seoService.setH1({value: `SEO.H1.${seo}`, data: localesData})
        } else { this.seoService.resetH1(); }
        if(seoOptions.main) { this.seoService.setMain({value: `SEO.MAIN.${seo}`, data: localesData})
        } else { this.seoService.resetMain(); }
        if(seoOptions.faq) { this.seoService.setFaq({value: `SEO.FAQ.${seo}`, data: localesData})
        } else { this.seoService.resetFaq(); }
      }
      this.seoService.updateDescription(`SEO.DESCRIPTION.${route.snapshot.data['seo']}`, {hostName: this.environment.HOSTNAME});
    } else {
      this.seoService.updateDescription(`SEO.DESCRIPTION.DEFAULT`, {hostName: this.environment.HOSTNAME});
      this.seoService.updateTitle(`SEO.TITLE.DEFAULT`, {hostName: hostName[hostName.length - 1]});
      this.seoService.resetSeoTexts();
    }

    this.setSEOIndex();

    this.seoService.updateOgUrl(`${this.environment.HOSTNAME}${route.snapshot.data['ogUrl'] || this.router.url}`);
    this.seoService.updateCanonicalUrl(`${this.environment.HOSTNAME}${route.snapshot.data['canonicalUrl'] || this.router.url}`);
    this.seoService.updateAlternateLinks(languages.includes(this.router.url.split('/')[1])? this.router.url.slice(3): this.router.url);

  }

  /**
   * Определение пометить ли текущую страницу как важную для сео или неважную
   */
  private setSEOIndex(): void {
    // Если текущая страница не важна для сео, то помечаем её недоступной для ботов
    if (!seoRoutes.includes(LanguageService.getBaseUrl(this.router.url))) {
      this.seoService.setNoIndexMeta();
    } else {
      this.seoService.setIndexMeta();
    }
  }
}
