import { Component, Input, ViewEncapsulation } from '@angular/core';
import { EventsConfig, ICaseQuestTotalProgress, ICasesQuestItem} from '@dev-fast/types';
import { Observable } from 'rxjs';
import { NyEventService } from '../../../core/services/ny-event.service';

@Component({
  selector: 'csgofast-ny-progress-block',
  templateUrl: './progress-block.component.html',
  styleUrls: ['./progress-block.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NyProgressBlockComponent {
  @Input() public totalProgress: ICaseQuestTotalProgress | null;
  @Input() public reward!: ICasesQuestItem | null;
  @Input() public eventName: keyof EventsConfig | null | undefined;
  @Input() public isEventComplete: boolean | undefined;

  constructor(private readonly nyEventService: NyEventService) {
    this.totalProgress = null;
  }
  //fixme move to ny-event.component
  public pickupReward(present: ICasesQuestItem): void {
    this.nyEventService.pickUpPrize(present);
  } 
}
