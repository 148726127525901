import {Injectable} from '@angular/core';
import {Action, NgxsOnInit, Selector, State, StateContext, Store} from '@ngxs/store';
import {patch, removeItem, updateItem} from '@ngxs/store/operators';
import moment from "moment";
import {Observable, of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {P2pApiService} from '@dev-fast/backend-services';
import {
  IMarketplaceItem,
  IP2pParticipateItemUpdated,
  IP2pPurchaseItem,
  OrderStatusEnum,
  Panel,
  SubPanel
} from "@dev-fast/types";
import {P2P_BUYING_INITIAL_STATE, P2pBuyingStateModel} from './buying-state.model';
import {HttpErrorResponse} from '@angular/common/http';
import {NotificationsService} from '@csgofast/core/notification-service';
import {
  ConfirmBid,
  CreateBid,
  DeleteBid,
  GetMyBids,
  MergePurchasing,
  RemoveItem,
  ToggleSelected,
  UpdateShowWarnValue,
} from './buying.actions';
import {LocalStorageService} from '@csgofast/core/local-storage-service';
import {FrameMessageTypes, IFrameMessageService} from '@csgofast/core/iframe';
import {ChangeActivePanel, LayoutState} from '@csgofast/core/state/layout';
import { PriorityList, sortBy, sortByPriority } from "@csgofast/shared/utils";
import {RefreshCurrentUser} from '@csgofast/core/state/user-store';

@State<P2pBuyingStateModel>({
  name: 'buying',
  defaults: P2P_BUYING_INITIAL_STATE,
})
@Injectable()
export class P2pBuyingState implements NgxsOnInit {
  @Selector()
  public static purchasing({ purchasing }: P2pBuyingStateModel): IP2pPurchaseItem[] {
    return purchasing;
  }
  @Selector()
  public static showWarn({ showWarn }: P2pBuyingStateModel): boolean {
    return showWarn;
  }

  constructor(
    private readonly _api: P2pApiService,
    private readonly _store: Store,
    private readonly _notificationsService: NotificationsService,
    private readonly _localStorage: LocalStorageService,
    private readonly _frameMessageService: IFrameMessageService
  ) {}

  public ngxsOnInit({ getState, patchState, dispatch }: StateContext<P2pBuyingStateModel>): void {
    const showWarn = this._localStorage.get('marketplace-showWarn');
    if (showWarn !== undefined) {
      patchState({ showWarn });
    }

    const hasInState = (id: number): boolean => {
      const { purchasing } = getState();
      const element = purchasing.find((el) => el.id === id);
      return element !== undefined;
    };
    // this._api.itemUpdatedEvent((payload: IP2pItemUpdated) => {
    //   if (hasInState(payload.id)) {
    //     console.log(payload);
    //     // this._store.dispatch(new MergePurchasing(payload.id, payload));
    //     dispatch(new GetMyBids());
    //   }
    // });
    this._api.participantItemUpdatedEvent((payload: IP2pParticipateItemUpdated) => {
      if (hasInState(payload.id)) {
        // console.warn(payload);
        this._store.dispatch(new MergePurchasing(payload.id, payload));
        // dispatch(new GetMyBids());
      }
    });

    this._api.itemDeletedEvent((payload) => {
      if (hasInState(payload.id)) {
        this._store.dispatch(new RemoveItem(payload.id, true));
      }
    });

    this._frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'clickToTrade', (payload: IMarketplaceItem) =>
      dispatch(new ToggleSelected(payload))
    );
  }
  // public ngxsOnInit({ getState }: StateContext<P2pDepositStateModel>): void {
  //   this._api.participantItemUpdatedEvent((payload: IP2pParticipateItemUpdated) => {
  //     const { depositingItems } = getState();
  //     const element = depositingItems.find((el) => el.id === payload.id);
  //     if (element) {
  //       this._store.dispatch(new ParticipantItemUpdatedEvent(payload));
  //     }
  //   });
  // }
  @Action(RefreshCurrentUser)
  public refreshUser({ dispatch, getState }: StateContext<P2pBuyingStateModel>, { payload }: RefreshCurrentUser): void {
    if (payload.id && payload.id !== null) {
      dispatch(new GetMyBids());
    }
  }
  @Action(UpdateShowWarnValue)
  public updateShowWarnValue({ patchState }: StateContext<P2pBuyingStateModel>, { value }: UpdateShowWarnValue) {
    this._localStorage.set('marketplace-showWarn', value);
    patchState({ showWarn: value });
  }
  @Action(GetMyBids)
  public getMyBids({ patchState }: StateContext<P2pBuyingStateModel>) {
    const now = moment();

    return this._api.reqPurchasing().pipe(
      tap((response) => {
        const priorityList: PriorityList = {
          high: [OrderStatusEnum.WAIT_FOR_TRADE, OrderStatusEnum.WAIT_FOR_CONFIRM, OrderStatusEnum.WAIT_FOR_BUYER_ACCEPT],
        }
        return patchState({ purchasing: (sortByPriority(response.items, 'statusAt', priorityList, 'asc') as IP2pPurchaseItem[])
            .filter(value =>  moment(value.nextStatusAt).diff(now,'days') >= 0) });
      }),
      catchError((e) => this.onError(e))
    );
  }
  @Action(ToggleSelected)
  public toggleSelected({ patchState, getState, dispatch }: StateContext<P2pBuyingStateModel>, { payload }: ToggleSelected) {
    const { purchasing } = getState();
    const has = purchasing.some((el) => el.id === payload.id);

    if (has) {
      dispatch(new RemoveItem(payload.id));
    } else {
      const newValue = [...purchasing, payload];
      // setState(patch({ purchasing: insertItem(payload) }));
      patchState({ purchasing: sortBy(newValue, 'statusAt', 'asc') });
      const activePanel = this._store.selectSnapshot(LayoutState.activePanel);
      if (activePanel === null || activePanel.panel !== Panel.TRADES) {
        dispatch(new ChangeActivePanel({ panel: Panel.TRADES, subPanel: SubPanel.NONE }));
      }
    }
  }
  @Action(MergePurchasing)
  public mergePurchasing({ setState, getState }: StateContext<P2pBuyingStateModel>, { id, data }: MergePurchasing) {
    // const { purchasing } = getState();
    // const element = purchasing.find((el) => el.id === id);
    // if (element) {
    // console.log(id, data);

    setState(
      patch({
        purchasing: updateItem<IP2pPurchaseItem>(
          (o) => o?.id === id,
          patch({
            ...data,
            isActive: false,
          })
        ),
      })
    );
    // }
  }
  @Action(CreateBid)
  public createBid({ dispatch }: StateContext<P2pBuyingStateModel>, { id }: CreateBid) {
    return this._api.reqBid(id).pipe(catchError((e) => this.onError(e)));
  }
  @Action(ConfirmBid)
  public confirmBid({ dispatch }: StateContext<P2pBuyingStateModel>, { id }: ConfirmBid) {
    return this._api.reqConfirm(id).pipe(
      tap((response) => dispatch([new MergePurchasing(id, response)])),
      catchError((e) => this.onError(e))
    );
  }
  @Action(DeleteBid)
  public deleteBid({ dispatch }: StateContext<P2pBuyingStateModel>, { id }: DeleteBid) {
    return this._api.reqBidDelete(id).pipe(
      tap((r) => {
        return dispatch(new RemoveItem(id));
      }),
      catchError((e) => {
        return this.onError(e);
      })
    );
  }
  @Action(RemoveItem)
  public removeItem({ setState, getState }: StateContext<P2pBuyingStateModel>, { id, isActive }: RemoveItem) {
    // const { purchasing } = getState();
    // const element = purchasing.find((el) => el.id === id);
    // if (!element) return;
    setState(
      patch({
        purchasing: removeItem<IP2pPurchaseItem>((x) => x?.id === id),
      })
    );
    this._frameMessageService.sendMessage({
      type: FrameMessageTypes.MESSAGE_TO_BB,
      eventName: 'RemoveSkin',
      payload: { id },
    });
  }

  private onError(e: HttpErrorResponse): Observable<void> {
    const { error } = e;
    this._notificationsService.addErrorNotification(error.message || error.error, {
      icon: 'warning',
      system: error.system,
      params: error?.params ?? {},
    });
    return of();
  }
}
