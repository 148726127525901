import {ChangeDetectionStrategy, Component, Inject, OnDestroy} from '@angular/core';
import {pluck, takeUntil, tap} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import {IUserDetailed} from '@dev-fast/types';
import {P2pModalSetupService} from './modal.service';

@Component({
  selector: 'csgofast-p2p-setup-modal',
  templateUrl: './p2p-setup-modal.component.html',
  styleUrls: ['./p2p-setup-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class P2pSetupModalComponent implements OnDestroy {
  public apiKeyForm: FormGroup;
  public tradeLinkForm: FormGroup;

  public readonly steam$: Observable<IUserDetailed['links']['steam']>;
  public readonly steamApiKey$: Observable<IUserDetailed['steamApiKey']>;
  public readonly tradelinkIsValid$: Observable<boolean>;
  public readonly apikeyIsValid$: Observable<boolean>;

  private destroyed$: Subject<void>;

  constructor(
    private readonly fb: FormBuilder,
    private readonly moduleService: P2pModalSetupService,
    @Inject(MAT_DIALOG_DATA) public data: string[]
  ) {
    this.tradelinkIsValid$ = this.moduleService.tradelinkIsValid$;
    this.apikeyIsValid$ = this.moduleService.apikeyIsValid$;

    this.destroyed$ = new Subject<void>();

    this.tradeLinkForm = this.fb.group({
      steam: ['', [Validators.required]],
      submitted: false,
    });

    this.apiKeyForm = this.fb.group({
      apikey: ['', [Validators.required]],
      submitted: false,
    });

    this.steam$ = this.moduleService.user$.pipe(
      pluck('links', 'steam'),
      tap((steam) =>
        this.tradeLinkForm.patchValue({
          steam: steam || '',
          submitted: !!steam,
        })
      )
    );

    this.steamApiKey$ = this.moduleService.user$.pipe(
      pluck('steamApiKey'),
      tap((apikey) =>
        this.apiKeyForm.patchValue({
          apikey: apikey || '',
          submitted: !!apikey,
        })
      )
    );
  }

  public isDisabled(val: string, origin: string): boolean {
    // console.log(val);
    // console.log(origin);

    return val === origin || !val;
  }

  public onSubmitTradeLink(steam: string): void {
    this.moduleService.updateTradeLink({ steam });

    this.moduleService.tradeLinkUpdated$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      const control = this.tradeLinkForm.get('submitted');
      control && control.setValue(true);
    });
  }

  public onSubmitApiKey(key: string): void {
    this.moduleService.updateApiKey(key);
    this.moduleService.apiKeyUpdated$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      const control = this.apiKeyForm.get('submitted');
      control && control.setValue(true);
    });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
