import { LanguageGuard, LanguageService, MAIN_LANG } from "@csgofast/core/language-service";
import { NotFoundPageComponent } from "@csgofast/not-found-page";
import { GofastLayoutComponent } from "@csgofast/gofast/layout";
import { routes as appRoutes } from './app.routes';
import { NoIndexComponent } from "@dev-fast/ui-components";
import { OnlyServerGuard } from "@csgofast/core/guards";
import { Routes } from '@angular/router';

const notFoundPage = {
  path: '**',
  component: NotFoundPageComponent,
  data: {seo: 'NOT_FOUND', seoOptions: {h1: true}},
  canActivate: [LanguageGuard],
};

export const routes: Routes = [
  {
    path: '',
    component: GofastLayoutComponent,
    children: [{
      path: '',
      children: appRoutes,
      canActivate: [LanguageGuard],
      data: {lang: MAIN_LANG},
    }, { // страница заглушка для пререндера неважных сео страниц
      path: 'noindex',
      canActivate: [OnlyServerGuard],
      canLoad: [OnlyServerGuard],
      component: NoIndexComponent
    },
    ...Object.keys(LanguageService.getRouteLanguages()).filter(item => item !== MAIN_LANG).map(lang => ({
      path: lang,
      children: [...appRoutes, notFoundPage],
      canActivate: [LanguageGuard],
      data: {lang}
    })),
    notFoundPage
  ]}
];
