export const MatchTicketTypeBalanceField = {
  'green-key': 'greenKeys',
  'blue-key': 'blueKeys',
  'orange-key': 'orangeKeys',
};
export const MatchTicketTypeImg = {
  'green-key': 'green-cane.png',
  'blue-key': 'blue-cane.png',
  'orange-key': 'red-cane.png',
};
export const MatchPopTypeImg = {
  'green-key': 'green-pop.svg',
  'blue-key': 'blue-pop.svg',
  'orange-key': 'red-pop.svg',
};