import {IconConfig} from "./icon.model";

export const iconsMedia: IconConfig[] = [
  {
    name: 'volume-up',
    path: 'assets/img/mat-icons/media/volume-up.svg',
  },
  {
    name: 'volume-off',
    path: 'assets/img/mat-icons/media/volume-off.svg',
  },
  {
    name: 'play',
    path: 'assets/img/mat-icons/media/play.svg',
  },
];
