import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { OpenModal } from '@csgofast/core/state/modals';
import { IUserAvatarNickname, ModalNames } from '@dev-fast/types';
import { Store } from '@ngxs/store';
import { winnerIconAnimation } from './user-avatar.animation';

@Component({
  selector: 'fast-ui-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
  animations: [winnerIconAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAvatarComponent {
  @Input() public set url(url: string) {
    this._image = this.computeUserAvatar(url);
  }

  public get url(): string {
    return this._image;
  }

  @Input() public fallback: string;
  @Input() public alt: string;
  @Input() public level?: number;
  @Input() public levelLabelOn: boolean;
  @Input() public levelUpAnimation?: boolean;
  @Input() public newLevel?: number;
  @Input() public notRound?: boolean;
  @Input() public noBorder?: boolean;
  @Input() public noBorderline?: boolean;
  @Input() public noChevron?: boolean;
  @Input() public isWinner?: boolean;
  @Input() public additionalIcon?: string;
  @Input() public username?: IUserAvatarNickname;
  private _type: string;
  @Input() public set type(val: string | undefined) {
    if (val) {
      this._type = val;
    }
  }
  public get type(): string {
    return this._type;
  }
  @Input() public userId?: number | null;
  @Output() public openUserModal: EventEmitter<number>;
  private _image!: string;
  private _dimensions!: number;

  constructor(private elementRef: ElementRef, private store: Store) {
    this.levelLabelOn = false;
    this.alt = '';
    this.level = 0;
    this._type = '';
    this.newLevel = 1;
    this.fallback = '//d2lomvz2jrw9ac.cloudfront.net/avatars/avatar-fallback_2.png';
    this.levelUpAnimation = false;
    this.openUserModal = new EventEmitter(false);
  }

  public onImageError(): void {
    this.url = this.fallback;
  }

  public computeUserAvatar(url: string): string {
    if (!url) return this.fallback;
    this._dimensions = this.computeComponentDimensions();
    if (url.startsWith('data:') || url.startsWith('http') || url.startsWith('//')) {
      if ((url || '').startsWith('https://din8r827idtuo.cloudfront.net/')) {
        const [source, ...query] = url.split('?');
        query.push(`width=${this._dimensions}`);
        query.push(`height=${this._dimensions}`);
        // ToDo: move it out
        return source.replace('din8r827idtuo.cloudfront.net', 'de4khei8i4ut2.cloudfront.net') + `?${query.join('&')}`;
      }
      if (url.startsWith('https://api.adorable.io/avatars/')) {
        const avatar = url.split('/');
        const path = avatar[avatar.length - 1];
        return `https://api.adorable.io/avatars/${this._dimensions}/${path}`;
      }
      return url;
    }

    return `https://steamcdn-a.akamaihd.net/steamcommunity/public/images/avatars/${url}_full.jpg`;
  }

  public computeComponentDimensions(): number {
    const host = getComputedStyle(this.elementRef.nativeElement);
    const size = host.getPropertyValue('--size');
    const [, dimensions, type] = size.split(/([0-9]+)/);
    const width = 96;
    const dimensionsNumber = isNaN(Number(dimensions)) ? width : Number(dimensions);
    if (type === 'rem') {
      const calculated = dimensionsNumber * 16;
      return calculated < width ? width : calculated;
    }
    return Math.max(width, dimensionsNumber);
  }

  public openProfile(event: Event, userId?: number | null): void {
    if (userId) {
      // this.dialog.open(UserProfileModalComponent, {
      //   data: { userId },
      //   panelClass: 'user-profile-modal',
      // });
      // this.openUserModal.emit(userId);
      this.store.dispatch(new OpenModal(ModalNames.USER_PROFILE, { userId }));
    }
  }
}
