<div class="possible-items-container">
  <div class="possible-items-header">
    <div class="header-title">{{ 'GOFAST.CASES.SPIN_GAME.POSSIBLE_ITEMS.CASE_CONTENTS' | translate }}:</div>
    <div class="possible-gain-motivator loading-gradient">
    </div>
    <div class="btn header-title_btn">
      {{ 'GOFAST.CASES.SPIN_GAME.BUTTONS.BUTTON_CASE_REVISION' | translate }}
    </div>
  </div>
  <div class="possible-items">
    <csgofast-possible-card-mock>
    </csgofast-possible-card-mock>
    <csgofast-possible-card-mock>
    </csgofast-possible-card-mock>
    <csgofast-possible-card-mock>
    </csgofast-possible-card-mock>
    <csgofast-possible-card-mock>
    </csgofast-possible-card-mock>
    <csgofast-possible-card-mock>
    </csgofast-possible-card-mock>
    <csgofast-possible-card-mock>
    </csgofast-possible-card-mock>
    <csgofast-possible-card-mock>
    </csgofast-possible-card-mock>
    <csgofast-possible-card-mock>
    </csgofast-possible-card-mock>
    <csgofast-possible-card-mock>
    </csgofast-possible-card-mock>
    <csgofast-possible-card-mock>
    </csgofast-possible-card-mock>
  </div>
</div>
