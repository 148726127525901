import { LocalizationApiService } from "@dev-fast/backend-services";
import { catchError, Observable, of } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';
import { makeStateKey, TransferState } from "@angular/platform-browser";
import LocalizationMock from 'libs/shared/assets/src/i18n/en_US.json';

class CustomTransLoader implements TranslateLoader {
  private DEFAULT_LOCALE_KEY = makeStateKey('default-locale');

  constructor(
    private readonly localizationApiService: LocalizationApiService,
    private readonly transferState: TransferState,
  ) {
  }

  public getTranslation(lang: string): Observable<object> {
    console.log('getTranslation', lang);
    const defaultLocale = this.transferState.get(this.DEFAULT_LOCALE_KEY, LocalizationMock as any);
    // default-locale - язык переданные с пререндера. Ставится первым по-умолчанию пока не загрузятся запросы
    return (lang === 'default-locale' ? of(defaultLocale) : this.localizationApiService.getTranslation(lang))
      .pipe(catchError(() => this.localizationApiService.getTranslationFromAssets(lang)));
  }
}

export function customTranslateLoader( localizationApiService:LocalizationApiService, transferState: TransferState) {
  return new CustomTransLoader(localizationApiService, transferState);
}
