import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';
import { SocialItem, SocialType } from '@dev-fast/types';
import { Observable } from 'rxjs';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'fast-auth-modal',
  templateUrl: './auth-modal.component.html',
  styleUrls: ['./auth-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AuthModalComponent {
  public loginAttempt: boolean;
  public agreementChecked: boolean;
  public socials$: Observable<SocialItem[]>;
  @Input() public showAttention: boolean

  constructor(private authService: AuthService) {
    this.loginAttempt = false;
    this.agreementChecked = true;
    this.socials$ = this.authService.socials$;
    this.showAttention = true;
  }

  public authorize(provider: SocialType): void {
    this.loginAttempt = true;
    this.agreementChecked && this.authService.onLogin(provider);
  }
}
