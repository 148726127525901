import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../api.service';
import { Environments, EnvironmentService } from '@csgofast/core/environment-service';
import {
  ICaseCollection,
  ICaseEventCollection,
  ICaseItemDetailedReq,
  ICaseItemDtoV2,
  ICaseOpenPrizeDataDto,
  ICaseRevisionInfo,
  ICasesFilter,
  IHistoryOfOpenCases,
  IMotivatorStatistic,
  TicketTypeEnum,
} from '@dev-fast/types';
import { paramsToQuery } from '@csgofast/shared/utils';

@Injectable({
  providedIn: 'root',
})
export class CasesBackendService {
  private environment: Environments;

  constructor(private readonly api: ApiService, private readonly environmentService: EnvironmentService) {
    this.environment = this.environmentService.environments;
  }
  public getStat = (filter: { keys: string; limit: number }): Observable<IMotivatorStatistic> =>
    this.api.get(`${this.environment.GATEWAY_URL}/api/motivator/cases`, { params: filter });

  public getCategoriesById = (casesFilter: ICasesFilter, id: number): Observable<ICaseCollection> => {
    return this.api.get(
      `${this.environment.GATEWAY_URL}/api/cases/categories/${id}`,
      {
        params: casesFilter,
        withCredentials: true,
      }
    );
  };
  public getCasesFromCategoryAll = (casesFilter: ICasesFilter): Observable<ICaseItemDtoV2[]> => {
    return this.api.get(
      `${this.environment.GATEWAY_URL}/api/cases/suitcases`,
      {
        params: casesFilter,
        withCredentials: true,
      }
    );
  };
  public getFreeCategory = (): Observable<ICaseCollection> => {
    return this.api.get(`${this.environment.GATEWAY_URL}/api/cases/free`);
  };
  public getRoutingCategories = (): Observable<ICaseCollection[]> => {
    return this.api.get(`${this.environment.GATEWAY_URL}/api/cases/categories?detailed=true`, { withCredentials: true });
  };
  public getCasesEvents = (): Observable<ICaseEventCollection[]> => {
    return this.api.get(`${this.environment.GATEWAY_URL}/api/cases/events`, { withCredentials: true });
  };
  public getItemsFromCaseById = (id: number): Observable<ICaseItemDtoV2> =>
    this.api.get(`${this.environment.GATEWAY_URL}/api/cases/suitcases/${id}`, { withCredentials: true });

  public getSpinItems = (lines: number, caseId: number): Observable<Array<number[]>> =>
    this.api.get(`${this.environment.GATEWAY_URL}/api/cases/strip/calc?lines=${lines}&caseId=${caseId}`, { withCredentials: true });

  public getCaseOpeningHistory = (page: number): Observable<IHistoryOfOpenCases> =>
    this.api.get(`${this.environment.GATEWAY_URL}/api/cases/suitcases/history?page%5Bnumber%5D=${page}&page%5Bsize%5D=20`, {
      withCredentials: true,
    });

  public getItemsFromOpenedCase = (
    id: number,
    count: number,
    gameType: 'for-money' | 'autoplay' | 'demo' | 'bonus',
    isFree?: boolean,
    ticketColor?: TicketTypeEnum
  ): Observable<ICaseOpenPrizeDataDto> =>
    this.api.post(
      `${this.environment.GATEWAY_URL}/api/cases/suitcases/${id}/open${gameType === 'demo' ? '/demo' : ''}`,
      { count: count, isFree, ticketColor },
      { withCredentials: true }
    );
  public getReplays = (openUUID: string): Observable<ICaseOpenPrizeDataDto> =>
    this.api.get(`${this.environment.GATEWAY_URL}/api/cases/replay/${openUUID}`);

  public getCaseRevisionById = (id: number, round: number): Observable<ICaseRevisionInfo> =>
    this.api.get(`${this.environment.GATEWAY_URL}/api/cases/suitcases/revision/${id}/${round}`);

  public getAvailabilityItemInCases = (params: ICaseItemDetailedReq): Observable<ICaseItemDtoV2[]> =>
    this.api.get(`${this.environment.GATEWAY_URL}/api/cases/suitcases/item`, {
      withCredentials: true,
      params,
    });
}
