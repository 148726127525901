import { ILocales } from "@dev-fast/types";

export class SetLanguage {
  public static readonly type = '[Layout] Change language';
  constructor(public locale: ILocales) {}
}

export class GetLocales {
  public static readonly type = '[Layout] Get locales';
  constructor() {}
}
