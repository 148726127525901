import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {NotificationComponent} from './notification/notification.component';
import {AppCurrencyTranslateModule} from '@csgofast/shared/pipes';

@NgModule({
  declarations: [NotificationComponent],
  imports: [CommonModule, MatIconModule, TranslateModule, AppCurrencyTranslateModule],
  providers: [],
  exports: [NotificationComponent],
})
export class NotificationsModule {}
