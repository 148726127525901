import {Directive, ElementRef, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {IUserDetailed, Permissions} from '@dev-fast/types';
import {HasPermissionService} from "./has-permission.service";

@Directive({
  selector: '[hasPermission]',
})
export class HasPermissionDirective {
  // private currentUser: IUserDetailed | null;
  private permissions: Permissions[] = [];
  private logicalOp = 'AND';
  private isHidden = true;
  private currentPermissions: Permissions[];

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private userService: HasPermissionService
  ) {
    // this.currentUser=null;
    this.currentPermissions=[]
  }

  ngOnInit() {
    this.userService.permissions$.subscribe(permissions => {
      // this.currentUser = user;
      this.currentPermissions = permissions;
      this.updateView();
    });
  }

  @Input()
  set hasPermission(val: Permissions[]) {
    this.permissions = val;
    this.updateView();
  }

  @Input()
  set hasPermissionOp(permop: string) {
    this.logicalOp = permop;
    this.updateView();
  }
  /** todo улучшить*/

  private updateView() {
    if (this.checkPermission()) {
      if(this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.isHidden = true;
      this.viewContainer.clear();
    }
  }

  private checkPermission() {
    let hasPermission = false;

    if (this.currentPermissions.length) {
      for (const checkPermission of this.permissions) {
        const permissionFound = this.currentPermissions.find(x => x.toUpperCase() === checkPermission.toUpperCase());

        if (permissionFound) {
          hasPermission = true;

          if (this.logicalOp === 'OR') {
            break;
          }
        } else {
          hasPermission = false;
          if (this.logicalOp === 'AND') {
            break;
          }
        }
      }
    }

    return hasPermission;
  }

}
