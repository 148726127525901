import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { ICaseItemDtoV2, ICasesQuestItem } from '@dev-fast/types';
import { NyEventService } from '../../../core/services/ny-event.service';

@Component({
  selector: 'csgofast-room',
  templateUrl: './room.component.html',
  styleUrls: ['./room.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoomComponent implements OnDestroy, AfterViewInit {
  @Input() public presents: ICasesQuestItem[] | undefined | null;
  @Input() public overlay!: ICasesQuestItem | null

  private scale = 0;
  private presentsDims = [45, 122, 41, 50, 142, 46, 111, 104, 129];
  public presentsAxis = [
    'top: 31.3%;  left: 37.5%;',
    'bottom: 24.9%; left: 13.5%;',
    'top: 29.4%;  left: 50.7%;',
    'left: 55.9%;    top: 31.2%;',
    'left: 42%;    top: 9.7%;',
    'left: 43.4%;    top: 29.1%;',
    'left: 66.4%;    top: 81.3%;    z-index: 1;',
    'left: 75.2%;    top: 75.3%;',
    'left: 86.1%;    top: 81.9%;',
  ];

  @ViewChild('roomBackground') roomBgContainer!: ElementRef;

  resizeObs = new ResizeObserver(() => {
    this.scale = this.roomBgContainer.nativeElement.width / 1048;
    //fixme
    this.cd.detectChanges();
  });

  public scalePresent(index: number): string {
    return this.presentsDims[index] * this.scale + 'px';
  }

  constructor(private cd: ChangeDetectorRef,private readonly nyEventService: NyEventService) {}
  public ngAfterViewInit(): void {
    this.resizeObs.observe(this.roomBgContainer.nativeElement);
  }
  public pickupReward(present: ICasesQuestItem): void {
    // this.pickUpPrize.next(present)
    this.nyEventService.pickUpPrize(present)
  }
  public ngOnDestroy(): void {
    this.resizeObs.unobserve(this.roomBgContainer.nativeElement);
  }

}
