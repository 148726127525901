import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ScrolledToDirective} from './scrolled-to.directive';

@NgModule({
  declarations: [
    ScrolledToDirective,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    ScrolledToDirective,
  ],
})
export class ScrolledToModule { }
