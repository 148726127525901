import {Injectable} from '@angular/core';
import {BehaviorSubject, map, Observable} from 'rxjs';
import {INotification} from '@dev-fast/types';
import {Timer} from './utils/timer';
import {NotificationsService} from '@csgofast/core/notification-service';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private readonly STACK_SIZE = 4;
  private readonly COOLDOWN = 5000;
  private readonly stackFrom: 'top' | 'bottom';
  private notificationsStack: Timer[];

  // private notifications: BehaviorSubject<INotification[]>;
  public notifications$: Observable<INotification[]>;

  private showcaseNotifications: BehaviorSubject<INotification[]>;
  public showcaseNotifications$: Observable<INotification[]>;

  constructor(private readonly notificationsService: NotificationsService) {
    this.showcaseNotifications = new BehaviorSubject<INotification[]>([]);
    this.showcaseNotifications$ = this.showcaseNotifications.asObservable();

    // this.notifications = new BehaviorSubject<INotification[]>([]);
    // this.notifications$ = this.notifications.asObservable();

    this.notificationsStack = [];

    this.stackFrom = 'bottom';
    this.notifications$ = this.notificationsService.notifications$.pipe(
      // distinctUntilChanged((prev,current)=>{}),
      map((val) => {
        const showcaseNotifications = this.showcaseNotifications.getValue();
        const result = val.filter((element) => !showcaseNotifications.map((el) => el.id).includes(element.id));
        return result;
      })
    );
  }
  public addNotification(notification: INotification): void {
    this.notifyMessage(notification);
  }

  // public getNewNotifications(notifications: INotification[]): void {
  //   this.notifications.next(notifications);
  // }

  public deleteNotification(notyId: number): void {
    this.notificationsService.removeNotification(notyId);
    // this.notifications.next(notifications);
    // this.showcaseNotifications.next(notifications);
    // this.notifications.next(
    //   this.notifications.getValue().filter((oldNotification) => oldNotification.id !== notification.id),
    // );
    this.showcaseNotifications.next(this.showcaseNotifications.getValue().filter((oldNotification) => oldNotification.id !== notyId));
  }

  public popNotification(id: number): void {
    this.showcaseNotifications.next(this.showcaseNotifications.getValue().filter((oldNotification) => oldNotification.id !== id));
  }

  // public onError(error: HttpErrorResponse): Observable<void> {
  //   console.log(error);
  //   return of();
  // }

  // public watchAllNotifications(): void {
  //   // this.notifications.next(notifications);
  //   this.notificationsService.watchAllNotifications();
  // }

  public notifyMessage(notification: INotification) {
    const notifications = this.showcaseNotifications.getValue();

    if (['top'].includes(this.stackFrom)) {
      notifications.unshift(notification);
      if (this.STACK_SIZE < notifications.length) {
        notifications.pop();
      }
    } else {
      notifications.push(notification);
      if (this.STACK_SIZE < notifications.length) {
        notifications.shift();
      }
    }

    // this.notifications.next([notification, ...this.notifications.getValue()]);

    this.showcaseNotifications.next(notifications);

    this.notificationsStack.push(new Timer(notification.id, () => this.popNotification(notification.id), this.COOLDOWN));

    if (document.hidden) {
      this.pauseTimer();
    }
  }

  public pauseTimer() {
    this.notificationsStack.forEach((t: Timer) => t.pause());
  }

  public resumeTimer() {
    this.notificationsStack.forEach((t: Timer) => t.resume());
  }
}
