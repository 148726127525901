import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MotivatorWidgetComponent } from './motivator-widget.component';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { AppCurrencyModule } from '@csgofast/shared/pipes';
import { DropCardComponentModule, UserAvatarModule } from '@dev-fast/ui-components';
import { RecentActivitiesService } from '../../recent-activities.service';
import { MotivatorWidgetMockComponent } from "./mock/motivator-widget.component.mock";

@NgModule({
  imports: [CommonModule, MatIconModule, TranslateModule, AppCurrencyModule, UserAvatarModule, DropCardComponentModule],
  declarations: [MotivatorWidgetComponent, MotivatorWidgetMockComponent],
  exports: [MotivatorWidgetComponent, MotivatorWidgetMockComponent],
  providers: [RecentActivitiesService]

})
export class MotivatorWidgetModule {}
