import { Pipe, PipeTransform, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@Pipe({
  name: 'replace',
})
export class ReplacePipe implements PipeTransform {
  transform(value: string, regexValue: string, replaceValue: string): any {
    const regex = new RegExp(regexValue, 'g');
    return value.replace(regex, replaceValue);
  }

}

@NgModule({
  imports: [CommonModule],
  declarations: [ReplacePipe],
  exports: [ReplacePipe],
})
export class ReplacePipeModule {}
