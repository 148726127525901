export const paramsToQuery = <T>(params?: T): string => {
  if (!params) return '';
  const query = Object.keys(params)
    .map((key: string) => {
      const value = params[key as keyof T];
      if (typeof value !== 'number' && !value) return '';
      if (Array.isArray(value)) {
        if (value.length === 0) return '';
        return `${key}=${value.join(',')}`;
      }
      if (typeof value === 'object') {
        return Object.keys(value)
          .map((subkey: string) => `${key}[${subkey}]=${value[subkey as keyof typeof value]}`)
          .join('&');
      }
      return `${key}=${params[key as keyof T]}`;
    })
    .filter((result) => result)
    .join('&');
  return query === '' ? query : `?${query}`;
};
