import {AppCurrencyPipe} from './currency.pipe';
import {NgModule} from '@angular/core';
import {CommonModule, CurrencyPipe} from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    AppCurrencyPipe,
  ],
  exports: [
    AppCurrencyPipe,
  ],
  providers: [
    CurrencyPipe,
    AppCurrencyPipe,
  ],
})

export class AppCurrencyModule {
}
