export enum TransactionOperations {
  // balance
  CREDIT = 'credit',
  DEBIT = 'debit',
  INVENTORY_BUY = 'inventory_buy',
  INVENTORY_SELL = 'inventory_sell',
  // inventory
  ADD = 'add',
  REMOVE = 'remove',
  // game
  BET = 'bet',
  WINNING = 'winning',
}
