import {MenuLink} from '@dev-fast/types';

export const LINKS: MenuLink[] = [
  {
    label: 'Баланс',
    path: './account/balance',
    authRequired: true,
  },
  // {
  //   label: 'Инвентарь',
  //   path: './faq',
  //   authRequired: true,
  // },
  {
    label: 'Реферальная система',
    path: './bonuses/referral',
    authRequired: false,
  },
  {
    label: 'Фрикойнс',
    path: './bonuses/quest',
    authRequired: false,
  },
  {
    label: 'Личный кабинет',
    path: './account',
    authRequired: true,
  },
];
