<ng-container
  *ngTemplateOutlet="
    appTpl;
    context: {
      activePanel: activePanel$ | async,
      user: user$ | async,
      lockedPanels: lockedPanels$ | async
    }
  "
></ng-container>

<ng-template
  #appTpl
  let-activePanel="activePanel"
  let-user="user"
  let-lockedPanels="lockedPanels"
>
  <!-- FIXME хранить бы конфиги этих переключашек и выводить их просто перебором-->
  <nav
    class="menu"
    [ngClass]="{ menu_opened: activePanel }"
  >
    <button
      class="menu__collapse"
      (click)="onChangeActivePanel(activePanel && activePanel.panel)"
    >
      <mat-icon
        class="menu__collapse-icon"
        [svgIcon]="'chevron-double-left'"
      ></mat-icon>
    </button>
    <ng-scrollbar class="scroll">
      <div
        *hasPermission="[permissions.MEMBER]"
        class="menu-item transparent-btn"
        (click)="onAvatar(user)"
      >
        <fast-ui-user-avatar
          [url]="user.avatar"
          [type]="user.profileType"
          [levelUpAnimation]="canLevelUp$ | myAsync"
          [newLevel]="user.experience.level + 1"
        ></fast-ui-user-avatar>
      </div>

      <button
        class="menu-item transparent-btn"
        *hasPermission="[permissions.MEMBER, permissions.CAN_USE]"
        [class.active]="isActivePanel(panels.INVENTORY)"
        (click)="onChangeActivePanel(panels.INVENTORY, $event)"
        [disabled]="isLockedMenuItem(lockedPanels, panels.INVENTORY)"
      >
        <div class="menu-icon--wrapper">
          <mat-icon
            class="menu-icon"
            svgIcon="inventory-sidebar"
          ></mat-icon>
          <mat-icon
            class="menu-icon--lock"
            *ngIf="isLockedMenuItem(lockedPanels, panels.INVENTORY)"
            svgIcon="game-lock"
          ></mat-icon>
        </div>
      </button>
      <button
        class="menu-item transparent-btn"
        *hasPermission="[permissions.MEMBER,permissions.CAN_USE]"
        [class.active]="isActivePanel(panels.TRADES)"
        (click)="onChangeActivePanel(panels.TRADES, $event)"
      >
        <mat-icon
          class="menu-icon"
          svgIcon="market-sidebar"
        ></mat-icon>
      </button>
      <button
        class="menu-item transparent-btn"
        *ngIf="!user"
        (click)="onLogin()"
      >
        <mat-icon
          class="menu-icon"
          svgIcon="login"
        ></mat-icon>
      </button>
      <button
        *hasPermission="[permissions.CAN_USE]"
        class="menu-item transparent-btn"
        [class.active]="isActivePanel(panels.CHAT)"
        (click)="onChangeActivePanel(panels.CHAT, $event)"
      >
        <mat-icon
          class="menu-icon"
          svgIcon="chat-sidebar"
        ></mat-icon>
      </button>

      <button

        class="menu-item notifications transparent-btn"
        *hasPermission="[permissions.MEMBER]"
        [class.active]="isActivePanel(panels.NOTIFICATIONS)"
        (click)="onChangeActivePanel(panels.NOTIFICATIONS, $event)"
        [ngClass]="{ notifications_unread: countNewNotifications$ | async }"
        [@notificationAnimation]="countNewNotifications$ | async"
      >
        <span [ngClass]="{ 'notifications_unread-circle--pulse': countNewNotifications$ | async }"></span>
        <span [ngClass]="{ 'notifications_unread-circle': countNewNotifications$ | async }"></span>
        <mat-icon
          class="menu-icon"
          svgIcon="notification-sidebar"
        ></mat-icon>
      </button>
      <button
        class="menu-item transparent-btn"
        *hasPermission="[permissions.MEMBER]"
        [routerLink]="'account/settings'"
      >
        <mat-icon
          class="menu-icon"
          svgIcon="setting-sidebar"
        ></mat-icon>
      </button>
      <button
        class="menu-item transparent-btn"
        (click)="openTechSupportDialog()"
      >
        <mat-icon
          class="menu-icon"
          svgIcon="techsupport-sidebar"
        ></mat-icon>
      </button>

      <ng-container *ngIf="isTabletByWidth$ | async">
        <div class="divider"></div>
        <a
          class="menu-item transparent-btn toMobile-icon"
          (click)="goToMobile()"
        >
          <mat-icon
            class="menu-icon"
            svgIcon="to-mobile"
          ></mat-icon>
        </a>
        <a
          class="menu-item transparent-btn"
          [routerLink]="'/faq'"
        >
          <mat-icon
            class="menu-icon"
            svgIcon="faq"
          ></mat-icon>
        </a>

        <a
          class="menu-item transparent-btn"
          (click)="toggleSounds()"
        >
          <ng-container *ngIf="soundStatus$ | async; else soundOff">
            <mat-icon
              class="menu-icon"
              svgIcon="volume-on"
            ></mat-icon>
          </ng-container>
          <ng-template #soundOff>
            <mat-icon
              class="menu-icon"
              svgIcon="volume-off"
            ></mat-icon>
          </ng-template>
        </a>

        <ng-container *hasPermission="[permissions.MEMBER]">
          <div class="divider"></div>
          <a
            class="menu-item transparent-btn"
            (click)="onLogout()"
          >
            <mat-icon
              class="menu-icon"
              svgIcon="log-out"
            ></mat-icon>
          </a>
        </ng-container>
      </ng-container>
    </ng-scrollbar>
  </nav>

  <div
    class="panel"
    [ngClass]="{ panel_opened: activePanel }"
  >
    <!--      FIXME Нужно убрать инлайн стиль и поставить в css максимальную высоту-->
    <!-- fixed -->
    <div
      *ngIf="activePanel"
      [ngSwitch]="activePanel.panel"
      class="descktop-right-panel__panel-wrapper"
    >
      <ng-container *hasPermission="[permissions.MEMBER]">
        <fast-ui-inventory-widget
          *ngSwitchCase="panels.INVENTORY"
          [inventoryList]="inventoryItems$ | myAsync"
          [selectedItems]="selectedInventoryItems$ | myAsync"
          [totalBalance]="totalBalance$ | myAsync"
          [subPanel]="activePanel.subPanel"
          [gameInProgress]="gameInProgress$ | async"
          [params]="inventoryParams$ | myAsync"
          (paramsEmit)="changeInventoryParams($event)"
          (toggleExchangePanel)="onToggleExchangePanel()"
          (closeSubPanel)="onCloseSubInventoryPanel()"
          (clickOnSkinEmit)="clickOnSkinInventory($event)"
          (sellAll)="onSellAll()"
        >
          <fast-ui-exchange-panel
            *ngIf="activePanel.subPanel === subPanels.EXHANGE"
            [user]="user"
            [inventoryList]="shopItems$ | myAsync"
            [selectedItems]="selectedShopItems$ | myAsync"
            [tradeBalance]="inventoryTradeBalance$ | myAsync"
            [params]="shopParams$ | myAsync"
            [meta]="shopMeta$ | myAsync"
            [loading]="inventoryShopLoading$ | myAsync"
            (changeParams)="changeShopParams($event)"
            (clickOnSkinEmit)="clickOnSkinShop($event)"
            (createTrade)="clickOnCreateTrade()"
          ></fast-ui-exchange-panel>
          <fast-ui-bet-panel-legacy
            *ngIf="activePanel.subPanel === subPanels.BET"
            [selectedItems]="selectedInventoryItems$ | myAsync"
            [config]="legacyConfig$ | myAsync"
            [state]="legacyState$ | myAsync"
            [liteState]="legacyLiteState$ | myAsync"
            [participated]="participatedSuccess$ | myAsync"
            [participatedItems]="legacyParticipation$ | myAsync"
            (participate)="onParticipate()"
            (clean)="onCleanParticipate($event)"
            (clickOnSkinEmit)="clickOnSkinInventory($event)"
          ></fast-ui-bet-panel-legacy>
        </fast-ui-inventory-widget>
        <!-- <fast-ui-trades-widget
          *ngSwitchCase="panels.TRADES"
          (closeAttentionEmit)="onCloseAttention()"
          (tradesSettingsEmit)="onChangeTradesSettings($event)"
        ></fast-ui-trades-widget> -->
        <csgofast-p2p *ngSwitchCase="panels.TRADES"></csgofast-p2p>

        <!-- <fast-ui-notifications
          *ngSwitchCase="panels.NOTIFICATIONS"
          [notificationList]="notifications"
          (watchAllEmit)="onWatchAllNotification()"
          (closeEmit)="onChangeActivePanel(panels.NOTIFICATIONS)"
          (deleteSelectedNotificationEmit)="onDeleteSelectedNotification($event)"
        ></fast-ui-notifications> -->
        <csgofast-notifications *ngSwitchCase="panels.NOTIFICATIONS"></csgofast-notifications>
      </ng-container>
      <!-- <fast-ui-chat
        *ngSwitchCase="panels.CHAT"
        [messageList]="messageList"
        (sendMessageEmit)="sendMessage($event)"
      ></fast-ui-chat> -->
      <csgofast-chat *ngSwitchCase="panels.CHAT"></csgofast-chat>
    </div>
  </div>
</ng-template>
